<template>
  <div
    v-show="showable"
    @click="upgrade()"
    :class="{ 'bg-gradient-to-br from-gray-100 to-gray-500 px-2': !small, 'cursor-pointer': clickable }"
    class="flex select-none items-center rounded-full text-sm text-black"
  >
    <svg
      :class="{ 'h-6 w-6': small, 'h-4 w-4': !small }"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 27.96 27.96"
    >
      <defs>
        <mask :id="`mask_${id}`" x="4.76" y="5.28" width="19.47" height="19.47" maskUnits="userSpaceOnUse" />
        <linearGradient
          :id="`silver_gradient_${id}`"
          x1="13.98"
          y1="25.61"
          x2="13.98"
          y2="6.14"
          gradientTransform="translate(0 29.86) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#9ca1a3" />
          <stop offset="1" stop-color="#9ca1a3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          :id="`silver_gradient_${id}_2`"
          x1="14.03"
          y1="22.65"
          x2="14.03"
          y2="10.05"
          gradientTransform="translate(0 29.86) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f1f5f5" />
          <stop offset="0" stop-color="#fff" />
          <stop offset="1" stop-color="#f1f5f5" />
        </linearGradient>
      </defs>
      <circle cx="13.98" cy="13.98" r="13.48" style="fill: #e3e3e3; stroke: #404040" />
      <circle cx="13.98" cy="13.98" r="9.71" style="fill: #595959" />
      <g :style="{ mask: `url(#mask_${id})` }">
        <circle cx="13.98" cy="13.98" r="9.73" :style="`fill: url(#silver_gradient_${id});`" />
      </g>
      <path
        d="M14.03,7.21l2.1,4.2,4.2,.52-2.88,3.23,.78,4.64-4.2-2.1-4.2,2.1,.79-4.64-2.89-3.23,4.2-.52,2.1-4.2Z"
        :style="`fill: url(#silver_gradient_${id}_2);`"
      />
    </svg>

    <div class="mb-0 ml-1 mt-0.5 whitespace-nowrap pr-2" v-if="!small">
      <strong>Silver<span v-if="isSilverPlus">+</span></strong> <span class="text-base font-normal"> plan</span>
    </div>
  </div>
</template>
<script>
import canFeature from '../../../mixins/canFeature'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { v4 as uuid } from 'uuid'

export default {
  mixins: [canFeature],
  props: {
    title: { type: String, required: false },
    feature: { type: String, required: false },
    subtitle: { type: String, required: false },
    small: { type: Boolean, required: false, default: false },
    findFeature: { type: Boolean, required: false, default: true },
    forceShow: { type: Boolean, required: false, default: false },
    canClick: { type: Boolean, required: false, default: true },
    isSilverPlus: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      id: uuid(),
      feat: null,
    }
  },
  computed: {
    showable() {
      return this.forceShow || !this.userCan(this.feature)
    },
    clickable() {
      return this.canClick && !this.forceShow && !this.userCan(this.feature)
    },
  },
  methods: {
    upgrade() {
      if (!this.clickable || (this.forceShow && this.userCan(this.feature))) {
        return
      }

      upgradeDialog.open({
        title: this.getTitle(),
        subtitle: this.getSubtitle(),
        product: 'silver',
        reason: 'Clicked Silver Plan Button',
        intentional: true,
      })
    },
    getTitle() {
      if (this.title) {
        return this.title
      }

      if (this.feature && this.findFeature) {
        if (this.feat === null) this.setFeature()
        return this.feat?.promo?.title
      }

      return null
    },
    getSubtitle() {
      if (this.subtitle) {
        return this.subtitle
      }

      if (this.feature && this.findFeature) {
        if (this.feat === null) this.setFeature()
        return this.feat?.promo?.subtitle
      }

      return null
    },
    setFeature() {
      this.feat = this.getFeatureFromString(this.feature)
    },
  },
}
</script>
