<script setup lang="ts">
import { Select, SelectTrigger, SelectValue, SelectItem, SelectContent } from '@/components/ui/select';
import { useTheme } from '@/Hooks/useTheme';
import IconSaxSun1 from '@/components/Icons/iconsax/IconSaxSun1.vue';
import IconSaxMoon from '@/components/Icons/iconsax/IconSaxMoon.vue';
import IconSaxMonitor from '@/components/Icons/iconsax/IconSaxMonitor.vue';
import IconSaxMobile from '@/components/Icons/iconsax/IconSaxMobile.vue';
import { useIsMobile } from '@/Hooks/useIsMobile';

const { colorMode } = useTheme();
const isMobile = useIsMobile();
</script>

<template>
  <form>
    <label class="flex max-w-[50ch] flex-col">
      <span class="text-lg">UI Settings</span>
      <span class="flex items-center gap-4">
        <span class="whitespace-nowrap">Color mode</span>
        <Select v-model="colorMode">
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="dark">
              <span  class="flex items-center gap-2">
                <IconSaxMoon class="size-4" />
                <span class="font-light">Dark</span>
              </span>
            </SelectItem>
            <SelectItem value="light">
              <span  class="flex items-center gap-2">
                <IconSaxSun1 class="size-4" /> 
                <span class="font-light">Light</span>
              </span>
            </SelectItem>
            <SelectItem value="auto">
              <span  class="flex items-center gap-2">
                <IconSaxMonitor class="size-4" v-if="!isMobile" />
                <IconSaxMobile class="size-4" v-else />
                <span class="font-light">Device</span>
              </span>
            </SelectItem>
          </SelectContent>
        </Select>
      </span>
    </label>
  </form>
</template>

<style scoped lang="scss">

</style>
