import { computed } from 'vue'
import { useGuard } from '@/Hooks/useGuard'
import { isSameDay, isPast, addDays, addMilliseconds } from 'date-fns'
import type { EventDropArg } from '@fullcalendar/core'
import toastEvents from '@/events/toastEvents'
import { useUserInfoStore } from '@/store/user/userInfo'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import type { DropArg, DateClickArg } from '@fullcalendar/interaction'
import type { UpdatePostDto } from '@/apis/streamladder-publisher/model'
import { useToast } from '@/Hooks/useToast'

export function useContentPublisherEventHandling(options: Options) {

  const userInfoStore = useUserInfoStore()
  const hasSocialsConnected = computed(() => {
    return (
      userInfoStore.youTubeAccounts.length >= 1 ||
      userInfoStore.tikTokAccounts.length >= 1 ||
      userInfoStore.instagramAccounts.length >= 1
    )
  })

  const canSchedule = computed(() => useGuard('scheduler').value)

  function onDrop(event: DropArg) {
    if (!canSchedule.value) {
      return openUpgradeDialog()
    }

    // If there are no socials connected, do not continue
    if (!hasSocialsConnected.value) {
      options.onConnectSocials()
      return
    }

    const eventData = event.draggedEl?.dataset?.event
    if (!eventData || isSchedulingInThePast(event.date)) {
      return
    }

    const data = JSON.parse(eventData)
    options.onDrop({ videoId: data.id, date: event.date.toISOString() })
  }

  function onDateClick(event: DateClickArg) {
    if (!canSchedule.value) {
      return openUpgradeDialog()
    }

    const eventDate = new Date(event.date)
    const isSchedulingForToday = isSameDay(eventDate, new Date())

    // If there are no socials connected, do not continue
    if (!hasSocialsConnected.value) {
      options.onConnectSocials()
      return
    }

    if (!isSchedulingForToday && isSchedulingInThePast(eventDate, { silent: true })) {
      return
    }

    const now = new Date()
    const date = !isSchedulingForToday
      ? new Date(eventDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()))
      : null

    options.onSchedule({ postDate: date?.toISOString() })
  }

  function onEventDrop(event: EventDropArg) {
    const post = event.event._def.extendedProps
    const date = addDelta(post.apiData.scheduledAt, event.delta)

    if (!canSchedule.value) {
      event.revert()
      return openUpgradeDialog()
    }

    if (!event.event._instance || isSchedulingInThePast(date)) {
      event.revert()
      return
    }

    options.onUpdate(post.apiData.id, {
      publishAt: date.toISOString(),
      targets: post.targets,
    })
  }

  return computed(() => ({
    drop: onDrop,
    dateClick: onDateClick,
    eventDrop: onEventDrop,
  }))
}

function addDelta(date: string, delta: { days: number; milliseconds: number }) {
  return addDays(addMilliseconds(new Date(date), delta.milliseconds), delta.days)
}

function openUpgradeDialog() {
  upgradeDialog.open('scheduler')
}

function isSchedulingInThePast(scheduleDate: Date, { silent = false } = {}) {
  if (isPast(scheduleDate)) {
    if (!silent) {
      const { showToast } = useToast()
      showToast({
        type: toastEvents.TOAST_ERROR,
        title: 'Oh no!',
        subtitle: 'You cannot reschedule to the past!',
      })
    }
    return true
  }
  return false
}

type Options = {
  onDrop: (event: { videoId: string; date: string }) => void
  onUpdate: (id: string, payload: UpdatePostDto) => void
  onSchedule: (event: { postDate: string | undefined }) => void
  onConnectSocials: () => void
}
