<script setup lang="ts">
import { videosFilter } from '@/Hooks/useFilterVideos'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import SendIcon from '@/components/Icons/SendIcon.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import UploadIcon from '@/components/Icons/UploadIcon.vue'
import { computed, ref } from 'vue'
import { Button } from '@/components/ui/button'
import { DialogClose } from '@/components/ui/dialog'
import MultiUploadDialog from '@/components/Dialog/MultiUploadDialog/MultiUploadDialog.vue'

const props = defineProps<{
  hideFileUpload?: boolean
  hideScheduleButton?: boolean
  totalAmountOfVideos: number
}>()

const header = computed(() => {

  if (props.totalAmountOfVideos === 0) {
    return {
      title: 'No clips found',
      subtitle: 'Try adjusting the filter or create a video in minutes'
    }
  }

  switch (videosFilter.value) {
    case 'unscheduled':
      return {
        title: 'No unscheduled clips',
        subtitle: 'Try adjusting the filter or create a video in minutes'
      }
    case 'scheduled':
      return {
        title: 'No scheduled clips',
        subtitle: 'Try adjusting the filter or schedule a clip'
      }
    default:
      return {
        title: 'No clips found',
        subtitle: 'Try adjusting the filter or create a video in minutes'
      }
  }
});
</script>

<template>
  <NoContentSection :title="header.title" :subtitle="header.subtitle">
    <template v-if="!hideScheduleButton && videosFilter === 'scheduled'">
      <RouterLink :to="{ name: dashboardRouteNames.contentPublisher.post }" class="shadow-lg">
        <GradientButton>
          Schedule clip
          <SendIcon class="h-4 fill-current" />
        </GradientButton>
      </RouterLink>
    </template>
    <template v-else>
      <RouterLink
        v-if="!hideScheduleButton || videosFilter !== 'scheduled'"
        :to="{ name: dashboardRouteNames.editor }" class="shadow-lg"
      >
        <GradientButton>
          Create video
          <IconSaxAdd />
        </GradientButton>
      </RouterLink>
      <MultiUploadDialog v-if="!hideFileUpload" upload-in-background storage-variant="medium" :auto-close-dialog-in-ms="2000">
        <Button variant="outline" class="shadow-lg p-5 rounded-lg">
          Upload file
          <UploadIcon class="w-5" />
        </Button>
      </MultiUploadDialog>
    </template>
  </NoContentSection>
</template>

<style scoped lang="scss">

</style>