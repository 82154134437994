/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { GetApiNotificationsParams, NotificationDto } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiNotifications = (
  params?: MaybeRef<GetApiNotificationsParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return streamLadderAxiosInstance<NotificationDto[]>(
    { url: `/api/Notifications`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiNotificationsQueryKey = (params?: MaybeRef<GetApiNotificationsParams>) => {
  return ['api', 'Notifications', ...(params ? [params] : [])] as const
}

export const getGetApiNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiNotifications>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiNotificationsParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiNotificationsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNotifications>>> = ({ signal }) =>
    getApiNotifications(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiNotifications>>,
    TError,
    TData
  >
}

export type GetApiNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNotifications>>>
export type GetApiNotificationsQueryError = unknown

export const useGetApiNotifications = <TData = Awaited<ReturnType<typeof getApiNotifications>>, TError = unknown>(
  params?: MaybeRef<GetApiNotificationsParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiNotifications>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiNotificationsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
