<template>
  <div class="flex cursor-pointer flex-col w-14" @click="emit('click')">
    <Avatar
      :title="!connectionType ? 'Disabled' : account.displayName"
      :class="{
        'opacity-50': !selected,
        'opacity-100': selected,
      }"
      class="relative w-14 h-14 hover:opacity-100 overflow-visible"
    >
      <AvatarImage
        :src="account.profileImageUrl"
        draggable="false"
        alt="PF"
        :class="{
          grayscale: !selected,
        }"
        class="h-12 w-12 select-none rounded-full"
      />
      <AvatarFallback class="h-14 w-14" alt="default user">
        <div class="flex items-center justify-center h-full w-full text-3xl font-semibold pt-0.5 text-white bg-gray-500 rounded-full">
          {{ account.displayName[0] }}
        </div>
      </AvatarFallback>
      <connection-type-icon
        width="12"
        height="12"
        size="w-6 h-6"
        position="absolute -bottom-1 -right-0"
        :connection-type="connectionType"
      />
      <span
        v-if="account.state === 'active' && account.hasAccess"
        :class="{
          'scale-100 opacity-100': selected,
          'scale-50 opacity-0': !selected,
        }"
        class="sca absolute -left-1 -top-1 flex h-6 w-6 transform items-center justify-center rounded-full bg-green-700 text-white transition"
      >
        <check-mark-icon width="12" height="12" class="fill-current" />
      </span>
      <span
        v-else
        :class="{
          'scale-100 opacity-100': selected,
          'scale-50 opacity-0': !selected,
        }"
        class="absolute -left-1 -top-1 flex h-6 w-6 transform items-center justify-center rounded-full bg-red-700 text-white transition"
      >
        <IconSaxInfoCircle width="18" height="18" class="stroke-current" />
      </span>
    </Avatar>
    <span
      :title="account.displayName"
      class="mt-2 text-wrap h-8 line-clamp-2 hyphens-auto break-words overflow-hidden text-center pr-1 text-xs"
      :class="{
        'opacity-50': !selected,
        'opacity-100': selected,
      }"
    >
      {{ account.displayName }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import ConnectionTypeIcon from '../Connections/List/ConnectionTypeIcon.vue'
import CheckMarkIcon from '../../Icons/CheckMarkIcon.vue'
import type { ConnectionType } from '@/enums/connectionTypes'
import type { AccountState } from '@/apis/streamladder-accounts/model'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

defineProps<{
  account: {
    id: string
    displayName: string
    profileImageUrl: string
    state: AccountState
    hasAccess: boolean
  }
  connectionType: ConnectionType
  selected: boolean
}>()

const emit = defineEmits<{
  (event: 'click'): void
}>()
</script>
