<script setup lang="ts">
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import IconSaxVolumeHigh from '@/components/Icons/iconsax/IconSaxVolumeHigh.vue'
import IconSaxVolumeSlash from '@/components/Icons/iconsax/IconSaxVolumeSlash.vue'
import { computed, ref } from 'vue'
import IconSaxVolumeLow from '@/components/Icons/iconsax/IconSaxVolumeLow.vue'
import IconSaxVolumeLow1 from '@/components/Icons/iconsax/IconSaxVolumeLow1.vue'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { Slider } from '@/components/ui/slider';
import { clamp } from 'lodash-es';
import { Keyboard } from '@/components/ui/keyboard';

const isMobile = useIsMobile();

const videoStore = useVideoStore()

const isShowingVolumeSlider = ref(false)

const localVolume = computed<number[]>({
  get: () => {
    if (videoStore.muted) {
      return [0];
    } else {
      return [clamp(videoStore.volume * 100, 0, 100)];
    }
  },
  set: ([value]) => {
    videoStore.muted = value === 0;
    videoStore.volume = value / 100;
  },
});
</script>

<template>
  <div
    class="relative flex items-center justify-start"
    @mouseleave="isShowingVolumeSlider = false"
    :class="{ 'ml-16': isShowingVolumeSlider, 'bg-zinc-200 dark:bg-surface-panel-50 rounded-r-2xl': isMobile }"
  >
    <Transition name="fade">
      <div
        v-if="isShowingVolumeSlider || isMobile"
        class="absolute right-full flex h-full w-20 items-center justify-start overflow-hidden py-2"
        :class="{ 'bg-secondary rounded-l-2xl': isMobile }"
      >
        <Slider
          v-model="localVolume"
          :min="0" :max="100" :step="1"
          class="w-20 mx-2 text-foreground"
          size="sm"
          @mouseenter="isShowingVolumeSlider = true"
          @mouseleave="isShowingVolumeSlider = false"
        />
        <!--        <input class="mx-2 cursor-pointer range range-xs range-primary" type="range" v-model.number="localVolume" min="0" max="100" />-->
      </div>
    </Transition>

    <Tooltip disableHoverableContent>
      <TooltipTrigger>
        <Button
          variant="ghost"
          size="sm"
          class="px-0 w-8 flex items-center justify-center"
          @click="videoStore.muted = !videoStore.muted"
          @mouseenter="isShowingVolumeSlider = true"
        >
          <IconSaxVolumeSlash v-if="videoStore.muted || videoStore.volume === 0" class="w-4" />
          <IconSaxVolumeHigh v-else-if="videoStore.volume > 0.5" class="w-4" />
          <IconSaxVolumeLow v-else-if="videoStore.volume < 0.25" class="w-4" />
          <IconSaxVolumeLow1 v-else class="w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {{ videoStore.muted ? 'Unmute' : 'Mute' }} <Keyboard event="mute/unmute" />
      </TooltipContent>
    </Tooltip>
  </div>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: width 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  width: 0;
}
</style>
