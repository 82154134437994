import NotFound from '@/pages/NotFoundPage.vue'
import DownloadResult from '@/pages/DownloadResultPage.vue'
import LayoutsPage from '@/components/LayoutSelection/LayoutsPage.vue'
import LoginAccessDenied from '@/components/LoginAccessDenied.vue'
import ConnectionSuccess from '@/pages/auth/ConnectionSuccess.vue'
import LoginPage from '@/pages/LoginPage.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useUserInfoStore } from '@/store/user/userInfo'
import { areas } from '@/areas/router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import ClipEditorPage from '@/pages/ClipEditorPage.vue'
import EditorRoot from '@/pages/editor/EditorRoot.vue'
import SignInCallback from '@/pages/auth/SignInCallbackPage.vue'
import Index from '@/pages/Index.vue'
import ServerSideRenderStatusPage from '@/webcodec-renderer/pages/ServerSideRenderStatusPage.vue'
import ClientSideRenderPage from '@/webcodec-renderer/pages/ClientSideRenderPage.vue'

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Index,
    meta: { label: 'Home' },
  },
  {
    name: 'ClipEditor',
    path: '/clip-editor',
    component: ClipEditorPage,
  },
  {
    path: '/scheduler',
    redirect: '/content-publisher',
  },
  {
    // For New Editor users, this route is redirected in the EditorRoot component.
    path: `/:source(twitch-clip|twitch-vod|local-file|youtube-clip|kick-clip|kick-cx-clip)/:clipId`,
    component: EditorRoot,
    children: [
      {
        path: '',
        name: `editor`,
        component: LayoutsPage,
      },
      {
        path: 'layouts/:layout',
        name: `editor/cropping`,
        component: () => import('@/pages/editor/CroppingPage.vue'),
      },
      {
        path: 'layouts/:layout/preview',
        name: `editor/main`,
        component: () => import('@/pages/editor/EditorPage.vue'),
      },
    ],
    props: (route) => ({
      clipId: route.path.split('/')[2],
      source: route.path.split('/')[1],
    }),
  },
  {
    path: '/video-renderer',
    name: 'ClientSideRenderPage',
    component: ClientSideRenderPage,
    props: true,
  },
  {
    path: '/server-generate-queue',
    name: 'ServerSideRenderStatusPage',
    component: ServerSideRenderStatusPage,
  },
  {
    path: '/download-result/',
    name: 'DownloadResult',
    component: DownloadResult,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: () => import('@/pages/UpgradePage.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    children: [
      {
        path: '',
        name: 'AccountSettings',
        redirect: { name: dashboardRouteNames.account.settings },
      },
      {
        name: 'Invoicing',
        path: 'invoicing',
        redirect: { name: dashboardRouteNames.account.invoicing },
      },
      {
        name: 'Referrals',
        path: '/account/referrals',
        component: () => import('@/pages/account/referrals/ReferralsPage.vue'),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/subscription/updated',
    component: () => import('@/pages/account/cancel/SubscriptionUpdated.vue'),
    name: 'SubscriptionUpdated',
  },
  {
    path: '/account/videos',
    name: 'MyVideosPage',
    redirect: { name: dashboardRouteNames.videos },
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/account/templates',
  //   name: 'SavedTemplatesPage',
  //   component: SavedTemplatesPage,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/login-access-denied',
    name: 'LoginAccessDenied',
    component: LoginAccessDenied,
  },
  {
    path: '/sticker-render',
    name: 'StickerRender',
    component: () => import('@/pages/internal/StickerRender.vue'),
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/overlay-test/:renderId',
    name: 'OverlayTest',
    component: () => import('@/pages/internal/StickerRender.vue'),
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/font-line-height-calculator',
    name: 'FontLineHeightCalculator',
    component: () => import('@/pages/internal/FontLineHeightCalculator.vue'),
  },
  {
    path: '/feature-promo-content',
    name: 'FeaturePromoContent',
    component: () => import('@/pages/internal/FeaturePromoContent.vue'),
  },
  {
    path: '/design-system',
    name: 'DesignSystem',
    component: () => import('@/pages/internal/DesignSystemPage.vue'),
  },
  // {
  //   path: '/caption-manager',
  //   name: 'CaptionManager',
  //   component: () => import('@/pages/internal/CaptionManagerPage.vue'),
  // },
  {
    path: '/video-support',
    name: 'VideoSupport',
    component: () => import('@/pages/VideoSupport.vue'),
  },
  {
    path: '/video-repair',
    name: 'VideoRepair',
    component: () => import('@/pages/VideoRepair.vue'),
  },
  {
    path: '/video-repair-popup',
    name: 'VideoRepairPopup',
    component: () => import('@/webcodec-renderer/repair-tool/VideoRepairPopup.vue'),
    props: true
  },
  {
    path: '/video-repair-generate-page',
    name: 'ClientSideRenderRepairPage',
    component: () => import('@/webcodec-renderer/repair-tool/repair-pages/ClientSideRenderRepairPage.vue'),
    props: true
  },
  {
    path: '/editor-startup-repair-page',
    name: 'EditorStartupRepairPage',
    component: () => import('@/webcodec-renderer/repair-tool/repair-pages/EditorStartupRepairPage.vue'),
    props: true
  },
  {
    path: '/support/form',
    redirect: { name: dashboardRouteNames.support },
  },
  // {
  //   path: '/login-success',
  //   name: 'LoginSuccess',
  //   component: LoginSuccess,
  //   meta: {
  //     preventUserInfoLoad: true,
  //   },
  // },
  {
    path: '/signin-callback',
    name: 'SignInCallback',
    component: SignInCallback,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/ref/:code',
    name: 'ReferralsLandingPage',
    component: () => import('@/pages/ReferralsLandingPage.vue'),
  },
  {
    path: '/connection-success',
    name: 'ConnectionSuccess',
    component: ConnectionSuccess,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  ...areas,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { el: to.hash }
    }
    return { top: 0 }
  },
  routes,
})

// affiliate code guard
router.beforeEach((to, from, next) => {
  const refCode = to.query.ref
  if (refCode && typeof refCode === 'string') {
    localStorage.setItem('refCode', refCode)
  }
  return next()
})

// authentication guard
router.beforeEach(async (to, from, next) => {
  const userInfoStore = useUserInfoStore()
  // Check if the route requires auth. If it does not, continue.
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the userDetails have been loaded. If not, load them except if the route is marked to prevent it.
    if (!userInfoStore.hasLoadedUserInfo && !to.matched.some((record) => record.meta.preventUserInfoLoad))
      userInfoStore.updateUserInfo()
    return next()
  }

  // Check if the userDetails have been loaded. If not, load them.
  if (!userInfoStore.hasLoadedUserInfo) await userInfoStore.updateUserInfo()
  if (!userInfoStore.isAuthenticated) {
    // User is not authenticated, redirect to login page
    return next({ name: 'Login', query: { redirect: to.fullPath } })
  }

  // User is authenticated, continue
  return next()
})

export { router }
