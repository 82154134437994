<script setup lang="ts">
import { ref, onUnmounted, onMounted, computed } from 'vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Skeleton } from '@/components/ui/skeleton'
import RecentClipGptProjectCard from '@/areas/dashboard/pages/clipGPT/RecentClipGptProjectCard.vue'
import { Pagination, PaginationList, PaginationListItem, PaginationPrev, PaginationNext, PaginationFirst, PaginationLast, PaginationEllipsis } from '@/components/ui/pagination'
import { Button } from '@/components/ui/button'
import { useScreenName } from '@/Hooks/useScreen'
import { useClipGptProjects } from '@/areas/dashboard/pages/clipGPT/useClipGptProjects'

const timeout = 60_000;
const interval = ref<NodeJS.Timeout>()

const  { projects, fetchRecentProjects, isFetching } = useClipGptProjects()

async function forceReload() {
  clearInterval(interval.value);
  interval.value = setInterval(fetchRecentProjects, timeout);
  await fetchRecentProjects();
}

onMounted(() => {
  forceReload();
  window.addEventListener('focus', forceReload);
})

onUnmounted(() => {
  clearInterval(interval.value);
  window.removeEventListener('focus', forceReload);
})

// grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-3 2xl:grid-cols-4
const screenSizeName = useScreenName()
const itemsPerRow = computed(() => {
  switch (screenSizeName.value) {
    case 'lg':
    case 'xl':
      return 3;
    case '2xl':
      return 4;
    default:
      return 2;
  }
})

const itemsPerPage = computed(() => itemsPerRow.value * 4)
const pages = computed(() => Math.ceil(projects.value.length / itemsPerPage.value))

const skip = ref(0)
const pageNumber = computed({
  get: () => {
    return Math.floor(skip.value / itemsPerPage.value) + 1;
  },
  set: (value: number) => {
    skip.value = (value - 1) * itemsPerPage.value;
  }
})

const projectsOnPage = computed(() => {
  const skip = (pageNumber.value - 1) * itemsPerPage.value;
  const take = itemsPerPage.value;
  return projects.value.slice(skip, skip + take);
})
</script>

<template>
  <section class="flex flex-col gap-2">
    <h2 class="mb-0 text-2xl font-normal text-brand-state-text-primary">
      Recent projects
    </h2>
    
    <div class="grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-3 2xl:grid-cols-4" v-if="projects.length > 0 || isFetching">
      <template v-if="isFetching">
        <div class="flex flex-col rounded-xl p-1 gap-1 bg-surface-panel-bg layer-2" v-for="i in 12" :key="i">
          <Skeleton class="aspect-video w-full rounded-lg" />
          <header class="p-1 flex flex-col">
            <Skeleton class="h-4 w-2/3 mt-2 mb-1" />
            <Skeleton class="h-3 w-1/2" />
          </header>
        </div>
      </template>
      <template v-for="project in projectsOnPage" :key="project.id">
        <RecentClipGptProjectCard :project="project" @delete="forceReload" />
      </template>
    </div>
    
    <div class="layer-2 flex w-full flex-col items-center gap-6 rounded-xl px-10 py-20" v-else>
      <LottieAnimation class="w-32" url="/lottie/empty.json" :auto-play="true" :loop="true" />

      <header class="flex flex-col items-center">
        <h3 class="text-center text-xl font-semibold">
          Your projects will show up here
        </h3>
        <p class="max-w-[50ch] text-center text-sm font-light">
          Looks like you haven’t generated any clips from your streams yet. Paste a VOD link or search for a streamer to get started!
        </p>
      </header>

      <slot />
    </div>

    <Pagination class="mt-4 mx-auto" v-slot="{ page }" :total="projects.length" :items-per-page="itemsPerPage" :sibling-count="1" show-edges :default-page="1" v-if="pages > 1" v-model:page="pageNumber">
      <PaginationList v-slot="{ items }" class="flex items-center gap-1">
        <PaginationFirst v-if="items.length > 3" />
        <PaginationPrev />

        <template v-for="(item, index) in items">
          <PaginationListItem v-if="item.type === 'page'" :key="index" :value="item.value" as-child>
            <Button class="w-10 h-10 p-0" :variant="item.value === page ? 'default' : 'outline'">
              {{ item.value }}
            </Button>
          </PaginationListItem>
          <PaginationEllipsis v-else :key="item.type" :index="index" />
        </template>

        <PaginationNext />
        <PaginationLast v-if="items.length > 3" />
      </PaginationList>
    </Pagination>
  </section>
</template>

<style scoped lang="scss">

</style>
