import { useRouter } from "vue-router";
import { useDeploymentVersion } from "./useDeploymentVersion";

export async function useAutoReloadOnNewVersion() {

    const deploymentVersionQuery = useDeploymentVersion()
    const router = useRouter();
    

    let isInitialPageLoad = true;

    router.beforeEach((guard) => {
      if (deploymentVersionQuery.data.value?.hasUpdateReady && !isInitialPageLoad) {
        window.location.href = guard.fullPath;
      }
  
      isInitialPageLoad = false;
      return true;
    });
}