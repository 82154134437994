<script setup lang="ts">
import { type TranscriptWord, type TranscriptWordEffect, useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { CaptionPresetVariant } from '@/components/Captions/v3/CaptionPreset'
import { ColorInput } from '@/components/colors'
import IconSaxActivity from '@/components/Icons/iconsax/IconSaxActivity.vue'
import IconSaxAddSquare from '@/components/Icons/iconsax/IconSaxAddSquare.vue'
import IconSaxBlur from '@/components/Icons/iconsax/IconSaxBlur.vue'
import IconSaxMagicStar from '@/components/Icons/iconsax/IconSaxMagicStar.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { onClickOutside } from '@vueuse/core'
import { v4 as uuid } from 'uuid'

const captionsStore = useCaptionsStore();
const videoStore = useVideoStore();
const historyStore = useHistoryStore();

const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors();

const addCaption = () => {

  if (!canAdd.value) {
    return;
  }

  const captionRight = captionsStore.getCaptionAtTime(videoStore.currentTimeMs + 500);

  let newCaption = null;

  const currentTimeMs = videoStore.currentTimeMs;
  historyStore.transaction('CAPTIONS:ADD', () => {
    if (captionRight) {
      newCaption = captionsStore.addCaption(currentTimeMs, captionRight.words[0].start);
    } else {
      newCaption = captionsStore.addCaption(currentTimeMs, currentTimeMs + 500);
    }
  });

  if (newCaption) {
    captionsStore.captionIdHighlighted = newCaption.id;
    captionsStore.captionIdBeingEdited = newCaption.id;
    captionsStore.wordIdBeingEdited = newCaption.words[0].id;
  }
};

const canAdd = computed(() => {
  return captionsStore.canAddNewCaptionAtTime(videoStore.currentTimeMs);
});

const editCaption = () => {
  const currentTimeMs = videoStore.currentTimeMs;
  const caption = captionsStore.getCaptionAtTime(currentTimeMs);
  if (caption) {
    captionsStore.captionIdBeingEdited = caption.id;
    captionsStore.captionIdHighlighted = caption.id;
    videoStore.playing = false;
    videoStore.preservedPaused = true;
  }
};

const deleteWordAtTime = () => {
  const currentTimeMs = videoStore.currentTimeMs;
  historyStore.transaction('CAPTIONS:DELETE_WORDS', () => {
    captionsStore.deleteWordAtTime(currentTimeMs);
  });
};

const wordAtCurrentTime = computed(() => {
  return captionsStore.wordAtTime(videoStore.currentTimeMs);
});

const colorPicker = ref<HTMLDivElement|null>(null);
const showColorPickerFor = ref<TranscriptWord[]>([]);

onClickOutside(colorPicker, () => {
  showColorPickerFor.value = [];
});

const removeEffectsFrom = (selectedWords: TranscriptWord[], type: string | null = null) => {

  const ids = selectedWords.map(w => w.id);

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:REMOVE_EFFECTS', () => {
      captionsStore.removeEffectsFromWordsByIds(ids, type);
    });
  }
};

const supersizeSelectedWords = (selectedWords: TranscriptWord[] | undefined) => {

  if (!selectedWords) {
    return;
  }

  const ids = selectedWords.map(w => w.id);

  const effect: TranscriptWordEffect = {
    id: uuid(),
    type: 'supersize',
    animation: captionsStore.baseOptions.animation,
    size: 'large',
  };

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:ADD_SUPERSIZE_EFFECT', () => {
      captionsStore.addEffectToWordsByIds(ids, effect);
    });
  }
};

const shakeSelectedWords = (selectedWords: TranscriptWord[]) => {

  const ids = selectedWords.map(w => w.id);

  const effect: TranscriptWordEffect = {
    id: uuid(),
    type: 'shake',
    animation: 'float-around',
    size: 'medium',
  };

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:ADD_SHAKE_EFFECT', () => {
      captionsStore.addEffectToWordsByIds(ids, effect);
    });
  }
};

const currentColor = computed<string>({
  get: () => {
    const firstCaptionVariant = showColorPickerFor.value?.[0]?.captionVariant as CaptionPresetVariant;
    if (firstCaptionVariant && !firstCaptionVariant.font?.color?.variant) {
      return firstCaptionVariant.font.color as string;
    } else {
      return captionsStore.baseCaptionPreset?.font?.color as string ?? '#ffffff';
    }
  },
  set: (value: string) => {
    commitColorChange(showColorPickerFor.value, value);
  }
})

const commitColorChange = (selectedWords: TranscriptWord[], color = '#ffffff') => {

  if (selectedWords.length === 0) {
    return;
  }

  historyStore.transaction('CAPTIONS:CHANGE_COLOR', () => {
    captionsStore.updateWordsColor(selectedWords, color);
  });
};

const showColorPicker = () => {
  if (wordAtCurrentTime.value) {
    showColorPickerFor.value = [wordAtCurrentTime.value];
  }
};
</script>

<template>
  <Tooltip disable-hoverable-content>
    <TooltipTrigger as-child :class="{ 'opacity-50 !cursor-not-allowed': !canAdd }">
      <Button
        :disabled="videoStore.playing"
        variant="ghost"
        size="sm"
        @click="addCaption"
      >
        <IconSaxAddSquare class="w-4 h-4" />
      </Button>
    </TooltipTrigger>
    <TooltipContent>
      <span>{{ canAdd ? 'Add a new caption at current time' : 'Can\'t add a new caption, there\'s already a caption at the current time' }}</span>
    </TooltipContent>
  </Tooltip>

  <Tooltip disable-hoverable-content>
    <TooltipTrigger
      :class="{ 'opacity-50 !cursor-not-allowed': !wordAtCurrentTime || videoStore.playing }"
    >
      <ColorInput
        v-model="currentColor"
        :recently-used-colors="recentlyUsedCaptionColors"
        :recommended-colors="recommendedColors.LIGHT"
        side="right"
        @close="(color: string) => { showColorPickerFor = []; setRecentlyUsedColor(color) }"
      >
        <Button
          :disabled="videoStore.playing"
          tabindex="-1"
          variant="ghost"
          size="sm"
          @mousedown.prevent
          @click="showColorPicker"
          class="relative"
        >
          <IconSaxBlur class="stoke-[1] h-4 w-4" />
          <div
            class="absolute w-1/2 h-0.5 bottom-0 rounded"
            :style="`background-color: ${ wordAtCurrentTime?.captionVariant?.font?.color || 'transparent' }`"
          />
        </Button>
      </ColorInput>
    </TooltipTrigger>
    <TooltipContent>
      {{ (!wordAtCurrentTime || videoStore.playing) ? 'Can\'t change color, there\'s no caption at the current time' : 'Change color' }}
    </TooltipContent>
  </Tooltip>

  <template v-if="wordAtCurrentTime && wordAtCurrentTime?.effects?.some(e => e.type === 'supersize')">
    <Tooltip disable-hoverable-content>
      <TooltipTrigger as-child :class="{ 'opacity-50 !cursor-not-allowed': !wordAtCurrentTime || videoStore.playing }">
        <Button
          :disabled="videoStore.playing"
          tabindex="-1"
          variant="ghost"
          size="sm"
          @mousedown.prevent
          @click.prevent.stop="removeEffectsFrom([wordAtCurrentTime], 'supersize')"
          class="bg-purple-100 dark:bg-company-primary-500"
        >
          <IconSaxMagicStar class="stoke-[1] h-4 w-4 text-company-primary-100 dark:text-purple-300" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {{ 'Remove supersize' }}
      </TooltipContent>
    </Tooltip>
  </template>
  <template v-else>
    <Tooltip disable-hoverable-content>
      <TooltipTrigger as-child :class="{ 'opacity-50 !cursor-not-allowed': !wordAtCurrentTime || videoStore.playing }">
        <Button
          :disabled="videoStore.playing"
          tabindex="-1"
          variant="ghost"
          size="sm"
          @mousedown.prevent
          @click.prevent.stop="supersizeSelectedWords([wordAtCurrentTime])"
        >
          <IconSaxMagicStar class="stoke-[1] h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {{ (!wordAtCurrentTime || videoStore.playing) ? 'Can\'t add supersize effect, there\'s no caption at the current time' : 'Supersize' }}
      </TooltipContent>
    </Tooltip>
  </template>

  <template v-if="wordAtCurrentTime && wordAtCurrentTime?.effects?.some(e => e.type === 'shake')">
    <Tooltip disable-hoverable-content>
      <TooltipTrigger as-child :class="{ 'opacity-50 !cursor-not-allowed': !wordAtCurrentTime || videoStore.playing }">
        <Button
          :disabled="videoStore.playing"
          tabindex="-1"
          variant="ghost"
          size="sm"
          @mousedown.prevent
          @click.prevent.stop="removeEffectsFrom([wordAtCurrentTime], 'shake')"
          class="bg-purple-100 dark:bg-company-primary-500"
        >
          <IconSaxActivity class="stoke-[1] h-4 w-4 text-company-primary-100 dark:text-purple-300" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {{ 'Remove shake' }}
      </TooltipContent>
    </Tooltip>
  </template>
  <template v-else>
    <Tooltip disable-hoverable-content>
      <TooltipTrigger as-child :class="{ 'opacity-50 !cursor-not-allowed': !wordAtCurrentTime || videoStore.playing }">
        <Button
          :disabled="videoStore.playing"
          tabindex="-1"
          variant="ghost"
          size="sm"
          @mousedown.prevent
          @click.prevent.stop="shakeSelectedWords([wordAtCurrentTime])"
        >
          <IconSaxActivity class="stoke-[1] h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {{ (!wordAtCurrentTime || videoStore.playing) ? 'Can\'t add shake effect, there\'s no caption at the current time' : 'Shake' }}
      </TooltipContent>
    </Tooltip>
  </template>

  <Tooltip disable-hoverable-content>
    <TooltipTrigger as-child :class="{ 'opacity-50 !cursor-not-allowed': !wordAtCurrentTime || videoStore.playing }">
      <Button
        :disabled="videoStore.playing"
        variant="ghost"
        size="sm"
        @click="deleteWordAtTime"
      >
        <IconSaxTrash class="w-4 h-4" :class="{ 'text-red-500': wordAtCurrentTime }" />
      </Button>
    </TooltipTrigger>
    <TooltipContent>
      <span v-if="wordAtCurrentTime">Remove word at current time</span>
      <span v-else>There is no word at current time to remove</span>
    </TooltipContent>
  </Tooltip>
</template>

<style scoped></style>