<script setup lang="ts">
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { ref, computed, watch } from 'vue'
import IconSaxSubtitle from '@/components/Icons/iconsax/IconSaxSubtitle.vue'
import IconSaxSticker from '@/components/Icons/iconsax/IconSaxSticker.vue'
import { Button } from '@/components/ui/button'
import IconSaxExport from '@/components/Icons/iconsax/IconSaxExport.vue'
import IconSaxCrop from '@/components/Icons/iconsax/IconSaxCrop.vue'
import Timeline from '@/areas/editor/timeline/Timeline.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { useHead } from '@unhead/vue'
import { useTheme } from '@/Hooks/useTheme'
import '../index.css'
import { TooltipProvider } from '@/components/ui/tooltip'
import { useIsMobile } from '@/Hooks/useIsMobile'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useResizeObserver } from '@vueuse/core'
import { useUserInfoStore, type OutboundSocialAccount } from '@/store/user/userInfo'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import IconSaxHomeTrendUp from '@/components/Icons/iconsax/IconSaxHomeTrendUp.vue'
import IconSaxStar from '@/components/Icons/iconsax/IconSaxStar.vue'
import IconSaxLogoutCurve from '@/components/Icons/iconsax/IconSaxLogoutCurve.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import IconSaxArrowLeft2 from '@/components/Icons/iconsax/IconSaxArrowLeft2.vue'
import IconSaxLoginCurve from '@/components/Icons/iconsax/IconSaxLoginCurve.vue'
import ExportButton from '@/areas/editor/workspaces/ExportButton.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import IconSaxEmojiHappy from '@/components/Icons/iconsax/IconSaxEmojiHappy.vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { isGiphySticker, useStickersStore } from '@/areas/editor/store/useStickersStore'
import { supabase } from '@/authentication/supabase';
import { useRouter } from 'vue-router';
import { tiers } from '@/enums/tiers'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import Spinner from '@/components/Icons/Spinner.vue'
import { useGenerateCaptions } from '@/areas/editor/views/captions/useGenerateCaptions'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import IconSaxSound from '@/components/Icons/iconsax/IconSaxSound.vue'
import { queryClient } from '@/services/QueryClient';
import ChatPluginSlotMobile from '@/areas/editor/slots/ChatPluginSlotMobile.vue';

const clipInfoStore = useEditorClipInfoStore()
const { theme } = useTheme()
useHead({
  bodyAttrs: {
    class: [theme],
  },
  htmlAttrs: {
    class: 'prevent-overscroll'
  }
})

const {
  direction,
  component: Step, currentStep,
  isLayoutsStep,
  isCaptionsStep,
  isEffectsStep,
  currentSection,
  steps, stepIndex,
  isBottomPanelOpen,
  cropperOrPreviewSwitchMobile,
  isRiveStep,
  resetEditorStep
} = useEditorStep()

const isClipReady = computed(() => clipInfoStore.id && !clipInfoStore.loadingState)

const isMobile = useIsMobile()

const nav = ref<HTMLElement | null>(null)
const navWidth = ref(nav.value?.scrollWidth)
useResizeObserver(nav, () => navWidth.value = nav.value?.scrollWidth)

const editorFocusStore = useEditorFocusStore()

const scrollNavTo = (side: 'left' | 'right') => {
  if (nav.value) {
    nav.value?.scrollTo({
      left: side === 'left' ? 0 : navWidth.value,
      behavior: 'smooth'
    });
  }
};

const userInfoStore = useUserInfoStore()
const account = computed(() => {
  const googleAccount = userInfoStore.googleAccount as OutboundSocialAccount
  const twitchAccount = userInfoStore.twitchAccount as OutboundSocialAccount
  return userInfoStore.allSocials.find((s) => s.profileImageUrl) ?? googleAccount ?? twitchAccount ?? null
})

const historyStore = useHistoryStore()
const isLoggingOut = ref(false)

const onLogout = async () => {
  isLoggingOut.value = true

  await supabase.auth.signOut()

  const userInfoStore = useUserInfoStore()
  await userInfoStore.updateUserInfo()
  await queryClient.invalidateQueries()
  window.location.href = router.resolve({ name: 'editor' }).href

  isLoggingOut.value = false
};

const accountMenuOpen = ref(false)

// Cheeky way to re-mount the components after authenticating
watch(() => userInfoStore.isAuthenticated, () => {
  if (!isLayoutsStep.value) {
    isBottomPanelOpen.value = false
  }
});

// We want to show certain elements like layout drawer to be full screen when the user first visits the editor
const initialVisit = ref(true)
watch(currentStep, () => {
  if (initialVisit.value) {
    initialVisit.value = false;
  }
})

const videoStore = useVideoStore()
const editorCaptionsStore = useEditorCaptionsStore()

watch(() => editorCaptionsStore.isEditingOnMobile, () => {

  if (editorCaptionsStore.isEditingOnMobile) {

    isBottomPanelOpen.value = true
    currentStep.value = editorRouteNames.captions

    if (videoStore.playing) {
      videoStore.playing = false
    }
  } else {
    if (!videoStore.playing) {
      videoStore.playing = true
    }
  }
})

watch(isBottomPanelOpen, () => {
  if (isBottomPanelOpen.value) {
    editorFocusStore.unFocus()
  } else if (initialVisit.value) {
    initialVisit.value = false;
  } else {
    editorCaptionsStore.isEditingOnMobile = false;
    currentSection.value = null;
  }
})

watch(() => editorFocusStore.focus, () => {
  if (editorFocusStore.focus && initialVisit.value) {
    initialVisit.value = false;
    isBottomPanelOpen.value = false;
  }
  if (editorFocusStore.focus && !isLayoutsStep.value && !isCaptionsStep.value && !isEffectsStep.value && !isRiveStep.value) {
    isBottomPanelOpen.value = false
  }
  if (isBottomPanelOpen.value && isCaptionsStep.value) {
    editorFocusStore.unFocus()
  }
}, { immediate: true })

const stickerStore = useStickersStore();

watch(() => editorFocusStore.focus, () => {

  if (isLayoutsStep.value) {
    return
  }

  const focusType = editorFocusStore.focus?.type
  if (focusType === FocusTypes.TEXTSTICKER) {
    currentStep.value = editorRouteNames.elements
    currentSection.value = editorRouteNames.text
  } else if (focusType === FocusTypes.CAPTION) {
    currentStep.value = editorRouteNames.captions
  } else if (focusType === FocusTypes.STICKER) {
    const sticker = stickerStore.selectById(editorFocusStore!.focus!.key!)
    currentStep.value = editorRouteNames.elements
    if (isGiphySticker(sticker)) {
      currentSection.value = editorRouteNames.gifs
    } else {
      currentSection.value = editorRouteNames.stickers
    }
  } else if (focusType === FocusTypes.SEGMENT) {
    currentStep.value = editorRouteNames.layouts
  } else if (focusType === FocusTypes.ZOOM) {
    currentStep.value = editorRouteNames.effects
    currentSection.value = editorRouteNames.zooms
  } else if (focusType === FocusTypes.SOUND) {
    currentStep.value = editorRouteNames.sounds
    currentSection.value = editorRouteNames.editSounds
  } else if (focusType === FocusTypes.RIVE) {
    currentStep.value = editorRouteNames.elements;
    currentSection.value = editorRouteNames.rive;
  }
}, { deep: true })

const router = useRouter()
async function newProject() {
  resetEditorStep();
  await historyStore.$reset();
  await router.push({ name: 'editor' });
}

const captions = useGenerateCaptions()
</script>

<template>
  <TooltipProvider :delay-duration="0">
    <div v-if="isMobile" class="flex flex-col content-end h-[100dvh] w-screen layer-0 text-brand-state-text-primary dark:bg-[#1e1e1e]" :class="{ 'content-between': isClipReady }">
      <header
        v-if="isClipReady"
        class="bg-gradient-to-l -from-[12.32%] from-[#A000FE] to-[97.88%] to-[#6080FE] z-[50] flex justify-between"
      >
        <Popover v-model:open="accountMenuOpen" v-if="userInfoStore.isLoggedIn">
          <div class="flex justify-center items-center gap-0.5">
            <PopoverTrigger as-child class="mt-auto mx-auto">
              <Button
                size="sm" variant="ghost"
                class="flex-row text-white h-[2.5rem] hover:bg-white/5 max-w-[60%] items-center p-2 m-0"
              >
                <Avatar class="w-7 h-7">
                  <AvatarImage :src="account?.profileImageUrl" />
                </Avatar>
              </Button>
            </PopoverTrigger>
            <RouterLink to="/">
              <Button
                size="sm" variant="ghost"
                class="text-white hover:bg-white/20 flex-col items-center px-0"
              >
                <span class="text-lg mt-1">StreamLadder</span>
              </Button>
            </RouterLink>

            <GoldPlanButton v-if="userInfoStore.tier === tiers.GOLD" class="mt-0.5 ml-1 w-4 h-4" :can-click="false" :force-show="true" :small="true" />
            <SilverPlanButton v-else-if="userInfoStore.tier === tiers.SILVER" class="mt-0.5 ml-1 w-4 h-4" :can-click="false" :force-show="true" :small="true" />

          </div>
          <PopoverContent align="start" side="bottom" class="p-1 rounded-none border-none w-screen">
            <nav class="flex flex-col items-stretch gap-1">
              <span class="pl-4 flex items-center gap-2 w-full border-b border-b-accent text-center py-2 -mt-0.5 text-base truncate overflow-hidden">
                Hi, {{ userInfoStore.userName }}
              </span>
              <RouterLink :to="{ name: dashboardRouteNames.dashboard }">
                <Button class="justify-start w-full" variant="ghost">
                  <IconSaxHomeTrendUp class="w-4 h-4" /> Dashboard
                </Button>
              </RouterLink>
              <RouterLink :to="{ name: dashboardRouteNames.account.settings }">
                <Button class="justify-start w-full" variant="ghost">
                  <IconSaxEmojiHappy class="w-4 h-4" /> My Account
                </Button>
              </RouterLink>
              <Button
                class="justify-start w-full"
                @click="accountMenuOpen = false; newProject()"
                variant="ghost"
              >
                <IconSaxStar class="w-4 h-4" /> New project
              </Button>
              <Button class="justify-start w-full" variant="ghost" @click="onLogout">
                <template v-if="isLoggingOut">
                  <div class="border-2 border-current border-t-transparent rounded-full h-4 w-4 animate-spin mr-2.5" />
                  Logging out..
                </template>
                <template v-else>
                  <IconSaxLogoutCurve class="h-4 w-4 shrink-0 rotate-180" />
                  Logout
                </template>
              </Button>
            </nav>
          </PopoverContent>
        </Popover>

        <template v-if="!userInfoStore.isLoggedIn">
          <ChatPluginSlotMobile class="shrink-0 w-6 ml-auto mr-2" />
          <RouterLink to="/">
            <Button
              size="sm" variant="ghost"
              class="h-full text-white hover:bg-white/20 flex-col items-center py-0 px-2"
            >
              <span class="text-lg mt-1">StreamLadder</span>
            </Button>
          </RouterLink>
          <LoginDialog>
            <button class="ml-auto flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-none px-3 py-2 text-white outline-none transition-colors hover:bg-indigo-600 focus:bg-indigo-600">
              <IconSaxLoginCurve class="h-4 w-4 shrink-0 [&>path]:stroke-1" />
              Login
            </button>
          </LoginDialog>
        </template>
        <template v-else>
          <ChatPluginSlotMobile class="shrink-0 w-6 ml-auto mr-2" />
          <Button
            :disabled="isBottomPanelOpen && currentStep === editorRouteNames.export"
            size="sm" variant="ghost" @click="isBottomPanelOpen = true; currentStep = editorRouteNames.export; scrollNavTo('right')"
            class="bg-white/20 hover:bg-white/20 rounded-none h-full px-2 text-white flex-row items-center py-1 gap-1"
          >
            <IconSaxExport class="w-4 h-4" />
            <span class="text-sm uppercase mt-0.5">Export</span>
          </Button>
        </template>
      </header>

      <div class="w-full h-full">
        <Transition appear name="fade">
          <div
            v-if="isClipReady && isBottomPanelOpen && (currentStep !== editorRouteNames.layouts || initialVisit)"
            class="absolute top-0 bottom-0 h-full w-full z-[20]"
            :class="isEffectsStep && currentSection === editorRouteNames.zooms ? 'bg-transparent' : 'bg-black/60'"
            @click="isBottomPanelOpen = !isBottomPanelOpen"
          />
        </Transition>
        <RouterView />
        <slot name="router-view" />
      </div>

      <Transition
        enter-from-class="opacity-0 translate-y-20"
        leave-to-class="opacity-0 translate-y-20"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <div
          v-if="isClipReady && isBottomPanelOpen"
          class="w-full border-t-2 dark:border-none dark:bg-surface-panel layer-1 rounded-t-3xl pb-2 bottom-[100px] h-[750px] max-h-[60svh] absolute z-[50] overflow-hidden"
          :class="{
            'max-h-[80dvh]': currentStep === editorRouteNames.export,
            '!max-h-auto !h-auto': currentStep === editorRouteNames.effects && currentSection === editorRouteNames.zooms,
            'max-h-[calc(50dvh-65px)]': !initialVisit && currentStep === editorRouteNames.layouts,
            '!max-h-[calc(100dvh-140px)] !bottom-[100px]': initialVisit && currentStep === editorRouteNames.layouts,
          }"
        >
          <Transition
            :enter-from-class="direction === 'backward' ? 'opacity-0 -translate-x-20' : 'opacity-0 translate-x-20'"
            :leave-to-class="direction === 'backward' ? 'opacity-0 translate-x-20' : 'opacity-0 -translate-x-20'"
            enter-active-class="motion-safe:transition-[transform,_opacity]"
            leave-active-class="motion-safe:transition-[transform,_opacity]"
          >
            <component :is="Step" />
          </Transition>
          <Button size="square" variant="ghost" class="absolute top-2 right-2" @click="isBottomPanelOpen = !isBottomPanelOpen">
            <IconSaxCloseCircle />
          </Button>
        </div>
      </Transition>

      <div class="bottom-0 z-[50]">
        <div v-if="isClipReady && !initialVisit" class="flex flex-col min-w-0 w-[100vw]" :class="{ 'hidden pointer-events-none': isBottomPanelOpen }">
          <div class="min-h-[58px] border-t border-surface-panel-border bg-surface-panel layer-1 overflow-hidden">
            <Timeline step="editor" />
          </div>
        </div>

        <nav v-if="isClipReady" class="bottom-0 w-screen">
          <div ref="nav" class="border-t border-surface-panel-border bg-surface-panel-50 items-center justify-between w-screen px-3 py-1.5 flex flex-row">
            <template v-if="currentStep !== editorRouteNames.layouts">
              <Button
                size="sm"
                variant="outline"
                :class="{ '!opacity-0' : stepIndex === 0 }"
                :disabled="stepIndex === 0"
                class="snap-center gap-1 h-9 hover:bg-transparent flex-row items-center px-4 justify-center transition-opacity"
                @click="() => {
                  isBottomPanelOpen = true;
                  currentStep = steps[stepIndex - 1]?.name ?? steps[0].name
                  if (stepIndex < steps.length / 2) {
                    scrollNavTo('left');
                  }
                }"
              >
                <IconSaxArrowLeft2 class="w-4 h-4" />
                <span class="uppercase mt-0.5 opacity-70">
                  Back
                </span>
              </Button>

              <template v-if="currentStep !== editorRouteNames.export">
                <Button
                  size="sm" variant="gradient"
                  :class="{ '!opacity-0' : stepIndex === steps.length - 1 }"
                  :disabled="stepIndex === steps.length - 1"
                  class="snap-center gap-1 text-white h-9 flex-row items-center px-4 transition-opacity before:content-none"
                  @click="() => {
                    isBottomPanelOpen = true;
                    currentStep = steps[stepIndex + 1].name
                    if (stepIndex >= steps.length / 2) {
                      scrollNavTo('right');
                    }
                  }"
                >
                  <span class="uppercase mt-0.5">
                    Next
                  </span>
                  <IconSaxArrowRight2 class="w-4 h-4" />
                </Button>
              </template>
              <template v-else-if="userInfoStore.isAuthenticated">
                <ExportButton button-position="BelowTimeline" class="snap-center gap-2 text-white h-9 flex-row items-center px-4 transition-opacity before:content-none" />
              </template>
            </template>
            <template v-else>
              <Button
                size="sm"
                variant="outline"
                :class="{ '!opacity-0' : cropperOrPreviewSwitchMobile === 'cropper' }"
                :disabled="cropperOrPreviewSwitchMobile === 'cropper'"
                class="snap-center hover:bg-transparent gap-1 h-9 flex-row items-center px-4 justify-center transition-opacity"
                @click="cropperOrPreviewSwitchMobile = 'cropper'"
              >
                <IconSaxArrowLeft2 class="w-4 h-4" />
                <span class="uppercase mt-0.5 opacity-70">
                  Back
                </span>
              </Button>

              <template v-if="initialVisit">
                <Button
                  size="sm" variant="gradient"
                  :class="{ '!opacity-0' : stepIndex === steps.length - 1 }"
                  :disabled="stepIndex === steps.length - 1"
                  class="snap-center gap-1 text-white h-9 flex-row items-center px-4 transition-opacity before:content-none"
                  @click="isBottomPanelOpen = false"
                >
                  <span class="uppercase mt-0.5">
                    Next
                  </span>
                  <IconSaxArrowRight2 class="w-4 h-4" />
                </Button>
              </template>
              <template v-else-if="cropperOrPreviewSwitchMobile === 'cropper'">
                <Button
                  size="sm" variant="gradient"
                  :class="{ '!opacity-0' : stepIndex === steps.length - 1 }"
                  :disabled="stepIndex === steps.length - 1"
                  class="snap-center gap-1 text-white h-9 flex-row items-center px-4 transition-opacity before:content-none"
                  @click="cropperOrPreviewSwitchMobile = 'preview'"
                >
                  <span class="uppercase mt-0.5">
                    Next
                  </span>
                  <IconSaxArrowRight2 class="w-4 h-4" />
                </Button>
              </template>
              <template v-else>
                <Button
                  size="sm" variant="gradient"
                  :class="{ '!opacity-0' : stepIndex === steps.length - 1 }"
                  :disabled="stepIndex === steps.length - 1"
                  class="snap-center gap-1 text-white h-9 flex-row items-center px-4 transition-opacity before:content-none"
                  @click="() => {
                    currentStep = steps[stepIndex + 1].name
                    isBottomPanelOpen = true;
                    if (stepIndex >= steps.length / 2) {
                      scrollNavTo('right');
                    }
                  }"
                >
                  <span class="uppercase mt-0.5">
                    Next
                  </span>
                  <IconSaxArrowRight2 class="w-4 h-4" />
                </Button>
              </template>
            </template>
          </div>
        </nav>

        <nav v-if="isClipReady" class="border-t border-surface-panel-border bottom-0 overflow-x-auto max-w-full">
          <div ref="nav" class="h-[54px] items-center w-full px-2.5 flex flex-row bg-surface-panel-50 overflow-x-auto snap-x snap-mandatory">
            <div class="flex flex-row gap-2.5 relative w-full justify-evenly">
              <Button
                size="sm" variant="ghost" @click="isBottomPanelOpen = true; currentStep = editorRouteNames.layouts; scrollNavTo('left')"
                class="snap-center gap-0.5 text-black/60 dark:text-white/50 h-12 flex-col items-center w-16 p-0.5 hover:bg-transparent"
                :class="{ '!text-company-primary-50': currentStep === editorRouteNames.layouts }"
              >
                <IconSaxCrop class="w-[22px] h-[22px]" />
                <span
                  class="text-[0.7rem] uppercase"
                >
                  Crop
                </span>
              </Button>
              <Button
                size="sm" variant="ghost" @click="isBottomPanelOpen = true; currentStep = editorRouteNames.elements; scrollNavTo('left')"
                class="snap-center gap-0.5 text-black/60 dark:text-white/50 h-12 flex-col items-center w-16 p-0.5 hover:bg-transparent"
                :class="{ '!text-company-primary-50': currentStep === editorRouteNames.elements }"
              >
                <IconSaxSticker class="w-[22px] h-[22px]" />
                <span
                  class="text-[0.7rem] uppercase"
                >
                  Elements
                </span>
              </Button>
              <Button
                size="sm" variant="ghost" @click="isBottomPanelOpen = true; currentStep = editorRouteNames.effects; scrollNavTo('right')"
                class="snap-center gap-0.5 text-black/60 dark:text-white/50 h-12 flex-col items-center w-16 p-0.5 hover:bg-transparent"
                :class="{ '!text-company-primary-50': currentStep === editorRouteNames.effects }"
              >
                <IconSaxMagicpen class="w-[22px] h-[22px]" />
                <span
                  class="text-[0.7rem] uppercase"
                >
                  Effects
                </span>
              </Button>
              <Button
                size="sm" variant="ghost" @click="isBottomPanelOpen = true; currentStep = editorRouteNames.sounds; scrollNavTo('right')"
                class="snap-center gap-0.5 text-black/60 dark:text-white/50 h-12 flex-col items-center w-16 p-0.5 hover:bg-transparent"
                :class="{ '!text-company-primary-50': currentStep === editorRouteNames.sounds }"
              >
                <IconSaxSound class="w-[22px] h-[22px]" />
                <span
                  class="text-[0.7rem] uppercase"
                >
                  Audio
                </span>
              </Button>
              <Button
                size="sm" variant="ghost" @click="isBottomPanelOpen = true; currentStep = editorRouteNames.captions; scrollNavTo('right')"
                class="snap-center gap-0.5 text-black/60 dark:text-white/50 h-12 flex-col items-center w-16 p-0.5 hover:bg-transparent"
                :class="{ '!text-company-primary-50': currentStep === editorRouteNames.captions }"
              >
                <Spinner v-if="captions.isGeneratingCaptions.value" class="w-[22px] h-[22px] animate-spin text-company-primary-50" />
                <IconSaxSubtitle v-else class="w-[22px] h-[22px]" />
                <span
                  class="text-[0.7rem] uppercase"
                >
                  Captions
                </span>
              </Button>
                <Button
                  size="sm" variant="ghost" @click="isBottomPanelOpen = true; currentStep = editorRouteNames.export; scrollNavTo('right')"
                  class="snap-center gap-0.5 text-black/60 dark:text-white/50 h-12 flex-col items-center w-16 p-0.5 hover:bg-transparent"
                  :class="{ '!text-company-primary-50': currentStep === editorRouteNames.export }"
                >
                <IconSaxExport class="w-[22px] h-[22px]" />
                <span
                  class="text-[0.7rem] uppercase"
                >
                  Export
                </span>
              </Button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </TooltipProvider>
</template>

<style scoped lang="scss">
.editor-page {
  display: grid;
  width: 100vw;
  height: 100vh;

  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto auto 1fr;
  grid-template-areas:
    "upgrade-banner upgrade-banner upgrade-banner"
    "nav side-panel workspace"
    "nav side-panel timeline";

  .editor-page__upgrade-banner {
    grid-area: upgrade-banner;
  }

  .editor-page__nav {
    grid-area: nav;
  }

  .editor-page__side-panel {
    grid-area: side-panel;
  }

  .editor-page__workspace {
    grid-area: workspace;
  }

  .editor-page__timeline {
    grid-area: timeline;
  }
}
</style>
