<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, ref, watch } from 'vue'
import DashboardHamburgerButton from '@/areas/dashboard/layout/nav/DashboardHamburgerButton.vue'
import { useRouter, type RouteRecordName } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { tiers } from '@/enums/tiers'
import IconSaxHappyemoji from '@/components/Icons/iconsax/IconSaxHappyemoji.vue'
import DashboardAccountInfoFlyout from '@/areas/dashboard/layout/nav/DashboardAccountInfoFlyout.vue'
import IconSaxHomeTrendUp from '@/components/Icons/iconsax/IconSaxHomeTrendUp.vue'
import IconSaxShare from '@/components/Icons/iconsax/IconSaxShare.vue'
import IconSaxClipboard from '@/components/Icons/iconsax/IconSaxClipboard.vue'
import IconSaxExportSquare from '@/components/Icons/iconsax/IconSaxExportSquare.vue'
import IconSaxMobile from '@/components/Icons/iconsax/IconSaxMobile.vue'
import DashboardNotificationFlyout from '@/areas/dashboard/layout/nav/DashboardNotificationFlyout.vue'
import IconSaxCalendarEdit from '@/components/Icons/iconsax/IconSaxCalendarEdit.vue'
import DashboardNavigationItem from '@/areas/dashboard/layout/nav/DashboardNavigationItem.vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import DashboardNavigationBanner from '@/areas/dashboard/layout/nav/DashboardNavigationBanner.vue'
import IconSaxVideoVertical from '@/components/Icons/iconsax/IconSaxVideoVertical.vue'
import IconSaxGhost from '@/components/Icons/iconsax/IconSaxGhost.vue'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { posthog } from 'posthog-js'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { getApiRendersPremiumRenderCount } from '@/apis/streamladder-api/renders/renders'
import { Progress } from '@/components/ui/progress'
import { useBlackFriday } from '@/Hooks/dates/useBlackFriday'
import logging from '@/logging'

const userInfo = useUserInfoStore()

const isOpen = ref(false)
useRouter().afterEach(() => {
  isOpen.value = false
})

function openUpgradeDialog() {
  upgradeDialog.open({
    tier: 100,
    reason: 'Dashboard navigation banner',
    subtitle: undefined,
    title: undefined,
    intentional: true,
  })
}

const router = useRouter()

const isOpenCreateNew = ref(false)

function onButtonClicked(text: string, routeName: RouteRecordName | string) {
  logging.trackEvent('Dashboard Button Clicked', {
    button: text,
    route: typeof routeName === 'string' && routeName.startsWith('https://')
      ? routeName
      : router.resolve({ name: routeName }).href
  });
}

const isInPremiumExperiment = useFeatureFlagVariantEnabled('max-5-premium-exports', 'premium');
const premiumRenderCount = ref<number | null>(null);

watch(isInPremiumExperiment, async () => {
  if (isInPremiumExperiment.value) {
    const response = await getApiRendersPremiumRenderCount();
    if (response) {
      premiumRenderCount.value = response.value as number;
    }
  }
}, { immediate: true });

const clampedPremiumRenderCount = computed(() => {
  return premiumRenderCount.value ? Math.min(premiumRenderCount.value, 5) : 0;
});

const { showBlackFridayDeal } = useBlackFriday();
</script>

<template>
  <section
    v-click-outside="() => (isOpen = false)"
    :class="isOpen ? 'sm:max-lg:min-h-[100dvh]' : 'sm:max-lg:min-h-0'"
    class="flex flex-col overflow-hidden transition-all lg:h-full lg:overflow-y-auto"
  >
    <header class="flex items-center justify-between">
      <RouterLink
        :to="{ name: dashboardRouteNames.dashboard }"
        class="flex flex-nowrap items-center gap-4 overflow-x-hidden rounded-lg px-4 py-2 text-brand-state-text-primary lg:w-full"
      >
        <img alt="StreamLadder branding" class="h-8 w-8" height="192" src="/images/logo.png" width="192" />
        <span class="flex items-center gap-2">
          <span class="h-4 text-lg font-semibold leading-[1]">StreamLadder</span>
          <GoldPlanButton v-if="userInfo.tier === tiers.GOLD" class="pointer-events-none h-4 w-4" small />
          <SilverPlanButton v-else-if="userInfo.tier === tiers.SILVER" class="pointer-events-none h-4 w-4" small />
          <span v-else class="flex rounded bg-white px-1 py-0.5 text-xs font-semibold uppercase text-indigo-500">
            {{ userInfo.plan }}
          </span>
        </span>
      </RouterLink>

      <DashboardHamburgerButton v-model="isOpen" />
    </header>
    <section
      :class="isOpen ? 'max-lg:max-h-[150vh]' : 'max-lg:max-h-0'"
      class="flex flex-auto flex-col gap-4 transition-all lg:gap-6"
    >
      <nav class="flex flex-col gap-2 pt-6">

        <div class="mt-auto ml-auto w-full">
          <Popover v-model:open="isOpenCreateNew">
            <PopoverTrigger as-child>
              <GradientButton
                class="w-full !justify-start gap-4 rounded-lg !px-4 !font-semibold tracking-wide transition-all hover:overflow-visible"
              >
                <IconSaxAdd class="shrink-0 [&>path]:stroke-2" />
                <span>Create new</span>
              </GradientButton>
            </PopoverTrigger>
            <PopoverContent
              align="start"
              class="mt-2 p-4 flex flex-col gap-2 layer-2 rounded-xl w-auto max-w-[100dvw]"
              side="bottom"
              trap-focus
              @click.stop="isOpenCreateNew = false"
            >
              <RouterLink :to="{ name: 'editor' }" @click="onButtonClicked('Vertical Clip', 'editor')">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxClipboard class="shrink-0" />
                  <span>Vertical Clip</span>
                </Button>
              </RouterLink>
              <RouterLink :to="{ name: dashboardRouteNames.contentPublisher.post }" @click="onButtonClicked('Social Media Post', dashboardRouteNames.contentPublisher.post)">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxCalendarEdit class="shrink-0" />
                  <span>Social Media Post</span>
                </Button>
              </RouterLink>
              <RouterLink :to="{ name: dashboardRouteNames.montageMaker }" @click="onButtonClicked('Clip Montage', dashboardRouteNames.montageMaker)">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxVideoVertical class="shrink-0" />
                  <span class="flex w-full items-center justify-between gap-1">
                    Clip Montage
                  </span>
                </Button>
              </RouterLink>
              <RouterLink :to="{ name: dashboardRouteNames.clipGpt.root }"  @click="onButtonClicked('Clips from Stream', dashboardRouteNames.clipGpt.root)">
                <Button variant="ghost" class="w-full justify-start">
                  <IconSaxGhost class="shrink-0" />
                  <span class="flex w-full items-center justify-between gap-4">
                    Clips from Stream
                    <span class="rounded-full font-semibold text-xs uppercase px-3 py-1 leading-[1] bg-foreground text-background">
                      Beta
                    </span>
                  </span>
                </Button>
              </RouterLink>
              <Button variant="ghost" class="w-full justify-start" as="a" href="https://emotes.streamladder.com/" target="_blank" @click="onButtonClicked('Animated Emote', 'https://emotes.streamladder.com/')">
                <IconSaxHappyemoji class="shrink-0" />
                <span class="flex w-full items-center justify-between gap-3">
                    Animated Emote
                    <IconSaxExportSquare class="ml-auto h-4 w-4" />
                  </span>
              </Button>
            </PopoverContent>
          </Popover>
        </div>

        <div class="flex flex-col gap-2 min-h-0">
          <DashboardNavigationItem :route="dashboardRouteNames.dashboard" @click="onButtonClicked('Dashboard', dashboardRouteNames.dashboard)">
            <IconSaxHomeTrendUp class="shrink-0" />
            <span>Start</span>
          </DashboardNavigationItem>

          <DashboardNavigationItem :route="dashboardRouteNames.contentPublisher.root" @click="onButtonClicked('Content Publisher', dashboardRouteNames.contentPublisher.root)">
            <IconSaxCalendarEdit class="shrink-0" />
            <span>Share & Schedule</span>
          </DashboardNavigationItem>

          <DashboardNavigationItem :route="dashboardRouteNames.videos" @click="onButtonClicked('My Videos', dashboardRouteNames.videos)">
            <IconSaxMobile class="shrink-0 translate-x-[2px]" />
            <span>My Videos</span>
          </DashboardNavigationItem>

          <template v-if="false">
            <!-- <DashboardNavigationItem :route="dashboardRouteNames.projects" @click="onButtonClicked('My Projects', dashboardRouteNames.videos)">-->
            <!--   <IconFolders class="shrink-0" />-->
            <!--   <span>My Projects</span>-->
            <!-- </DashboardNavigationItem>-->
          </template>
        </div>
      </nav>

      <nav class="relative mt-auto flex flex-col gap-2">
        <div class="flex flex-col gap-1 sm:flex-row lg:flex-col">
          <template v-if="isInPremiumExperiment">
            <button class="hover:scale-105 transition-transform mb-2 flex justify-center items-center gap-2 w-full overflow-hidden px-4 py-3 rounded-lg shadow-lg bg-surface-panel" @click="openUpgradeDialog">
              <img
                src="/images/images/bolt.png"
                class="h-14 rotate-6"
                alt="Bolt"
              />
              <div class="flex flex-col gap-1">
                <span class="text-black dark:text-white font-light">Free Clips created {{ clampedPremiumRenderCount }} / 5</span>
                <Progress class="bg-zinc-300" :model-value="clampedPremiumRenderCount * 20" />
              </div>
            </button>
          </template>
          <template v-else-if="userInfo.tier < tiers.GOLD">
            <button v-if="showBlackFridayDeal" class="flex w-full overflow-hidden" @click="upgradeDialog.open({
              tier: 100,
              reason: 'Black Friday Dashboard Navigation Banner',
              subtitle: undefined,
              title: undefined,
              intentional: true,
            })">
              <DashboardNavigationBanner class="bg-[#663DF0] text-sm">
                <img class="absolute top-0 right-0 w-1/2" src="/black-friday/ribbon-tr.png" alt="black friday ribbon" />
                <header class="flex w-56 flex-col items-center gap-1 relative">
                  <h6 class="text-center !text-white font-title text-xs mb-2 stroke-text">
                    Your clips deserve to go viral.
                    StreamLadder makes it easier - and 50% cheaper!
                  </h6>
                </header>
                <button
                  tabindex="-1"
                  class="w-full rounded-xl bg-white px-6 py-2 font-semibold font-title text-[#5D5FEF] transition-all group-hover/banner:scale-105 group-hover/banner:shadow-lg group-active/banner:scale-90"
                >
                Sign me up
              </button>
              </DashboardNavigationBanner>
            </button>
            <button v-else class="flex w-full overflow-hidden" @click="openUpgradeDialog">
              <DashboardNavigationBanner
                call-to-action="Upgrade now"
                class="bg-[#663DF0] text-sm"
                title="Get access to all features on StreamLadder"
              />
            </button>
          </template>
        </div>

        <DashboardNotificationFlyout />
        <DashboardNavigationItem :route="dashboardRouteNames.socials" @click="onButtonClicked('Social Media Connections', dashboardRouteNames.socials)">
          <IconSaxShare class="shrink-0" />
          <span>Social Media Connections</span>
        </DashboardNavigationItem>
      </nav>

      <DashboardAccountInfoFlyout />
    </section>
  </section>
</template>

<style lang="scss" scoped>
@use '@/assets/mixins/text' as text;

.stroke-text {
  @include text.stroke(#663DF0, 1);
}
</style>
