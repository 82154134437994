export function dedupeAsync<T, TArgs extends unknown[]>(
  fn: (...args: TArgs) => Promise<T>
): (...args: TArgs) => Promise<T> {

  const promises = new Map<string, Promise<T>>();

  return async function(...args: TArgs) {
    
    const key = JSON.stringify(args);

    if (promises.has(key)) {
      return promises.get(key)!;
    }

    const promise = fn(...args).finally(() => promises.delete(key));
    promises.set(key, promise);
    return promise;
  };
}
