import { createGlobalState, useStorage } from '@vueuse/core'
import { ref, type Ref, watch, computed, reactive } from 'vue'
import { useUserInfoStore, onUserInfoReady } from '@/store/user/userInfo'
import { getApiTwitchClips } from '@/apis/streamladder-api/twitch-clips/twitch-clips'
import { debounce } from 'lodash-es'
import { useTwitchClipsFilter, type TwitchClipFilterValues } from '@/Hooks/useTwitchClipsFilter'
import type { ClipDto } from '@/apis/streamladder-clipgpt/model/clipDto'
import { dedupeAsync } from '@/helpers/dedupeAsync'

export const useMyClips = createGlobalState(() => {
  
  const userInfoStore = useUserInfoStore()
  const userName = computed(() => userInfoStore.userName)
  const myClips = useClipsOf(userName)
  const myMostRecentClips = useClipsOf(userName, 'recency')
  
  const searchQueryModel = ref('')
  const searchQuery = ref(searchQueryModel.value)
  const searchedClips = useClipsOf(searchQuery)

  onUserInfoReady(({ isAuthenticated, userName }) => {
    if (isAuthenticated && userName) {
      searchQueryModel.value = userName
      searchQuery.value = userName
    }
  })

  const searchHistory = useStorage<string[]>('searchHistory', [])
  function selectLastThreeValidSearches(searches: string[]) {
    return searches
      .map((s) => s.trim())
      .filter(Boolean)
      .slice(-5)
  }

  function search(value?: string) {
    const query = (value ?? searchQueryModel.value).trim()
    searchQuery.value = query
    searchQueryModel.value = query
    if (!searchHistory.value.includes(query)) {
      searchHistory.value = selectLastThreeValidSearches([ ...searchHistory.value, query ])
    }
    searchedClips.fetch()
  }

  const previewClips = computed(() => {
    if (!userInfoStore.hasLoadedUserInfo) {
      return null
    } else if (userInfoStore.isAuthenticated) {
      return [ ...myMostRecentClips.ordered.value ?? [] ]
    } else {
      return [
        {"id":"CrispyHeartlessAdminThisIsSparta-SYsnQDmG57Yr3Dyq","title":"family picture","thumbnailUrl":"https://clips-media-assets2.twitch.tv/o_Na8UmLJ0A5hue2K9w8qg/39981367973-offset-11096-preview-480x272.jpg","mp4Url":"https://sl-clips.b-cdn.net/o_Na8UmLJ0A5hue2K9w8qg/39981367973-offset-11096.mp4","viewCount":723,"languageCode":"EN","broadcasterName":"g_birb","broadcasterProfileImage":"https://static-cdn.jtvnw.net/jtv_user_pictures/c9ac3d3d-fe03-4e2c-8683-58856c8a55b5-profile_image-50x50.png","gameName":"Dead by Daylight","gameBoxArt":"https://static-cdn.jtvnw.net/ttv-boxart/491487-52x72.jpg","taskId":null,"createdAt":"2023-09-21T15:12:47.3007908Z","dateFromNow":null},
        {"id":"AnimatedMoralWalletMingLee-ODj2KlFT8ICSSGJs","title":"Murder","thumbnailUrl":"https://clips-media-assets2.twitch.tv/W_N7szg49ipRU_CAkkhibA/AT-cm%7CW_N7szg49ipRU_CAkkhibA-preview-480x272.jpg","mp4Url":"https://sl-clips.b-cdn.net/W_N7szg49ipRU_CAkkhibA/AT-cm%7CW_N7szg49ipRU_CAkkhibA.mp4","viewCount":123,"languageCode":"EN","broadcasterName":"JoeR247","broadcasterProfileImage":"https://static-cdn.jtvnw.net/jtv_user_pictures/c1ed810c-ad23-4a74-8864-ff4c58fa1ca4-profile_image-50x50.png","gameName":"EA Sports WRC","gameBoxArt":"https://static-cdn.jtvnw.net/ttv-boxart/1580396182_IGDB-52x72.jpg","taskId":null,"createdAt":"2023-10-14T21:03:28.9373782Z","dateFromNow":null},
        {"id":"ExuberantToughAnteaterTakeNRG-FDURk-g0tD3ue2Kq","title":"Slashing bodies!! !dixper !discord !tiktok !youtube |Team OP|","thumbnailUrl":"https://clips-media-assets2.twitch.tv/q9Nx3xQ1EZPoNLQ97ntiqg/41018220264-offset-1192-preview-480x272.jpg","mp4Url":"https://sl-clips.b-cdn.net/q9Nx3xQ1EZPoNLQ97ntiqg/41018220264-offset-1192.mp4","viewCount":91,"languageCode":"EN","broadcasterName":"hamsterlovin_","broadcasterProfileImage":"https://static-cdn.jtvnw.net/jtv_user_pictures/dcd22225-5445-45e7-af3a-6dc997dd523c-profile_image-50x50.png","gameName":"Dead by Daylight","gameBoxArt":"https://static-cdn.jtvnw.net/ttv-boxart/491487-52x72.jpg","taskId":null,"createdAt":"2023-08-24T23:50:34.8229111Z","dateFromNow":null}
      ]
    }
  })

  const amountOfPreviewClips = computed(() => {
    return previewClips.value?.length ?? 0
  })

  return {
    myClips: reactive(myClips),
    myMostRecentClips: reactive(myMostRecentClips),
    previewClips: reactive({
      clips: previewClips,
      amountOfClips: amountOfPreviewClips,
    }),

    search: search,
    searchQuery: searchQueryModel,
    onInput: debounce(() => search(searchQueryModel.value), 400),
    searchHistory: searchHistory,
    searchResult: reactive(searchedClips),
  }
})

type Clip = { [P in keyof ClipDto]-?: NonNullable<ClipDto[P]> }

function useClipsOf(streamerName: Ref<string>, key?: TwitchClipFilterValues) {

  const clips: Ref<Clip[] | null> = ref(null)
  
  const isFetching = ref(false)
  const fetch = dedupeAsync(async () => {
    if (streamerName.value) {
      isFetching.value = true
      try {
        clips.value = await getApiTwitchClips({ 
          streamerName: streamerName.value, 
          sinceDays: filter.value.sinceDays
        }) as unknown as Clip[]
      } catch (error) {
        console.error(error)
      } finally {
        isFetching.value = false
      }
    }
  })

  const { twitchClipFilterKey, twitchClipsOptions } = useTwitchClipsFilter()
  const filter = computed(() => {
    return twitchClipsOptions.find((o) => {
      return o.value === (key ?? twitchClipFilterKey.value)
    }) ?? twitchClipsOptions[0]
  })

  watch(filter, () => fetch())
  
  return {
    clips: clips,
    amountOfClips: computed(() => clips.value?.length ?? 0),
    streamerName: streamerName,
    isFetching: isFetching,
    fetchIfNull() {
      if (clips.value === null) {
        fetch()
      }
    },
    fetch() {
      fetch()
    },
    ordered: computed(() => {
      return orderBy(clips.value, filter.value.sort)
    }),
    orderedBy: computed(() => {
      return (order: 'recency' | 'views') => orderBy(clips.value, order)
    })
  }
}

function orderBy<T extends { viewCount: number } & { createdAt: string }>(clips: T[] | null, order: 'recency' | 'views'): T[] | null {
  if (!clips) {
    return null
  } else if (order === 'recency') {
    return orderByRecency(clips)
  } else if (order === 'views') {
    return orderByViewCount(clips)
  } else {
    return clips
  }
}

function orderByRecency<T extends { createdAt: string }>(clips: T[]): T[] {
  return [...clips].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })
}

function orderByViewCount<T extends { viewCount: number }>(clips: T[]): T[] {
  return [...clips].sort((a, b) => {
    return b.viewCount - a.viewCount
  })
}
