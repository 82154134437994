<script setup lang="ts">
import settings from '@/data/settings'
import { ref } from 'vue'
import type { UpgradeDialogPayload } from '@/helpers/upgradeDialog'
import CrossIcon from '@/components/Icons/CrossIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { type Tier } from '@/data/plans'
import { useDiscountCode } from '@/Hooks/useDiscountCode'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { posthog } from 'posthog-js'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import UpgradeDialogContentTierCard from '@/components/Dialog/Gold/UpgradeDialogContentTierCard.vue'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import logging from '@/logging'

const props = defineProps<{
  isLoading: string | null
  close: (_payload: UpgradeDialogPayload) => void
  handleClose: (payload: UpgradeDialogPayload) => void
  payload: UpgradeDialogPayload
  error: string | null
  reason: string
  tiers: Tier[]
  title?: string
  subtitle?: string
}>();

const { interval, intervals } = useSubscriptionIntervals()

const userInfoStore = useUserInfoStore()

const discountCode = useDiscountCode()

const { reveal } = useConfirmDialog()
const { isLoading: _isLoading, upgrade: _upgrade, detailedErrorMessage } = usePlanUpgrade()

const isUpgrading = ref(false);

const upgrade = async (tier: Tier) => {

  if (isUpgrading.value || userInfoStore.tier >= tier.tier) return;

  isUpgrading.value = true;

  const tierNumber = tier.key === 'silver' ? 10 : 100;

  if (userInfoStore.tier !== 0) {
    const confirm = await reveal({
      title: `Upgrade to ${tier.key} now`,
      message: `Warning: You will be charged immediately for the difference in price for the remainder of this ${interval.value}.`,
    })
    if (!confirm) {
      isUpgrading.value = false;
      return
    }
  }

  if (tierNumber > userInfoStore.tier) {
    const eventMeta = {
      tier: tier.key,
      monthlyOrYearly: interval.value,
      currentPlan: userInfoStore.plan,
      isCurrentlyInTrial: userInfoStore.trialStatus === 'active',
    }

    logging.trackEvent('SubscriptionUpgrade Clicked', eventMeta)
  }

  isUpgrading.value = false;

  return _upgrade(tier, props.reason, discountCode, interval.value)
}

const showWithoutAndWithGoldCards = useFeatureFlagVariantEnabled('with-and-without-gold-cards', 'cards');
</script>

<template>
  <div class="modal-box max-h-screen xl:max-h-[95vh] w-full max-w-full gap-12 rounded-none p-0 md:rounded-lg min-[1400px]:max-w-[1400px] flex overflow-hidden">
    <div class="flex flex-col w-full max-h-full overflow-y-auto">
      <div
        v-if="!isLoading && !_isLoading"
        class="absolute right-2 top-2 z-10 cursor-pointer p-2 text-company-primary hover:text-company-primary xl:text-gray-300"
        @click="() => close(payload)"
      >
        <cross-icon class="h-6 w-6 fill-current" />
      </div>

      <div class="flex-1 justify-center items-center gap-8 xl:min-w-[620px] bg-white">
        <div class="flex flex-col justify-center items-center gap-4 xl:gap-2 h-full">
          <Transition
            appear
            enter-active-class="transition-[transform,_opacity] duration-300"
            leave-active-class="transition-[transform,_opacity] duration-300"
            enter-from-class="opacity-0 translate-y-4"
            enter-to-class="opacity-100 translate-y-0"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 -translate-y-4"
          >
            <div class="grid w-full h-full pt-16">
              <div class="flex flex-col">
                <div class="flex flex-col lg:flex-row w-full gap-8 justify-center lg:justify-between items-center py-8 px-8 2xl:px-20">

                  <div class="hidden lg:flex flex-col gap-2 relative">

                    <div class="z-10 text-4xl xl:text-5xl font-extrabold text-black relative">
                      <span>Unlock super <br>editing</span>
                      <span class="text-brand-primary"> powers</span>
                    </div>

                    <img class="hidden lg:flex absolute top-0 z-0 w-40 xl:w-48 -translate-x-4 -translate-y-2" src="/images/upgrade-dialog/unlock-surround.png" alt="unlock" draggable="false" />

                    <span class="font-light text-black">Choose the best plan for you</span>
                    <div class="w-64 xl:w-72 flex gap-2 flex-col">
                      <div class="senja-embed" data-id="d4fe32c2-2000-4aa9-857b-91cf93a9a0bc" data-mode="shadow" data-lazyload="false" style="display: block;"></div>
                    </div>

                    <div class="flex items-center justify-center absolute top-full">
                      <div class="flex items-center justify-center h-[300px] w-[425px]">
                        <img class="absolute -left-10 max-h-[200px] 2xl:max-h-full" src="/images/upgrade-dialog/left.png" alt="left" draggable="false" />
                        <img class="absolute max-h-[200px] 2xl:max-h-full" src="/images/upgrade-dialog/middle.png" alt="middle" draggable="false" />
                        <img class="absolute -right-10 max-h-[200px] 2xl:max-h-full" src="/images/upgrade-dialog/right.png" alt="right" draggable="false" />
                      </div>
                    </div>
                  </div>

                  <div class="flex lg:hidden text-4xl xl:text-5xl font-extrabold text-center text-black">
                    <span>Unlock super editing <span class="text-brand-primary">powers</span></span>
                  </div>

                  <div class="flex flex-col md:flex-row gap-8">
                    <UpgradeDialogContentTierCard
                      :reason="reason"
                      :tier="props.tiers.find(t => t.key === 'silver')!"
                      @click="upgrade(props.tiers.find(t => t.key === 'silver')!)"
                    />
                    <UpgradeDialogContentTierCard
                      :reason="reason"
                      :tier="props.tiers.find(t => t.key === 'gold')!"
                      @click="upgrade(props.tiers.find(t => t.key === 'gold')!)"
                    />
                  </div>
                </div>
              </div>

              <header class="flex min-h-[110px] flex-col items-center lg:items-end justify-center text-center font-bold gap-1 px-8 2xl:px-20">
                <div class="flex flex-col items-center justify-center">
                  <div class="flex flex-col items-center justify-center gap-2.5 rounded-full px-3 py-1.5">
                    <LightSwitch
                      class="bg-white text-sm"
                      :options="Object.values(intervals)"
                      :class="isLoading && 'pointer-events-none'"
                      background="bg-gradient"
                      v-model:value="interval"
                      labelClasses="text-white"
                    />
                    <span v-if="interval !== 'year'" class="relative text-sm font-bold leading-tight text-emerald-600">
                      Select yearly to save 20%!
                    </span>
                    <p v-else class="text-sm font-bold leading-tight text-emerald-600">
                      You are saving 20% 👌
                    </p>
                  </div>
                </div>
              </header>

              <div class="relative h-full block lg:hidden overflow-hidden">
                <div class="flex items-center justify-center top-full w-screen">
                  <div class="flex items-center justify-center h-[300px] w-[300px]">
                    <img class="absolute left-0" src="/images/upgrade-dialog/left.png" alt="left" draggable="false" />
                    <img class="absolute" src="/images/upgrade-dialog/middle.png" alt="middle" draggable="false" />
                    <img class="absolute right-0" src="/images/upgrade-dialog/right.png" alt="right" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          <div v-if="error" class="alert alert-error">
            <div class="flex w-full flex-row gap-4">
              <svg
                class="flex-shrink-0"
                fill="none"
                height="33"
                viewBox="0 0 32 33"
                width="32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16.5" fill="#FDCECE" r="16" />
                <path
                  d="M16 6.5C14.0222 6.5 12.0888 7.08649 10.4443 8.1853C8.79981 9.28412 7.51809 10.8459 6.76121 12.6732C6.00433 14.5004 5.8063 16.5111 6.19215 18.4509C6.578 20.3907 7.53041 22.1725 8.92894 23.5711C10.3275 24.9696 12.1093 25.922 14.0491 26.3078C15.9889 26.6937 17.9996 26.4957 19.8268 25.7388C21.6541 24.9819 23.2159 23.7002 24.3147 22.0557C25.4135 20.4112 26 18.4778 26 16.5C25.9949 13.8494 24.9397 11.3088 23.0655 9.43455C21.1912 7.56028 18.6506 6.50508 16 6.5ZM19.625 19.0288C19.7694 19.1747 19.8505 19.3717 19.8505 19.5769C19.8505 19.7822 19.7694 19.9792 19.625 20.125C19.478 20.2672 19.2814 20.3466 19.0769 20.3466C18.8724 20.3466 18.6759 20.2672 18.5288 20.125L16 17.5865L13.4712 20.125C13.3241 20.2672 13.1276 20.3466 12.9231 20.3466C12.7186 20.3466 12.522 20.2672 12.375 20.125C12.2306 19.9792 12.1495 19.7822 12.1495 19.5769C12.1495 19.3717 12.2306 19.1747 12.375 19.0288L14.9135 16.5L12.375 13.9712C12.2523 13.8217 12.1897 13.632 12.1991 13.4388C12.2086 13.2457 12.2896 13.063 12.4263 12.9263C12.563 12.7896 12.7457 12.7086 12.9388 12.6991C13.132 12.6896 13.3217 12.7523 13.4712 12.875L16 15.4135L18.5288 12.875C18.6783 12.7523 18.868 12.6896 19.0612 12.6991C19.2543 12.7086 19.437 12.7896 19.5737 12.9263C19.7104 13.063 19.7914 13.2457 19.8009 13.4388C19.8104 13.632 19.7477 13.8217 19.625 13.9712L17.0865 16.5L19.625 19.0288Z"
                  fill="#F30C0C"
                />
              </svg>
              <div class="w-full text-left">
                <span class="break-words sm:text-lg">Uh oh!</span>
                <p class="w-full max-w-full text-sm">
                  <template v-if="error === 'configuration-unknown'">
                    This plan configuration could not be found. Please contact our support team through our&nbsp;
                    <a :href="settings.discordInviteUrl" target="_blank" class="link text-indigo-500 hover:no-underline"
                    >Discord</a
                    >.
                  </template>
                  <template v-if="error === 'checkout-failed'">
                    {{ detailedErrorMessage ?? "We're trying to upgrade your account, however the process is being blocked by our payment provider. Please contact our support and we'll upgrade your account!" }}
                    &nbsp;
                    <a :href="settings.discordInviteUrl" target="_blank" class="link text-indigo-500 hover:no-underline"
                    >Discord</a
                    >.
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="showWithoutAndWithGoldCards">
        <div class="pt-8 bg-white">
          <header class="flex flex-col items-center justify-center text-center pb-8 text-black">
            <h3 class="pb-2 pt-0 text-center font-bold text-2xl px-4 md:px-28 text-[#2F184E] lg:text-3xl">
              “The <span class="text-brand-primary">most user-friendly way</span> to edit live stream clips into dynamic, exciting and watchable vertical clips”
            </h3>
            <img draggable="false" src="/images/images/joer247.png" alt="JoeR247" class="w-14 h-14 rounded-full" />
            <p class="font-semibold pt-2">JoeR247</p>
            <a class="text-sm font-light text-gray-500 hover:underline" target="_blank" href="https://www.twitch.tv/JoeR247">twitch.tv/JoeR247</a>
          </header>
          <div class="flex flex-col-reverse md:flex-row gap-6 w-full items-center justify-center text-black pb-8">
            <div class="flex flex-col w-[340px] rounded-3xl border overflow-hidden shadow-lg">
              <img draggable="false" class="h-[250px]" src="/images/images/without-gold.gif" alt="You without Gold"/>
              <h3 class="text-3xl text-center py-4 font-bold">You Without Gold</h3>
              <div class="flex flex-col max-w-full px-8 pb-4 gap-4">
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">❌</div> Spending hours doing things that should take minutes</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">❌</div> Juggling five different tools just to make one clip</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">❌</div> Wasting hours scheduling posts manually</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">❌</div> Cringing at low-quality exports that don’t do your gameplay justice</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">❌</div> Your clips feel like background noise —nothing stands out</div>
              </div>
            </div>
            <div class="flex flex-col w-[340px] rounded-3xl border border-company-primary-50 overflow-hidden shadow-lg bg-[#F7F4FF]">
              <img draggable="false" class="h-[250px]" src="/images/images/with-gold.gif" alt="You with Gold"/>
              <h3 class="text-3xl text-center py-4 font-bold text-brand-primary">You With Gold</h3>
              <div class="flex flex-col max-w-full px-8 pb-4 gap-4">
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">✅</div> Save hours, edit and finish in just minutes.</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">✅</div> All-in-one tools —no need to jump between platforms</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">✅</div> Automate scheduling—post to all socials with a click</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">✅</div> Export in 1080p 60fps, optimized for social media</div>
                <div class="flex gap-3 items-center text-sm font-light"><div class="text-xs">✅</div> Stand out with zooms, audio effects, and animated stickers</div>
              </div>
            </div>
          </div>
          <slot />
        </div>
      </template>
      <template v-else>
        <div class="pt-8 bg-white">
          <header class="flex flex-col items-center justify-center text-center">
            <h3 class="pb-2 pt-0 text-center font-bold text-xl text-[#2F184E] lg:text-2xl">
              Hear from <span class="text-brand-primary">other creators</span>
            </h3>
          </header>
          <slot />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
