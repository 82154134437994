import { fontsData, type Font } from '@/data/fonts';
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { VideoRenderer } from './worker/video-renderer-worker';
import type { ColorSource } from '@pixi/color'
import { computed, toRaw, watch } from 'vue'
import type { VideoFragment } from '@/modules/SLVideoplayer/types';
import { noop } from 'ts-essentials';
import { decodeImage } from './rive/decode-image';
import type { RenderDetailsDto, StickerData } from '@/apis/streamladder-api/model'
import { useStickersStore as useOldStickersStore } from '@/store/entity-system/useStickersStore';
import { useStickersStore } from '@/areas/editor/store/useStickersStore';
import { copyRef } from '@/store/entity-system/_copyRef';
import { textStyleToCaptionPreset } from '@/components/Captions/v3/textStyleToCaptionPreset'
import * as Sentry from '@sentry/vue'
import { runWebcodecTest } from './worker/webcodec-test';
import { getAudioDecoder } from 'audio-file-decoder';
import { fetchWithRetry } from '@/lib/fetchWithRetry';
import { clearCache, renderStickerVue } from '@/lib/renderStickerVue';
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore';
import { useProjectStore } from '@/areas/editor/store/useProjectStore';

interface VideoWorkerManagerProps {

  id: string

  width: number
  height: number
  backgroundColor?: ColorSource
  frames?: VideoFragment[]
  videoUrl?: string

  startMs?: number
  endMs?: number

  previewCanvas?: HTMLCanvasElement

  live?: boolean | undefined
  onDownloadFinished?: (url: string, blob: Blob, fps?: string, speed?: string) => void | undefined
  onDownloadFinishedRaw?: (buffer: ArrayBuffer, fps?: string, speed?: string) => void
  onDownloadProgress?: (status: string) => void | undefined
  onProgress?: (status: string) => void | undefined
  onLog?: (message: string) => void | undefined
  onError?: (message: string, tryRepair?: boolean) => void | undefined

  renderDetails?: RenderDetailsDto

  startTimeOffset?: number

  watermarkOverride?: boolean
  isFallbackRender?: boolean
}

interface WorkerLike {
  postMessage: { (message: any, transfer: Transferable[]): void; (message: any, options?: StructuredSerializeOptions | undefined): void; };
  addEventListener: (type: string, callback: (message: { data: { type: string, status: string, buffer: ArrayBuffer, progress: number, log: string, url: string, clickableAreas: [], fps?: string, speed?: string, audioData?: string } }) => void) => void;
  terminate: () => void;
  onerror: ((this: AbstractWorker, ev: ErrorEvent) => any) | null;
}

interface ClickableArea {
  parent: string;
  area: { x: number, y: number, width: number, height: number };
  hoverIcon?: string;
  onMove?: (e: MouseEvent, x: number, y: number) => void;
  onClick?: (e: MouseEvent, x: number, y: number) => void;
  reset?: (e: MouseEvent, x: number, y: number) => void;
  clickIcon?: string;
}


interface OptionsUpdate {
  captionsWrapper: {
    x?: number;
    y?: number;
    scale?: number;
  }
}

export class VideoWorkerManager {

  id: string | null = null;

  options = {};
  worker: WorkerLike;
  canvas: HTMLCanvasElement
  offscreenCanvas?: OffscreenCanvas
  initializeCallback?: () => void | undefined;
  onLog?: (message: string) => void | undefined;
  onError?: (message: string, tryRepair?: boolean) => void | undefined

  clickableAreas: ClickableArea[] = [];
  capturedArea?: ClickableArea;

  isRendering = false;
  isExporting = false;
  isFinished = false;
  isTerminated = false;

  useRenderDetails = false;

  mixAudioPromise?: Promise<string>;
  mixAudioPromiseResolve?: (url: string) => void;

  live = false;

  lastRenderFunction? = noop;

  constructor(props: VideoWorkerManagerProps) {

    this.id = props.id

    const { live, onDownloadProgress, onDownloadFinished, onProgress, onLog, onError, onDownloadFinishedRaw } = props;
    this.live = !!live;

    this.onLog = onLog;
    this.onError = props.onError;

    if (!live) {
      this.worker = new Worker(new URL('./worker/worker.js', import.meta.url), { type: "module" });
    } else {
      this.worker = VideoRenderer();
    }

    this.worker.onerror = (ev: ErrorEvent) => {
      const error = new Error(ev.message);
      error.cause = ev;

      this.handleWorkerError(error, this.onError);
    };
  
    this.canvas = document.createElement('canvas');

    this.canvas.width = props.width;
    this.canvas.height = props.height;
  
    if (!live) {
      this.offscreenCanvas = this.canvas.transferControlToOffscreen();
    }

    this.useRenderDetails = !!props.renderDetails;
    if (props.renderDetails) {

      const videoFragments = [
          { type: 'background', cropData: { zIndex: 0 } }
      ];

      let startMs = Infinity;
      let endMs = 0;

      const fragments = props.renderDetails.segments ?? [];
      fragments.sort((a, b) => (a.startMs ?? 0) - (b.startMs ?? 0));

      for (const fragment of fragments) {
          for (const cropData of fragment.cropData ?? []) {
            videoFragments.push({
              cropData: cropData,
              feedData: cropData.feedData,
              startMs: fragment.startMs,
              endMs: fragment.endMs
            })
          }

          startMs = Math.min(fragment.startMs ?? startMs, startMs);
          endMs = Math.max(fragment.endMs ?? endMs, endMs);
      }

      videoFragments.sort((a, b) => a.cropData.zIndex - b.cropData.zIndex);

      const fonts = [];

      const fontInfo = fontsData.fonts.find(f => f.label === props.renderDetails?.overlay?.captionsObject?.captionPreset?.font?.fontFamily);
      if (fontInfo) fonts.push(fontInfo);

      const captionsV2Fonts = props.renderDetails?.overlay?.captionsV2?.captionPreset?.font?.fontFamily;
      const captionsV2FontInfo = fontsData.fonts.find(f => f.label === captionsV2Fonts);
      if (captionsV2FontInfo) fonts.push(captionsV2FontInfo);

      const stickers = (props.renderDetails.overlay?.Stickers as StickerData[] ?? [])
        .map((sticker) => sticker.isTextSticker
          ? ({ preset: textStyleToCaptionPreset(sticker.variant), ...toRaw(sticker), component: null })
          : { ...toRaw(sticker), component: null });

      for (const sticker of stickers) {
        const fontInfo = fontsData.fonts.find(f => f.label === sticker.preset?.font?.fontFamily);
        if (fontInfo) fonts.push(fontInfo);
      }

      const captionsV1Preset = props.renderDetails.overlay?.captionsObject?.captionPreset;
      const captionsV2Preset = props.renderDetails.overlay?.captionsV2?.captionPreset;

      const captionsV1BaseOptions = props.renderDetails.overlay?.captionsObject?.BaseOptions;
      const captionsV2BaseOptions = props.renderDetails.overlay?.captionsV2?.baseOptions;

      const captionsV1Area = props.renderDetails.overlay?.CaptionsWrapper;
      const captionsV2Area = props.renderDetails.overlay?.captionsV2?.captionsArea;

      const captionsV1 = props.renderDetails.overlay?.Captions;
      const captionsV2 = props.renderDetails.overlay?.captionsV2?.captions;

      const captions = captionsV2 ?? (captionsV1 ?? []);
      const captionStyleSettings = captionsV2Preset ?? captionsV1Preset;
      const captionsBaseOptions = captionsV2BaseOptions ?? captionsV1BaseOptions;
      const captionsArea = captionsV2Area ?? captionsV1Area;

      this.options = {
        videoInfo: {
          videoUrl: props.renderDetails.sourceUrl,
        },
        clipInfo: {
          start: startMs,
          end: endMs,
        },
        canvas: this.offscreenCanvas ?? this.canvas,
        fonts: { value: fonts },
        frames: videoFragments,

        captionSettings: captionsArea,
        captions: { value: captions },
        baseOptions: { value: captionsBaseOptions },
        captionStylesSettings: { value: captionStyleSettings },

        socialStickers: stickers.map(sticker => ({ ref: sticker })),
        stickers: stickers,
        live,
        previewCanvas: props.previewCanvas?.transferControlToOffscreen(),
        devicePixelRatio: window.devicePixelRatio,
        startTimeOffset: props.startTimeOffset,
        outputFPS: props.renderDetails.outputFps,
        renderWatermark: props.renderDetails.overlay?.watermark,
        isFallbackRender: props.isFallbackRender,
        effects: props.renderDetails.overlay?.Effects
      };
    } else {

      const editorCaptionsStore = useEditorCaptionsStore();
      const stickersStore = useStickersStore();

      watch([
        editorCaptionsStore,
        stickersStore.listImageUrls(),
      ], () => this.updateOptions(editorCaptionsStore, stickersStore));

      const stickers = []
      for (const sticker of stickersStore.entities) {
        if ('imageUrl' in sticker && sticker.imageUrl) {
          const imageElement = new Image();
          imageElement.src = sticker.imageUrl;
          stickers.push(({
            ref: sticker,
            imageBitmap: imageElement,
          }))
        }
        else if (sticker.type === 'text') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.color)
          }))
        } else if (sticker.type === 'brand-kit') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.primaryColor, sticker.secondaryColor)
          }))
        }
        else if (sticker.type === 'rive') {
          stickers.push(({
            ref: sticker,
          }))
        }
      }

      const projectsStore = useProjectStore();
      const captionsV2 = useCaptionsStore();

      const fonts = computed(() => {
        const captionPreset = projectsStore.useLegacyCaptions ? editorCaptionsStore.captionPreset : captionsV2.baseCaptionPreset;
        return fontsData.fonts.filter(f => f.label === captionPreset?.font?.fontFamily);
      })

      watch(fonts, () => {
        this.updateOptions(editorCaptionsStore, stickersStore);
      })
      
      this.options = {
        videoInfo: {
          videoUrl: props.videoUrl,
        },
        clipInfo: {
          start: props.startMs,
          end: props.endMs,
        },
        canvas: this.offscreenCanvas ?? this.canvas,
        fonts: fonts,
        frames: props.frames,
        socialStickers: stickers,
        live,
        previewCanvas: props.previewCanvas?.transferControlToOffscreen(),

        // Sorry.
        captionSettings: projectsStore.useLegacyCaptions ? editorCaptionsStore.captionsWrapper : captionsV2.captionsArea,
        styleOptions: computed(() => projectsStore.useLegacyCaptions ? toRaw(editorCaptionsStore.styleOptions) : toRaw(captionsV2.baseOptions)),
        baseOptions: computed(() => projectsStore.useLegacyCaptions ? toRaw(editorCaptionsStore.baseOptions) : toRaw(captionsV2.baseOptions)),
        captionStylesSettings: computed(() => projectsStore.useLegacyCaptions ? editorCaptionsStore.captionPreset : captionsV2.baseCaptionPreset),
        captions: computed(() => projectsStore.useLegacyCaptions ? editorCaptionsStore.captions : captionsV2.rendererEntities),

        devicePixelRatio: window.devicePixelRatio,
        startTimeOffset: props.startTimeOffset,
        watermark: props.watermarkOverride,
      };
    }
  
    this.worker.addEventListener('message', (message) => {
      if (message.data.type === 'finished') {
        this.isExporting = false;
        this.isFinished = true;
        this.worker.terminate();
        console.log('Export done!', message)
        // downloadArrayBufferAsFile(message.data.buffer, 'videofile' + '.mp4');
        if (onDownloadFinished) {
          const blob = new Blob([message.data.buffer]);
          const url = URL.createObjectURL(blob);
          onDownloadFinished(url, blob, message.data.fps, message.data.speed);
        }
        if (onDownloadFinishedRaw) {
          onDownloadFinishedRaw(message.data.buffer, message.data.fps, message.data.speed);
        }
      }
      else if (message.data.type === 'download-progress') {
        if (onDownloadProgress) onDownloadProgress(Math.round(message.data.progress) + '%')
      }
      else if (message.data.type === 'progress') {
          // don't remove or edit this log! It's used by puppeteer to track the progress of the server side video render
          console.log(`progress=${Math.round(message.data.progress * 100)}`)

          if (onProgress) onProgress(Math.round(message.data.progress * 100) + '%')
      }
      else if (message.data.type === 'initialized') {
        if (this.initializeCallback) this.initializeCallback();
      }
      else if (message.data.type === 'log') {
        if (this.onLog) this.onLog(message.data.log);
      }
      else if (message.data.type === 'decode-image') {
        decodeImage(message.data.url).then(async image => {
          const bitmap = await createImageBitmap(image);
          // console.log('decode-image')
          this.worker.postMessage({ type: 'decode-image', bitmap, url: message.data.url }, [bitmap]);
        })
      }
      else if (message.data.type === 'clickable-areas') {
        this.clickableAreas = message.data.clickableAreas;
      }
      else if (message.data.type === 'error') {
        this.terminate();
        this.handleWorkerError(new Error(message.data.log), this.onError)
      } else if (message.data.type === 'decode-audio') {
        this.decodeAudio(message.data.url).then((data) => {
          this.worker.postMessage({ type: 'decoded-audio', url: message.data.url, data }, data.rawData.map(data => data.buffer));
        }).catch(error => {
          console.error('error decoding audio');
          this.handleWorkerError(error, this.onError)
          throw error;
        });
      }
      else if (message.data.type === 'mixed-audio') {
        this.mixAudioPromiseResolve?.(message.data.audioData!)
      }
    });
  }

  getHoveredClickableArea(e: MouseEvent) {
    if (e.target && e.target instanceof HTMLElement) {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      for (const clickable of this.clickableAreas) {
        if (x >= clickable.area.x && x <= clickable.area.x + clickable.area.width) {
          if (y >= clickable.area.y && y <= clickable.area.y + clickable.area.height) {
            return { clickable, x, y }
          } 
        }
      }

      return { clickable: null, x, y }
    }

    return { clickable: null, x: 0, y: 0 };
  }


  mixAudio(videoDuration: number) {
    const effectsStore = useEffectsStore();


    this.mixAudioPromise = new Promise<string>((resolve) => {
      this.mixAudioPromiseResolve = resolve;
    })
    this.worker.postMessage({ type: 'mix-audio', data: { videoDuration, audioEffects: effectsStore.entities.filter(entity => entity.type === "sound").map(entity => toRaw(entity)) }})
  }

  canvasHover(e: MouseEvent) {
    const { clickable: currentHover, x, y } = this.getHoveredClickableArea(e);
    
    if (this.capturedArea) {
      this.capturedArea.onMove?.(e, x, y);
      return;
    }

    if (currentHover) {
      if (currentHover.hoverIcon) {
        this.canvas.style.cursor = currentHover.hoverIcon;
      }
      currentHover.onMove?.(e, x, y);
    }
    else {
      this.canvas.style.cursor = 'unset';
    }
  }

  canvasClick(e: PointerEvent) {
    const { clickable: currentClicked, x, y } = this.getHoveredClickableArea(e);
    if (currentClicked?.onClick) currentClicked.onClick(e, x, y);

    if (currentClicked?.clickIcon && e.buttons > 0) {
      this.canvas.style.cursor = currentClicked.clickIcon;
    }

    for (const clickable of this.clickableAreas) {
      if (currentClicked === clickable) clickable.onClick?.(e, x, y);
      else if (!currentClicked || currentClicked.parent !== clickable.parent) clickable.reset?.(e, x, y);
    }

    if (currentClicked) {
      this.canvas.setPointerCapture(e.pointerId);
      this.capturedArea = currentClicked;
    }
  }

  handleWorkerError(ev: Error, callback: ((message: string) => void) | undefined){

    // don't remove or edit this log! It's used by puppeteer to track the error of the server side video render
    console.error('Error from video worker: ' + ev.message);
    
    // Sentry.captureException(new Error('WebCodec Error: ' + ev.message));

    if (callback) {
      callback(ev.message);
    }
  }

  // this actually starts the video exporting:
  // reading the source video file, rendering all the frames and generating the output video file
  async startExporting() {
    const transferables: Transferable[] = [this.offscreenCanvas];

    clearCache()

    // @ts-ignore
    if (this.options.previewCanvas) {
      // @ts-ignore
      transferables.push(this.options.previewCanvas);
    }

    try {

      this.isFinished = false;
      const promises = [];

      if (!this.useRenderDetails) {
        const oldSocialStickerStore = useOldStickersStore();
        const newSocialStickerStore = useStickersStore();
        this.options.socialStickers = [...oldSocialStickerStore.entities, ...newSocialStickerStore.entities]
          .map(sticker => ({ ref: toRaw(copyRef(sticker)), preset: null, }));
      }

      // const captionStyleSettingsEntries = Object.keys(captionStylesSettings);
      for (const sticker of this.options.socialStickers) {

        if (sticker.ref.type === 'custom') {
          const downloadCustomSticker = async () => {

            if (!sticker.ref.imageUrl.startsWith('blob')) {
              const image = new Image();
              image.src = 'https://wsrv.nl/?url=' + encodeURI(sticker.ref.imageUrl);
              image.crossOrigin = 'anonymous';
              await image.decode();
              const bmp = await createImageBitmap(image);
              transferables.push(bmp);
              sticker.imageBitmap = bmp;
            }
          }
          promises.push(downloadCustomSticker())
        }
        else if (sticker.ref.type !== 'giphy' && sticker.ref.type !== 'rive'){
          const downloadScreenshot = async () => {

              // downloadStickerScreenshot()
              // const arrayBuffer = await downloadStickerScreenshot(sticker.ref, this.canvas.width / (sticker.ref.area.width * this.canvas.width), 1080);
              // const bmp = await createImageBitmap(new Blob([ arrayBuffer ]));
              const imageUrl = await renderStickerVue(toRaw(sticker.ref), this.canvas.width / (sticker.ref.area.width * this.canvas.width), 1080);
              const image = new Image();
              image.src = imageUrl!;
              await image.decode()

              if (image.width === 0 || image.height === 0) {
                const stickerError = new Error(`Sticker failed rendering: ${JSON.stringify(sticker.ref)}`);
                Sentry.captureException(stickerError);
                this.onError?.(stickerError.message, false);
                return;
              }
              const stickerCanvas = new OffscreenCanvas(image.width, image.height);
              stickerCanvas.getContext('2d')?.drawImage(image, 0, 0);

              sticker.imageBitmap = await createImageBitmap(stickerCanvas);
              sticker.ref.imageUrl = '';
              transferables.push(sticker.imageBitmap);
          }

          await downloadScreenshot();
          // promises.push(downloadScreenshot())
        }
      }

      await Promise.all(promises);

    } catch(e) {
      console.error('error downloading stickers: ', e)
    }

    const webcodecTestResults = await runWebcodecTest();

    this.options.socialStickers = this.options.socialStickers.filter(sticker => sticker.imageBitmap || !sticker.ref.imageUrl || !sticker.ref.imageUrl.startsWith('blob'));
    console.log(this.options.socialStickers);


    let isExporting = false;
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(null)
      }, 5000)
    })

    console.log('Start')
    const startExportingPromise = new Promise((resolve, reject) => {
      try {
        console.log(JSON.stringify(this.options))
        this.worker.postMessage({ type: 'start', options: this.options, webcodecTestResults }, transferables);
        isExporting = true;
        resolve(null);
      } catch(e) {
        reject(e);
      }
    })

    try {
      await Promise.race([timeoutPromise, startExportingPromise]);
    } catch(e) {
      console.error(e);
      Sentry.captureException(e);
    }
    if (!isExporting) {
      this.onError?.('Rendering start has a timeout', false)
    }
    this.isExporting = true;
    console.log('is exporting')

    // window.onbeforeunload = () => {
    //   this.terminate();
    //   for (let ix = 0; ix < 100_000; ix ++) {}
    // };
  }

  // this sends all the options to the worker to initialize it for realtime rendering the frames without creating a video file
  initializeForPreview(callback: () => void) {
    this.initializeCallback = callback;
    this.worker.postMessage({ type: 'initialize', options: this.options }, [this.offscreenCanvas]);
  }


  resize(width: number, height: number) {
    this.worker.postMessage({ type: 'resize', width, height });
  }

  // used for video preview
  // the source video frames is the input
  renderVideoFrames(frames: Map<string, VideoFragment>, timestamp: number, width: number, height: number) {
    if (this.isRendering) return;

    this.isRendering = true;
    this.lastRenderFunction = () => this.worker.postMessage({ type: 'render-frame', frames: frames, timestamp, width: width, height: height });
    this.lastRenderFunction();
    this.isRendering = false;
  }

  async updateOptions(
    editorCaptionsStore: ReturnType<typeof useEditorCaptionsStore>,
    stickersStore: ReturnType<typeof useStickersStore>
  ) {

    const stickers = [];

    if (this.live) {
      for (const sticker of stickersStore.entities) {
        if ('imageUrl' in sticker && sticker.imageUrl) {

          let imageElement: HTMLImageElement | ImageBitmap = new Image();

          imageElement.src = sticker.imageUrl;
          const isAppleDevice = navigator.userAgent.includes("Mac");
          if (isAppleDevice && sticker.type === 'text') {
            await imageElement.decode().catch(console.error);
            imageElement = await createImageBitmap(imageElement, { resizeWidth: imageElement.width * 10, resizeHeight: imageElement.height * 10, resizeQuality: 'high' });
          } else {
            await imageElement.decode();
          }
          sticker.bitmap = imageElement;
          stickers.push(({
            ref: sticker,
            imageBitmap: imageElement,
          }))
        } else if (sticker.type === 'text') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.color)
          }))
        } else if (sticker.type === 'brand-kit') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.primaryColor, sticker.secondaryColor)
          }))
        } else if (sticker.type === 'giphy') {
          stickers.push(({
            ref: sticker
          }))
        }
        else if (sticker.type === 'rive') {
          stickers.push(({
            ref: sticker
        }))
      }
      }
    }
    else {
      // we dont need to update the options if not in preview.
      return;
    }

    // editorCaptionsStore.captionPreset.font.highlightColor = editorCaptionsStore.styleOptions.data.highlightColor;
    // editorCaptionsStore.captionPreset.font.color = editorCaptionsStore.styleOptions.data.baseColor;
    
    const projectsStore = useProjectStore();

    if (projectsStore.useLegacyCaptions) {
      this.worker.postMessage({
        type: 'update-options',
        options: {
          captions: computed(() => editorCaptionsStore.captions),
          captionSettings: toRaw(editorCaptionsStore.captionsWrapper),
          captionStylesSettings: computed(() => editorCaptionsStore.captionPreset),
          socialStickers: stickers,
          baseOptions: { value: toRaw(editorCaptionsStore.baseOptions) },
        },
      });
    } else {
      const captionsV2 = useCaptionsStore();
      this.worker.postMessage({
        type: 'update-options',
        options: {
          captions: computed(() => captionsV2.rendererEntities),
          captionSettings: toRaw(captionsV2.captionsArea),
          captionStylesSettings: computed(() => captionsV2.baseCaptionPreset),
          socialStickers: stickers,
          baseOptions: computed(() => captionsV2.baseOptions),
          fonts: computed(() => {
            const mainFont = fontsData.fonts.find(f => f.label === captionsV2.baseCaptionPreset?.font.fontFamily);
            return [mainFont];
          }),
        },
      });
    }
  }

  async decodeAudio(fileUrl: string) {

    console.time('decodeAudio')
    const blob = await (await fetchWithRetry(fileUrl)).blob();

    let decoder;
    try {
      decoder = await getAudioDecoder('/decode-audio.wasm', new File([blob], "audio"));
    } catch(e) {
      if (`${e}`.includes('Failed to locate audio stream')) {
        return {
          rawData: []
        };
      }
      else {
        throw e;
      }
    }

    console.timeEnd('decodeAudio')
    if (!decoder) return {
      rawData: [],
      blobUrl: URL.createObjectURL(blob)
    };

    const channelData = [];

    // decode audio from a low, negative timestamp, because otherwise we miss audio in trimmed videos. (the first audio samples have a negative timestamp)
    const interleavedSamples = decoder.decodeAudioData(-99999999, -1, { multiChannel: true });

    const sampleCount = interleavedSamples.length / decoder.channelCount;

    for (let channelNr = 0; channelNr < decoder.channelCount; channelNr++) {
      channelData[channelNr] = new Float32Array(sampleCount);
    }

    let offset = 0;
    for (let sampleNr = 0; sampleNr < sampleCount; sampleNr++) {
      for (let channelNr = 0; channelNr < decoder.channelCount; channelNr++) {
        channelData[channelNr][sampleNr] = interleavedSamples[offset++];
      }
    }

    // uncomment this to play the raw audio.


    // const audioContext = new window.AudioContext();
    // const audioBuffer = audioContext.createBuffer(decoder.channelCount, sampleCount, decoder.sampleRate);

    // for (let channelNr = 0; channelNr < decoder.channelCount; channelNr++) {
    //   audioBuffer.copyToChannel( channelData[channelNr], channelNr);
    // }

    // // Step 3: Create an AudioBufferSourceNode to play the audio
    // const audioSource = audioContext.createBufferSource();
    // audioSource.buffer = audioBuffer;
  
    // // Connect the source to the AudioContext destination (the speakers)
    // audioSource.connect(audioContext.destination);
  
    // // Play the sound (start)
    // audioSource.start();


    decoder.dispose();

    return {
        sampleRate: decoder.sampleRate,     // Sample rate of the audio
        duration: decoder.duration,         // Duration of the audio
        channels: decoder.channelCount, // Number of channels
        rawData: channelData,        // Array of Float32Arrays containing raw samples for each channel
        blobUrl: URL.createObjectURL(blob)
      };
  }

  createMontage(videoUrls: string[], useHardwareAcceleration: boolean) {
    this.worker.postMessage({
      type: 'create-montage',
      videoUrls,
      useHardwareAcceleration,
    })
  }

  terminate() {
    this.isTerminated = true;
    this.worker.postMessage({
      type: 'terminate'
  });
  }
}
