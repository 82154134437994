/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */

export type JobType = (typeof JobType)[keyof typeof JobType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobType = {
  render: 'render',
  montage: 'montage',
} as const
