<template>
  <div
     ref="toastRef"
    :id="'toast_message_' + id"
    class="group flex min-w-1/2 max-w-[380px] items-center justify-between rounded-lg bg-zinc-200 shadow-2xl dark:bg-zinc-500 my-2 border border-zinc-500 transition-transform"
    :class="{ '!transition-none': isMouseOrTouchDown }"
    @mousedown="handleMouseStart"
    @mousemove="handleMouseMove"
    @mouseup="resetSwipage"
    @mouseleave="resetSwipage"
    @touchstart.passive="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="resetSwipage"
    @touchcancel="resetSwipage"
  >
    <div class="relative w-full">
      <IconSaxCloseCircle
        @click="() => emit('click', id)"
        class="group-hover:opacity-100 group-hover:scale-100 scale-0 opacity-0 group-hover:transition-all absolute -top-2.5 -left-2.5 bg-zinc-200 dark:bg-zinc-500 rounded-full p-0.5 w-6 h-6 text-black/60 dark:text-white/75 shadow-lg cursor-pointer hover:scale-105 dark:hover:text-white hover:text-black transition-all" />
      <div class="overflow-hidden rounded-lg">
        <div class="flex p-3 pl-1 items-center justify-between">
          <div class="flex">
            <div class="flex flex-col items-center justify-center px-3">
              <div
                class="rounded-full p-0.5"
                :class="{
                  'bg-green-50 dark:bg-green-950': status === 'success',
                  'bg-red-100 dark:bg-red-950': status === 'error',
                }"
                :style="{ boxShadow }"
              >
                <IconSaxTickCircle
                  v-if="status === 'success'"
                  class="h-6 w-6 rounded-full fill-green-600 text-green-100 dark:text-green-950"
                />
                <IconSaxCloseCircle
                  v-else-if="status === 'error'"
                  class="text-red h-6 w-6 rounded-full fill-red-500 text-red-100 dark:text-red-950"
                />
                <img
                  src="/images/icons/lightning.svg"
                  v-else-if="status === 'premium'"
                  draggable="false"
                  class="h-6 w-6 rotate-180 rounded-full fill-blue-500 text-blue-100 dark:text-blue-950"
                  alt="lightning"/>
                <IconSaxInfoCircle
                  v-else
                  class="h-6 w-6 rotate-180 rounded-full fill-blue-500 text-blue-100 dark:text-blue-950"
                />
              </div>
            </div>
            <div class="flex flex-col cursor-default" :class="{ 'max-w-[250px]': view || retry }">
              <p class="mb-0 text-black dark:text-white select-none">
                {{ status === 'success' ? '🎉' : '' }}
                {{ title }}
              </p>
              <p class="mb-0 text-xs font-light text-black/50 dark:text-white/70 select-none" v-if="subtitle">
                {{ subtitle }}
              </p>
            </div>
          </div>
          <Button variant="link" class="text-xs p-2">
            <span v-if="view" @click="() => { view!(id); removeMessage(id); }">{{ viewTitle || 'View' }}</span>
            <span v-else-if="retry" @click="() => { retry!(id); removeMessage(id); }">{{ retryTitle || 'Retry' }}</span>
          </Button>
        </div>
        <ProgressRoot
          v-if="timeout"
          v-model="progressValue"
          class="absolute top-full -mt-[1px] overflow-hidden bg-blackA9 rounded-b-full w-[calc(100%-4px)] h-[2px]"
          style="transform: translateZ(0)"
        >
          <ProgressIndicator
            class="bg-brand-state-link w-full h-full transition-transform duration-100 ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
            :style="`transform: translateX(${progressValue}%)`"
          />
        </ProgressRoot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue';
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue';
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue';
import { ProgressIndicator, ProgressRoot } from 'radix-vue';
import { Button } from '@/components/ui/button';
import { useUserInfoStore } from '@/store/user/userInfo';
import IconSaxCloudLightning from '@/components/Icons/iconsax/IconSaxCloudLightning.vue'

const toastRef = ref<HTMLElement | null>(null);

const userInfoStore = useUserInfoStore();
const userIsAuthenticated = computed(() => userInfoStore.isAuthenticated);

const props = defineProps<{
  id: number;
  status?: string;
  title: string;
  subtitle?: string;
  view?: (id: number) => void;
  viewTitle?: string;
  retry?: (id: number) => void;
  retryTitle?: string;
  timeout?: number | null;
  removeMessage: (id: number) => void;
}>();

const emit = defineEmits<{
  (event: 'click', id: number): void
}>();

const progressValue = ref(0);
const interval = ref(0);
const startDateTime = ref(Date.now());

const startProgress = () => {

  progressValue.value = 0;
  startDateTime.value = Date.now();

  if (props.timeout) {
    interval.value = setInterval(() => {
      const elapsedTime = Math.min(Date.now() - startDateTime.value, props.timeout!);
      progressValue.value = Math.min((elapsedTime / props.timeout!) * 100, 100);
      if (progressValue.value === 100) {
        clearInterval(interval.value);
      }
    }, 100);
  }
};

onMounted(startProgress);

onBeforeUnmount(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});

const boxShadow = computed(() => {
  switch (props.status) {
    case 'success':
      return 'rgb(0 250 165 / 10%) 0px 0px 20px 20px';
    case 'error':
      return 'rgba(255, 0, 0, 0.06) 0px 0px 20px 20px';
    default:
      return 'rgb(87 159 235 / 19%) 0px 0px 20px 20px';
  }
});

const isMouseOrTouchDown = ref(false);
const startPosition = ref({ x: 0, y: 0 });

const handleMouseStart = (event: MouseEvent) => {
  startPosition.value = { x: event.clientX, y: event.clientY };
  isMouseOrTouchDown.value = true;
};

const handleTouchStart = (event: TouchEvent) => {
  startPosition.value = { x: event.touches[0].clientX, y: event.touches[0].clientY };
  isMouseOrTouchDown.value = true;
};

const handleMouseMove = (event: MouseEvent) => {

  if (!isMouseOrTouchDown.value) {
    return;
  }

  if (toastRef.value) {
    const deltaX = event.clientX - startPosition.value.x;
    moveOrRemoveToast(deltaX);
  }
};

const handleTouchMove = (event: TouchEvent) => {

  if (!isMouseOrTouchDown.value) {
    return;
  }

  if (toastRef.value) {
    const deltaX = event.touches[0].clientX - startPosition.value.x;
    moveOrRemoveToast(deltaX);
  }
};

const moveOrRemoveToast = (deltaX: number) => {

  if (toastRef.value) {

    // Is swiping to the right
    if (deltaX > -25) {
      toastRef.value.style.transform = `translateX(${deltaX}px)`;
    }

    if (deltaX > 150) {
      props.removeMessage(props.id);
    }
  }
};

const resetSwipage = () => {
  isMouseOrTouchDown.value = false;
  if (toastRef.value) {
    toastRef.value!.style.transform = 'translateX(0px)';
  }
};
</script>

<style scoped></style>
