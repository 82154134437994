<template>
  <div>
    <slot name="button" @click="resumeSubscription" :disabled="loading">
      <button class="btn-primary btn rounded shadow-none" @click="resumeSubscription" :disabled="loading">
        Resume my subscription
      </button>
    </slot>

    <tw-base-dialog styles="max-w-lg" v-model="showConfirmDialog">
      <div class="flex w-full flex-col items-center justify-center p-8 text-center">
        <p class="leading-2 mb-0 text-lg">Resume subscription</p>
        <p class="mb-4 opacity-50">
          You are about to resume your subscription. You will be billed
          {{ currentSubscriptionProduct.totalPrice }} immediately.
        </p>
        <div class="flex space-x-4">
          <button class="rounded-full bg-gray-300 px-4 py-2 text-company-primary-300 hover:bg-gray-400" @click="close">
            No
          </button>
          <button
            class="rounded-full bg-company-primary-300 px-4 py-2 text-white hover:bg-company-primary"
            @click="confirmResume"
            :disabled="loading"
          >
            Yes
          </button>
        </div>
      </div>
    </tw-base-dialog>
  </div>
</template>

<script>
import TwBaseDialog from '../../Dialog/TwBaseDialog.vue'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import { useProductsStore } from '@/store/products'
import { mapState } from 'pinia/dist/pinia'
import { putApiSubscriptionIdResume } from '@/apis/streamladder-accounts/subscription/subscription'
import * as Sentry from '@sentry/vue'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'

export default {
  components: {
    TwBaseDialog,
  },
  data() {
    return {
      showConfirmDialog: false,
      showErrorDialog: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(useUserSubscriptionStore, ['isLoading', 'subscription', 'isPaddleSubscription']),
    ...mapState(useProductsStore, ['products', 'isLoadingPrices']),
    currentSubscriptionProduct() {
      if (this.isLoadingPrices || !this.subscription.subscriptionPlanId) {
        return null
      }
      return this.products.find((x) => x.productId == this.subscription.subscriptionPlanId)
    },
  },
  methods: {
    async resumeSubscription() {

      // If user is charged immediately, show the confirm Dialog
      const resumeDate = new Date(this.subscription.resumePayment?.date);
      const today = new Date();

      if (resumeDate < today) {
        this.showConfirmDialog = true;
      } else {
        await this.confirmResume();
      }
    },
    async confirmResume() {
      try {
        this.loading = true

        const response = await putApiSubscriptionIdResume(this.subscription.id);

        if (!response.isSuccess) {
          this.showConfirmDialog = false
          this.loading = false
          console.error(JSON.stringify(this.subscription));
          Sentry.captureException(new Error('Resuming plan failed'));
          const message = response.error.errorCode === 502
              ? response.error.errorMessage
              : "We couldn't resume your subscription. Please contact support.";
          EventBus.$emit(mainEvents.ERROR, message);
        } else {
          this.showConfirmDialog = false
          this.loading = false
          // refresh the page to see the changes
          location.reload()
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException('Resuming plan failed', e);

        this.showConfirmDialog = false;

        EventBus.$emit(mainEvents.ERROR, "We couldn't resume your subscription. Please contact support.");
        this.loading = false;
      }
    },
    close() {
      this.showConfirmDialog = false
    },
  },
}
</script>

<style></style>