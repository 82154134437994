<script lang="ts" setup>

import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { FocusTypes, useEditorFocusStore, type FocusType } from '@/store/editor/editorFocus'
import { colorOptions, stringToSemiRandomNumber, removeTags } from '@/components/Editor/Timeline/tracks/stickerHelpers'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxTextBlock from '@/components/Icons/iconsax/IconSaxTextBlock.vue'
import IconSaxSticker from '@/components/Icons/iconsax/IconSaxSticker.vue'
import { computed } from 'vue'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';

const props = defineProps<{ id: string, focusType: FocusType }>()

const editorFocusStore = useEditorFocusStore()
const editorVideoStore = useEditorVideoStore()
const historyStore = useHistoryStore()

const stickersStore = useStickersStore()
const sticker = stickersStore.selectById(props.id)
const focused = stickersStore.selectFocusById(props.id)

const { durationMs } = useTimelineStore()

const semiRandomColor = computed(() => colorOptions[Math.floor(stringToSemiRandomNumber(sticker.key, 0, colorOptions.length))])
</script>

<template>
  <TimeLineTrackNode
    :active="focused"
    :class="focused 
      ? 'border-[var(--random-bg-color)] bg-[var(--random-bg-color)] ring-[var(--random-border-color)] hover:border-[var(--random-border-color)] hover:bg-[var(--random-border-color)]'
      : 'border-[var(--random-bg-color)] bg-[var(--random-bg-color)] opacity-50 ring-[var(--random-border-color)] hover:border-[var(--random-border-color)] hover:opacity-80'"
    :end-ms="sticker.endMs"
    :height="25"
    :max-end-ms="durationMs"
    :min-start-ms="0"
    :start-ms="sticker.startMs"
    :style="{
      '--random-bg-color': semiRandomColor.bg,
      '--random-border-color': semiRandomColor.border,
      '--random-handle-color': semiRandomColor.handle,
    }"
    class="group cursor-grab rounded-[5px] border-solid outline-white ring-offset-2"
    seek-behavior="seek"
    @activated="() => {
      editorFocusStore.setFocus(focusType, sticker.id)
      editorVideoStore._currentTime = sticker.startMs / 1000 + 0.01
    }"
    @resizeStop="(startMs, endMs) => historyStore.transaction('STICKER:UPDATE_DURATION', () => stickersStore.updateTimingsById(sticker.id, startMs, endMs))"
    @dragStop="(startMs, endMs) => historyStore.transaction('STICKER:UPDATE_START_TIME', () => stickersStore.updateTimingsById(sticker.id, startMs, endMs))"
  >
    <div class="flex h-full w-full items-center overflow-hidden rounded-[5px] text-sm text-white">
      <span class="mx-3 inline-flex max-h-full w-full select-none flex-row items-center justify-center gap-1 overflow-hidden whitespace-nowrap py-0.5">
        <IconSaxTextBlock v-if="focusType === FocusTypes.TEXTSTICKER" class="mb-0.5 h-4 w-4" />
        <IconSaxSticker v-else class="mb-0.5 h-4 w-4" />
        
        <span v-if="'textContent' in sticker">{{ removeTags(sticker.textContent) }}</span>
      </span>
    </div>
    <template #handles>
      <div class="flex h-full flex-row gap-0.5 px-1 py-1">
        <div :class="sticker.key ? 'bg-gray-800' : 'bg-[var(--random-handle-color)]'" class="h-full w-1 rounded" />
        <div :class="sticker.key ? 'bg-gray-800' : 'bg-[var(--random-handle-color)]'" class="h-full w-1 rounded" />
      </div>
    </template>
  </TimeLineTrackNode>
</template>

<style lang="scss" scoped>

</style>
