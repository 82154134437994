import { noop } from 'ts-essentials';

// our "patched" version of rive.js renderer
import '../rive/SL_rive';
import animations from './captions/caption-animations'
import { clamp } from 'lodash-es';
import { Fit, Layout, type Rive, FileAsset } from '@rive-app/canvas'
import { Alignment } from '@rive-app/canvas'
import type { RiveSticker } from '@/areas/editor/@type/Project';
import { RiveDefinitions } from '../rive/rive-definitions';

const artboardName = '32';


export class RiveRenderer {

    canvas: HTMLCanvasElement;
    riveCanvas: OffscreenCanvas;
    animationCanvas: OffscreenCanvas;

    ctx: CanvasRenderingContext2D;
    riveCanvasCtx: OffscreenCanvasRenderingContext2D;
    animationCanvasCtx: OffscreenCanvasRenderingContext2D;
    rive: Rive;
    previousTime: number | null;

    artboard = '';

    loadedResolve = noop;
    loadedPromise = new Promise(resolve => {
        this.loadedResolve = resolve;
    })

    lastRender = noop;
    firstRender = true;

    constructor(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, riveUrl: string, decodeImageInMainThread: (src: string) => Promise<ImageBitmap>) {

        this.canvas = canvas;
        this.ctx = ctx;
        this.previousTime = null;

        // @ts-ignore
        self.decodeImage = decodeImageInMainThread;


        this.riveCanvas = new OffscreenCanvas(100, 100);
        this.riveCanvasCtx = this.riveCanvas.getContext('2d')!;

        this.animationCanvas = new OffscreenCanvas(100, 100)
        this.animationCanvasCtx = this.riveCanvas.getContext('2d')!;


        this.rive = new (self as any).rive.Rive({
            src: riveUrl,
            canvas: this.riveCanvas,
            autoplay: true,
            // animations: 'kick',
            stateMachines: 'lowerthird',
            layout: new Layout({ 
                alignment: Alignment.Center,
                fit: Fit.None,
            }),
            onLoad: () => {
                const stateMachine = this.rive.stateMachineNames[0];
                this.rive.initArtboard(artboardName, [], [stateMachine], true);
                // this.rive.animator.add('lowerthird', true);
                this.loadedResolve();
            },
            // assetLoader: (asset: FileAsset, bytes: Uint8Array) => {
            //     console.log(asset.name, bytes);

            //     if (asset.name === 'Image 221x130') {
            //         // fetch('/106409223060604071010.jpg').then(async res => {
            //         //     const bytes = await res.arrayBuffer();
            //         //     const image = await self.rive.decodeImage(new Uint8Array(bytes));
            //         //     asset.setRenderImage(image);
            //         // })
            //         // return true;
            //     }
            // }
        });
    }

    setText(text: string) {
        const textRunCount = this.rive.artboard.textValueRunCount();
        const textRun = this.rive.artboard.textValueRunByIndex(textRunCount - 1);
        if (textRun) textRun.text = text ?? '';
    }

    render(sticker: RiveSticker, timestamp: number) {

        if (!this.rive?.loaded) return;

        const { area, scale } = sticker;

        if (timestamp > sticker.endMs * 1e3) {
            return;
        }

        if (this.artboard !== sticker.artboard) {

            this.rive.reset();
            // this.riveCanvas.colors = {};

            const stateMachine = this.rive.stateMachineNames[0];
            this.rive.initArtboard(sticker.artboard ?? artboardName, [], [stateMachine], true);

            this.rive.initArtboard(sticker.artboard ?? artboardName, [], [this.rive.stateMachineNames[0]], true);
            // this.rive.animator.add('lowerthird', true);

            this.artboard = sticker.artboard;

            const stateMachineInputs = this.rive.stateMachineInputs(this.rive.stateMachineNames[0]);

            const artboard = this.rive.artboard;
            const riveDefinition = RiveDefinitions[artboard.name];
            if (riveDefinition?.defaultStateMachine) {
                for (const [name, value] of riveDefinition.defaultStateMachine) {
                    const state = stateMachineInputs?.find(inputState => inputState.name === name);
                    if (state) state.value = value;
                }
            }

            // if (self.stateMachineInputs) {
            //     for (let ix = 0; ix < stateMachineInputs?.length; ix++) {
            //         if (self.stateMachineInputs[ix]) {
            //             stateMachineInputs[ix].value = self.stateMachineInputs[ix].value;
            //         }
            //     }
            // }
            // if (sticker.stateMachineInputs?.length) {
            //     for (let ix = 0; ix < sticker.stateMachineInputs.length; ix++) {
            //         if (stateMachineInputs[ix].value) stateMachineInputs[ix].value = ix;
            //     }
            // }

            // self.stateMachineInputs = stateMachineInputs;
        }

        // if (sticker.colors) {
        //     this.riveCanvas.colors = sticker.colors;
        // }

        // if (sticker.icon) {
        //     this.rive.stateMachineInputs('lowerthird').find(input => input.name === 'input social').value = sticker.icon;
        // }

        if (this.canvas && this.rive?.loaded) {

            this.setText(sticker.textContent);
            const artboard = this.rive.artboard;
            const riveDefinition = RiveDefinitions[artboard.name];

            const hasInAnimation = true;
            const inAnimationDuration = (riveDefinition.animationDuration ?? 0) * 1e3;
            const inAnimationStartTime = 0;
            const startTime = sticker.startMs;

            const riveTimeStamp = (timestamp / 1e3 - startTime) - inAnimationStartTime;

            // animations('fade-in')(this.ctx, riveTimeStamp / 1e3);

            const startFadingOut = sticker.endMs - inAnimationDuration;
            const fadeOutTimeStamp = 1.0 - clamp((timestamp / 1e3 - startFadingOut) / 1e3, 0.0, 1.0);

            if (fadeOutTimeStamp < 1.0) {
                // fade out
                // animations('fade-in')(this.ctx, fadeOutTimeStamp);
            }

            if (this.previousTime == null) {
                this.previousTime = riveTimeStamp;
            }

            if (riveTimeStamp >= 0.0) {
                const artboard = this.rive.artboard;
                const riveDefinition = RiveDefinitions[artboard.name];

                self.riveDefinition = riveDefinition;

                const topL = artboard.bone("Top left")?.worldTransform();
                const botL = artboard.bone("Bottom left")?.worldTransform();
                const botR = artboard.bone("Bottom right")?.worldTransform();
                const topR = artboard.bone("Top right")?.worldTransform();


                if (!topL || !botR || !botL || !topR) {
                    console.warn("bones not found");
              
                    this.riveCanvas.width = artboard.bounds.maxX;
                    this.riveCanvas.height = artboard.bounds.maxY;
                  } else {

                    const x = [botL.tx, topL.tx, botR.tx, topR.tx];
                    const y = [botL.ty, topL.ty, botR.ty, topR.ty];

                    const boundingBox = {
                      x1: Math.min(...x),
                      y1: Math.min(...y),
                      x2: Math.max(...x),
                      y2: Math.max(...y),
                    };
              
                    if (topL && botR) {
                        this.riveCanvas.width = Math.max(boundingBox.x2 - boundingBox.x1 + (riveDefinition?.widthOffset ?? 0), riveDefinition?.minWidth || 10);
                        this.riveCanvas.height = Math.max(boundingBox.y2 - boundingBox.y1, riveDefinition?.heightOffset ?? 0, riveDefinition?.minHeight || 10);
                    }
                  }
                
                
                this.rive.layout = new Layout({
                    fit: Fit.None,
                    alignment: Alignment.Center,
                    minX: riveDefinition?.xOffset ?? 0,
                    minY: riveDefinition?.yOffset ?? 0,
                    maxX: this.riveCanvas.width,
                    maxY: this.riveCanvas.height
                })

                this.rive.artboard.bounds.minX = riveDefinition?.xOffset ?? 0;
                this.rive.artboard.bounds.minY = 0//riveDefinition?.yOffset ?? 0;
                this.rive.artboard.bounds.maxX = this.riveCanvas.width;
                this.rive.artboard.bounds.maxY = this.riveCanvas.height;
                

                if (hasInAnimation && riveTimeStamp > inAnimationDuration) {
                    this.rive.runtime.requestAnimationFrame(() => this.rive.draw(riveTimeStamp, inAnimationDuration));
                    this.previousTime = riveTimeStamp;
                }
                else {
                    this.rive.runtime.requestAnimationFrame(() => this.rive.draw(riveTimeStamp, riveTimeStamp - this.previousTime!));
                }

                const aspectRatio = this.riveCanvas.width / (area.width * this.canvas.width + (riveDefinition.xOriginOffset ?? 0) * area.width * this.canvas.width);
                const renderHeight = this.riveCanvas.height / aspectRatio;
                

                this.ctx.save();
                
                const x = area.x * this.canvas.width;
                const y = area.y * this.canvas.height - (renderHeight - area.height * this.canvas.height) / 4;
                const w = area.width * this.canvas.width;

                let renderX = -w/2;
                let renderY = -renderHeight/2;

                this.ctx.translate(x + w / 2, y + renderHeight / 2);

                if (sticker.animation) {

                    const offsets = animations(sticker.animation)?.(this.ctx, riveTimeStamp / 500, timestamp);

                    if (offsets?.yOffset) {
                        renderY += offsets.yOffset;
                    }

                    if (offsets?.xOffset) {
                        renderX += offsets.xOffset;
                    }
                }

                if (!this.firstRender) {
                    this.ctx.drawImage(this.riveCanvas, 0, 0, this.riveCanvas.width, this.riveCanvas.height, renderX, renderY, w + (riveDefinition.xOriginOffset ?? 0) * w, renderHeight);
                }
                this.ctx.restore();
            }
            else if (this.previousTime > riveTimeStamp) {
                // advance back to start
                this.rive.runtime.requestAnimationFrame(() => this.rive.draw(riveTimeStamp, -9999999));
            }
    
    
            this.previousTime = riveTimeStamp;
    
        }

        this.ctx.globalAlpha = 1.0;
        this.lastRender = () => this.render(sticker, timestamp);

        if (this.firstRender) {
            this.firstRender = false;
            this.rive.runtime.requestAnimationFrame(() => this.rive.draw(0, 0));
            this.lastRender();
        }
    }
}
