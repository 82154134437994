import { useStorage } from '@vueuse/core';

let defaultZoomLevel = 0.5;
const storedDefaultZoomLevel = localStorage.getItem('default-zoom-level');
if (storedDefaultZoomLevel) {
  defaultZoomLevel = Number(storedDefaultZoomLevel);
  localStorage.removeItem('default-zoom-level');
}

const preference = useStorage('zoom-preference', {
  x: 0.5,
  y: 0.5,
  level: defaultZoomLevel
})

export function useZoomPreference() {
  return preference;
}
