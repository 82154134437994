<script setup lang="ts">
import { orderBy } from 'lodash-es'
import { ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import type { LayoutPreset } from '@/areas/editor/@data/layouts'
import StickerPreview from '@/areas/editor/views/layouts/StickerPreview.vue'

defineProps<{ preset: LayoutPreset }>()

function imageUrlOf(name: string) {
  if (name === 'Facecam Right') {
    return '/images/images/streamer.png'
  } else if (name.includes('Facecam')) {
    return '/images/images/streamer.png'
  } else if (name.includes('Game UI')) {
    return '/images/svgs/game-ui.svg'
  } else if (name.includes('Map')) {
    return '/images/svgs/game-ui.svg'
  } else {
    return '/images/images/shooter.png'
  }
}

const container = ref<HTMLElement>()
const { width, height } = useElementSize(container)
</script>

<template>
  <div ref="container" class="absolute inset-0 pointer-events-none transition-[filter] rounded-lg overflow-hidden bg-white">
    <img draggable="false" alt="blurred background" src="/images/images/shooter.png" 
      class="object-cover rounded-lg blur opacity-30 absolute -inset-12 w-[calc(100%_+_6rem)] h-[calc(100%_+_6rem)]" 
    />
    <template v-for="crop of orderBy(preset.crops, 'z')" :key="crop.id">
      <img draggable="false"
        :alt="crop.input.name"
        :src="imageUrlOf(crop.input.name)"
        class="absolute select-none"
        :class="[
          crop.input.shape === 'circle' ? 'rounded-full' : '',
          crop.input.name.includes('Facecam') ? 'bg-[#33cff2] object-contain' : 'object-cover',
        ]"
        :style="{ 
          top: crop.feedData.y * 100 + '%', 
          left: crop.feedData.x * 100 + '%', 
          width: crop.feedData.width * 100 + '%', 
          height: crop.feedData.height * 100 + '%' 
        }"
      />
    </template>
    <template v-for="sticker of preset.stickers" :key="sticker.id">
      <StickerPreview :render-width="width" :render-height="height" :sticker="sticker" />
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>
