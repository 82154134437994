<script setup lang="ts">
import { getDisplayPrice, useProductsStore } from '@/store/products'
import { computed } from 'vue'
import type { TierLevel } from '@/data/plans'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'

const props = defineProps<{
  tier: TierLevel
  interval: 'year' | 'month'
  isSelected: boolean
}>()

const productsStore = useProductsStore()

const plan = computed(() => {

  if (productsStore.isLoadingPrices) {
    return null
  }

  const plans = productsStore.productData[props.tier].plans
  return plans.find((p) => p.key === props.interval)
})

const currency = computed(() => {
  return productsStore.products?.find((p) => p.currency)?.currency
})

const displayPrice = computed(() => {

  if (plan.value?.paddleData && currency.value) {
    if (props.interval === 'year') {
      return getDisplayPrice(plan.value.paddleData.totalPriceRaw / 12, currency.value, 2);
    } else {
      return getDisplayPrice(plan.value.paddleData.totalPriceRaw, currency.value, 2);
    }
  }

  if (currency.value) {
    return getDisplayPrice(0, currency.value)
  }

  return undefined
})

const displayPriceSummary = computed(() => {

  if (plan.value?.paddleData && currency.value) {
    if (props.interval === 'year') {
      return getDisplayPrice(plan.value.paddleData.totalPriceRaw, currency.value, 2);
    } else {
      return getDisplayPrice(plan.value.paddleData.totalPriceRaw, currency.value, 2);
    }
  }

  if (currency.value) {
    return getDisplayPrice(0, currency.value)
  }

  return undefined
})
</script>

<template>
  <div class="flex flex-row items-center justify-between w-full">
    <div class="flex flex-row items-center gap-4 px-4">
      <div class="flex flex-col gap-1">
        <p class="text-xl"> Billed {{ interval === 'year' ? 'Yearly' : 'Monthly' }} </p>
        <p v-if="interval === 'year'" class="rounded bg-[#DAE8FF] text-[#008000] py-0.5 px-1.5">Save 20% 👌</p>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center px-4 py-2">
      <p class="text-sm">Price per month</p>
      <p class="font-bold text-3xl">{{ displayPrice }}</p>
      <p v-if="interval === 'year'" class="text-xs font-light">({{ displayPriceSummary }} per year)</p>
    </div>
  </div>

  <div
    :class="{ 'shadow-xl': interval === 'year' }"
    class="group-hover:shadow-gradient mt-2 relative h-12 rounded-full transition-all before:transition-all xl:h-10 2xl:h-12"
  >
    <div class="bg-gradient absolute w-full rounded-full">
      <button
        :class="
          interval === 'year'
            ? 'bg-black text-white'
            : 'btn-outline border-2 bg-white text-black group-hover:border-black group-hover:bg-black group-hover:text-white'
        "
        class="btn-lg btn w-full flex-nowrap gap-2 whitespace-nowrap font-bold mix-blend-lighten xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
      >
        Select {{ interval === 'year' ? 'Yearly' : 'Monthly' }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dot {
  animation-duration: 500ms;
  animation-iteration-count: infinite;
}

.dot-1 {
  animation-name: dot-1;
}

.dot-2 {
  animation-name: dot-2;
}

.dot-3 {
  animation-name: dot-2;
}

.dot-4 {
  animation-name: dot-3;
}

@keyframes dot-1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 0.75;
    transform: scale(1);
  }
}

@keyframes dot-2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes dot-3 {
  0% {
    opacity: 0.75;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
