<script lang="ts" setup>
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { ref } from 'vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import { Input } from "@/components/ui/input";
import Spinner from '@/components/Icons/Spinner.vue'
import { useClipUrlValidator } from '@/Hooks/clip-form/useClipUrlValidator'
import { posthog } from 'posthog-js'
import logging from '@/logging'

const clipUrl = ref<string>()
const loading = ref(false);

const emit = defineEmits<{
  (event: 'selected', payload: { source: string; id: string }): void
}>()

function onClipSelected(source: string, clipId: string) {
  emit('selected', { source: source, id: clipId })
}

const validateClipUrl = useClipUrlValidator()
const error = ref<{ title: string, message: string } | null>(null)
async function submitClipUrl(clipUrl: string | undefined) {

  if (!clipUrl) {
    return
  }

  const result = validateClipUrl(clipUrl)
  if (result.error) {
    error.value = result.error
  } else {
    const { source, clipId } = result.route.params;
    logging.trackEvent('Clip Downloader URL Submitted', { source: source })
    onClipSelected(source, clipId)
  }
}

const handleSubmitClipUrl = async (clipUrl: string | undefined) => {
  loading.value = true
  await submitClipUrl(clipUrl)
  loading.value = false
}

async function onPaste() {
  setTimeout(() => handleSubmitClipUrl(clipUrl.value), 0)
}
</script>

<template>
  <section class="flex flex-col w-full gap-4 light">
    
    <div class="flex flex-col md:flex-row items-center gap-2 w-full">
      <Input
        as="form"
        class="w-full relative p-0 border-none"
        :class="{ 'outline outline-2 outline-offset-1 outline-rose-500': error }"
        @submit.prevent.stop="handleSubmitClipUrl(clipUrl)"
      >
        <div class="shrink-0 pr-0 absolute left-3 top-2.5 text-rose-500" v-if="error">
          <IconSaxInfoCircle />
        </div>
        <input
          v-model="clipUrl"
          @paste="onPaste"
          class="rounded-r-none bg-transparent min-w-0 flex-1 h-full px-3"
          :class="{ 'pl-11 text-rose-500': error }"
          placeholder="Paste the URL of a YouTube, Twitch or Kick clip here"
          type="text"
        />
        <button
          class="group flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-l-none rounded-r-md bg-emerald-500 px-6 py-3 text-surface-inverse transition-colors"
          :class="{ 'pointer-events-none': loading }"
          type="submit"
        >
          <template v-if="loading">
            <span class="hidden md:inline">Loading..</span>
            <Spinner class="h-4 w-4 animate-spin mr-2.5" />
          </template>
          <template v-else>
            <span class="hidden md:inline">Get clip</span>
            <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-2 md:mr-2" />
          </template>
        </button>
      </Input>
    </div>

    <ClipUrlError :error="error" />
  </section>
</template>

<style lang="scss" scoped>
input:placeholder-shown {
  text-overflow: ellipsis;
}
</style>
