<script setup lang="ts">
import UploadWorkspace from '@/areas/editor/workspaces/UploadWorkspace.vue';
import { useRoute, useRouter } from 'vue-router';
import UploadWorkspaceMobile from '@/areas/editor/workspaces/UploadWorkspaceMobile.vue';
import { useIsMobile } from '@/Hooks/useIsMobile';
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';

const route = useRoute();
const router = useRouter();
onMounted(async () => {

  if (typeof route.query.s !== 'string') {
    return;
  }

  function decodeFromBase64(base64Str: string) {
    const binaryString = atob(base64Str);
    const binaryData = new Uint8Array([...binaryString].map(char => char.charCodeAt(0)));
    const decoder = new TextDecoder();
    return decoder.decode(binaryData);
  }
  
  const json = JSON.parse(decodeURIComponent(decodeFromBase64(route.query.s)));
  await router.push({
    name: 'editor/[clipSource]/[clipId]',
    params: { clipSource: json.source, clipId: json.id },
    query: { s: route.query.s }
  });
})

const isMobile = useIsMobile()
const historyStore = useHistoryStore();
onMounted(() => historyStore.$reset());
</script>

<template>
  <div v-if="isMobile" class="flex items-center justify-center w-full h-full bg-zinc-200 dark:bg-zinc-100 overflow-hidden">
    <UploadWorkspaceMobile />
  </div>
  <main v-else class="flex items-center justify-center p-8 w-full h-full">
    <UploadWorkspace />
  </main>
</template>

<style scoped lang="scss">

</style>
