import { useDateTimeNow } from '@/Hooks/dates/useDateTimeNow';
import { computed, toValue, type MaybeRefOrGetter, type ComputedRef } from 'vue';
import { useUserInfoStore } from '@/store/user/userInfo';
import type { Countdown } from '@/components/ui/countdown/Countdown';

const secondInMs = 1000;
const minuteInMs = secondInMs * 60;
const hourInMs = minuteInMs * 60;
const dayInMs = hourInMs * 24;

export function useCountdown(start: Date, end: Date): { [key in keyof Countdown]: ComputedRef<Countdown[key]> } {
  
  const now = useDateTimeNow();
  
  const remaining = computed(() => {
    
    if (now.value >= end || now.value <= start) {
      return 0;
    }
    
    return end.getTime() - now.value.getTime();
  })

  const days = computed(() => Math.floor((remaining.value) / dayInMs));  
  const hours = computed(() => Math.floor((remaining.value % dayInMs) / hourInMs));
  const minutes = computed(() => Math.floor((remaining.value % hourInMs) / minuteInMs));
  const seconds = computed(() => Math.floor(remaining.value % minuteInMs / 1000));
  const ms = computed(() => remaining.value % 1000);
  
  function padded(n: MaybeRefOrGetter<number>, padding: number = 2) {
    return toValue(n).toString().padStart(padding, '0');
  }
  
  const countdown = computed(() => {
    return `${days.value}:${padded(hours)}:${padded(minutes)}:${padded(seconds)}:${padded(ms, 3)}`;
  });

  return {

    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    ms: ms,

    remaining: remaining,
    countdown: countdown,
  }
}
