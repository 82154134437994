<script setup lang="ts">
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import StickerElementToolbar from '@/areas/editor/workspaces/preview/layers/stickers/StickerElementToolbar.vue'
import ConnectedStickerElement from '@/areas/editor/workspaces/preview/layers/stickers/ConnectedStickerElement.vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxArrowUp2 from '@/components/Icons/iconsax/IconSaxArrowUp2.vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'

const props = defineProps<{ id: string; snappingEnabled: boolean }>()

const stickersStore = useStickersStore()
const sticker = stickersStore.selectById(props.id)

const focused = stickersStore.selectFocusById(props.id)

function setFocus() {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => stickersStore.focus(props.id), 0)
}

function handleKeyPress(event: KeyboardEvent) {

  if (!focused.value || sticker.editing) return

  if (event.ctrlKey && event.key === 'd') {
    stickersStore.duplicateById(props.id)

    event.preventDefault()
    return false
  }

  if (event.ctrlKey || event.metaKey) {
    if (event.key === '}') {
      stickersStore.moveToForeground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === '{') {
      stickersStore.moveToBackground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === ']') {
      stickersStore.shift(props.id, +1)

      event.preventDefault()
      return false
    } else if (event.key === '[') {
      stickersStore.shift(props.id, -1)

      event.preventDefault()
      return false
    }
  }

  if (event.key === 'Escape') {
    if (focused.value) {
      useEditorFocusStore().unFocus()
      event.preventDefault()
      return false
    }
  }
}

onMounted(() => {
  window.addEventListener('keypress', handleKeyPress)
  window.addEventListener('keydown', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keypress', handleKeyPress)
  window.removeEventListener('keydown', handleKeyPress)
})

const mouseOver = ref(false)

const style = computed(() => {
  return ({
    left: sticker.area.x * 100 + '%',
    top: sticker.area.y * 100 + '%',
    width: sticker.area.width * 100 + '%',
    height: sticker.area.height * 100 + '%',
  })
})

const historyStore = useHistoryStore()
const { isLayoutsStep } = useEditorStep()

const videoStore = useVideoStore();

const stickerIsVisibleAtCurrentTime = computed(() => {
  return videoStore.currentTimeMs >= sticker.startMs && videoStore.currentTimeMs <= sticker.endMs;
});

const isDisabled = computed(() => {
  return isLayoutsStep.value || (!focused.value && !stickerIsVisibleAtCurrentTime.value)
});
</script>

<template>
  <div
    @dblclick="stickersStore.setEditModeById(props.id, true)"
    :style="style"
    class="group absolute transition-opacity select-none"
    :class="isDisabled ? '-z-50' : focused ? 'z-[51]' : 'z-50'"
    @click.stop
    @touchstart.stop.passive="setFocus"
    @mousedown.stop="setFocus"
    @mouseover="mouseOver = true"
    @mouseleave="mouseOver = false"
  >
    <MovableElement :id="sticker.id" :move="!isDisabled" :resize="!isDisabled" class="absolute inset-0"
      v-model="sticker.area"
      shape="rectangle"
      @resize-end="historyStore.transaction('STICKER:RESIZE')"
      @move-end="historyStore.transaction('STICKER:MOVE')"
      :disableArrowKeys="sticker.editing || !focused"
      :aspectLock="{ width: sticker.naturalWidth, height: sticker.naturalHeight }"
      :bounds="{ top: -0.10, right: 1.30, bottom: 1.10, left: -0.30 }"
      :snap="snappingEnabled"
      :min-size="0"
      :resize-handle-class="focused ? 'z-[250]' : ''"
      :handles="['e', 'w']"
    >
      <Teleport to="#sticker-layer">
        <div class="absolute" :class="{ 'z-[250]': sticker.editing }" :style="style">
          <ConnectedStickerElement :id="id" ref="stickerElementRef" :focus="mouseOver || focused" />
        </div>
      </Teleport>
      <template #move>
        <div
          class="absolute -inset-px border-2 transition-[opacity] border-brand-state-active-border box-content"
          :class="{
            'opacity-100': focused,
            'opacity-0 group-hover:opacity-75': !focused,
            '!opacity-0': sticker.editing
          }"
        />
      </template>
      <template #resize-direction="{ angle }">
        <div
          :class="focused && !sticker.editing ? 'opacity-100' : 'opacity-0'"
          class="w-5 h-5 grid place-items-center rounded-full layer-1 transition-[opacity] text-brand-state-active-border drop-shadow"
        >
          <IconSaxArrowUp2 class="w-3 h-3" :style="{ transform: `rotate(${angle}deg)`}" />
        </div>
      </template>
    </MovableElement>

    <StickerElementToolbar v-if="focused && !isLayoutsStep" :id="id" />
  </div>
</template>

<style scoped lang="scss"></style>
