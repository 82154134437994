import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { retryAsync } from '@/helpers/retry'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import type { ClipDto } from '../@type/ClipDto'
import {getApiTwitchClipsClipId} from "@/apis/streamladder-api/twitch-clips/twitch-clips"

export async function startupFromTwitchClipByClipId(clipId: string, config: StartupConfig, mp4UrlOverride?: string) {

  const editorClipInfoStore = useEditorClipInfoStore()
  try {

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info. This may take a while...'
    }

    const response = await retryAsync(async () => {
      return await getApiTwitchClipsClipId(clipId, { signal: config.signal ?? undefined }) as unknown as ClipDto
    }).catch((reason) => {
      if (reason instanceof DOMException && reason.name === 'AbortError') {
        throw reason
      } else {
        throw new Error('Failed to fetch clip info')
      }
    })

    config.signal?.throwIfAborted()

    isValidClipOrThrow(response)
    const clip = {
      ...response,
      id: clipId,
      mp4Url: mp4UrlOverride ?? response.mp4Url
    }

    return { error: null, clip: clip };
  } catch (error) {
    return handleStartupError(error)
  }
}

function isValidClipOrThrow(clip: ClipDto) {

  if (!clip?.id) {
    throw new Error('Clip not found')
  }

  if (!clip.mp4Url) {
    throw new Error('Clip does not have a video source')
  }
}
