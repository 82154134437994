<script setup lang="ts">
import { ref, computed } from 'vue';
import { useElementSize } from '@vueuse/core';
import type { ClassValue } from 'clsx';
import { cn } from '@/lib/utils';
const props = defineProps<{ days: number; hours: number; minutes: number; class?: ClassValue }>()

const container = ref<HTMLDivElement | null>(null);
const { width: containerWidth } = useElementSize(container);
const fontSize = computed(() => 0.1 * containerWidth.value + 'px');

const bounds = ref<HTMLDivElement | null>(null);
const { width: boundsWidth, height: boundsHeight } = useElementSize(bounds);

const size = computed(() => Math.max(boundsWidth.value, boundsHeight.value));
</script>

<template>
  <div class="w-full h-full relative" ref="bounds">
    <div ref="container"
      :style="{ width: size + 'px', height: size + 'px' }" 
      :class="cn('absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden aspect-square text-black', props.class)" 
    >
      <slot />
      <div :style="{ fontSize }" class="grid leading-[1] pointer-events-none overflow-hidden font-black font-title place-items-center absolute aspect-[20/19] rounded-3xl w-[20%] left-[30%] top-[54.1%] -translate-y-1/2 -translate-x-1/2">
        {{ days }}
      </div>
      <div :style="{ fontSize }" class="grid leading-[1] pointer-events-none overflow-hidden font-black font-title place-items-center absolute aspect-[20/19] rounded-3xl w-[20%] left-[50%] top-[54.1%] -translate-y-1/2 -translate-x-1/2">
        {{ hours }}
      </div>
      <div :style="{ fontSize }" class="grid leading-[1] pointer-events-none overflow-hidden font-black font-title place-items-center absolute aspect-[20/19] rounded-3xl w-[20%] left-[70%] top-[54.1%] -translate-y-1/2 -translate-x-1/2">
        {{ minutes }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
