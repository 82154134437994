/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */

export type RendererType = (typeof RendererType)[keyof typeof RendererType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RendererType = {
  serverSideRenderer: 'serverSideRenderer',
  webCodecRenderer: 'webCodecRenderer',
  serverSideWebCodecRenderer: 'serverSideWebCodecRenderer',
} as const
