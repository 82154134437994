import type { ClipDto } from '@/areas/editor/@type/ClipDto';
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig';
import type { Clip } from '@/Hooks/useTwitchClips';
import { useWebsocketChannel } from '@/modules/WebsocketService/WebSocketService';
import { getApiKickClipsClipId } from '@/apis/streamladder-api/kick-clips/kick-clips';
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';
import { getApiYouTubeClipsClipId } from '@/apis/streamladder-api/you-tube-clips/you-tube-clips';
import type { StartupSource } from '.';

export async function downloadClipIfMp4UrlIsEmpty(clip: ClipDto, config: StartupConfig, source: StartupSource) {

  const editorClipInfoStore = useEditorClipInfoStore()

  // If we get an empty response from a YouTube clip, we have to listen to the pusher client to retrieve the info needed.
  if (clip.mp4Url === '') {

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Importing YouTube Clip. This may take a few minutes...'
    }

    console.log('Empty response, listening to pusher client')
    try {
      return await listenForClipDownload(clip, config, source)
    } catch (e) {
      return { error: e?.toString() ?? null }
    }
  } else {
    return clip
  }
}

export async function listenForClipDownload(clip: ClipDto, config: StartupConfig, source: StartupSource) {

  return new Promise<Clip>((resolve, reject) => {

    const channelName = `cache-task-status-${clip.taskId}`;
    const timeout = setTimeout(() => {
      channel.value?.disconnect()
      reject(new Error('Clip download timed out.'))
    }, 5 * 60 * 1000)

    const channel = useWebsocketChannel(channelName, (eventName, data) => {

      if (config?.signal?.aborted) {
        channel.value?.disconnect()
        reject(new DOMException('Aborted', 'AbortError'))
        clearTimeout(timeout)
        return
      }

      if (eventName === 'progress') {
        if (data.status === 'finished') {
          abortPollController.abort()
          resolve(data as ClipDto)
          clearTimeout(timeout)
          return
        } else if (data.status === 'error') {
          abortPollController.abort()
          reject(data.message)
          clearTimeout(timeout)
        }
      }
    })

    const abortPollController = new AbortController()
    async function pollClipStatus(clipId: string, options: { wait: number }) {

      await new Promise((resolve) => setTimeout(resolve, options.wait))

      let response: Clip | undefined;
      
      if (source === 'kick-clip') {
        response = await getApiKickClipsClipId(clipId) as unknown as Clip
      }
      else if (source === 'youtube-clip'){
        response = await getApiYouTubeClipsClipId(clipId) as unknown as Clip
      }

      if (config?.signal?.aborted || abortPollController.signal.aborted) {
        throw new DOMException('Aborted', 'AbortError')
      }

      if (response?.mp4Url) {
        return response
      } else {
        channel.value?.disconnect()
        return await pollClipStatus(clipId, { wait: 5_000 })
      }
    }

    pollClipStatus(clip.id, { wait: 15_000 }).then((response) => {
      resolve(response)
    });
  })
}
