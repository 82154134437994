<script setup lang="ts">
import type { TypedOutboundSocialAccount } from '@/store/user/userInfo'
import { computed, ref, watch } from 'vue'
import ConnectionTypes from '@/enums/connectionTypes'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import accountService from '@/services/accountService'
import ProfileImage from '@/areas/dashboard/components/ProfileImage.vue'
import IconSaxRefresh from '@/components/Icons/iconsax/IconSaxRefresh.vue'
import useCreateSocialConnection from '@/Hooks/useCreateSocialConnection'
import {Button} from "@/components/ui/button";
import ReconnectionNecessary from '@/areas/dashboard/components/ReconnectionNecessary.vue'
import { useToast } from '@/Hooks/useToast'
import toastEvents from '@/events/toastEvents'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { AlertDialogTitle, AlertDialogContent, AlertDialog, AlertDialogDescription, AlertDialogFooter } from '@/components/ui/alert-dialog'
import { useConfirmDialog } from '@vueuse/core'

const props = defineProps<{ account: TypedOutboundSocialAccount }>()

const mustReconnectTikTokAccount = computed(() => {
  const isUnauthorized = !props.account.scopes?.includes('user.info.stats') || props.account.state === 'unathorized' || !props.account.hasAccess;
  return props.account.type === ConnectionTypes.TIKTOK && isUnauthorized;
});

const facebookAccountIsNotConnectedToInstagramAccount = computed(() => {
  console.log(props.account);
  const accountIsEmpty = props.account.displayName == null && props.account.profileImageUrl == null;
  return props.account.type === ConnectionTypes.INSTAGRAM && accountIsEmpty;
});

const { showToast } = useToast()

watch(facebookAccountIsNotConnectedToInstagramAccount, () => {
  if (facebookAccountIsNotConnectedToInstagramAccount.value) {
    showToast({
      type: toastEvents.TOAST,
      title: 'Instagram account not linked to Facebook account',
      subtitle: 'Please link your Instagram account to your Facebook account to enable publishing.',
      view: () => {
        window.open('https://streamladder.com/user-guide/a-complete-guide-on-how-to-connect-your-instagram-to-streamladder', '_blank')
      },
      viewTitle: 'Link now',
    })
  }
}, { immediate: true });

const { confirm, cancel, reveal, isRevealed } = useConfirmDialog()

const isRemoving = ref<boolean>(false)

async function remove() {
  
  const { isCanceled } = await reveal({
    title: `Are you sure you want to remove this account?`,
    message: 'This action cannot be undone, \nyou will have to reconnect your account manually.',
  })
  
  if (!isCanceled) {
    isRemoving.value = true
    await accountService.revokeSocial(props.account.id, props.account.type)
    isRemoving.value = false
  }
}

const isReconnecting = ref<boolean>(false)

const { connect } = useCreateSocialConnection()
async function reconnect() {

  isReconnecting.value = true

  try {
    await accountService.revokeSocial(props.account.id, props.account.type)
    await connect(props.account.type)
  } catch (error) {
    console.error(error)
  }

  isReconnecting.value = false
}
</script>

<template>
  <article class="layer-2 flex flex-col gap-4 rounded-xl px-6 py-4">
    <div class="grid grid-cols-2 items-center gap-4 md:grid-cols-3">
      <div class="col-span-2 flex items-center gap-4 lg:col-span-1">

        <template v-if="account.type === ConnectionTypes.INSTAGRAM">

          <template v-if="facebookAccountIsNotConnectedToInstagramAccount">
            <InstagramIcon class="h-16 w-16 fill-current" />
            <header class="flex flex-col">
              <h3 class="m-0 p-0 text-lg font-normal">
                <template v-if="account.displayName">@{{ account.displayName }}</template>
              </h3>
              <span class="text-sm font-light">
                Instagram
              </span>
              <span class="text-brand-state-text-secondary lg:hidden">
                Please connect your Instagram account to your Facebook account to enable publishing.
              </span>
            </header>
          </template>

          <template v-else>

            <ProfileImage v-if="account.profileImageUrl" :account="account" />
            <template v-else>
              <Avatar class="relative w-16 h-16 mr-3 overflow-visible text-white bg-gray-500">
                <AvatarImage :src="account?.profileImageUrl" />
                <AvatarFallback class="text-4xl font-semibold">
                  {{ account.displayName[0] }}
                </AvatarFallback>
                <div class="absolute -right-3 bottom-0">
                  <InstagramIcon class="h-8 w-8" />
                </div>
              </Avatar>
            </template>

            <header class="flex flex-col">
              <h3 class="m-0 p-0 text-lg font-normal">
                @{{ account.displayName }}
              </h3>
              <span class="text-sm font-light">
                Instagram
              </span>
              <span class="text-brand-state-text-secondary lg:hidden">
                <template v-if="!account.hasAccess">Reconnection needed</template>
                <template v-if="account.hasAccess && account.state === 'active'">Connected</template>
                <template v-if="account.hasAccess && account.state === 'inactive'">Disconnected</template>
                <template v-if="account.hasAccess && account.state === 'unathorized'">Disconnected</template>
              </span>
            </header>
          </template>
        </template>

        <template v-else>

          <ProfileImage :account="account" />

          <header class="flex flex-col">
            <h3 class="m-0 p-0 text-lg font-normal">
              <template v-if="account.type === ConnectionTypes.YOUTUBE">{{ account.displayName }}</template>
              <template v-else>@{{ account.displayName }}</template>
            </h3>
            <span class="text-sm font-light">
              <template v-if="account.type === ConnectionTypes.YOUTUBE">YouTube</template>
              <template v-if="account.type === ConnectionTypes.TIKTOK">TikTok</template>
            </span>
              <span class="text-brand-state-text-secondary lg:hidden">
              <template v-if="!account.hasAccess">Reconnection needed</template>
              <template v-if="account.hasAccess && account.state === 'active'">Connected</template>
              <template v-if="account.hasAccess && account.state === 'inactive'">Disconnected</template>
              <template v-if="account.hasAccess && account.state === 'unathorized'">Disconnected</template>
            </span>
          </header>
        </template>
      </div>

      <template v-if="facebookAccountIsNotConnectedToInstagramAccount">
        <span class="hidden place-self-center text-brand-state-text-secondary text-center lg:inline">
          Connected
        </span>
        <div class="col-span-2 flex items-center justify-between gap-2 md:col-span-1 lg:justify-self-end">
          <div class="flex flex-col">
            <Button
              variant="ghost"
              class="text-sm"
              @click="reconnect"
              :disabled="isReconnecting"
            >
              <IconSaxRefresh class="h-4 w-4" />
              Check again
            </Button>
          </div>
          <Button
            :disabled="isRemoving"
            @click="remove"
            variant="ghost"
            size="square"
            data-tip="Remove social media connection"
            class="tooltip tooltip-left text-red-400 "
          >
            <span v-if="isRemoving" class="spinner spinner-border !h-6 !w-6 border-2" />
            <IconSaxTrash v-else />
          </Button>
        </div>
      </template>

      <template v-else>
        <span class="hidden place-self-center text-brand-state-text-secondary lg:inline">
          <template v-if="!account.hasAccess">Reconnection needed</template>
          <template v-if="account.hasAccess && account.state === 'active'">Connected</template>
          <template v-if="account.hasAccess && account.state === 'inactive'">Disconnected</template>
          <template v-if="account.hasAccess && account.state === 'unathorized'">Disconnected</template>
        </span>

        <div class="col-span-2 flex items-center justify-between gap-2 md:col-span-1 lg:justify-self-end">
          <Button
            variant="ghost"
            class="text-sm"
            v-if="mustReconnectTikTokAccount || !account.hasAccess"
            @click="reconnect"
            :disabled="isReconnecting"
          >
            <IconSaxRefresh class="h-4 w-4" />
            Reconnect
          </Button>
          <Button
            :disabled="isRemoving"
            @click="remove"
            variant="ghost"
            size="square"
            data-tip="Remove social media connection"
            class="tooltip tooltip-left text-red-400 "
          >
            <span v-if="isRemoving" class="spinner spinner-border !h-6 !w-6 border-2" />
            <IconSaxTrash v-else />
          </Button>
        </div>
      </template>
    </div>

    <div
      v-if="facebookAccountIsNotConnectedToInstagramAccount"
      class="flex items-center gap-4 justify-left mt-2 font-light w-full rounded-xl p-6 border border-blue-500 bg-blue-50"
    >
      <IconSaxInfoCircle class="h-14 w-14 rotate-180 rounded-full fill-blue-500 text-blue-100 dark:text-blue-950" />
      <div class="flex flex-col">
        <span>To post or schedule from StreamLadder to Instagram, you need an <span class="font-semibold">Instagram Business</span> account.</span>
        <a href="https://www.facebook.com/business/help/connect-instagram-to-page" target="_blank" class="text-blue-500 underline hover:text-blue-400">
          Learn how to upgrade to Instagram Business
        </a>
      </div>
    </div>

    <ReconnectionNecessary :account="account" />
  </article>

  <AlertDialog v-model:open="isRevealed">
    <AlertDialogContent>
      <AlertDialogTitle class="text-pretty">Are you sure you want to remove this account?</AlertDialogTitle>
      <AlertDialogDescription class="text-pretty font-light text-muted-foreground">
        This action cannot be undone, you will have to reconnect your account manually.
      </AlertDialogDescription>

      <AlertDialogFooter>
        <Button variant="ghost" @click="cancel">Cancel</Button>
        <Button variant="destructive" @click="confirm">
          <IconSaxTrash class="w-4 h-4" /> Delete
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<style scoped lang="scss"></style>
