export const dashboardRouteNames = {
  root: 'dashboard-root',
  dashboard: 'dashboard',
  editor: 'create-clip',
  editorResult: 'create-clip-result',
  montageMaker: 'montage-maker',
  analytics: 'analytics',
  contentPublisher: {
    root: 'content-publisher',
    render: 'content-publisher/render',
    post: 'content-publisher/post',
    video: 'content-publisher/video',
  },
  clipGpt: {
    root: 'clip-gpt',
    project: 'clip-gpt/project',
  },
  downloader: {
    root: 'clip-downloader',
    result: 'clip-downloader/result',
  },
  videos: 'videos',
  projects: 'projects',
  socials: 'socials',
  support: 'support',
  whatsNew: 'whats-new',
  account: {
    root: 'account',
    settings: 'account/settings',
    invoicing: 'account/invoicing',
    referrals: 'account/referrals',
    templates: 'account/templates',
    brandPartners: 'account/brandPartners'
  },
} as const
