import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { getFileFromStorage } from '@/components/Dialog/MultiUploadDialog/file-uploads/_storage'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { onUserInfoReadyAsync } from '@/store/user/userInfo'
import { getUploadedVideosAsync } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { metadataService } from '@/services/metadataService'
import type { ClipDto } from '@/areas/editor/@type/ClipDto';

export async function startupFromLocalFileByClipId(clipId: string, config: StartupConfig) {
  
  try {

    const editorClipInfoStore = useEditorClipInfoStore()
    editorClipInfoStore.isLoadingClip = true
    editorClipInfoStore.id = clipId

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Finding video file...'
    }

    const upload = await fetchPreviousUpload(clipId, config)
    config.signal?.throwIfAborted()
    if (upload) {
      return await startupFromLocalFile({
        id: upload.id,
        title: upload.title,
        mp4Url: upload.mp4Url,
      }, config)
    }

    const localFile = await getFileFromStorage(clipId)
    config.signal?.throwIfAborted()
    if (localFile) {

      config.signal?.throwIfAborted()

      editorClipInfoStore.loadingState = {
        state: 'loading',
        description: 'Uploading video...'
      };

      await uploadVideo(clipId).catch((e) => {
        throw e;
      });

      const upload = await fetchPreviousUpload(clipId, config);

      if (upload) {
        return await startupFromLocalFile({
          id: upload.id,
          title: upload.title,
          mp4Url: upload.mp4Url,
        }, config)
      }
    }

    editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Video missing 🧐',
      description: 'The video could not be found in your browser or in cloud storage. Please re-upload to continue.'
    }

    return { error: 'The video could not be found in your browser or in cloud storage. Please re-upload to continue.' }
  } catch (e) {
    return handleStartupError(e)
  }
}

export async function startupFromLocalFile(clip: { id: string, title: string, mp4Url: string }, config: StartupConfig) {
  return { error: null, clip: clip as ClipDto };
}

async function fetchPreviousUpload(clipId: string, config: StartupConfig) {
  
  const userInfo = await onUserInfoReadyAsync()
  if (!userInfo.isAuthenticated) return null
  config.signal?.throwIfAborted()

  const uploadedVideos = await getUploadedVideosAsync()
  config.signal?.throwIfAborted()

  const previousUpload = uploadedVideos.find((upload) => upload.id === clipId)
  if (!previousUpload) return null
  
  const mp4Url = `${previousUpload.videoUrl}?cache=${Date.now()}`
  if (!await metadataService.canPlayUrl(mp4Url)) return null

  return {
    id: previousUpload.id,
    title: previousUpload.name,
    mp4Url: mp4Url,
    slug: previousUpload.name,
    isLocalFile: true,
    source: 'local-file',
  }
}

async function uploadVideo(clipId: string) {

  const fileUploads = useFileUploads();
  const editorClipInfoStore = useEditorClipInfoStore();

  await new Promise<void>((resolve, reject) => {

    fileUploads.resume(clipId);
    let promise: Promise<void> | undefined;
    
    const onDone = () => {
      editorClipInfoStore.loadingState = {
        state: 'loading',
        title: 'Upload complete 🎉',
        description: 'Preparing video editor...'
      };
      stop();
      resolve();
    };

    const stop = watch(() => fileUploads.state[clipId], (upload) => {

      if (upload?.status === 'error') {
        editorClipInfoStore.loadingState = {
          state: 'error',
          title: 'Upload failed 😢',
          description: 'The video could not be uploaded. Please try again.'
        };
        stop();
        reject(new Error('Upload failed'));
      } else if (upload?.status === 'finished') {
        onDone();
      } else {
        editorClipInfoStore.loadingState = {
          state: 'loading',
          progressRatio: (upload?.progress ?? 0) / 100,
          title: 'Uploading video 🚀',
          description: 'This might take a little while, depending on the size of the video.'
        };
      }

      const suspense = upload?.suspense();
      if (!promise && suspense) {
        promise = suspense.then(onDone);
      }
    }, { immediate: true, deep: true });
  })
} 
