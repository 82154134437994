<script setup lang="ts">
import LayoutCard from '@/areas/editor/views/layouts/LayoutCard.vue'
import { computed, capitalize, ref } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { usePresets } from '@/areas/editor/@data/layouts'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { Button } from '@/components/ui/button'
import IconSaxExportSquare from '@/components/Icons/iconsax/IconSaxExportSquare.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { tiers } from '@/enums/tiers'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'

const segment = useCurrentSegment()

const { layouts } = usePresets()

const origins = computed(() => {

  const origins = [
    {
      value: null,
      label: 'All',
    },
    {
      value: 'custom-layout',
      label: 'Custom layouts',
    },
    {
      value: 'saved-template',
      label: 'Saved templates',
    },
    {
      value: 'prefab',
      label: 'Default layouts',
    }
  ]

  return origins.filter(({ value }) => !value || layouts.value.some(l => l.origin === value))
})

const selectedOrigin = ref(null)
const layoutsWithoutZoom = computed(() => layouts.value.filter(l => l.id !== 'zoom'))
const filteredLayouts = computed(() => {
  if (!selectedOrigin.value) {
    return layoutsWithoutZoom.value
  } else {
    return layoutsWithoutZoom.value.filter(l => l.origin === selectedOrigin.value)
  }
})

const router = useRouter()

const multipleLayoutsCardSplitClip = useFeatureFlagVariantEnabled('multiple-layouts-card', 'split-clip');
const multipleLayoutsCardAddLayout = useFeatureFlagVariantEnabled('multiple-layouts-card', 'add-layout');

const editorFocusStore = useEditorFocusStore();
const videoStore = useVideoStore();
const userInfoStore = useUserInfoStore();

const splitClip = () => {
  const currentSegmentId = segment.value.id;
  editorFocusStore.setFocus('segment', currentSegmentId);
  editorFocusStore.splitFocusModel(videoStore.getExactTime(), true);
};
</script>

<template>
  <div class="flex flex-col gap-1 md:gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">
    <header>
      <h2 class="text-xl font-semibold leading-snug font-title">Choose your layout</h2>
      <p class="font-light hidden md:block">Customize your layout to your liking making a unique viewing experience</p>
    </header>

    <template v-if="multipleLayoutsCardSplitClip">
      <div class="hidden md:flex flex-wrap 2xl:flex-nowrap items-center w-full gap-1 2xl:gap-4 px-4 py-4 2xl:py-5 rounded-lg bg-gradient-to-r from-[#FAE5FF] to-[#DAF1FF] dark:bg-gradient">
        <div class="flex flex-col gap-0.5">
          <span class="font-semibold text-lg dark:text-white">
            Multi-layouts
          </span>
          <span class="font-light dark:text-white">
            Apply layouts at different moments on the timeline
          </span>
        </div>
        <Button variant="primary" class="gap-2 dark:bg-white dark:text-gray-900" @click="splitClip">
          <IconSaxAdd />
          Split clip
          <SilverPlanButton v-if="userInfoStore.tier !== tiers.SILVER && userInfoStore.tier !== tiers.GOLD" class="pointer-events-none -mt-0.5" :can-click="false" :small="true" />
        </Button>
      </div>
    </template>
    <template v-else-if="multipleLayoutsCardAddLayout">
      <div class="hidden md:flex flex-wrap 2xl:flex-nowrap items-center w-full gap-1 2xl:gap-4 px-4 py-4 2xl:py-5 rounded-lg bg-gradient-to-r from-[#FAE5FF] to-[#DAF1FF] dark:bg-gradient">
        <div class="flex flex-col gap-0.5">
          <span class="font-semibold text-lg dark:text-white">
            Multi-layouts
          </span>
          <span class="font-light dark:text-white">
            Apply layouts at different moments on the timeline
          </span>
        </div>
        <Button variant="primary" class="gap-2 dark:bg-white dark:text-gray-900" @click="splitClip">
          <IconSaxAdd />
          Add layout
          <SilverPlanButton v-if="userInfoStore.tier !== tiers.SILVER && userInfoStore.tier !== tiers.GOLD" class="pointer-events-none -mt-0.5" :can-click="false" :small="true" />
        </Button>
      </div>
    </template>

    <div class="flex flex-row flex-wrap gap-2" v-if="origins.some(o => o.value === 'custom-layout' || o.value === 'saved-template')">
      <RadioToggleButton v-for="tag in origins" :key="tag.label" :value="tag.value" v-model="selectedOrigin" size="sm" class="flex-col px-2 relative font-light data-[state=active]:font-semibold">
        <span class="absolute inset-auto">{{ capitalize(tag.label) }}</span>
        <span class="font-semibold invisible">{{ capitalize(tag.label) }}</span>
      </RadioToggleButton>
    </div>

    <Button
      v-if="origins.some(o => o.value === 'saved-template') && selectedOrigin === null || selectedOrigin === 'saved-template'"
      as='a' :href="router.resolve({ name: dashboardRouteNames.account.templates }).href" target="_blank"
      variant="link" class="cursor-pointer mr-auto font-light p-0" size="sm"
    >
      <IconSaxExportSquare class="h-3.5 w-3.5" />
      <span class="mt-0.5">Manage saved templates</span>
    </Button>

    <div class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8" v-if="segment">
      <div class="grid grid-cols-[repeat(auto-fill,_minmax(130px,_1fr))] gap-2">
        <LayoutCard v-for="preset of filteredLayouts" :key="preset.id" :preset="preset" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
