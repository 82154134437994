import type { StartupConfig } from '@/areas/editor/startup/StartupConfig';
import { startupFromLocalFileByClipId } from '@/areas/editor/startup/startupFromLocalFile'
import { startupFromYoutubeClipByClipId } from '@/areas/editor/startup/startupFromYoutubeClip'
import { startupFromTwitchClipByClipId } from '@/areas/editor/startup/startupFromTwitchClip'
import { startupFromTwitchVodByClipId } from '@/areas/editor/startup/startupFromTwitchVod'
import { startupFromKickClipByClipId } from '@/areas/editor/startup/startUpFromKickClip'
import { startupFromKickCxClipByClipId } from '@/areas/editor/startup/startUpFromKickCxClip'
import type { ClipDto } from '@/areas/editor/@type/ClipDto';

export type StartupSource = 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'kick-cx-clip' | 'local-file';

export function startup(
  source: StartupSource,
  clipId: string,
  config: StartupConfig
): Promise<{ error: string | null, clip: ClipDto | null }> {
  switch (source) {
    case 'twitch-clip':
      return startupFromTwitchClipByClipId(clipId, config);
    case 'twitch-vod':
      return startupFromTwitchVodByClipId(clipId, config);
    case 'youtube-clip':
      return startupFromYoutubeClipByClipId(clipId, config);
    case 'kick-clip':
      return startupFromKickClipByClipId(clipId, config);
    case 'kick-cx-clip':
      return startupFromKickCxClipByClipId(clipId, config);
    case 'local-file':
      return startupFromLocalFileByClipId(clipId, config);
    default:
        throw new Error(`Unsupported source "${source}"`);
  }
}
