<script setup lang="ts">
import type { ClipGptProjectDto } from '@/apis/streamladder-api/model'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import AppImage from '@/areas/editor/components/AppImage.vue'
import IconSaxEdit from '@/components/Icons/iconsax/IconSaxEdit.vue'
import IconSaxHambergerMenu from '@/components/Icons/iconsax/IconSaxHambergerMenu.vue'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogTitle } from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useConfirmDialog } from '@vueuse/core'
import { addDays, formatDistanceToNow } from "date-fns"
import { ref } from 'vue'

defineProps<{ project: ClipGptProjectDto }>()
const emit = defineEmits(['delete'])

const { reveal, isRevealed, cancel, confirm } = useConfirmDialog()

const isDeletingProject = ref(false)
async function deleteProject() {
  isDeletingProject.value = true
  const { isCanceled } = await reveal()
  if (!isCanceled) {
    // await deleteApiClipGptProject(project.id)
  }
  emit('delete')
  isDeletingProject.value = false
}

const deleteEndpointExists = false

const getTimeLeft = (createdAt: string) => {
  const createdAtDate = new Date(createdAt);
  const removeDate = addDays(createdAtDate, 14);
  return formatDistanceToNow(removeDate, { addSuffix: false });
};
</script>

<template>
  <RouterLink class="flex flex-col"
    :class="project.status === 'failed' ? 'cursor-default' : ''"
    :to="project.status === 'failed' ? {} : { name: dashboardRouteNames.clipGpt.project, params: { projectId: project.id } }"
  >
    <article
      class="flex flex-col rounded-xl p-1 gap-1 layer-2 transition-all"
      :class="{
        'border-rose-500 bg-rose-50 dark:border-rose-700 dark:bg-rose-900': project.status === 'failed',
        'border-emerald-500 bg-emerald-50 dark:border-emerald-700 dark:bg-emerald-900 hover:shadow-lg': project.status === 'processing' || project.status === 'starting',
        'bg-surface-panel-bg hover:bg-brand-state-hover-bg hover:border-brand-state-hover-border': project.status === 'succeeded',
      }">
      <figure class="bg-surface-panel-border w-full aspect-video rounded-lg relative grid place-items-center">
        <AppImage :src="project.thumbnailUrl" alt="ClipGPT project thumbnail" class="rounded-lg w-full">
          <template #error>
            <div class="flex items-center justify-center w-full h-full bg-black rounded-lg" />
          </template>
        </AppImage>
        <figcaption v-if="project.status === 'failed'" class="absolute inset-auto flex items-center justify-center gap-2 border border-rose-600 bg-rose-50 text-rose-700 dark:bg-rose-900 dark:text-rose-50 rounded-lg text-sm text-balance px-2 py-1 shadow-rose-900 max-w-[75%]">
          <IconSaxWarning2 class="w-4 h-4 shrink-0" />
          We were unable to get clips from this stream
        </figcaption>
        <figcaption v-if="project.status === 'processing' || project.status === 'starting'" class="absolute inset-auto flex items-center justify-center gap-2 border border-emerald-600 bg-emerald-50 text-emerald-700 dark:bg-emerald-900 dark:text-emerald-50 rounded-lg text-sm text-balance px-2 py-1 shadow-emerald-900">
          <IconSaxMagicpen class="w-4 h-4" />
          AI magic at work
        </figcaption>
      </figure>
      <header class="p-1 flex gap-2 w-full">
        <div class="flex flex-col flex-1 min-w-0 gap-2">
          <div class="flex flex-col">
            <h4 class="text-current text-base font-semibold truncate">{{ project.title }}</h4>
            <p class="text-sm truncate">{{ project.streamerName }}</p>
          </div>
          <div v-if="project.createdAt" class="font-light text-xs text-zinc-800 dark:text-white">
            Available for {{ getTimeLeft(project.createdAt) }}
          </div>
        </div>
        
        <Popover v-if="deleteEndpointExists">
          <PopoverTrigger as-child>
            <Button variant="ghost" size="square" class="shrink-0 grow-0" @click.stop.prevent>
              <IconSaxHambergerMenu />
            </Button>
          </PopoverTrigger>
          <PopoverContent class="w-auto p-1 rounded-xl flex flex-col gap-1">
            <RouterLink :to="{ name: dashboardRouteNames.clipGpt.project, params: { projectId: project.id }}" v-if="project.status !== 'failed'">
              <Button variant="ghost" size="sm" class="justify-start w-full">
                <IconSaxEdit class="w-4 h-4" /> Edit
              </Button>
            </RouterLink>
            <Button variant="ghostDestructive" size="sm" class="justify-start" @click="deleteProject" :disabled="isDeletingProject">
              <template v-if="isDeletingProject">
                <div class="w-4 h-4 border-2 border-current border-t-transparent rounded-full animate-spin" />
                Deleting...
              </template>
              <template v-else>
                <IconSaxTrash class="w-4 h-4" /> Delete
              </template>
            </Button>
          </PopoverContent>
        </Popover>
      </header>
    </article>
  </RouterLink>
  
  <AlertDialog v-model:open="isRevealed">
    <AlertDialogContent>
      <AlertDialogTitle class="text-pretty">Are you sure you wish to delete this ClipGPT project?</AlertDialogTitle>
      <AlertDialogDescription>This action cannot be undone.</AlertDialogDescription>
      
      <AlertDialogFooter>
        <Button variant="ghost" @click="cancel">Cancel</Button>
        <Button variant="destructive" @click="confirm">
          <IconSaxTrash class="w-4 h-4" /> Delete
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<style scoped lang="scss">

</style>
