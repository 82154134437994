<script setup lang="ts">
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import IconSaxArrangeVerticalCircle from '@/components/Icons/iconsax/IconSaxArrangeVerticalCircle.vue'
import { type Transcript, useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { computed } from 'vue'

const props = defineProps<{
  caption: Transcript
}>();

const captionsStore = useCaptionsStore();
const historyStore = useHistoryStore();
const videoStore = useVideoStore();

const isLastCaption = computed(() => {
  return props.caption.id === captionsStore.entities[captionsStore.entities.length - 1].id;
});

const mergeCaptionWithNextCaption = async () => {

  if (isLastCaption.value) {
    return;
  }

  const nextCaption = captionsStore.entities.indexOf(props.caption) < captionsStore.entities.length - 1
    ? captionsStore.entities[captionsStore.entities.indexOf(props.caption) + 1]
    : null;

  if (nextCaption) {
    await historyStore.transaction('CAPTIONS:MERGE', () => captionsStore.mergeCaptions(props.caption, nextCaption));
  }
};

const canAddCaption = computed(() => {
  return captionsStore.getAvailableSpaceMsAfterCaption(props.caption) >= 100;
});

const addCaptionAfter = async () => {

  if (!canAddCaption.value) {
    return;
  }

  if (captionsStore.getAvailableSpaceMsAfterCaption(props.caption) < 100) {
    return;
  }

  videoStore.playing = false;

  const newCaption = await captionsStore.addCaptionAfter(props.caption);
  if (newCaption) {
    historyStore.transaction('CAPTIONS:ADD')
    captionsStore.captionIdBeingEdited = newCaption.id!;
    const word = newCaption.words[0];
    videoStore._currentTime = (word.start + (word.end - word.start) / 2) / 1000;
  }
};
</script>

<template>
  <div class="font-light group w-full h-6 flex opacity-0 hover:opacity-100 items-center justify-center cursor-default relative transition-[colors,opacity,transform,height]">

    <div class="w-full h-[1px] dark:bg-zinc-900 group-hover:bg-blue-400" />

    <div class="flex gap-4 absolute group-hover:opacity-100">
      <Tooltip disable-hoverable-content :delay-duration="500">
        <TooltipTrigger>
          <div
            @click="addCaptionAfter()"
            class="flex gap-1.5 w-28 bg-white px-1.5 items-center justify-center border border-blue-400 rounded-full cursor-pointer"
            :class="{
              '!cursor-not-allowed !text-gray-500 !bg-gray-200 dark:!bg-gray-600': !canAddCaption,
              'hover:bg-blue-100 dark:hover:bg-company-secondary-900 dark:bg-surface-panel-50': canAddCaption,
            }"
          >
            <IconSaxAdd class="-mt-0.5 w-5 h-5 opacity-0 group-hover:opacity-100 text-gray-400 group-hover:text-gray-800 dark:group-hover:text-white" />
            <span class="text-sm">Add</span>
          </div>
        </TooltipTrigger>
        <TooltipContent>
            {{ canAddCaption ? 'Add a new caption' : 'Not enough space to add a new caption' }}
        </TooltipContent>
      </Tooltip>

      <Tooltip disable-hoverable-content :delay-duration="500">
        <TooltipTrigger>
          <div
            class="flex gap-1.5 w-28 bg-white px-1.5 items-center justify-center border border-blue-400 rounded-full cursor-pointer hover:bg-blue-100 dark:hover:bg-company-secondary-900 dark:bg-surface-panel-50"
            :class="{
              '!cursor-default !text-gray-500 !bg-gray-200': isLastCaption,
            }"
            @click="mergeCaptionWithNextCaption()"
          >
            <IconSaxArrangeVerticalCircle class="-mt-0.5 w-3.5 h-3.5 opacity-0 group-hover:opacity-100 text-gray-400 group-hover:text-gray-800 dark:group-hover:text-white" />
            <span class="text-sm">Merge</span>
          </div>
        </TooltipTrigger>
        <TooltipContent>
            {{ isLastCaption ? 'No caption to merge' : 'Merge captions together' }}
        </TooltipContent>
      </Tooltip>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
