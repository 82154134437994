<script lang="ts" setup>
import { type Bindings, bindings } from '@/lib/keybindings';
import { capitalize } from 'vue';
import { isMacOs } from '@/data/isMacOs';

interface Props {
  event: Bindings;
  limit?: number;
}

const props = withDefaults(defineProps<Props>(), { limit: Infinity });

const keyCombinations = computed(() => {

  const combinations = bindings[props.event];
  const keyCombinations = [];

  for (let i = 0; i < combinations.length; i++) {
    const combination = combinations[i] ?? [];
    const keyCombination = [];
    for (let key of combination.split('+') ?? []) {
      if (isMacOs) {
        key = key
          .replace('cmd', '⌘')
          .replace('ctrl', '⌃')
          .replace('alt', '⌥')
          .replace('shift', '⇧')
          .replace('enter', '↩')
          .replace('backspace', '⌫')
          .replace('delete', '⌦')
          .replace('left', '←')
          .replace('right', '→')
          .replace('up', '↑')
          .replace('down', '↓');
      }

      keyCombination.push(capitalize(key));
    }
    keyCombinations.push(keyCombination);
  }

  return keyCombinations;
});
</script>

<template>
  <kbd class="text-foreground inline-flex items-center pointer-events-none h-5 select-none gap-0.5 rounded border border-border bg-muted font-sans font-medium min-h-5 text-[11px] px-1">
    <template v-for="(combination, index) in keyCombinations.slice(0, limit)" :key="'key-combination-' + index">
      <span v-if="index > 0" class="mx-1">/</span>
      <template v-for="key in combination" :key="key">
        <span v-if="key === '⌘'" class="text-xs">{{ key }}</span>
        <span v-else-if="key === '⇧'" class="-translate-y-px text-[1.1em]">{{ key }}</span>
        <span v-else>{{ key }}</span>
      </template>
    </template>
  </kbd>
</template>
