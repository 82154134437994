import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { getApiYouTubeClipsClipId } from '@/apis/streamladder-api/you-tube-clips/you-tube-clips'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import type { ClipDto } from '../@type/ClipDto';
import { listenForClipDownload, downloadClipIfMp4UrlIsEmpty } from '@/areas/editor/startup/listenForClipDownload';

export async function startupFromYoutubeClipByClipId(clipId: string, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  try {
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }

    const clip = await getApiYouTubeClipsClipId(clipId) as unknown as ClipDto
    config.signal?.throwIfAborted()

    clip.title = clip.title ?? 'Youtube Clip';

    const response = await downloadClipIfMp4UrlIsEmpty(clip, config, 'youtube-clip')
    config.signal?.throwIfAborted()
    if ('error' in response) {
      if (response.error) {
        editorClipInfoStore.loadingState = {
          state: 'error',
          ...explanationFor(response.error)
        };
      } else {
        return { error: response.error, clip: null };
      }
    }

    return { error: null, clip: response }
  } catch (error) {
    return handleStartupError(error)
  }
}

function explanationFor(error: string) {
  switch (error) {
    case 'blocked_video':
      return {
        description: 'This video is geo-blocked in the USA and could not be imported. Please ensure the original video is available in the USA.'
      }
    default:
      return {
        title: 'Could not import clip 🚫',
        description: 'Looks like your clip is either still processing on YouTube (stream clips take up to 24 hours), age-restricted or it wasn\'t found. Try another clip or check back later!'
      }
  }
}
