<template>
  <div class="comfy-cat-wrapper" ref="stickerContainer">
    <div class="top-content">
      <Cat1 class="cat" />
      <!-- <img class="blanket" src="/images/svgs/blanket.svg" @load="onAssetLoad" /> -->
       <Blanket class="blanket" />
    </div>
    <div class="comfy-cat-a">
      <div class="logo">
        <twitch-icon />
      </div>

      <div class="text-wrapper">
        <TextSticker
          ref="sticker"
          variant="none"
          font-family="Shadows Into Light"
          @update-rect="(e) => $emit('updateRect', e)"
          @update-content="(e) => $emit('updateContent', e)"
          @update="(e) => $emit('update', e)"
          :html-content="htmlContent"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const sticker = ref()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<script>
import TwitchIcon from '../../../Icons/SocialMedia/TwitchIcon.vue'
import Blanket from '../../../Icons/Blanket.vue'
import Cat1 from '../../../Icons/Cat1.vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

const stickerContainer = ref();
export default {
  components: {
    TextSticker,
    TwitchIcon,
    Blanket,
    Cat1,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    setEditMode: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      assetsLoadedCount: 0,
    }
  },
  async mounted() {
    this.$emit('stickerLoaded', this)
  },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Shadows Into Light';
  font-style: normal;
  font-weight: 400;
  src: local('Shadows Into Light'), url('/fonts/ShadowIntoLight/ShadowsIntoLight-Regular.ttf') format('truetype');
}

.comfy-cat-wrapper {
  position: relative;
  user-select: none;
  display: inline-flex;
  flex-direction: column;
  width: auto;

  * {
    box-sizing: content-box;
  }

  > .top-content {
    position: absolute;
    width: 100%;
    display: inline-flex;
    z-index: 2;

    justify-content: space-between;

    > .cat {
      width: 70px;
      position: relative;
      top: 17px;
    }

    > .blanket {
      position: relative;
      top: 21px;
      width: 110px;
    }
  }
}

.comfy-cat-a {
  position: relative;
  padding: 0.5em 3em 0.5em 0.5em;
  padding-right: 3em;
  margin-top: 54px;
  margin-right: 15px;
  margin-bottom: 3px;
  left: 7px;

  --twitch-color: white;
  display: inline-flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;

  background: linear-gradient(250.8deg, #fd99ff 32.01%, #ba85ff 75.06%);
  border-radius: 10px;

  width: auto;
  color: #ffffff;

  > .text-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.3em;

    font-family: 'Shadows Into Light';

    .none {
      padding-left: 2px;

      letter-spacing: 0.24em;

      font-style: normal;
      font-weight: bold;
      font-size: 23px;
      line-height: 23px;
    }
  }

  > .logo {
    display: flex;
    align-items: center;

    > svg {
      width: 1.7em;
      height: 1.7em;
    }
  }
}
</style>
