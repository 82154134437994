import type { Area } from '@/modules/SLMovable/@types/Movable'
import { defineStore } from 'pinia'
import { useEntityStore } from '@/store/entity-system/useEntityStore'
import { readonly, computed } from 'vue'
import { clamp, omit, sortBy } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import type { FontLabel } from '@/data/fonts'
import type { CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'
import type { TextStyleVariants } from '@/components/Stickers/textLibrary'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import type { Sticker as ApiSticker } from '@/apis/streamladder-api/model'
import { selectStickerFont } from '@/store/entity-system/selectors/selectStickerFont'

export type Sticker =
  | { type: 'text' } & TextSticker
  | { type: 'social' } & SocialSticker
  | { type: 'custom' } & CustomSticker
  | { type: 'brand-kit' } & BrandKitSticker
  | { type: 'giphy' } & GiphySticker
  | { type: 'rive' } & RiveSticker

export type TypedSticker<T extends Sticker['type'] = Sticker['type']> = Extract<Sticker, { type: T }>

export type BaseSticker = {
  id: string;
  key: string;
  scale: number | null;
  area: Area;
  startMs: number;
  endMs: number;
  z: number;
  imageUrl?: string;
  editing: boolean;
}

export type StickerVariant = CaptionStyle | TextStyleVariants

type TextSticker = BaseSticker & { color: string; textContent: string, variant: StickerVariant; editing: boolean; }
type SocialSticker = BaseSticker & { color: string; textContent: string, icon: string, imageUrl?: string; editing: boolean; }
type CustomSticker = BaseSticker & { imageUrl: string }
type BrandKitSticker = BaseSticker & { primaryColor: string, secondaryColor: string, fontFamily: FontLabel, variant: StickerVariant; editing: boolean; }
type GiphySticker = BaseSticker & { imageUrl: string, naturalWidth: number, naturalHeight: number }
export type RiveSticker = BaseSticker & { artboard: string, textContent: string, icon: string, animation?: string, stateMachineInputs: number[] }

export const useStickersStore = defineStore('entity-stickers', () => {

  const { state, ids, entities, operations } = useEntityStore<TypedSticker>()

  const editorVideoStore = useEditorVideoStore()
  const durationMs = computed(() => editorVideoStore._duration * 1000)

  const editorFocusStore = useEditorFocusStore()

  return {
    state: readonly(state),
    ids: ids,
    entities: entities,

    ...operations,

    duplicateById(id: string) {

      const sticker = state[id]
      const randomDistance = Math.random() * 0.15

      function clampX(area: Area) {
        if (area.x + area.width + randomDistance <= 1) {
          return area.x + randomDistance
        } else if (area.x - randomDistance >= 0) {
          return area.x - randomDistance
        } else {
          return area.x
        }
      }

      function clampY(area: Area) {
        if (area.y + area.height + randomDistance <= 1) {
          return area.y + randomDistance
        } else if (area.y - randomDistance >= 0) {
          return area.y - randomDistance
        } else {
          return area.y
        }
      }

      const duplicateId = uuid()
      this.createById(duplicateId, {
        ...omit(sticker, 'id'),
        area: {
          x: clampX(sticker.area),
          y: clampY(sticker.area),
          width: sticker.area.width,
          height: sticker.area.height,
        }
      })

      return duplicateId
    },

    shift(id: string, amount: number) {


      const stickers = sortBy(entities.value, 'z')

      const from = state[id].z
      const to = clamp(from + amount, 0, stickers.length - 1)

      if (from === to) return

      for (const id of ids.value) {
        if (Math.sign(amount) === -1) {
          if (state[id].z >= to && state[id].z <= from) {
            state[id].z++
          }
        } else {
          if (state[id].z <= to && state[id].z >= from) {
            state[id].z--
          }
        }
      }

      state[id].z = to
    },

    moveToBackground(id: string) {
      this.shift(id, -Infinity)
    },

    moveToForeground(id: string) {
      this.shift(id, Infinity)
    },

    createTextSticker(payload: PartialBy<Omit<TypedSticker<'text'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'startMs' | 'endMs' | 'editing'>) {
      const id = uuid()
      this.createById<TypedSticker<'text'>>(id, {
        type: 'text',
        scale: null,
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        editing: false,
        ...omit(payload, 'editing')
      })

      if (payload.editing !== false) {
        // Honestly no idea why, but 75 seems to work here.
        setTimeout(() => this.setEditModeById(id), 75)
      }

      return id
    },

    createSocialSticker(payload: PartialBy<Omit<TypedSticker<'social'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'startMs' | 'endMs' | 'editing'>) {
      const id = uuid()
      this.createById<TypedSticker<'social'>>(id, {
        type: 'social',
        scale: null,
        area: { x: 0.125, y: 0.5, width: 0.75, height: 0.25 },
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        editing: false,
        ...omit(payload, 'editing')
      })

      if (payload.editing !== false) {
        // Honestly no idea why, but 75 seems to work here.
        setTimeout(() => this.setEditModeById(id), 75)
      }

      return id
    },

    createCustomSticker(payload: PartialBy<Omit<TypedSticker<'custom'>, | 'id' | 'z' | 'key' | 'type'>, 'scale' | 'area' | 'startMs' | 'endMs'>) {
      const id = uuid()
      this.createById<TypedSticker<'custom'>>(id, {
        type: 'custom',
        key: 'custom-sticker',
        scale: null,
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        z: ids.value.length,
        startMs: 0,
        editing: false,
        endMs: durationMs.value,
        ...omit(payload, 'editing')
      })

      return id
    },

    createBrandKitSticker(payload: PartialBy<Omit<TypedSticker<'brand-kit'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'startMs' | 'endMs' | 'editing'>) {
      const id = uuid()
      this.createById<TypedSticker<'brand-kit'>>(id, {
        type: 'brand-kit',
        scale: null,
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        editing: false,
        ...omit(payload, 'editing')
      })

      if (payload.editing !== false) {
        // Honestly no idea why, but 75 seems to work here.
        setTimeout(() => this.setEditModeById(id), 75)
      }

      return id
    },

    createGiphySticker(payload: PartialBy<Omit<TypedSticker<'giphy'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'startMs' | 'endMs'>) {
      const id = uuid()
      this.createById(id, {
        type: 'giphy',
        scale: null,
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        editing: false,
        ...omit(payload, 'editing'),
      })
      return id
    },

    updateTimingsById(id: string, startMs: number, endMs: number) {
      state[id].startMs = Math.round(startMs)
      state[id].endMs = Math.round(endMs)
    },

    listImageUrls() {
      return computed(() => {
        const urls = [] as string[]
        for (const sticker of entities.value) {
          if ('imageUrl' in sticker && sticker.imageUrl) {
            urls.push(sticker.imageUrl)
          }
        }
        return urls
      })
    },

    splitStickerById(id: string | undefined, ms: number) {
      
      if (!id) return

      const sticker = state[id]
      if (!sticker) return

      const newSticker = { ...sticker, startMs: ms, id: uuid() }
      sticker.endMs = ms
      this.createById(newSticker.id, newSticker)
    },

    selectFocusTypeById(id: string) {
      const sticker = state[id]
      return computed(() => isTextSticker(sticker) ? FocusTypes.TEXTSTICKER : FocusTypes.STICKER)
    },

    selectFocusById(id: string) {
      const focusType = this.selectFocusTypeById(id)
      return computed(() => {
        if (editorFocusStore.focus) {
          return editorFocusStore.focus.type === focusType.value && editorFocusStore.focus.key === id
        } else {
          return false
        }
      })
    },

    setEditModeById(id: string, editing = true) {
      const sticker = state[id]
      if (sticker && 'editing' in sticker) {
        this.focus(id)
        sticker.editing = editing
      }
    },

    focus(id: string) {
      const focusType = this.selectFocusTypeById(id)
      editorFocusStore.setFocus(focusType.value, id)
    },

    selectStickersForStorage(): ApiSticker[] {
      return entities.value.map(toApiSticker)
    },

    selectFontById(id: string) {
      const sticker = state[id]
      return sticker ? selectStickerFont(sticker) : null
    }
  }
})

const toApiSticker = (sticker: Sticker): ApiSticker & { width: number, height: number } => ({
  x: sticker.area.x,
  y: sticker.area.y,
  width: sticker.area.width,
  height: sticker.area.height,
  key: sticker.id,
  scale: sticker.scale ?? undefined,
  animationTime: undefined,
  animationStyle: undefined,
  animationDuration: null,
  color: 'color' in sticker ? sticker.color : undefined,
  icon: 'icon' in sticker ? sticker.icon : undefined,
  isTextSticker: isTextSticker(sticker),
  imageUrl: 'imageUrl' in sticker ? sticker.imageUrl : undefined,
  text: 'textContent' in sticker ? sticker.textContent : undefined,
  visible: true,
  componentName: sticker.key
})

function isTextSticker(sticker: Sticker) {
  return 'textContent' in sticker && sticker.type !== 'social'
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
