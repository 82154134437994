<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref, toRaw, watch, provide } from 'vue'
import SendIcon from '@/components/Icons/SendIcon.vue'
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue'
import {
  type CreatePostDto,
  OverallPostStatus as Status,
  PrivacyLevel as privacyLevels,
  type ReplacePostDto,
  type TargetDto, YouTubeVisibility
} from '@/apis/streamladder-publisher/model'
import useValidation from '@/Hooks/useValidation'
import SocialMediaAccountPicker from '@/areas/dashboard/components/ScheduledPosts/form/SocialMediaAccountPicker.vue'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import QrIcon from '@/components/Icons/QrIcon.vue'
import DownloadIcon from '@/components/Icons/DownloadIcon.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import connectionTypes from '@/enums/connectionTypes'
import { useUserInfoStore } from '@/store/user/userInfo'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import logging from '@/logging'
import GetQrCode from '@/components/ResultPage/GetQrCode.vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import SelectClipDialog from '@/components/Dialog/Scheduler/SelectClipDialog.vue'
import {
  type ContentPublisherFormState,
  deepAssign,
  toTargetDto,
  useDefaultSocialMedia,
  validationSchema,
} from '@/areas/dashboard/pages/contentPublishing/ContentPublishHelpers'
import SchedulePostTikTokForm from '@/areas/dashboard/components/ScheduledPosts/form/SchedulePostTikTokForm.vue'
import SchedulePostInstagramForm from '@/areas/dashboard/components/ScheduledPosts/form/SchedulePostInstagramForm.vue'
import SchedulePostYouTubeForm from '@/areas/dashboard/components/ScheduledPosts/form/SchedulePostYouTubeForm.vue'
import SchedulePostYoutubeFormADV from '@/areas/dashboard/components/ScheduledPosts/form/SchedulePostYoutubeFormADV.vue'
import SchedulePostTikTokFormADV from '@/areas/dashboard/components/ScheduledPosts/form/SchedulePostTikTokFormADV.vue'
import SchedulePostInstagramFormADV from '@/areas/dashboard/components/ScheduledPosts/form/SchedulePostInstagramFormADV.vue'
import SchedulePostDatePicker from '@/areas/dashboard/components/ScheduledPosts/form/SchedulePostDatePicker.vue'
import type { DeepPartial } from 'ts-essentials'
import { Button } from '@/components/ui/button'
import IconSaxVideoVertical from '@/components/Icons/iconsax/IconSaxVideoVertical.vue'
import Spinner from '@/components/Icons/Spinner.vue'
import toastEvents from '@/events/toastEvents'
import { cloneDeep, unset } from 'lodash-es'
import { useContentPublisherPostData } from '@/areas/dashboard/components/ScheduledPosts/form/useContentPublisherPostData'
import { useContentPublisherVideoData } from '@/areas/dashboard/components/ScheduledPosts/form/useContentPublisherVideoData'
import { useContentPublisherRenderData } from '@/areas/dashboard/components/ScheduledPosts/form/useContentPublisherRenderData'
import { useElementBounding, useMediaControls } from '@vueuse/core'
import TestemonialBanner from '@/components/Banners/TestemonialBanner.vue'
import { useToast } from '@/Hooks/useToast'
import { useUserPreferencesStore } from '@/queries/useUserPreferencesStore'
import ReconnectionNecessary from '@/areas/dashboard/components/ReconnectionNecessary.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { isPast } from 'date-fns'
import SingleLongStorageUploadDialog from '@/components/Dialog/MultiUploadDialog/SingleLongStorageUploadDialog.vue'
import { existsAsync } from '@/areas/editor/startup/generalStartupMethods'
import { queryClient } from '@/services/QueryClient'
import { getGetApiVideosQueryKey } from '@/apis/streamladder-api/videos/videos'
import { useIsMobile } from '@/Hooks/useIsMobile';

useHead({
  title: 'Content Publisher',
  meta: [
    {
      name: 'description',
      content: 'Schedule your clips to be published on TikTok, Instagram and YouTube.',
    },
  ],
})

// In some cases, the video does not exist anymore in posts, and we need to show the upload button.
const forceShowUploadButton = ref(false);
const videoDoesNotExist = () => {
  forceShowUploadButton.value = true;
}
const replacedPostVideoResultUrl = ref<string|null>(null);

////////////////////////////////////////////////////////////////////////////
////////////////// Data fetching and reactive properties ///////////////////
////////////////////////////////////////////////////////////////////////////

const router = useRouter()
const route = useRoute()

const { hasRender, renderData, getFormData: getRenderFormData } = useContentPublisherRenderData()
const { postId, hasPost, postData, getFormData: getPostFormData } = useContentPublisherPostData()
const { hasVideo, videoData, getFormData: getVideoFormData } = useContentPublisherVideoData()

const hasNoSource = computed(() => {
  return (!hasRender.value && !hasPost.value && !hasVideo.value) || forceShowUploadButton.value
})

const userPreferencesStore = useUserPreferencesStore()

async function getPreferredFormData(): Promise<DeepPartial<ContentPublisherFormState>> {
  await userPreferencesStore.loadPreferences()
  return {
    selectedAccounts: userPreferencesStore.preferences.contentPublisherPreferredAccounts,
    youtube: {
      visibility: userPreferencesStore.preferences.contentPublisherYoutubeVisibility,
      isChildFriendly: userPreferencesStore.preferences.contentPublisherYoutubeIsChildFriendly,
      notifySubscribers: userPreferencesStore.preferences.contentPublisherYoutubeNotifySubscribers,
    },
    instagram: {
      shareToFeed: userPreferencesStore.preferences.contentPublisherInstagramShareToFeed,
    },
    tikTok: {
      allowComments: userPreferencesStore.preferences.contentPublisherTikTokAllowComments,
      allowDuet: userPreferencesStore.preferences.contentPublisherTikTokAllowDuets,
      allowStitch: userPreferencesStore.preferences.contentPublisherTikTokAllowStitch,
      privacyLevel: userPreferencesStore.preferences.contentPublisherTikTokPrivacyLevel,
      isDraft: userPreferencesStore.preferences.contentPublisherTikTokDraft,
      disclosePostContent: userPreferencesStore.preferences.contentPublisherTikTokDiscloseContent,
    },
  }
}

const preferencesLoaded = ref(false)

async function initUserPreferences() {
  // fill user preferences into form
  const defaultFormData = await getPreferredFormData()
  deepAssign(form.value, defaultFormData)
  preferencesLoaded.value = true
}

const dataLoaded = ref(false)

async function initForm() {
  // fill render/post/video data into form
  if (renderData.value || postData.value || videoData.value) {
    if (dataLoaded.value) return
    let formOverride: DeepPartial<ContentPublisherFormState> | undefined = undefined
    if (renderData.value) formOverride = getRenderFormData()
    if (postData.value) formOverride = getPostFormData()
    if (videoData.value) formOverride = getVideoFormData()
    if (formOverride) {
      deepAssign(form.value, formOverride)
      // dates do not work in overrride
      if (formOverride.dateTime) form.value.dateTime = formOverride.dateTime
      dataLoaded.value = true
    }
  }
}

onMounted(() => {
  initUserPreferences()
})
watch([renderData, postData, videoData, preferencesLoaded], () => {
  if (!preferencesLoaded.value) return
  initForm()
})

const resultUrl = computed(() => {
  if (replacedPostVideoResultUrl.value) {
    return replacedPostVideoResultUrl.value
  }
  if (videoData.value) {
    return videoData.value.resultUrl
  }
  if (postData.value && postData.value.contentUrl) {
    return postData.value.contentUrl
  }
  return renderData.value?.resultUrl
})

watch(resultUrl, async () => {
  if (resultUrl.value && !await existsAsync(resultUrl.value)) {
    videoDoesNotExist()
  }
}, { immediate: true })

////////////////////////////////////////////////////////////////////////////
////////////////// Form state and computed properties //////////////////////
////////////////////////////////////////////////////////////////////////////

const [initialSocialMediaAccountId, setDefaultSocialMediaAccounts] = useDefaultSocialMedia()
const initialDate = computed(() => (route.query.postDate ? new Date(route.query.postDate) : undefined))

const form = ref<ContentPublisherFormState>({
  selectedAccounts: initialSocialMediaAccountId.value,
  dateTime: initialDate.value,
  youtube: {
    title: '',
    description: '',
    visibility: YouTubeVisibility.Private,
    publishPublic: false,
    isChildFriendly: false,
    notifySubscribers: true,
  },
  instagram: {
    description: '',
    shareToFeed: false,
    videoCoverTimestampMs: 0,
  },
  tikTok: {
    title: '',
    allowDuet: false,
    allowStitch: false,
    allowComments: true,
    privacyLevel: privacyLevels.PUBLIC_TO_EVERYONE,
    isDraft: false,
    videoCoverTimestampMs: 0,
    disclosePostContent: false,
    brandContentToggle: false,
    brandOrganicToggle: false,
  },
})

const userInfo = useUserInfoStore()
const accounts = computed(() => {
  return userInfo.allSocials.filter((account) => form.value.selectedAccounts?.includes(account.id))
})

watch(
  () => userInfo.allSocials,
  (value, oldValue) => {
    if (value.length !== oldValue.length) {
      const newAccount = value.find((account) => !oldValue.find((oldAccount) => oldAccount.id === account.id))
      form.value.selectedAccounts.push(newAccount?.id)
    }
  }
)

const tikTokReconnectNeeded = computed(() =>
  accounts.value.some((account) => account.type === connectionTypes.TIKTOK && account.state !== 'active')
)
const showTikTokForm = computed(
  () =>
    form?.value?.tikTok &&
    accounts.value.some((account) => account.type === connectionTypes.TIKTOK) &&
    !tikTokReconnectNeeded.value
)

const instagramReconnectNeeded = computed(() =>
  accounts.value.some((account) => account.type === connectionTypes.INSTAGRAM && !account.displayName && !account.profileImageUrl)
)
const showInstagramForm = computed(
  () =>
    form?.value?.instagram &&
    accounts.value.some((account) => account.type === connectionTypes.INSTAGRAM && !instagramReconnectNeeded.value)
)

const youtubeReconnectNeeded = computed(() =>
  accounts.value.some((account) => account.type === connectionTypes.YOUTUBE && account.state !== 'active')
)
const showYoutubeForm = computed(
  () =>
    form?.value?.youtube &&
    accounts.value.some((account) => account.type === connectionTypes.YOUTUBE) &&
    !youtubeReconnectNeeded.value
)

const { showToast } = useToast()

////////////////////////////////////////////////////////////////////////////
////////////////// Form validation and submission //////////////////////////
////////////////////////////////////////////////////////////////////////////

const isPublishing = ref(false)
const { validate, errors, scrolltoError } = useValidation(validationSchema, form)
const contentPublisherStore = useContentPublisherStore()

async function submit() {
  if (!canGuardWithPopup('publish')) return

  isPublishing.value = true

  const data = cloneDeep(toRaw(form.value))

  if (!showYoutubeForm.value) unset(data, 'youtube')
  if (!showInstagramForm.value) unset(data, 'instagram')
  if (!showTikTokForm.value) unset(data, 'tikTok')

  const errors = await validate(data)

  if (errors.value) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'Please fill in all required fields',
    })

    scrolltoError()
    isPublishing.value = false
    return
  }

  if (videoDurationInvalid.value) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'Video duration does not meet requirements for Instagram ⏱️',
      subtitle: 'Instagram Reels must be between 3 seconds and 15 minutes long. Please select another clip.',
    });
    isPublishing.value = false
    return
  }

  const targets = accounts.value.map((account) => toTargetDto(form.value, account)).filter(Boolean) as TargetDto[]

  if (targets.length === 0) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'Something went wrong',
      subtitle: 'Please select at least one social media account.',
      timeout: 20000,
    })
    isPublishing.value = false
    return
  }

  const publishData: ReplacePostDto = {
    publishAt: form.value.dateTime,
    targets,
  }

  try {
    if (hasPost.value && postId.value) {

      publishData.contentUrl = resultUrl.value

      await contentPublisherStore.reschedulePost(postId.value, publishData)

      logging.trackEvent('SocialMediaPost Edited', {
        isScheduled: !!form.value.dateTime,
        publishDate: (form.value.dateTime ?? new Date())?.toISOString(),
        targets: targets,
      })

      await router.push({
        name: dashboardRouteNames.contentPublisher.root,
        query: {
          success: `Your post has been ${postId.value ? 'updated' : 'scheduled'} successfully`,
          date: (form.value.dateTime ?? new Date())?.toISOString(),
        },
      })
    } else {
      const postUpdate: CreatePostDto = {
        ...publishData,
        contentUrl: resultUrl.value,
      }

      const postDto = await contentPublisherStore.createPost(postUpdate)

      setDefaultSocialMediaAccounts(form.value.selectedAccounts)

      logging.trackEvent('SocialMediaPost Published', {
        isScheduled: !!form.value.dateTime,
        publishDate: (form.value.dateTime ?? new Date())?.toISOString(),
        targets: targets,
      })

      const navigateToPost = () => {
        router.push({
          name: dashboardRouteNames.contentPublisher.root,
          query: {
            post: postDto.id,
          },
        })
      }

      if (postDto.status?.overallStatus === Status.Failed || postDto.status?.overallStatus === Status.PartialFailure) {
        showToast({
          type: toastEvents.TOAST_ERROR,
          title: 'Something went wrong while posting your clip.',
          subtitle: 'Please try again later.',
          view: navigateToPost,
          viewTitle: 'View post',
        })
      } else {
        showToast({
          type: toastEvents.TOAST_SUCCESS,
          title: `Clip ${postId.value ? 'updated' : form.value.dateTime ? 'scheduled' : 'posted'}!`,
          subtitle: 'Your awesome clip is on its way to stardom. Let the countdown begin! 🚀',
          timeout: 10000,
          view: navigateToPost,
          viewTitle: 'View post',
        })
      }

      await router.push({
        name: dashboardRouteNames.contentPublisher.root,
        query: {
          date: (form.value.dateTime ?? new Date())?.toISOString(),
        },
      })
    }
  } catch (error) {
    console.error(error)
    if (error?.response?.data?.message === 'invalid_targets') {
      showToast({
        type: toastEvents.TOAST_ERROR,
        title: 'Something went wrong while posting your clip.',
        subtitle: 'Please try to reconnect your accounts or contact our support.',
        view: () => {
          router.push({
            name: dashboardRouteNames.support,
          })
        },
        viewTitle: 'Support',
      })
    } else {
      showToast({
        type: toastEvents.TOAST_ERROR,
        title: 'Something went wrong while posting your clip.',
        subtitle: 'Please try again later.',
      })
    }
  } finally {
    userPreferencesStore.patchPreferences({
      contentPublisherPreferredAccounts: form.value.selectedAccounts,
      contentPublisherYoutubeVisibility: form.value.youtube?.visibility,
      contentPublisherYoutubeIsChildFriendly: form.value.youtube?.isChildFriendly,
      contentPublisherYoutubeNotifySubscribers: form.value.youtube?.notifySubscribers,
      contentPublisherTikTokAllowComments: form.value.tikTok?.allowComments,
      contentPublisherTikTokAllowDuets: form.value.tikTok?.allowDuet,
      contentPublisherTikTokAllowStitch: form.value.tikTok?.allowStitch,
      contentPublisherTikTokPrivacyLevel: form.value.tikTok?.privacyLevel,
      contentPublisherTikTokDraft: form.value.tikTok?.isDraft,
      contentPublisherTikTokDiscloseContent: form.value.tikTok?.disclosePostContent,
      contentPublisherInstagramShareToFeed: form.value.instagram?.shareToFeed,
    })

    isPublishing.value = false
  }
}

// Ensure that the selected date is not in the past
watch(() => form.value?.dateTime, (date) => {

  if (date === undefined) {
    return
  }

  if (isPast(date)) {
    form.value.dateTime = undefined
  }
}, { immediate: true });

////////////////////////////////////////////////////////////////////////////
////////////////// Video preview    ////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

const resultVideo = ref()
provide('resultVideo', resultVideo)
const { duration, currentTime, playing } = useMediaControls(resultVideo)
const tracked = ref(false)
watch(
  () => form.value.tikTok?.videoCoverTimestampMs,
  (value) => {
    if (value) {
      if (playing.value) {
        resultVideo.value.pause()
      }
      if (!tracked.value) {
        tracked.value = true
        logging.trackEvent('ResultVideo TikTok Cover Selected')
      }
      currentTime.value = value / 1000
    }
  }
)

watch(
  () => form.value.instagram?.videoCoverTimestampMs,
  (value) => {
    if (value) {
      if (playing.value) {
        resultVideo.value.pause()
      }
      if (!tracked.value) {
        tracked.value = true
        logging.trackEvent('ResultVideo Instagram Cover Selected')
      }
      currentTime.value = value / 1000
    }
  }
)
////////////////////////////////////////////////////////////////////////////
////////////////// Qr code download modal //////////////////////////////////
////////////////////////////////////////////////////////////////////////////

const qrModal = ref()

function openModal() {
  if (!canGuard('qr-code-download')) {
    upgradeDialog.open('qr-code-download')
  } else {
    qrModal.value.publish(resultUrl.value)
    logging.trackEvent('ResultVideo QrCode Generated', {
      Location: 'Dashboard My Clips Page',
      Destination: 'QrCode',
      IsPublic: false,
    })
  }
}

////////////////////////////////////////////////////////////////////////////
////////////////// Select/Upload video clip dialog /////////////////////////
////////////////////////////////////////////////////////////////////////////

const showUploadClipDialog = ref(false)
const showClipSelectionDialog = ref(false)

async function selectVideo(_clip: { id: string, title: string, resultUrl: string }) {

  forceShowUploadButton.value = false
  showUploadClipDialog.value = false
  showClipSelectionDialog.value = false

  await queryClient.invalidateQueries({
    queryKey: getGetApiVideosQueryKey(),
  });

  if (hasPost.value) {
    replacedPostVideoResultUrl.value = _clip.resultUrl;
  } else {
    await router.push({
      name: dashboardRouteNames.contentPublisher.video,
      params: {
        videoId: _clip.id,
      },
      query: route.query,
    })
  }
}

function downloadClicked() {
  logging.trackEvent('Clicked Download');
}

const header = ref<HTMLElement>();
const { top: headerTop, height: headerHeight } = useElementBounding(header);
const isStuck = computed(() => headerTop.value <= 0);

const anySelectedAccountRequiresAction = computed(() =>
  accounts.value.some((account) => !account.hasAccess || account.state !== 'active')
);

const videoDurationInvalid = computed(() => {
  const instagramSelected = accounts.value.find((account) => account.type === connectionTypes.INSTAGRAM);
  if (resultVideo.value && instagramSelected) {
    const minTimeMs = 3000; // 3 seconds
    const maxTimeMs = 60 * 15 * 1000; // 15 minutes
    return resultVideo.value.duration * 1000 < minTimeMs || resultVideo.value.duration > maxTimeMs;
  } else {
    return false;
  }
})

const isMobile = useIsMobile();
</script>

<template>
  <main class="flex h-full w-full">
    <div class="flex w-full flex-grow flex-col items-start pb-6 pt-3 lg:pb-12 lg:pt-6">
      <header
        ref="header"
        :class="{ 'shadow dark:shadow-xl !rounded-none': isStuck }"
        class="layer-1 sticky top-0 z-[100] flex w-full flex-col justify-between gap-2 rounded-t-2xl p-4 md:p-6 transition-[shadow] lg:px-12 xl:flex-row"
      >
        <div class="flex flex-col">
          <h1 v-if="hasRender" class="font-bold text-3xl lg:text-4xl">Publish & share your clip</h1>
          <h1 v-else-if="hasPost" class="font-bold text-3xl lg:text-4xl">Edit scheduled clip</h1>
          <h1 v-else class="font-bold text-3xl lg:text-4xl">Create post</h1>
          <p v-if="hasPost" class="text-style-sm text-brand-state-text-secondary">
            Fine-tune your clip details and preferences
          </p>
          <p v-else class="text-style-sm text-brand-state-text-secondary">
            Grow your channel by posting towards multiple socials
          </p>
        </div>
        <div class="flex flex-col gap-2 lg:flex-row">
          <Transition name="bounce">
            <div
              class="flex gap-2"
              :class="{ 'flex-col sm:flex-row': form.dateTime }"
              v-if="form.selectedAccounts.length > 0"
            >
              <SchedulePostDatePicker v-model:datetime="form.dateTime" :errors="errors" />
              <Button
                variant="primary"
                size="lg"
                class="gap-2 w-full md:w-auto"
                :disabled="hasNoSource || isPublishing || anySelectedAccountRequiresAction"
                @click="submit"
              >
                <spinner v-if="isPublishing" class="mr-2 h-4 w-4 animate-spin text-company-primary-50" />
                <span v-if="hasPost">Update post</span>
                <span v-else-if="form.dateTime">Schedule post</span>
                <span v-else>Post now</span>
                <SendIcon class="h-5 fill-current" />
              </Button>
            </div>
          </Transition>
        </div>
      </header>
      
      <div class="flex w-full flex-col gap-4 px-1 sm:px-6 pt-0 lg:flex-row lg:px-12 lg:pt-0">
        <div class="mx-auto flex flex-col gap-2 w-full lg:w-72 lg:max-h-full">
          <div
            v-if="hasNoSource"
            :style="{ top: `${headerHeight + 8}px` }"
            class="mx-auto sticky py-4 top-48 lg:top-32 box-content flex lg:aspect-[9/16] w-full min-w-0 max-w-full md:max-w-sm flex-col bg-surface-panel-50 border border-surface-panel-border justify-center rounded-xl"
          >
            <div class="flex flex-col items-center gap-5 pt-6">
              <LottieAnimation url="/lottie/searching.json" class="w-36 h-36" />
              <span v-if="forceShowUploadButton" class="text-center text-brand-state-text-primary font-light">
                Select a clip
              </span>
              <span v-else class="text-center text-brand-state-text-primary font-light">
                No clip selected
              </span>
            </div>
            <div class="flex flex-col gap-2 p-4">
              <Button variant="primary" @click="showClipSelectionDialog = true">Select Clip</Button>
              <SingleLongStorageUploadDialog @select="selectVideo">
                <Button variant="outline" class="w-full">Upload file</Button>
              </SingleLongStorageUploadDialog>
            </div>
          </div>
          <template v-else>
            <div
              :class="{'sticky top-32': !hasRender && !hasPost }"
              class="mx-auto box-content aspect-[9/16] max-w-sm overflow-hidden rounded-2xl border-[5px] border-surface-inverse bg-surface-inverse xl:mx-0 max-h-[400px] lg:max-h-none"
            >
              <video ref="resultVideo" :src="resultUrl" :key="resultUrl" class="h-full w-full" width="1080" height="1920" controls />
            </div>
            <Button v-if="!hasRender" variant="outline" @click="forceShowUploadButton = true" class="w-full text-sm">
              Change clip
            </Button>
          </template>
          <div v-if="!forceShowUploadButton && (hasRender || hasPost)" class="flex w-full flex-col gap-2">
            <Button
              variant="outline"
              size="lg"
              as="a"
              :download="`streamladder-${'clipName'}.mp4`"
              :href="resultUrl"
              id="video-result-page-download-link "
              @click="downloadClicked"
              class="flex-grow gap-2"
            >
              Download
              <DownloadIcon />
            </Button>
            <Button v-if="!isMobile" variant="outline" size="lg" class="flex-grow gap-2" @click="openModal">
              Send to Mobile
              <QrIcon />
              <SilverPlanButton :small="true" feature="qr-code-download" />
            </Button>
            <Button
              size="lg"
              :as="RouterLink"
              variant="outline"
              @click="logging.trackEvent('Clicked Edit as Montage')"
              :to="{ name: dashboardRouteNames.montageMaker }"
              class="gap-2"
            >
              Edit as Montage
              <IconSaxVideoVertical />
              <DynamicPlanButton :small="true" feature="montage-maker" />
            </Button>
          </div>
          <TestemonialBanner v-if="hasRender" class="max-w-full w-full md:w-[480px] mx-auto" />
        </div>
        <div class="flex min-w-0 flex-grow flex-col gap-4" v-auto-animate>
          <SocialMediaAccountPicker v-model="form.selectedAccounts" :errors="errors?.['selectedAccounts']" />

          <template v-for="account in accounts" :key="account.id">
            <ReconnectionNecessary :account="account" showLinkToSocialsPage />
          </template>

          <SchedulePostTikTokForm
            v-if="showTikTokForm"
            v-model:title="form.tikTok.title"
            v-model:coverMs="form.tikTok.videoCoverTimestampMs"
            :maxMs="duration"
            :errors="errors"
            :videoAdded="!!resultUrl"
          />

          <SchedulePostInstagramForm
            v-if="showInstagramForm"
            v-model:description="form.instagram.description"
            v-model:coverMs="form.instagram.videoCoverTimestampMs"
            :maxMs="duration"
            :errors="errors"
            :videoAdded="!!resultUrl"
          />

          <SchedulePostYouTubeForm
            v-if="showYoutubeForm"
            v-model:title="form.youtube.title"
            v-model:description="form.youtube.description"
            :videoDuration="duration"
            :errors="errors"
          />

          <!-- socials -->
          <h3 v-if="showYoutubeForm || showTikTokForm || showInstagramForm" class="text-style-lg mt-8 text-center">
            Advanced settings
          </h3>

          <SchedulePostInstagramFormADV
            v-if="form.instagram && showInstagramForm"
            v-model:share-to-feed="form.instagram.shareToFeed"
            :errors="errors"
          />
          <SchedulePostYoutubeFormADV
            v-if="form.youtube && showYoutubeForm"
            v-model:is-child-friendly="form.youtube.isChildFriendly"
            v-model:visibility="form.youtube.visibility"
            v-model:notify-subscribers="form.youtube.notifySubscribers"
            :errors="errors"
          />
          <SchedulePostTikTokFormADV
            v-if="form.tikTok && showTikTokForm"
            :accounts="accounts"
            v-model:allow-comments="form.tikTok.allowComments"
            v-model:allow-duet="form.tikTok.allowDuet"
            v-model:allow-stitch="form.tikTok.allowStitch"
            v-model:privacy-level="form.tikTok.privacyLevel"
            v-model:disclose-post-content="form.tikTok.disclosePostContent"
            v-model:brand-content-toggle="form.tikTok.brandContentToggle"
            v-model:brand-organic-toggle="form.tikTok.brandOrganicToggle"
            v-model:is-draft="form.tikTok.isDraft"
            :errors="errors"
          />
        </div>
      </div>
    </div>
  </main>
  <GetQrCode ref="qrModal" />
  <select-clip-dialog v-model="showClipSelectionDialog" @clipSelected="selectVideo" />
</template>

<style scoped lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.25s;
}

.bounce-leave-active {
  animation: none;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

