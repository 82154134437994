<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { createMovableContext } from '@/modules/SLMovable/useMovableContext'
import { v4 as uuid } from 'uuid'
import MovableMask from '@/modules/SLMovable/MovableMask.vue'

const props = withDefaults(defineProps<{ 
  blackout?: boolean, maskClass?: string, 
  containerSelector?: string, containerX?: number, containerY?: number
}>(), {
  containerSelector: 'body',
  containerX: 0,
  containerY: 0,
})

const cropper = ref<HTMLElement | null>(null)

const uniqueId = uuid()
const maskId = computed(() => (props.blackout ? `movable-mask-${uniqueId}` : null))

const snapGridId = `snap-grid-${uniqueId}`

const { top, left, width, height, resize } = createMovableContext(cropper, { blackout: maskId, snapGridId })

watch([() => props.containerX, () => props.containerY], () => {
  resize()
})

// Cheeky hack to ensure teleport targets are mounted before rendering any crops.
const initialized = ref(false)
onMounted(() => {
  initialized.value = true
})

defineExpose({ resize })
</script>

<template>
  <div ref="cropper" @contextmenu.stop.prevent>
    <MovableMask class="absolute inset-0" :class="maskClass" v-if="blackout" :mask-id="maskId" />
    <Teleport :to="containerSelector" v-if="initialized">
      <div :id="snapGridId" class="absolute pointer-events-none z-[10]" :style="{ 
        top: top - containerY + 'px', 
        left: left - containerX + 'px', 
        width: width + 'px', height: height + 'px'
      }" />
    </Teleport>
    <slot v-if="initialized" />
  </div>
</template>

<style scoped lang="scss">
.mask {
  mask-image: var(--mask-id);
}
</style>
