import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { startupVideo, startupWithDefaultLayout } from '@/areas/editor/startup/generalStartupMethods'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { getApiKickClipsClipId } from '@/apis/streamladder-api/kick-clips/kick-clips';
import type { ClipDto } from '../@type/ClipDto';
import { listenForClipDownload, downloadClipIfMp4UrlIsEmpty } from '@/areas/editor/startup/listenForClipDownload';

export async function startupFromKickClipByClipId(clipId: string, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  try {
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }

    const clip = await getApiKickClipsClipId(clipId) as unknown as ClipDto
    config.signal?.throwIfAborted()

    clip.title = clip.title ?? 'Kick Clip';

    const response = await downloadClipIfMp4UrlIsEmpty(clip, config, 'kick-clip');
    config.signal?.throwIfAborted()
    if ('error' in response) {
      return { error: response.error, clip: null };
    } else {
      return { error: null, clip: { ...response, id: response.id ?? clipId } }
    }
  } catch (error) {
    return handleStartupError(error)
  }
}
