<script setup lang="ts">
import type { Feature } from '@/data/features'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { useGuard } from '@/Hooks/useGuard'
import { Button } from '@/components/ui/button'
import { computed } from 'vue'

const props = defineProps<{
  feature: Feature
  title: string
  subtitle: string
  class?: string
}>()

const hasUnlockedFeature = computed(() => useGuard(props.feature).value)
</script>

<template>
  <div class="relative p-4" :class="props.class" v-if="!hasUnlockedFeature">
    <slot />

    <section class="absolute inset-0 z-[1] backdrop-blur-sm">
      <div class="sticky top-0 w-full flex flex-col items-center justify-center h-full max-h-[100dvh]">
        <div
          class="group flex cursor-pointer flex-col items-center justify-center gap-4 rounded-2xl border border-brand-state-not-connected-border bg-brand-state-not-connected px-16 py-12 shadow-lg"
          @click="upgradeDialog.open(feature, true)"
        >
          <h2 class="text-2xl font-semibold">{{ title }}</h2>
          <p class="max-w-[50ch] text-center font-light opacity-75">{{ subtitle }}</p>
          <Button variant="gradient" class="group-hover:shadow-gradient">
            <DynamicPlanButtonWithTooltip :feature="feature" />
            Upgrade now
            <IconSaxArrowRight class="transition-transform will-change-transform group-hover:translate-x-2" />
          </Button>
        </div>
      </div>
    </section>
  </div>
  <slot v-else />
</template>

<style scoped lang="scss"></style>
