<script setup lang="ts">
import { type HTMLAttributes, computed, onMounted, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'
import type { PrimitiveProps } from 'radix-vue'

interface Props extends PrimitiveProps {
  defaultValue?: string | number
  modelValue?: string | number
  class?: HTMLAttributes['class']
  autoFocus?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  as: 'input',
})

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})

const className = computed(() => cn(
  'flex h-12 w-full rounded-md border text-brand-state-text-primary border-surface-input-border hover:shadow-hover bg-surface-panel-100 px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-brand-state-text-placeholder focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-state-active-border focus-within:outline-none focus-within:ring-2 focus-within:ring-brand-state-active-border hover:ring-2 hover:ring-brand-state-hover-border disabled:cursor-not-allowed disabled:opacity-50',
  props.class
))

const inputRef = ref<HTMLInputElement>();

onMounted(() => {
  if (props.autoFocus) {
    inputRef.value?.focus();
  }
})

// Had to specify the input element, since I couldn't get model binding working otherwise.
</script>

<template>
  <input ref="inputRef" v-if="as === 'input'" v-model="modelValue" :class="className" v-bind="props" />
  <component v-else :is="props.as" :class="className">
    <slot />
  </component>
</template>
