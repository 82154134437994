/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { ClipGptProjectDto, CreateClipGptProjectRequest } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiClipGptProjects = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<ClipGptProjectDto[]>({ url: `/api/ClipGptProjects`, method: 'GET', signal }, options)
}

export const getGetApiClipGptProjectsQueryKey = () => {
  return ['api', 'ClipGptProjects'] as const
}

export const getGetApiClipGptProjectsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiClipGptProjects>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptProjects>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiClipGptProjectsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiClipGptProjects>>> = ({ signal }) =>
    getApiClipGptProjects(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiClipGptProjects>>,
    TError,
    TData
  >
}

export type GetApiClipGptProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiClipGptProjects>>>
export type GetApiClipGptProjectsQueryError = unknown

export const useGetApiClipGptProjects = <
  TData = Awaited<ReturnType<typeof getApiClipGptProjects>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptProjects>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiClipGptProjectsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const postApiClipGptProjects = (
  createClipGptProjectRequest: MaybeRef<CreateClipGptProjectRequest>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  createClipGptProjectRequest = unref(createClipGptProjectRequest)

  return streamLadderAxiosInstance<ClipGptProjectDto>(
    {
      url: `/api/ClipGptProjects`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createClipGptProjectRequest,
    },
    options
  )
}

export const getPostApiClipGptProjectsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiClipGptProjects>>,
    TError,
    { data: CreateClipGptProjectRequest },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiClipGptProjects>>,
  TError,
  { data: CreateClipGptProjectRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiClipGptProjects>>,
    { data: CreateClipGptProjectRequest }
  > = (props) => {
    const { data } = props ?? {}

    return postApiClipGptProjects(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiClipGptProjectsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiClipGptProjects>>>
export type PostApiClipGptProjectsMutationBody = CreateClipGptProjectRequest
export type PostApiClipGptProjectsMutationError = unknown

export const usePostApiClipGptProjects = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiClipGptProjects>>,
    TError,
    { data: CreateClipGptProjectRequest },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postApiClipGptProjects>>,
  TError,
  { data: CreateClipGptProjectRequest },
  TContext
> => {
  const mutationOptions = getPostApiClipGptProjectsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiClipGptProjectsProjectId = (
  projectId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  projectId = unref(projectId)

  return streamLadderAxiosInstance<ClipGptProjectDto>(
    { url: `/api/ClipGptProjects/${projectId}`, method: 'GET', signal },
    options
  )
}

export const getGetApiClipGptProjectsProjectIdQueryKey = (projectId: MaybeRef<string>) => {
  return ['api', 'ClipGptProjects', projectId] as const
}

export const getGetApiClipGptProjectsProjectIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiClipGptProjectsProjectId>>,
  TError = unknown
>(
  projectId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptProjectsProjectId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiClipGptProjectsProjectIdQueryKey(projectId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiClipGptProjectsProjectId>>> = ({ signal }) =>
    getApiClipGptProjectsProjectId(projectId, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(projectId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptProjectsProjectId>>, TError, TData>
}

export type GetApiClipGptProjectsProjectIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiClipGptProjectsProjectId>>
>
export type GetApiClipGptProjectsProjectIdQueryError = unknown

export const useGetApiClipGptProjectsProjectId = <
  TData = Awaited<ReturnType<typeof getApiClipGptProjectsProjectId>>,
  TError = unknown
>(
  projectId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptProjectsProjectId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiClipGptProjectsProjectIdQueryOptions(projectId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
