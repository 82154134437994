import UAParser from 'ua-parser-js'
import { useIsMobile } from '@/Hooks/useIsMobile'

export function throwIfBrowserUnsupported() {
  if (!isBrowserSupported()) {
    throw new UnsupportedBrowserError()
  }
}

export function isBrowserSupported() {

  // Chrome 130, Firefox 130 and Edge 131 are supported
  const parser = new UAParser();
  const result = parser.getResult();

  if (result.browser.name === 'Chrome' && result.browser.version) {
    const version = parseInt(result.browser.version.split('.')[0]);
    return version >= 130;
  } else if (result.browser.name === 'Firefox' && result.browser.version) {
    const version = parseInt(result.browser.version.split('.')[0]);
    return version >= 130;
  } else if (result.browser.name === 'Edge' && result.browser.version) {
    const version = parseInt(result.browser.version.split('.')[0]);
    return version >= 131;
  } else {
    // For now, we support all mobile browsers as they'll use server side rendering.
    const isMobile = useIsMobile();
    return isMobile.value;
  }
}

export class UnsupportedBrowserError extends Error {

  constructor() {

    const parser = new UAParser();
    const result = parser.getResult();
    const browser = result.browser.name;

    if (browser === 'Safari') {
      super(`Please update to the latest version of ${browser} and ${result.os.name} or try Chrome.`);
    } else if (browser === 'Chrome' || browser === 'Edge') {
      super(`Please update to the latest version of ${browser} to use StreamLadder.`);
    } else {
      super(`Please use the latest Chrome, Firefox or Edge to use StreamLadder.`);
    }
    
    return this;
  }
}
