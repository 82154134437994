import { tiers } from '@/enums/tiers'
import CustomStickers from '@/assets/features/custom-stickers.png'
import CustomText from '@/assets/features/custom-text.png'
import HighDefinition from '@/assets/features/high-definition.png'
import Rocket from '@/assets/features/rocket.png'
import Coffee from '@/assets/features/coffee.png'
import Brush from '@/assets/features/brush.png'
import { computed } from 'vue'
import type { MaybeRefOrGetter } from '@vueuse/shared'
import { toValue } from '@vueuse/core'

const featuresArray = [
  {
    // Allows the user to use custom text on their video.
    name: 'text',
    promo: {
      title: 'Unlock custom text',
      subtitle: 'Upgrade to Silver to add custom text to your clip.',
      reason: 'User Clicked: Text',
      visual: CustomText,
    },
    dialog: {
      title: 'Upgrade to add text to your clip and more',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.SILVER,
  },
  {
    // Allows the user to use stickers on their video.
    name: 'stickers',
    promo: {
      title: 'Unlock custom stickers',
      subtitle: 'Upgrade to Silver and add stickers to your clip.',
      reason: 'User Clicked: Stickers',
      visual: CustomStickers,
    },
    dialog: {
      title: 'Upgrade to unlock adding stickers and more',
      subtitle: 'Let people know where to find you by adding stickers. Choose the perfect plan for you.',
    },
    tier: tiers.SILVER,
  },
  {
    // Allows the user to output the video in a 1080p resolution instead of the 720.
    name: 'resolution-1080',
    alias: 'high-quality exports',
    promo: {
      title: 'Get better video quality and more',
      subtitle: 'Upgrade your subscription to unlock the following features:',
      usps: ['High-quality exports (1080p / 60fps)', 'Save Templates'],
      reason: 'Resolution-1080p',
      visual: HighDefinition,
    },
    dialog: {
      title: 'Upgrade for higher video quality and more',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.SILVER,
  },
  {
    // Allows the user to use premium templates.
    name: 'layouts',
    promo: {
      title: 'Using a StreamLadder Premium layout',
      subtitle: 'To use <strong> StreamLadder Premium templates</strong> you need StreamLadder Silver account',
      reason: 'User Clicked: Premium template',
    },
    dialog: {
      title: 'Upgrade to unlock premium layouts',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.SILVER,
  },
  {
    // Allows the user to use custom stickers.
    name: 'upload-custom-stickers',
    alias: 'custom stickers',
    tier: tiers.SILVER,
  },
  {
    name: 'publish',
    tier: tiers.SILVER,
  },
  {
    // Allows the user to use SL on mobile.
    name: 'mobile',
    tier: tiers.SILVER,
  },
  {
    // Saves a custom template for the user to re-use on a later time.
    name: 'save-template',
    alias: 'saving templates',
    promo: {
      title: 'Saving a template',
      subtitle: 'To <strong>save templates</strong> you need StreamLadder Silver',
      reason: 'User Clicked: Save Template',
    },
    dialog: {
      title: 'Upgrade to save templates and more!',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.SILVER,
  },
  {
    // Allows the user to publish their download their video with a QR code.
    name: 'save-video',
    alias: 'storing videos for up to 7 days',
    dialog: {
      title: 'Upgrade today and save your clips for 7 days',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.SILVER,
  },
  {
    // Allows the user to create montages with their clips.
    name: 'montage-maker',
    alias: 'create a montage',
    dialog: {
      title: 'Upgrade to Gold today and start creating montages',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.GOLD,
  },
  {
    // Allows the user to publish their download their video with a QR code.
    name: 'qr-code-download',
    tier: tiers.SILVER,
  },
  {
    // Allows the user to publish their video to TikTok shorts.
    name: 'send-to-tiktok',
    tier: tiers.SILVER,
  },
  {
    // Allows the user to publish their video to YouTube shorts.
    name: 'send-to-youtube',
    tier: tiers.SILVER,
  },
  {
    // Allows the user to generate automated captions for their video.
    name: 'captions',
    promo: {
      title: 'Unlock auto captions',
      subtitle: 'Upgrade to {{ tier }} and add captions to your clip easily.',
      usps: [
        'Generate precise captions automatically',
        'Choose from various caption styles',
        'Sprinkle in some fun with AI emojis',
      ],
      reason: 'User Clicked: Captions silver button',
      visual: Rocket,
    },
    dialog: {
      title: 'Upgrade to unlock auto captions and more',
      subtitle: 'Choose the perfect plan for you.',
    },
    isLive: true,
    tier: tiers.SILVER,
  },
  {
    // Renders the video on the server. Used when the user is on mobile or when the browser is incompatible
    name: 'server-side-rendering',
    tier: tiers.SILVER,
    dialog: {
      title: 'Use StreamLadder on your phone',
      subtitle: 'To use StreamLadder on your phone, please upgrade to StreamLadder Silver!',
    },
  },
  {
    // Gold members can always use server-side-rendering + the user can navigate away from the render and a notification queue is displayed
    name: 'server-side-queueing',
    promo: {
      title: 'Multitask Like a Pro',
      subtitle: 'Upgrade to gold and get the power to:',
      usps: ['Render multiple clips simultaneously', 'Skip the loading screens'],
      allFeaturesButton: true,
      reason: 'User Clicked: Tired of waiting',
      visual: Coffee,
    },
    dialog: {
      title: 'Upgrade to Gold to render multiple clips simultaneously',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.GOLD,
  },
  {
    name: 'video-segmentation',
    tier: tiers.SILVER,
    dialog: {
      title: 'Upgrade to Silver to split a clip',
      subtitle:
        'Exporting videos with split segments is a feature exclusively available to our Silver or Gold Plan members.',
    },
  },
  {
    // Allows the user to have multiple YouTube/TikTok accounts connected to their StreamLadder account.
    name: 'zoom-segments',
    alias: 'zoom in for emphasis',
    dialog: {
      title: 'Upgrade to Gold to add zoom effects',
      subtitle: 'Videos with zoom effects is a feature available exclusively to our Gold Plan members.',
    },
    tier: tiers.GOLD,
  },
  {
    // Allows the user to have multiple YouTube/TikTok accounts connected to their StreamLadder account.
    name: 'multiple-publishing-accounts',
    alias: 'connecting multiple accounts',
    promo: {
      title: 'Connect multiple accounts',
      subtitle: 'Connecting multiple social media accounts required a StreamLadder gold account',
      reason: 'User Clicked: Add publishing account',
    },
    dialog: {
      title: 'Upgrade to Gold to connect multiple accounts',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.GOLD,
  },
  {
    // Allows the user to create custom text styles.
    name: 'brandkit',
    alias: 'custom styles',
    promo: {
      title: 'Unlock custom styles',
      subtitle: 'Upgrade to Gold to create custom text styles.',
      reason: 'User Clicked: brandkit',
      visual: Brush,
    },
    dialog: {
      title: 'Upgrade to Gold to create custom text styles',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.GOLD,
  },
  {
    // Allows the user to schedule a video on a specific date.
    name: 'scheduler',
    promo: {
      title: 'Upgrade to schedule your posts',
      subtitle: 'Save precious time by scheduling your clips to be published on a specific date and time',
      reason: 'User Clicked: scheduler',
    },
    dialog: {
      title: 'Upgrade to Gold to schedule your posts',
      subtitle: 'Choose the perfect plan for you.',
    },
    tier: tiers.GOLD,
  },
  {
    // Allows the user refer other users to StreamLadder.
    name: 'referral',
    tier: tiers.GOLD,
  },
  {
    name: 'upload-large-files',
    tier: tiers.SILVER,
    dialog: {
      title: 'Upgrade to unlock uploading files greater than 200 MB',
      subtitle: 'Choose the perfect plan for you.',
      reason: 'User uploaded a file that exceeded upload limit',
    },
  },
  {
    name: 'custom-layouts',
    tier: tiers.SILVER,
    dialog: {
      title: 'Upgrade to Silver to unlock adding unlimited crops',
      subtitle: 'Choose the perfect plan for you.',
      reason: 'User Clicked: Custom Layouts',
    },
  },
  {
    name: 'giphy-stickers',
    tier: tiers.GOLD,
    dialog: {
      title: 'Upgrade to Gold to unlock Giphy stickers',
      subtitle: 'Choose the perfect plan for you.',
      reason: 'User Clicked: Giphy stickers',
    },
  },
  {
    name: 'twitch-emotes',
    tier: tiers.GOLD,
    dialog: {
      title: 'Upgrade to Gold to unlock Twitch Emote stickers',
      subtitle: 'Choose the perfect plan for you.',
      reason: 'User Clicked: Twitch Emote stickers',
    },
  },
  {
    name: 'skip-the-queue',
    tier: tiers.GOLD,
    dialog: {
      title: 'Upgrade to Gold to skip the queue',
      subtitle: 'Choose the perfect plan for you.',
      reason: 'User Clicked: Skip the Queue',
    },
  },
  {
    name: 'sounds',
    tier: tiers.GOLD,
    dialog: {
      title: 'Upgrade to Gold to add Sound Effects',
      subtitle: 'Choose the perfect plan for you.',
      reason: 'User Clicked: Sound Effects',
    },
  },
  {
    name: 'clip-downloading',
    tier: tiers.FREE,
  },
  {
    name: 'clip-gpt',
    tier: tiers.GOLD,
  },
  {
    name: 'export-without-watermark',
    tier: tiers.GOLD,
  },
  {
    name: 'animated-social-stickers',
    tier: tiers.GOLD,
    dialog: {
      title: 'Upgrade to Gold to add Animated Social Stickers',
      subtitle: 'Choose the perfect plan for you.',
    },
  }
] as const

type FeatureDefinition = (typeof featuresArray)[number]
export type Feature = FeatureDefinition['name']
export default featuresArray

export const useFeature = (feature: MaybeRefOrGetter<Feature | undefined>) => {
  return computed(() => findFeature(toValue(feature)))
}

export const findFeature = (feature: Feature) => {
  return featuresArray.find((f) => f.name === feature)
}
