import type { CSSProperties } from 'vue';
import { keys } from 'lodash-es';

const button = ref<HTMLElement | null>(null);
const icon = ref<HTMLElement | null>(null);
const arrowIcon = ref<HTMLElement | null>(null);
const chat = ref<HTMLElement | null>(null);
const notificationWindow = ref<HTMLElement | null>(null);

function assignButtonValueOnce() {
  if (button.value === null) {
    const bbFeedbackButton = document.querySelector('.bb-feedback-button');
    if (bbFeedbackButton instanceof HTMLElement) {
      button.value = bbFeedbackButton;
      icon.value = bbFeedbackButton.querySelector('.bb-feedback-button-icon');
      arrowIcon.value = bbFeedbackButton.querySelector('.bb-feedback-button-icon .bb-logo-arrowdown');
    }
  }
}

function assignChatValueOnce() {
  if (chat.value === null) {
    const gleapFrameContainer = document.querySelector('.gleap-frame-container');
    if (gleapFrameContainer instanceof HTMLElement) {
      chat.value = gleapFrameContainer;
    }
  }
}

function assignNotificationWindowValueOnce() {
  if (notificationWindow.value === null) {
    const gleapNotificationContainer = document.querySelector('.gleap-notification-container');
    if (gleapNotificationContainer instanceof HTMLElement) {
      notificationWindow.value = gleapNotificationContainer;
    }
  }
}

const observer = new MutationObserver(() => {
  assignButtonValueOnce();
  assignChatValueOnce();
  assignNotificationWindowValueOnce();
  if (button.value !== null && chat.value !== null) {
    observer.disconnect();
  }
});

observer.observe(document.body, {
  subtree: true,
  childList: true,
});

export function useChat() {
  return { button, chat };
}

function applyStyleTo(element: HTMLElement, style: CSSProperties) {

  for (const key in element.style) {
    element.style.removeProperty(key);
  }

  for (const key of keys(style)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element.style[key] = style[key as keyof CSSProperties] as string;
  }
}

function useElementStyle(element: MaybeRefOrGetter<HTMLElement | null>) {

  const style = ref<CSSProperties>({});
  const elementRef = computed(() => toValue(element));

  const stop = watch([style, elementRef], ([style, element]) => {
    if (element) {
      applyStyleTo(element, style);
    }
  }, { immediate: true });

  onUnmounted(() => {
    const elementValue = toValue(element);
    if (elementValue) {
      applyStyleTo(elementValue, {});
    }
    stop();
  });

  return style;
}

export function useChatButtonStyle() {
  return useElementStyle(button);
}

export function useChatWindowStyle() {
  return useElementStyle(chat);
}

export function useChatButtonIconStyle() {
  return useElementStyle(icon);
}

export function useChatArrowIconStyle() {
  return useElementStyle(arrowIcon);
}

export function useNotificationWindowStyle() {
  return useElementStyle(notificationWindow);
}
