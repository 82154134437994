/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type {
  CreateMontageDto,
  GetApiMontagesParams,
  MontageDetailsDto,
  MontageDto,
  MontageWebhookPayload,
} from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const postApiMontages = (
  createMontageDto: MaybeRef<CreateMontageDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  createMontageDto = unref(createMontageDto)

  return streamLadderAxiosInstance<MontageDto>(
    { url: `/api/montages`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createMontageDto },
    options
  )
}

export const getPostApiMontagesMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiMontages>>,
    TError,
    { data: CreateMontageDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiMontages>>, TError, { data: CreateMontageDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiMontages>>, { data: CreateMontageDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postApiMontages(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiMontagesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiMontages>>>
export type PostApiMontagesMutationBody = CreateMontageDto
export type PostApiMontagesMutationError = void

export const usePostApiMontages = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiMontages>>,
    TError,
    { data: CreateMontageDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postApiMontages>>,
  TError,
  { data: CreateMontageDto },
  TContext
> => {
  const mutationOptions = getPostApiMontagesMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiMontages = (
  params?: MaybeRef<GetApiMontagesParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return streamLadderAxiosInstance<MontageDto[]>(
    { url: `/api/montages`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiMontagesQueryKey = (params?: MaybeRef<GetApiMontagesParams>) => {
  return ['api', 'montages', ...(params ? [params] : [])] as const
}

export const getGetApiMontagesQueryOptions = <TData = Awaited<ReturnType<typeof getApiMontages>>, TError = void>(
  params?: MaybeRef<GetApiMontagesParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMontages>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiMontagesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMontages>>> = ({ signal }) =>
    getApiMontages(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiMontages>>,
    TError,
    TData
  >
}

export type GetApiMontagesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMontages>>>
export type GetApiMontagesQueryError = void

export const useGetApiMontages = <TData = Awaited<ReturnType<typeof getApiMontages>>, TError = void>(
  params?: MaybeRef<GetApiMontagesParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMontages>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMontagesQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiMontagesId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return streamLadderAxiosInstance<MontageDto>({ url: `/api/montages/${id}`, method: 'GET', signal }, options)
}

export const getGetApiMontagesIdQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'montages', id] as const
}

export const getGetApiMontagesIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiMontagesId>>, TError = void>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMontagesId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiMontagesIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMontagesId>>> = ({ signal }) =>
    getApiMontagesId(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiMontagesId>>, TError, TData>
}

export type GetApiMontagesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMontagesId>>>
export type GetApiMontagesIdQueryError = void

export const useGetApiMontagesId = <TData = Awaited<ReturnType<typeof getApiMontagesId>>, TError = void>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMontagesId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMontagesIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const deleteApiMontagesId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  id = unref(id)

  return streamLadderAxiosInstance<void>({ url: `/api/montages/${id}`, method: 'DELETE' }, options)
}

export const getDeleteApiMontagesIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiMontagesId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiMontagesId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiMontagesId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deleteApiMontagesId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiMontagesIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiMontagesId>>>

export type DeleteApiMontagesIdMutationError = void

export const useDeleteApiMontagesId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiMontagesId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof deleteApiMontagesId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteApiMontagesIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiMontagesIdRetry = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  id = unref(id)

  return streamLadderAxiosInstance<MontageDto>({ url: `/api/montages/${id}/retry`, method: 'POST' }, options)
}

export const getPostApiMontagesIdRetryMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiMontagesIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiMontagesIdRetry>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiMontagesIdRetry>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return postApiMontagesIdRetry(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiMontagesIdRetryMutationResult = NonNullable<Awaited<ReturnType<typeof postApiMontagesIdRetry>>>

export type PostApiMontagesIdRetryMutationError = void

export const usePostApiMontagesIdRetry = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiMontagesIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof postApiMontagesIdRetry>>, TError, { id: string }, TContext> => {
  const mutationOptions = getPostApiMontagesIdRetryMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiMontagesIdDetails = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return streamLadderAxiosInstance<MontageDetailsDto>(
    { url: `/api/montages/${id}/details`, method: 'GET', signal },
    options
  )
}

export const getGetApiMontagesIdDetailsQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'montages', id, 'details'] as const
}

export const getGetApiMontagesIdDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiMontagesIdDetails>>,
  TError = void
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMontagesIdDetails>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiMontagesIdDetailsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMontagesIdDetails>>> = ({ signal }) =>
    getApiMontagesIdDetails(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiMontagesIdDetails>>, TError, TData>
}

export type GetApiMontagesIdDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMontagesIdDetails>>>
export type GetApiMontagesIdDetailsQueryError = void

export const useGetApiMontagesIdDetails = <TData = Awaited<ReturnType<typeof getApiMontagesIdDetails>>, TError = void>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMontagesIdDetails>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiMontagesIdDetailsQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const postWebhooksMontages = (
  montageWebhookPayload: MaybeRef<MontageWebhookPayload>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  montageWebhookPayload = unref(montageWebhookPayload)

  return streamLadderAxiosInstance<void>(
    {
      url: `/webhooks/montages`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: montageWebhookPayload,
    },
    options
  )
}

export const getPostWebhooksMontagesMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksMontages>>,
    TError,
    { data: MontageWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postWebhooksMontages>>,
  TError,
  { data: MontageWebhookPayload },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWebhooksMontages>>,
    { data: MontageWebhookPayload }
  > = (props) => {
    const { data } = props ?? {}

    return postWebhooksMontages(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostWebhooksMontagesMutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksMontages>>>
export type PostWebhooksMontagesMutationBody = MontageWebhookPayload
export type PostWebhooksMontagesMutationError = void

export const usePostWebhooksMontages = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksMontages>>,
    TError,
    { data: MontageWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postWebhooksMontages>>,
  TError,
  { data: MontageWebhookPayload },
  TContext
> => {
  const mutationOptions = getPostWebhooksMontagesMutationOptions(options)

  return useMutation(mutationOptions)
}
