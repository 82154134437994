import { AudioEncoderConfig } from "./get-encoder-config";

export function resampleAudio(inputSamples: Float32Array, inputSampleRate: number, outputSampleRate: number) {
    const inputLength = inputSamples.length;
    const outputLength = Math.ceil(inputLength * outputSampleRate / inputSampleRate);
    const outputSamples = new Float32Array(outputLength);

    const sampleRatio = inputSampleRate / outputSampleRate;

    for (let i = 0; i < outputLength; i++) {
        const index = i * sampleRatio;
        const lowerIndex = Math.floor(index);
        const upperIndex = Math.min(lowerIndex + 1, inputLength - 1);
        const weight = index - lowerIndex;

        outputSamples[i] = inputSamples[lowerIndex] * (1 - weight) + inputSamples[upperIndex] * weight;
    }

    return outputSamples;
}



export async function encodeAudio(data: Float32Array[], outputFrameDuration: number, sampleRate: number, 
    handleEncodedAudioChunk: (chunk: EncodedAudioChunk, timestamp: number) => void, onError: (error: string) => void) {

    const audioEncoder = new AudioEncoder({
        output: (chunk) => {
            handleEncodedAudioChunk(chunk, chunk.timestamp)
        },
        error: (e) => {
            onError('Audio encoder error ' + e.message );
            console.error(e.message);
        }
    });
    audioEncoder.configure(AudioEncoderConfig);

    // temp fix for mono audio
    if (!data[1]) data[1] = data[0];

    // max 2 channels
    if (data.length > 2) data = data.slice(0, 2)

    const blockSize = 960; // Opus typically encodes in 20ms frames (960 samples at 48kHz)
    const totalFrames = data[0].length;
    let frameStart = 0;

    let joinedSamples = new Float32Array(blockSize * data.length);

    while (frameStart < totalFrames) {
        const frameEnd = Math.min(frameStart + blockSize, totalFrames);

        const joinedSamplesLength = (frameEnd - frameStart) * data.length;

        if (joinedSamples.length !== joinedSamplesLength) {
            joinedSamples = new Float32Array(joinedSamplesLength);
        }

        let offset = 0;
        for (let channelNr = 0; channelNr < data.length; channelNr++) {
            joinedSamples.set(data[channelNr].subarray(frameStart, frameEnd), offset);
            offset += (frameEnd - frameStart);
        }

        // Create an AudioData object for each frame
        // I am not sure why, but 2 * outputFrameDuration correction on the audio makes it almost perfect sync like on the input video. (the difference is not really noticeable)
        const audioData = new AudioData({
            timestamp: (frameStart / sampleRate) * 1e6 - outputFrameDuration * 2,
            data: joinedSamples.buffer, // Interleaved audio data
            format: 'f32-planar',
            sampleRate: sampleRate,
            numberOfFrames: joinedSamples.length / data.length,
            numberOfChannels: data.length,
        });

        // Encode the AudioData object
        audioEncoder.encode(audioData);

        audioData.close();

        frameStart = frameEnd;
    }
    await audioEncoder.flush();
}