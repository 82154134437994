<script lang="ts" setup>
import { FocusTypes } from '@/store/editor/editorFocus'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { computed } from 'vue'
import StickerNode from '@/components/Editor/Timeline/tracks/StickerNode.vue'

const stickersStore = useStickersStore()

const textStickers = computed(() => stickersStore.entities.filter((sticker) => 'htmlContent' in sticker && sticker.type !== 'social'))
const socialStickers = computed(() => stickersStore.entities.filter((sticker) => !textStickers.value.some((textSticker) => textSticker.id === sticker.id)))
</script>

<template>
  <TimeLineTrack v-if="textStickers.length > 0" class="relative h-6 w-full">
    <StickerNode
      v-for="sticker in textStickers"
      :id="sticker.id"
      :key="sticker.id"
      :focus-type="FocusTypes.TEXTSTICKER"
    />
  </TimeLineTrack>
  <TimeLineTrack v-if="socialStickers.length > 0" class="relative h-6 w-full">
    <StickerNode
      v-for="sticker in socialStickers"
      :id="sticker.id"
      :key="sticker.id"
      :focus-type="FocusTypes.STICKER"
    />
  </TimeLineTrack>
</template>
