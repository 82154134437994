<script lang="ts" setup>
import { inject, ref, type Ref, watch, onMounted } from 'vue'

const props = defineProps<{ currentTime: number }>()

const video = inject<Ref<HTMLVideoElement | null>>('resultVideo')!
const localVideo = ref<HTMLVideoElement | null>(null)
const isLoaded = ref(false)

const canvas = ref<HTMLCanvasElement | null>(null)

async function captureFrame() {

  if (!isLoaded.value || !localVideo.value || !canvas.value) {
    return
  }

  const wasPaused = localVideo.value.paused

  if (wasPaused) {
    try {
      await localVideo.value.play().catch(console.error)
    } catch (e) {
      console.error(e)
    }
  }
  
  // Contain crop
  const scale = Math.min(canvas.value.width / localVideo.value.videoWidth, canvas.value.height / localVideo.value.videoHeight)
  const width = scale * localVideo.value.videoWidth
  const height = scale * localVideo.value.videoHeight
  
  const x = 0.5 * canvas.value.width - 0.5 * width
  const y = 0.5 * canvas.value.height - 0.5 * height

  const context = canvas.value.getContext('2d')!
  context.fillRect(0, 0, canvas.value.width, canvas.value.height)
  context.drawImage(localVideo.value, x, y, width, height)
  
  if (wasPaused) {
    localVideo.value.pause()
  }
}

watch(() => props.currentTime, () => {
  if (!localVideo.value) return
  localVideo.value.currentTime = props.currentTime
})

function onLoad() {
  if (!localVideo.value) return
  isLoaded.value = true
  localVideo.value.currentTime = props.currentTime
  captureFrame()
}

onMounted(() => captureFrame())
</script>

<template>
  <div v-if="video" class="w-[75px] relative aspect-[9/16]">
    <video muted ref="localVideo" class="invisible pointer-events-none w-full" @durationchange="onLoad" :src="video?.src" @seeked="captureFrame" playsinline disablePictureInPicture disableRemotePlayback />
    <!-- Using 1080x1920 will prevent CLS in most cases, since all rendered videos are vertical -->
    <canvas ref="canvas" class="w-[75px] hover:w-[320px] rounded hover:rounded-lg bg-surface-bg absolute top-1/2 -translate-y-1/2 border-2 border-surface-panel-border hover:shadow-lg transition-[width,_drop-shadow,_border-radius] z-50" height="1920" width="1080" />
    <div
      v-if="!canvas?.width || !canvas?.height"
      class="w-full h-full skeleton !absolute inset-0 rounded-lg"
    />
  </div>
  <div v-else class="aspect-[9/16] w-[75px] bg-surface-bg flex justify-center items-center">
    <IconVideoOff class="w-5 text-gray-500" />
  </div>
</template>

<style scoped></style>
