
const isSafariBasedBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isFireFox = navigator.userAgent.search("Firefox") > -1;
// need to use h265, because of a bug in safari that has not released yet https://bugs.webkit.org/show_bug.cgi?id=264893
const USE_HEVC = isSafariBasedBrowser;

export function getEncoderConfig(width: number, height: number) {
    const encoderConfig = {
        codec: USE_HEVC ? "hev1.1.0.L30.b0" : "avc1.424033",
        width: width,
        height: height,
        hardwareAcceleration: 'prefer-hardware',
        avc: USE_HEVC ? undefined : { format: 'avc' },
    }


    return encoderConfig;
}



export const AudioEncoderConfig: AudioEncoderConfig = {
    codec: isFireFox ? 'opus' : "mp4a.40.2",
    sampleRate: 44100,
    numberOfChannels: 2,
    bitrate: 192000
}