import { createGlobalState } from '@vueuse/core'
import { ref, watch } from 'vue'
import type { ClipGptProjectDto } from '@/apis/streamladder-api/model'
import { useUserInfoStore } from '@/store/user/userInfo'
import { getApiClipGptProjects } from '@/apis/streamladder-api/clip-gpt-projects/clip-gpt-projects'
import { debounce } from 'lodash-es'

export const useClipGptProjects = createGlobalState(() => {

  const projects = ref<ClipGptProjectDto[]>([]);

  const userInfoStore = useUserInfoStore();
  async function fetchRecentProjects() {
    if (userInfoStore.isAuthenticated) {
      const clipGptProjects = await getApiClipGptProjects();
      projects.value = clipGptProjects.map(project => ({
        ...project,
        status: isStuck(project) ? 'failed' : project.status
      }));
    } else {
      projects.value = [];
    }
  }
  
  const debouncedFetchRecentProjects = debounce(fetchRecentProjects, 1000, { leading: true });

  const isFetching = ref(false)
  watch(() => userInfoStore.isAuthenticated, async () => {
    isFetching.value = true;
    await debouncedFetchRecentProjects();
    isFetching.value = false;
  }, { immediate: true });

  return { projects, fetchRecentProjects: debouncedFetchRecentProjects, isFetching }
})

function isStuck(project: ClipGptProjectDto) {
  if (project.status === 'failed' || project.status === 'succeeded') {
    return false;
  } else {
    return new Date().getTime() - new Date(project.updatedAt!).getTime() > 1000 * 60 * 60 * 2;
  }
}
