<template>
  <div class="relative inline-flex items-center justify-center overflow-hidden rounded-full">
    <svg class="w-full h-full" width="60" height="60" viewBox="0 0 60 60">
      <circle class="text-gray-200" stroke-width="4" stroke="currentColor" fill="transparent" r="26" cx="30" cy="30" />
      <circle
        class="progress-ring -mt-2 font-semibold text-company-primary"
        stroke-width="4"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference - (displayProgress / 100) * circumference"
        stroke-linecap="round"
        stroke="currentColor"
        fill="transparent"
        r="26"
        cx="30"
        cy="30"
      />
    </svg>
    <span class="absolute text-company-primary dark:text-white">{{ displayProgress }}%</span>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 25,
    },
  },
  computed: {
    displayProgress() {
      return Math.max(this.progress, 0).toFixed(0)
    },
  },
  data() {
    return {
      circumference: 26 * 2 * Math.PI,
    }
  },
}
</script>
<style scoped>
.progress-ring {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 49% 49%;
}
</style>
