<script setup lang="ts">
import LottieAnimation from "@/components/LottieAnimation.vue";
import { Button } from '@/components/ui/button'
import { onMounted, ref } from 'vue'
import { isBrowserSupported } from '@/areas/editor/startup/UnsupportedBrowserError'

const emit = defineEmits<{
  (name: 'continue'): void
}>();

const title = ref('Something went wrong');
const description = ref('');
const secondDescription = ref('');

onMounted(() => {
  const browserUnsupported = !isBrowserSupported();
  if (browserUnsupported) {
    title.value = 'Unsupported Browser';
    description.value = 'Please use the latest version of Chrome, Firefox or Edge for the best experience.';
    secondDescription.value = 'If you continue, you may encounter unexpected behavior or non-functional features.';
  } else {
    emit('continue');
  }
});
</script>

<template>
  <Transition
    appear
    enter-active-class="transition-[transform,_opacity] duration-300"
    leave-active-class="transition-[transform,_opacity] duration-300"
    enter-from-class="opacity-0 translate-y-4"
    enter-to-class="opacity-100 translate-y-0"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 -translate-y-4"
  >
    <div class="absolute inset-0 grid place-items-center p-8 w-full h-full" key="warning">
      <div class="flex flex-col gap-4 items-center text-center w-full max-w-[480px]">

        <LottieAnimation url="/lottie/error.json" class="w-24 h-24 mb-6" :loop="false" />

        <h2 class="text-xl font-semibold leading-snug">
          {{ title }}
        </h2>

        <p class="font-light text-pretty" :key="description">
          {{ description }}
        </p>

        <p v-if="secondDescription" class="font-light text-pretty" :key="secondDescription">
          {{ secondDescription }}
        </p>

        <div class="w-full flex flex-col items-center justify-center mt-2 gap-2">
          <Button variant="primary" @click="emit('continue')">
            I understand, continue anyway
          </Button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">

</style>
