<script setup lang="ts">
import type { StoredCaption, StoredWord } from '@/components/Captions/captionTypes'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { ref, defineAsyncComponent, computed } from 'vue'
import TrashcanIcon from '@/components/Icons/TrashcanIcon.vue'
import 'vue3-emoji-picker/css'
import logging from '@/logging'
import { useStripPunctuation } from '@/components/Captions/useStripPunctuation'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { useTheme } from '@/Hooks/useTheme'
import { useCurrentCaption } from '@/areas/editor/views/captions/useCurrentCaption'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { v4 as uuid } from 'uuid'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'

const EmojiPicker = defineAsyncComponent(() => import('vue3-emoji-picker'))

const props = defineProps<{
  caption: StoredCaption
  active?: boolean
  disabled?: boolean
}>()

const emit = defineEmits<{
  (event: 'select', selection: Selection): void
}>()

const focusedElement = defineModel<HTMLElement | null>('focus', { required: true })
const popoverOpen = defineModel<boolean>('popoverOpen', { required: true })

const videoStore = useVideoStore()
const editorCaptionsStore = useEditorCaptionsStore()
const historyStore = useHistoryStore()

async function updateWordTo(index: number, text: string) {

  const updatedWords = props.caption.words.flatMap((word, i) => {
    if (i === index && text !== placeholder) {
      const words = text.split(/(?<!\\) /)
      const wordLength = (word.end - word.start) / words.length
      return words.map((text, wordIndex) => ({
        ...word,
        id: uuid(),
        start: wordLength * wordIndex + word.start,
        end: wordLength * (wordIndex + 1) + word.start,
        text: text.replace('\\ ', '\u00A0').trim(),
        editedByUser: true,
      }))
    } else {
      return [word]
    }
  })

  historyStore.transaction('CAPTIONS:EDIT_TEXT', () => {
    editorCaptionsStore.updateCaption(props.caption.id, {
      words: updatedWords,
    })
  })
}

const placeholder = 'Type here!'
function onBlur(e: Event, wordIndex: number) {
  const target = e.target as HTMLElement
  const word = props.caption.words[wordIndex]
  if (!target.innerText || !(!word.editedByUser && target.innerText === placeholder)) {
    updateWordTo(wordIndex, target.innerText)
  }
  window.getSelection()?.removeAllRanges();
  focusedElement.value = null
  if (e.target instanceof HTMLElement) {
    e.target.contentEditable = 'false'
  }
}

function onKeyDown(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    if (!e.shiftKey) {
      e.preventDefault()
      if (e.target instanceof HTMLElement) {
        e.target.blur()
      }
    }
  }
}

function focusOn(element: HTMLElement) {
  element.contentEditable = 'true'
  element.focus();
  focusedElement.value = element
  popoverOpen.value = true
}

function onFocus(e: Event, word: StoredWord) {

  editorFocusStore.setFocus(FocusTypes.CAPTION, props.caption.id)
  videoStore._currentTime = (word.start + 0.5 * (word.end - word.start)) / 1000 + 0.01
  videoStore.playing = false

  if (e.target instanceof HTMLElement) {
    selectElementContents(e.target)
    focusOn(e.target)
  }
}

function onClick(e: Event, word: StoredWord) {

  editorFocusStore.setFocus(FocusTypes.CAPTION, props.caption.id)
  videoStore._currentTime = (word.start + 0.5 * (word.end - word.start)) / 1000 + 0.01
  videoStore.playing = false

  if (e.target instanceof HTMLElement) {
    focusOn(e.target)
  }
}

function selectElementContents(el: Element) {
  const range = document.createRange()
  range.selectNodeContents(el)
  const selection = window.getSelection()
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
    emit('select', selection)
  }
}

const deleteEmoji = () => {

  logging.trackEvent('Editor Emoji Deleted', {
    text: props.caption.text,
    emoji: props.caption.emojis?.[0],
    language: editorCaptionsStore.selectedLanguage
  });

  historyStore.transaction('CAPTIONS:DELETE_EMOJI', () => {
    editorCaptionsStore.updateCaption(props.caption.id, {
      emojis: [],
    })
  })
}

const selectEmoji = ref(false)
const onSelectEmoji = (emoji: { i: string }) => {
  selectEmoji.value = false

  const isUpdate = props.caption.emojis?.length && props.caption.emojis?.length > 0
  if (isUpdate) {
    // If the emoji is updated, track it as updated
    logging.trackEvent('Editor Emoji Updated', {
      text: props.caption.text,
      oldEmoji: props.caption.emojis[0],
      newEmoji: emoji.i,
      language: editorCaptionsStore.selectedLanguage
    })
  } else {
    // If the emoji is added, track it as added
    logging.trackEvent('Editor Emoji Added', {
      text: props.caption.text,
      emoji: emoji.i,
      language: editorCaptionsStore.selectedLanguage
    })
  }

  historyStore.transaction(isUpdate ? 'CAPTIONS:CHANGE_EMOJI' : 'CAPTIONS:ADD_EMOJI', () => {
    editorCaptionsStore.updateCaption(props.caption.id, {
      emojis: [emoji.i],
    })
  })
}

const strip = useStripPunctuation()
const emoji = computed(() => props.caption.emojis?.[0])

const { colorMode } = useTheme()

const { currentCaptionId, currentWordId } = useCurrentCaption()
const isCurrentCaption = computed(() => currentCaptionId.value === props.caption.id)
const isCurrentWord = computed(() => (word: StoredWord) => word.id === currentWordId.value)

const editorFocusStore = useEditorFocusStore()
</script>

<template>
  <div 
    class="flex flex-wrap rounded font-light leading-tight notranslate group"
    @click="editorFocusStore.setFocus(FocusTypes.CAPTION, caption.id)"
  >
    <div
      class="flex flex-wrap items-center rounded py-0 px-1.5 min-h-[1.75rem] relative before:rounded before:absolute before:inset-0 before:z-0 before:transition-[opacity,_transform] before:duration-100"
      :class="isCurrentCaption 
        ? 'before:scale-100 before:bg-zinc-200 dark:bg-zinc-50 focus-within:before:bg-transparent focus-within:before:scale-75' 
        : 'before:scale-75 hover:before:scale-100 hover:before:bg-zinc-200 dark:hover:bg-black'"
    >
      <template v-for="(word, index) in caption.words" :key="word.id">
        <template v-if="index > 0">&nbsp;</template>
        <span
          class="relative before:rounded before:absolute before:-inset-x-1 before:-inset-y-0.5 before:z-0 before:transition-[opacity,_transform] before:duration-100 focus-within:before:scale-100 after:absolute after:bottom-px after:rounded after:left-0 after:w-full after:h-0.5 after:bg-[var(--color)]"
          :class="isCurrentWord(word) 
            ? 'text-white before:scale-100 before:bg-primary group-focus-within:text-inherit group-focus-within:before:bg-transparent group-focus-within:before:scale-75' 
            : 'before:scale-75 hover:before:scale-100 hover:before:bg-zinc-400'"
          :style="`--color: ${word.color}`"
        >
          <span
            :id="'word' + '-' + word.id" :data-caption-id="caption.id" :data-word-index="index"
            class="z-10 w-full h-full block relative" contenteditable="false"
            @keydown="onKeyDown($event)" @click="onClick($event, word)"
            @focus="onFocus($event, word)" @blur="onBlur($event, index)"
          >
            {{ strip(word) || placeholder }}
          </span>
        </span>
      </template>
      <Popover v-if="emoji" :key="emoji">
        <PopoverTrigger as-child>
          <Button variant="ghost" class="w-7 h-7 relative z-10" :data-caption-id="caption.id" :data-emoji="emoji">
            <span class="text-xl">{{ emoji }}</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent class="bg-none flex flex-col items-center gap-0.5 layer-2">
          <EmojiPicker class="!bg-none !bg-transparent !shadow-none !m-0 !transition-all [&_*]:!transition-all" :native="true" @select="onSelectEmoji" :theme="colorMode" />
          <Button variant="ghostDestructive" @click="deleteEmoji">
            <TrashcanIcon class="fill-current w-4 h-4" /> Delete emoji
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  </div>
</template>

<style></style>
