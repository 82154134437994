<script setup lang="ts">
import { useUserInfoStore } from '@/store/user/userInfo'
import AccountSettingsPartial from '@/areas/dashboard/pages/account/partial/AccountSettingsPartial.vue'
import SocialMediaConnectionItem from '@/areas/dashboard/pages/account/partial/SocialMediaConnectionItem.vue'
import { computed, ref } from 'vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import ConnectSocialDialog from '@/areas/dashboard/components/ConnectSocialDialog.vue'
import { useHead } from '@unhead/vue'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'

const userInfo = useUserInfoStore()

const connections = computed(() => userInfo.allSocials)

useHead({
  title: 'Account Settings',
  meta: [
    {
      name: 'description',
      content: 'Manage your account settings.',
    },
  ],
})

const isConnectSocialDialogOpen = ref(false)
</script>

<template>
  <main class="flex flex-col gap-8 lg:gap-16">
    <header class="flex flex-col gap-4">
      <h1 class="text-3xl lg:text-5xl">Account Settings</h1>
      <AccountSettingsPartial />
    </header>

    <section class="flex flex-col gap-4">
      <header class="flex flex-wrap justify-between gap-2">
        <h2 class="text-2xl font-semibold md:text-3xl">Social media connections</h2>

        <GradientButton @click="isConnectSocialDialogOpen = true">
          <DynamicPlanButtonWithTooltip feature="multiple-publishing-accounts" />
          Connect new account
          <IconSaxAdd />
        </GradientButton>
      </header>

      <NoContentSection
        v-if="connections.length === 0"
        title="No social media accounts connected"
        subtitle="You can schedule and post clips by connecting an account"
      >
        <GradientButton @click="isConnectSocialDialogOpen = true">
          Connect your socials
          <IconSaxAdd />
        </GradientButton>
      </NoContentSection>

      <ol class="flex flex-col gap-3" v-else>
        <li v-for="connection of connections" :key="connection.id">
          <SocialMediaConnectionItem :account="connection" />
        </li>
      </ol>
    </section>

    <ConnectSocialDialog v-model="isConnectSocialDialogOpen" />
  </main>
</template>

<style scoped lang="scss"></style>
