import json from '@/assets/caption-presets.json';
import type { CaptionPreset } from '@/components/Captions/v3/CaptionPreset'

const captionPresets = {} as Record<keyof typeof json, CaptionPreset>;

for (const key of Object.keys(json) as (keyof typeof json)[]) {
  captionPresets[key] = {
    key: key,
    createdAt: new Date(json[key].createdAt),
    ...json[key] as unknown as Omit<CaptionPreset, 'key' | 'createdAt'>,
  }
}

export { captionPresets }
