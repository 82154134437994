<script setup lang="ts">
import { Button } from '@/components/ui/button'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { onMounted, ref } from 'vue'
import * as Sentry from '@sentry/browser'
import { accountsAxios } from '@/services/axios'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import logging from '@/logging'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import Spinner from '@/components/Icons/Spinner.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import {
  putApiSubscriptionIdCancel,
  putApiSubscriptionIdPause
} from '@/apis/streamladder-accounts/subscription/subscription'
import LottieAnimation from '@/components/LottieAnimation.vue'
import settings from "@/data/settings";

const subscriptionStore = useUserSubscriptionStore();
const userInfoStore = useUserInfoStore();

const processingStatus = ref<'notProcessing' | 'processing' | 'success'| 'error'>('notProcessing')
const errorMessage = ref('')
const defaultErrorMessage = "Can't cancel your subscription! Please contact our support!"

onMounted(async () => {
  await cancelSubscription()
})

const cancelSubscription = async () => {

  processingStatus.value = 'processing'

  try {

    if (!subscriptionStore.subscription || !subscriptionStore.subscription?.id) {
      console.error('Error getting subscription info');
      Sentry.captureException(new Error('Can\'t cancel account: Error getting subscription info'));
      EventBus.$emit(mainEvents.ERROR, "Can't cancel your subscription! Please contact our support!");
      processingStatus.value = 'error';
      errorMessage.value = defaultErrorMessage;
      return;
    }

    if (subscriptionStore.subscription?.type === 'paddle') {
      // For Paddle we need to pause the subscription, not cancel it
      const pauseResponse = await putApiSubscriptionIdPause(subscriptionStore.subscription.id);

      if (!pauseResponse.isSuccess) {
        console.error('Error pausing subscription', JSON.stringify(pauseResponse));
        Sentry.captureException(new Error('Can\'t cancel account: ' + pauseResponse.error?.errorMessage));
        const message = pauseResponse.error?.errorCode === 502
            ? pauseResponse?.error?.errorMessage ?? defaultErrorMessage
            : defaultErrorMessage;
        EventBus.$emit(mainEvents.ERROR, message);
        processingStatus.value = 'error';
        errorMessage.value = message;
      } else {
        processingStatus.value = 'success';
        logging.trackEvent('Subscription Cancelled Frontend');
      }

    } else if (subscriptionStore.subscription?.type === 'paypal') {

      const cancelResponse = await putApiSubscriptionIdCancel(subscriptionStore.subscription.id, { reason: '', });

      if (!cancelResponse.isSuccess) {
        console.error('Error pausing subscription', JSON.stringify(cancelResponse));
        Sentry.captureException(new Error('Can\'t cancel account: ' + cancelResponse.error?.errorMessage));
        const message = cancelResponse.error?.errorCode === 502
            ? cancelResponse?.error?.errorMessage ?? defaultErrorMessage
            : defaultErrorMessage;
        EventBus.$emit(mainEvents.ERROR, message);
        processingStatus.value = 'error';
        errorMessage.value = message;
      } else {
        await subscriptionStore.fetchSubscriptionData(true);
        await userInfoStore.updateUserInfo();
        processingStatus.value = 'success';
        logging.trackEvent('Subscription Cancelled Frontend');
      }
    } else {
      processingStatus.value = 'error';
      errorMessage.value = defaultErrorMessage;
      console.error('Invalid subscription', JSON.stringify(subscriptionStore.subscription));
      Sentry.captureException(new Error('Can\'t cancel account: Invalid subscription'));
      EventBus.$emit(mainEvents.ERROR, "Can't cancel your subscription! Please contact our support!");
    }
  } catch (e) {

    processingStatus.value = 'error';
    errorMessage.value = 'We experienced a problem connecting to our servers, please try again or contact our support!'
    Sentry.captureException(e);
    EventBus.$emit(mainEvents.ERROR, "We couldn't cancel your subscription! Please contact our support!")

    return
  }
};
</script>

<template>
  <div class="flex flex-col gap-8 items-center text-center h-full">

    <div class="flex h-[500px]">
      <div class="w-full sm:w-2/3 p-4 gap-4 flex flex-col items-center justify-center" :class="{ 'sm:w-full': processingStatus === 'error' }">
        <h3 class="text-2xl sm:text-3xl lg:text-4xl">
          {{
            processingStatus == 'processing' ? "Subscription canceling.."
                : processingStatus == 'success' ? "Subscription canceled." : "Something went wrong"
          }}
        </h3>
        <p v-if="processingStatus == 'success'" class="font-light">
          Your subscription has been canceled. You won’t be billed again.
        </p>
        <p v-else-if="processingStatus == 'error'" class="font-light">
          <span style="white-space: pre-wrap;">{{errorMessage}}</span>
        </p>
        <div class="flex items-center justify-center w-full h-48">
          <Spinner
            v-if="processingStatus == 'processing'"
            class="h-32 w-32 animate-spin" />
          <IconSaxTickCircle
            v-if="processingStatus == 'success'"
            class="h-32 w-32 rounded-full fill-green-600 text-white dark:text-black"
          />
          <LottieAnimation
            v-if="processingStatus == 'error'"
            class="h-64 w-64 absolute inset-auto"
            url="/lottie/error-cross.json"
            :loop="false"
            :autoPlay="true"
          />
        </div>
        <p v-if="processingStatus == 'error'" class="font-light">
          Need help? You can contact our support team through our
          <a :href="settings.discordInviteUrl" target="_blank" class="text-brand-state-link underline underline-offset-2 hover:underline-offset-4 transition-all"> Discord server </a>
        </p>
        <Button variant="primary" as="a" href="/account/invoicing" :disabled="processingStatus == 'processing'">
          Go to my account
          <IconSaxArrowRight class="w-4 h-4" />
        </Button>
      </div>
      <div v-if="processingStatus != 'error'" class="p-8 w-full sm:w-1/3 h-full bg-[#F0F0F3] dark:bg-zinc-400 hidden sm:flex flex-col items-center justify-center gap-8">
        <p class="font-light tracking-wide leading-8 story">
          Thank you for your support at StreamLadder! We're committed to improving what we build and hope to see you again in the future. Take care and happy streaming!
        </p>
        <p class="autograph text-4xl font-extralight">
          Lolke
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@font-face {
  font-family: autograph;
  src: url(/fonts/Dancing_Script/DancingScript-Regular.ttf);
}

.autograph {
  font-family: autograph, serif;
  cursor: default;
}

@font-face {
  font-family: story;
  src: url(/fonts/Aboreto/Aboreto-Regular.ttf);
}

.story {
  font-family: story, serif;
}
</style>