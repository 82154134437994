<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue'
import Tile from '@/components/Tile.vue'
import { Switch } from '@/components/ui/switch'
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import { useUserInfoStore, onUserInfoReady } from '@/store/user/userInfo'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { Input } from '@/components/ui/input'
import { IconAdjustmentsHorizontal, IconArrowRight } from '@tabler/icons-vue'
import { Dialog, DialogClose, DialogTrigger, DialogContent } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import type { TypedSticker } from "@/areas/editor/@type/Project";
import { useStickersStore } from "@/areas/editor/store/useStickersStore";
import { useHistoryStore } from "@/areas/editor/store/useHistoryStore";
import logging from "@/logging";
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { DecodeImage, DefaultRiveUrl, getRiveStickersSelection, RiveDefinitions } from '@/webcodec-renderer/rive/rive-definitions'
import { RiveRenderer } from '@/webcodec-renderer/worker/rive-renderer'
import { useIntervalFn } from '@vueuse/core'

defineProps<{
  hideBackButton?: boolean
}>();

const stickerSelection = getRiveStickersSelection();

const stickers = stickerSelection.map(({artboard}) => ({ 
    area: { x: 0, y: 0, width: 0.7873, height: 0.137 },
    startMs: 0, endMs: 100e6, z: 0, editing: false, scale: 1, id: '123', key: '321',
    textContent: 'StreamLadder', 
    animation: '',
    artboard,
    stateMachineInputs: [],
    icon: '1',
}))

const selectedFilter = ref()

const seletedStickerKey = ref('');

const userInfoStore = useUserInfoStore()
const stickerText = ref()
const stickerCanvasElements = ref<HTMLCanvasElement[]>()

const clipInfoStore = useEditorClipInfoStore()
watch(() => clipInfoStore.source, (src) => {
  selectedFilter.value = selectFilterByClipSource(src as 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'local-file')
}, { immediate: true })

function selectFilterByClipSource(source: 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'local-file') {
  switch (source) {
    case 'youtube-clip':
      return 'youtube'
    case 'kick-clip':
      return 'kick'
    case 'twitch-clip':
    case 'twitch-vod':
    default:
      return 'twitch'
  }
}

const stickersStore = useStickersStore()
const historyStore = useHistoryStore()
const editorFocusStore = useEditorFocusStore()


onUserInfoReady(() => {
  if (!stickerText.value) {
    stickerText.value = userInfoStore.userName || 'StreamLadder'
    for (const sticker of stickers) {
      sticker.textContent = stickerText.value;
    }
  }
})

const { currentSection } = useEditorStep()

const stateMachineInputs = window.stateMachineInputs ?? []

const firstEntity = computed(() => stickersStore.entities[0]);
const riveStickers = stickersStore.where<TypedSticker<'rive'>>((s) => s.type === 'rive')
function inputChange() {

  for (const { id } of riveStickers.value) {
    const sticker = stickersStore.selectById<TypedSticker<'rive'>>(id);
    if (editorFocusStore.focus?.key === sticker.id) {
      sticker.textContent = stickerText.value;
    }
  }

  for (const sticker of stickers) {
    sticker.textContent = stickerText.value;
  }
}


const renderers: RiveRenderer[] = [];


onMounted(async () => {
  for (const canvas of stickerCanvasElements.value ?? []) {
      const ctx = canvas.getContext('2d')!;
      const riveRenderer = new RiveRenderer(canvas, ctx, DefaultRiveUrl, DecodeImage);

      await riveRenderer.loadedPromise;

      renderers.push(riveRenderer);
  }
})

let timeStamp = 0;
useIntervalFn(() => {
    let ix = 0;
    for (const renderer of renderers ?? []) {
        renderer.render(stickers[ix], timeStamp);

        renderer.ctx.clearRect(0, 0, renderer.canvas.width, renderer.canvas.height);

        const def = Object.entries(RiveDefinitions).find(([key]) => key === renderer.artboard)?.[1];
        const aspectRatio = renderer.riveCanvas.height / renderer.riveCanvas.width;
        renderer.ctx.drawImage(renderer.riveCanvas, 0, 0, renderer.riveCanvas.width, renderer.riveCanvas.height, 0, def?.previewOffset ?? 60, renderer.canvas.width, renderer.canvas.width * aspectRatio)

        ix++;
    }

    timeStamp += 1000/30 * 1e3;

    timeStamp %= 3e6;
}, 1000/30)

async function addRiveSticker(artboard: string) {
    const id = stickersStore.createRiveSticker({ 
        artboard,
        textContent: stickerText.value,
        key: artboard,
        naturalWidth: .75 * 100,
        naturalHeight: .25 * 100,
    })

    editorFocusStore.setFocus(FocusTypes.RIVE, id)
}

</script>

<template>
  <section class="flex flex-col gap-4 w-full h-full p-4 pb-0 2xl:p-8 max-h-screen">
    <div v-if="!hideBackButton" class="flex items-center gap-2 cursor-pointer hover:-translate-x-0.5 transition-transform text-brand-state-link hover:underline underline-offset-2" @click="currentSection = null">
      <IconSaxArrowLeft class="w-4 h-4" />
      <span class="text-xl lg:text-sm mt-0.5 select-none">Back to Elements</span>
    </div>

    <Input :key="seletedStickerKey" :autoFocus="true" class="min-w-0" type="text" ref="stickerTextInp" v-model="stickerText" @change="inputChange()" />


    <!-- <div class="flex flex-col gap-2">
      <div v-for="input, ix in stateMachineInputs" v-bind:key="input.name" class="flex flex-col">
        <span>{{ input.name }}</span>
        <input type="number" :value="input.value" @change="setValue(ix, Number($event.target?.value))" />
      </div>
      <div class="flex flex-col" v-if="firstEntity">
        <span>Animation</span>
        <div class="flex flex-row flex-wrap gap-1">
          <RadioToggleButton
            v-for="option in [
              { label: 'None', value: '' },
              { label: 'Reveal', value: 'reveal' },
              { label: 'Fade', value: 'fade-in' },
              { label: 'Slide Right', value: 'slide-right' },
              { label: 'Slide Top', value: 'slide-top' },
              { label: 'Shrink', value: 'shrink' },
              { label: 'Bounce', value: 'bounce' },
            ]"
            :key="option.value" :value="option.value"
            v-model="firstEntity.animation"
            @click="firstEntity.animation = option.value"
            class="flex-col relative font-light data-[state=active]:font-semibold flex-auto shrink-0 p-2"
          >
            <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
            <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
          </RadioToggleButton>
        </div>
      </div>
    </div> -->


    <ScrollArea class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8">
      <div class="grid w-full gap-2 notranslate grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))]">
        <Dialog v-if="selectedFilter === 'multiple'">
          <DialogTrigger>
            <Tile :aspect=".6" class="text-current active:scale-90 transition-[transform,_border-color,_background-color]">
              <IconAdjustmentsHorizontal />
              <span>Edit socials</span>
            </Tile>
          </DialogTrigger>
          <DialogContent>
            <h4>Manage your social icons</h4>
            <p class="text-opacity-50">Select which social platforms you wish to display in your sticker</p>
            <div class="mt-4 flex flex-col gap-2">
              <label class="flex items-center gap-2 cursor-pointer" v-for="social in socials" :key="social.key" :for="social.key">
                <Switch :id="social.key" v-model:checked="social.selected" />
                <span class="text-lg">{{ social.label }}</span>
              </label>
            </div>

            <footer class="flex items-center justify-between">
              <DialogClose>
                <Button variant="depressed">
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose>
                <Button variant="gradient">
                  Save <IconArrowRight class="transition group-hover:translate-x-2" />
                </Button>
              </DialogClose>
            </footer>
          </DialogContent>
        </Dialog>
      </div>
      <div class="grid w-full gap-2 notranslate grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))] relative">
          <Tile
            @click="historyStore.transaction('STICKER:ADD', () => addRiveSticker(definition.artboard))"
            v-for="definition in stickerSelection" :key="definition.artboard" 
            :aspect="0.6"
            class="active:scale-90 transition-[transform,_border-color,_background-color] dark bg-checkerboard"
            :class="{
            // '!border-brand-state-active-border': focused,
            }"
            feature="animated-social-stickers"
        >
            <canvas style="width: 230px; height: auto; padding-right: 12px; padding-left: 12px; max-width: 100%;" ref="stickerCanvasElements" width="420" :height="253"></canvas>
        </Tile>
      </div>
    </ScrollArea>
  </section>
</template>

<style scoped lang="scss">

</style>
