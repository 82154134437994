<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { ColorInput } from '@/components/colors'
import IconSaxSun1 from '@/components/Icons/iconsax/IconSaxSun1.vue'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { NumberField, NumberFieldContent, NumberFieldInput } from '@/components/ui/number-field'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Slider } from '@/components/ui/slider'
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { useFontsStore } from '@/store/fonts'
import { RadioGroupItem, RadioGroupRoot } from 'radix-vue'

const accordion = ref<string[]>(['text']);

const captionsStore = useCaptionsStore();

const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors();

const historyStore = useHistoryStore();
const fontsStore = useFontsStore();
const isLoadingFonts = ref(true);

onMounted(async () => {
  await fontsStore.initialize();
  isLoadingFonts.value = false;
});

const fontColorModel = computed<string>({
  get() {
    const color = captionsStore.baseCaptionPreset.font.color;
    if (typeof color === 'string') {
      return color;
    } else {
      return color.colorStops[0].color;
    }
  },
  set(color: string) {
    captionsStore.baseCaptionPreset.font.color = color;
  }
});

const strokeWidthModel = computed({
  get() {
    return [captionsStore.baseCaptionPreset.stroke.width];
  },
  set(value: number[]) {
    captionsStore.baseCaptionPreset.stroke.width = value[0];
  }
});

const backgroundColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.background.color;
  },
  set(color: string) {
    captionsStore.baseCaptionPreset.background.color = color;
  }
})

const backgroundOpacityModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.background.opacity * 100;
  },
  set(value: number) {
    captionsStore.baseCaptionPreset.background.opacity = value / 100;
  }
});

const backgroundOpacitySliderModel = computed({
  get() {
    return [backgroundOpacityModel.value];
  },
  set(value: number[]) {
    backgroundOpacityModel.value = value[0];
  }
});

const shadowColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.color;
  },
  set(value: string) {
    captionsStore.baseCaptionPreset.shadow.color = value;
  }
});

const shadowBlurModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.blur;
  },
  set(value: number) {
    captionsStore.baseCaptionPreset.shadow.blur = value;
  }
});

const shadowBlurSliderModel = computed({
  get() {
    return [shadowBlurModel.value];
  },
  set(value: number[]) {
    shadowBlurModel.value = value[0];
  }
});

const glowColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.glow.color;
  },
  set(value: string) {
    captionsStore.baseCaptionPreset.glow.color = value;
  }
});

const glowRadiusModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.glow.radius;
  },
  set(value: number) {
    captionsStore.baseCaptionPreset.glow.radius = value;
  }
});

const glowRadiusSliderModel = computed({
  get() {
    return [glowRadiusModel.value];
  },
  set(value: number[]) {
    glowRadiusModel.value = value[0];
  }
});

const fontSizeModel = computed({
  get() {
    return captionsStore.baseOptions.size;
  },
  set(size: 'small' | 'medium' | 'large') {
    captionsStore.baseOptions.size = size;
  }
});

const strokeColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.stroke.color;
  },
  set(color: string) {
    captionsStore.baseCaptionPreset.stroke.color = color;
  }
});
</script>

<template>

  <div class="my-1 h-px bg-surface-panel-border" />

  <section class="p-4" v-if="captionsStore.baseCaptionPreset.font.editable">

    <div class="flex flex-col gap-2 py-2">

      <header class="mb-2">
        <span class="text-base font-semibold">Font size</span>
      </header>

      <RadioGroupRoot
        v-model="fontSizeModel"
        default-value="medium"
        aria-label="View density"
      >
        <div class="grid grid-cols-3 gap-2.5">
          <RadioGroupItem
            id="small"
            value="small"
          >
            <div
              class="h-14 flex items-center justify-center overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
              :class="{ '!bg-primary text-white cursor-default': captionsStore.baseOptions.size === 'small' }"
            >
              <span class="font-semibold">Small</span>
            </div>
          </RadioGroupItem>
          <RadioGroupItem
            id="medium"
            value="medium"
          >
            <div
              class="h-14 flex items-center justify-center overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
              :class="{ '!bg-primary text-white cursor-default': captionsStore.baseOptions.size === 'medium' }"
            >
              <div class="font-semibold">Medium</div>
            </div>
          </RadioGroupItem>
          <RadioGroupItem
            id="large"
            value="large"
          >
            <div
              class="h-14 flex items-center justify-center overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
              :class="{ '!bg-primary text-white cursor-default': captionsStore.baseOptions.size === 'large' }"
            >
              <div class="font-semibold">Large</div>
            </div>
          </RadioGroupItem>
        </div>
      </RadioGroupRoot>
    </div>
  </section>

  <div class="my-1 h-px bg-surface-panel-border" />

  <Accordion collapsible v-model="accordion" class="w-full">
    <AccordionItem value="text" class="w-full">
      <AccordionTrigger class="px-4 pointer-events-none">
        <span class="text-base font-semibold">Style</span>
        <template #icon><div /></template>
      </AccordionTrigger>
      <AccordionContent class="px-4">
        <div v-if="isLoadingFonts" class="flex flex-col gap-2 py-2">
          Loading..
        </div>
        <Transition
          enter-from-class="opacity-0 -translate-y-4"
          leave-to-class="opacity-0 -translate-y-4"
          enter-active-class="motion-safe:transition-[transform,_opacity]"
          leave-active-class="motion-safe:transition-[transform,_opacity]"
        >
          <div v-if="!isLoadingFonts" class="flex flex-col gap-2 py-2">
            <Select v-model="captionsStore.baseCaptionPreset.font.fontFamily">
              <SelectTrigger class="hover:bg-brand-state-hover-bg hover:border-brand-state-hover-border">
                <SelectValue placeholder="Choose a font" :style="{ fontFamily: captionsStore.baseCaptionPreset.font.fontFamily }" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="font in fontsStore.fonts" :key="font.url" :value="font.label" :style="{ fontFamily: font.label }">
                  {{ font.label }}
                </SelectItem>
              </SelectContent>
            </Select>
            <ColorInput
              v-model="fontColorModel"
              class="shrink-0"
              side="right"
              @commit-value="historyStore.transaction('CAPTIONS:CHANGE_COLOR')"
              @close="setRecentlyUsedColor(fontColorModel)"
              :recently-used-colors="recentlyUsedCaptionColors"
              :recommended-colors="recommendedColors.LIGHT"
            />
          </div>
        </Transition>
      </AccordionContent>
    </AccordionItem>

    <section class="p-4" v-if="captionsStore.baseCaptionPreset.stroke.editable">
      <header class="my-2">
        <span class="text-base font-semibold">Stroke</span>
      </header>

      <div class="flex flex-col gap-2 py-2">
        <ColorInput
          v-model="strokeColorModel"
          side="right"
          @commit-value="historyStore.transaction('CAPTIONS:CHANGE_STROKE_COLOR')"
          @close="setRecentlyUsedColor(strokeColorModel)"
          :recently-used-colors="recentlyUsedCaptionColors"
          :recommended-colors="recommendedColors.DARK"
        />
        <div class="flex gap-2">
          <Slider v-model="strokeWidthModel" :min="0" :max="10" :step="1" @value-commit="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')" />
          <NumberField :min="0" :max="10" v-model="captionsStore.baseCaptionPreset.stroke.width" @change="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')" class="w-20">
            <NumberFieldContent>
              <NumberFieldInput class="pr-5" @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')" @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')" />
              <span class="text-muted-foreground p-3 top-1/2 -translate-y-1/2 right-0 absolute">px</span>
            </NumberFieldContent>
          </NumberField>
        </div>

      </div>
    </section>

    <div v-if="captionsStore.baseCaptionPreset.glow.editable" class="my-1 h-px bg-surface-panel-border" />

    <section class="p-4" v-if="captionsStore.baseCaptionPreset.glow.editable">
      <header class="my-2">
        <span class="text-base font-semibold">Glow</span>
      </header>

      <div class="flex flex-col gap-2 py-2">
        <ColorInput
          v-model="glowColorModel"
          side="right"
          @commit-value="historyStore.transaction('CAPTIONS:CHANGE_GLOW_COLOR')"
          @close="setRecentlyUsedColor(glowColorModel)"
          :recently-used-colors="recentlyUsedCaptionColors"
          :recommended-colors="recommendedColors.LIGHT"
        />
        <div class="flex gap-2">
          <Slider v-model="glowRadiusSliderModel" :min="0" :max="10" :step="1" @value-commit="historyStore.transaction('CAPTIONS:CHANGE_GLOW_RADIUS')" />
          <NumberField :min="0" :max="10" v-model="glowRadiusModel" @change="historyStore.transaction('CAPTIONS:CHANGE_GLOW_RADIUS')" class="w-20">
            <NumberFieldContent>
              <NumberFieldInput class="pl-5" @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_GLOW_RADIUS')" @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_GLOW_RADIUS')" />
              <div class="text-muted-foreground p-3 top-1/2 -translate-y-1/2 left-0 absolute">
                <IconSaxSun1 class="w-4 h-4" />
              </div>
            </NumberFieldContent>
          </NumberField>
        </div>
      </div>
    </section>

    <div v-if="captionsStore.baseCaptionPreset.shadow.editable" class="my-1 h-px bg-surface-panel-border" />

    <section class="p-4" v-if="captionsStore.baseCaptionPreset.shadow.editable">

      <header class="my-2">
        <span class="text-base font-semibold">Shadow</span>
      </header>

      <div class="flex flex-col gap-2 py-2">
        <ColorInput
          v-model="shadowColorModel"
          side="right"
          @commit-value="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_COLOR')"
          @close="setRecentlyUsedColor(shadowColorModel)"
          :recently-used-colors="recentlyUsedCaptionColors"
          :recommended-colors="recommendedColors.LIGHT"
        />
        <div class="flex gap-2">
          <Slider v-model="shadowBlurSliderModel" :min="0" :max="10" :step="1" @value-commit="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')" />
          <NumberField :min="0" :max="10" v-model="shadowBlurModel" @change="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')" class="w-20">
            <NumberFieldContent>
              <NumberFieldInput class="pl-5" @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')" @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')" />
              <div class="text-muted-foreground p-3 top-1/2 -translate-y-1/2 left-0 absolute">
                <IconSaxSun1 class="w-4 h-4" />
              </div>
            </NumberFieldContent>
          </NumberField>
        </div>
      </div>
    </section>

    <div v-if="captionsStore.baseCaptionPreset.background.editable" class="my-1 h-px bg-surface-panel-border" />

    <section class="p-4" v-if="captionsStore.baseCaptionPreset.background.editable">

      <header class="my-2">
        <span class="text-base font-semibold">Background</span>
      </header>

      <div class="flex flex-col gap-2 py-2">
        <ColorInput
          v-model="backgroundColorModel"
          side="right"
          @commit-value="historyStore.transaction('CAPTIONS:CHANGE_BACKGROUND_COLOR')"
          @close="setRecentlyUsedColor(backgroundColorModel)"
          :recently-used-colors="recentlyUsedCaptionColors"
          :recommended-colors="recommendedColors.DARK"
        />
        <div class="flex gap-2">
          <Slider v-model="backgroundOpacitySliderModel" :min="0" :max="100" :step="1" @value-commit="historyStore.transaction('CAPTIONS:CHANGE_BACKGROUND_OPACITY')" />
          <NumberField :min="0" :max="100" v-model="backgroundOpacityModel" @change="historyStore.transaction('CAPTIONS:CHANGE_BACKGROUND_OPACITY')" class="w-20">
            <NumberFieldContent>
              <NumberFieldInput class="pr-5" @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_BACKGROUND_OPACITY')" @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_BACKGROUND_OPACITY')" />
              <span class="text-muted-foreground p-3 top-1/2 -translate-y-1/2 right-0 absolute">%</span>
            </NumberFieldContent>
          </NumberField>
        </div>
      </div>
    </section>
  </Accordion>
</template>

<style scoped lang="scss">

</style>
