<script lang="ts" setup>
import ResultVideosList from '@/areas/dashboard/components/ResultVideos/ResultVideosList.vue'
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import MultiUploadDialog from '@/components/Dialog/MultiUploadDialog/MultiUploadDialog.vue'
import UploadIcon from '@/components/Icons/UploadIcon.vue'
import { Button } from '@/components/ui/button'
import { videosFilter, videosFilterOptions } from '@/Hooks/useFilterVideos'
import { useHead } from '@unhead/vue'
import { ref } from 'vue'

useHead({
  title: 'My Videos',
  meta: [
    {
      name: 'description',
      content: 'Check out the clips you created in the last 7 days.',
    },
  ],
})

const hasVideos = ref(false)
</script>

<template>
  <WithUpgradeOverlay
    class="max-h-[calc(100vh-3.1rem)] overflow-hidden rounded-2xl"
    feature="save-video"
    subtitle="Save your clips to your device and upload them to your social media accounts."
    title="Save your clips"
  >
    <main class="flex flex-col gap-4 p-4 lg:p-12">
      <div class="flex flex-row flex-wrap items-end justify-between gap-2">
        <header class="flex flex-col">
          <h1 class="text-3xl lg:text-5xl">My Videos</h1>
          <p class="font-light text-opacity-50">Below are the videos you created in the last 7 days.</p>
        </header>

        <div v-if="hasVideos" class="flex flex-row gap-2">
          <MultiUploadDialog upload-in-background storage-variant="medium" :auto-close-dialog-in-ms="2000">
            <Button variant="outline" class="rounded-xl h-full">
              Upload
              <UploadIcon class="w-5" />
            </Button>
          </MultiUploadDialog>

          <label class="flex items-center gap-2">
            <SelectDropdown v-model="videosFilter" :options="videosFilterOptions" class="ml-auto" />
          </label>
        </div>
      </div>

      <ResultVideosList @hasVideos="(e) => hasVideos = e" />
    </main>
  </WithUpgradeOverlay>
</template>

<style lang="scss" scoped></style>
