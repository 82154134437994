import { mbToBytes } from '@/helpers/fileSize'
// @ts-ignore
import compatabilityChecker from '@/services/compatabilityChecker'
import { selectFileSizeLimitForTier, useUserInfoStore } from "@/store/user/userInfo";
import { canGuard } from '@/Hooks/useGuard'
import type { Feature } from '@/data/features'
import { metadataService } from '@/services/metadataService'
import logging from '@/logging'
import { tiers } from "@/enums/tiers";

const validFileTypes = [
  { mimeType: 'video/mp4', extension: 'mp4' },
  { mimeType: 'video/quicktime', extension: 'mov' }
]

const validMimeTypes = validFileTypes.map(t => t.mimeType)
const validExtensions = validFileTypes.map(t => t.extension)

export function useLocalFileValidator() {

  const userInfoStore = useUserInfoStore()

  return async function validateLocalFile(file: File) {

    const result = {
      error: null as { message: string; title: string } | null,
      guard: null as Feature | null,
      requiresAuth: false
    }

    if (!file) {
      logging.trackEvent('Local file error: No file selected', {})
      result.error = {
        title: 'No file selected',
        message: 'Please select a file to upload',
      }
      return result
    }

    const fileExtension = file.name.split('.').pop()!
    if (!validMimeTypes.includes(file.type) && !validExtensions.includes(fileExtension)) {

      logging.trackEvent('Local file error: Invalid file type', {
        fileExtension: fileExtension,
        fileType: file.type,
        fileSize: file.size,
      })

      result.error = {
        title: `Invalid file type '.${fileExtension}' (${fileExtension})`,
        message: 'Please select a video file (*.mov or *.mp4) to upload',
      }

      return result
    }

    await metadataService.canPlayFile(file)

    // Would give false negatives in iOS Safari. Disabled for now, since it used to work fine without it.
    // if (!canPlay) {
    //
    //   logging.trackEvent('Local file error: Corrupt video file', {
    //     fileExtension: fileExtension,
    //     fileType: file.type,
    //     fileSize: file.size,
    //   })
    //
    //   result.error = {
    //     title: 'Corrupt video file',
    //     message: 'The file you selected could not be played. Please select a different file.',
    //   }
    //
    //   return result
    // }

    if (file.size > userInfoStore.fileSizeLimit) {
      if (!userInfoStore.isLoggedIn) {
        result.requiresAuth = true
        result.guard = 'upload-large-files'
        return result
      } else if (!canGuard('upload-large-files')) {
        result.guard = 'upload-large-files'
        return result
      } else {

        logging.trackEvent('Local file error: File too large', {
          fileExtension: fileExtension,
          fileType: file.type,
          fileSize: file.size,
        })

        result.error = {
          title: 'File too large',
          message: 'Please select a file smaller than 1GB',
        }

        return result
      }
    }

    if (!userInfoStore.isLoggedIn) {
      result.requiresAuth = true
      return result
    }

    return result
  }
}

function deviceSupportsFFMPEG() {
  return compatabilityChecker.canRenderClientside()
}
