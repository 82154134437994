<script setup lang="ts">
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import CaptionNodeV2 from '@/areas/editor/timeline/tracks/captions/CaptionNodeV2.vue'
import CaptionNodeV2AddNode from '@/areas/editor/timeline/tracks/captions/CaptionNodeV2AddNode.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'
import IconSaxSubtitle from '@/components/Icons/iconsax/IconSaxSubtitle.vue'

const captionsStore = useCaptionsStore();

const { currentStep } = useEditorStep();

const editCaptions = () => {
  currentStep.value = editorRouteNames.captions;
};

const captions = computed(() => {
  return captionsStore.entities;
});
</script>

<template>
  <TimeLineTrack class="relative h-full max-h-12 w-full" v-if="captions.length">
    <template v-if="currentStep === editorRouteNames.captions">
      <CaptionNodeV2
        v-for="(caption, captionIndex) in captions"
        :caption="caption"
        :captionIndex="captionIndex"
        :key="caption.id"
      />
      <CaptionNodeV2AddNode />
    </template>
    <template v-else>
      <div @click="editCaptions" class="flex items-center justify-center h-full w-full bg-company-primary-50 rounded-md hover:cursor-pointer hover:bg-company-primary-200 transition-colors">
        <span class="text-sm text-white flex gap-2 items-center justify-center">
          <IconSaxSubtitle class="w-5 h-5" />
          <span class="mt-0.5 font-light">Edit Captions</span>
        </span>
      </div>
    </template>
  </TimeLineTrack>
</template>
