<script lang="ts" setup>
import { type FocusType, FocusTypes } from '@/store/editor/editorFocus'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import StickerNode from '@/areas/editor/timeline/tracks/stickers/StickerNode.vue'
import { computed } from 'vue'
import type { SocialSticker, TextSticker } from '@/areas/editor/@type/Project'
import { v5 as uuidv5 } from 'uuid'

type TrackTextSticker = TextSticker & { focusType: FocusType, trackId?: string };
type TrackSocialSticker = SocialSticker & { focusType: FocusType, trackId?: string };
type TrackSticker = TrackTextSticker | TrackSocialSticker;

type Track = {
  id: string;
  stickers: TrackSticker[];
};

const stickersStore = useStickersStore()
const textStickers = stickersStore.whereFocusTypeIs(FocusTypes.TEXTSTICKER)
const socialStickers = stickersStore.whereFocusTypeIs(FocusTypes.STICKER)

const stickers = computed(() => [
  ...textStickers.value.map(sticker => ({ ...sticker, focusType: FocusTypes.TEXTSTICKER } as TrackTextSticker)),
  ...socialStickers.value.map(sticker => ({ ...sticker, focusType: sticker.type === 'rive' ? FocusTypes.RIVE : FocusTypes.STICKER } as TrackSocialSticker)),
]);

const isOverlapping = (stickerA: TrackSticker, stickerB: TrackSticker) => {
  if (stickerA.id === stickerB.id) {
    return false;
  } else {
    const overlapStart = Math.max(stickerA.startMs, stickerB.startMs);
    const overlapEnd = Math.min(stickerA.endMs, stickerB.endMs);
    const overlapDuration = overlapEnd - overlapStart;
    return overlapDuration > 5;
  }
};

const namespace = '927f8367-3a2d-40e2-9ecb-78bdb2cd0ab3';
const timeLineTracks = computed(() => {

  const tracks: Track[] = [];

  for (const sticker of stickers.value) {

    let targetTrack = tracks.find((track) => {
      return !track.stickers.some((trackSticker) => isOverlapping(trackSticker, sticker));
    });

    if (!targetTrack) {      
      const idSeed = tracks.length; // Use the current track length as the seed
      const guid = uuidv5(idSeed.toString(), namespace); // Generate a deterministic GUID
      targetTrack = { id: guid, stickers: [] };
      tracks.push(targetTrack);
    }

    targetTrack.stickers.push(sticker);
  }

  return tracks;
});
</script>

<template>
  <div class="flex flex-col-reverse gap-2 h-full">
    <TimeLineTrack
      v-for="track in timeLineTracks"
      :key="'track_' + track.id"
      class="relative h-full !min-h-8 w-full"
    >
      <StickerNode
        v-for="sticker in track.stickers"
        :id="sticker.id"
        :key="sticker.id"
        :focus-type="sticker.focusType"
      />
    </TimeLineTrack>
  </div>
</template>
