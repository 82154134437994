/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { ApiError, StringObjectDictionaryApiResponse } from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiUsersUserIdPreferences = (
  userId: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  userId = unref(userId)

  return accountsAxiosInstance<StringObjectDictionaryApiResponse>(
    { url: `/api/users/${userId}/preferences`, method: 'GET', signal },
    options
  )
}

export const getGetApiUsersUserIdPreferencesQueryKey = (userId: MaybeRef<string>) => {
  return ['api', 'users', userId, 'preferences'] as const
}

export const getGetApiUsersUserIdPreferencesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUsersUserIdPreferencesQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>> = ({ signal }) =>
    getApiUsersUserIdPreferences(userId, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(userId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>, TError, TData>
}

export type GetApiUsersUserIdPreferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>
>
export type GetApiUsersUserIdPreferencesQueryError = ApiError

export const useGetApiUsersUserIdPreferences = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUsersUserIdPreferencesQueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const putApiUsersUserIdPreferences = (
  userId: MaybeRef<string>,
  putApiUsersUserIdPreferencesBody: MaybeRef<unknown>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  userId = unref(userId)
  putApiUsersUserIdPreferencesBody = unref(putApiUsersUserIdPreferencesBody)

  return accountsAxiosInstance<StringObjectDictionaryApiResponse>(
    {
      url: `/api/users/${userId}/preferences`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: putApiUsersUserIdPreferencesBody,
    },
    options
  )
}

export const getPutApiUsersUserIdPreferencesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
    TError,
    { userId: string; data: unknown },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
  TError,
  { userId: string; data: unknown },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
    { userId: string; data: unknown }
  > = (props) => {
    const { userId, data } = props ?? {}

    return putApiUsersUserIdPreferences(userId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiUsersUserIdPreferencesMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>
>
export type PutApiUsersUserIdPreferencesMutationBody = unknown
export type PutApiUsersUserIdPreferencesMutationError = ApiError

export const usePutApiUsersUserIdPreferences = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
    TError,
    { userId: string; data: unknown },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
  TError,
  { userId: string; data: unknown },
  TContext
> => {
  const mutationOptions = getPutApiUsersUserIdPreferencesMutationOptions(options)

  return useMutation(mutationOptions)
}
