<script setup lang="ts">
import { computed, ref } from 'vue'
import IconSaxWristClock from '@/components/Icons/iconsax/IconSaxWristClock.vue'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import settings from '@/data/settings'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import IconSaxCloseSquare from '@/components/Icons/iconsax/IconSaxCloseSquare.vue'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { RouterLink, useRouter } from 'vue-router'
import type { MontageDto, RenderDto } from '@/apis/streamladder-api/model'
import type { Notification } from '@/modules/NotificationService/INotificationAdapter.interface'
import { NotificationQueryKey, useMarkNotificationAsRead } from '@/modules/NotificationService/NotificationService'
import { useLiveRenderStatus } from '@/Hooks/useLiveRenderStatus'
import IconSaxRefresh2 from '@/components/Icons/iconsax/IconSaxRefresh2.vue'
import { postApiRendersIdRetry } from '@/apis/streamladder-api/renders/renders'
import { queryClient } from '@/services/QueryClient'
import type { TypedOutboundSocialAccount } from '@/store/user/userInfo'

const props = defineProps<{ notification: Notification<RenderDto | MontageDto | TypedOutboundSocialAccount> }>()

const hideRetryButton = ref(false)
const isStuckRendering = (data: RenderDto | MontageDto) => {
  return data.updatedAt
    && data.status === 'rendering'
    && new Date(data.updatedAt).getTime() < new Date().getTime() - 1000 * 60 * 60 * 0.5 // 30 minutes
}

const status = computed(() => props.notification.data?.status ?? 'draft')
const isQueued = computed(() => status.value === 'draft')
const isStarted = computed(() => status.value === 'rendering')
const isProcessing = computed(() => status.value === 'processing') // Montage renderer
const isError = computed(() => status.value === 'failed')

const router = useRouter()

const {
  overlayPercentage,
  renderPercentage,
  isFinished: _isFinished,
} = useLiveRenderStatus(computed(() => props.notification?.data?.id ?? ''))

const isFinished = computed(() => status.value === 'success' || _isFinished.value)

const currentPercentage = computed(() => {
  return Math.round((overlayPercentage.value + renderPercentage.value) / 2)
})

const markAsRead = useMarkNotificationAsRead()

async function onClick() {
  const { title, data } = props.notification

  if (isFinished.value) {
    await markAsRead(props.notification.id)
  }

  if (isError.value) {
    EventBus.$emit(
      mainEvents.ERROR,
      `Something went wrong with rendering <strong>${title}</strong>.
       <br/><br/>
      Please open a Ticket on Discord with TaskId:<br/>${data?.id}`
    )
  }
}

async function retry() {
  hideRetryButton.value = true
  const id = props.notification.data?.id
  if (!id) return
  await postApiRendersIdRetry(id)
  await queryClient.invalidateQueries(NotificationQueryKey)
}
</script>

<template>
  <li>
    <component
      :is="notification.to ? RouterLink : 'button'"
      :to="notification.to ?? undefined"
      @click="onClick"
      class="relative flex w-full flex-1 items-center gap-3 rounded-lg px-3 py-2 transition-all hover:bg-zinc-100 active:scale-90 dark:hover:bg-zinc-600"
      :class="{
        'bg-sky-400/5': isFinished,
      }"
    >
      <template v-if="notification.type === 'social-connection'">
        <IconSaxRefresh2 class="shrink-0 text-sky-500" />
        <span class="text-left">
          <span class="truncate font-bold">{{ notification.title }}</span
          ><br />
          <span class="font-light">{{ notification.description }}</span>
        </span>
      </template>
      <template v-else>
        <template v-if="isError || isStuckRendering(notification.data!)">
          <IconSaxCloseSquare class="shrink-0 text-rose-500" />
          <span class="text-left">
              Something went wrong while rendering <span class="font-bold">&ldquo;{{ notification.title }}&rdquo;</span>.
              Please feel free to open a ticket on
              <a class="link text-indigo-500 hover:no-underline" :href="settings.discordInviteUrl">our Discord</a>.
            </span>
          <button :disabled="!hideRetryButton" class="origin-center transform transition hover:scale-110 active:scale-90" @click.stop="retry">
            <IconSaxRefresh2 class="" />
          </button>
        </template>
        <template v-else-if="isQueued">
          <IconSaxWristClock class="shrink-0 text-sky-500" />
          <span class="text-left">
            <span class="truncate font-bold">&ldquo;{{ notification.title }}&rdquo;</span> is queued for rendering!
            Please check back in a few minutes.
          </span>
        </template>
        <template v-else-if="isStarted || isProcessing">
          <IconSaxMagicpen class="shrink-0 text-amber-500" />
          <span class="text-left">
            <span class="font-bold">&ldquo;{{ notification.title }}&rdquo;</span> is currently rendering! Be sure to
            check back in a few minutes.
          </span>
          <span v-if="!isProcessing" class="ml-auto flex shrink-0 items-center justify-center self-center">
            <span
              class="radial-progress text-xs"
              :style="`--value: ${currentPercentage}; --size: 3rem; --thickness: 0.25rem`"
              role="progressbar"
            >
              {{ currentPercentage }}%
            </span>
          </span>
        </template>
        <template v-else-if="isFinished">
          <IconSaxTickCircle class="shrink-0 text-emerald-500" />
          <span class="text-left">
            <span class="font-bold">&ldquo;{{ notification.title }}&rdquo;</span> is done! Click here to post it to your
            socials.
          </span>
          <span v-if="isFinished" class="ml-auto h-2.5 w-2.5 shrink-0 rounded-full bg-sky-500" />
        </template>
        <template v-else>
          <IconSaxWristClock class="shrink-0 text-sky-500" />
          <span class="text-left">
            Unknown status for <span class="font-bold">&ldquo;{{ notification.title }}&rdquo;</span>. Our system should
            update the current status automatically. Please stand by.
          </span>
        </template>
      </template>
    </component>
  </li>
</template>

<style scoped lang="scss"></style>
