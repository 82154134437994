<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useUserTemplatesStore, type Template } from '@/store/user/userTemplates'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { getLayout, getLayoutsWithRoutes } from '@/data/layoutData'
import { useResetEditor } from '@/Hooks/useResetEditor'
import AppPage from '@/components/AppPage.vue'
import Stepper from '@/components/Editor/Stepper/Stepper.vue'
import ClipPreviewBlock from '@/components/LayoutSelection/ClipPreviewBlock.vue'
import ArrowRightIcon from '@/components/Icons/Vuesax/ArrowRightIcon.vue'
import Timeline from '@/components/Editor/Timeline/Timeline.vue'
import { type RouteLocationRaw, useRouter } from 'vue-router'
import { useGuard } from '@/Hooks/useGuard'
import LayoutBlock2 from '@/components/LayoutSelection/LayoutBlock2.vue'
import CustomLayouts from '@/components/LayoutSelection/CustomLayouts.vue'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import logging from '@/logging'
import { useScreen } from '@/Hooks/useScreen'
import { storageToSticker } from '@/components/Stickers/stickerStorageMapper'

const editorMainStore = useEditorMainStore()
const editorFeedDataStore = useEditorFeedDataStore()
const userTemplatesStore = useUserTemplatesStore()

const router = useRouter()
const selectedLayout = ref({
  key: null as string | null,
  name: null as string | null,
  route: null as RouteLocationRaw | null,
  trimRoute: null as RouteLocationRaw | null,
  isSavedTemplate: false,
  isCustomLayout: false,
  templateId: null as string | null,
})

const savedTemplates = computed(() => userTemplatesStore.savedTemplates)
const layouts = computed(() => getLayoutsWithRoutes('LayoutsPage'))

const canLayouts = useGuard('layouts')
const canSaveTemplate = useGuard('save-template')

const nextButtonText = computed(() => {
  if (selectedLayout.value.route != null && selectedLayout.value.name != null) {
    return selectedLayout.value.name.replace(/^Game Ui$/, 'Game UI')
  } else {
    return 'Select a layout'
  }
})

const isVerticalVideo = computed(() => {
  return editorMainStore.aspectRatio <= 0.6
})

function goToEditingPage() {
  storeFullscreenCrop()
  router.push({
    name: `editor/main`,
    params: {
      layout: 'FullScreen',
    },
  })
}

async function goTo(route) {
  editorMainStore.templateId = selectedLayout.value.templateId

  useResetEditor()(['clipInfo', 'main', 'feedData'])
  editorFeedDataStore.resetFragments()

  // Apply template if selected
  if (selectedLayout.value.isSavedTemplate) {
    const template = savedTemplates.value.find((x) => x.id === selectedLayout.value.templateId)
    applyTemplate(template)
  }

  if (selectedLayout.value.isCustomLayout) {
    logging.trackEvent('Layout Selected', {
      Layout: selectedLayout.value.key,
      SavedTemplate: selectedLayout.value.isSavedTemplate,
      IsPremiumLayout: true,
      IsCustomLayout: true,
    })

    editorMainStore.layoutId = selectedLayout.value.key!
    editorMainStore.layoutName = 'Custom'
  }

  await router.push(route)
}

function gotoNextPage() {
  goTo(selectedLayout.value.route)
}

function layoutSelected(layout) {
  selectedLayout.value = {
    key: layout.key,
    name: layout.name,
    route: {
      name: 'editor/cropping',
      params: {
        layout: layout.key,
      },
    },
    isSavedTemplate: false,
    templateId: null,
  }
}

function templateSelected(template: Template) {
  let layoutName = template.layoutName
  if ((layoutName as string) === 'Split') {
    layoutName = 'Split' + template.ratio
  }

  if ('layoutId' in template) {
    editorMainStore.layoutId = template.layoutId
  }

  const route = {
    name: 'editor/main',
    query: { savedTemplate: template.id },
    params: {
      layout: layoutName,
    },
  }

  selectedLayout.value = {
    name: template.templateName,
    route: route,
    trimRoute: { ...route, name: 'editor/cropping' },
    isSavedTemplate: true,
    templateId: template.id,
  }
}

function useCustomLayout(id: string) {
  customLayoutSelected(id)
  gotoNextPage()
}

function customLayoutSelected(id: string | null) {
  if (id === null) {
    selectedLayout.value = {
      key: null,
      name: null,
      route: null as RouteLocationRaw | null,
      trimRoute: null,
      isSavedTemplate: false,
      isCustomLayout: false,
      templateId: null,
    }
    return
  }

  const layoutsStore = useLayoutsStore()
  const layout = layoutsStore.selectById(id)

  selectedLayout.value = {
    key: layout.id,
    name: layout.emoji + ' ' + layout.name,
    route: {
      name: 'editor/main',
      params: {
        layout: layout.id,
      },
    },
    isSavedTemplate: false,
    isCustomLayout: true,
    templateId: null,
  }
}

function applyTemplate(template: Template) {

  let layoutName = template.layoutName
  if ((layoutName as string) === 'Split') {
    layoutName = 'Split' + template.ratio
  }

  editorMainStore.layoutName = layoutName

  // Appears to no longer be necessary, if anything is redundant because it immediately happens again in FeedLayer.vue.
  // Presumably an artifact from when users would still have to trim in the next step.
  // 
  // editorFeedDataStore.initializeForTemplate(template, 
  //   editorMainStore.videoWidth, editorMainStore.videoHeight, 
  //   1080, 1920)

  const editorStickersStore = useEditorStickersStore()
  editorStickersStore.selectedStickers = template.stickers.map((sticker) =>
    storageToSticker(sticker, editorMainStore.videoDuration)
  )
}

function storeFullscreenCrop() {
  const key = 'FullScreen'
  const layout = getLayout(key)
  const { videoWidth, videoHeight, aspectRatio: videoRatio } = editorMainStore

  // Fullscreen usually crops at 90% of regular size. We want as much as
  // possible to be visual in this case.

  const cropRatio = 9 / 16
  const cropWidth = Math.min(videoHeight * cropRatio, videoHeight * videoRatio)
  const cropHeight = Math.min(videoWidth / cropRatio, videoWidth / videoRatio)

  const cropX = 0.5 * videoWidth - 0.5 * cropWidth
  const cropY = 0.5 * videoHeight - 0.5 * cropHeight

  const finalCrop = {
    w: cropWidth / videoWidth,
    h: cropHeight / videoHeight,
    x: cropX / videoWidth,
    y: cropY / videoHeight,
    ratio: cropRatio,
  }

  for (const fragment of layout.baseFragments) {
    localStorage.setItem(fragment.key, JSON.stringify(finalCrop))
  }

  editorMainStore.layoutName = key
  editorFeedDataStore.initializeForFeed(key, videoWidth, videoHeight, cropWidth, cropHeight)
}

const isXl = useScreen('xl')
const isSm = useScreen('sm')
const templateSkeletons = computed(() => {
  if (isXl) {
    return 4
  } else if (isSm) {
    return 3
  } else {
    return 2
  }
})
</script>

<template>
  <app-page alert-on-leave-site content-direction="row" header-size="medium" show-breadcrumb>
    <template v-slot:sub-nav>
      <stepper />
    </template>
    <template v-slot:content>
      <div class="z-10 grid w-full grid-cols-1 gap-3 overflow-x-hidden md:grid-cols-3">
        <div class="w-full">
          <h2 style="color: white">1. Select your video</h2>
          <clip-preview-block />
        </div>
        <div class="md:col-span-2">
          <h2 class="md:text-white">2. Select your layout</h2>
          <div class="rounded-xl border-2 border-gray-100 bg-white">
            <template v-if="canLayouts">
              <div v-if="userTemplatesStore.isLoading" class="p-4">
                <h4>Saved templates</h4>
                <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 xl:grid-cols-4">
                  <div v-for="key in templateSkeletons" :key="key">
                    <div class="skeleton h-36 w-full rounded-xl bg-[#f2fcfe]" />
                  </div>
                </div>
              </div>

              <div v-else-if="savedTemplates.length > 0" class="p-4">
                <h4>Saved templates</h4>
                <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 xl:grid-cols-4">
                  <div v-for="template in savedTemplates" :key="template.id">
                    <button
                      :class="{
                        selected: selectedLayout.templateId === template.id,
                      }"
                      class="saved-template h-full w-full transition hover:border-company-secondary-200"
                      @click="templateSelected(template)"
                    >
                      <div class="name text-left font-semibold">
                        {{ template.templateName }}
                      </div>
                      <div class="metadata">
                        <span class="font-semibold">Based on:</span>
                        <span>
                          {{ template.displayName }}
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <hr v-if="canSaveTemplate && (userTemplatesStore.isLoading || savedTemplates.length > 0)" />

            <div class="p-4">
              <h4 class="">Layouts</h4>
              <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 xl:grid-cols-4">
                <CustomLayouts
                  @select="customLayoutSelected"
                  @save="useCustomLayout"
                  :selected="selectedLayout.key"
                />
                <LayoutBlock2
                  v-for="layout in layouts"
                  :key="layout.name"
                  :cssClass="layout.cssClass"
                  :img="layout.img"
                  :isPremium="layout.premium"
                  :name="layout.name"
                  :selected="selectedLayout.name === layout.name"
                  :to-route="layout.routeName"
                  :video="layout.video"
                  @click="layoutSelected(layout)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex w-full flex-col">
        <div class="container mx-auto">
          <div class="relative w-full">
            <div class="absolute bottom-full right-0 mb-6 flex flex-row gap-2">
              <button
                v-if="!selectedLayout.isSavedTemplate && isVerticalVideo"
                class="floating-footer-button btn-white btn-outline btn-primary btn gap-2 bg-white sm:btn-xl"
                @click="goToEditingPage"
              >
                Skip to editing
                <arrow-right-icon />
              </button>

              <button
                :disabled="!selectedLayout.route"
                class="floating-footer-button btn-primary btn gap-2 sm:btn-xl disabled:bg-zinc-300 disabled:opacity-100"
                @click="gotoNextPage"
              >
                {{ nextButtonText }}
                <arrow-right-icon />
              </button>
            </div>
          </div>
        </div>
        <Timeline step="layout" class="mx-0 w-full border-0" :default-open="false" />
      </div>
    </template>
  </app-page>
</template>

<style lang="scss" scoped>
.selected-video {
  .description {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .button-secondary {
      align-self: flex-end;
    }
  }
}

.panel-content {
  h4 {
    margin-left: 1em;
  }
}

hr {
  width: 100%;
  color: #f9f6fe;
  margin-top: 0;
  height: 2px;
  opacity: 1;
}

.saved-template {
  background-color: #f2fcfe;
  padding: 1em;
  border-radius: 15px;
  border: 1px solid #f9f6fe;
  margin-bottom: 1.5em;
  cursor: pointer;

  .name {
    min-height: 3em;
  }

  .metadata {
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
  }

  &.selected {
    background-color: #8833ff;
    color: white;
    border-color: #6523c2;
  }
}
</style>
