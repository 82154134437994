<script setup lang="ts">
import { useUserInfoStore, onLoggedIn } from '@/store/user/userInfo'
import DashboardIndex from '@/areas/dashboard/pages/index/DashboardIndex.vue'
import EditorIndex from '@/areas/editor/pages/EditorLayout.vue'
import { ref, onMounted } from 'vue'
import DashboardLayout from '@/areas/dashboard/layout/DashboardLayout.vue'
import { useRouter } from 'vue-router'
import EditorUploadPage from '@/areas/editor/pages/EditorUploadPage.vue';
import EditorLayout from '@/areas/editor/pages/EditorLayout.vue';

const userInfoStore = useUserInfoStore()
const showEditor = ref<boolean>()

const router = useRouter()
onLoggedIn(() => {
  if (showEditor.value === true) {
    router.replace({ name: 'editor' })
  }
})

onMounted(async () => {
  if (userInfoStore.isLoggedIn) {
    showEditor.value = false
  } else {
    await userInfoStore.updateUserInfo()
    showEditor.value = !userInfoStore.isLoggedIn
  }
})
</script>

<template>
  <EditorLayout v-if="showEditor === true">
    <template #router-view>
      <EditorUploadPage />
    </template>
  </EditorLayout>
  <DashboardLayout v-else-if="showEditor === false">
    <template #router-view>
      <DashboardIndex />
    </template>
  </DashboardLayout>
</template>

<style scoped lang="scss">

</style>
