<script lang="ts" setup>
import { useEventListener, useResizeObserver } from '@vueuse/core';
import { useChatButtonStyle, useChatButtonIconStyle, useChatWindowStyle, useChatArrowIconStyle, useNotificationWindowStyle } from '@/Hooks/useChat';

const slot = ref<HTMLElement | null>(null);
const chatButtonStyle = useChatButtonStyle();
const chatButtonIconStyle = useChatButtonIconStyle();
const chatArrowIconStyle = useChatArrowIconStyle();
const chatWindowStyle = useChatWindowStyle();
const notificationWindowStyle = useNotificationWindowStyle();

function onResize() {

  if (!slot.value) {
    return;
  }

  const rect = slot.value.getBoundingClientRect();

  chatButtonStyle.value = {
    bottom: `calc(100dvh - ${rect.bottom}px)`,
    left: `${rect.left}px`,
    width: `${rect.width}px`,
    height: `${rect.width}px`,
    opacity: '1',
    pointerEvents: 'auto',
    visibility: 'visible',
  };

  chatWindowStyle.value = {
    bottom: `0.5rem`,
    left: `${rect.right}px`,
  };

  chatButtonIconStyle.value = {
    backgroundColor: 'hsla(0 0 100% / 1)',
    width: '3.5rem',
    height: '3.5rem',
    color: 'var(--brand-primary-default)',
  };

  chatArrowIconStyle.value = {
    'transform': 'rotate(90deg)',
  }
  
  notificationWindowStyle.value = {
    top: 'auto',
    bottom: 0,
    left: `${rect.right + rect.left + 8}px`,
  }
}

useResizeObserver(slot, onResize);
useEventListener('resize', onResize);
onMounted(onResize);
</script>

<template>
  <div class="flex flex-col items-center justify-end">
    <div ref="slot" class="size-[3.5rem]" />
  </div>
</template>

<style scoped>

</style>
