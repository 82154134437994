<script setup lang="ts">
import { type DateValue, ZonedDateTime } from '@internationalized/date'
import { computed } from 'vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { isSameDay } from 'date-fns'

const props = defineProps<{ date: DateValue }>();

const contentPublisherStore = useContentPublisherStore();
const anyPostsScheduledOnThisDay = computed(() => {
  return contentPublisherStore.posts.some((post) => {
    return isSameDay(new Date(post.scheduledAt!), (props.date as ZonedDateTime).toDate());
  });
});
</script>

<template>
  <div class="relative">
    {{ props.date.day }}
    <span
      class="w-2 h-2 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-primary" 
      v-if="anyPostsScheduledOnThisDay"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
