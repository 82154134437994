<script lang="ts" setup>
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useUserInfoStore, type OutboundSocialAccount } from '@/store/user/userInfo'
import { computed, ref } from 'vue'
import NavigationFlyout from '@/areas/dashboard/components/NavigationFlyout.vue'
import IconSaxMoreSquare from '@/components/Icons/iconsax/IconSaxMoreSquare.vue'
import IconSaxMessageQuestion from '@/components/Icons/iconsax/IconSaxMessageQuestion.vue'
import { useRoute } from 'vue-router'
import IconSaxLogoutCurve from '@/components/Icons/iconsax/IconSaxLogoutCurve.vue'
import IconSaxElement4 from '@/components/Icons/iconsax/IconSaxElement4.vue'
import DashboardNavigationItem from '@/areas/dashboard/layout/nav/DashboardNavigationItem.vue'
import IconSaxSetting2 from '@/components/Icons/iconsax/IconSaxSetting2.vue'
import { useScreen } from '@/Hooks/useScreen'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import Spinner from '@/components/Icons/Spinner.vue'
import { signOut } from '@/authentication/supabase'

const isOpen = ref(false)
const userInfo = useUserInfoStore()

const account = computed(() => {
  const googleAccount = userInfo.googleAccount as OutboundSocialAccount
  const twitchAccount = userInfo.twitchAccount as OutboundSocialAccount

  return userInfo.allSocials.find((s) => s.profileImageUrl) ?? googleAccount ?? twitchAccount ?? null
})

const route = useRoute()
const isCurrentRoute = computed(() => route.matched.some((r) => r.name === dashboardRouteNames.account.root))

const isDesktop = useScreen('lg')

const isLoggingOut = ref(false)
const signOutWithUiState = async () => {
  isLoggingOut.value = true
  await signOut(route)
  isLoggingOut.value = false
};

useConditionalEventListener(isOpen, 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    isOpen.value = false
  }
})
</script>

<template>
  <nav class="flex flex-col">
    <DashboardNavigationItem
      v-if="account"
      v-click-outside="() => (isOpen = false)"
      :is-active="isDesktop && (isCurrentRoute || isOpen)"
      class="group/account cursor-pointer overflow-x-visible py-4 lg:order-last lg:flex"
      @click="isOpen = !isOpen"
    >
      <img
        v-if="account.profileImageUrl"
        :alt="`${userInfo.userName}'s avatar`"
        :src="account.profileImageUrl"
        class="h-10 w-10 self-center rounded-full transition-all"
      />

      <div class="flex max-w-[175px] flex-col gap-2 text-ellipsis">
        <p :title="userInfo.userName" class="overflow-hidden text-ellipsis text-left leading-[1]">
          {{ userInfo.userName }}
        </p>
      </div>

      <NavigationFlyout :model-value="isOpen" arrow class="ml-auto hidden lg:flex">
        <template #button>
          <button
            class="flex items-center justify-center rounded-xl p-2 transition-all group-hover/account:bg-black/10 group-active/account:scale-90"
          >
            <IconSaxMoreSquare />
          </button>
        </template>
        <template #menu="props">
          <nav class="flex flex-col gap-1" v-bind="props" @click.stop>
            <DashboardNavigationItem
              href="https://guides.streamladder.com/"
              class="hover:!bg-zinc-200 dark:hover:!bg-zinc-700"
              @click="isOpen = false"
            >
              <IconSaxMessageQuestion class="shrink-0" />
              Support
            </DashboardNavigationItem>

            <DashboardNavigationItem
              :route="dashboardRouteNames.account.settings"
              class="hover:!bg-zinc-200 dark:hover:!bg-zinc-700"
              @click="isOpen = false"
            >
              <IconSaxSetting2 class="shrink-0" />
              Account Settings
            </DashboardNavigationItem>

            <DashboardNavigationItem
              :route="dashboardRouteNames.account.templates"
              class="hover:!bg-zinc-200 dark:hover:!bg-zinc-700"
              @click="isOpen = false"
            >
              <IconSaxElement4 class="shrink-0" />
              My Templates
            </DashboardNavigationItem>

            <DashboardNavigationItem class="hover:!bg-zinc-200 dark:hover:!bg-zinc-700" @click="signOutWithUiState">
              <template v-if="isLoggingOut">
                <Spinner class="h-4 w-4 animate-spin mr-2.5" />
                Logging out..
              </template>
              <template v-else>
                <IconSaxLogoutCurve class="shrink-0 rotate-180" />
                Logout
              </template>
            </DashboardNavigationItem>
          </nav>
        </template>
      </NavigationFlyout>
    </DashboardNavigationItem>

    <DashboardNavigationItem href="https://guides.streamladder.com/" class="lg:hidden">
      <IconSaxMessageQuestion class="shrink-0" />
      Support
    </DashboardNavigationItem>

    <DashboardNavigationItem :route="dashboardRouteNames.account.settings" class="lg:hidden">
      <IconSaxSetting2 class="shrink-0" />
      Account Settings
    </DashboardNavigationItem>

    <DashboardNavigationItem :route="dashboardRouteNames.account.templates" class="lg:hidden">
      <IconSaxElement4 class="shrink-0" />
      My Templates
    </DashboardNavigationItem>

    <DashboardNavigationItem class="lg:hidden" @click="signOutWithUiState">
      <template v-if="isLoggingOut">
        <Spinner class="h-4 w-4 animate-spin mr-2.5" />
        Logging out..
      </template>
      <template v-else>
        <IconSaxLogoutCurve class="shrink-0 rotate-180" />
        Logout
      </template>
    </DashboardNavigationItem>
  </nav>
</template>

<style lang="scss" scoped></style>
