<script setup lang="ts">
import textLibrary from '@/components/Stickers/textLibrary'
import { isNewText } from '@/helpers/isNewOverlayElement'
import { ref, computed, onMounted, watch, capitalize } from 'vue'
import { useGuard } from '@/Hooks/useGuard'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { ScrollArea } from '@/components/ui/scroll-area'
import Tile from '@/components/Tile.vue'
import IconSaxElementPlus from '@/components/Icons/iconsax/IconSaxElementPlus.vue'
import BrandKitStyleWrapper from '@/areas/editor/views/text/brand-kit/BrandKitStyleWrapper.vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import StickerWrapper from '@/areas/editor/views/stickers/StickerWrapper.vue'
import CreateBrandKitStyleDialog from '@/areas/editor/views/text/brand-kit/CreateBrandKitStyleDialog.vue'
import { useStickersStore } from "@/areas/editor/store/useStickersStore";
import { useHistoryStore } from "@/areas/editor/store/useHistoryStore";
import type { TypedSticker } from "@/areas/editor/@type/Project";
import logging from "@/logging";
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { setLastUsedTextStickerKey } from '@/areas/editor/views/elements/useElementsSettings'
import { useUserInfoStore } from '@/store/user/userInfo'

const tags = ['Default', 'Intro', 'Outro',] as const
const selectedTag = ref<typeof tags[number]>('Default')

const getHtmlContent = computed(() => (index: number) => {
  return previewTexts[selectedTag.value][index % previewTexts[selectedTag.value].length]
})

const previewTexts = {
  Default: ['Enter Text\n here'],
  Outro: [
    'Link in the\n description 👇',
    'What did you think?\n Let me know',
    'Did you agree?\n 👍',
    'Did you disagree? 👎',
    'Like, subscribe\n and hit that bell\n for more ❤️',
    'This was CRAZY\n 😨',
    'This was SO fun\n 😇',
    'Share this\n with your friends',
    'Follow for more!',
    'Subscribe for more!',
    'Comment below💬',
    'My mind\n was BLOWN🤯',
  ],
  Intro: [
    'Stay till the end\n for a surprise',
    'What happens next\n is crazy!',
    "That's what I call\n a plottwist",
    'How did\n this happen?!',
    'I did NOT\n expect this',
    'I was shocked\n after this',
    'This will\n change your\n perspective ',
    'Wait for it...',
  ],
  'Fill in': [
    'The top 5\n [...]',
    'Imagine if you could \n (DESIRED RESULT)',
    'Why doesn’t \n anyone talk about \n (RELATED TO NICHE)?',
    'What if you could\n (DESIRED RESULT) \nwithout\n (PAIN POINT OF TARGET AUDIENCE)?',
    'Are you struggling with \n(PAIN POINT OF TARGET AUDIENCE)? \nThen try this',
    'I tried every\n (HACK/TIP/TRICK),\n so you don’t have to',
    '(HACK/TIP/TRICK) \nI wish I knew earlier',
    'These 3 (HACK/TIP/TRICK)\n feel illegal to know',
    'Did you know that\n (FACT)',
    'Fun fact:\n (FACT)',
  ],
}

const loadingBrandKit = ref(false)

const canCustomText = useGuard('text')

const stickerText = ref('')

const brandKitStore = useUserBrandkitStore()

const rescaleStickers = () => {
  if (!stickerWrappers.value) {
    return
  }
  stickerWrappers.value.forEach((stickerWrapper) => {
    stickerWrapper.scaleSticker?.()
  })
}

const stickerWrappers = ref([])

onMounted(async () => {
  rescaleStickers()
  await brandKitStore.list()
})

watch(stickerText, (newValue) => {
  if (newValue.length > 0) {
    rescaleStickers()
  }
})

const stickersStore = useStickersStore()
const historyStore = useHistoryStore()
const editorFocusStore = useEditorFocusStore()

async function select(sticker: TypedSticker<'text' | 'brand-kit'>) {
  switch (sticker.type) {
    case 'text':
      return selectTextSticker(sticker)
    case 'brand-kit':
      return selectBrandKitSticker(sticker)
    default:
      throw new Error(`Unknown sticker type: ${sticker.type}`)
  }
}

async function selectTextSticker(sticker: TypedSticker<'text'>) {
  
  const id = stickersStore.createTextSticker(sticker)

  editorFocusStore.setFocus(FocusTypes.STICKER, id)

  setLastUsedTextStickerKey(sticker.key);

  logging.trackEvent('Editor TextSticker Added', {
    Variant: sticker.variant,
    Color: sticker.color,
    Tags: textLibrary.find(t => t.key === sticker.key)?.tags ?? [],
  })
}

async function selectBrandKitSticker(sticker: TypedSticker<'brand-kit'>) {

  const id = stickersStore.createBrandKitSticker(sticker)
  editorFocusStore.setFocus(FocusTypes.STICKER, id)

  setLastUsedTextStickerKey(sticker.id);

  logging.trackEvent('Editor Brandkit TextSticker Added', {
    Variant: sticker.variant,
    Color: sticker.primaryColor,
    Tags: [],
  })
}

const { currentSection } = useEditorStep()

const userInfoStore = useUserInfoStore()
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">

    <div class="flex items-center gap-2 cursor-pointer hover:-translate-x-0.5 transition-transform text-brand-state-link hover:underline underline-offset-2" @click="currentSection = null">
      <IconSaxArrowLeft class="w-4 h-4" />
      <span class="text-xl lg:text-sm mt-0.5 select-none">Back to Elements</span>
    </div>

    <header v-if="canCustomText" class="hidden md:block">
      <h2 class="text-xl font-semibold leading-snug font-title">Custom Texts</h2>
      <p class="font-light">Add text to your clips and capture your audience's attention.</p>
    </header>
    <header v-else class="hidden md:block">
      <h2 class="text-xl font-semibold leading-snug font-title">Custom Texts</h2>
      <p class="font-light">Upgrade to add text to your clips and capture your audience's attention.</p>
    </header>

    <template v-if="!loadingBrandKit">
      <div class="shrink-0 w-full min-w-0 overflow-x-auto overflow-y-hidden">
        <div class="flex flex-row gap-1">
          <RadioToggleButton
            v-for="tag in tags" :key="tag" :value="tag" v-model="selectedTag"
            size="sm" class="flex-col relative font-light data-[state=active]:font-semibold  shrink-0"
          >
            <span class="absolute inset-auto">{{ capitalize(tag) }}</span>
            <span class="font-semibold invisible">{{ capitalize(tag) }}</span>
          </RadioToggleButton>
        </div>
      </div>
    </template>
    <ScrollArea>
      <div class="notranslate grid grid-cols-2 gap-2 lg:grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))]">
        <template v-if="userInfoStore.isLoggedIn">
          <CreateBrandKitStyleDialog>
            <Tile :aspect="0.6" feature="brandkit"
              class="text-current active:scale-90 transition-[transform,_border-color,_background-color]"
            >
              <IconSaxElementPlus />
              <span>Custom style</span>
            </Tile>
          </CreateBrandKitStyleDialog>

          <BrandKitStyleWrapper
            popover-enabled
            v-for="(style, index) in brandKitStore.brandKitStyles"
            :key="style.id + selectedTag + getHtmlContent(index)"
            :text-content="getHtmlContent(index)"
            :moment="selectedTag.toLowerCase() as 'default' | 'intro' | 'outro'"
            @select="(s) => select(s)"
            ref="stickerWrappers"
            :style="style"
          />
        </template>

        <StickerWrapper
          ref="stickerWrappers"
          v-for="(sticker, index) in textLibrary"
          :key="sticker.key + (index + brandKitStore.brandKitStyles.length) + selectedTag + getHtmlContent(index + brandKitStore.brandKitStyles.length)"
          :moment="selectedTag.toLowerCase()"
          :stickerMeta="sticker"
          :is-new="isNewText(sticker)"
          :editable="true"
          :text-content="getHtmlContent(index + brandKitStore.brandKitStyles.length)"
          @select="(s) => select(s as TypedSticker<'text'>)"
        />
      </div>
    </ScrollArea>
  </div>
</template>

<style scoped lang="scss">

</style>
