import { JobStatus, JobType, type MontageDto, type NotificationDto, type RenderDto } from "@/apis/streamladder-api/model";
import { getApiNotifications } from "@/apis/streamladder-api/notifications/notifications";
import { putApiRendersIdRead, putApiRendersReadall } from "@/apis/streamladder-api/renders/renders";
import { dashboardRouteNames } from "@/areas/dashboard/routeNames";
import { truncate } from "lodash-es";
import type { RouteLocationRaw } from "vue-router";
import type { INotificationAdapter, Notification } from "../INotificationAdapter.interface";

export class NotificationsAdapter implements INotificationAdapter {
    public readonly name = 'notification';
    private identifier = 'notification-';

    public getIdentifier(id?: string): string {
        return this.identifier + (id || '');
    }

    public async getUnreadNotifications(): Promise<Notification[]> {
        const notifications = await getApiNotifications({
            cutoffTime: "14" // days
        });

        const renderNotifications = notifications
            .filter((notification) => notification.type === JobType.render)
            .map((n) => this.parseRenderNotification(n));

        const montageNotifications = notifications
            .filter((notification) => notification.type === JobType.montage)
            .map((n) => this.parseMontageNotification(n));

        return [...renderNotifications, ...montageNotifications];
    }

    public async markNotificationAsRead(notificationId: string): Promise<void> {
        await putApiRendersIdRead(notificationId.replace(this.identifier, ''))
    }

    public async markAllNotificationsAsRead(): Promise<void> {
        await putApiRendersReadall()
    }

    private parseRenderNotification(notification: NotificationDto): Notification<RenderDto> {
        let to = {} as RouteLocationRaw | undefined

        if (notification.status === JobStatus.success) {
            to = {
                name: dashboardRouteNames.contentPublisher.render,
                params: {
                    renderId: notification.id,
                }
            }
        } else if (notification.status === JobStatus.failed) {
            to = undefined
        } else {
            to = {
                name: 'ServerSideRenderStatusPage',
                query: {
                    task: notification.id,
                },
            }
        }

        return {
            id: `${this.identifier}${notification.id}`,
            type: 'render',
            title: truncate(notification.title ?? 'New render', { length: 22 }),
            description: notification.statusMessage,
            to,
            data: notification,
        } as Notification<RenderDto>
    }

    private parseMontageNotification(notification: NotificationDto): Notification<MontageDto> {
        let to = {} as RouteLocationRaw | undefined

        if (notification.status === 'processing') {
            to = {
                name: 'montage-maker',
                query: {
                    task: notification.id,
                },
            }
        }

        return {
            id: `${this.identifier}${notification.id}`,
            type: 'montage',
            title: truncate(notification.title ?? 'New montage', { length: 22 }),
            description: notification.statusMessage,
            to,
            data: notification,
        } as Notification<MontageDto>
    }
}