import type { Notification, INotificationAdapter } from '@/modules/NotificationService/INotificationAdapter.interface'
import type { RouteLocationRaw } from 'vue-router'
import type { TypedOutboundSocialAccount } from '@/store/user/userInfo'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useUserInfoStore } from '@/store/user/userInfo'

export class SocialConnectionsAdapter implements INotificationAdapter {

  public readonly name = 'social';
  private identifier = 'social-notification-';

  public getIdentifier(id?: string): string {
    return this.identifier + (id || '')
  }

  public async getUnreadNotifications(): Promise<Notification<TypedOutboundSocialAccount>[]> {

    const userInfoStore = useUserInfoStore()
    if (!userInfoStore.isAuthenticated) await userInfoStore.updateUserInfo()

    const socialAccounts = (userInfoStore.allSocials) ?? []

    return socialAccounts
      .filter((account) => !account.hasAccess || account.state !== 'active')
      .map((account) => {

        const to = { name: dashboardRouteNames.socials } as RouteLocationRaw;

        const socialLabels = {
          "tiktok": "TikTok",
          "instagram": "Instagram",
          "youtube": "YouTube"
        };

        const socialLabel = socialLabels[account.type] || "social";

        return {
          id: `${this.identifier}${account.id}`,
          type: 'social-connection',
          title: 'Reconnection required',
          description: `Reconnect ${socialLabel} account to continue using it.`,
          to,
          data: account,
        } as Notification<TypedOutboundSocialAccount>
      })
  }
}
