<script setup lang="ts">
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import settings from '@/data/settings'

const userInfoStore = useUserInfoStore()

const timeBeforeShowingLoaderMs = 10000;

const showLoader = ref(false);

const hasLoadedUserInfo = computed(() => {
  return userInfoStore.hasLoadedUserInfo
});

const failedToReachAccountsService = computed(() => {
  return userInfoStore.failedToReachAccountsService
});

const timeout = ref();

onMounted(() => {
  timeout.value = setTimeout(() => {
    showLoader.value = true;
  }, timeBeforeShowingLoaderMs);
});

onUnmounted(() => {
  clearTimeout(timeout.value);
});

watch(() => hasLoadedUserInfo.value, () => {
  if (hasLoadedUserInfo.value) {
    showLoader.value = false;
  }
});
</script>

<template>
  <div class="bg-white fixed inset-0 z-50 flex items-center justify-center" v-if="failedToReachAccountsService">
    <div class="flex flex-col items-center justify-center p-4 gap-4">
      <LottieAnimation url="/lottie/race-car-crash.json" class="w-48 h-48" />
      <p class="text-black text-2xl font-light">Something went wrong while connecting to StreamLadder</p>
      <p class="text-black font-light">Try reloading the page or check our Discord!</p>
      <a :href="settings.discordInviteUrl" class="btn-primary btn-lg btn mx-auto mt-3 gap-2" target="_blank">
        <discord-logo class="w-6 h-6" fill="#FFF" />
        Check status
      </a>
    </div>
  </div>
  <div class="bg-white fixed inset-0 z-50 flex items-center justify-center" v-else-if="showLoader && !hasLoadedUserInfo">
    <div class="flex flex-col items-center justify-center p-4 gap-4">
      <LottieAnimation url="/lottie/rocketLaunch.json" class="w-24 h-24" />
      <p class="text-black text-xl font-light">Loading StreamLadder is taking longer than expected</p>
      <p class="text-black font-light">Please hold tight!</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(40px)
}
</style>