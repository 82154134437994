/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { Template } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const postApiTemplates = (
  template: MaybeRef<Template>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  template = unref(template)

  return streamLadderAxiosInstance<void>(
    { url: `/api/Templates`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: template },
    options
  )
}

export const getPostApiTemplatesMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiTemplates>>, TError, { data: Template }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiTemplates>>, TError, { data: Template }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTemplates>>, { data: Template }> = (props) => {
    const { data } = props ?? {}

    return postApiTemplates(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiTemplatesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTemplates>>>
export type PostApiTemplatesMutationBody = Template
export type PostApiTemplatesMutationError = unknown

export const usePostApiTemplates = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiTemplates>>, TError, { data: Template }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof postApiTemplates>>, TError, { data: Template }, TContext> => {
  const mutationOptions = getPostApiTemplatesMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiTemplates = (
  template: MaybeRef<Template>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  template = unref(template)

  return streamLadderAxiosInstance<void>(
    { url: `/api/Templates`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: template },
    options
  )
}

export const getPutApiTemplatesMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiTemplates>>, TError, { data: Template }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiTemplates>>, TError, { data: Template }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiTemplates>>, { data: Template }> = (props) => {
    const { data } = props ?? {}

    return putApiTemplates(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiTemplatesMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTemplates>>>
export type PutApiTemplatesMutationBody = Template
export type PutApiTemplatesMutationError = unknown

export const usePutApiTemplates = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiTemplates>>, TError, { data: Template }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof putApiTemplates>>, TError, { data: Template }, TContext> => {
  const mutationOptions = getPutApiTemplatesMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiTemplates = (options?: SecondParameter<typeof streamLadderAxiosInstance>, signal?: AbortSignal) => {
  return streamLadderAxiosInstance<void>({ url: `/api/Templates`, method: 'GET', signal }, options)
}

export const getGetApiTemplatesQueryKey = () => {
  return ['api', 'Templates'] as const
}

export const getGetApiTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTemplates>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTemplates>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTemplatesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTemplates>>> = ({ signal }) =>
    getApiTemplates(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiTemplates>>,
    TError,
    TData
  >
}

export type GetApiTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTemplates>>>
export type GetApiTemplatesQueryError = unknown

export const useGetApiTemplates = <TData = Awaited<ReturnType<typeof getApiTemplates>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTemplates>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const deleteApiTemplatesTemplateId = (
  templateId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  templateId = unref(templateId)

  return streamLadderAxiosInstance<void>({ url: `/api/Templates/${templateId}`, method: 'DELETE' }, options)
}

export const getDeleteApiTemplatesTemplateIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiTemplatesTemplateId>>,
    TError,
    { templateId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiTemplatesTemplateId>>,
  TError,
  { templateId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiTemplatesTemplateId>>,
    { templateId: string }
  > = (props) => {
    const { templateId } = props ?? {}

    return deleteApiTemplatesTemplateId(templateId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiTemplatesTemplateIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiTemplatesTemplateId>>
>

export type DeleteApiTemplatesTemplateIdMutationError = unknown

export const useDeleteApiTemplatesTemplateId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiTemplatesTemplateId>>,
    TError,
    { templateId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof deleteApiTemplatesTemplateId>>,
  TError,
  { templateId: string },
  TContext
> => {
  const mutationOptions = getDeleteApiTemplatesTemplateIdMutationOptions(options)

  return useMutation(mutationOptions)
}
