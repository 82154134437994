<script setup lang="ts">

import { ref, watch } from 'vue'
import type { SurveyQuestion } from 'posthog-js'
import { Input } from '@/components/ui/input'

const props = defineProps<{
  question: SurveyQuestion
}>();

const emit = defineEmits<{
  (event: 'answered', value: string | null): void
  (event: 'invalidAnswer'): void
}>();

const textareaRef = ref<HTMLTextAreaElement | null>(null);

watch(() => props.question, () => {

  emit('answered', null);
  emit('invalidAnswer');

  if (textareaRef.value) {
    textareaRef.value.value = '';
  }
}, { immediate: true });

const onInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  if (target.value.length > 3) {
    emit('answered', target.value);
  } else {
    emit('invalidAnswer');
  }
};
</script>

<template>
  <Input as="textarea"
    ref="textareaRef"
    @input="onInput"
    class="placeholder:font-light placeholder:text-sm w-full min-h-[75px] max-h-[250px]"
    maxlength="300"
    :placeholder="question.description"
  />
</template>

<style scoped>

</style>
