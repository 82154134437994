import { acceptHMRUpdate, defineStore } from 'pinia'
import type { CaptionOptions, CaptionsDocument, CaptionStyleDefinition, CaptionsWrapper, StoredCaption } from '@/components/Captions/captionTypes'
import { captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'
import type { CaptionStyle } from '@/components/Captions/styles/captionStyles'
import { addEmojiToCaptions, addMetaDataToCaptions, groupCaptionWords, reduceGapBetweenCaptions } from '@/components/Captions/CaptionPreprocessors'
import { setBaseColor, setHighlightColor } from '@/components/Captions/CaptionPreRenderer'
import { computed, nextTick, reactive, ref, watch } from 'vue'
import { useToast } from '@/Hooks/useToast'
import { useStorage } from '@vueuse/core'
import { v4 } from 'uuid'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import logging from '@/logging'
import ToastEvents from '@/events/toastEvents'
import { captionStyleToCaptionPreset } from '@/components/Captions/v3/captionStyleToCaptionPreset'
import type { CaptionsSettings } from '@/areas/editor/@type/Project';

export const defaultCaptionsWrapper: CaptionsWrapper = {
  x: 0.0545,
  y: 0.57731,
  scale: 0.00405,
}

export const defaultCaptionOptions: Omit<CaptionOptions, 'baseColor'> = {
  emojis: false,
  emojiLocation: 'bottom',
  stripPunctuation: false,
  rotate: true,
  highlight: false,
  highlightColor: '#0080ff',
  grouping: 'group',
  size: 'medium',
  animation: 'reveal',
  animationTarget: 'word',
}

type CaptionFeatures = 'highlight' | 'emojis'
const featuresPerLanguage = {
  en_us: ['highlight', 'emojis'],
}

/** @deprecated */
export const useEditorCaptionsStore = defineStore('editorCaptions', () => {

  const captionsDocument = ref<CaptionsDocument | null>(null)
  const groupedCaptions = reactive<StoredCaption[]>([])

  const captionStyle = ref<CaptionStyle>('funky')
  const storedData = localStorage.getItem(`caption-options`)
  const baseOptions = reactive<CaptionOptions>(
    storedData ? { ...defaultCaptionOptions, ...JSON.parse(storedData) } : defaultCaptionOptions
  )
  const styleOptions = reactive({
    data: {
      baseColor: captionStylesSettings[captionStyle.value].colors[0],
      highlightColor: captionStylesSettings[captionStyle.value].highlightColor,
    },
  })

  const captionPreset = computed(() => {
    return captionStyleToCaptionPreset(captionStyle.value, styleOptions.data.baseColor, styleOptions.data.highlightColor)
  })

  watch(captionStyle, (newValue, oldValue) => {
    const storedData = localStorage.getItem(`caption-options-${newValue}`)
    const parsedData = storedData ? JSON.parse(storedData) : {}
    styleOptions.data = {
      ...{
        baseColor: captionStylesSettings[captionStyle.value].colors[0],
        highlightColor: captionStylesSettings[captionStyle.value].highlightColor,
      },
      ...parsedData,
    } as CaptionOptions
    // nextTick(() => {
    //   editorVideoStore.timeline.invalidate()
    // })
  })

  watch(
    () => styleOptions,
    (newValue, oldValue) => {
      localStorage.setItem(`caption-options-${captionStyle.value}`, JSON.stringify(newValue.data))
      // nextTick(() => {
      //   editorVideoStore.timeline.invalidate()
      // })
    },
    { deep: true }
  )

  watch(
    baseOptions,
    (newValue, oldValue) => {
      localStorage.setItem(`caption-options`, JSON.stringify(newValue))
      // nextTick(() => {
      //   editorVideoStore.timeline.invalidate()
      // })
    },
    { deep: true }
  )

  const selectedLanguage = useStorage<string>('caption-language', 'en_us')
  const captionsWrapper = ref<CaptionsWrapper>(defaultCaptionsWrapper)
  const setCaptionsDocument = (captions: CaptionsDocument) => {
    captionsDocument.value = captions
  }

  // change Captions Document or Grouping
  // recalculate
  watch([captionsDocument, () => baseOptions.grouping], async () => {
    if (!captionsDocument.value) return
    const grouped = groupCaptionWords(JSON.parse(JSON.stringify(captionsDocument.value)), baseOptions.grouping)
    reduceGapBetweenCaptions(grouped)
    addMetaDataToCaptions(grouped)

    setBaseColor(grouped, styleOptions.data.baseColor)
    groupedCaptions.splice(0, groupedCaptions.length, ...grouped)
    setBaseColor(groupedCaptions, styleOptions.data.baseColor)

    // wait for the elements to dismount and mount from the Dom and timeline.
    // then force a rerender of the timeline
    nextTick(() => {
      if (baseOptions.emojis && hasFeature('emojis').value) {
        addEmojiToCaptions(groupedCaptions)
      }
      // editorVideoStore.timeline.invalidate()
    })
  })

  const hasCaptions = computed(() => {
    return captionsDocument.value !== null
  })

  // change Base Color
  watch(
    () => styleOptions.data.baseColor,
    () => {
      setBaseColor(groupedCaptions, styleOptions.data.baseColor)
    }
  )

  const captionStyleSettings = computed(() => {
    const baseSettings = captionStylesSettings[captionStyle.value] as CaptionStyleDefinition
    const emojiSettings = baseSettings.effects?.emoji || {
      maxEmojis: 1,
      location: baseOptions.emojiLocation || 'bottom',
    }
    const rotateSettings = baseSettings.effects?.rotate || 10
    const textScale = baseOptions.size === 'large' ? 1 : baseOptions.size === 'medium' ? 0.7 : 0.5
    return {
      ...baseSettings,
      fontSize: {
        ...baseSettings.fontSize,
        scale: textScale,
      },
      effects: {
        emoji: emojiSettings,
        stripPunctuation: baseOptions.stripPunctuation,
        rotate: baseOptions.rotate ? rotateSettings : undefined,
      },
    } as CaptionStyleDefinition
  })

  watch(
    () => baseOptions.emojis,
    async () => {
      if (baseOptions.emojis) {
        await addEmojiToCaptions(groupedCaptions)
      } else {
        groupedCaptions.forEach((caption) => {
          caption.emojis = []
        })
      }
      // nextTick(() => {
      //   editorVideoStore.timeline.invalidate()
      // })
    }
  )

  // change Highlight Color
  watch(
    () => [
      baseOptions.highlight,
      styleOptions.data.highlightColor,
      captionStyleSettings.value.disableHighlight,
      baseOptions.grouping // Add this to the array to watch for changes
    ],
    (
      [newHighlight, newHighlightColor, newDisableHighlight, newGroup],
      [oldHighlight, oldHighlightColor, oldDisableHighlight, oldGroup]
    ) => {
      const groupChanged = newGroup !== oldGroup;
      if (!groupChanged) {
        setHighlightColor(
          groupedCaptions,
          newHighlight && !newDisableHighlight
            ? newHighlightColor
            : undefined,
        );
      }
    }
  );

  const addCaption = async (at: number) => {
    const captionAtPosition = groupedCaptions.findIndex((c) => c.start <= at && c.end >= at)
    const nextCaption = groupedCaptions.findIndex((c) => c.start > at)
    const defaultWidth = 1000
    const width =
      nextCaption >= 0
        ? groupedCaptions[nextCaption].start - at <= defaultWidth
          ? groupedCaptions[nextCaption].start - at
          : defaultWidth
        : defaultWidth
    const end = at + width

    const distanceToStartCaptionAtPosition = at - groupedCaptions[captionAtPosition]?.start
    if (distanceToStartCaptionAtPosition <= 200) {
      const { showToast } = useToast()
      showToast({
        type: ToastEvents.TOAST,
        title: 'Already a caption at this position',
        subtitle: 'Please select a different position',
      })
      return
    }

    const newCaption = createCaption(at, end)

    const focusStore = useEditorFocusStore()
    focusStore.setFocus('caption', newCaption.id)
    logging.trackEvent('Caption added', {})
    if (captionAtPosition >= 0) {
      groupedCaptions[captionAtPosition].end = at
      groupedCaptions.splice(captionAtPosition + 1, 0, newCaption)
      return newCaption
    }
    if (nextCaption >= 0) {
      groupedCaptions.splice(nextCaption, 0, newCaption)
      return newCaption
    }
    groupedCaptions.push(newCaption)
    return newCaption
  }
  
  function createCaption(startMs: number, endMs: number): StoredCaption {
    return {
      start: startMs,
      end: endMs,
      id: v4(),
      text: '',
      confidence: 1,
      color: styleOptions.data.baseColor,
      randomizer: Math.random(),
      words: [
        {
          id: v4(),
          color: undefined,
          confidence: 1,
          start: startMs,
          end: endMs,
          text: '',
          Highlighted: false,
          speaker: 'A',
        },
      ],
    }
  }

  const updateCaption = (id: string, caption: Partial<StoredCaption>) => {
    const index = groupedCaptions.findIndex((c) => c.id === id)
    if (index > -1) {
      groupedCaptions.splice(index, 1, { ...groupedCaptions[index], ...caption })
    }
  }

  const deleteCaption = (id: string) => {
    const index = groupedCaptions.findIndex((c) => c.id === id)
    if (index > -1) {
      groupedCaptions.splice(index, 1)
    }
  }

  const splitCaption = (timeMs: number) => {
    const focusStore = useEditorFocusStore()
    const caption = groupedCaptions.find((c) => c.start <= timeMs && c.end >= timeMs)
    if (!caption) return
    const index = groupedCaptions.findIndex((c) => c.id === caption.id)
    if (index < 0) return
    const rightWords = caption.words.filter((w) => w.start >= timeMs || w.end >= timeMs)
    const leftWords = caption.words.filter((w) => w.end < timeMs)
    if (rightWords.length === 0) rightWords.push(caption.words[caption.words.length - 1])
    if (leftWords.length === 0) leftWords.push(caption.words[0])
    const newCaption = {
      ...caption,
      id: v4(),
      start: timeMs,
      words: rightWords,
    }
    caption.end = timeMs
    caption.words = leftWords
    groupedCaptions.splice(index + 1, 0, newCaption)
    focusStore.setFocus('caption', newCaption.id)
  }

  const hasFeature = (feature: CaptionFeatures) => {
    return computed(() => featuresPerLanguage[selectedLanguage.value]?.includes(feature) ?? false)
  }

  const resetPositionScale = () => {
    // Initial position of the Captions
    captionsWrapper.value = defaultCaptionsWrapper
  }

  const captionOverride = ref<StoredCaption[]>()

  const captions = computed(() => {
    return captionOverride.value ?? groupedCaptions
  })

  const captionsGenerated = computed(() => {
    return !!captionsDocument.value || groupedCaptions.length > 0
  })

  function $reset() {
    captionsDocument.value = null
    groupedCaptions.splice(0, groupedCaptions.length)
    captionsWrapper.value = defaultCaptionsWrapper
    captionStyle.value = 'lit'
  }

  const getLoggingData = () => {
    return {
      CaptionCount: captions.value.length ?? 0,
      CaptionStyle: captionStyle.value,
      CaptionLanguage: selectedLanguage.value,
      CaptionDisplayMethod: baseOptions.grouping,
    }
  }

  // Used to open the bottom drawer on mobile.
  const isEditingOnMobile = ref(false)

  const captionsSettings = computed<CaptionsSettings>({
    get: () => ({
      style: captionStyle.value,
      fontSize: baseOptions.size,
      languageCode: selectedLanguage.value,

      animation: {
        style: baseOptions.animation,
        target: baseOptions.animationTarget,
      },

      color: styleOptions.data?.baseColor,
      highlights: {
        enabled: baseOptions.highlight,
        color: styleOptions.data?.highlightColor,
      },

      emojis: {
        enabled: baseOptions.emojis,
        position: baseOptions.emojiLocation,
      },

      grouping: baseOptions.grouping,
      stripPunctuation: baseOptions.stripPunctuation,
      rotate: baseOptions.rotate,
    }),
    set: (settings) => {
      captionStyle.value = settings.style

      baseOptions.size = settings.fontSize

      baseOptions.animation = settings.animation.style
      baseOptions.animationTarget = settings.animation.target

      baseOptions.highlight = settings.highlights.enabled

      baseOptions.emojis = settings.emojis.enabled
      baseOptions.emojiLocation = settings.emojis.position

      baseOptions.grouping = settings.grouping
      baseOptions.stripPunctuation = settings.stripPunctuation
      baseOptions.rotate = settings.rotate

      styleOptions.data = {
        baseColor: settings.color,
        highlightColor: settings.highlights.color,
      }
    }
  });

  return {
    isEditingOnMobile,
    captionsDocument,
    addCaption,
    createCaption,
    captions,
    captionPreset,
    captionStyleSettings,
    captionsWrapper,
    groupedCaptions,
    hasCaptions,
    baseOptions,
    styleOptions,
    selectedLanguage,
    setCaptionsDocument,
    resetPositionScale,
    deleteCaption,
    updateCaption,
    captionsGenerated,
    captionStyle,
    hasFeature,
    getLoggingData,
    splitCaption,
    captionOverride,
    noDefaultOptions: !storedData,
    $reset,

    captionsSettings: captionsSettings
  }
})

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorCaptionsStore, import.meta.hot))
}
