<script setup lang="ts">
import { ref, computed } from 'vue';
import { useElementSize } from '@vueuse/core';
defineProps<{ days: number; hours: number; minutes: number; }>()

const container = ref<HTMLDivElement | null>(null);
const { width: containerWidth } = useElementSize(container);
const fontSize = computed(() => 0.04 * containerWidth.value + 'px');
</script>

<template>
  <div class="relative w-full overflow-hidden rounded-2xl aspect-[6/1]" ref="container">
    <slot />
    <div :style="{ fontSize }" class="grid leading-[1] pointer-events-none overflow-hidden font-black font-title place-items-center absolute aspect-[20/19] rounded-lg w-[6.1%] left-[40.45%] top-[42.5%] -translate-y-1/2 -translate-x-1/2">
      {{ days }}
    </div>
    <div :style="{ fontSize }" class="grid leading-[1] pointer-events-none overflow-hidden font-black font-title place-items-center absolute aspect-[20/19] rounded-lg w-[6.1%] left-[50%] top-[42.5%] -translate-y-1/2 -translate-x-1/2">
      {{ hours }}
    </div>
    <div :style="{ fontSize }" class="grid leading-[1] pointer-events-none overflow-hidden font-black font-title place-items-center absolute aspect-[20/19] rounded-lg w-[6.1%] left-[59.55%] top-[42.5%] -translate-y-1/2 -translate-x-1/2">
      {{ minutes }}
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
