<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getApiTwitchClipsClipId } from '@/apis/streamladder-api/twitch-clips/twitch-clips'
import { getApiKickClipsClipId } from '@/apis/streamladder-api/kick-clips/kick-clips'
import { useEditorClipInfoStore, type ClipInfo } from '@/store/editor/editorClipInfo'
import AppPage from '@/components/AppPage.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { downloadYoutubeClip } from '@/Hooks/useYoutubeClip'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useGetUploadedVideosQuery } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import { onUserInfoReadyAsync } from '@/store/user/userInfo'
import { metadataService } from '@/services/metadataService'
import { getFileFromStorage } from '@/components/Dialog/MultiUploadDialog/file-uploads/_storage'
import RadixErrorDialog from '@/components/Dialog/RadixErrorDialog.vue'
import { Button } from '@/components/ui/button'
import { getTwitchVodClipInfo } from '@/Hooks/useTwitchVod'

type SupportedSources = 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'local-file'

const props = defineProps<{
  source: SupportedSources
  clipId: string
}>()

const editorClipInfoStore = useEditorClipInfoStore()

const clipStatus = ref<'loading' | 'error' | 'success'>('loading')

const emptyResponse = {
  id: null,
  mp4Url: null,
  title: null,
}

const errorDialogOpen = ref(false)

function clipDoesNotExist() {
  clipStatus.value = 'error'
  errorDialogOpen.value = true
}

const blockedDialogOpen = ref(false)
function clipBlockedError() {
  clipStatus.value = 'error'
  blockedDialogOpen.value = true
}

const loadingText = ref('Searching for your clip')
const router = useRouter()

function resetClip() {
  clipStatus.value = 'loading'
  router.push({ name: 'ClipEditor' })
}

async function fetchClipInfo() {
  try {
    const source = props.source

    let response
    if (source === 'twitch-clip') {
      loadingText.value = 'Importing Twitch clip'
      response = (await getApiTwitchClipsClipId(props.clipId)) as unknown as ClipInfo
    } else if (source === 'twitch-vod') {
      loadingText.value = 'Importing Twitch stream highlight'
      response = await getTwitchVodClipInfo(props.clipId)
    } else if (source === 'youtube-clip') {
      loadingText.value = 'Importing YouTube clip'
      response = (await downloadYoutubeClip(props.clipId)) as unknown as ClipInfo
      if (response.title == null) {
        response.title = 'YouTube clip'
      }
    } else if (source === 'kick-clip') {
      loadingText.value = 'Importing Kick clip'
      response = (await getApiKickClipsClipId(props.clipId)) as unknown as ClipInfo
    } else if (source === 'local-file') {
      loadingText.value = 'Searching for uploaded video'
      response = (await handleLocalFile(props.clipId)) as unknown as ClipInfo
    }

    await new Promise((resolve) => setTimeout(resolve, 500))

    if (response?.mp4Url === null || response?.mp4Url === undefined || response?.mp4Url === '') {
      editorClipInfoStore.setClip(emptyResponse)
      clipDoesNotExist()
    } else {
      editorClipInfoStore.setClip({
        ...response,
        source: source,
      })
    }
  } catch (e) {
    if (e?.toString()?.includes('blocked_video')) {
      clipBlockedError()
    } else {
      clipDoesNotExist()
    }
  } finally {
    clipStatus.value = 'success'
  }
}

const { uploadedVideos, suspense } = useGetUploadedVideosQuery()
const route = useRoute()

async function fetchPreviousUpload() {
  const userInfo = await onUserInfoReadyAsync()
  if (!userInfo.isAuthenticated) return null

  await suspense()
  const previousUpload = uploadedVideos.value.find((upload) => upload.id === route.params.clipId)
  if (!previousUpload) return null

  // Validate if the video is playable
  const canPlay = await metadataService.canPlayUrl(previousUpload.videoUrl)
  if (!canPlay) return null

  return {
    id: previousUpload.id,
    title: previousUpload.name,
    mp4Url: previousUpload.videoUrl,
    slug: previousUpload.name,
    isLocalFile: true,
    source: 'local-file',
  }
}

async function handleLocalFile(blobGuid: string) {
  const fileData = await getFileFromStorage(route.params.clipId as string)

  // No file data, so we can't use the file
  if (fileData == null) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    loadingText.value = 'Importing recent uploads'
    const upload = await fetchPreviousUpload()
    if (upload) {
      return upload
    } else {
      console.error('No file data found')
      return emptyResponse
    }
  }

  // Start refetching previous uploads to determine if upload needs be resumed.
  // Not awaited because the switch should be (mostly) seamless and user might not be logged in.
  const fileUploads = useFileUploads()
  fetchPreviousUpload().then((upload) => {
    if (upload) {
      return upload as unknown as ClipInfo
    } else {
      fileUploads.resume(blobGuid)
    }
  })

  const clipUrl = URL.createObjectURL(fileData)
  const fileUrlFunctions = await doesFileExist(clipUrl)

  // the file url exists, so we can use it
  if (fileUrlFunctions) {
    // Start uploading the clip if the file comes from local storage.
    return {
      id: blobGuid,
      title: fileData.name.split('.').slice(0, -1).join('.'),
      mp4Url: clipUrl,
      slug: fileData.name,
      isLocalFile: true,
      source: 'local-file',
    }
  }

  // the file url does not exist, so we can't use it
  // create new blobUrl from localforage data
  const blobUrl = URL.createObjectURL(fileData)
  const _blobGuid = blobUrl.split('/').pop() ?? blobUrl
  return {
    id: _blobGuid,
    title: fileData.name,
    mp4Url: blobUrl,
    slug: fileData.name,
    isLocalFile: true,
    source: 'local-file',
  }
}

async function doesFileExist(objectUrl: string) {
  try {
    return (
      await fetch(objectUrl).catch((e) => {
        throw new Error(e)
      })
    ).ok
  } catch (e) {
    return false
  }
}

const show = ref(false)
const isStarting = ref(false)
onMounted(async () => {
  isStarting.value = true
  if (typeof route.params.clipId === 'string' && typeof route.params.source === 'string') {
    editorClipInfoStore.$reset()
    await router.replace({ name: 'editor/[clipSource]/[clipId]', params: { clipSource: route.params.source, clipId: route.params.clipId } })
  }
})
</script>

<template>
  <div v-if="show">
    <router-view v-if="!editorClipInfoStore.isLoadingClip || source === 'twitch-clip'"></router-view>
    <app-page v-else content-container-class="justify-center">
      <template #content>
        <div v-if="props.source === 'youtube-clip'" class="flex w-full flex-col p-8">
          <lottie-animation url="/lottie/search-youtube.json" class="mx-auto w-1/3" :auto-play="true" />
          <p class="mb-0 text-center font-bold text-youtube">One moment</p>
          <p class="text-center text-gray-800">Importing YouTube Clip</p>
        </div>
        <transition
          appear
          v-else
          mode="out-in"
          enter-from-class="translate-y-12 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          enter-active-class="transition-[transform,_opacity]"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="-translate-y-12 opacity-0"
          leave-active-class="transition-[transform,_opacity]"
        >
          <div class="grid h-full w-full grid-cols-1 place-items-center">
            <LottieAnimation url="/lottie/searching-clip.json" class="w-full max-w-md" :auto-play="true" />
            <p class="text-2xl font-semibold text-black">One moment</p>
            <transition
              appear
              mode="out-in"
              enter-from-class="translate-x-12 opacity-0"
              enter-to-class="translate-x-0 opacity-100"
              enter-active-class="transition-[transform,_opacity]"
              leave-from-class="translate-x-0 opacity-100"
              leave-to-class="-translate-x-12 opacity-0"
              leave-active-class="transition-[transform,_opacity]"
            >
              <p class="text-center text-gray-800" :key="loadingText">{{ loadingText }}</p>
            </transition>
            <span class="sr-only">Loading...</span>
          </div>
        </transition>
      </template>
    </app-page>
  </div>
  <RadixErrorDialog v-model:open="errorDialogOpen">
    <template #title>Something went wrong</template>
    <template #description>
      Unfortunately, your clip could not be found. Please try another clip or use the 'From Twitch' button.
    </template>
    <template #actions>
      <Button variant="primary" @click="resetClip">Change video</Button>
    </template>
  </RadixErrorDialog>
  <RadixErrorDialog v-model:open="blockedDialogOpen">
    <template #title>Region blocked 🌎</template>
    <template #description>
      Unfortunately, this clip has been blocked in the USA based on copyright grounds. Please try another clip or use the 'From Twitch' button.
    </template>
    <template #actions>
      <Button variant="primary" @click="resetClip">Change video</Button>
    </template>
  </RadixErrorDialog>
</template>

<style></style>
