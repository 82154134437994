<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { ColorInput } from '@/components/colors'
import { Switch } from '@/components/ui/switch'
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { capitalize, ref } from 'vue'

const captionsStore = useCaptionsStore()
const historyStore = useHistoryStore()

const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors();

const bleepCurseWords = ref('false');

const toggleEmojis = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_EMOJIS', () => {
    captionsStore.baseOptions.emojis = !captionsStore.baseOptions.emojis
  });
};

const toggleRotation = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_ROTATION', () => {
    captionsStore.baseOptions.rotate = !captionsStore.baseOptions.rotate
  });
};

const toggleStripPunctuation = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_STRIP_PUNCTUATION', () => {
    captionsStore.baseOptions.stripPunctuation = !captionsStore.baseOptions.stripPunctuation
  });
};

const animationGroupingModel = computed({
  get() {
    return captionsStore.baseOptions.grouping
  },
  set(value) {
    historyStore.transaction('CAPTIONS:CHANGE_ANIMATION_GROUPING', () => {
      captionsStore.baseOptions.grouping = value
    })
  }
});

const updateTiming = (value: string | null | undefined) => {

  historyStore.transaction('CAPTIONS:CHANGE_ANIMATION_TIMING', () => {
    if (value === 'single') {
      captionsStore.baseOptions.animationTarget = 'word';
    } else if (value === 'group' && captionsStore.baseOptions.animationTarget === 'word') {
      captionsStore.baseOptions.highlight = false;
    }
  });
};

const highlightModel = computed({
  get() {
    return captionsStore.baseOptions.highlight
  },
  set(value) {
    historyStore.transaction('CAPTIONS:TOGGLE_HIGHLIGHT', () => {
      captionsStore.baseOptions.highlight = value
    })
  }
})

const highlightColorModel = computed({
  get() {
    return captionsStore.baseOptions.highlightColor
  },
  set(value) {
    captionsStore.baseOptions.highlightColor = value
  }
})
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="flex flex-col gap-4 my-4">

    <div class="flex flex-col gap-2 px-4">

      <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer" @click="toggleRotation">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Random rotation</h3>
            <p class="text-sm text-muted-foreground font-light">Give each caption a slight and random rotation.</p>
          </div>

          <Switch v-model:checked="captionsStore.baseOptions.rotate" @click.stop />
        </header>
      </section>
    </div>

    <div class="flex flex-col gap-2 px-4">
      <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer" @click="toggleStripPunctuation">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Hide punctuation</h3>
            <p class="text-sm text-muted-foreground font-light">Remove all ".?!" from captions</p>
          </div>

          <Switch v-model:checked="captionsStore.baseOptions.stripPunctuation" @click.stop />
        </header>
      </section>
    </div>

    <div class="flex flex-col gap-4 px-4">
      <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Display grouping</h3>
            <p class="text-sm text-muted-foreground font-light">Display each word individually or group words into sentences.</p>
          </div>
        </header>

        <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
          <RadioToggleButton
            v-for="option in [{ label: 'Words', value: 'single' }, { label: 'Sentences', value: 'group' }]"
            :key="option.value" :value="option.value"
            v-model="animationGroupingModel"
            @update:model-value="value => updateTiming(value)"
            class="flex-col relative font-light hover:bg-zinc-200 data-[state=active]:font-semibold data-[state=active]:bg-primary data-[state=active]:text-white !border-transparent bg-transparent shrink-0 flex-1"
          >
            <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
            <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
          </RadioToggleButton>
        </footer>
      </section>

      <Transition
        enter-from-class="opacity-0 -translate-y-2"
        leave-to-class="opacity-0 -translate-y-2"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <section v-if="captionsStore.baseOptions.grouping !== 'single' && captionsStore.baseOptions.animation !== '' && captionsStore.baseOptions.animation !== 'fade-right'" class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
          <header class="flex items-start justify-between gap-2 cursor-pointer">
            <div class="flex flex-col gap-0">
              <h3 class="font-semibold text-lg">Animation timing</h3>
              <p class="text-sm text-muted-foreground font-light">Choose when to animate your captions.</p>
            </div>
          </header>

          <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
            <RadioToggleButton
              v-for="option in [{ label: 'Word by word', value: 'word' }, { label: 'Sentence by sentence', value: 'sentence' }]"
              :key="option.value" :value="option.value"
              @update:model-value="updateTiming"
              v-model="captionsStore.baseOptions.animationTarget"
              class="truncate text-xs 2xl:text-base flex-col relative font-light hover:bg-zinc-200 data-[state=active]:font-semibold data-[state=active]:bg-primary data-[state=active]:text-white !border-transparent bg-transparent shrink-0 flex-1"
            >
              <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
              <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
            </RadioToggleButton>
          </footer>
        </section>
      </Transition>
      <Transition
        enter-from-class="opacity-0 -translate-y-2"
        leave-to-class="opacity-0 -translate-y-2"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <section v-if="captionsStore.baseOptions.grouping !== 'single'" class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
          <header class="flex items-start justify-between gap-2 cursor-pointer" @click="captionsStore.baseOptions.highlight = !captionsStore.baseOptions.highlight">
            <div class="flex flex-col gap-0">
              <h3 class="font-semibold text-lg">Highlight current spoken word</h3>
              <p class="text-sm text-muted-foreground font-light">Automatically highlight the current spoken word in your captions.</p>
            </div>

            <Switch v-model:checked="highlightModel" @click.stop />
          </header>
          <footer v-if="captionsStore.baseOptions.highlight">
            <ColorInput
              v-model="highlightColorModel"
              class="w-full"
              side="right"
              :recently-used-colors="recentlyUsedCaptionColors"
              :recommended-colors="recommendedColors.LIGHT"
              @commit-value="historyStore.transaction('CAPTIONS:CHANGE_HIGHLIGHT_COLOR')"
              @close="(color) => setRecentlyUsedColor(color)"
            />
          </footer>
        </section>
      </Transition>
    </div>
  </div>

  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="flex flex-col gap-2 select-none mt-4 px-4">

    <div v-if="captionsStore.baseOptions.emojis" class="flex flex-col gap-2 px-4">
      <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer" @click="toggleEmojis">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Add emojis automatically</h3>
            <p class="text-sm text-muted-foreground font-light">Magically add emojis to captions. ✨</p>
          </div>
  
          <Switch v-model:checked="captionsStore.baseOptions.emojis" @click.stop />
        </header>
      </section>
    </div>

    <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
      <div class="flex flex-col gap-2 justify-between max-h-[300px] mt-1">
        <div class="flex flex-row gap-2">
          <h3 class="font-semibold text-lg">Special Effects ✨</h3>
        </div>
        <p class="text-sm text-muted-foreground font-light">Bleep curse words, add sound effects, animated emojis and more to your captions!</p>
      </div>

      <div class="my-1 h-px bg-surface-panel-border" />

      <div class="relative">

        <section class="flex flex-col gap-2 p-3 rounded-xl bg-surface-panel">
          <header class="flex items-start justify-between gap-2 cursor-pointer">
            <div class="flex flex-col gap-0">
              <h3 class="font-semibold text-lg">Bleep curse words</h3>
              <p class="text-sm text-muted-foreground font-light">Automatically detect curse words and emit a sound effect instead</p>
            </div>
          </header>

          <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
            <RadioToggleButton
              v-for="option in [{ label: 'Disabled', value: 'false' }, { label: 'Enabled', value: 'true' }]"
              :key="option.value"
              :value="option.value"
              v-model="bleepCurseWords"
              class="flex-col relative font-light hover:bg-zinc-200 data-[state=active]:font-semibold data-[state=active]:bg-primary data-[state=active]:text-white !border-transparent bg-transparent shrink-0 flex-1"
            >
              <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
              <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
            </RadioToggleButton>
          </footer>
        </section>

        <div class="mt-4 flex flex-col gap-2 p-2">
          <audio class="h-8 w-full" controls src="" />
          <audio class="h-8 w-full" controls src="" />
          <audio class="h-8 w-full" controls src="" />
        </div>

        <div class="top-0 backdrop-blur-sm rounded-xl absolute w-full h-full flex items-center justify-center">
          <span class="rotate-6 rounded font-semibold text-xl uppercase px-2 py-1 bg-green-700 text-background -mt-0.5 cursor-default user-select-none">
            Coming soon!
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">

</style>
