/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { GetApiYouTubeClipsClipIdParams } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiYouTubeClipsClipId = (
  clipId: MaybeRef<string>,
  params?: MaybeRef<GetApiYouTubeClipsClipIdParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  clipId = unref(clipId)
  params = unref(params)

  return streamLadderAxiosInstance<void>(
    { url: `/api/YouTubeClips/${clipId}`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiYouTubeClipsClipIdQueryKey = (
  clipId: MaybeRef<string>,
  params?: MaybeRef<GetApiYouTubeClipsClipIdParams>
) => {
  return ['api', 'YouTubeClips', clipId, ...(params ? [params] : [])] as const
}

export const getGetApiYouTubeClipsClipIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>,
  TError = unknown
>(
  clipId: MaybeRef<string>,
  params?: MaybeRef<GetApiYouTubeClipsClipIdParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiYouTubeClipsClipIdQueryKey(clipId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>> = ({ signal }) =>
    getApiYouTubeClipsClipId(clipId, params, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(clipId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>, TError, TData>
}

export type GetApiYouTubeClipsClipIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>>
export type GetApiYouTubeClipsClipIdQueryError = unknown

export const useGetApiYouTubeClipsClipId = <
  TData = Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>,
  TError = unknown
>(
  clipId: MaybeRef<string>,
  params?: MaybeRef<GetApiYouTubeClipsClipIdParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiYouTubeClipsClipIdQueryOptions(clipId, params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
