<script setup lang="ts">
import type { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import type { ValueOf } from 'ts-essentials'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
  route?: ValueOf<typeof dashboardRouteNames> | string
  path?: boolean
  href?: string
  isActive?: boolean
}>()

const router = useRouter()
const to = computed(() => {
  const route = props.route ? { name: props.route as string } : undefined
  if (route) {
    return props.path ? { path: router.resolve(route).path } : route
  } else {
    return null
  }
})
</script>

<template>
  <RouterLink
    v-if="to"
    :to="to"
    class="flex flex-nowrap items-center gap-4 overflow-x-hidden rounded-lg px-4 py-2 text-brand-state-text-primary hover:bg-zinc-100 dark:hover:bg-zinc-400 group shrink-0 whitespace-nowrap"
    :active-class="path ? 'bg-primary !text-white hover:!bg-primary/90' : ''"
    :exact-active-class="!path ? 'bg-primary !text-white hover:!bg-primary/90 pointer-events-none' : ''"
  >
    <slot />
  </RouterLink>
  <a
    v-else-if="href"
    :href="href"
    class="flex flex-nowrap items-center gap-4 overflow-x-hidden rounded-lg px-4 py-2 text-brand-state-text-primary hover:bg-zinc-100 dark:hover:bg-zinc-400 group shrink-0 whitespace-nowrap"
    target="_blank"
  >
    <slot />
  </a>
  <button
    v-else
    class="flex flex-nowrap items-center gap-4 overflow-x-hidden rounded-lg px-4 py-2 text-brand-state-text-primary hover:bg-zinc-100 dark:hover:bg-zinc-400 group shrink-0 whitespace-nowrap"
    :class="{ 'bg-primary !text-white hover:!bg-primary/90': isActive }"
  >
    <slot />
  </button>
</template>

<style scoped lang="scss"></style>
