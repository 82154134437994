<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { getSession } from '@/authentication/supabase'
import { Button } from '@/components/ui/button'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { useTheme } from '@/Hooks/useTheme'
import { useHead } from '@unhead/vue'
import { useRoute } from 'vue-router'

const session = ref<Awaited<ReturnType<typeof getSession>> | null>(null)
const route = useRoute()
const hasError = ref<boolean>(false) 
const twitchEmailError = ref<boolean>(false)

onMounted(async () => {

  session.value = await getSession()

  if (route.query.error) {
    hasError.value = true
    if (route.query.error_description === 'Error getting user email from external provider') {
      twitchEmailError.value = true
    }
    
  } else {
    // Authentication successful, close the window
    close()
  }

})

function close() {
  window.close()
}

const { theme } = useTheme()
useHead({
  bodyAttrs: {
    class: [theme],
  }
})
</script>

<template>
  <main class="w-[100dvw] h-[100dvh] grid place-items-center layer-0">
    <div v-if="session" class="flex flex-col items-center gap-4">
      <div v-if="hasError">
        <div class="flex flex-col items-center gap-2">
          <LottieAnimation url="/lottie/error.json" :loop="false" class="w-24 h-24" />
          <h1 class="text-lg font-bold">Authentication error</h1>
        </div>
        <div v-if="twitchEmailError" class="flex flex-col gap-2 items-center font-light max-w-lg text-center">
          <!-- Since Supabase required an email address, we need to inform the users -->
          <p class="font-bold">Email Required</p>

          <p>Your Twitch account doesn't have an email. Please <a href="https://www.twitch.tv/settings/security" target="_blank">add an email</a> to continue, or log in with Google.</p>
          <p>
            <Button href="https://www.twitch.tv/settings/security" as="a" target="_blank">
              Add email at Twitch
            </Button>
          </p>

        </div>
        <div v-else class="flex flex-col gap-2 items-center font-light max-w-lg text-center">
          <p>{{ session.error?.message ?? 'Something went wrong. Please try again later.' }}</p>
        </div>
      </div>
      <template v-else>
        <div class="flex flex-col items-center gap-2">
          <LottieAnimation url="/lottie/success.json" :loop="false" class="w-24 h-24" />
          <h1 class="text-lg font-bold">Welcome back!</h1>
        </div>
        <div class="flex flex-col gap-2 items-center font-light max-w-lg text-center">
          <p class="text-sm opacity-50">
            This window will close automatically. If it isn't closed in a few seconds, you can close it manually.
          </p>
        </div>
      </template>
      <Button @click="close()" variant="outline">
        Close
      </Button>
    </div>
    <div v-else class="flex flex-col items-center gap-4">
      <div class="w-24 h-24 animate-spin border-8 border-brand-state-active-border border-t-transparent rounded-full" />
      <h1 class="text-3xl font-bold">You're being logged in...</h1>
    </div>
  </main>
</template>

<style scoped lang="scss">

</style>
