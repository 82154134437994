<script setup lang="ts">
import type { VideoResultDto } from '@/apis/streamladder-api/model';

const props = defineProps<{
  clip: VideoResultDto,
}>();

const emit = defineEmits<{
  (event: 'clipClicked', clip: VideoResultDto): void,
}>();

function replaceByDefault(e: Event) {
  (e.target as HTMLImageElement).src = '/images/images/thumbnail.jpg';
}

function clipClicked() {
  emit('clipClicked', props.clip);
}
</script>

<template>
  <div class="relative flex w-full flex-col items-center justify-center aspect-[9/16] rounded-xl cursor-pointer border-2 border-transparent hover:border-brand-state-hover-border overflow-hidden group transition-colors" @click.prevent="clipClicked">
    <div class="flex flex-1 items-center justify-center bg-black aspect-[9/16]">
      <video disableRemotePlayback disablePictureInPicture muted
        v-if="!clip.thumbnailUrl"
        class="w-full group-hover:scale-110 duration-75 transition-transform"
        :src="clip.resultUrl!" 
      ></video>
      <img v-else class="group-hover:scale-110 duration-75 transition-transform w-full" :src="clip.thumbnailUrl!" @error="replaceByDefault" />
    </div>

  </div>
</template>

<style scoped></style>
