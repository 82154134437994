import type { RouteRecordRaw } from 'vue-router'
import { editorRouteNames } from '@/areas/editor/routeNames'
import EditorLayout from '@/areas/editor/pages/EditorLayout.vue';
import EditorUploadPage from '@/areas/editor/pages/EditorUploadPage.vue';
import EditorStartupPage from '@/areas/editor/pages/EditorStartupPage.vue';
import EditorProjectPage from '@/areas/editor/pages/EditorProjectPage.vue';

export const routes: RouteRecordRaw[] = [
  {
    name: editorRouteNames.root,
    path: '/editor',
    component: EditorLayout,
    children: [
      {
        name: 'editor',
        path: '/editor',
        component: EditorUploadPage,
      },
      {
        name: 'editor/[clipSource]/[clipId]',
        path: '/editor/:clipSource(twitch-clip|twitch-vod|youtube-clip|kick-clip|kick-cx-clip|local-file)/:clipId',
        component: EditorStartupPage,
      },
      {
        name: 'editor/[projectId]',
        path: '/editor/:projectId',
        component: EditorProjectPage
      }
    ]
  }
]
