<script setup lang="ts">
import textLibrary, { type TextLibraryItem } from '@/components/Stickers/textLibrary'
import { isNewText, lastUsedTextFirst } from '@/helpers/isNewOverlayElement'
import { ref, computed, onMounted, watch } from 'vue'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { ScrollArea } from '@/components/ui/scroll-area'
import BrandKitStyleWrapper from '@/areas/editor/views/text/brand-kit/BrandKitStyleWrapper.vue'
import StickerWrapper from '@/areas/editor/views/stickers/StickerWrapper.vue'
import { useStickersStore } from "@/areas/editor/store/useStickersStore";
import { useHistoryStore } from "@/areas/editor/store/useHistoryStore";
import type { TypedSticker } from "@/areas/editor/@type/Project";
import logging from "@/logging";
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { Button } from '@/components/ui/button'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useScreen } from '@/Hooks/useScreen'
import { getLastUsedTextStickerKey, setLastUsedTextStickerKey } from '@/areas/editor/views/elements/useElementsSettings'

const tags = ['Default', 'Intro', 'Outro',] as const
const selectedTag = ref<typeof tags[number]>('Default')

const getHtmlContent = computed(() => (index: number) => {
  return previewTexts[selectedTag.value][index % previewTexts[selectedTag.value].length]
})

const previewTexts = {
  Default: ['Enter Text\n here'],
  Outro: [
    'Link in the\n description 👇',
    'What did you think?\n Let me know',
    'Did you agree?\n 👍',
    'Did you disagree? 👎',
    'Like, subscribe\n and hit that bell\n for more ❤️',
    'This was CRAZY\n 😨',
    'This was SO fun\n 😇',
    'Share this\n with your friends',
    'Follow for more!',
    'Subscribe for more!',
    'Comment below💬',
    'My mind\n was BLOWN🤯',
  ],
  Intro: [
    'Stay till the end\n for a surprise',
    'What happens next\n is crazy!',
    "That's what I call\n a plottwist",
    'How did\n this happen?!',
    'I did NOT\n expect this',
    'I was shocked\n after this',
    'This will\n change your\n perspective ',
    'Wait for it...',
  ],
  'Fill in': [
    'The top 5\n [...]',
    'Imagine if you could \n (DESIRED RESULT)',
    'Why doesn’t \n anyone talk about \n (RELATED TO NICHE)?',
    'What if you could\n (DESIRED RESULT) \nwithout\n (PAIN POINT OF TARGET AUDIENCE)?',
    'Are you struggling with \n(PAIN POINT OF TARGET AUDIENCE)? \nThen try this',
    'I tried every\n (HACK/TIP/TRICK),\n so you don’t have to',
    '(HACK/TIP/TRICK) \nI wish I knew earlier',
    'These 3 (HACK/TIP/TRICK)\n feel illegal to know',
    'Did you know that\n (FACT)',
    'Fun fact:\n (FACT)',
  ],
}

const stickerText = ref('')

const brandKitStore = useUserBrandkitStore()

const rescaleStickers = () => {
  if (!stickerWrappers.value) {
    return
  }
  stickerWrappers.value.forEach((stickerWrapper) => {
    stickerWrapper.scaleSticker?.()
  })
}

const stickerWrappers = ref([])

onMounted(async () => {
  rescaleStickers()
  await brandKitStore.list()
})

watch(stickerText, (newValue) => {
  if (newValue.length > 0) {
    rescaleStickers()
  }
})

const stickersStore = useStickersStore()
const historyStore = useHistoryStore()
const editorFocusStore = useEditorFocusStore()

async function select(sticker: TypedSticker<'text' | 'brand-kit'>) {
  switch (sticker.type) {
    case 'text':
      return selectTextSticker(sticker)
    case 'brand-kit':
      return selectBrandKitSticker(sticker)
    default:
      throw new Error(`Unknown sticker type: ${sticker.type}`)
  }
}

async function selectTextSticker(sticker: TypedSticker<'text'>) {

  const id = stickersStore.createTextSticker(sticker)

  editorFocusStore.setFocus(FocusTypes.STICKER, id)

  setLastUsedTextStickerKey(sticker.key);

  logging.trackEvent('Editor TextSticker Added', {
    Variant: sticker.variant,
    Color: sticker.color,
    Tags: textLibrary.find(t => t.key === sticker.key)?.tags ?? [],
  })
}

async function selectBrandKitSticker(sticker: TypedSticker<'brand-kit'>) {

  const id = stickersStore.createBrandKitSticker(sticker)
  editorFocusStore.setFocus(FocusTypes.STICKER, id)

  setLastUsedTextStickerKey(sticker.id);

  logging.trackEvent('Editor Brandkit TextSticker Added', {
    Variant: sticker.variant,
    Color: sticker.primaryColor,
    Tags: [],
  })
}

const isLastUsedTextStickerCustom = () => {
  return brandKitStore.brandKitStyles.some((style) => style.id === getLastUsedTextStickerKey())
};

const { currentSection } = useEditorStep()
const viewAll = () => {
  currentSection.value = editorRouteNames.text
};

const is2Xl = useScreen('2xl')
const maxItems = computed(() => {
  return is2Xl.value ? 6 : 3;
});

const filteredTextLibrary = ref<TextLibraryItem[] | null>(null);

onMounted(() => {
  filteredTextLibrary.value = textLibrary
    .sort(lastUsedTextFirst)
    .slice(0, isLastUsedTextStickerCustom() ? maxItems.value - 1 : maxItems.value);
})

watch([currentSection, isLastUsedTextStickerCustom], () => {
  filteredTextLibrary.value = textLibrary
    .sort(lastUsedTextFirst)
    .slice(0, isLastUsedTextStickerCustom() ? maxItems.value - 1 : maxItems.value);
}, { immediate: true });
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row gap-2 justify-between items-center">
      <h2 class="text-base font-semibold leading-snug">Text stickers</h2>
      <Button variant="link" class="font-semibold lg:text-sm p-0 h-full" @click="viewAll">
        View all
      </Button>
    </div>

    <ScrollArea>
      <div class="notranslate grid gap-2 grid-cols-[repeat(auto-fill,_minmax(100px,_1fr))] lg:grid-cols-[repeat(auto-fill,_minmax(90px,_1fr))] 2xl:grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))]">

        <template v-if="isLastUsedTextStickerCustom">
          <BrandKitStyleWrapper
            v-for="(style, index) in brandKitStore.brandKitStyles.filter(s => s.id === getLastUsedTextStickerKey())"
            :key="style.id + selectedTag + getHtmlContent(index)"
            :text-content="getHtmlContent(index)"
            :moment="selectedTag.toLowerCase() as 'default' | 'intro' | 'outro'"
            @select="(s) => select(s as TypedSticker<'brand-kit'>)"
            ref="stickerWrappers"
            :style="style"
          />
        </template>

        <StickerWrapper
          ref="stickerWrappers"
          v-for="(sticker, index) in filteredTextLibrary"
          :key="sticker.key + (index + brandKitStore.brandKitStyles.length) + selectedTag + getHtmlContent(index + brandKitStore.brandKitStyles.length)"
          :moment="selectedTag.toLowerCase()"
          :stickerMeta="sticker"
          :is-new="isNewText(sticker)"
          :editable="true"
          :text-content="getHtmlContent(index + brandKitStore.brandKitStyles.length)"
          @select="(s) => select(s as TypedSticker<'text'>)"
        />
      </div>
    </ScrollArea>
  </div>
</template>

<style scoped lang="scss">

</style>
