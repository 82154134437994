<script setup lang="ts">
import { computed, ref } from 'vue'
import { useMouseInElement, useParentElement } from '@vueuse/core'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import { findClosestFittingLocation } from '@/modules/SLVideoplayer/helpers'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'

const captionsStore = useCaptionsStore()

const { pxToMs, msToPx, durationMs, dragging } = useTimelineStore()

const parentEl = useParentElement()
const { isOutside, elementX } = useMouseInElement(parentEl)

const width = ref(20)
const popupOpen = ref(false)
let storedLastPosition: { point: number; distance: number } | undefined

const positionOnTimeline = computed(() => {

  if (popupOpen.value && storedLastPosition != undefined) {
    return storedLastPosition
  }

  const nodes = captionsStore.entities.map((caption) => {

    const firstWord = caption.words[0];
    const lastWord = caption.words[caption.words.length - 1];

    return {
      start: msToPx(firstWord.start, true),
      end: msToPx(lastWord.end, true),
    };
  });

  const closestFittingLocation = findClosestFittingLocation(
    elementX.value,
    width.value,
    0,
    msToPx(durationMs.value),
    nodes
  )

  // reset storedLastPosition if the popover is closed
  storedLastPosition = undefined

  return (
    closestFittingLocation || {
      point: elementX.value,
      distance: 0,
    }
  )
})

const left = computed(() => positionOnTimeline.value.point)

const addNewCaption = async () => {

  const _durationMs = pxToMs(width.value, true)
  const startMs = pxToMs(left.value, true) - (_durationMs / 2);
  const endMs = startMs + pxToMs(width.value, true);

  captionsStore.addCaption(startMs, endMs);
};
</script>

<template>
  <button
    ref="node"
    class="peer h-full absolute rounded-lg border-none bg-company-primary-300 hover:bg-company-primary-200 ring-company-primary-300] text-white"
    @click="addNewCaption"
    v-if="popupOpen || (!isOutside && positionOnTimeline.distance <= 0 && dragging?.type !== 'node')"
    :style="{
      left: `${left}px`,
      transform: `translateX(-50%)`,
      width: `${width}px`,
    }"
  >
    <IconSaxAdd class="w-4 h-4" />
  </button>
</template>

<style scoped lang="scss"></style>