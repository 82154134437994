<template>
  <Card>
    <CardHeader class="cursor-pointer flex-row gap-2 p-8" @click="open = !open">
      <div class="flex aspect-square h-12 w-12 items-center justify-center rounded bg-zinc-200">
        <InstagramIcon class="h-7 w-7 fill-current" />
      </div>
      <div class="flex flex-col">
        <CardTitle>Instagram settings</CardTitle>
        <CardDescription>Decide how your Instagram post should look like.</CardDescription>
      </div>
      <div class="flex-grow" />
      <Button variant="primary" size="circle" class="h-6 w-6 self-center p-1">
        <IconSaxArrowDown2
          class="transform transition-all"
          :class="{
            'rotate-180': open,
          }"
        />
      </Button>
    </CardHeader>
    <CardContent v-show="open" class="flex flex-col gap-2">
      <div class="flex w-full flex-row items-center justify-between gap-8">
        <div class="flex flex-col">
          <span class="label-text mt-2 mb-1 font-bold text-foreground">Share to feed?</span>
          <span class="mb-2 font-light text-xs">
            Share your feed post in followers feeds.
          </span>
        </div>
        <LightSwitch
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]"
          class="text-sm"
          :value="_shareToFeed.toString()"
          @update:value="(value) => (_shareToFeed = value === 'true')"
        />
      </div>
      <hr />
    </CardContent>
    <CardFooter v-show="open" />
  </Card>
</template>
<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import { ref } from 'vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'

const props = defineProps<{
  shareToFeed: boolean
  errors?: Record<string, { code: string; message: string }[]>
}>()

const emit = defineEmits(['update:shareToFeed'])

const open = ref(false)

const _shareToFeed = useVModel(props, 'shareToFeed', emit)
</script>
