import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import type { CalendarOptions } from '@fullcalendar/core'
import { getValidRange } from '@/components-v2/content-publisher/_helpers'

export const contentPublisherConfig = {
  plugins: [dayGridPlugin, interactionPlugin],
  views: {
    dayGridMonth: {
      titleFormat: { month: 'long', year: 'numeric' },
      dayHeaderFormat: { weekday: 'short' },
    },
    dayGridWeek: {
      duration: { weeks: 1 },
      titleFormat: { month: 'long', day: 'numeric' },
      dayHeaderFormat: (input) => {
        const date = new Date(input.date.marker.getTime() - input.date.timeZoneOffset * 60 * 1000)
        const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date)
        const day = date.getDate()
        return `${weekday} ${day}`
      },
    },
  },
  initialView: 'dayGridWeek',
  height: 'auto',
  aspectRatio: 1.75,
  firstDay: 1,
  fixedWeekCount: false,
  slotEventOverlap: false,
  eventOrder: 'start,-duration',
  eventDisplay: 'list-item',
  droppable: true,
  headerToolbar: {
    left: 'prev,today,next',
    center: 'title',
    right: 'dayGridMonth dayGridWeek',
  },
  validRange: getValidRange,
} as Partial<CalendarOptions>
