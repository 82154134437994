export async function retryAsync<T>(fn: () => Promise<T>, retries = 3) {

  return new Promise<T>((resolve, reject) => {

    async function attempt(attemptNumber: number) {
      try {
        resolve(await fn());
      } catch (error) {
        if (attemptNumber >= retries) {
          reject(error);
        } else {
          setTimeout(() => attempt(attemptNumber + 1), 100 * 2 ** attemptNumber);
        }
      }
    }

    attempt(1);
  });
}
