<script lang="ts" setup>
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxFlag from '@/components/Icons/iconsax/IconSaxFlag.vue'
import { PostHog } from '@/logging/post-hog'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { Button } from '@/components/ui/button'

const routes = dashboardRouteNames.account

const linkClass = [
  'flex items-center justify-center gap-2',
  'px-4 py-2 rounded-lg',
  'hover:bg-indigo-500/10',
  'text-center font-light whitespace-nowrap',
  'transition-all hover:text-indigo-500',
]

const linkActiveClass = '!bg-indigo-500 !text-white pointer-events-none'
const isBetaTester = useFeatureFlagEnabled('IsBetaTesterV2')
</script>

<template>
  <main class="flex flex-col gap-6 p-4 lg:gap-12 lg:p-12 max-w-[calc(100dvw-0.25rem)]">
    <nav class="flex flex-row gap-2">
      <ul class="flex gap-2 min-w-0 overflow-x-auto">
        <RouterLink :class="linkClass" :exact-active-class="linkActiveClass" :to="{ name: routes.settings }">
          Account Settings
        </RouterLink>
        <RouterLink :class="linkClass" :exact-active-class="linkActiveClass" :to="{ name: routes.invoicing }">
          Plans & Billing
        </RouterLink>
        <RouterLink :class="linkClass" :exact-active-class="linkActiveClass" :to="{ name: routes.templates }">
          My Templates
        </RouterLink>
        <RouterLink :class="linkClass" :exact-active-class="linkActiveClass" :to="{ name: routes.brandPartners }">
          Brand partners
        </RouterLink>
      </ul>
      <Button v-if="PostHog.instance && isBetaTester" variant="primary" class="p-0 h-10 w-10 shrink-0" size="lg" id="early-access-features-button">
        <IconSaxFlag class="h-5 w-5" />
      </Button>
    </nav>

    <RouterView />
  </main>
</template>

<style lang="scss" scoped></style>
