import { ref, type Ref } from 'vue'
import { tryOnMounted, tryOnUnmounted, useResizeObserver } from '@vueuse/core'

export function useBoundingClientRect(element: Ref<HTMLElement | null>) {

  const x = ref(0)
  const y = ref(0)
  const width = ref(0)
  const height = ref(0)
  const top = ref(0)
  const right = ref(0)
  const bottom = ref(0)
  const left = ref(0)
  
  function updateIfChanged(rect: DOMRect, key: keyof DOMRect, ref: Ref<number>) {
    if (rect[key] !== ref.value) {
      // console.log(key, 'changed', 'from', oldValue, 'to', rect[key])
      ref.value = rect[key] as number
    }
  }

  function updateBoundingRect() {
    const rect = element.value?.getBoundingClientRect()
    if (rect) {
      updateIfChanged(rect, 'width', width)
      updateIfChanged(rect, 'height', height)
      updateIfChanged(rect, 'x', x)
      updateIfChanged(rect, 'y', y)
      updateIfChanged(rect, 'top', top)
      updateIfChanged(rect, 'right', right)
      updateIfChanged(rect, 'bottom', bottom)
      updateIfChanged(rect, 'left', left)
    }
  }

  tryOnMounted(() => {
    updateBoundingRect()
    window.addEventListener('resize', updateBoundingRect)
    window.addEventListener('scroll', updateBoundingRect)
    window.visualViewport?.addEventListener('resize', updateBoundingRect)
  })

  tryOnUnmounted(() => {
    window.removeEventListener('resize', updateBoundingRect)
    window.removeEventListener('scroll', updateBoundingRect)
    window.visualViewport?.removeEventListener('resize', updateBoundingRect)
  })

  useResizeObserver(element, () => updateBoundingRect())

  return {
    x,
    y,
    width,
    height,
    top,
    right,
    bottom,
    left,
    forceUpdate() {
      updateBoundingRect()
    }
  }
}
