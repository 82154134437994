<script lang="ts" setup>
import { computed } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { Button } from '@/components/ui/button'
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import InstagramSimpleIcon from '@/components/Icons/SocialMedia/InstagramSimpleIcon.vue'
import useCreateSocialConnection from '@/Hooks/useCreateSocialConnection'
import GoogleIcon from '@/components/Icons/GoogleIcon.vue'

const userInfoStore = useUserInfoStore()

const { connect: connectSocialAccount } = useCreateSocialConnection()

const hasTikTokAccount = computed(() => userInfoStore.connections.tiktok.length > 0)
const hasYouTubeAccount = computed(() => userInfoStore.connections.youtube.length > 0)
const hasInstagramAccount = computed(() => userInfoStore.connections.instagram.length > 0)

const isInstagramSupported = false
</script>

<template>
  <p class="font-light text-sm" style="text-wrap: pretty">
    Don’t miss out! Connect more services to reach a wider audience. Share your content on all your socials for the best
    results.
  </p>

  <ol class="flex flex-col gap-2">
    <li class="flex items-center gap-4 bg-white dark:bg-surface-bg shadow p-2 rounded-2xl" v-if="userInfoStore.hasTwitchConnected">
      <span class="grid place-items-center w-16 h-16 bg-twitch text-white rounded-xl">
        <TwitchIcon class="w-8 h-8" />
      </span>
      <header class="flex flex-col items-start justify-center mr-auto">
        <p class="text-sm font-light">Service</p>
        <h3 class="font-bold text-base">Twitch</h3>
      </header>

      <span class="bg-brand-state-success-bg text-brand-state-success rounded py-1 px-2 mr-2 leading-[1]">
        Connected
      </span>
    </li>
    <li class="flex items-center gap-4 bg-white dark:bg-surface-bg shadow p-2 rounded-2xl" v-if="userInfoStore.hasGoogleConnected">
      <span class="grid place-items-center w-16 h-16 rounded-xl bg-surface-panel">
        <GoogleIcon class="w-8 h-8" />
      </span>
      <header class="flex flex-col items-start justify-center mr-auto">
        <p class="text-sm font-light">Service</p>
        <h3 class="font-bold text-base">Google</h3>
      </header>

      <span class="bg-brand-state-success-bg text-brand-state-success rounded py-1 px-2 mr-2 leading-[1]">
        Connected
      </span>
    </li>

    <li class="flex items-center gap-4 bg-white dark:bg-surface-bg shadow p-2 rounded-2xl">
      <span class="grid place-items-center w-16 h-16 bg-black text-white rounded-xl">
        <TikTokIcon class="w-8 h-8" />
      </span>
      <header class="flex flex-col items-start justify-center mr-auto">
        <p class="text-sm font-light">Social</p>
        <h3 class="font-bold text-base">TikTok</h3>
      </header>

      <span
        v-if="hasTikTokAccount"
        class="bg-brand-state-success-bg text-brand-state-success rounded py-1 px-2 mr-2 leading-[1]"
      >
        Connected
      </span>
      <Button v-else class="mr-2" variant="outline" @click="connectSocialAccount('tiktok')">
        Connect
      </Button>
    </li>

    <li class="flex items-center gap-4 bg-white dark:bg-surface-bg shadow p-2 rounded-2xl">
      <span class="grid place-items-center w-16 h-16 bg-[#F00] text-white rounded-xl">
        <YoutubeIcon class="w-8 h-8" />
      </span>
      <header class="flex flex-col items-start justify-center mr-auto">
        <p class="text-sm font-light">Social</p>
        <h3 class="font-bold text-base">YouTube</h3>
      </header>

      <span
        v-if="hasYouTubeAccount"
        class="bg-brand-state-success-bg text-brand-state-success rounded py-1 px-2 mr-2 leading-[1]"
      >
        Connected
      </span>
      <Button v-else class="mr-2" variant="outline" @click="connectSocialAccount('youtube')">
        Connect
      </Button>
    </li>

    <li class="flex items-center gap-4 bg-white dark:bg-surface-bg shadow p-2 rounded-2xl" v-if="isInstagramSupported">
      <span
        class="grid place-items-center w-16 h-16 text-white rounded-xl bg-instagram"
      >
        <InstagramSimpleIcon class="w-8 h-8" />
      </span>
      <header class="flex flex-col items-start justify-center mr-auto">
        <p class="text-sm font-light">Social</p>
        <h3 class="font-bold text-base">Instagram</h3>
      </header>

      <span
        v-if="hasInstagramAccount"
        class="bg-brand-state-success-bg text-brand-state-success rounded py-1 px-2 mr-2 leading-[1]"
      >
        Connected
      </span>
      <Button v-else class="mr-2" variant="outline" @click="connectSocialAccount('instagram')">
        Connect
      </Button>
    </li>
  </ol>
</template>

<style lang="scss" scoped>

</style>
