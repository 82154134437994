import { drawLoader } from "./draw-loader";
import type { Area } from "./types";
import { decodeWebp } from "./webp/webp-decoder";

const isInWorker = self.document === undefined;

interface frame {
    bitmap: ImageBitmap;
    timestamp: number;
    duration: number;
}

export default class WebpRenderer {
    imageUrl: string;
    live: boolean;
    ctx: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;

    videoPreview?: HTMLVideoElement;

    prevTimestamp: number;

    frames: frame[];
    duration: number;

    static decodeWorker: Worker | null = null;
    
    constructor(ctx: CanvasRenderingContext2D, imageUrl: string, live: boolean) {
        this.ctx = ctx;
        this.canvas = ctx.canvas;
        this.imageUrl = imageUrl;
        this.live = live;
        this.prevTimestamp = -1;
        this.frames = [];
        this.duration = 0;
        if (this.live) {
            this.initialize();
        }
    }

    async initialize() {

        if (isInWorker) {
            const result = await decodeWebp(this.imageUrl);
            this.duration = result.duration;
            this.frames = result.bitmapFrames;
        }
        else {
            if (WebpRenderer.decodeWorker === null) {
                WebpRenderer.decodeWorker = new Worker(new URL('./webp/webp-worker.ts', import.meta.url), { type: "module" });
            }

            const listener = (message: MessageEvent) => {
                if (message.data.imageUrl === this.imageUrl) {
                    WebpRenderer.decodeWorker?.removeEventListener('message', listener);
                    this.frames = message.data.result.bitmapFrames;
                    this.duration = message.data.result.duration;
                }
            }

            WebpRenderer.decodeWorker.addEventListener('message', listener);
            WebpRenderer.decodeWorker.postMessage({ type: 'decode', imageUrl: this.imageUrl });
        }
    }

    render(timestamp: number, startMs: number, endMs: number, area: Area) {
        if (timestamp > endMs * 1e3 || timestamp < startMs * 1e3) return;

        const x = area.x * this.canvas.width;
        const y = area.y * this.canvas.height;
        const width = area.width * this.canvas.width;
        const height = area.height * this.canvas.height;

        if (this.frames.length === 0) {
            drawLoader(this.ctx, this.canvas, x + width / 2, y + height / 2, width * .75, height * .75);
            return;
        }

        const stickerTime = (timestamp - startMs * 1e3) % this.duration;

        const frame = this.frames.find(frame => frame.timestamp <= stickerTime && frame.timestamp + frame.duration >= stickerTime);

        if (frame) {
            this.ctx.drawImage(frame.bitmap, x, y, width, height);
        }
    }
}
