<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  SwitchRoot,
  type SwitchRootEmits,
  type SwitchRootProps,
  SwitchThumb,
  useForwardPropsEmits,
} from 'radix-vue'
import { cn } from '@/lib/utils'

const variants = {
  size: {
    sm: 'h-5 w-9',
    md: 'h-6 w-11',
    lg: 'h-8 w-16',
  }
};

const thumbVariants = {
  size: {
    'sm': 'h-4 w-4 data-[state=checked]:translate-x-4',
    'md': 'h-5 w-5 data-[state=checked]:translate-x-5',
    'lg': 'h-7 w-7 data-[state=checked]:translate-x-7',
  }
};

interface VariantProps {
  size?: keyof typeof variants['size'];
}

const props = withDefaults(defineProps<SwitchRootProps & VariantProps & { class?: HTMLAttributes['class'] }>(), { size: 'md' });

const emits = defineEmits<SwitchRootEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <SwitchRoot
    v-bind="forwarded"
    :class="cn(
      'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-emerald-500 data-[state=unchecked]:bg-input',
      variants.size[props.size],
      props.class,
    )"
  >
    <SwitchThumb
      :class="cn('pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0', thumbVariants.size[props.size])"
    />
  </SwitchRoot>
</template>
