import { streamLadderAxios, publicAxios } from '@/services/axios'
import type {S3RequestUploadResult} from "@/apis/swagger-api-streamladder";
import type { DeepNonNullable } from 'ts-essentials'
import type { AxiosRequestConfig } from 'axios'

export default {

  async getUploadResultSignedUrl() {
    const res = await streamLadderAxios.get<S3RequestUploadResult>('/api/Upload/ResultUploadUrl')
    if (res.status === 200) {
      if (!res.data.signedUrl || !res.data.resultUrl) {
        throw new Error('Invalid signed URL response')
      }
      return res.data as DeepNonNullable<Required<S3RequestUploadResult>>
    } else {
      throw new Error(`Get SignedURL for ResultUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },

  async getDebugSignedUrl() {
    const res = await streamLadderAxios.get('api/Upload/DebugBucketUploadUrl')
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for ResultUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },

  async getImportFileSignedUrl() {
    const res = await streamLadderAxios.get('/api/Upload/ImportUploadUrl')
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for ImportUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },

  async getUploadCustomStickerSignedUrl() {
    const res = await streamLadderAxios.get('/api/Upload/CustomStickerUploadUrl')
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for StickerUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },

  async getClipAudioUploadSignedUrl(hash: string) {
    const res = await streamLadderAxios.get(`/api/Upload/ClipAudioUploadUrl?hash=${hash}`)
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for AudioUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },

  async uploadFileS3(
    blobSasUrl: string,
    fileData: Blob,
    onProgressCallback: (progress: number) => void,
    contentType = 'application/x-www-form-urlencoded',
    attachmentFilename = '',
    axiosOptions: AxiosRequestConfig<Blob> = {}
  ) {
    attachmentFilename = encodeURIComponent(attachmentFilename)
    return await publicAxios.put(blobSasUrl, fileData, {
      ...axiosOptions,
      withCredentials: false,
      headers: {
        'Content-Disposition': 'attachment' + (attachmentFilename ? `; filename="${attachmentFilename}"` : ''),
        'Content-Type': contentType,
      },
      onUploadProgress: (event) => onProgressCallback(Math.floor((event.loaded / (event.total || 1)) * 100)),
    })
  },
}

/**
 * @typedef {Object} SignedS3Result
 * @prop {string} signedUrl
 * @prop {string} filename
 * @prop {string} resultUrl
 * */
