<script lang="ts" setup>
import { useEventListener, useResizeObserver } from '@vueuse/core';
import { useChatButtonStyle, useChatButtonIconStyle, useChatWindowStyle, useNotificationWindowStyle } from '@/Hooks/useChat';

const slot = ref<HTMLElement | null>(null);
const chatButtonStyle = useChatButtonStyle();
const chatButtonIconStyle = useChatButtonIconStyle();
const chatWindowStyle = useChatWindowStyle();
const notificationWindowStyle = useNotificationWindowStyle();

function onResize() {

  if (!slot.value) {
    return;
  }

  const rect = slot.value.getBoundingClientRect();
  const navigationHeight = 40;
  const size = 24;

  chatButtonStyle.value = {
    left: `${rect.left}px`,
    top: `${0.5 * (navigationHeight - size)}px`,
    width: `${size}px`,
    height: `${size}px`,
    display: 'grid',
    alignItems: 'end',
    justifyContent: 'center',
    opacity: '1',
    pointerEvents: 'auto',
    visibility: 'visible',
  };

  chatWindowStyle.value = {
    inset: 0,
    width: '100dvw',
  };

  chatButtonIconStyle.value = {
    backgroundColor: 'hsla(0 0 100% / 1)',
    width: `${size}px`,
    height: `${size}px`,
    color: 'var(--brand-primary-default)',
  };

  notificationWindowStyle.value = {
    top: navigationHeight + 8 + 'px',
    alignItems: 'start',
    bottom: 'auto',
    left: 'auto',
    right: '8px',
  }
}

useResizeObserver(slot, onResize);
useEventListener('resize', onResize);
onMounted(onResize);
</script>

<template>
  <div ref="slot" />
</template>

<style scoped>

</style>
