/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { ClipGptClipDto } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiClipGptClips = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<ClipGptClipDto[]>({ url: `/api/ClipGptClips`, method: 'GET', signal }, options)
}

export const getGetApiClipGptClipsQueryKey = () => {
  return ['api', 'ClipGptClips'] as const
}

export const getGetApiClipGptClipsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiClipGptClips>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptClips>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiClipGptClipsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiClipGptClips>>> = ({ signal }) =>
    getApiClipGptClips(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiClipGptClips>>,
    TError,
    TData
  >
}

export type GetApiClipGptClipsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiClipGptClips>>>
export type GetApiClipGptClipsQueryError = unknown

export const useGetApiClipGptClips = <
  TData = Awaited<ReturnType<typeof getApiClipGptClips>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptClips>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiClipGptClipsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiClipGptClipsId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return streamLadderAxiosInstance<ClipGptClipDto>({ url: `/api/ClipGptClips/${id}`, method: 'GET', signal }, options)
}

export const getGetApiClipGptClipsIdQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'ClipGptClips', id] as const
}

export const getGetApiClipGptClipsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiClipGptClipsId>>,
  TError = unknown
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptClipsId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiClipGptClipsIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiClipGptClipsId>>> = ({ signal }) =>
    getApiClipGptClipsId(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptClipsId>>, TError, TData>
}

export type GetApiClipGptClipsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiClipGptClipsId>>>
export type GetApiClipGptClipsIdQueryError = unknown

export const useGetApiClipGptClipsId = <TData = Awaited<ReturnType<typeof getApiClipGptClipsId>>, TError = unknown>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiClipGptClipsId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiClipGptClipsIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
