import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useSLVideoController } from '@/modules/SLVideoplayer/hooks/useSLVideoController'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { gsap } from 'gsap'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useRafFn, useMediaControls, useLocalStorage } from '@vueuse/core';
import * as Sentry from '@sentry/vue'
import MP4Box from 'mp4box';
import { logFlatTable } from '@/lib/console';

export const _globalTimeline = gsap.timeline({
  repeat: -1,
  repeatDelay: 0,
  paused: true,
})

export const useVideoStore = defineStore('video', () => {

  const videoElement = ref<HTMLVideoElement>()
  const src = ref('')

  const segmentsStore = useSegmentsStore()
  const segments = segmentsStore.whereIsNotZoom()

  const videoSize = ref<{ width: number, height: number } | null>(null)

  const videoController = useSLVideoController(videoElement, segments)
  
  const audioElement = ref<HTMLAudioElement>()
  const audioControls = useMediaControls(audioElement)

  function handleDocumentVisibilityChange() {
    if (document.hidden) {
      playing.value = false;
      preservedPaused.value = true;
      videoElement.value?.pause();
    }
  }

  window.addEventListener("visibilitychange", handleDocumentVisibilityChange);
  
  const playing = computed({
    get() {
      if (audioControls.playing.value !== videoController.playing.value) {
        audioControls.playing.value = videoController.playing.value
      }
      return audioControls.playing.value && videoController.playing.value
    },
    set(value) {
      audioControls.playing.value = value
      videoController.playing.value = value
    }
  })

  const canvas = ref<OffscreenCanvas | null>(null)

  useRafFn(() => {
    if (videoElement.value) {
      canvas.value
        ?.getContext('2d')
        ?.drawImage(videoElement.value, 0, 0, canvas.value.width, canvas.value.height)
    }
  })

  function resizeCanvas(width: number, height: number) {
    if (canvas.value) {
      canvas.value.width = Math.abs(Math.round(width))
      canvas.value.height = Math.abs(Math.round(height))
    }
  }

  const preservedPaused = ref(false)
  const preservedMuted = useLocalStorage('preferences:muted', false)

  watch(audioControls.muted, (muted) => {
    preservedMuted.value = muted
    if (videoElement.value) videoElement.value.muted = muted;
  })

  const preservedVolume = useLocalStorage('preferences:volume', 1)
  watch(audioControls.volume, (volume) => {
    preservedVolume.value = volume
    if (videoElement.value) videoElement.value.volume = volume;
  });

  const scrubbing = ref(false)

  const isLoaded = computed(() => {
    return videoElement.value !== undefined
  })

  function getExactTime() {
    return (videoElement.value?.currentTime ?? 0) * 1000
  }

  type Track = {
    type: 'video' | 'audio',
    duration: number,
    timescale: number,
    nb_samples: number,
    edits?: {
     media_time: number,
     segment_duration: number,
    }[],
  }

  async function loadVideo(element: HTMLVideoElement, audio: HTMLAudioElement, _src: string) {

    await new Promise<void>((resolve, reject) => {

      videoElement.value = element;
      audioElement.value = audio;
      
      // Ensure new canvas is created for each video
      canvas.value = null;

      const isVideoInvalid = () => {
        const hasInvalidDuration = isNaN(element.duration) || !isFinite(element.duration);
        const hasInvalidDimensions = element.videoWidth === 0 || element.videoHeight === 0 || isNaN(element.videoWidth) || isNaN(element.videoHeight);
        return { hasInvalidDuration, hasInvalidDimensions };
      }

      function handleVideoState() {

        const { hasInvalidDuration, hasInvalidDimensions } = isVideoInvalid();

        if (!hasInvalidDuration && !hasInvalidDimensions) {
          cleanup();
          resolve();
        } else if (element.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
          cleanup();
          console.error(JSON.stringify(element));
          const error = new Error(hasInvalidDuration ? 'Video has no duration' : 'Video has no dimensions');
          Sentry.captureException(error);
          reject(error);
        }
      }
      
      function handleError(event: Event) {
        console.error(JSON.stringify(event));
        Sentry.captureException(new Error('Video failed to load'));
        handleVideoState()
      }

      function cleanup() {
        element.removeEventListener('error', handleError);
        element.removeEventListener('loadedmetadata', handleVideoState);
        element.removeEventListener('loadeddata', handleVideoState);
        element.removeEventListener('canplay', handleVideoState);
        element.removeEventListener('canplaythrough', handleVideoState);
      }

      element.addEventListener('error', handleError);
      element.addEventListener('loadedmetadata', handleVideoState);
      element.addEventListener('loadeddata', handleVideoState);
      element.addEventListener('canplay', handleVideoState);
      element.addEventListener('canplaythrough', handleVideoState);

      audioControls.muted.value = preservedMuted.value;
      videoController.muted.value = preservedMuted.value;

      audioControls.volume.value = preservedVolume.value;
      videoController.volume.value = preservedVolume.value;

      src.value = _src;

      videoElement.value.currentTime = 1 / 1000;
      audioElement.value.currentTime = 1 / 1000;

      // Play might fail if user has not interacted with the page yet. In which case we will mute the video and then play it again, since playing muted videos is allowed and playing the video takes precedence over the audio.
      videoElement.value!.play().catch(() => {
        audioControls.muted.value = true;
        videoController.muted.value = true;
        videoElement.value!.play().catch(console.error);
      });
    });

    console.log('Video loaded:', element.src);
    videoSize.value = { width: element.videoWidth, height: element.videoHeight };
    canvas.value = new OffscreenCanvas(element.videoWidth, element.videoHeight);
    setMainStore(element);

    const promise = fetchVideoData(src.value).then((info) => {

      // Assume the first video track contains the framerate info
      const videoTrack = info.tracks.find(track => track.type === 'video');

      logFlatTable(info)

      if (videoTrack && videoTrack.duration && videoTrack.timescale && videoTrack.nb_samples) {
        const fps = videoTrack.nb_samples / (videoTrack.duration / videoTrack.timescale);
        console.log('Framerate:', fps);
        framerate.value = fps;
      }
      
      return info;
    })

    return () => promise;
  }

  const framerate = ref(60)
  async function fetchVideoData(src: string): Promise<{ tracks: Track[] }> {

    const blob = await fetch(src)
      .then((r) => r.blob())
      .catch((e) => {
        console.error(e)
        throw new Error('Could not fetch video file. Please try again.')
      })

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const mp4boxFile = MP4Box.createFile();

      mp4boxFile.onReady = resolve;

      fileReader.onload = () => {
        // Add `fileStart` property to arrayBuffer by type assertion
        const arrayBuffer = fileReader.result as ArrayBuffer & { fileStart: number };
        arrayBuffer.fileStart = 0;
        mp4boxFile.appendBuffer(arrayBuffer);
        mp4boxFile.flush();
      };

      fileReader.onerror = () => reject(fileReader.error);
      fileReader.readAsArrayBuffer(blob);
    });
  }

  function setMainStore(element: HTMLVideoElement) {
    const editorMainStore = useEditorMainStore()
    if (editorMainStore.videoHeight === 0 || editorMainStore.trimmedEndTime === 0) {
      // editorMainStore.videoDuration = Math.round(element.duration * 1000)
      editorMainStore.videoHeight = element.videoHeight
      editorMainStore.videoWidth = element.videoWidth
    }
  }

  // When the media element is removed from the DOM, the src attribute should be set to an empty string to allow garbage collecting.
  // https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
  function unmount(element: HTMLMediaElement | null | undefined) {

    if (!element) {
      return;
    }

    element.pause()
    element.src = ''
    element.load()
    element.remove();
  }

  function reset() {

    unmount(videoElement.value)
    unmount(audioElement.value)

    videoElement.value = undefined;
    audioElement.value = undefined;

    src.value = '';
    canvas.value = null;
  }

  return {
    ...videoController,
    playing,
    volume: computed({
      get() {
        return audioControls.volume.value
      },
      set(value: number) {
        audioControls.volume.value = value;
        videoController.volume.value = value;
      }
    }),
    muted: audioControls.muted, 
    durationMs: computed(() => videoController._duration.value * 1000),
    canvas,
    resizeCanvas,
    segments,
    loadVideo,
    getExactTime,
    videoElement,
    audioElement,
    videoSize,
    scrubbing,
    isLoaded,
    $reset: reset,
    preservedPaused,
    framerate,
    // timeline: timeline,
  }
})
