<script setup lang="ts">
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogHeader, DialogFooter, DialogClose } from '@/components/ui/dialog'
import { computed, ref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { Calendar } from '@/components/ui/calendar'
import { parseAbsoluteToLocal, ZonedDateTime, toCalendarDate, toTime, parseAbsolute } from '@internationalized/date'
import MorningBold from '@/components/Icons/Normalized/DaytimeIndicators/MorningBold.vue'
import MorningLine from '@/components/Icons/Normalized/DaytimeIndicators/MorningLine.vue'
import SunBold from '@/components/Icons/Normalized/DaytimeIndicators/SunBold.vue'
import SunLine from '@/components/Icons/Normalized/DaytimeIndicators/SunLine.vue'
import AfternoonBold from '@/components/Icons/Normalized/DaytimeIndicators/AfternoonBold.vue'
import AfternoonLine from '@/components/Icons/Normalized/DaytimeIndicators/AfternoonLine.vue'
import MoonBold from '@/components/Icons/Normalized/DaytimeIndicators/MoonBold.vue'
import MoonLine from '@/components/Icons/Normalized/DaytimeIndicators/MoonLine.vue'
import unwrap from '@/helpers/unwrap'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { useIsMobile } from '@/Hooks/useIsMobile'
import DateTimeDialogDayScheduleMobile from '@/components/date-time-dialog/DateTimeDialogDayScheduleMobile.vue'
import DateTimeDialogDayScheduleDesktop from '@/components/date-time-dialog/DateTimeDialogDayScheduleDesktop.vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { toPostCalendarEvent, addGridRowProperty } from '@/components-v2/content-publisher/_helpers'
import { isSameDay, isBefore, startOfDay } from 'date-fns'
import DateTimeDialogCalendarDay from '@/components/date-time-dialog/DateTimeDialogCalendarDay.vue'

const isOpen = defineModel<boolean>('open', { required: true });

const dateTime = defineModel<Date | undefined>('date', { required: true });
const localState = ref(dateTime.value);
watch(() => dateTime.value, (value) => {
  localState.value = value;
});

function submit() {
  
  if (!localState.value || isBefore(localState.value, new Date())) {
    isSchedulingInThePast.value = true;
    return;
  }
  
  dateTime.value = localState.value;
  isOpen.value = false;
}

const isSchedulingInThePast = ref(false);
watch(() => localState.value, () => {
  isSchedulingInThePast.value = false;
})

const timePreference = useLocalStorage<string | undefined>('timePreference', undefined);
const defaultDate = computed(() => {
  const defaultDate = new Date()
  if (timePreference.value) {
    const [hours, minutes] = timePreference.value.split(':').map(Number)
    defaultDate.setHours(hours, minutes)
  }
  return defaultDate
})

const zonedDateTime = computed({
  get() {
    if (dateTime.value) {
      return parseAbsoluteToLocal(dateTime.value.toISOString());
    } else {
      return parseAbsoluteToLocal(defaultDate.value.toISOString());
    }
  },
  set(zonedDateTime: ZonedDateTime) {
    dateTime.value = zonedDateTime?.toDate() ?? defaultDate.value;
  },
});

const time = computed({
  get() {
    return toTime(zonedDateTime.value).toString().slice(0, 5);
  },
  set(time: string) {
    timePreference.value = time;
    const date = toCalendarDate(zonedDateTime.value).toString();
    zonedDateTime.value = parseAbsolute(new Date(`${date}T${time}:00`).toISOString(), zonedDateTime.value.timeZone);
  }
});

const timesOfDay = {
  morning: { start: 7, end: 12 },
  noon: { start: 12, end: 16 },
  afternoon: { start: 16, end: 19 },
  evening: { start: 19, end: 24 },
  night: { start: 0, end: 6 },
} as const;

const timeOfDay = computed({
  get() {
    const hour = zonedDateTime.value.hour;
    for (const [timeOfDay, { start, end }] of unwrap.entries(timesOfDay)) {
      if (hour >= start && hour < end) {
        return timeOfDay;
      }
    }
    return undefined;
  },
  set(value: keyof typeof timesOfDay) {
    const { start, end } = timesOfDay[value];
    const hours = Math.floor(Math.random() * (end - start) + start).toString().padStart(2, '0')
    const minutes = Math.floor(Math.random() * 60).toString().padStart(2, '0')
    time.value = `${hours}:${minutes}`
  }
});

const minDate = computed(() => {
  return parseAbsoluteToLocal(startOfDay(new Date()).toISOString())
})
const maxDate = computed(() => {
  const date = parseAbsoluteToLocal(new Date().toISOString())
  return date.add({ months: 2 })
})

const dateTimeLocaleString = computed(() => {
  return zonedDateTime.value.toDate().toLocaleString(undefined, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortOffset',
    hour12: false,
  });
})

const isMobile = useIsMobile()
const contentPublisherStore = useContentPublisherStore()

const scheduledOnThisDay = computed(() => {

  const currentDate = zonedDateTime.value.toDate()
  const events = contentPublisherStore.posts
    .map(toPostCalendarEvent)
    .filter((event) => isSameDay(new Date(event.date), currentDate))

  return addGridRowProperty(events)
})
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogContent class="max-w-xl gap-6">
      <DialogHeader>
        <DialogTitle as="h3" class="text-2xl">Schedule post</DialogTitle>
        <DialogDescription as="p" class="text-sm text-muted-foreground font-light">
          Choose the perfect date and time to schedule your post
        </DialogDescription>
      </DialogHeader>
      
      <DateTimeDialogDayScheduleMobile :posts="scheduledOnThisDay" v-if="isMobile" />

      <article>
        <div class="flex gap-4">
          <div class="flex flex-col items-center gap-2" :class="isMobile ? 'w-full' : ''">
            <Calendar v-model="zonedDateTime" :min-value="minDate" :max-value="maxDate" :week-starts-on="1">
              <template #cell="{ date }">
                <DateTimeDialogCalendarDay :date="date" />
              </template>
            </Calendar>
            <div class="flex flex-col items-start w-full">
              <h4 class="text-sm">Choose a time</h4>
              <div class="flex flex-col items-start gap-1">
                <Input v-model="time" type="time" class="min-w-0" :class="isSchedulingInThePast ? 'text-red-500 bg-red-50 border-red-500' : ''" />
                <span class="text-sm text-red-500 font-light" v-if="isSchedulingInThePast">
                  Can't schedule in the past.
                </span>
              </div>
            </div>
          </div>
          <DateTimeDialogDayScheduleDesktop :posts="scheduledOnThisDay" v-if="!isMobile" />
        </div>
      </article>

      <article class="flex flex-col items-start">
        <h4 class="text-sm">Can't decide on a time?</h4>
        <div class="grid grid-cols-4 gap-4">
          <Button variant="ghost" size="sm" @click="timeOfDay = 'morning'" class="flex-col gap-1 h-auto py-2">
            <MorningBold v-if="timeOfDay === 'morning'" class="h-8 w-8" />
            <MorningLine v-else class="h-8 w-8" />
            Morning
          </Button>
          <Button variant="ghost" size="sm" @click="timeOfDay = 'noon'" class="flex-col gap-1 h-auto py-2">
            <SunBold v-if="timeOfDay === 'noon'" class="h-8 w-8" />
            <SunLine v-else class="h-8 w-8" />
            Noon
          </Button>
          <Button variant="ghost" size="sm" @click="timeOfDay = 'afternoon'" class="flex-col gap-1 h-auto py-2">
            <AfternoonBold v-if="timeOfDay === 'afternoon'" class="h-8 w-8" />
            <AfternoonLine v-else class="h-8 w-8" />
            Afternoon
          </Button>
          <Button variant="ghost" size="sm" @click="timeOfDay = 'evening'" class="flex-col gap-1 h-auto py-2">
            <MoonBold v-if="timeOfDay === 'evening'" class="m-1 h-6 w-6" />
            <MoonLine v-else class="m-1 h-6 w-6" />
            Evening
          </Button>
        </div>
      </article>

      <p class="text-sm text-muted-foreground font-light" v-if="dateTimeLocaleString">
        Your post will be scheduled for {{ dateTimeLocaleString }}.
      </p>
      <DialogFooter class="sm:justify-between">
        <DialogClose as-child>
          <Button variant="ghost">Cancel</Button>
        </DialogClose>
        <Button variant="primary" @click="submit">Schedule</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
