<script setup lang="ts" generic="T extends string | number">
import { computed } from 'vue'
import ToggleButton from '@/components-v2/data-input/ToggleButton.vue'
import { uniq } from 'lodash-es'

const props = defineProps<{ choice: T }>()
const selection = defineModel<T[]>('answers', { required: true })

const checked = computed({
  get() {
    return selection.value.includes(props.choice)
  },
  set(checked: boolean) {
    if (checked) {
      selection.value = uniq([...selection.value, props.choice])
    } else {
      selection.value = selection.value.filter((choice) => choice !== props.choice)
    }
  }
})
</script>

<template>
  <ToggleButton v-model="checked">
    <slot />
  </ToggleButton>
</template>

<style scoped lang="scss">

</style>
