import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { computed } from 'vue'
import type { Crop, Segment } from '@/areas/editor/@type/Project'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { CropData as ApiCropData } from '@/apis/streamladder-api/model'
import { usePresets } from '@/areas/editor/@data/layouts'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import * as Sentry from '@sentry/vue'
import type { VideoEffect } from '@/modules/SLVideoplayer/types'
import type { Area } from '@/modules/SLMovable/@types/Movable'

export function useRendererData() {

  const segmentsStore = useSegmentsStore()
  const cropsStore = useCropsStore()
  const videoStore = useVideoStore()
  const layoutsStore = useLayoutsStore()
  const { layouts } = usePresets()

  const flattenedSegments = segmentsStore.flat()

  const rendererCrops = computed(() => {
    const result = []
    for (const segment of flattenedSegments.value) {
      const crops = [...cropsStore.entities]
        .filter(crop => crop.layoutId === segment.layoutId)
        .sort((a, b) => a.z - b.z);
      for (const crop of crops) {
        const renderCrop = toRenderCrop(crop, segment, videoStore.videoElement!)
        if (renderCrop) {
          result.push(renderCrop)
        }
      }
    }
    return result
  })

  const rendererSegments = computed(() => {
    const result = [];
    for (const segment of flattenedSegments.value) {
      const layout = layoutsStore.selectById(segment.layoutId)
      const crops = cropsStore.orderByZ(layout.id).value
      const layoutName = layouts.value.find((l) => l.presetId === layout.presetId)?.id ?? 'custom'
      result.push({
        startMs: segment.startMs,
        endMs: segment.endMs,
        layout: toApiLayoutName(layoutName),
        cropData: crops.map(toApiCropData).filter(Boolean) as ApiCropData[]
      })
    }
    return result
  })

  return { rendererCrops, rendererSegments }
}

function isNumber(number: unknown): number is number {
  return typeof number === 'number' && !isNaN(number)
}

function isValidArea(area: Record<keyof Area, unknown>): area is Area {
  return isNumber(area.x) && isNumber(area.y) && isNumber(area.width) && isNumber(area.height)
}

function toApiCropData(crop: Crop): ApiCropData | null {
  if (isValidArea(crop) && isValidArea(crop.feedData)) {
    return {
      x: crop.x,
      y: crop.y,
      width: crop.width,
      height: crop.height,
      feedData: {
        x: crop.feedData.x,
        y: crop.feedData.y,
        width: crop.feedData.width,
        height: crop.feedData.height,
        effects: crop.input.shape === 'circle'
          ? [{ type: 'rounded' }]
          : [],
      },
    }
  } else {
    console.log(window.location.href)
    Sentry.captureException('Invalid crop data')
    return null
  }
}

function toRenderCrop(crop: Crop, segment: Segment, videoElement: HTMLVideoElement | undefined): RendererCrop | null {
  if (!videoElement) {
    return null
  } else {
    return {
      key: segment.id + ':' + crop.id,
      videoUrl: videoElement.src,
      videoDurationMs: videoElement.duration * 1000,
      startMs: segment.startMs,
      endMs: segment.endMs,
      z: crop.z,
      shape: crop.input.shape === 'circle' ? 'circle' : 'rectangle',
      effects: [],
      cropData: {
        x: crop.x,
        y: crop.y,
        width: crop.width,
        height: crop.height,
        feedData: {
          x: crop.feedData.x,
          y: crop.feedData.y,
          width: crop.feedData.width,
          height: crop.feedData.height,
        },
      },
    }
  }
}

function toApiLayoutName(layoutName: string) {
  if (layoutName === 'full') {
    return 'Fullscreen'
  } else if (layoutName === 'split' || layoutName == 'basecam') {
    return 'Split'
  } else {
    return 'Custom'
  }
}

export type RendererCrop = {
  key: string;
  videoUrl: string;
  videoDurationMs: number;
  startMs: number;
  endMs: number;
  z: number;
  shape: 'rectangle' | 'circle';
  effects: VideoEffect[];
  cropData: {
    x: number;
    y: number;
    width: number;
    height: number;
    feedData: {
      x: number;
      y: number;
      width: number;
      height: number;
    },
  }
};
