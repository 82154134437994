<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useMouseInElement, useParentElement } from '@vueuse/core'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { findClosestFittingLocation } from '@/modules/SLVideoplayer/helpers'
import { FocusTypes, useEditorFocusStore } from "@/store/editor/editorFocus";
import { canGuardWithPopup } from '@/Hooks/useGuard'

const editorFocusStore = useEditorFocusStore()
const editorFeedStore = useEditorFeedDataStore()

const { pxToMs, msToPx, durationMs, dragging } = useTimelineStore()

const parentEl = useParentElement()
const { isOutside, elementX } = useMouseInElement(parentEl)

const width = ref(52)
const popupOpen = ref(false)
let storedLastPosition: { point: number; distance: number } | undefined

const positionOnTimeline = computed(() => {
  if (popupOpen.value && storedLastPosition != undefined) {
    return storedLastPosition
  }

  const closestFittingLocation = findClosestFittingLocation(
    elementX.value,
    width.value,
    0,
    msToPx(durationMs.value),
    editorFeedStore.zooms.map((zoom) => ({
      start: msToPx(zoom.start, true),
      end: msToPx(zoom.end, true),
    }))
  )

  if (popupOpen.value && storedLastPosition == undefined) {
    storedLastPosition = closestFittingLocation
    return storedLastPosition
  }

  // reset storedLastPosition if the popover is closed
  storedLastPosition = undefined

  return (
    closestFittingLocation || {
      point: elementX.value,
      distance: 0,
    }
  )
})

const left = computed(() => positionOnTimeline.value.point)

async function addZoom() {
  const canAdd = canGuardWithPopup('zoom-segments')
  if (!canAdd) return
  const targetMs = pxToMs(left.value, true)
  const duration = pxToMs(width.value, true)

  const id = editorFeedStore.addZoom({
    start: targetMs - duration / 2,
    end: targetMs + duration / 2,
  })
  await nextTick()
  editorFocusStore.setFocus(FocusTypes.ZOOM, id)
  popupOpen.value = false
}
</script>

<template>
  <button
    ref="node"
    class="peer absolute rounded-[5px] border-none bg-purple-900 h-[25px] px-3 text-white"
    @click="addZoom"
    v-if="popupOpen || (!isOutside && positionOnTimeline.distance <= 0 && dragging?.type !== 'node')"
    :style="{
      left: `${left}px`,
      transform: `translateX(-50%)`,
      width: `${width}px`,
    }"
  >
    <IconSaxAdd size="1rem" />
  </button>
</template>

<style scoped lang="scss"></style>