<script setup lang="ts">
import { computed } from 'vue'
import { DropdownMenuCheckboxItem } from '@/components/ui/dropdown-menu'

const props = defineProps<{ value: string | null }>()
const model = defineModel<string[]>('checked', { required: true })

const checked = computed({
  get() {
    if (props.value === null) {
      return model.value.length === 0
    } else {
      return model.value.includes(props.value)
    }
  },
  set() {
    if (props.value === null) {
      model.value = []
    } else {
      if (checked.value) {
        model.value = model.value.filter((v) => v !== props.value)
      } else {
        model.value = [...model.value, props.value]
      }
    }
  }
})
</script>

<template>
  <DropdownMenuCheckboxItem v-model:checked="checked" class="flex items-center gap-2">
    <slot />
  </DropdownMenuCheckboxItem>
</template>

<style scoped lang="scss">

</style>
