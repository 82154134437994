<script setup lang="ts">
import IconSaxEdit from '@/components/Icons/iconsax/IconSaxEdit.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useFocus } from '@vueuse/core'
import { computed, ref } from 'vue'
import { usePutApiUserUpdateDisplayName } from '@/apis/streamladder-accounts/user/user'
import * as Sentry from '@sentry/vue'
import { cn } from '@/lib/utils'

const input = ref<HTMLInputElement>()
const { focused } = useFocus(input)
const userInfo = useUserInfoStore()
const twitchName = ref(userInfo.userName)

const {
  isLoading,
  isSuccess,
  mutate: updateDisplayName,
  isError,
} = usePutApiUserUpdateDisplayName({
  mutation: {
    onSuccess(data) {
      const { userName } = data as unknown as { userName: string }
      userInfo.updateDisplayName(userName)
    },
    onError(error) {
      Sentry.captureException(error)
    },
  },
})

async function onSubmitDisplayName() {
  updateDisplayName({ data: { displayName: twitchName.value } })
}

function onChange() {
  isError.value = false
  isSuccess.value = false
}

const lengthIsOutOfRange = computed(
  () => twitchName.value && (twitchName.value?.length > maxTwitchNameLength || twitchName.value?.length < minTwitchNameLength)
);

const hasError = computed(() => isError.value || lengthIsOutOfRange.value)

const maxTwitchNameLength = 36
const minTwitchNameLength = 3
</script>

<template>
  <form @submit.prevent="onSubmitDisplayName">
    <label class="flex max-w-[50ch] flex-col">
      <span class="text-lg">Twitch name</span>
      <span class="text-sm text-opacity-50"> This is used to suggest clips on your personal home page </span>
      <span
        class="mt-4"
        :class="
          cn(
            'flex h-12 w-full rounded-md border border-surface-input-border text-base text-brand-state-text-primary ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-brand-state-text-placeholder hover:shadow-hover hover:ring-2 hover:ring-brand-state-hover-border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-state-active-border disabled:cursor-not-allowed disabled:opacity-50',
            { 'outline-rose-400': hasError }
          )
        "
      >
        <span class="bg-background flex flex-auto items-center rounded-l-lg">
          <input
            ref="input"
            type="text"
            v-model="twitchName"
            @change="onChange"
            class="flex-auto select-all bg-transparent px-3 py-2 outline-none"
          />
          <span
            class="px-3 text-xs font-light"
            :class="{ 'text-rose-400': lengthIsOutOfRange }"
            v-if="twitchName && twitchName !== userInfo.userName"
          >
            {{ twitchName.length }} / {{ maxTwitchNameLength }}
          </span>
        </span>

        <button
          v-if="isLoading"
          disabled
          type="button"
          class="bg-background flex items-center gap-2 rounded-r-lg px-3 py-2 hover:bg-zinc-200 disabled:pointer-events-none disabled:opacity-75 dark:hover:bg-zinc-900"
        >
          <span class="spinner spinner-border !h-4 !w-4 !border-2" />
          Saving
        </button>

        <span
          v-else-if="twitchName === userInfo.userName && !focused"
          class="bg-background flex cursor-pointer items-center gap-2 rounded-r-lg px-3 py-2 hover:bg-zinc-200 disabled:pointer-events-none disabled:opacity-75 dark:hover:bg-zinc-900"
        >
          <IconSaxEdit />
        </span>

        <button
          :disabled="lengthIsOutOfRange"
          type="submit"
          v-else
          class="bg-background flex cursor-pointer items-center gap-2 rounded-r-lg px-3 py-2 hover:bg-zinc-200 disabled:pointer-events-none disabled:opacity-75 dark:hover:bg-zinc-900"
        >
          Save
        </button>
      </span>
    </label>

    <ul v-if="hasError" class="mx-4 my-2 flex list-disc flex-col gap-1 text-rose-400">
      <li v-if="isError">Something went wrong while saving your new Twitch name. Please try again later.</li>
      <li v-if="twitchName?.length < minTwitchNameLength">
        Your Twitch name should not be shorter than {{ minTwitchNameLength }} characters.
      </li>
      <li v-if="twitchName?.length > maxTwitchNameLength">
        Your Twitch name should not be longer than {{ maxTwitchNameLength }} characters.
      </li>
    </ul>
  </form>
</template>

<style scoped lang="scss"></style>
