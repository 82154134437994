import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'
import { toValue } from '@vueuse/core'
import { getSession } from '@/authentication/supabase'

const useAxiosInstance = (instance: AxiosInstance) => {
  return async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
    const controller = new AbortController()
    const promise = instance({
      ...config,
      ...options,
      signal: controller.signal,
    }).then(({ data }) => data)

    // @ts-ignore
    promise.cancel = () => {
      controller.abort()
    }

    return promise
  }
}

export const streamLadderAxios = axios.create({
  baseURL: import.meta.env.VITE_SERVICES_URL,
})

export const streamLadderAxiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const controller = new AbortController()

  // check if params is a ref and unwrap it
  // for some reason orval accepts Refs as params but doesn't unwrap them
  if (config.params) {
    config.params = toValue(config.params)
  }

  const promise = streamLadderAxios({
    ...config,
    ...options,
    signal: controller.signal,
  }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    controller.abort()
  }

  return promise
}

export const accountsAxios = axios.create({
  withCredentials: true,
  baseURL: import.meta.env.VITE_ACCOUNTS_URL,
})

export const accountsAxiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const executor = useAxiosInstance(accountsAxios)
  return executor(config, options)
}

export const publisherAxios = axios.create({
  withCredentials: false,
  baseURL: import.meta.env.VITE_PUBLISHER_URL,
})

export const publisherAxiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const executor = useAxiosInstance(publisherAxios)
  const response = executor<{ requestId: string; value: T }>(config, options)
  return response.then((r) => r.value)
}

export const clipGPTAxios = axios.create({
  withCredentials: false,
  baseURL: import.meta.env.VITE_CLIPGPT_URL,
})

export const clipGPTAxiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const executor = useAxiosInstance(clipGPTAxios)
  return executor<{ requestId: string; value: T }>(config, options)
}

export const publicAxios = axios.create()

for (const instance of [accountsAxios, streamLadderAxios, publisherAxios, clipGPTAxios]) {
  instance.interceptors.request.use(
    async (config) => {

      const { data: { session } } = await getSession()
      if (session) {
        config.headers.Authorization = `Bearer ${session.access_token}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

axiosRetry(publicAxios, {
  retries: 3,
  retryCondition: (error) => isNetworkOrIdempotentRequestError(error) || error.message === 'Network Error',
  retryDelay: (count) => count * 1000,
})
