
export const DefaultRiveUrl = '/rive/stickers_v3.riv';

export const DecodeImage = async (url: string) => {
    const image = new Image();
    image.src = url;
    await image.decode();
    const bitmap = await createImageBitmap(image);
    return bitmap;
}


export const RiveArtboards = {
    TwitchFollow: '30',
    YoutubeNotification: '31 2',
    TiktokFollow: '32',
    KICK: 'KICK',
    TWITCH: 'TWITCH',
    YOUTUBE: 'YOUTUBE',
    Twitch2: 'twitch 2',
    MixIcons: 'mix icons',
    Subscribe: '1 subscribe',
    Icons3D: '2 3D icons',
    Youtube2: '3 youtube',
    IconsPink: '4 3 icons pink',
    ProfilePicture: '5 with profile picture',
    BounceButton: '6 bounce button ',
    YoutubeProfilePicture: '7 youtube profile picture',
    IconsGreen: '8 3 icons green',
}

type RiveDef = {
    xOffset?:  number
    widthOffset?: number
    yOffset?: number
    heightOffset?: number
    defaultStateMachine?: [string, number][]
    xOriginOffset?: number
    soundEffect?: string
    animationDuration?: number
    minWidth?: number
    sortIndex?: number
    minHeight?: number
    previewOffset?: number
}

export const RiveDefinitions: {
    [key: string]: RiveDef;
} = {
    [RiveArtboards.Twitch2]: {
        xOffset: 0,
        yOffset: 20,
        heightOffset: 300,
        widthOffset: 0,
        minWidth: 800,
        defaultStateMachine: [['Number 1', 1]],
        animationDuration: 3,
        sortIndex: 0,
    },
    [RiveArtboards.TWITCH]: {
        xOffset: 25,
        yOffset: -100,
        widthOffset: 20,
        heightOffset: 400,
        minWidth: 800,
        defaultStateMachine: [['Number 1', 1]],
        animationDuration: 3,
        sortIndex: 1,
    },
    [RiveArtboards.KICK]: {
        xOffset: 25,
        yOffset: -100,
        widthOffset: 20,
        heightOffset: 400,
        minWidth: 800,
        defaultStateMachine: [['Number 1', 1]],
        animationDuration: 3,
        sortIndex: 2,
    },
    [RiveArtboards.YOUTUBE]: {
        xOffset: 25,
        yOffset: -100,
        widthOffset: 20,
        heightOffset: 400,
        minWidth: 800,
        defaultStateMachine: [['Number 1', 1]],
        animationDuration: 3,
        sortIndex: 3,
    },
    [RiveArtboards.TwitchFollow]: {
        xOffset: -110,
        yOffset: 20,
        defaultStateMachine: [['Number 1', 1]],
        soundEffect: '/audio-effects/sounds/sticker_artboard_30.mp3',
        animationDuration: 2,
        sortIndex: 4,
        previewOffset: 100,
    },
    [RiveArtboards.YoutubeNotification]: {
        xOffset: 20,
        widthOffset: 1,
        heightOffset: 100,
        xOriginOffset: 0,
        yOffset: 20,
        soundEffect: '/audio-effects/sounds/sticker_artboard_31.mp3',
        defaultStateMachine: [['Number 1', 1]],
        animationDuration: 3,
        previewOffset: 100,
    },
    [RiveArtboards.TiktokFollow]: {
        xOffset: 4,
        yOffset: -40,
        widthOffset: 20,
        heightOffset: 200,
        minWidth: 500,
        defaultStateMachine: [['Number 1', 1]],
        soundEffect: '/audio-effects/sounds/sticker_artboard_32.mp3',
        animationDuration: 2,
        previewOffset: 100,
    },
    [RiveArtboards.MixIcons]: {
        xOffset: 110,
        yOffset: 0,
        widthOffset: 20,
        heightOffset: 250,
        minWidth: 700,
        defaultStateMachine: [['Number 1', 1]],
        animationDuration: 3
    },
    [RiveArtboards.Subscribe]: {
        "animationDuration": 3,
        "heightOffset": 415,
        "minWidth": 350,
        "widthOffset": 25,
        "xOffset": 7,
        "yOffset": -133,
        "minHeight": 10,
        "defaultStateMachine": [
            [
            "Number 1",
            1
            ]
        ],
        "sortIndex": 3,
        previewOffset: -40,
    },
    [RiveArtboards.Icons3D]: {
        "animationDuration": Infinity,
        "heightOffset": 323,
        "minWidth": 600,
        "widthOffset": 85,
        "xOffset": 0,
        "yOffset": 33,
        "defaultStateMachine": [
            [
            "Number 1",
            1
            ]
        ],
        previewOffset: 40,
    },
    [RiveArtboards.Youtube2]: {
        "animationDuration": 3,
        "heightOffset": 0,
        "minWidth": 410,
        "widthOffset": 0,
        "xOffset": 0,
        "yOffset": 0,
        "defaultStateMachine": [
            [
            "Number 1",
            1
            ]
        ] 
    },
    [RiveArtboards.IconsPink]: {
        "animationDuration": 3,
        "heightOffset": 1,
        "minWidth": 265,
        "widthOffset": 47,
        "xOffset": 29,
        "yOffset": -141,
        "minHeight": 393,
        "defaultStateMachine": [
            [
            "Number 1",
            1
            ]
        ],
        previewOffset: 20
    },
    // [RiveArtboards.ProfilePicture]: {
    //     "animationDuration": 3,
    //     "heightOffset": 314,
    //     "minWidth": 787,
    //     "widthOffset": 560,
    //     "xOffset": 113,
    //     "yOffset": 10,
    //     "defaultStateMachine": [
    //         [
    //         "Number 1",
    //         1
    //         ]
    //     ],
    // },
    [RiveArtboards.BounceButton]: {
        "animationDuration": 5,
        "heightOffset": 2,
        "minWidth": 485,
        "widthOffset": 107,
        "xOffset": -73,
        "yOffset": -121,
        "minHeight": 358,
        "defaultStateMachine": [
            [
            "Number 1",
            1
            ]
        ],
        previewOffset: 20
    },
    // [RiveArtboards.YoutubeProfilePicture]: {
    //     "animationDuration": 3,
    //     "heightOffset": -4,
    //     "minWidth": 652,
    //     "widthOffset": 177,
    //     "xOffset": 0,
    //     "yOffset": -9,
    //     "minHeight": 309,
    //     "defaultStateMachine": [
    //         [
    //         "Number 1",
    //         1
    //         ]
    //     ]
    // },
    [RiveArtboards.IconsGreen]: {
        "animationDuration": 3,
        "heightOffset": 1,
        "minWidth": 265,
        "widthOffset": 47,
        "xOffset": 29,
        "yOffset": -141,
        "minHeight": 393,
        "defaultStateMachine": [
            [
            "Number 1",
            1
            ]
        ],
        previewOffset: 20
    },
}



export function getRiveStickersSelection() {
    const result: { artboard: string, definition: RiveDef }[] = [];

    for (const artboard of Object.keys(RiveDefinitions)) {
        result.push({ artboard, definition: RiveDefinitions[artboard] });
    }

    result.sort((a,b) => (a.definition.sortIndex ?? 9999) - (b.definition.sortIndex ?? 9999));

    return result;
}