<script lang="ts" setup>
import { type Transcript, type TranscriptWord, type TranscriptWordEffect, useCaptionsStore } from '@/areas/editor/store/useCaptionsStore';
import { useVideoStore } from '@/areas/editor/store/useVideoStore';
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import IconSaxMagicStar from '@/components/Icons/iconsax/IconSaxMagicStar.vue';
import { Button } from '@/components/ui/button';
import IconSaxBlur from '@/components/Icons/iconsax/IconSaxBlur.vue';
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue';
import type { CaptionPresetVariant } from '@/components/Captions/v3/CaptionPreset';
import { v4 as uuid } from 'uuid';
import IconSaxActivity from '@/components/Icons/iconsax/IconSaxActivity.vue';
import { onClickOutside } from '@vueuse/core';
import ToggleButton from '@/components-v2/data-input/ToggleButton.vue';
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { ColorInput } from '@/components/colors'
import { useIsMobile } from '@/Hooks/useIsMobile';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

const props = defineProps<{
  caption: Transcript
  isNew: boolean
  isActive: boolean
}>();

const captionRef = ref<HTMLTextAreaElement | null>(null);

const toolbarRef = ref<HTMLDivElement | null>(null);
const captionToolbarChevron = ref<HTMLDivElement | null>(null);

const captionsStore = useCaptionsStore();
const videoStore = useVideoStore();
const historyStore = useHistoryStore();
const isMobile = useIsMobile();

const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors();

const pendingUpdates = ref<string | null>(null);

const setPendingUpdates = (caption: Transcript, event: Event) => {
  const inputElement = event.target as HTMLTextAreaElement;
  pendingUpdates.value = inputElement.value;
  autoResizeTextarea();
};

const commitChanges = (caption: Transcript, event: Event) => {
  // This means that the target is a newly added caption and the user has not typed anything.
  const target = event.target as HTMLTextAreaElement;
  if (target && target.value === '' && caption.words.length === 1 && caption.words[0].text === '') {
    return;
  }

  isEditingElement.value = null;

  setPendingUpdates(caption, event);

  const hasChanges = pendingUpdates.value !== caption.words.map(w => w.text).join(' ');
  if (hasChanges || pendingUpdates.value === '') {
    captionsStore.updateCaptionWordsById(caption.id, pendingUpdates.value);
    pendingUpdates.value = null;
    historyStore.transaction('CAPTIONS:EDIT_TEXT');
  }

  if (captionRef.value instanceof HTMLTextAreaElement) {
    captionRef.value.blur();
  }

  captionsStore.captionIdBeingEdited = null;
  captionsStore.wordIdBeingEdited = null;

  if (!props.isActive) {
    showToolbar.value = false;
    selectedWords.value = [];
  }
};

const autoResizeTextarea = () => {
  if (captionRef.value instanceof HTMLTextAreaElement) {
    captionRef.value.style.height = 'auto'; // Reset height to recalculate
    captionRef.value.style.height = `${captionRef.value.scrollHeight}px`; // Set height based on scrollHeight
  }
};

watch([() => props.caption.words, () => captionRef.value], () => {
  nextTick(() => autoResizeTextarea()); // Ensure resizing on caption change
}, { immediate: true });

const isEditingElement = ref<HTMLTextAreaElement | null>(null);

const onFocus = (caption: Transcript, e: Event) => {

  isEditingElement.value = e.target as HTMLTextAreaElement;

  videoStore.playing = false;

  if (caption) {

    captionsStore.captionIdBeingEdited = caption?.id ?? null;

    // Set the wordIdBeingEdited to the last word so the video jumps to the end of the caption
    if (!captionsStore.wordIdBeingEdited) {
      captionsStore.wordIdBeingEdited = caption.words[caption.words.length - 1].id;
    }
  }

  if (caption && caption.words.length && captionsStore.baseOptions.grouping !== 'single') {
    const rendererCaption = captionsStore.findRendererCaptionByWordId(captionsStore.wordIdBeingEdited);
    if (rendererCaption) {
      const lastWord = rendererCaption.words[rendererCaption.words.length - 1];
      const middleOfWord = lastWord.start + (0.5 * (lastWord.end - lastWord.start));
      videoStore._currentTime = middleOfWord / 1000;
    }
  }
};

const onSelect = (caption: Transcript, event: Event) => {

  if (!isEditingElement.value) {
    return;
  }

  // This means that the target is a newly added caption and the user has not typed anything.
  const target = event.target as HTMLTextAreaElement;
  if (target && target.value === '' && caption.words.length === 1 && caption.words[0].text === '') {
    return;
  }

  if (isEditingElement.value === event.target) {

    setPendingUpdates(caption, event);

    const hasChanges = pendingUpdates.value !== caption.words.map(w => w.text).join(' ');
    if (hasChanges || pendingUpdates.value === '') {
      historyStore.transaction('CAPTIONS:EDIT_TEXT', () => {
        captionsStore.updateCaptionWordsById(caption.id, pendingUpdates.value ?? '');
      });
      pendingUpdates.value = null;
    }
  }

  const start = target.selectionStart;
  const end = target.selectionEnd;

  findAllTranscriptWordsInSelection(caption, start, end);

  setVideoCurrentTimeBasedOnSelection();

  if (selectedWords.value.length > 0) {

    const wordElementStart = document.getElementById(`word-${selectedWords.value[0].id}`);
    const wordElementEnd = document.getElementById(`word-${selectedWords.value[selectedWords.value.length - 1].id}`);

    if (wordElementStart && wordElementEnd) {
      const boundingClientRectStart = wordElementStart.getBoundingClientRect();
      const boundingClientRectEnd = wordElementEnd.getBoundingClientRect();

      // Initial centering of the toolbar above the selected words
      toolbarLeft.value =
        0.5 * (boundingClientRectStart.left + boundingClientRectEnd.left) +
        (0.5 * boundingClientRectEnd.width);
      
      toolbarTop.value = boundingClientRectStart.top - 40;

      if (isMobile) {
        // Wait for next tick to ensure toolbarRef is rendered and its dimensions are available
        nextTick(() => {
          const toolbarWidth = toolbarRef.value?.offsetWidth || 0;
          const margin = 10;
          const halfToolbar = toolbarWidth / 2;
          const viewportWidth = window.innerWidth;
  
          // Adjust if toolbar goes off the left edge
          if (toolbarLeft.value - halfToolbar < 0) {
            toolbarLeft.value = halfToolbar + margin;
          }
          // Adjust if toolbar goes off the right edge
          else if (toolbarLeft.value + halfToolbar > viewportWidth) {
            toolbarLeft.value = viewportWidth - halfToolbar;
          }
  
          // Calculate chevron position above the first selected word
          // Assuming the chevron should point to the first word in the selection:
          const wordCenter = boundingClientRectStart.left + (boundingClientRectStart.width / 2);
          // Determine toolbar's left edge position after adjustments
          const toolbarEdge = toolbarLeft.value - halfToolbar;
          // Chevron's left offset relative to the toolbar container
          const chevronOffset = wordCenter - toolbarEdge - (margin / 2);
          
          captionToolbarChevron.value?.style.setProperty('left', `${chevronOffset}px`);
        });
      }

      showToolbar.value =
        selectedWords.value.length > 0 ||
        (selectedWords.value.length > 0 &&
          selectedWords.value.every((w) => (w.effects?.length ?? 0) > 0)) ||
        props.isActive;
    }
  }
};

const setVideoCurrentTimeBasedOnSelection = () => {
  const word = selectedWords.value[selectedWords.value.length - 1];
  const middleOfWord = word.start + (0.5 * (word.end - word.start));
  videoStore._currentTime = middleOfWord / 1000;
};

const findAllTranscriptWordsInSelection = (caption: Transcript, start: number, end: number) => {

  const wholeCaptionAsString = caption.words.map(w => w.text).join(' ');

  const startMinusAmountOfSpaces = wholeCaptionAsString.slice(0, start).split(' ').length;
  const endMinusAmountOfSpaces = wholeCaptionAsString.slice(0, end).split(' ').length;

  // Set selectedWords to the words between and including startWord and endWord
  selectedWords.value = caption.words.filter((word, index) => {
    return index >= startMinusAmountOfSpaces - 1 && index <= endMinusAmountOfSpaces - 1;
  });
};

const currentTimeMs = computed(() => {
  return videoStore.currentTimeMs;
});

const activeWord = computed(() => {
  return props.caption.words.find(w => w.start <= currentTimeMs.value && w.end >= currentTimeMs.value);
});

onMounted(() => {
  // check if new caption is empty and focus it
  nextTick(() => {
    if (props.isNew && captionRef.value) {
      captionRef.value.focus()
    }
  });
});

const showToolbar = ref(false);
const toolbarLeft = ref(0);
const toolbarTop = ref(0);

const selectedWords = ref<TranscriptWord[]>([]);

const deleteSelectedWords = (selectedWords: TranscriptWord[]) => {

  const ids = selectedWords.map(w => w.id);

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:DELETE_WORDS', () => {
      captionsStore.deleteWordsByIds(ids);
    });
  }

  showToolbar.value = false;
};

const removeEffectsFrom = (selectedWords: TranscriptWord[], type: string | null = null) => {

  const ids = selectedWords.map(w => w.id);

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:REMOVE_EFFECTS', () => {
      captionsStore.removeEffectsFromWordsByIds(ids, type);
    });
  }
};

const shakeSelectedWords = (selectedWords: TranscriptWord[]) => {

  const ids = selectedWords.map(w => w.id);

  const effect: TranscriptWordEffect = {
    id: uuid(),
    type: 'shake',
    animation: 'float-around',
    size: 'medium',
  };

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:ADD_SHAKE_EFFECT', () => {
      captionsStore.addEffectToWordsByIds(ids, effect);
    });
  }
};

const supersizeSelectedWords = (selectedWords: TranscriptWord[]) => {

  const ids = selectedWords.map(w => w.id);

  const effect: TranscriptWordEffect = {
    id: uuid(),
    type: 'supersize',
    animation: captionsStore.baseOptions.animation,
    size: 'large',
  };

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:ADD_SUPERSIZE_EFFECT', () => {
      captionsStore.addEffectToWordsByIds(ids, effect);
    });
  }
};

const colorPicker = ref<HTMLDivElement | null>(null);
const showColorPickerFor = ref<TranscriptWord[]>([]);

onClickOutside(colorPicker, () => {
  showColorPickerFor.value = [];
});

watch(() => props.isActive, () => {
  if (!props.isActive) {
    showToolbar.value = false;
  }
});

watch(() => showToolbar.value, () => {
  if (!showToolbar.value) {
    selectedWords.value = [];
    showColorPickerFor.value = [];
  }
}, { immediate: true });

const currentColor = computed<string>({
  get: () => {
    const firstCaptionVariant = showColorPickerFor.value?.[0]?.captionVariant as CaptionPresetVariant;
    if (firstCaptionVariant && !firstCaptionVariant.font?.color?.variant) {
      return firstCaptionVariant.font.color as string;
    } else {
      return captionsStore.baseCaptionPreset?.font?.color as string ?? '#ffffff';
    }
  },
  set: (value: string) => {
    commitColorChange(showColorPickerFor.value, value);
  },
});

const commitColorChange = (selectedWords: TranscriptWord[], color = '#ffffff') => {

  if (selectedWords.length === 0) {
    return;
  }

  historyStore.transaction('CAPTIONS:CHANGE_COLOR', () => {
    captionsStore.updateWordsColor(selectedWords, color);
  });
};

const textAreaValue = computed(() => {
  return props.caption.words.map(w => w.text).join(' ');
});


const selectedWordsHaveShakeEffectModel = computed({
  get() {
    return selectedWords.value.every((w) => w.effects?.some((e) => e.type === 'shake'));
  },
  set(shake: boolean) {
    if (shake) {
      shakeSelectedWords(selectedWords.value);
    } else {
      removeEffectsFrom(selectedWords.value, 'shake');
    }
  },
});

const selectedWordsHaveSupersizeEffectModel = computed({
  get() {
    return selectedWords.value?.every((w) => w.effects?.some((e) => e.type === 'supersize'));
  },
  set(supersize: boolean) {
    if (supersize) {
      supersizeSelectedWords(selectedWords.value);
    } else {
      removeEffectsFrom(selectedWords.value, 'supersize');
    }
  },
});

const captionLineRef = ref<HTMLDivElement | null>(null);
onClickOutside(captionLineRef, () => {
  if (showColorPickerFor.value.length === 0 && !showWordCorrect.value) {
    showToolbar.value = false;
  }
});

const showWordCorrectRef = ref<HTMLFormElement | null>(null);

const correctWord = ref<string>('');
const showWordCorrect = ref(false);
const openWordCorrect = () => {
  showWordCorrect.value = true;
  correctWord.value = selectedWords.value.map(w => w.text).join(' ').trim();
  captionsStore.showWordHighlights(selectedWords.value);
};

const applyReplace = () => {

  if (correctWord.value.length > 0 && selectedWords.value.length > 0) {
    historyStore.transaction('CAPTIONS:CORRECT_WORD', () => {
      captionsStore.replaceWordsInSentence(selectedWords.value, correctWord.value, false);
    });
  }

  hideToolbar();
  showWordCorrect.value = false;
  captionsStore.hideWordHighlights();
};

const applyReplaceAll = () => {

  if (correctWord.value.length > 0 && selectedWords.value.length > 0) {
    historyStore.transaction('CAPTIONS:CORRECT_WORD', () => {
      captionsStore.replaceWordsInSentence(selectedWords.value, correctWord.value);
    });
  }

  hideToolbar();
  showWordCorrect.value = false;
  captionsStore.hideWordHighlights();
};

const hideToolbar = () => {
  showToolbar.value = false;
  captionsStore.hideWordHighlights();
};
</script>

<template>
  <div ref="captionLineRef" class="w-full relative">
    <Transition
      :enter-from-class="'opacity-0 translate-y-2'"
      :leave-to-class="'opacity-0 -translate-y-2'"
      enter-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
      leave-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
    >
      <div
        v-if="showToolbar"
        ref="toolbarRef"
        :style="{
          top: toolbarTop + 'px',
          left: toolbarLeft + 'px'
        }"
        class="fixed z-20 bg-surface-panel border border-surface-border dark:border-surface-input-border rounded-md shadow-md flex -translate-x-1/2"
        @mousedown.prevent
      >
        <div class="flex relative w-full h-full">

          <div ref="captionToolbarChevron" class="absolute pointer-events-none bottom-0 -translate-x-1/2 translate-y-1/2 left-1/2 w-3 h-3 rotate-45 bg-surface-panel border border-surface-border dark:border-surface-input-border shadow-md z-10"></div>

          <div class="flex w-full h-full bg-surface-panel z-20">
            <Tooltip disable-hoverable-content>
              <TooltipTrigger>
                <Button
                  tabindex="-1"
                  variant="ghost"
                  size="sm"
                  class="rounded-r-none rounded-l-sm font-medium"
                  @click="openWordCorrect"
                  @mousedown.prevent
                >
                  Replace
                </Button>
              </TooltipTrigger>
              <TooltipContent class="font-light">
                Replace this word with a new one in all sentences it appears
              </TooltipContent>
            </Tooltip>

            <div class="w-px bg-secondary" />

            <Tooltip disable-hoverable-content>
              <TooltipTrigger>
                <ColorInput
                  v-model="currentColor"
                  :recently-used-colors="recentlyUsedCaptionColors"
                  :recommended-colors="recommendedColors.LIGHT"
                  side="right"
                  @close="(color: string) => { showColorPickerFor = []; setRecentlyUsedColor(color) }"
                >
                  <Button
                    tabindex="-1"
                    variant="ghost"
                    size="sm"
                    class="rounded-r-none rounded-l-sm"
                    @mousedown.prevent
                    @click="showColorPickerFor = selectedWords"
                  >
                    <IconSaxBlur class="stoke-[1] h-4 w-4" />
                    <div
                      class="absolute w-4 h-0.5 bottom-0.5 rounded"
                      :style="`background-color: ${ selectedWords[0]?.captionVariant?.font?.color || 'transparent' }`"
                    />
                  </Button>
                </ColorInput>
              </TooltipTrigger>
              <TooltipContent class="font-light">
                {{ selectedWords.length > 1 ? 'Change the color of selected words' : 'Change color' }}
              </TooltipContent>
            </Tooltip>

            <div class="w-px bg-secondary" />

            <Tooltip disable-hoverable-content>
              <TooltipTrigger>
                <ToggleButton
                  v-model="selectedWordsHaveSupersizeEffectModel"
                  class="rounded-none !border-none"
                  size="sm"
                  tabindex="-1"
                  variant="ghost"
                >
                  <IconSaxMagicStar
                    :class="selectedWordsHaveSupersizeEffectModel ? 'text-company-primary-100' : ''"
                    class="stoke-[1] h-4 w-4"
                  />
                </ToggleButton>
              </TooltipTrigger>
              <TooltipContent class="font-light">
                <template v-if="selectedWordsHaveSupersizeEffectModel">
                  {{ selectedWords.length > 1 ? 'Remove all effects from selected words' : 'Remove supersize' }}
                </template>
                <template v-else>
                  {{ selectedWords.length > 1 ? 'Add supersize effect to selected words' : 'Supersize' }}
                </template>
              </TooltipContent>
            </Tooltip>

            <div class="w-px bg-secondary" />

            <Tooltip disable-hoverable-content>
              <TooltipTrigger>
                <ToggleButton
                  v-model="selectedWordsHaveShakeEffectModel"
                  class="rounded-none !border-none"
                  size="sm"
                  tabindex="-1"
                  variant="ghost"
                >
                  <IconSaxActivity
                    :class="selectedWordsHaveShakeEffectModel ? 'text-company-primary-100' : ''"
                    class="stoke-[1] h-4 w-4"
                  />
                </ToggleButton>
              </TooltipTrigger>
              <TooltipContent class="font-light">
                <template v-if="selectedWordsHaveShakeEffectModel">
                  {{ selectedWords.length > 1 ? 'Remove shake from selected words' : 'Remove shake' }}
                </template>
                <template v-else>
                  {{ selectedWords.length > 1 ? 'Shake selected words' : 'Shake' }}
                </template>
              </TooltipContent>
            </Tooltip>

            <div class="w-px bg-secondary" />

            <Tooltip disable-hoverable-content>
              <TooltipTrigger>
                <Button
                  size="sm"
                  tabindex="-1"
                  variant="ghost"
                  class="rounded-l-none rounded-r-sm"
                  @mousedown.prevent
                  @click.prevent.stop="deleteSelectedWords(selectedWords)"
                >
                  <IconSaxTrash class="stoke-[1] h-4 w-4 text-red-500" />
                </Button>
              </TooltipTrigger>
              <TooltipContent class="font-light">
                {{ selectedWords.length > 1 ? 'Delete selected words' : 'Delete word' }}
              </TooltipContent>
            </Tooltip>
          </div>
        </div>
      </div>
    </Transition>

    <div
      :class="captionsStore.captionIdHighlighted === caption.id ? 'animate-highlight' : ''"
      class="flex relative w-full items-center justify-center rounded-md"
      translate="no"
    >
      <div class="absolute top-0 leading-[25px] left-0 p-1.5 w-full h-full bg-transparent z-10 pointer-events-none opacity-100">
        <div
          v-for="word in caption.words"
          :id="'word-'+word.id"
          :key="word.id"
          class="inline rounded-md font-light"
        >
          <span
            :class="{
              'before:!bg-[#ff0] rounded !text-black !opacity-100 !font-medium': captionsStore.wordIdsHighlighted?.includes(word.id),
              'before:bg-purple-500 text-white opacity-100': activeWord?.id === word.id && !isEditingElement,
              'underline underline-offset-4 decoration-2 decoration-primary opacity-100 text-transparent cursor-pointer': word.effects?.length > 0,
              'underline underline-offset-4 decoration-2 decoration-zinc-900 opacity-100 text-transparent cursor-pointer': selectedWords.includes(word),
              '!opacity-100 text-transparent': word.captionVariant?.font?.color,
            }"
            :style="{
              'border-bottom': '2px solid' + word.captionVariant?.font?.color,
            }"
            class="relative transition-[opacity,colors] opacity-0 inline-block before:rounded-[3px] before:absolute before:-inset-x-0.5 before:inset-y-0.5 before:z-[-1]"
          >
            {{ word.text }}
          </span>
          {{ ' ' }}
        </div>
      </div>

      <textarea
        :id="'caption-'+caption.id"
        ref="captionRef"
        :class="{
          '!bg-blue-50 dark:!bg-surface-panel-100': isActive,
        }"
        :value="textAreaValue"
        class="bg-transparent w-full rounded-md leading-[25px] p-1.5 font-light outline-0 resize-none hover:bg-blue-50 dark:hover:bg-surface-panel-100 dark:bg-surface-panel transition-[background-color,transform,border-color] relative"
        placeholder="Type your new caption here.."
        rows="1"
        @blur="e => commitChanges(caption, e)"
        @click="e => onSelect(caption, e)"
        @focus="e => onFocus(caption, e)"
        @input="e => setPendingUpdates(caption, e)"
        @select="e => onSelect(caption, e)"
        @keydown.enter.exact="e => commitChanges(caption, e)"
        @keydown.shift.enter.stop
      />
    </div>

    <Popover @update:open="(open) => !open && hideToolbar()" v-model:open="showWordCorrect">
      <PopoverTrigger class="absolute right-0 top-14" />
      <PopoverContent side="top" align="start" :sideOffset="5" class="w-full p-3 ml-2 shadow-2xl">
        <form :ref="showWordCorrectRef" @submit.prevent="applyReplaceAll" class="flex flex-col justify-start gap-1">
          <header>
            <h3 class="text-lg font-semibold">
              {{ selectedWords.length > 1 ? 'Replace words' : 'Replace word' }}
            </h3>
          </header>
          <div class="flex gap-2">
            <label class="flex flex-col text-sm font-light">
              <input v-model.trim="correctWord" placeholder="Correct word" type="text" class="p-[9px] border border-surface-border dark:bg-zinc-200 dark:border-surface-input-border rounded-md" />
            </label>
            <Button variant="primary" type="submit" class="rounded-md font-medium text-sm" @click="showWordCorrect = false">
              Replace All
            </Button>
            <Button variant="outline" type="submit" class="rounded-md font-medium text-sm" @click="applyReplace">
              Replace
            </Button>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  </div>
</template>

<style lang="scss" scoped>
textarea {
  overflow: hidden; /* Prevent scrollbar from appearing */
}
</style>
