import { ref } from 'vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { createGlobalState, useRafFn } from '@vueuse/core';
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Segment } from '@/areas/editor/@type/Project';
import { orderBy } from 'lodash-es';

export const useCurrentSegment = createGlobalState(() => {

  const segmentsStore = useSegmentsStore();
  const videoStore = useVideoStore();
  const segments = segmentsStore.whereIsNotZoom();
  const segment = ref<Segment | null>(null);
  
  function isCurrent(segment: Segment | null | undefined) {
    return segment
      && Math.ceil(segment.startMs) <= Math.ceil(videoStore.currentTimeMs)
      && Math.ceil(segment.endMs) >= Math.ceil(videoStore.currentTimeMs);
  }

  useRafFn(() => {
    
    // When the store is reset or the current segment is removed, reset the current segment.
    if (!segments.value.length || !segments.value.some(s => s.id === segment.value?.id)) {
      segment.value = null;
    }

    if (isCurrent(segment.value)) {
      return;
    }

    const sortedSegments = orderBy(segments.value, 'startMs', 'asc');
    const currentSegment = sortedSegments.find(isCurrent);
    const firstSegment = sortedSegments[0];

    if (currentSegment) {
      segment.value = currentSegment
    } else if (firstSegment) {
      segment.value = firstSegment;
    }
  })

  return segment
})
