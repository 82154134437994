<script lang="ts" setup>
import type { Clip } from '@/Hooks/useTwitchClips'
import logging from '@/logging'
import { ref } from 'vue'
import { Button } from '@/components/ui/button'
import { useIsMobile } from '@/Hooks/useIsMobile'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import { Skeleton } from '@/components/ui/skeleton'
import { TooltipProvider } from 'radix-vue'

const props = defineProps<{
  clip?: Clip | null | undefined
  isLoading?: boolean
  disablePreview?: boolean
  overlayScaling?: string
}>()

const emit = defineEmits<{
  (name: 'select', clip: Clip): void
  (name: 'preview', clip: Clip): void
}>()

function select(clip: Clip) {
  logging.trackEvent('Twitch Clip Selected')
  emit('select', clip)
}

function preview(clip: Clip) {
  logging.trackEvent('Twitch Clip Previewed')
  emit('preview', clip)
}

const loaded = ref(false)

const isMobile = useIsMobile()

const handleCardClick = (clip: Clip) => {
  if (isMobile.value) {
    if (!props.disablePreview) {
      preview(clip);
    } else {
      select(clip);
    }
  }
}

const error = ref(false)
function onThumbnailBroken() {
  error.value = true
  loaded.value = true
}
</script>

<template>
  <article class="flex flex-col gap-1">
    <Skeleton v-if="isLoading" class="rounded-xl w-full aspect-video" />
    <figure
      v-else
      :class="{ 'group transition-transform hover:scale-105': !error }"
      class="layer-0 relative flex aspect-video flex-col overflow-hidden rounded-xl"
    >
      <template v-if="clip">
        <img
          v-if="!error"
          :alt="`Thumbnail of '${clip.title}'`"
          :class="loaded ? 'scale-100 opacity-100' : 'scale-90 opacity-0'"
          :src="clip.thumbnailUrl"
          class="aspect-video h-full w-full rounded-xl object-contain transition-all"
          @load="loaded = true"
          @error="onThumbnailBroken"
        />
        <div class="w-full h-full grid place-items-center text-red-400 bg-red-50 dark:bg-red-900 dark:text-red-300" v-else>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger class="cursor-default">
                <IconSaxWarning2 />
              </TooltipTrigger>
              <TooltipContent>
                <p>Clip could not be loaded.<br/>Please try again later.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </template>

      <span
        v-if="clip && !disablePreview && !error"
        class="pointer-events-none absolute inset-1 top-auto flex items-end justify-between text-xs text-foreground"
      >
        <span class="flex rounded-lg bg-surface-bg px-1 py-1 line-clamp-1 text-ellipsis overflow-hidden">
          {{ clip.viewCount }} {{ clip.viewCount === 1 ? 'view' : 'views' }}
        </span>

        <span v-if="clip.dateFromNow" class="flex rounded-lg bg-surface-bg px-1 py-1 line-clamp-1 text-ellipsis overflow-hidden">
          {{ clip.dateFromNow }}
        </span>
      </span>

      <div
        v-if="clip"
        class="pointer-events-none absolute inset-0 h-full p-4 w-full rounded-[10px] bg-black/25 opacity-100 md:opacity-0 transition-all group-hover:pointer-events-auto group-hover:opacity-100"
        @click="() => handleCardClick(clip!)"
      >
        <div
          v-if="!isMobile"
          :class="{ 'md:scale-[0.8]': overlayScaling }"
          class="flex h-full w-full origin-center flex-col items-center justify-center gap-1"
        >
          <Button variant="gradient" class="w-full" @click="select(clip!)">Select clip</Button>
          <Button variant="dashboardOutline" v-if="!disablePreview" class="w-full" @click="preview(clip)">
            Preview
          </Button>
        </div>
      </div>
    </figure>

    <header v-if="!disablePreview" class="flex flex-col gap-2">
      <Skeleton v-if="isLoading" class="w-4/5 h-4 rounded-xl" />
      <h6 v-else class="line-clamp-2 tracking-tighter md:tracking-normal text-sm md:text-base leading-tight font-light md:font-semibold text-brand-state-text-primary">
        {{ clip?.title }}
      </h6>
    </header>
  </article>
</template>

<style lang="scss" scoped></style>
