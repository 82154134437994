<script setup lang="ts">
import { computed, onMounted } from "vue";
import { Dialog, DialogContent, DialogTrigger, DialogDescription, DialogFooter, DialogTitle, DialogClose } from "@/components/ui/dialog";
import { Select, SelectTrigger, SelectContent, SelectItem } from '@/components/ui/select'
import { Input } from "@/components/ui/input";
import { type FontLabel, fontsData } from "@/data/fonts";
import { useFontsStore } from "@/store/fonts";
import BrandKitStyleWrapper from "@/areas/editor/views/text/brand-kit/BrandKitStyleWrapper.vue";
import { oldTextStylesArray } from "@/components/Stickers/textLibrary";
import Tile from "@/components/Tile.vue";
import DiscordLogo from "@/components/Icons/DiscordLogo.vue";
import settings from "@/data/settings";
import { Button } from "@/components/ui/button";

const fonts = fontsData.fonts;

const preview = defineModel<string>('preview', { required: true });
const fontFamily = defineModel<FontLabel>('fontFamily', { required: true });
const selected = defineModel<(typeof oldTextStylesArray)[number]['variant']>('selected', { required: true });
const primary = defineModel<string>('primary', { required: true });
const secondary = defineModel<string>('secondary', { required: true });

const fontsStore = useFontsStore();
onMounted(async () => {
  await fontsStore.initialize();
});

const style = computed(() => {
  return oldTextStylesArray.find(style => style.variant === selected.value)!;
});
</script>

<template>
  <Dialog>
    <DialogTrigger>
      <slot />
    </DialogTrigger>
    <DialogContent class="w-full max-w-screen-2xl p-0">
      <section class="flex flex-col-reverse md:grid min-h-0 w-full md:grid-cols-5">
        <div class="flex flex-col gap-6 overflow-y-auto p-6 md:col-span-3 md:p-8">
          <header>
            <DialogTitle class="text-2xl font-bold">Create a new style</DialogTitle>
            <DialogDescription class="font-light opacity-75">
              Choose your own font, color scheme and text style to create a unified look for all of your videos.
            </DialogDescription>
          </header>

          <label class="flex flex-col gap-1">
            <span>Preview text</span>
            <Input v-model="preview" />
          </label>

          <label class="flex flex-col gap-1">
            <span>Font</span>
            <Select v-model="fontFamily">
              <SelectTrigger class="bg-surface-panel-100 h-12">
                {{ fontFamily }}
              </SelectTrigger>
              <SelectContent>
                <SelectItem
                  v-for="font in fonts"
                  :key="font.label"
                  :value="font.label"
                  :style="{ fontFamily: font.label }"
                >
                  {{ font.label }}
                </SelectItem>
              </SelectContent>
            </Select>
          </label>

          <div class="flex flex-col gap-1">
            <span>Text style</span>
            <div class="min-h-0 overflow-y-auto overflow-x-hidden -m-2 p-2">
              <div class="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
                <BrandKitStyleWrapper
                  v-for="style in oldTextStylesArray"
                  :key="style.key" 
                  :style="{ ...style, primaryColor: primary, secondaryColor: secondary }"
                  :selected="selected === style.variant"
                  :text-content="preview"
                  :aspect="1"
                  @select="() => (selected = style.variant)"
                />
                <a class="block" :href="settings.discordInviteUrl" target="_blank">
                  <Tile class="text-indigo-500">
                    <DiscordLogo class="h-10 w-10 fill-current" />

                    <span>Your suggestion here?</span>
                    <span class="text-xs">Leave your idea in our discord!</span>
                  </Tile>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row md:items-center justify-start gap-4">
            <label class="flex flex-col gap-1">
              <span>Primary color</span>
              <Input as="label" class="relative items-center justify-between gap-2 w-auto min-w-[200px]">
                <Input v-model="primary" type="color" class="opacity-0 absolute" />
                <span class="text-sm uppercase">{{ primary }}</span>
                <span
                  :style="{
                    backgroundColor: primary,
                    '--tw-shadow-color': primary,
                     '--tw-shadow': 'var(--tw-shadow-colored)'
                  }"
                  class="w-4 h-4 rounded-full shadow border border-border"
                />
              </Input>
            </label>

            <label class="flex flex-col gap-1">
              <span>Secondary color</span>
              <Input as="label" class="relative items-center justify-between gap-2 w-auto min-w-[200px]">
                <Input v-model="secondary" type="color" class="opacity-0 absolute" />
                <span class="text-sm uppercase">{{ secondary }}</span>
                <span
                  :style="{
                      backgroundColor: secondary,
                      '--tw-shadow-color': secondary,
                       '--tw-shadow': 'var(--tw-shadow-colored)'
                    }"
                  class="w-4 h-4 rounded-full shadow border border-border"
                />
              </Input>
            </label>
          </div>
        </div>
        <div class="grid min-h-[300px] place-items-center border-none p-6 bg-background md:col-span-2">
          <Component
            class="[&_*]:!cursor-default [&_*]:!whitespace-pre-wrap"
            :editable="false"
            :is="style.component"
            :primary-color="primary"
            :secondary-color="secondary"
            :font-family="fontFamily"
            :html-content="preview"
            :variant="style.variant"
          />
        </div>
      </section>
      <DialogFooter class="px-6 pb-6 md:px-8 md:pb-8 flex flex-row items-center justify-between sm:justify-between">
        <DialogClose>
          <Button variant="outline">Cancel</Button>
        </DialogClose>
        <slot name="save" />
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
