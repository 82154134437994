import { fetchWithRetry } from "@/lib/fetchWithRetry";
import { decompressFrames, parseGIF } from "gifuct-js";


export async function decodeGif(sourceUrl: string) {
    const outputFrames = [];
    let duration = 0;
    let width = 0;
    let height = 0;

    const url = 'https://wsrv.nl/?output=gif&n=-1&url=' + sourceUrl;
    const buffer = await (await fetchWithRetry(url)).arrayBuffer();
    const gif = parseGIF(buffer);

    if (gif.frames.length === 0) {
        outputFrames.push({
           bitmap: null,
           timestamp: 0,
           duration: Infinity
        });
        duration = 1;

        return { outputFrames, duration, width, height }
    }

    width = gif.lsd.width;
    height = gif.lsd.height;

    const frames = decompressFrames(gif, true)

    // gif patch canvas
    const tempCanvas = new OffscreenCanvas(frames[0].dims.width, frames[0].dims.height);
    const tempCtx = tempCanvas.getContext('2d')!
    // full gif canvas
    const gifCanvas = new OffscreenCanvas(tempCanvas.width, tempCanvas.height)
    const gifCtx = gifCanvas.getContext('2d')!

    gifCanvas.width = tempCanvas.width;
    gifCanvas.height = tempCanvas.height;

    let timestamp = 0;
    let needsDisposal = false;
    let frameImageData = null;

    for (const frame of frames) {

        if (needsDisposal) {
            gifCtx.clearRect(0, 0, gifCanvas.width, gifCanvas.height)
            needsDisposal = false
        }
          
        const dims = frame.dims

        if (
          !frameImageData ||
          dims.width != frameImageData.width ||
          dims.height != frameImageData.height
        ) {
          tempCanvas.width = dims.width
          tempCanvas.height = dims.height
          frameImageData = tempCtx.createImageData(dims.width, dims.height)
        }
      
        // set the patch data as an override
        frameImageData.data.set(frame.patch)
      
        // draw the patch back over the canvas
        tempCtx.putImageData(frameImageData, 0, 0)
      
        gifCtx.drawImage(tempCanvas, dims.left, dims.top)
     
        const bitmap = await createImageBitmap(gifCanvas)

        outputFrames.push({
            bitmap,
            timestamp: timestamp * 1e3,
            duration: frame.delay * 1e3,
            dims: frame.dims,
        })

        timestamp += frame.delay;
        duration = timestamp * 1e3;

        if (frame.disposalType === 2) {
            needsDisposal = true;
        }
    }

    return { outputFrames, duration, width, height }
}