<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useStickersStore } from "@/areas/editor/store/useStickersStore";
import { useHistoryStore } from "@/areas/editor/store/useHistoryStore";
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { Button } from '@/components/ui/button'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { DecodeImage, DefaultRiveUrl, getRiveStickersSelection } from '@/webcodec-renderer/rive/rive-definitions'
import { RiveRenderer } from '@/webcodec-renderer/worker/rive-renderer'
import { useIntervalFn } from '@vueuse/core'
import { onUserInfoReady, useUserInfoStore } from '@/store/user/userInfo'
import Tile from '@/components/Tile.vue'

const stickerSelection = getRiveStickersSelection().slice(0, 4);
const stickers = stickerSelection.map(({artboard}) => ({
    area: { x: 0, y: 0, width: 0.7873, height: 0.137 },
    startMs: 0, endMs: 100e6, z: 0, editing: false, scale: 1, id: '123', key: '321',
    textContent: 'StreamLadder', 
    animation: '',
    artboard,
    stateMachineInputs: [],
    icon: '1',
}));

const stickerCanvasElements = ref<HTMLCanvasElement[]>();

const stickerText = ref('');

const userInfoStore = useUserInfoStore();

onUserInfoReady(() => {
  if (!stickerText.value) {
    stickerText.value = userInfoStore.userName || 'StreamLadder';
    stickers.forEach(sticker => {
        sticker.textContent = stickerText.value;
    });
  }
});

const stickersStore = useStickersStore();
const historyStore = useHistoryStore();
const editorFocusStore = useEditorFocusStore();

const { currentSection } = useEditorStep();
const viewAll = () => {
  currentSection.value = editorRouteNames.rive;
};

const renderers: RiveRenderer[] = [];

onMounted(async () => {

    for (const canvas of stickerCanvasElements.value ?? []) {

        const ctx = canvas.getContext('2d')!;
        const riveRenderer = new RiveRenderer(canvas, ctx, DefaultRiveUrl, DecodeImage);

        await riveRenderer.loadedPromise;

        renderers.push(riveRenderer);
    }
})

let timeStamp = 0;
useIntervalFn(() => {

    let ix = 0;

    for (const renderer of renderers ?? []) {
        renderer.render(stickers[ix], timeStamp);

        renderer.ctx.clearRect(0, 0, renderer.canvas.width, renderer.canvas.height);

        const aspectRatio = renderer.riveCanvas.height / renderer.riveCanvas.width;
        renderer.ctx.drawImage(renderer.riveCanvas, 0, 0, renderer.riveCanvas.width, renderer.riveCanvas.height, 0, 60, renderer.canvas.width, renderer.canvas.width * aspectRatio)

        ix++;
    }

    timeStamp += 1000/30 * 1e3;

    timeStamp %= 3e6;

}, 1000/30);

function addRiveSticker(artboard: string) {

  const id = stickersStore.createRiveSticker({ 
    artboard,
    textContent: stickerText.value,
    key: artboard,
    naturalWidth: .75 * 100,
    naturalHeight: .25 * 100,
  });

  editorFocusStore.setFocus(FocusTypes.RIVE, id);
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row gap-2 justify-between items-center">
      <div class="flex gap-2">
        <span class="rounded-full font-semibold text-xs uppercase px-2 py-1 bg-green-700 text-background -mt-0.5 cursor-default">
          New
        </span>
        <h2 class="text-base font-semibold leading-snug">Animated social stickers</h2>
      </div>
      <Button variant="link" class="font-semibold lg:text-sm p-0 h-full" @click="viewAll">
        View all
      </Button>
    </div>

    <ScrollArea>
      <div class="grid w-full gap-2 notranslate grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))]">
        <Tile
            @click="historyStore.transaction('STICKER:ADD', () => addRiveSticker(definition.artboard))"
            v-for="definition in stickerSelection" :key="definition.artboard"
            :aspect="0.6"
            class="active:scale-90 transition-[transform,_border-color,_background-color] dark bg-checkerboard"
            :class="{
            // '!border-brand-state-active-border': focused,
            }"
            feature="animated-social-stickers"
        >
            <canvas style="width: 230px; height: auto; padding-right: 12px; padding-left: 12px; max-width: 122%;" ref="stickerCanvasElements" width="420" height="253"></canvas>
        </Tile>
      </div>
    </ScrollArea>
  </div>
</template>

<style scoped lang="scss">

</style>
