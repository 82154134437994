<script setup lang="ts">
import { ToolbarButton, ToolbarRoot, ToolbarSeparator } from 'radix-vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { inject, computed } from 'vue'
import type { Ref } from 'vue'
import IconBringToFront from '@/components/Icons/IconBringToFront.vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import IconSaxDocumentCopy from '@/components/Icons/iconsax/IconSaxDocumentCopy.vue'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import ShapeSelect from '@/areas/editor/workspaces/preview/cropper/CropToolbar/ShapeSelect.vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';
import { useWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { useToolbarPosition } from '@/areas/editor/hooks/useToolbarPosition'

const props = defineProps<{ id: string, show?: boolean }>()
const cropsStore = useCropsStore()

const crop = cropsStore.selectById(props.id)

const canvas = inject<Ref<HTMLElement>>('canvas')!

const cropRef = computed(() => crop)

const offset = 1
const { top, left, width, element } = useToolbarPosition(cropRef, canvas, offset)
const { element: workspace } = useWorkspaceBoundingContext()!

const currentCrops = cropsStore.idsWhereLayoutIdIs(crop.layoutId)

const editorFocusStore = useEditorFocusStore()
function duplicate() {
  const duplicateId = cropsStore.duplicateCropById(props.id)
  if (duplicateId) {
    editorFocusStore.setFocus('crop', duplicateId)
  }
}

const layoutsStore = useLayoutsStore()
const layout = computed(() => layoutsStore.selectById(crop.layoutId))
const presetId = computed(() => layout.value?.presetId)

const historyStore = useHistoryStore();
</script>

<template>
  <Teleport :to="workspace">
    <ToolbarRoot
      ref="element"
      data-retain-focus
      :style="{
        top: top + 'px',
        left: left - 0.5 * width + 'px'
      }"
      :class="!show ? 'opacity-0 translate-y-4 invisible' : ''"
      class="layer-1 !fixed mt-4 flex !min-w-max max-w-full items-center gap-1 rounded-md py-1 px-1.5 shadow-lg z-40 transition-[opacity,_transform]"
      @click.stop
    >
      <ShapeSelect :id="id" v-if="presetId !== 'full'" />
  
      <ToolbarButton>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              size="sm"
              @click.stop="historyStore.transaction('CROP:MOVE_TO_FOREGROUND', () => cropsStore.moveToForeground(id))"
            >
              <IconBringToFront class="stoke-[1] h-4 w-4 transform rotate-180" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Bring to front
          </TooltipContent>
        </Tooltip>
      </ToolbarButton>
  
      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />
  
      <ToolbarButton>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              size="sm"
              :disabled="currentCrops.length === 10"
              @click.stop="duplicate"
            >
              <IconSaxDocumentCopy class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <template v-if="currentCrops.length === 10">Cannot add more than 10 crops</template>
            <template v-else>Duplicate crop</template>
          </TooltipContent>
        </Tooltip>
      </ToolbarButton>
  
      <template v-if="presetId !== 'split' && presetId !== 'basecam'">
        <ToolbarSeparator class="h-6 w-px bg-zinc-200" />
        <ToolbarButton>
          <Tooltip>
            <TooltipTrigger>
              <Button variant="ghostDestructive" size="sm" 
                @click.stop="historyStore.transaction('CROP:DELETE', () => cropsStore.removeById(id))" 
                :disabled="currentCrops.length === 1"
              >
                <IconSaxTrash class="stoke-[1] h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <template v-if="currentCrops.length === 1">
                Cannot remove the last crop
              </template>
              <template v-else>Remove crop</template>
            </TooltipContent>
          </Tooltip>
        </ToolbarButton>
      </template>
    </ToolbarRoot>
  </Teleport>
</template>

<style lang="scss" scoped></style>
