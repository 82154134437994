/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type {
  CancelSubscriptionRequest,
  GetApiSubscriptionGeneratePaymentLinkV2Params,
  PaddlePaymentListResponse,
  PaymentLinkResourceResponse,
  PostApiSubscriptionChangePlanParams,
  PostApiSubscriptionDiscountsParams,
  Response,
  SubscriptionViewModelResourceResponse,
  TrialStateResourceResponse,
} from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const postApiSubscriptionTrials = (options?: SecondParameter<typeof accountsAxiosInstance>) => {
  return accountsAxiosInstance<TrialStateResourceResponse>({ url: `/api/Subscription/trials`, method: 'POST' }, options)
}

export const getPostApiSubscriptionTrialsMutationOptions = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TError, void, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, void> = () => {
    return postApiSubscriptionTrials(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionTrialsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSubscriptionTrials>>>

export type PostApiSubscriptionTrialsMutationError = Response

export const usePostApiSubscriptionTrials = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TError, void, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TError, void, TContext> => {
  const mutationOptions = getPostApiSubscriptionTrialsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteApiSubscriptionTrials = (options?: SecondParameter<typeof accountsAxiosInstance>) => {
  return accountsAxiosInstance<Response>({ url: `/api/Subscription/trials`, method: 'DELETE' }, options)
}

export const getDeleteApiSubscriptionTrialsMutationOptions = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TError, void, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, void> = () => {
    return deleteApiSubscriptionTrials(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiSubscriptionTrialsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>
>

export type DeleteApiSubscriptionTrialsMutationError = Response

export const useDeleteApiSubscriptionTrials = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TError, void, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TError, void, TContext> => {
  const mutationOptions = getDeleteApiSubscriptionTrialsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiSubscriptionDiscounts = (
  params?: MaybeRef<PostApiSubscriptionDiscountsParams>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  params = unref(params)

  return accountsAxiosInstance<Response>(
    { url: `/api/Subscription/discounts`, method: 'POST', params: unref(params) },
    options
  )
}

export const getPostApiSubscriptionDiscountsMutationOptions = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>,
    TError,
    { params?: PostApiSubscriptionDiscountsParams },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>,
  TError,
  { params?: PostApiSubscriptionDiscountsParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>,
    { params?: PostApiSubscriptionDiscountsParams }
  > = (props) => {
    const { params } = props ?? {}

    return postApiSubscriptionDiscounts(params, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionDiscountsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>
>

export type PostApiSubscriptionDiscountsMutationError = Response

export const usePostApiSubscriptionDiscounts = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>,
    TError,
    { params?: PostApiSubscriptionDiscountsParams },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>,
  TError,
  { params?: PostApiSubscriptionDiscountsParams },
  TContext
> => {
  const mutationOptions = getPostApiSubscriptionDiscountsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiSubscriptionBillingInfo = (
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  return accountsAxiosInstance<SubscriptionViewModelResourceResponse>(
    { url: `/api/Subscription/BillingInfo`, method: 'GET', signal },
    options
  )
}

export const getGetApiSubscriptionBillingInfoQueryKey = () => {
  return ['api', 'Subscription', 'BillingInfo'] as const
}

export const getGetApiSubscriptionBillingInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>,
  TError = SubscriptionViewModelResourceResponse
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>, TError, TData>>
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiSubscriptionBillingInfoQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>> = ({ signal }) =>
    getApiSubscriptionBillingInfo(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>,
    TError,
    TData
  >
}

export type GetApiSubscriptionBillingInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>
>
export type GetApiSubscriptionBillingInfoQueryError = SubscriptionViewModelResourceResponse

export const useGetApiSubscriptionBillingInfo = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>,
  TError = SubscriptionViewModelResourceResponse
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>, TError, TData>>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSubscriptionBillingInfoQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const postApiSubscriptionUpgradeToYearlySubscription = (
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<Response>(
    { url: `/api/Subscription/UpgradeToYearlySubscription`, method: 'POST' },
    options
  )
}

export const getPostApiSubscriptionUpgradeToYearlySubscriptionMutationOptions = <
  TError = Response,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
    TError,
    void,
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
    void
  > = () => {
    return postApiSubscriptionUpgradeToYearlySubscription(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionUpgradeToYearlySubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>
>

export type PostApiSubscriptionUpgradeToYearlySubscriptionMutationError = Response

export const usePostApiSubscriptionUpgradeToYearlySubscription = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
    TError,
    void,
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getPostApiSubscriptionUpgradeToYearlySubscriptionMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiSubscriptionChangePlan = (
  params?: MaybeRef<PostApiSubscriptionChangePlanParams>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  params = unref(params)

  return accountsAxiosInstance<Response>(
    { url: `/api/Subscription/ChangePlan`, method: 'POST', params: unref(params) },
    options
  )
}

export const getPostApiSubscriptionChangePlanMutationOptions = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
    TError,
    { params?: PostApiSubscriptionChangePlanParams },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
  TError,
  { params?: PostApiSubscriptionChangePlanParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
    { params?: PostApiSubscriptionChangePlanParams }
  > = (props) => {
    const { params } = props ?? {}

    return postApiSubscriptionChangePlan(params, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionChangePlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>
>

export type PostApiSubscriptionChangePlanMutationError = Response

export const usePostApiSubscriptionChangePlan = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
    TError,
    { params?: PostApiSubscriptionChangePlanParams },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
  TError,
  { params?: PostApiSubscriptionChangePlanParams },
  TContext
> => {
  const mutationOptions = getPostApiSubscriptionChangePlanMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiSubscriptionGeneratePaymentLinkV2 = (
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return accountsAxiosInstance<PaymentLinkResourceResponse>(
    { url: `/api/Subscription/GeneratePaymentLinkV2`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiSubscriptionGeneratePaymentLinkV2QueryKey = (
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>
) => {
  return ['api', 'Subscription', 'GeneratePaymentLinkV2', ...(params ? [params] : [])] as const
}

export const getGetApiSubscriptionGeneratePaymentLinkV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>,
  TError = PaymentLinkResourceResponse
>(
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiSubscriptionGeneratePaymentLinkV2QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>> = ({ signal }) =>
    getApiSubscriptionGeneratePaymentLinkV2(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>,
    TError,
    TData
  >
}

export type GetApiSubscriptionGeneratePaymentLinkV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>
>
export type GetApiSubscriptionGeneratePaymentLinkV2QueryError = PaymentLinkResourceResponse

export const useGetApiSubscriptionGeneratePaymentLinkV2 = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>,
  TError = PaymentLinkResourceResponse
>(
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSubscriptionGeneratePaymentLinkV2QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiSubscriptionIdPayments = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return accountsAxiosInstance<PaddlePaymentListResponse>(
    { url: `/api/Subscription/${id}/payments`, method: 'GET', signal },
    options
  )
}

export const getGetApiSubscriptionIdPaymentsQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'Subscription', id, 'payments'] as const
}

export const getGetApiSubscriptionIdPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>,
  TError = PaddlePaymentListResponse
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiSubscriptionIdPaymentsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>> = ({ signal }) =>
    getApiSubscriptionIdPayments(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>, TError, TData>
}

export type GetApiSubscriptionIdPaymentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>
>
export type GetApiSubscriptionIdPaymentsQueryError = PaddlePaymentListResponse

export const useGetApiSubscriptionIdPayments = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>,
  TError = PaddlePaymentListResponse
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSubscriptionIdPaymentsQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const putApiSubscriptionIdCancel = (
  id: MaybeRef<string>,
  cancelSubscriptionRequest: MaybeRef<CancelSubscriptionRequest>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  id = unref(id)
  cancelSubscriptionRequest = unref(cancelSubscriptionRequest)

  return accountsAxiosInstance<Response>(
    {
      url: `/api/Subscription/${id}/cancel`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: cancelSubscriptionRequest,
    },
    options
  )
}

export const getPutApiSubscriptionIdCancelMutationOptions = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
    TError,
    { id: string; data: CancelSubscriptionRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
  TError,
  { id: string; data: CancelSubscriptionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
    { id: string; data: CancelSubscriptionRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return putApiSubscriptionIdCancel(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiSubscriptionIdCancelMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>
>
export type PutApiSubscriptionIdCancelMutationBody = CancelSubscriptionRequest
export type PutApiSubscriptionIdCancelMutationError = Response

export const usePutApiSubscriptionIdCancel = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
    TError,
    { id: string; data: CancelSubscriptionRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
  TError,
  { id: string; data: CancelSubscriptionRequest },
  TContext
> => {
  const mutationOptions = getPutApiSubscriptionIdCancelMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiSubscriptionIdPause = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  id = unref(id)

  return accountsAxiosInstance<Response>({ url: `/api/Subscription/${id}/pause`, method: 'PUT' }, options)
}

export const getPutApiSubscriptionIdPauseMutationOptions = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, { id: string }> = (
    props
  ) => {
    const { id } = props ?? {}

    return putApiSubscriptionIdPause(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiSubscriptionIdPauseMutationResult = NonNullable<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>>

export type PutApiSubscriptionIdPauseMutationError = Response

export const usePutApiSubscriptionIdPause = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, TError, { id: string }, TContext> => {
  const mutationOptions = getPutApiSubscriptionIdPauseMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiSubscriptionIdResume = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  id = unref(id)

  return accountsAxiosInstance<Response>({ url: `/api/Subscription/${id}/resume`, method: 'PUT' }, options)
}

export const getPutApiSubscriptionIdResumeMutationOptions = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdResume>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdResume>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiSubscriptionIdResume>>, { id: string }> = (
    props
  ) => {
    const { id } = props ?? {}

    return putApiSubscriptionIdResume(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiSubscriptionIdResumeMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiSubscriptionIdResume>>
>

export type PutApiSubscriptionIdResumeMutationError = Response

export const usePutApiSubscriptionIdResume = <TError = Response, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdResume>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof putApiSubscriptionIdResume>>, TError, { id: string }, TContext> => {
  const mutationOptions = getPutApiSubscriptionIdResumeMutationOptions(options)

  return useMutation(mutationOptions)
}
