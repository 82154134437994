<script setup lang="ts">
import type { Tier } from '@/data/plans'
import { useUserInfoStore } from '@/store/user/userInfo'
import TierPrice from '@/components/Tiers/TierPrice.vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { computed } from 'vue'
import CheckMarkIcon from '@/components/Icons/CheckMarkIcon.vue'

const props = defineProps<{ tier: Tier; compact?: boolean; reason: string }>()

const userInfoStore = useUserInfoStore()
const { interval } = useSubscriptionIntervals()
const { isLoading } = usePlanUpgrade()

const highlighted = computed(() => props.tier.key === 'gold');

const features = computed(() => {
  const take = 5;
  return props.tier.features.slice(0, take);
});
</script>

<template>
  <div class="flex flex-col justify-center items-center h-auto hover:scale-[101%] transition-transform">
    <article
      :class="{
        'bg-gradient-to-tl from-[#feefff] to-[#f7f4ff] shadow-blue-300/30 hover:shadow-blue-300/50 shadow-xl border-[#9f01fe]': highlighted,
        'border-gray-200': !highlighted,
        'cursor-default': tier.tier <= userInfoStore.tier,
        'cursor-pointer': tier.tier > userInfoStore.tier,
      }"
      class="border group relative flex h-full flex-col rounded-3xl text-left backdrop-blur transition-all min-w-[300px] md:min-w-[320px] xl:min-w-[350px]"
    >
      <div class="flex flex-col gap-6 p-8 rounded-3xl">

        <header class="flex flex-col font-bold text-center">
          <h3 class="p-1 text-3xl">
            {{ tier.title === 'Gold' ? 'Gold' : 'Silver' }}
          </h3>
        </header>

        <TierPrice
          :class="{
            'group-hover:text-[#78767B]': tier.tier > userInfoStore.tier,
          }"
          :interval="interval"
          :tier="tier.key"
          class="transition-colors items-center"
        />

        <div class="mt-auto">
          <div class="my-4">
            <div
              v-if="isLoading === tier.key"
              :class="!highlighted ? 'btn-outline border-2 bg-white' : ''"
              class="btn-disabled loading btn-lg btn whitespace-nowrap font-bold xl:h-10 xl:min-h-[2.5rem] 2xl:h-12 2xl:min-h-[3rem]"
            >
              Upgrading to {{ tier.title }}...
            </div>
            <div
              v-else-if="tier.tier > userInfoStore.tier && !isLoading"
              class="group-hover:shadow-gradient relative h-12 rounded-full transition-all before:transition-all xl:h-10 2xl:h-12"
            >
              <div class="bg-primary absolute w-full rounded-lg">
                <button
                  :class="
                    highlighted
                      ? 'bg-black text-white'
                      : 'btn-outline border-2 bg-white text-black group-hover:border-black group-hover:bg-black group-hover:text-white'
                  "
                  class="btn-lg btn w-full rounded-lg flex-nowrap gap-2 whitespace-nowrap font-bold mix-blend-lighten xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
                >
                  Upgrade to {{ tier.title }}
                </button>
              </div>
            </div>
            <div v-else-if="tier.tier === userInfoStore.tier" class="btn-outline btn-disabled btn-lg btn border-2 bg-white">
              Current plan
            </div>
          </div>

          <h3 class="py-2 text-xs font-light text-black/70">
            {{ tier.key === 'silver' ? 'Everything in free +' : 'Everything in silver +' }}
          </h3>
          <ol class="flex flex-col gap-1.5 text-sm font-thin text-black">
            <li v-for="feature in features" v-bind:key="feature" class="flex gap-2">
              <CheckMarkIcon class="h-4 w-4 p-[3px] fill-white text-white bg-primary rounded mt-0.5" />
              {{ feature }}
            </li>
          </ol>
        </div>
        <a
          @click.stop
          class="link-hover link text-center text-sm font-bold text-[#2F184E]"
          :class="isLoading && 'pointer-events-none'"
          href="/upgrade#compare"
          target="_blank"
        >
          View all {{ tier.title }} features
        </a>
      </div>
    </article>
  </div>
</template>
