<script lang="ts" setup>
import { useLocalFileValidator } from '@/Hooks/clip-form/useLocalFileValidator'
import { postApiVideos } from '@/apis/streamladder-api/videos/videos'
import { changeVideoBitrateFFmpeg } from '@/areas/editor/startup/runFFmpegSingleThread'
import { startFileUpload } from '@/components/Dialog/MultiUploadDialog/file-uploads/_api'
import RadialProgress from '@/components/Dialog/RadialProgress.vue'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogTrigger } from '@/components/ui/dialog'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { formatFileSize } from '@/helpers/fileSize'
import uploadService from '@/services/uploadService'
import { useUserInfoStore } from '@/store/user/userInfo'
import * as Sentry from '@sentry/vue'
import { useDropZone } from '@vueuse/core'
import axios from 'axios'
import MP4Box from 'mp4box'
import { ref } from 'vue'

const emit = defineEmits<{ (name: 'select', event: { id: string; title: string; resultUrl: string }): void }>()

const isOpen = ref(false)
const dropzone = ref<HTMLLabelElement | null>(null)
const { isOverDropZone } = useDropZone(dropzone, { onDrop: updateFiles })
const validateLocalFile = useLocalFileValidator()
const showLowerBitrateDialog = ref<File>();
const lowerBitrateProgress = ref(0);

const isUploading = ref(false);
const uploadProgress = ref(0);

async function updateFiles(files: FileList | File[] | null) {

  if (!files || !await validateLocalFile(files[0])) {
    return;
  }

  isUploading.value = true;

  const file = files[0];

  type Track = {
      type: 'video' | 'audio',
      bitrate: number,
    }

  const readFileInfoMP4Box = async (blob: Blob) : Promise<Track | undefined> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const mp4boxFile = MP4Box.createFile();

      mp4boxFile.onReady = (info: { tracks: Track[] }) => {
        const videoTrack = info.tracks.find(track => track.type === 'video');
        resolve(videoTrack)
      };

      fileReader.onload = () => {
        // Add `fileStart` property to arrayBuffer by type assertion
        const arrayBuffer = fileReader.result as ArrayBuffer & { fileStart: number };
        arrayBuffer.fileStart = 0;
        mp4boxFile.appendBuffer(arrayBuffer);
        mp4boxFile.flush();
      };

      fileReader.onerror = () => reject(fileReader.error);
      fileReader.readAsArrayBuffer(blob);
    });
  }


  const videoInfo = await readFileInfoMP4Box(file);

  if (videoInfo?.bitrate && videoInfo.bitrate > 25_000_000) {
    showLowerBitrateDialog.value = file;
    return;
  }

  await uploadVideoFile(file);
}


async function uploadVideoFile(file: File) {
  const uploadResultStorage = await uploadService.getUploadResultSignedUrl();
  const upload = startFileUpload(uploadResultStorage, file, axios.CancelToken.source(), {
    onProgress: (progress) => { uploadProgress.value = progress },
  });

  await upload.then(async () => {

      const videoResponse = await postApiVideos({
        videoUrl: uploadResultStorage.resultUrl,
        title: file.name || 'StreamLadder video',
      });

      if (!videoResponse) {
        console.error('Failed to post video to api videos.');
        isUploading.value = false;
        isOpen.value = false;
        EventBus.$emit(mainEvents.ERROR, 'Failed to upload file, please try again.');
        Sentry.captureException(new Error('Failed to post video to api videos.'));
      }

      emit('select', {
        id: videoResponse.id!,
        title: file.name,
        resultUrl: uploadResultStorage.resultUrl,
      });

      isUploading.value = false;
      isOpen.value = false;
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      isUploading.value = false;
      isOpen.value = false;
      EventBus.$emit(mainEvents.ERROR, 'Failed to upload file, please try again.');
      Sentry.captureException(new Error(`Content Publisher User Upload failed`));
    });
}

const updateFilesFromEvent = async (event: Event) => {
  const target = event.target as HTMLInputElement
  await updateFiles(target.files)
};


function ignoreHighBitrate() {
  if (showLowerBitrateDialog.value) {
    uploadVideoFile(showLowerBitrateDialog.value);
    showLowerBitrateDialog.value = undefined;
  }
}

async function lowerBitRate() {
  if (showLowerBitrateDialog.value) {
    const newBlob = await changeVideoBitrateFFmpeg(URL.createObjectURL(showLowerBitrateDialog.value), '25M', (progress: number) => {
      lowerBitrateProgress.value = progress;
    })

    uploadVideoFile(new File([newBlob], showLowerBitrateDialog.value.name, { type: 'video/mp4' }));
    showLowerBitrateDialog.value = undefined;
  }
}

const userInfoStore = useUserInfoStore();
</script>

<template>
  <Dialog v-model:open="isOpen">

    <DialogTrigger class="flex flex-auto" v-if="$slots.default">
      <slot />
    </DialogTrigger>

    <DialogContent class="max-w-3xl gap-8">
      <DialogTitle>Upload your media</DialogTitle>
      <DialogDescription class="sr-only">Drag and drop or manually upload your file.</DialogDescription>
      <label
        ref="dropzone"
        :class="{
          'dark:bg-sky-400/50 !border-solid bg-brand-state-hover-bg text-black dark:text-white': isOverDropZone,
          'cursor-pointer': !isUploading,
        }"
        class="flex flex-col items-center gap-4 rounded-lg border-2 p-8 transition-[color,border-color,background-color,transform]"
      >
        <template v-if="!isUploading">
          <input
            :multiple="false"
            accept=".mp4,.mov"
            class="invisible absolute inset-0"
            type="file"
            @change="updateFilesFromEvent"
          />
          <IconSaxDocumentUpload :class="isOverDropZone ? 'text-sky-300 dark:text-sky-700' : 'text-brand-state-link'" />
          <span class="flex flex-col gap-1">
            <span v-if="isOverDropZone" class="text-center text-sm"> Drop to upload </span>
            <span v-else class="text-center text-sm">
              Drag your file or
              <Button as="p" class="h-auto cursor-pointer p-0" variant="link">browse</Button>
            </span>
            <span class="text-center text-xs font-light opacity-50">
              Max {{ formatFileSize(userInfoStore.fileSizeLimit) }} per file is allowed. Only .mp4 and .mov files are supported.
            </span>
          </span>
        </template>
        <template v-else-if="showLowerBitrateDialog">
          <div class="flex flex-col gap-8 max-w-full">
            <div v-if="lowerBitrateProgress">Reducing video bitrate..</div>
            <div v-else>The video bitrate exceeds the maximum for Instagram. Would you like to us to reduce the bitrate? This progress can take some time.</div>
            <div class="flex justify-center" v-if="lowerBitrateProgress"><radial-progress :progress="lowerBitrateProgress * 100" /></div>
            <div v-else class="flex gap-2 flex-wrap">
              <Button class="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 focus:outline-none" @click="ignoreHighBitrate()">Ignore and Upload</Button>
              <Button class="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none" @click="lowerBitRate()">Lower the bitrate</Button>
            </div>
          </div>

        </template>
        <template v-else-if="isUploading">
          <DialogTitle>Uploading..</DialogTitle>
          <radial-progress :progress="uploadProgress" />
        </template>
      </label>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped></style>
