export const sounds = [
  {
    "url": "/audio-effects/sounds/Memes & Humor/a-few-moments-later.mp3",
    "title": "A few moments later",
    "tags": [
      "Memes & Humor"
    ],
    "duration": 2
  },
  {
    "url": "/audio-effects/sounds/Memes & Humor/another-one.mp3",
    "title": "Another one",
    "tags": [
      "Memes & Humor"
    ],
    "duration": 1.5
  },
  {
      "url": "/audio-effects/memesounds/among.mp3",
      "title": "Among Us",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 3.866122
  },
  {
      "url": "/audio-effects/memesounds/anime-wow-sound-effect-mp3cut.mp3",
      "title": "Anime Wow",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 4.231837
  },
  {
      "url": "/audio-effects/memesounds/aplausos_3.mp3",
      "title": "Applause",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 23.066122
  },
  {
      "url": "/audio-effects/memesounds/are-you-out-of-your-mind-greenscreen-change-quality-and-end-wont-cut-off_2.mp3",
      "title": "Are you out of your mind?",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 2.951837
  },
  {
      "url": "/audio-effects/memesounds/baby-laughing-meme.mp3",
      "title": "Baby laughing",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 6.635102
  },
  {
      "url": "/audio-effects/memesounds/bad-to-the-bone-meme.mp3",
      "title": "Bad to the bone",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 2.56
  },
  {
      "url": "/audio-effects/memesounds/bombastic-side-eye.mp3",
      "title": "Bombastic side eye",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 6.408
  },
  {
      "url": "/audio-effects/memesounds/emotional-damage-meme.mp3",
      "title": "Emotional damage",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 3.369796
  },
  {
      "url": "/audio-effects/memesounds/english-or-spanish-song.mp3",
      "title": "English or Spanish song",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 25.032
  },
  {
      "url": "/audio-effects/memesounds/erm-what-the-sigma_su7GnzC.mp3",
      "title": "Erm what the sigma",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 1.44
  },
  {
      "url": "/audio-effects/memesounds/fortnite-dance-moves-emote-music-tv9iv8cxmo0-1.mp3",
      "title": "Fortnite dance moves emote music",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 8.045714
  },
  {
      "url": "/audio-effects/memesounds/george-micael-wham-careless-whisper-1.mp3",
      "title": "Careless Whisper",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 9.090612
  },
  {
      "url": "/audio-effects/memesounds/get-out-tuco.mp3",
      "title": "Get out Tuco",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 0.863982
  },
  {
      "url": "/audio-effects/memesounds/goodmorn1.mp3",
      "title": "Good morning",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 4.780408
  },
  {
      "url": "/audio-effects/memesounds/goofy-ahh-sounds.mp3",
      "title": "Goofy ahh",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 32.130612
  },
  {
      "url": "/audio-effects/memesounds/hawk-tuah-short.mp3",
      "title": "Hawk tuah",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 4.545306
  },
  {
      "url": "/audio-effects/memesounds/hello-its-john-cena.mp3",
      "title": "Hello its John Cena",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 9.534694
  },
  {
      "url": "/audio-effects/memesounds/joker-shitpost-beatbox.mp3",
      "title": "Joker shitpost beatbox",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 11.572245
  },
  {
      "url": "/audio-effects/memesounds/kids-saying-yay-sound-effect_3.mp3",
      "title": "Kids saying yay",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 8.176327
  },
  {
      "url": "/audio-effects/memesounds/m-e-o-w.mp3",
      "title": "Meow",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 1.906939
  },
  {
      "url": "/audio-effects/memesounds/movie_1.mp3",
      "title": "Movie",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 0.864
  },
  {
      "url": "/audio-effects/memesounds/musica-de-sigma-estourado.mp3",
      "title": "Musica de Sigma estourado",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 7.1315
  },
  {
      "url": "/audio-effects/memesounds/notification_o14egLP.mp3",
      "title": "Notification sound",
      "tags": [
          "Alerts & Notifications"
      ],
      "duration": 4.675918
  },
  {
      "url": "/audio-effects/memesounds/oh-my-god-bro-oh-hell-nah-man.mp3",
      "title": "Oh my god bro oh hell",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 10.840816
  },
  {
      "url": "/audio-effects/memesounds/oh-my-god-meme.mp3",
      "title": "Oh my god",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 1.671837
  },
  {
      "url": "/audio-effects/memesounds/pou-estourado_zIWCpMy.mp3",
      "title": "Pou estourado",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 16.032
  },
  {
      "url": "/audio-effects/memesounds/punch-sound-effect-meme.mp3",
      "title": "Punch sound effect",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 1.08
  },
  {
      "url": "/audio-effects/memesounds/rizz-sounds.mp3",
      "title": "Rizz sounds",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 1.9331
  },
  {
      "url": "/audio-effects/memesounds/run-vine-sound-effect.mp3",
      "title": "Run Vine",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 8.28
  },
  {
      "url": "/audio-effects/memesounds/sad-meow-song.mp3",
      "title": "Sad Meow song",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 25.077551
  },
  {
      "url": "/audio-effects/memesounds/snore-mimimimimimi.mp3",
      "title": "Snore mimimimimimi",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 5.694694
  },
  {
      "url": "/audio-effects/memesounds/super-mario-death-sound-sound-effect.mp3",
      "title": "Super Mario death sound",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 9.874286
  },
  {
      "url": "/audio-effects/memesounds/tapi-boong-hahaha.mp3",
      "title": "Tapi boong",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 3.336125
  },
  {
      "url": "/audio-effects/memesounds/theyre-eating-the-dogs.mp3",
      "title": "They're eating the dogs",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 15.046531
  },
  {
      "url": "/audio-effects/memesounds/trollface-smile.mp3",
      "title": "Trollface smile",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 5.32898
  },
  {
    "url": "/audio-effects/sounds/Memes & Humor/uwu.mp3",
    "title": "Uwuu~",
    "tags": [
      "Memes & Humor"
    ],
    "duration": 1.5
  },
  {
      "url": "/audio-effects/memesounds/vine_boom_sound_effect_longer_verison_for_real_read_description_pleaseyoutubetomp4.mp3",
      "title": "Vine boom sound effect",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 3.072
  },
  {
      "url": "/audio-effects/memesounds/wait-a-minute-who-are-you.mp3",
      "title": "Wait a minute who are you",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 6.896327
  },
  {
      "url": "/audio-effects/memesounds/wait-wait-wait-what-the-hell-legend-sound.mp3",
      "title": "Wait wait wait what the heeeeell",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 41.900408
  },
  {
      "url": "/audio-effects/memesounds/wrong-answer-sound-effect.mp3",
      "title": "Wrong answer",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 1.201633
  },
  {
      "url": "/audio-effects/memesounds/zias-stop-the-cap_RjHQpxU.mp3",
      "title": "Zias stop the cap",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 8.333061
  },
  {
      "url": "/audio-effects/songs/Cyberpunk Gaming Rave by Infraction [No Copyright Music]  Black Ice.mp3",
      "title": "Cyberpunk Gaming Rave",
      "tags": [
          "Full songs"
      ],
      "duration": 117.080816
  },
  {
      "url": "/audio-effects/songs/Cyberpunk Stream Music by Infraction [No Copyright Music]  Virtual Reality.mp3",
      "title": "Cyberpunk Stream Music",
      "tags": [
          "Full songs"
      ],
      "duration": 209.031837
  },
  {
      "url": "/audio-effects/songs/Energetic EDM Festival by Infraction [No Copyright Music]  Lionheart.mp3",
      "title": "Energetic EDM Festival",
      "tags": [
          "Full songs"
      ],
      "duration": 118.099592
  },
  {
      "url": "/audio-effects/songs/Halloween Cinematic by Infraction [No Copyright Music]  Halloween 2024.mp3",
      "title": "Halloween Cinematic",
      "tags": [
          "Full songs"
      ],
      "duration": 159.085714
  },
  {
      "url": "/audio-effects/songs/Hyperpop, dubstep by Emerel Gray  Pretty Trip [No Copyright Music].mp3",
      "title": "Hyperpop, dubstep by Emerel Gray",
      "tags": [
          "Full songs"
      ],
      "duration": 180.088163
  },
  {
      "url": "/audio-effects/songs/Indie Electronic Dance by Infraction, Emerel Gray [No Copyright Music]  Run For Your Life.mp3",
      "title": "Indie Electronic Dance",
      "tags": [
          "Full songs"
      ],
      "duration": 191.064
  },
  {
      "url": "/audio-effects/songs/Phonk Drift Gaming by Infraction [No Copyright Music]  Don't Care.mp3",
      "title": "Phonk Drift Gaming",
      "tags": [
          "Full songs"
      ],
      "duration": 105.090612
  },
  {
      "url": "/audio-effects/songs/Racing Drift Atmospheric Phonk by Infraction [No Copyright Music]  Backslider.mp3",
      "title": "Racing Drift Atmospheric Phonk",
      "tags": [
          "Full songs"
      ],
      "duration": 126.093061
  },
  {
      "url": "/audio-effects/songs/Sport Cyberpunk G-House by Infraction [No Copyright Music]  Shock Absorber.mp3",
      "title": "Sport Cyberpunk G-House",
      "tags": [
          "Full songs"
      ],
      "duration": 126.048
  },
  {
      "url": "/audio-effects/songs/Sport Trap Aggressive by Infraction [No Copyright Music]  Wanna Feel It.mp3",
      "title": "Sport Trap Aggressive",
      "tags": [
          "Full songs"
      ],
      "duration": 125.100408
  },
  {
      "url": "/audio-effects/songs/Sport Trap Olympics by Infraction [No Copyright Music]  Brave.mp3",
      "title": "Sport Trap Olympics",
      "tags": [
          "Full songs"
      ],
      "duration": 138.083265
  },
  {
      "url": "/audio-effects/songs/Upbeat Technology Hyperpop by Infraction [No Copyright Music]  Hyperdrop.mp3",
      "title": "Upbeat Technology Hyperpop",
      "tags": [
          "Full songs"
      ],
      "duration": 151.118367
  },
  {
      "url": "/audio-effects/sounds/Alerts & Notifications/Bell Ding Sound EFFECT.mp3",
      "title": "Bell Ding",
      "tags": [
          "Alerts & Notifications"
      ],
      "duration": 2.533878
  },
  {
      "url": "/audio-effects/sounds/Alerts & Notifications/Magic Chime - Sound Effect for editing.mp3",
      "title": "Magic Chime",
      "tags": [
          "Alerts & Notifications"
      ],
      "duration": 10.292245
  },
  {
      "url": "/audio-effects/sounds/Alerts & Notifications/Mobile Phone Vibration Sound Effect  Phone Ringing Vibrating Sound Effect.mp3",
      "title": "Mobile Phone Vibration",
      "tags": [
          "Alerts & Notifications"
      ],
      "duration": 12.669388
  },
  {
      "url": "/audio-effects/sounds/Ambient Sounds/Cinematic Sound FX - Thunder.mp3",
      "title": "Thunder",
      "tags": [
          "Ambient Sounds"
      ],
      "duration": 36.493061
  },
  {
      "url": "/audio-effects/sounds/Ambient Sounds/Footsteps Approaching - Sound Effect.mp3",
      "title": "Footsteps Approaching",
      "tags": [
          "Ambient Sounds"
      ],
      "duration": 12.146939
  },
  {
      "url": "/audio-effects/sounds/Ambient Sounds/Nature Sound Effects For YouTube Videos.mp3",
      "title": "Nature Sound Effects",
      "tags": [
          "Ambient Sounds"
      ],
      "duration": 69.537959
  },
  {
      "url": "/audio-effects/sounds/Gaming/Explosion sound effect - bomb sound - boom sound.mp3",
      "title": "Explosion sound effect",
      "tags": [
          "Gaming"
      ],
      "duration": 6.164898
  },
  {
      "url": "/audio-effects/sounds/Gaming/Pokemon Level Up - Sound Effect SFX.mp3",
      "title": "Pokemon Level Up",
      "tags": [
          "Gaming"
      ],
      "duration": 4.754286
  },
  {
      "url": "/audio-effects/sounds/Gaming/Power-Up - Sound Effect (HD).mp3",
      "title": "Power-Up",
      "tags": [
          "Gaming"
      ],
      "duration": 1.906939
  },
  {
      "url": "/audio-effects/sounds/Gaming/Victory Jingle.mp3",
      "title": "Victory Jingle",
      "tags": [
          "Gaming"
      ],
      "duration": 8.385306
  },
  {
      "url": "/audio-effects/sounds/Halloween/Halloween Dramatic Organ Sound Effect HD.mp3",
      "title": "Halloween Dramatic Organ",
      "tags": [
          "Spooky"
      ],
      "duration": 22.674286
  },
  {
      "url": "/audio-effects/sounds/Halloween/Sneaky Adventure - Background Music (HD).mp3",
      "title": "Sneaky Adventure",
      "tags": [
          "Full songs"
      ],
      "duration": 73.534694
  },
  {
      "url": "/audio-effects/sounds/Halloween/Spooky Forest Ambience Sound Effect  Scary Halloween Sounds.mp3",
      "title": "Spooky Forest Ambience",
      "tags": [
          "Spooky"
      ],
      "duration": 30.093061
  },
  {
      "url": "/audio-effects/sounds/Memes & Humor/Cartoon Boing (Spring Sound) - Sound Effects for Editing.mp3",
      "title": "Cartoon Boing (Spring Sound)",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 3.84
  },
  {
      "url": "/audio-effects/sounds/Memes & Humor/recorddisc scratch sound effects.mp3",
      "title": "Record scratch",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 9.874286
  },
  {
      "url": "/audio-effects/sounds/Memes & Humor/Sad Trombone - Sound Effect (HD).mp3",
      "title": "Sad Trombone",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 5.067755
  },
  {
    "url": "/audio-effects/sounds/Memes & Humor/screaming-goat.mp3",
    "title": "Screaming goat",
    "tags": [
      "Memes & Humor"
    ],
    "duration": 2
  },
  {
      "url": "/audio-effects/sounds/Memes & Humor/Swearing beep sound effect.mp3",
      "title": "Swearing beep",
      "tags": [
          "Memes & Humor"
      ],
      "duration": 1.097143
  },
  {
      "url": "/audio-effects/sounds/Reaction Sounds/Applause  Sound Effect.mp3",
      "title": "Applause",
      "tags": [
          "Reaction Sounds"
      ],
      "duration": 9.404082
  },
  {
    "url": "/audio-effects/sounds/Reaction Sounds/alert.mp3",
    "title": "Alert",
    "tags": [
      "Reaction Sounds"
    ],
    "duration": 1
  },
  {
    "url": "/audio-effects/sounds/Reaction Sounds/brother-ugh.mp3",
    "title": "Brother ugh",
    "tags": [
      "Reaction Sounds"
    ],
    "duration": 2
  },
  {
      "url": "/audio-effects/sounds/Reaction Sounds/Crowd Applause and Cheer Sound Effect [HD].mp3",
      "title": "Crowd Applause and Cheer",
      "tags": [
          "Reaction Sounds"
      ],
      "duration": 37.851429
  },
  {
      "url": "/audio-effects/sounds/Reaction Sounds/Drum Roll Sound Effect [Extended  High Quality].mp3",
      "title": "Drum Roll",
      "tags": [
          "Reaction Sounds"
      ],
      "duration": 52.793469
  },
  {
      "url": "/audio-effects/sounds/Reaction Sounds/laughing meme sound effect.mp3",
      "title": "Laughing meme",
      "tags": [
          "Reaction Sounds"
      ],
      "duration": 6.817959
  },
  {
    "url": "/audio-effects/sounds/Reaction Sounds/sad-hamster.mp3",
    "title": "Sad Hamster",
    "tags": [
      "Reaction Sounds"
    ],
    "duration": 9
  },
  {
      "url": "/audio-effects/sounds/Reaction Sounds/Shocked Sound Effect (no copyright).mp3",
      "title": "Shocked",
      "tags": [
          "Reaction Sounds"
      ],
      "duration": 30.61551
  },
  {
      "url": "/audio-effects/sounds/Reaction Sounds/Sitcom Laughing - Sound Effect.mp3",
      "title": "Sitcom Laughing",
      "tags": [
          "Reaction Sounds"
      ],
      "duration": 7.47102
  }
]