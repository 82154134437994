<script setup lang="ts">
import { Button } from '@/components/ui/button'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { useTheme } from '@/Hooks/useTheme'
import { useHead } from '@unhead/vue'
import { onMounted } from 'vue'

onMounted(() => {

  const channel = new BroadcastChannel('social-media-connection')
  channel.postMessage('connection-success')
  
  setTimeout(() => close(), 1000)
})

function close() {
  console.log('Closing window')
  window.close()
}

const { theme } = useTheme()
useHead({
  bodyAttrs: {
    class: [theme],
  }
})
</script>

<template>
  <main class="w-[100dvw] h-[100dvh] grid place-items-center layer-0">
    <div class="flex flex-col items-center gap-4">
      <div class="flex flex-col items-center gap-2">
        <LottieAnimation url="/lottie/success.json" :loop="false" class="w-24 h-24" />
        <h1 class="text-lg font-bold">Connection success!</h1>
      </div>
      <div class="flex flex-col gap-2 items-center font-light max-w-lg text-center">
        <p class="text-sm opacity-50">
          This window will close automatically. If it isn't closed in a few seconds, you can close it manually.
        </p>
      </div>
      <Button @click="() => close()" variant="outline">
        Close
      </Button>
    </div>
  </main>
</template>

<style scoped lang="scss">

</style>
