import { ref, watch, computed } from 'vue'

const twitchClipsOptions = [
  { label: 'Most recent', value: 'recency', sort: 'recency', sinceDays: 7 },
  { label: 'Top 24 hours', value: 'views-day', sort: 'views', sinceDays: 1 },
  { label: 'Top 7 days', value: 'views-week', sort: 'views', sinceDays: 7 },
  { label: 'Top 30 days', value: 'views-month', sort: 'views', sinceDays: 30 },
  { label: 'Top All', value: 'views-all', sort: 'views', sinceDays: undefined },
] as const

export type TwitchClipsOptions = typeof twitchClipsOptions

const defaultOption = twitchClipsOptions[0]

export function useTwitchClipsFilter() {
  return { twitchClipsOptions, twitchClipFilter, twitchClipFilterKey }
}

const storageKey = '__sl_clip_sort'
const getFilterFromStorage = (): TwitchClipFilterValues => {
  const storedValue = localStorage.getItem(storageKey)

  // Backwards compatibility
  if (Number(storedValue)) {
    return findDeprecatedValue(Number(storedValue) as (typeof twitchClipsOptions)[number]['sinceDays']).value
  }

  return ensureValidValue(storedValue)
}

const findDeprecatedValue = (sinceDays: (typeof twitchClipsOptions)[number]['sinceDays']) => {
  return twitchClipsOptions.find((o) => o.sinceDays === sinceDays && o.sort === 'views') ?? defaultOption
}

const storeFilter = (filter: TwitchClipFilterValues) => {
  localStorage.setItem(storageKey, ensureValidValue(filter))
}

const validValues: TwitchClipFilterValues[] = twitchClipsOptions.map((o) => o.value)
export type TwitchClipFilterValues = (typeof twitchClipsOptions)[number]['value']

const ensureValidValue = (filter: string | null): TwitchClipFilterValues => {
  if (validValues.includes(filter as TwitchClipFilterValues)) {
    return filter as TwitchClipFilterValues
  } else {
    return defaultOption.value
  }
}

const initial = getFilterFromStorage()
storeFilter(initial)

const twitchClipFilterKey = ref<TwitchClipFilterValues>(initial)
const twitchClipFilter = computed(
  () => twitchClipsOptions.find((o) => o.value === twitchClipFilterKey.value) ?? defaultOption,
)
watch(twitchClipFilterKey, storeFilter)
