<script lang="ts" setup generic="T extends string | undefined">
import type { Component } from 'vue'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select';
import type { ClassValue } from 'clsx';
import { cn } from '@/lib/utils'

interface Props {
  class?: ClassValue;
  options: {
    label: Component | string;
    value: T;
  }[];
  align?: "start" | "center" | "end"
}

const props = defineProps<Props>()

const value = defineModel<T>({ required: true });
</script>

<template>
  <Select v-model="value">
    <SelectTrigger :class="cn('w-auto min-w-48', props.class)">
      <SelectValue />
    </SelectTrigger>
    <SelectContent :align="align">
      <SelectItem v-for="option in options" :value="option.value!" :key="option.value!">
        {{ option.label }}
      </SelectItem>
    </SelectContent>
  </Select>
</template>

<style lang="scss" scoped></style>
