<script setup lang="ts">
import { useKeyCombination } from '@/lib/keybindings';
import { useTheme } from '@/Hooks/useTheme';
import { isInputTarget } from '@/components/Editor/Timeline/helpers';

const { theme, themes } = useTheme()
useKeyCombination('toggle-theme', (e) => {
  if (!isInputTarget(e)) {
    const index = themes.indexOf(theme.value);
    const nextIndex = (index + 1) % themes.length;
    theme.value = themes[nextIndex];
  }
})
</script>

<template>
  <slot />
</template>

<style scoped lang="scss">

</style>
