<script setup lang="ts">

</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9931 0.944336C15.8809 0.944336 17.8146 2.97174 17.8146 5.9873V12.9478C17.8146 15.954 15.8809 17.9814 12.9931 17.9814H5.60759C2.71981 17.9814 0.777588 15.954 0.777588 12.9478V5.9873C0.777588 2.97174 2.71981 0.944336 5.60759 0.944336H12.9931ZM12.8568 6.9073C12.5672 6.61767 12.0902 6.61767 11.8006 6.9073L8.2824 10.4254L6.79166 8.93471C6.50203 8.64508 6.025 8.64508 5.73537 8.93471C5.44574 9.22434 5.44574 9.69285 5.73537 9.991L7.76277 12.0099C7.90759 12.1547 8.095 12.2229 8.2824 12.2229C8.47833 12.2229 8.66574 12.1547 8.81055 12.0099L12.8568 7.96359C13.1465 7.67397 13.1465 7.20545 12.8568 6.9073Z" fill="#16A34A"/>
  </svg>
</template>

<style scoped lang="scss">

</style>
