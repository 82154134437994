<template>
<svg width="7" height="23" viewBox="0 0 7 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79281 1.56422C3.79317 1.32843 3.79138 1.10124 3.78793 0.884277V1.16968C3.78724 1.07461 3.7869 0.979475 3.7869 0.884292V2.18537C3.72689 5.6028 3.18477 10.3717 0.896378 12.2515C2.55861 13.5992 3.71333 17.601 3.78445 22.2923C3.78369 22.4498 3.78453 22.6033 3.7869 22.7524V22.5011C3.78759 22.5847 3.78793 22.6684 3.78793 22.7524V21.9835C3.84794 18.573 4.63728 13.5852 6.87439 12.2515C4.79547 10.6149 3.87392 6.20825 3.79281 1.56422Z" fill="url(#paint0_linear_126_139)"/>
    <defs>
    <linearGradient id="paint0_linear_126_139" x1="3.88538" y1="4.12899" x2="3.88538" y2="22.7524" gradientUnits="userSpaceOnUse">
    <stop stop-color="#E306A5"/>
    <stop offset="1" stop-color="#830760"/>
    </linearGradient>
</defs>
</svg>

</template>