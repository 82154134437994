<script setup lang="ts">
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { onKeyStroke } from '@vueuse/core'
import { interpolate, isInputTarget, msToTime } from '@/areas/editor/timeline/helpers'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxAddCircle from '@/components/Icons/iconsax/IconSaxAddCircle.vue'
import TimelineZoomSlider from '@/areas/editor/timeline/header/TimelineZoomSlider.vue'
import IconSaxMinusCirlce from '@/components/Icons/iconsax/IconSaxMinusCirlce.vue'
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxScissor from '@/components/Icons/iconsax/IconSaxScissor.vue'
import { computed } from 'vue'
import IconSaxArrowLeftStop from '@/components/Icons/iconsax/IconSaxArrowLeftStop.vue'
import IconSaxArrowRightStop from '@/components/Icons/iconsax/IconSaxArrowRightStop.vue'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import TimelineVolumeButton from '@/areas/editor/timeline/header/TimelineVolumeButton.vue'
import IconSaxSidebarBottom from '@/components/Icons/iconsax/IconSaxSidebarBottom.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import IconSaxBackward from '@/components/Icons/iconsax/IconSaxBackward.vue'
import IconSaxForward from '@/components/Icons/iconsax/IconSaxForward.vue'
import { clamp } from 'lodash-es'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import CaptionsTimelineHeaderToolbar from '@/areas/editor/timeline/tracks/captions/CaptionsTimelineHeaderToolbar.vue'
import { Switch } from '@/components/ui/switch';
import { Keyboard } from '@/components/ui/keyboard';

const editorFocusStore = useEditorFocusStore()
const videoStore = useVideoStore()

const { zoomToLevel, zoomLevel, fitToTimeline, _autoScroll, open: isOpen, triggerSeek } = useTimelineStore()

const togglePlaying = () => {
  _autoScroll.value = 'idle'
  videoStore.playing = !videoStore.playing
  videoStore.preservedPaused = !videoStore.preservedPaused
}

const splitSegment = () => {
  editorFocusStore.splitFocusModel(videoStore.getExactTime())
}

const pullLeft = () => {
  editorFocusStore.pullModel(videoStore.getExactTime(), 'left')
}

const pullRight = () => {
  editorFocusStore.pullModel(videoStore.getExactTime(), 'right')
}

onKeyStroke([']'], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  e.preventDefault()
  pullLeft()
})

onKeyStroke(['['], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  e.preventDefault()
  pullRight()
})

const nudgeZoom = (_nudgeAmount: number) => {
  const zoomedLevel = Math.min(Math.max(zoomLevel.value + _nudgeAmount, 0), 1)
  zoomToLevel(zoomedLevel, videoStore.getExactTime())
}

const nudgeAmount = computed(() => {
  const minStep = 5 / 100
  const minDuration = 10000
  const maxStep = 20 / 100
  const maxDuration = 60000
  const duration = videoStore.duration
  const step = Math.max(0, Math.min(1, duration - minDuration / maxDuration - minDuration))
  return interpolate(minStep, maxStep, step)
})

const segmentsStore = useSegmentsStore()
const segments = segmentsStore.whereIsNotZoom()
function fitCrop() {
  fitToTimeline(
    Math.min(...segments.value.map(s => s.startMs)),
    Math.max(...segments.value.map(s => s.endMs)))
}

const splittingIsPossible = computed(() => {
  return editorFocusStore.focus?.type === FocusTypes.ZOOM
    || editorFocusStore.focus?.type === FocusTypes.SEGMENT
    || editorFocusStore.focus?.type === FocusTypes.STICKER
    || editorFocusStore.focus?.type === FocusTypes.TEXTSTICKER;
});

const frametime = computed(() => 1000 / videoStore.framerate);
const totalDurationMs = computed(() => videoStore._duration * 1000);
const mouseDown = ref(false);

const goForward = () => {
  const nextFrame = clamp(videoStore.currentTimeMs + frametime.value, 0, totalDurationMs.value);
  triggerSeek(nextFrame);
};

const goBackwards = () => {
  const nextFrame = clamp(videoStore.currentTimeMs - frametime.value, 0, totalDurationMs.value);
  triggerSeek(nextFrame);
};

const goForwardHold = () => {
  const hold = () => {
    const nextFrame = clamp(videoStore.currentTimeMs + frametime.value, 0, totalDurationMs.value);
    triggerSeek(nextFrame);
    if (mouseDown.value) requestAnimationFrame(hold);
  };
  setTimeout(() => {
    if (mouseDown.value) hold();
  }, 500);
};

const goBackwardsHold = () => {
  const hold = () => {
    const nextFrame = clamp(videoStore.currentTimeMs - frametime.value, 0, totalDurationMs.value);
    triggerSeek(nextFrame);
    if (mouseDown.value) requestAnimationFrame(hold);
  };
  setTimeout(() => {
    if (mouseDown.value) hold();
  }, 500);
};

const onMouseDownForward = () => {
  mouseDown.value = true;
  goForwardHold();
};

const onMouseDownBackwards = () => {
  mouseDown.value = true;
  goBackwardsHold();
};

const captionsStore = useCaptionsStore();
const { currentStep } = useEditorStep();

const captionsMode = computed(() => {
  return captionsStore.hasGeneratedCaptions
    && currentStep.value === editorRouteNames.captions;
});
</script>

<template>
  <div class="flex items-center border-b border-border">
    <div class="absolute -top-1 text-sm px-2 flex justify-between items-center w-full">
      <Tooltip disable-hoverable-content>
        <TooltipTrigger class="flex gap-0.5 md:gap-2">
          <span class="min-w-[5ch] font-semibold opacity-90">
            {{ msToTime(videoStore.currentTimeMs) }}
          </span>
          <span class="hidden opacity-50 md:inline">/</span>
          <span class="hidden min-w-[5ch] font-normal text-muted-foreground min-[400px]:inline">
            {{ msToTime(videoStore._duration * 1000) }}
          </span>
        </TooltipTrigger>
        <TooltipContent side="top" align="start" :align-offset="-4">
          <span>Duration of exported video: </span>
          <span class="font-semibold">{{ msToTime(videoStore.duration * 1000) }}</span>
        </TooltipContent>
      </Tooltip>

      <div
        class="flex h-8 flex-row items-center justify-center gap-0.5 leading-none xl:absolute xl:left-1/2 xl:-translate-x-1/2"
      >
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Transition
              appear
              enter-active-class="transition-[transform,_opacity]"
              leave-active-class="transition-[transform,_opacity]"
              enter-from-class="opacity-0 translate-x-2 translate-y-0.5 scale-75"
              enter-to-class="opacity-100 translate-x-0"
              leave-from-class="opacity-100 translate-x-0"
              leave-to-class="opacity-0 translate-x-2 translate-y-0.5 scale-75"
            >
              <Button
                class="p-0 w-8" v-if="!videoStore.playing" size="sm"
                variant="ghost"
                @click="goBackwards"
                @mousedown="onMouseDownBackwards"
                @mouseleave="mouseDown = false"
                @mouseup="mouseDown = false"
              >
                <IconSaxBackward class="h-5 w-5" />
              </Button>
            </Transition>
          </TooltipTrigger>
          <TooltipContent side="top">
            Go back a frame
            <Keyboard class="translate-x-0.5 -translate-y-px" event="skip-backward" />
          </TooltipContent>
        </Tooltip>
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button class="p-0 w-8" size="sm" variant="ghost" @click="togglePlaying">
              <IconSaxPlay class="h-6 w-6 stroke-current fill-current" v-if="!videoStore.playing" />
              <IconSaxPause class="h-6 w-6 stroke-current fill-current" v-else type="bold" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">
            {{ videoStore.playing ? 'Play' : 'Pause' }}
            <Keyboard class="translate-x-0.5 -translate-y-px" event="play/pause" />
          </TooltipContent>
        </Tooltip>
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Transition
              appear
              enter-active-class="transition-[transform,_opacity]"
              leave-active-class="transition-[transform,_opacity]"
              enter-from-class="opacity-0 -translate-x-2 translate-y-0.5 scale-75"
              enter-to-class="opacity-100 translate-x-0"
              leave-from-class="opacity-100 translate-x-0"
              leave-to-class="opacity-0 -translate-x-2 translate-y-0.5 scale-75"
            >
              <Button
                class="p-0 w-8" v-if="!videoStore.playing" size="sm"
                variant="ghost"
                @click="goForward"
                @mousedown="onMouseDownForward"
                @mouseleave="mouseDown = false"
                @mouseup="mouseDown = false"
              >
                <IconSaxForward class="h-5 w-5" />
              </Button>
            </Transition>
          </TooltipTrigger>
          <TooltipContent side="top">
            Go forward a frame
            <Keyboard class="translate-x-0.5 -translate-y-px" event="skip-forward" />
          </TooltipContent>
        </Tooltip>
      </div>

      <div class="flex flex-row items-center justify-end gap-0.5">

        <TimelineVolumeButton />

        <template v-if="captionsMode">
          <Tooltip disable-hoverable-content>
            <TooltipTrigger>
              <label class="mt-0.5 flex items-center justify-center gap-1 text-xs font-light cursor-pointer mx-1 whitespace-nowrap">
                <Switch size="sm" v-model:checked="captionsStore.showOtherTracksWhileInCaptionsEditMode" />
                Show all tracks
              </label>
            </TooltipTrigger>
            <TooltipContent side="top">
              Show all timeline tracks
            </TooltipContent>
          </Tooltip>
        </template>

        <div class="relative flex items-center gap-1 shrink-0" v-if="isOpen">
          <Tooltip disable-hoverable-content>
            <TooltipTrigger>
              <Button
                class="h-auto p-2 disabled:bg-transparent"
                :disabled="zoomLevel === 1"
                size="sm"
                variant="ghost"
                @click="nudgeZoom(nudgeAmount)"
              >
                <IconSaxMinusCirlce class="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="top">
              Zoom out
            </TooltipContent>
          </Tooltip>

          <TimelineZoomSlider />

          <Tooltip disable-hoverable-content>
            <TooltipTrigger>
              <Button
                class="h-auto p-2 disabled:bg-transparent"
                :disabled="zoomLevel === 0"
                size="sm"
                variant="ghost"
                @click="nudgeZoom(nudgeAmount * -1)"
              >
                <IconSaxAddCircle class="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="top">
              Zoom in
            </TooltipContent>
          </Tooltip>
        </div>

        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button
              class="h-auto p-2 disabled:bg-transparent"
              size="sm"
              variant="ghost"
              @click="isOpen = !isOpen"
            >
              <IconSaxSidebarBottom class="h-4 w-4" :class="isOpen ? '' : 'rotate-180'" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top" align="end" :align-offset="-4">
            <span v-if="isOpen">Hide timeline</span>
            <span v-else>Show timeline</span>
          </TooltipContent>
        </Tooltip>
      </div>
    </div>

    <div class="flex-row flex-wrap items-center justify-center gap-1 flex w-full">
      <template v-if="captionsMode">
        <div class="absolute left-2 -mt-1">
          <Tooltip disable-hoverable-content>
            <TooltipTrigger as-child :class="{ 'opacity-50 !cursor-not-allowed': !splittingIsPossible }">
              <Button size="sm" variant="ghost" @click="splitSegment()">
                <IconSaxScissor class="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="top" class="font-light">
              <template v-if="splittingIsPossible">
                Split clip at current time <Keyboard class="translate-x-0.5 -translate-y-px" event="split" />
              </template>
              <template v-else>
                Select a clip segment in the timeline to split
              </template>
            </TooltipContent>
          </Tooltip>
        </div>
        <CaptionsTimelineHeaderToolbar />
      </template>
      <template v-else>
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button :disabled="!splittingIsPossible" size="sm" variant="ghost" @click="splitSegment()">
              <IconSaxScissor class="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">
            <template v-if="splittingIsPossible">
              Split clip at current time <Keyboard class="translate-x-0.5 -translate-y-px" event="split" />
            </template>
            <template v-else>
              Select an element to split
            </template>
          </TooltipContent>
        </Tooltip>
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button class="h-auto p-2" size="sm" variant="ghost" @click="pullRight">
              <IconSaxArrowRightStop class="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">
            Trim the start
            <Keyboard class="translate-x-0.5 -translate-y-px" event="trim-start" />
          </TooltipContent>
        </Tooltip>
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button class="h-auto p-2" size="sm" variant="ghost" @click="pullLeft">
              <IconSaxArrowLeftStop class="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">
            Trim the end
            <Keyboard class="translate-x-0.5 -translate-y-px" event="trim-end" />
          </TooltipContent>
        </Tooltip>
        <Tooltip disable-hoverable-content v-if="editorFocusStore.canDeleteFocusModel">
          <TooltipTrigger>
            <Button class="h-auto p-2" size="sm" variant="ghostDestructive" @click="editorFocusStore.deleteFocusModel()">
              <IconSaxTrash class="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">
            Delete selected
            <Keyboard class="translate-x-0.5 -translate-y-px" event="delete" :limit="1" />
          </TooltipContent>
        </Tooltip>
      </template>
    </div>
  </div>
</template>

<style scoped>
.btn.btn-ghost {
  background-color: transparent;
  border-color: transparent;
  padding-inline: 4px;
  gap: 0.25rem;
  display: flex;
  font-weight: 400;
}

.btn.btn-ghost.btn-info {
  background-color: #f2fcfe;
  color: #3b82f6;
}

.btn.btn-ghost.btn-success {
  background-color: #f0fdf4;
  color: #097c55;
}

.btn.btn-ghost:hover {
  background: #f3f4f6;
  border-color: transparent;
  color: black;
}

#zoomSlider {
  direction: rtl;
}

button > svg {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
</style>
