import type { VideoFragment } from '../types'
import { VideoWorkerManager } from '@/webcodec-renderer/VideoWorkerManager'
import { type ColorSource } from '@pixi/color'
import { Rectangle } from '@pixi/math'
import { getBackgroundCropData } from '@/modules/SLVideoplayer/helpers'
import type { RendererCrop } from '@/areas/editor/store/useRendererData'
import { watch, type Ref } from 'vue'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'

export class WorkerRenderer {

  static renderer?: WorkerRenderer;

  private videoFragments: Map<string, VideoFragment> = new Map()
  private crops: Ref<RendererCrop[] | undefined>

  private backgroundVideo: CanvasImageSource | undefined
  private canvas: HTMLCanvasElement;
  private _isReady: boolean;
  private worker: VideoWorkerManager;
  private audioMixWorker: VideoWorkerManager;
  private currentVideoSegment: HTMLVideoElement | undefined;
  private frameSource: CanvasImageSource | undefined;
  private initializePromise: Promise<null>;
  private initializeResolve: () => void;
  private aspectRatio: number;

  private lastResizeWidth = 0;
  private isUpdating = false;
  private disposed = false;
  private screen: Rectangle = new Rectangle(0,0,0,0);

  private audioElement?: HTMLAudioElement;

  constructor(width: number, height: number, backgroundColor?: ColorSource) {

    this.initializeResolve = () => {};
    this.initializePromise = new Promise(resolve => {
        this.initializeResolve = () => resolve(null);
    })

    this.worker = new VideoWorkerManager({ width, height, backgroundColor, live: true });
    this.audioMixWorker = new VideoWorkerManager({ width, height, backgroundColor, live: false });

    this.worker.initializeForPreview(this.initializeResolve);
    this.canvas = this.worker.canvas;
    this.aspectRatio = this.canvas.height / this.canvas.width;
    this.canvas.style.width = '100%';

    this._isReady = true;

    this.ticker.update();
    window.requestAnimationFrame(this.animationLoop.bind(this));

    const effectsStore = useEffectsStore();
    watch(effectsStore, () => this.mixAudio(), { immediate: true });

    WorkerRenderer.renderer = this;
  }


  async mixAudio() {

    if (!this.currentVideoSegment) return;

    // make sure no other mix audio process is running
    if (this.audioMixWorker.mixAudioPromise) {
      await this.audioMixWorker.mixAudioPromise!;
    }

    console.time('createWav')

    this.audioMixWorker.mixAudio(this.currentVideoSegment.duration);

    const audioUrl = await this.audioMixWorker.mixAudioPromise!;
   
    if (this.audioElement) {
      this.audioElement.src = audioUrl;
    }

    console.timeEnd('createWav')
  }

  syncAudioToVideo(videoElement: HTMLVideoElement) {
    if (this.audioElement) {
      const diff = Math.abs(videoElement.currentTime - this.audioElement.currentTime);
      if (diff > .5) {
        this.audioElement.currentTime = videoElement.currentTime;
      }
      else if (diff > 0.1) {
          if (!this.audioElement.seeking) {
              const minRate = 0.9;
              const maxRate = 1.1;
              const baseRate = 1.0;

              this.audioElement.playbackRate = Math.min(
                maxRate,
                Math.max(
                  minRate,
                  baseRate + diff * 1.2
                )
              );
          }
      }
    }
  }

  async animationLoop() {

    if (this.currentVideoSegment) {
        await this.ticker.update();
    }

    if (!this.disposed) {
      window.requestAnimationFrame(this.animationLoop.bind(this));
    }
  }

  public get app() {
    return {
        resize: () => {
            const containerSize = this.currentVideoSegment?.parentElement?.getBoundingClientRect();
            if (containerSize) {
                const width = Math.round(containerSize.width);
                const height = Math.round(width * this.aspectRatio);

                if (this.lastResizeWidth !== width) {
                    this.lastResizeWidth = width;
                    this.worker.resize(width, height)
                    this.ticker.update();
                }
            }
        },
    }
  }

  public get view() {
    return this.canvas;
  }

  public get ticker() {
    return {
        update: async () => {
            
            if (!this.currentVideoSegment) return;
            if (this.isUpdating) return;
            
            this.isUpdating = true;

            try {

              await this.initializePromise;

              const absoluteTimestamp = Math.round(this.currentVideoSegment.currentTime * 1_000_000);
              const timestampMs = this.currentVideoSegment.currentTime * 1_000;

              let clampedTimestamp = absoluteTimestamp;

              if (this.crops?.value) {

                let smallestDistance = Infinity;

                for (const crop of this.crops.value) {

                  // Is within a crop
                  if (crop.startMs <= timestampMs && crop.endMs >= timestampMs) {
                    clampedTimestamp = absoluteTimestamp;
                    break;
                  }

                  // Calculate the distance to the nearest edge of this crop
                  const distanceToStart = Math.abs(crop.startMs - timestampMs);
                  const distanceToEnd = Math.abs(crop.endMs - timestampMs);

                  if (distanceToStart < smallestDistance) {
                    smallestDistance = distanceToStart;
                    clampedTimestamp = Math.ceil(crop.startMs * 1_000);
                  }

                  if (distanceToEnd < smallestDistance) {
                    smallestDistance = distanceToEnd;
                    clampedTimestamp = Math.floor(crop.endMs * 1_000);
                  }
                }
              }

              if (this.crops?.value && this.frameSource) {

                const videoFragments = selectCurrentVideoFragments(this.crops.value, this.currentVideoSegment, this.frameSource, clampedTimestamp / 1_000);
                const map = new Map<string, VideoFragment>();
                map.set('background', this.videoFragments.get('background') as VideoFragment);
                for (const fragment of videoFragments) {
                  map.set(fragment.key, fragment);
                }

                const { width, height } = sizeOfCanvasImageSource(this.frameSource);
                this.worker.renderVideoFrames(map, clampedTimestamp, width, height);
              } else {
                const width = this.currentVideoSegment.videoWidth;
                const height = this.currentVideoSegment.videoHeight;
                this.worker.renderVideoFrames(this.videoFragments, clampedTimestamp, width, height);
              }
            } catch (e) {
              console.error(e);
            }

            this.isUpdating = false;
        },
        start: () => {},
        stop: () => {},
        pause: () => {},
    }
  }

  public get height() {
    return this.canvas.height;
  }

  public get width() {
    return this.canvas.width;
  }

  get isReady() {
    return this._isReady;
  }

  public resizeTo(element: HTMLElement) {
    this.worker.resize(element.clientWidth, element.clientHeight);

    this.screen = new Rectangle(0, 0, element.clientWidth, element.clientHeight);
  }

  public setCrops(crops: Ref<RendererCrop[]>, videoElement: HTMLVideoElement, frameSource: CanvasImageSource) {

    this.currentVideoSegment = videoElement;
    this.frameSource = frameSource;

    // Store crops proxy objects
    this.crops = crops
  }
  
  public setAudio(audioElement: HTMLAudioElement) {
    this.audioElement = audioElement;
  }

  public setVideo(identifier: string, fragment: VideoFragment) {
    this.currentVideoSegment = fragment.source as HTMLVideoElement;
    this.videoFragments.set(identifier, fragment)
  }

  public removeVideo(identifier: string) {
    if (this.videoFragments.get(identifier)) {
      this.videoFragments.delete(identifier)
    }
  }

  setBackground(src: CanvasImageSource | null, addBlurEffect = true) {
    if (!src) {
      this.backgroundVideo = undefined
      this.removeVideo('background')
      return
    }
    this.backgroundVideo = src
    const { width, height } = sizeOfCanvasImageSource(src)
    this.setVideo('background', {
      key: '',
      sourceEndMs: 0,
      sourceStartMs: 0,
      source: src,
      cropData: getBackgroundCropData(width, height, this.width, this.height),
      feedData: {
        x: 0,
        y: 0,
        w: 1,
        h: 1,
      },
      effect: addBlurEffect
        ? [
            {
              type: 'blur',
              strength: Math.round(window.innerHeight / height),
              quality: 3,
            },
          ]
        : [],
      zIndex: 0,
    })
  }
  
  setWatermark(show: boolean) {
    // TODO: Valesco will fix this
    // TODO: No
  }

  destroy() {
    this.worker?.terminate();
    this.disposed = true;
  }
}

function selectCurrentVideoFragments(crops: RendererCrop[], video: HTMLVideoElement | undefined, frameSource: CanvasImageSource, currentTimeMs: number) {

  const fragments: VideoFragment[] = [];
  for (const crop of crops) {

    if (!video) {
      break;
    }

    if (crop.startMs <= currentTimeMs && crop.endMs >= currentTimeMs) {
      fragments.push({
        key: crop.key,
        zIndex: crop.z,
        source: frameSource,
        sourceStartMs: crop.startMs,
        sourceEndMs: crop.endMs,
        effect: [...crop.shape === 'circle' ? [{ type: 'rounded' } as const] : [], ...crop.effects],
        cropData: {
          x: crop.cropData.x,
          y: crop.cropData.y,
          w: crop.cropData.width,
          h: crop.cropData.height,
          ratio: 1,
        },
        feedData: {
          x: crop.cropData.feedData.x,
          y: crop.cropData.feedData.y,
          w: crop.cropData.feedData.width,
          h: crop.cropData.feedData.height,
          ratio: 1,
        },
      });
    }
  }
  return fragments;
}

function sizeOfCanvasImageSource(source: CanvasImageSource) {
  if ('videoWidth' in source) {
    return { width: source.videoWidth, height: source.videoHeight }
  } else if ('codedWidth' in source) {
    return { width: source.codedWidth, height: source.codedHeight }
  } else if (typeof source.width === 'number' && typeof source.height === 'number') {
    return { width: source.width, height: source.height }
  } else {
    throw new Error('Could not determine size of CanvasImageSource')
  }
}
