import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { computed } from 'vue'
import { uniq } from 'lodash-es'

export function selectCaptionPresetByActiveCaptionStyles() {
  
  const { captionPreset } = useEditorCaptionsStore();
  const captions = useActiveCaptions()

  const colorsInProject = computed(() => {
    return uniq(captions.value.flatMap(caption => caption.words.map(word => word.color)))
  })
  
  const activeVariants = computed(() => {
    return captionPreset.variants.filter(variant => colorsInProject.value.includes(variant.key))
  })

  return computed(() => ({
    ...captionPreset,
    variants: activeVariants.value
  }))
}

export function useActiveCaptions() {

  const editorCaptionsStore = useEditorCaptionsStore();
  const segmentsStore = useSegmentsStore();

  const captions = editorCaptionsStore.captions;
  const segments = segmentsStore.whereIsNotZoom();

  return computed(() => {

    return captions.filter((caption) => {

      for (const segment of segments.value) {
        if (caption.start < segment.endMs && caption.end > segment.startMs) {
          return true;
        }
      }

      return false
    })
  })
}
