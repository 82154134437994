import localForage from 'localforage'
import { batchAsync } from '@/helpers/batchAsync'
import { useGetUploadedVideosQuery } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import * as Sentry from '@sentry/vue'

type Stored = { 
  id: string
  buffer: ArrayBuffer
  name: string
  type: 'video/mp4' | 'video/quicktime'
}

// Save the file in the local storage so that we can resume the upload if the user navigates away from the page and then
// returns to it. Needs to be caught, because there is a bug in Safari that prevents saving files to local storage.
// Since resuming is a Quality of Life feature, we can ignore the error and just continue with the upload until we find 
// a better solution.
export async function saveFileInStorage(id: string, file: File) {

  // Clear storage if we are running out of space
  if (await estimateAvailableSpace() < file.size * 2) {
    try {
      await localForage.clear()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  // Opt not to store if there is still not enough space
  if (await estimateAvailableSpace() < file.size) {
    return
  }
    
  try {
    const arrayBuffer = await file.arrayBuffer()
    await localForage.setItem<Stored>(id, {
      id: id,
      buffer: arrayBuffer,
      name: file.name,
      type: file.type as 'video/mp4' | 'video/quicktime'
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export async function getFileFromStorage(id: string) {
  
  const stored = await localForage.getItem<Stored>(id)
  if (!stored) {
    return null
  }
  
  const blob = new Blob([stored.buffer], { type: stored.type })
  return new File([blob], stored.name, { type: stored.type })
}

export async function removeFileFromStorage(id: string) {
  if (await localForage.getItem<Stored>(id)) {
    await localForage.removeItem(id)
  }
}

export async function cleanupFileStorage() {
  const { uploadedVideos, suspense } = useGetUploadedVideosQuery()
  await suspense()
  const storageKeys = await localForage.keys()
  const uploadIds = uploadedVideos.value.map(video => video.id)
  await batchAsync(storageKeys.filter(key => !uploadIds.includes(key)), key => removeFileFromStorage(key))
}


async function estimateAvailableSpace() {
  if (typeof navigator.storage?.estimate === 'function') {
    const storage = await navigator.storage.estimate()
    return (storage.quota ?? 0) - (storage.usage ?? 0)
  } else {
    return Infinity
  }
}
