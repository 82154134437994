<script lang="ts" setup>
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, onMounted, ref } from 'vue'
import { IconExclamationCircle } from '@tabler/icons-vue'
import type { SocialMedia, TargetListingDto } from '@/apis/streamladder-publisher/model'
import { TargetState } from '@/apis/streamladder-publisher/model'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import { useExternalApiErrorCodes } from './hooks/useExternalApiErrorCodes'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { Button } from '@/components/ui/button'
import { RouterLink } from 'vue-router'

const props = defineProps<{ postId: string; target: TargetListingDto }>()

const userInfo = useUserInfoStore()

const userHandle = computed(() => {
  if (!props.target.accountId) return

  const account = userInfo.findSocialById(props.target.accountId)
  if (!account) return undefined

  const handles: Record<SocialMedia, string> = {
    Instagram: `@${account.displayName}`,
    Tiktok: `@${account.displayName}`,
    Youtube: account.displayName,
  }

  if (!props.target.socialMedia) return undefined

  return handles[props.target.socialMedia]
})

const status = computed<(typeof TargetState)[keyof typeof TargetState]>(
  () => props.target.status?.status ?? 'Scheduled',
)

const statusLabel = computed(() => {
  return {
    [TargetState.New]: 'New',
    [TargetState.Failed]: 'Failed',
    [TargetState.Queued]: 'In queue',
    [TargetState.Processing]: 'In progress',
    [TargetState.Published]: 'Published',
    [TargetState.Publishing]: 'Publishing',
    [TargetState.Scheduled]: 'Scheduled',
    [TargetState.SentToInbox]: 'Sent as Draft',
  }[status.value]
})

const isError = computed(() => status.value === 'Failed')
const errorFor = useExternalApiErrorCodes()
const error = computed(() => errorFor(props.target.status?.code))

const redirectTitle = ref(`View on ${props.target.socialMedia}`);

const redirectYoutubeUrl = () => 'https://youtube.com/watch?v=' + props.target.externalContentId;

const redirectTikTokUrl = () => {

  const user = userInfo.tikTokAccounts.find((tikTokAccount) => tikTokAccount.id === props.target.accountId)!;

  // If externalContentId is a number, it's a video id. In all other cases, the video is pending, private or sent to inbox.
  if (props.target.externalContentId && (/^\d+$/).test(props.target.externalContentId)) {
    return 'https://tiktok.com/@' + user.displayName + '/video/' + props.target.externalContentId;
  } else {
    redirectTitle.value = 'View profile';
    return 'https://tiktok.com/@' + user.displayName;
  }
};

const redirectUrl = computed(() => {
  switch (props.target.socialMedia) {
    case 'Tiktok':
      return redirectTikTokUrl();
    case 'Youtube':
      return redirectYoutubeUrl();
    default:
      return undefined;
  }
});
</script>

<template>
  <li
    :class="status === 'Failed' ? 'border-rose-400 bg-rose-400/10' : 'border-transparent'"
    class="flex flex-col gap-2 rounded-lg border sm:px-4 py-2"
  >
    <div class="flex items-center gap-4">
      <template v-if="redirectUrl && (status === 'Published' || status === 'SentToInbox')">
        <a :href="redirectUrl" target="_blank" class="flex flex-col items-center gap-1 w-20 link text-center hover:scale-105 transition-all" variant="link">
          <TikTokIcon
            v-if="target.socialMedia === 'Tiktok'"
            class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-black p-2 text-white"
          />
          <YoutubeIcon
            v-else-if="target.socialMedia === 'Youtube'"
            class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-[#F00] p-1.5 text-white"
          />
          <InstagramIcon
            v-else-if="target.socialMedia === 'Instagram'"
            class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg p-2 text-white border"
          />
          <span class="text-xs font-light">
            {{ redirectTitle.replace('Tiktok', 'TikTok') }}
          </span>
        </a>
      </template>
      <template v-else>
        <div class="flex flex-col items-center gap-1 w-20">
          <TikTokIcon
            v-if="target.socialMedia === 'Tiktok'"
            class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-black p-2 text-white"
          />
          <YoutubeIcon
            v-else-if="target.socialMedia === 'Youtube'"
            class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-[#F00] p-1.5 text-white"
          />
          <InstagramIcon
            v-else-if="target.socialMedia === 'Instagram'"
            class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg p-2 text-white border"
          />
        </div>
      </template>
      <span v-if="userHandle" class="mr-4 font-semibold truncate min-w-0">{{ userHandle }}</span>
      <span v-else class="font-semibold">Unknown User 🕵️</span>
      <span
        :class="{
          'bg-rose-500': status === 'Failed',
          'bg-emerald-500': status === 'Published' || status === 'SentToInbox',
          'bg-[#B3B3B3]': ['Processing', 'Publishing', 'Queued'].includes(status),
          'bg-sky-600': ['New', 'Scheduled'].includes(status),
        }"
        class="ml-auto flex h-8 items-center gap-2 rounded px-2 py-1.5 leading-none text-white whitespace-nowrap"
      >
        <IconExclamationCircle v-if="status === 'Failed'" :size="20" />
        {{ statusLabel }}
      </span>
    </div>
    <template v-if="error && isError && userHandle">
      <p class="text-sm font-semibold text-rose-400">{{ error.title }}</p>
      <p class="text-sm font-light text-rose-400">
        {{ error.description }}
      </p>
      <a
        v-if="error.link"
        :href="error.link.href"
        class="link-hover link text-sm font-light text-blue-500"
        target="_blank"
      >
        {{ error.link.text }}
      </a>
    </template>
    <template v-if="error && isError && !userHandle">
      <p class="text-sm font-semibold text-rose-400">Social account not found</p>
      <p class="text-sm font-light text-rose-400">
        The social account used to schedule or publish this post is not found in your socials list anymore.
      </p>
      <Button
        :as="RouterLink"
        v-if="error.link"
        variant="link"
        :to="{ name: dashboardRouteNames.socials }"
        class="link-hover link text-sm font-light text-blue-500 text-left justify-start p-0 h-auto"
      >
        Go to active socials
      </Button>
    </template>
  </li>
</template>

<style lang="scss" scoped></style>
