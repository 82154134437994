import { markRaw, type Component } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'
import CaptionTextSticker from '@/components/Stickers/CaptionTextSticker.vue'
import { type CaptionStyleSettings, captionStyleSettingsArray, type CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'

/**
 * NOTE: For now the components should also be included in the TestPage.vue to avoid lazy-loading as it has issues
 */

const textLibrary = [
  {
    key: 'text-padded',
    component: markRaw(TextSticker),
    tags: ['text'],
    icon: '',
    variant: 'padded',
    title: 'Padded',
    colors: {
      white: 'white',
      black: 'black',
      red: '#ea4040',
      orange: '#ff923d',
      yellow: '#f2ce46',
      green: '#77c25d',
      lightblue: '#3496ef',
      pink: '#f8d7e8',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'text-outline',
    component: markRaw(TextSticker),
    tags: ['text'],
    icon: '',
    variant: 'outline',
    title: 'Action',
    colors: {
      white: 'white',
      black: 'black',
      red: '#ea4040',
      orange: '#ff923d',
      yellow: '#f2ce46',
      green: '#77c25d',
      lightblue: '#3496ef',
      pink: '#f8d7e8',
    },
    createdAt: new Date(1970, 0, 1),
  },
  // {
  //   key: 'text-outline-2',
  //   component: markRaw(TextSticker),
  //   tags: ['text'],
  //   icon: '',
  //   variant: 'outline-2',
  //   title: 'Playfull',
  //   colors: {
  //     white: 'white',
  //     black: 'black',
  //     red: 'red',
  //     orange: '#ff6f00',
  //     yellow: '#fff200',
  //     green: '#00ff00',
  //     lightblue: '#0084ff',
  //     pink: '#ff00d4',
  //   },
  // },
  // {
  //   key: 'text-fat',
  //   component: markRaw(TextSticker),
  //   tags: ['text'],
  //   icon: '',
  //   variant: 'fat-montserrat',
  //   title: 'Fat',
  //   colors: {
  //     white: 'white',
  //     red: 'red',
  //     yellow: '#fff200',
  //     green: '#00ff00',
  //     lightblue: '#0084ff',
  //     pink: '#ff00d4',
  //   },
  // },
  {
    key: 'text-glow',
    component: markRaw(TextSticker),
    tags: ['text'],
    icon: '',
    variant: 'glow',
    title: 'Neon',
    colors: {
      blue: 'rgb(8, 8, 241)',
      red: '#ea4040',
      yellow: 'rgb(255, 255, 0)',
      pink: '#f8d7e8',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'text-transparent',
    variant: 'transparent',
    tags: ['text'],
    component: markRaw(TextSticker),
    colors: {
      white: 'white',
      red: '#ea4040',
      orange: '#ff923d',
      yellow: '#f2ce46',
      green: '#77c25d',
      lightblue: '#3496ef',
      pink: '#f8d7e8',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'text-regular',
    component: markRaw(TextSticker),
    tags: ['text'],
    icon: '',
    variant: 'regular',
    title: 'regular',
    colors: {
      black: 'black',
      white: 'white',
      red: '#ea4040',
      orange: '#ff923d',
      yellow: '#f2ce46',
      green: '#77c25d',
      lightblue: '#3496ef',
      pink: '#f8d7e8',
    },
    createdAt: new Date(1970, 0, 1),
  },
] as const satisfies Readonly<TextLibraryItem[]>

function takeEvenlySpacedItems<T>(list: T[], space: number) {
  
  // Calculate the interval between items
  const interval = Math.floor(list.length / space)

  // Initialize the result array
  const result = [] as T[]

  // Iterate through the array to select items at intervals
  for (let i = 0; i < space; i++) {
    const index = i * interval
    result.push(list[index])
  }

  return result
}

const captionStyleToTextLibraryItem = (captionStyle: CaptionStyleSettings): TextLibraryItem => ({
  key: captionStyle.previewSettings?.displayName ?? captionStyle.cssClass,
  component: markRaw(CaptionTextSticker),
  tags: ['text',...(captionStyle.tags || [])],
  icon: '',
  variant: captionStyle.type,
  title: captionStyle.previewSettings?.displayName ?? captionStyle.cssClass,
  colors: withBlackAndWhite(captionStyle.colors),
  createdAt: new Date(1970, 0, 1),
  fontFamily: captionStyle.fontFamily,
})

function withBlackAndWhite(inputColors: string[]) {
  
  const outputColors = takeEvenlySpacedItems(inputColors,6);
  const result = {} as Record<string, string>;
  for (const color of outputColors) {
    result[color] = color;
  }
  
  const white = ['white', '#ffffff', '#fff', '#FFFFFF', '#FFF']
  if (white.some(color => inputColors.includes(color)) && !white.some(color => outputColors.includes(color))) {
    result.white = 'white';
  }
  
  const black = ['black', '#000000', '#000']
  if (black.some(color => inputColors.includes(color)) && !black.some(color => outputColors.includes(color))) {
    result.black = 'black';
  }
  
  return result;
}

const textStylesArray = [...captionStyleSettingsArray.map(captionStyleToTextLibraryItem),...textLibrary]
export const textStylesDictionary = textStylesArray.reduce((acc, item) => {
  return ({
    ...acc,
    [item.variant]: item,
  })
}, {} as Record<TextStyleVariants | CaptionStyle, typeof textLibrary[number]>)

export type TextLibraryItem = {
  key: string
  component: Component
  tags?: readonly 'text'[]
  icon?: string
  variant: string
  title?: string
  colors?: Record<string, string>
  createdAt: Date
}

export const oldTextStylesArray = textLibrary
export type TextStyleVariants = typeof textLibrary[number]['variant']
export const textStyleVariants = textLibrary.map((s) => s.variant) 

export default textStylesArray
