import * as Sentry from '@sentry/vue'

async function fetchVideoFileMeta(file: File, options?: { thumbSize?: number }) {

  const url = URL.createObjectURL(file)
  const { video, dispose } = await createVideo(url)

  const duration = video.duration
  const videoSize = {
    width: video.videoWidth,
    height: video.videoHeight
  }

  const thumbnail = await captureVideoThumbnail(video, options?.thumbSize)

  await new Promise(resolve => setTimeout(resolve, 0))
  dispose()

  const fileNameParts = file.name.split('.')
  const fileExtension = fileNameParts.pop()!
  const fileName = fileNameParts.join('.')

  return {
    fileName: fileName,
    fileType: file.type,
    fileExtension: fileExtension,
    fileSize: file.size,
    blobUrl: url,
    duration: duration,
  
    naturalVideoWidth: videoSize.width,
    naturalVideoHeight: videoSize.height,
  
    thumbnail: {
      ...thumbnail,
      fileName: file.name + '.thumbnail',
    },
  
    dispose() {
      URL.revokeObjectURL(url)
      URL.revokeObjectURL(this.thumbnail.blobUrl)
    }
  }
}

export type VideoFileMeta = Awaited<ReturnType<typeof fetchVideoFileMeta>>

async function createVideo(url: string) {

  const video = document.createElement('video')

  video.src = url
  video.muted = true
  video.playsInline = true
  video.autoplay = false

  await canProgressAsync(video)

  return { video, dispose: () => disposeOfVideo(video) }
}

function disposeOfVideo(video: HTMLVideoElement) {
  video.src = ''
  video.remove()
}

async function fetchVideoFileDuration(file: File) {

  const url = URL.createObjectURL(file)
  const { video, dispose } = await createVideo(url)
  await canProgressAsync(video)

  const duration = video.duration
  dispose()
  URL.revokeObjectURL(url)

  return duration
}

async function captureVideoThumbnail(video: HTMLVideoElement, maxSize = 512) {

  const previousTime = video.currentTime
  const wasPaused = video.paused

  if (wasPaused) {
    const canPlay = await canPlayVideo(video)
    if (!canPlay) {
      return await usePlaceholder(maxSize)
    }
  }
  
  if (video.readyState >= video.HAVE_METADATA && (video.videoWidth === 0 || video.videoHeight === 0)) {
    return await usePlaceholder(maxSize)
  }
  
  const videoWidth = video.videoWidth
  const videoHeight = video.videoHeight

  const scale = Math.min(maxSize / videoWidth, maxSize / videoHeight)

  const thumbnailWidth = videoWidth * scale
  const thumbnailHeight = videoHeight * scale

  const canvas = new OffscreenCanvas(thumbnailWidth, thumbnailHeight)
  const context = canvas.getContext('2d')!

  await trySetTimeHalfway(video)
  await canPlayVideo(video)
  await canProgressAsync(video)
  
  context.drawImage(video, 0, 0, canvas.width, canvas.height)

  const blob = await canvas.convertToBlob({
    type: 'image/jpeg',
    quality: 0.5
  })
  
  if (wasPaused) {
    video.pause()
  }

  video.currentTime = previousTime

  return {
    blob: blob,
    blobUrl: URL.createObjectURL(blob),
    width: thumbnailWidth,
    height: thumbnailHeight
  }
}

async function usePlaceholder(maxSize: number) {

  const thumbnailScale = Math.min(maxSize / 1920, maxSize / 1080)
  const thumbnailWidth = 1920 * thumbnailScale
  const thumbnailHeight = 1080 * thumbnailScale
  
  const canvas = new OffscreenCanvas(thumbnailWidth, thumbnailHeight)
  const context = canvas.getContext('2d')!
  context.fillStyle = '#D9D9E0'
  context.fillRect(0, 0, canvas.width, canvas.height)

  const svgScale = Math.min(thumbnailWidth / 24, thumbnailHeight / 24) * 0.75
  const svgWidth = 24 * svgScale
  const svgHeight = 24 * svgScale

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('width', svgWidth.toString())
  svg.setAttribute('height', svgHeight.toString())
  svg.setAttribute('viewBox', '0 0 24 24')
  svg.setAttribute('fill', '#B9BBC6')

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  path.setAttribute('d', 'M16.19 2H7.81C4.17 2 2 4.17 2 7.81v8.37C2 19.83 4.17 22 7.81 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22 4.17 19.83 2 16.19 2Zm-1.53 11.73-1.28.74-1.28.74c-1.65.95-3 .17-3-1.73v-2.96c0-1.91 1.35-2.68 3-1.73l1.28.74 1.28.74c1.65.95 1.65 2.51 0 3.46Z')
  svg.appendChild(path)

  const string = new XMLSerializer().serializeToString(svg)
  const base64 = 'data:image/svg+xml;base64,' + btoa(string)

  const image = new Image(svgWidth, svgHeight)
  image.src = base64

  await new Promise<void>(resolve => {
    image.onload = () => resolve()
  })

  context.drawImage(image, 
    0.5 * canvas.width - 0.5 * svgWidth, 
    0.5 * canvas.height - 0.5 * svgHeight,
    svgWidth, svgHeight)

  const blob = await canvas.convertToBlob({
    type: 'image/jpeg',
    quality: 0.5
  })

  return {
    blob: blob,
    blobUrl: URL.createObjectURL(blob),
    width: thumbnailWidth,
    height: thumbnailHeight
  }
}

async function canPlayVideo(video: HTMLVideoElement) {

  return await new Promise<boolean>((resolve) => {

    try {
      video.play()
        .catch((reason) => {
          Sentry.captureException(reason)
          resolve(false)
        })
        .then(() => {
          video.pause()
          if (video.videoWidth && video.videoHeight) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
    } catch (e) {
      console.error(e)
      resolve(false)
    }

    setTimeout(() => {
      resolve(false)
    }, 10_000)
  })
}

async function trySetTimeHalfway(video: HTMLVideoElement) {
  const duration = !isNaN(video.duration) ? video.duration : 0
  video.currentTime = Math.min(0.5, 0.5 * duration)
  await new Promise<void>((resolve) => {
    function advance() {
      resolve()
      video.removeEventListener('seeked', advance)
    }
    video.addEventListener('seeked', advance)
    setTimeout(() => {
      resolve()
    }, 1000)
  })
}

async function existsAsync(url: string) {
  return await fetch(url, { method: url.startsWith('blob:') ? 'GET' : 'HEAD' })
    .then((response) => response.ok)
    .catch(() => false)
}

async function canPlayUrl(url: string) {
  
  if (!await existsAsync(url)) {
    return false
  }
  
  await createVideo(url).catch(() => {
    return false
  })
  return true
}

async function canPlayFile(file: File) {
  const url = URL.createObjectURL(file)
  return await canPlayUrl(url)
}

async function canProgressAsync(video: HTMLVideoElement) {

  return await new Promise((resolve) => {

    function progress() {
      cleanup()
      resolve(video.videoWidth > 0 && video.videoHeight > 0)
    }

    function cleanup() {
      for (const event of events) {
        video.removeEventListener(event, progress)
      }
    }

    const events = ['seeked', 'canplay', 'canplaythrough', 'durationchange', 'error', 'loadeddata']
    for (const event of events) {
      video.addEventListener(event, progress)
    }

    setTimeout(() => {
      cleanup()
      resolve(video.videoWidth > 0 && video.videoHeight > 0 && video.readyState === video.HAVE_ENOUGH_DATA)
    }, 5000)
  })
}

function isSameMetaData(a: File, b: File) {
  return a.name === b.name
    && a.size === b.size
    && a.type === b.type
    && a.lastModified === b.lastModified
}

export const metadataService = {
  fetchVideoFileMeta,
  fetchVideoFileDuration,
  captureVideoThumbnail,
  canPlayUrl,
  canPlayFile,
  canPlayVideo,
  isSameMetaData,
  usePlaceholder,
}
