<script setup lang="ts">
import WorkspaceSpinner from '@/areas/editor/workspaces/WorkspaceSpinner.vue';
import { onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { startup, type StartupSource } from '@/areas/editor/startup';
import { v4 as uuid } from 'uuid';
import { storeProject } from '@/queries/projects/projectsApi';
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';
import { retryAsync } from '@/helpers/retry';
import type { Project, Captions } from '@/areas/editor/@type/Project';
import { handleStartupError } from '@/areas/editor/startup/handleStartupError';
import { version } from '@/data/versions';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore';
import { merge } from 'lodash-es';
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';

const route = useRoute();
const router = useRouter();

const editorClipInfoStore = useEditorClipInfoStore();

const clipSources = ['twitch-clip', 'twitch-vod', 'youtube-clip', 'kick-clip', 'local-file', 'kick-cx-clip'];
function isValidStartupSource(clipSource: unknown): clipSource is StartupSource {
  return typeof clipSource === 'string' && clipSources.includes(clipSource);
}

function isValidClipId(clipId: unknown): clipId is string {
  return typeof clipId === 'string';
}

const abortController = new AbortController();
const historyStore = useHistoryStore();

onMounted(async () => {
  historyStore.$reset();

  // The delay here stops the historyStore reset from interfering with the loading spinner.
  await new Promise((resolve) => setTimeout(resolve, 0));
  
  const clipSource = route.params.clipSource;
  const clipId = route.params.clipId;

  if (!isValidClipId(clipId) || !isValidStartupSource(clipSource)) {
    return await router.replace({ name: 'editor' });
  }

  const { error, clip } = await startup(clipSource, clipId, { signal: abortController.signal });
  if (error) {
    return;
  }
  
  if (!clip) {
    return editorClipInfoStore.loadingState = {
      state: 'error',
      description: 'Clip not found'
    }
  }

  const projectId = uuid();
  const captions = initialCaptionsState();

  const snapshot = typeof route.query.s === 'string' ? JSON.parse(decodeURIComponent(decodeFromBase64(route.query.s))) : {};
  const captionsSnapshot = { captions: captions };
  
  const baseProject = ({
    mp4Url: clip.mp4Url,
    language: clip.languageCode,
    title: clip.title,
    version: version,
    segments: [],
    layouts: [],
    crops: [],
    stickers: [],
    effects: [],
  });

  const project: Project = merge(baseProject, snapshot, captionsSnapshot, { id: projectId });

  try {
    await retryAsync(() => storeProject(project));
    await router.replace({ name: 'editor/[projectId]', params: { projectId } });
  } catch (e) {
    return handleStartupError(e);
  }
})

function decodeFromBase64(base64Str: string) {
  const binaryString = atob(base64Str);
  const binaryData = new Uint8Array([...binaryString].map(char => char.charCodeAt(0)));
  const decoder = new TextDecoder();
  return decoder.decode(binaryData);
}

const captionsStore = useCaptionsStore();

function initialCaptionsState() {
  return {
    entities: captionsStore.entities,
    hasGeneratedCaptions: captionsStore.hasGeneratedCaptions,
    captionsArea: captionsStore.captionsArea,
    baseOptions: captionsStore.baseOptions,
    baseCaptionPreset: captionsStore.baseCaptionPreset,
  } as Captions;
}

onUnmounted(() => {
  abortController.abort();
})
</script>

<template>
  <WorkspaceSpinner />
</template>

<style scoped lang="scss">

</style>
