<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useGesture } from '@vueuse/gesture'
import type { FullGestureState } from '@vueuse/gesture'
import { clamp, debounce } from 'lodash-es'
import compatabilityChecker from '@/services/compatabilityChecker'

const {
  containerEl,
  pxToMs,
  triggerSeek,
  dragging: timelineDragging,
  durationMs,
  _autoScroll,
} = useTimelineStore()

const isMobile = compatabilityChecker.isMobile()

const handleGesture = debounce((gestureState: FullGestureState<'drag'> & FullGestureState<'scroll'>) => {
  const {
    scrolling,
    xy: [x],
    tap,
    dragging,
    event
  } = gestureState
  
  const containerX = (containerEl.value?.getBoundingClientRect().x || 0)
  const containerScrollX = containerEl.value?.scrollLeft || 0
  const position = x - containerX + containerScrollX

  const ms = clamp(pxToMs(position), 0, durationMs.value)

  if (tap) {
    _autoScroll.value = 'idle'
    if (timelineDragging.value) timelineDragging.value = undefined
    if (!isTrackNode(event)) {
      triggerSeek(ms)
    }
    return
  }

  if (scrolling) {
    if (_autoScroll.value == 'seeking' || _autoScroll.value == 'idle') {
      _autoScroll.value = 'idle'
    } else {
      _autoScroll.value = 'disabled'
    }
  }

  if (dragging) {
    _autoScroll.value = 'disabled'
    if (!(timelineDragging.value?.type === 'node')) {
      triggerSeek(ms, true)
      timelineDragging.value = {
        type: 'seeker',
      }
    }
  } else {
    if (timelineDragging.value?.type === 'seeker') {
      timelineDragging.value = undefined
      triggerSeek(ms)
    }
  }
}, 2)

function isTrackNode(event: Event | undefined) {
  if (!event || !(event.target instanceof HTMLElement)) {
    return false
  } else {
    return event.target.matches('[data-track-node]') || event.target.closest('[data-track-node]')
  }
}

useGesture(
  {
    onDrag: handleGesture,
    onScroll: handleGesture,
    onDragStart: () => document.body.classList.add('select-none'),
    onDragEnd: () => document.body.classList.remove('select-none'),
  },
  {
    // Global options such as `domTarget`
    domTarget: containerEl,
    // Gesture specific options
    drag: {},
    // wheel: wheelOptions,
    // pinch: pinchOptions,
    // scroll: scrollOptions,
    // wheel: wheelOptions,
    // hover: hoverOptions,
  }
)

// useEventListener(
//   document,
//   'touchmove',
//   (evt) => {
//     if (timelineDragging.value) {
//       evt.preventDefault()
//     }
//   },
//   {
//     passive: false,
//   }
// )
</script>

<template>
  <div
    ref="containerEl"
    id="timeline-container"
    class="timeline-container relative overflow-auto"
    :style="{
      touchAction: timelineDragging?.type === 'node' ? 'none' : undefined,
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">

.timeline-container::-webkit-scrollbar {
  height: 7px !important;
}

.timeline-container::-webkit-scrollbar-track {
  background: rgba(70, 21, 128, 0.15);
  border-radius: 12px;
}

.timeline-container::-webkit-scrollbar-thumb {
  background: #461580;
  border-radius: 12px;
}
</style>
