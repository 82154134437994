import MP4Decoder from "./decoder/mp4-decoder";
import type { Area } from "./types";
import { drawLoader } from "./draw-loader";
import { decodeGif } from "./gif/decodeGif";
import WebpRenderer from "./webp-renderer";

const isInWorker = self.document === undefined;

interface frame {
    bitmap: ImageBitmap;
    timestamp: number;
    duration: number;

    dims?: {
        width: number;
        height: number;
        top: number;
        left: number;
    }
}

export default class GifRenderer {
    url: string;
    live: boolean;
    ctx: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;

    videoPreview?: HTMLVideoElement;
    decoder?: MP4Decoder;

    prevTimestamp: number;

    frames: frame[];
    duration: number;
    fallback: ImageBitmap;


    width?: number;
    height?: number;

    loading = false;
    
    constructor(ctx: CanvasRenderingContext2D, url: string, fallback: ImageBitmap, live: boolean) {
        this.ctx = ctx;
        this.canvas = ctx.canvas;
        this.url = url;
        this.live = live;
        this.prevTimestamp = -1;
        this.frames = [];
        this.duration = 0;
        this.fallback = fallback;

        if (this.live) {
            this.loading = true;
            this.initialize();
        }
    }

    async initialize() {
        if (isInWorker) {
            const decodedGif = await decodeGif(this.url);
                
            this.width = decodedGif.width;
            this.height = decodedGif.height;
            this.duration = decodedGif.duration;
            this.frames = decodedGif.outputFrames.map(frame => ({ ...frame, bitmap: frame.bitmap ?? this.fallback }));
            this.loading = false;
        }
        else {
            if (WebpRenderer.decodeWorker === null) {
                WebpRenderer.decodeWorker = new Worker(new URL('./webp/webp-worker.ts', import.meta.url), { type: "module" });
            }

            const listener = (message: MessageEvent) => {
                if (message.data.imageUrl === this.url) {
                    WebpRenderer.decodeWorker?.removeEventListener('message', listener);
                    this.frames = message.data.result.outputFrames;
                    this.duration = message.data.result.duration;
                    this.width = message.data.result.width;
                    this.height = message.data.result.height;
                    this.loading = false;
                }
            }

            WebpRenderer.decodeWorker.addEventListener('message', listener);
            WebpRenderer.decodeWorker.postMessage({ type: 'decode-gif', imageUrl: this.url });
        }
    }

    render(timestamp: number, startMs: number, endMs: number, area: Area) {

        if (timestamp > endMs * 1e3 || timestamp < startMs * 1e3) return;
        const stickerTime = (timestamp - startMs * 1e3) % this.duration;

        const frame = this.frames.find(frame => frame.timestamp <= stickerTime && frame.timestamp + frame.duration >= stickerTime);

        if (frame) {
            if (this.width && this.height && frame.dims) {
                this.ctx.drawImage(frame.bitmap, 0, 0, this.width, this.height, area.x * this.canvas.width, area.y * this.canvas.height, area.width * this.canvas.width, area.height * this.canvas.height);
            }
            else {
                this.ctx.drawImage(frame.bitmap, area.x * this.canvas.width, area.y * this.canvas.height, area.width * this.canvas.width, area.height * this.canvas.height);
            }
        }
        else if (this.loading) {
            const width = area.width * this.canvas.width;
            const height = area.height * this.canvas.height;
            drawLoader(this.ctx, this.canvas, area.x * this.canvas.width + width / 2, area.y * this.canvas.height + height / 2, width * .75, height * .75)
        }
    }
}