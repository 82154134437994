import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { publicAxios } from '@/services/axios'
import type { ClipDto } from '@/areas/editor/@type/ClipDto';

const kickCxUrl = 'https://clips-api.kick.cx/api/clips/details';

type KickCxClipDto = {
  data: {
    uid: string
    stream_title: string
    thumbnail_link: string
    direct_link_mp4: string
    viewer_count: number
    streamer_username: string
  }
};

export async function startupFromKickCxClipByClipId(clipId: string, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore();

  try {
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }

    const response = await publicAxios.get(`${kickCxUrl}/${clipId}`) as KickCxClipDto;
    config.signal?.throwIfAborted();

    return {
      error: null,
      clip: {
        id: response.data.uid,
        title: response.data.stream_title,
        viewCount: response.data.viewer_count,
        mp4Url: response.data.direct_link_mp4,
        thumbnailUrl: response.data.thumbnail_link,
        source: 'kick-cx-clip',
        languageCode: 'en_us',
        broadcasterName: response.data.streamer_username,
        createdAt: new Date().toISOString(),
        dateFromNow: 'just now',
        taskId: '',
      } as ClipDto
    };
  } catch (error) {
    return handleStartupError(error)
  }
}
