/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { GetApiUserSearchParams, UpdateDisplayNameRequest, UpdateEmailRequest } from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiUser = (options?: SecondParameter<typeof accountsAxiosInstance>, signal?: AbortSignal) => {
  return accountsAxiosInstance<void>({ url: `/api/User`, method: 'GET', signal }, options)
}

export const getGetApiUserQueryKey = () => {
  return ['api', 'User'] as const
}

export const getGetApiUserQueryOptions = <TData = Awaited<ReturnType<typeof getApiUser>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUser>>, TError, TData>>
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUserQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUser>>> = ({ signal }) =>
    getApiUser(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiUser>>,
    TError,
    TData
  >
}

export type GetApiUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUser>>>
export type GetApiUserQueryError = unknown

export const useGetApiUser = <TData = Awaited<ReturnType<typeof getApiUser>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUser>>, TError, TData>>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUserQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const putApiUserUpdateDisplayName = (
  updateDisplayNameRequest: MaybeRef<UpdateDisplayNameRequest>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  updateDisplayNameRequest = unref(updateDisplayNameRequest)

  return accountsAxiosInstance<void>(
    {
      url: `/api/User/UpdateDisplayName`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateDisplayNameRequest,
    },
    options
  )
}

export const getPutApiUserUpdateDisplayNameMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUserUpdateDisplayName>>,
    TError,
    { data: UpdateDisplayNameRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiUserUpdateDisplayName>>,
  TError,
  { data: UpdateDisplayNameRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiUserUpdateDisplayName>>,
    { data: UpdateDisplayNameRequest }
  > = (props) => {
    const { data } = props ?? {}

    return putApiUserUpdateDisplayName(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiUserUpdateDisplayNameMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiUserUpdateDisplayName>>
>
export type PutApiUserUpdateDisplayNameMutationBody = UpdateDisplayNameRequest
export type PutApiUserUpdateDisplayNameMutationError = unknown

export const usePutApiUserUpdateDisplayName = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUserUpdateDisplayName>>,
    TError,
    { data: UpdateDisplayNameRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof putApiUserUpdateDisplayName>>,
  TError,
  { data: UpdateDisplayNameRequest },
  TContext
> => {
  const mutationOptions = getPutApiUserUpdateDisplayNameMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putUpdateEmail = (
  updateEmailRequest: MaybeRef<UpdateEmailRequest>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  updateEmailRequest = unref(updateEmailRequest)

  return accountsAxiosInstance<void>(
    {
      url: `/updateEmail`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateEmailRequest,
    },
    options
  )
}

export const getPutUpdateEmailMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUpdateEmail>>,
    TError,
    { data: UpdateEmailRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putUpdateEmail>>, TError, { data: UpdateEmailRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUpdateEmail>>, { data: UpdateEmailRequest }> = (
    props
  ) => {
    const { data } = props ?? {}

    return putUpdateEmail(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutUpdateEmailMutationResult = NonNullable<Awaited<ReturnType<typeof putUpdateEmail>>>
export type PutUpdateEmailMutationBody = UpdateEmailRequest
export type PutUpdateEmailMutationError = unknown

export const usePutUpdateEmail = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUpdateEmail>>,
    TError,
    { data: UpdateEmailRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof putUpdateEmail>>,
  TError,
  { data: UpdateEmailRequest },
  TContext
> => {
  const mutationOptions = getPutUpdateEmailMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiUserId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return accountsAxiosInstance<void>({ url: `/api/User/${id}`, method: 'GET', signal }, options)
}

export const getGetApiUserIdQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'User', id] as const
}

export const getGetApiUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiUserId>>, TError = unknown>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUserId>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUserIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserId>>> = ({ signal }) =>
    getApiUserId(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiUserId>>, TError, TData>
}

export type GetApiUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserId>>>
export type GetApiUserIdQueryError = unknown

export const useGetApiUserId = <TData = Awaited<ReturnType<typeof getApiUserId>>, TError = unknown>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUserId>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUserIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const deleteApiUserId = (id: MaybeRef<string>, options?: SecondParameter<typeof accountsAxiosInstance>) => {
  id = unref(id)

  return accountsAxiosInstance<void>({ url: `/api/User/${id}`, method: 'DELETE' }, options)
}

export const getDeleteApiUserIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiUserId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiUserId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiUserId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deleteApiUserId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiUserId>>>

export type DeleteApiUserIdMutationError = unknown

export const useDeleteApiUserId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiUserId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof deleteApiUserId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteApiUserIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiUserSearch = (
  params?: MaybeRef<GetApiUserSearchParams>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return accountsAxiosInstance<void>({ url: `/api/User/search`, method: 'GET', params: unref(params), signal }, options)
}

export const getGetApiUserSearchQueryKey = (params?: MaybeRef<GetApiUserSearchParams>) => {
  return ['api', 'User', 'search', ...(params ? [params] : [])] as const
}

export const getGetApiUserSearchQueryOptions = <TData = Awaited<ReturnType<typeof getApiUserSearch>>, TError = unknown>(
  params?: MaybeRef<GetApiUserSearchParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUserSearch>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUserSearchQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserSearch>>> = ({ signal }) =>
    getApiUserSearch(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiUserSearch>>,
    TError,
    TData
  >
}

export type GetApiUserSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserSearch>>>
export type GetApiUserSearchQueryError = unknown

export const useGetApiUserSearch = <TData = Awaited<ReturnType<typeof getApiUserSearch>>, TError = unknown>(
  params?: MaybeRef<GetApiUserSearchParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUserSearch>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUserSearchQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
