/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { GetApiTiktokV2ConnectParams, WebhookRequest } from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiTiktokV2Connect = (
  params?: MaybeRef<GetApiTiktokV2ConnectParams>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return accountsAxiosInstance<void>(
    { url: `/api/tiktok/v2/connect`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiTiktokV2ConnectQueryKey = (params?: MaybeRef<GetApiTiktokV2ConnectParams>) => {
  return ['api', 'tiktok', 'v2', 'connect', ...(params ? [params] : [])] as const
}

export const getGetApiTiktokV2ConnectQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTiktokV2Connect>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiTiktokV2ConnectParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTiktokV2Connect>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTiktokV2ConnectQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTiktokV2Connect>>> = ({ signal }) =>
    getApiTiktokV2Connect(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiTiktokV2Connect>>,
    TError,
    TData
  >
}

export type GetApiTiktokV2ConnectQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTiktokV2Connect>>>
export type GetApiTiktokV2ConnectQueryError = unknown

export const useGetApiTiktokV2Connect = <TData = Awaited<ReturnType<typeof getApiTiktokV2Connect>>, TError = unknown>(
  params?: MaybeRef<GetApiTiktokV2ConnectParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTiktokV2Connect>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTiktokV2ConnectQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiTiktokV2Callback = (
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  return accountsAxiosInstance<void>({ url: `/api/tiktok/v2/callback`, method: 'GET', signal }, options)
}

export const getGetApiTiktokV2CallbackQueryKey = () => {
  return ['api', 'tiktok', 'v2', 'callback'] as const
}

export const getGetApiTiktokV2CallbackQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTiktokV2Callback>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTiktokV2Callback>>, TError, TData>>
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTiktokV2CallbackQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTiktokV2Callback>>> = ({ signal }) =>
    getApiTiktokV2Callback(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiTiktokV2Callback>>,
    TError,
    TData
  >
}

export type GetApiTiktokV2CallbackQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTiktokV2Callback>>>
export type GetApiTiktokV2CallbackQueryError = unknown

export const useGetApiTiktokV2Callback = <
  TData = Awaited<ReturnType<typeof getApiTiktokV2Callback>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTiktokV2Callback>>, TError, TData>>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTiktokV2CallbackQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const postApiTiktokV2RevokeId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  id = unref(id)

  return accountsAxiosInstance<void>({ url: `/api/tiktok/v2/revoke/${id}`, method: 'POST' }, options)
}

export const getPostApiTiktokV2RevokeIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiTiktokV2RevokeId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiTiktokV2RevokeId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTiktokV2RevokeId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return postApiTiktokV2RevokeId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiTiktokV2RevokeIdMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTiktokV2RevokeId>>>

export type PostApiTiktokV2RevokeIdMutationError = unknown

export const usePostApiTiktokV2RevokeId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiTiktokV2RevokeId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof postApiTiktokV2RevokeId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getPostApiTiktokV2RevokeIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiTiktokV2AccountsId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return accountsAxiosInstance<void>({ url: `/api/tiktok/v2/accounts/${id}`, method: 'GET', signal }, options)
}

export const getGetApiTiktokV2AccountsIdQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'tiktok', 'v2', 'accounts', id] as const
}

export const getGetApiTiktokV2AccountsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTiktokV2AccountsId>>,
  TError = unknown
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTiktokV2AccountsId>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTiktokV2AccountsIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTiktokV2AccountsId>>> = ({ signal }) =>
    getApiTiktokV2AccountsId(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiTiktokV2AccountsId>>, TError, TData>
}

export type GetApiTiktokV2AccountsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTiktokV2AccountsId>>>
export type GetApiTiktokV2AccountsIdQueryError = unknown

export const useGetApiTiktokV2AccountsId = <
  TData = Awaited<ReturnType<typeof getApiTiktokV2AccountsId>>,
  TError = unknown
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTiktokV2AccountsId>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTiktokV2AccountsIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const postApiTiktokV2Webhook = (
  webhookRequest: MaybeRef<WebhookRequest>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  webhookRequest = unref(webhookRequest)

  return accountsAxiosInstance<void>(
    {
      url: `/api/tiktok/v2/webhook`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: webhookRequest,
    },
    options
  )
}

export const getPostApiTiktokV2WebhookMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTiktokV2Webhook>>,
    TError,
    { data: WebhookRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiTiktokV2Webhook>>,
  TError,
  { data: WebhookRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTiktokV2Webhook>>, { data: WebhookRequest }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postApiTiktokV2Webhook(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiTiktokV2WebhookMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTiktokV2Webhook>>>
export type PostApiTiktokV2WebhookMutationBody = WebhookRequest
export type PostApiTiktokV2WebhookMutationError = unknown

export const usePostApiTiktokV2Webhook = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTiktokV2Webhook>>,
    TError,
    { data: WebhookRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postApiTiktokV2Webhook>>,
  TError,
  { data: WebhookRequest },
  TContext
> => {
  const mutationOptions = getPostApiTiktokV2WebhookMutationOptions(options)

  return useMutation(mutationOptions)
}
