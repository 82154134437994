<script setup lang="ts">
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue';
import { expandHexColor, hexToHsb, hexToRgb, hsbToHex, rgbToHex } from '@/components/colors/helpers';
import { Button } from '@/components/ui/button';
import { useTheme } from '@/Hooks/useTheme';
import { cn } from '@/lib/utils';
import { useClipboard } from '@vueuse/core';
import { clamp, throttle } from 'lodash-es';
import { LucideCopy, LucideCopyCheck } from 'lucide-vue-next';
import tinycolor from 'tinycolor2';
import { z } from 'zod';

interface Props {
  recentlyUsedColors?: readonly string[];
  recommendedColors?: readonly string[];
}

const colorCodes = ['hex', 'rgb'] as const;

defineProps<Props>();

const hexColor = defineModel<string>('hex', { required: true });

const { theme } = useTheme();

const emit = defineEmits<{
  (e: 'commit-value'): void;
  (e: 'isInteracting', value: boolean): void;
}>();

const hex = ref<string>(hexColor.value);
const rgb = ref<{r: number, g: number, b: number}>({ r: 0, g: 0, b: 0 });

const colorCode = ref<typeof colorCodes[number]>('hex');

const hue = ref<number>(0);
const saturation = ref<number>(0);
const brightness = ref<number>(0);

const invalidHexForm = ref(false);
const hexForm = z.object({
  hex: z.string().refine((value) => /^#?[0-9a-fA-F]{2,6}$/i.test(value), {
    message: 'Invalid hex code',
  }),
});

const invalidRgbForm = ref(false);
const rgbForm = z.object({
  r: z.number().int().min(0).max(255),
  g: z.number().int().min(0).max(255),
  b: z.number().int().min(0).max(255),
});

const isDragging = ref(false);

const grid = ref<HTMLElement | null>(null);
const updateSaturationBrightness = (event: MouseEvent | TouchEvent) => {

  if (!isDragging.value) {
    return;
  }

  if (grid.value instanceof HTMLElement) {
    const rect = grid.value.getBoundingClientRect();

    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;

    const x = clamp(clientX - rect.left, 0, rect.width);
    const y = clamp(clientY - rect.top, 0, rect.height);

    saturation.value = Math.round((x / rect.width) * 100);
    brightness.value = Math.round(((rect.height - y) / rect.height) * 100);

    updateColorFromHsb();
  }
};

function onMouseDown(event: MouseEvent | TouchEvent) {
  isDragging.value = true;
  document.getElementById('app')?.classList.add('pointer-events-none', 'select-none')
  window.addEventListener('mouseup', onMouseUp);
  window.addEventListener('touchend', onMouseUp);
  window.addEventListener('mousemove', updateSaturationBrightness);
  window.addEventListener('touchmove', updateSaturationBrightness);
  updateSaturationBrightness(event);
  emit('isInteracting', true);
}

function onMouseUp() {
  isDragging.value = false;
  document.getElementById('app')?.classList.remove('pointer-events-none', 'select-none')
  window.removeEventListener('mouseup', onMouseUp);
  window.removeEventListener('touchend', onMouseUp);
  window.removeEventListener('mousemove', updateSaturationBrightness);
  window.removeEventListener('touchmove', updateSaturationBrightness);
  emit('commit-value');
  emit('isInteracting', false);
}

function updateColorFromHex() {
  invalidHexForm.value = false;
  invalidRgbForm.value = false;
  
  const normalized = hex.value.toLowerCase();

  const validate = hexForm.safeParse({ hex: normalized });
  if (!validate.success) {
    invalidHexForm.value = true;
    return;
  }

  hex.value = expandHexColor(normalized);
  rgb.value = { ...hexToRgb(hex.value) };
  
  const hsb = hexToHsb(hex.value);
  hue.value = hsb.h;
  saturation.value = hsb.s;
  brightness.value = hsb.b;

  hexColor.value = hex.value;
}

function updateColorFromRgb() {
  invalidHexForm.value = false;
  invalidRgbForm.value = false;

  const validate = rgbForm.safeParse(rgb.value);
  if (!validate.success) {
    invalidRgbForm.value = true;
    return;
  }

  rgb.value = { r: validate.data.r, g: validate.data.g, b: validate.data.b };
  hex.value = rgbToHex(rgb.value.r, rgb.value.g, rgb.value.b);
  
  const hsb = hexToHsb(hex.value);
  hue.value = hsb.h;
  saturation.value = hsb.s;
  brightness.value = hsb.b;

  hexColor.value = hex.value;
}

function updateColorFromHsb() {
  hex.value = hsbToHex(hue.value, saturation.value, brightness.value);
  rgb.value = { ...hexToRgb(hex.value) };
  hexColor.value = hex.value;
}

function onClickColor(color: string) {
  hex.value = color;
  updateColorFromHex();
  emit('commit-value');
}

function pasteRgb(event: ClipboardEvent) {
  const value = event.clipboardData?.getData('text') ?? '';
  const match = value.match(/(\d{1,3})[^\d]+(\d{1,3})[^\d]+(\d{1,3})/)
  
  if (!match) {
    return;
  }

  event.preventDefault();
  
  const [_, r, g, b] = match.map(Number);

  rgb.value = { r, g, b };
  updateColorFromRgb();
}

const { copy, copied, isSupported } = useClipboard();

function copyToClipboard() {
  if (colorCode.value === 'hex') {
    copy(hex.value);
  } else if (colorCode.value === 'rgb') {
    copy(`rgb(${rgb.value.r}, ${rgb.value.g}, ${rgb.value.b})`);
  }
}

updateColorFromHex();
updateColorFromRgb();
updateColorFromHsb();

watch([hue, saturation, brightness], throttle(([h, s, b]) => {
  hexColor.value = hsbToHex(h, s, b);
}, 100));

function toBorderColor(color: string, theme: 'dark' | 'light') {
  switch (theme) {
    case 'dark': return tinycolor(color).lighten(20).toString();
    case 'light': return tinycolor(color).darken(20).toString();
  }
}

const borderColorOf = computed(() => {
  return (color: string) => {
    return toBorderColor(color, theme.value);
  }
});
</script>

<template>
  <div class="w-full pb-[100%] h-0 relative">
    <div
      ref="grid"
      class="absolute inset-0 rounded-lg overflow-hidden sb-grid border border-input select-none"
      @mousedown="onMouseDown"
      @touchstart="onMouseDown"
      :style="{ backgroundColor: `hsl(${hue}, 100%, 50%)` }"
    />
    <div
      class="absolute w-6 h-6 shadow border-2 border-white rounded-full pointer-events-none -translate-x-1/2 -translate-y-1/2 select-none"
      :style="{ left: saturation + '%', top: 100 - brightness + '%', backgroundColor: hexColor }"
    />
  </div>

  <div class="relative h-6 flex items-center mt-4" :data-hue="hue">
    <div class="absolute inset-0 h-6 w-full rounded-md hue-slider select-none">
      <div
        class="absolute shadow border-2 border-white rounded-md w-3 h-6 box-content -translate-x-1/2 -translate-y-1/2 top-1/2"
        :style="{ left: ((hue / 359) * 100) + '%', background: `hsl(${hue}, 100%, 50%)` }"
      />
    </div>
  
    <input
      type="range"
      :min="0"
      :max="359"
      v-model="hue"
      @change="emit('commit-value')"
      class="opacity-0 w-full h-6 cursor-pointer select-none"
    />
  </div>

  <div class="flex gap-1 mt-3">
    <SelectDropdown
      v-model="colorCode"
      :options="colorCodes.map((code) => ({ label: code.toUpperCase(), value: code }))"
      class="!min-w-min h-auto text-sm md:text-xs font-normal px-3 py-2 md:py-1 md:px-2"
    />

    <template v-if="colorCode === 'hex'">
      <form @submit.prevent="updateColorFromHex" class="w-full">
        <input
          v-model="hex"
          name="hex"
          type="text"
          placeholder="a000fe"
          @blur.prevent="updateColorFromHex"
          :class="cn('w-full border border-input bg-background rounded-md px-3 py-2 md:py-1 md:px-2 text-sm md:text-xs font-normal h-auto', {
            'border-error': invalidHexForm
          })"
        />
      </form>
    </template>

    <template v-else-if="colorCode === 'rgb'">
      <div class="grid grid-cols-3 gap-1 w-full">
        <input
          v-model.number="rgb.r"
          type="number"
          min="0"
          max="256"
          placeholder="r"
          @input="updateColorFromRgb"
          @paste="pasteRgb"
          :class="cn('w-full border border-input bg-background rounded-md px-3 py-2 md:py-1 md:px-2 text-sm md:text-xs font-normal h-auto', {
            'border-error': invalidRgbForm
          })"
        />
        <input
          v-model.number="rgb.g"
          type="number"
          min="0"
          max="256"
          placeholder="g"
          @input="updateColorFromRgb"
          @paste="pasteRgb"
          :class="cn('w-full border border-input bg-background rounded-md px-3 py-2 md:py-1 md:px-2 text-sm md:text-xs font-normal h-auto', {
            'border-error': invalidRgbForm
          })"
        />
        <input
          v-model.number="rgb.b"
          type="number"
          min="0"
          max="256"
          placeholder="b"
          @input="updateColorFromRgb"
          @paste="pasteRgb"
          :class="cn('w-full border border-input bg-background rounded-md px-3 py-2 md:py-1 md:px-2 text-sm md:text-xs font-normal h-auto', {
            'border-error': invalidRgbForm
          })"
        />
      </div>
    </template>

    <Button
      v-if="isSupported"
      @click="copyToClipboard"
      variant="ghost"
      size="sm"
      class="p-1 lowercase text-xs font-normal h-auto"
    >
      <LucideCopy v-if="!copied" class="w-5 h-5 md:w-4 md:h-4" />
      <LucideCopyCheck v-else class="w-5 h-5 md:w-4 md:h-4" />
    </Button>
  </div>

  <div v-if="recentlyUsedColors?.length || recommendedColors?.length" class="mt-2"></div>

  <template v-if="recentlyUsedColors?.length">
    <p class="text-base md:text-sm font-normal text-muted-foreground mb-1">Recently used</p>
    <div class="grid grid-cols-8 gap-1">
      <template v-for="color in recentlyUsedColors" :key="color">
        <div
          :style="{ backgroundColor: color, borderColor: borderColorOf(color) }"
          class="grid place-items-center w-full aspect-square rounded cursor-pointer border-2 shadow-sm border-transparent"
          @click="onClickColor(color)"
        >
          <IconCheck
            v-if="hexColor.toLowerCase() === color.toLowerCase()"
            :class="cn('size-6 md:size-4 text-white', { 'text-black': !tinycolor.isReadable('#ffffff', color) })"
          />
      </div>
      </template>
    </div>

    <hr v-if="recommendedColors?.length" class="my-2" />
  </template>
  
  <template v-if="recommendedColors?.length">
    <p class="text-base md:text-sm font-normal text-muted-foreground mb-1">Recommended</p>
    <div class="grid grid-cols-8 gap-1">
      <template v-for="color in recommendedColors" :key="color">
        <div
          :style="{ backgroundColor: color, borderColor: borderColorOf(color) }"
          class="grid place-items-center w-full aspect-square rounded cursor-pointer border-2 shadow-sm border-transparent"
          @click="onClickColor(color)"
        >
          <IconCheck
            v-if="hexColor.toLowerCase() === color.toLowerCase()"
            :class="cn('size-6 md:size-4 text-white', { 'text-black': !tinycolor.isReadable('#ffffff', color) })"
          />
      </div>
      </template>
    </div>
  </template>
</template>

<style scoped>
.sb-grid {
  cursor: crosshair;
  background-image:
    linear-gradient(0deg, hsl(0, 0%, 0%), transparent),
    linear-gradient(90deg, hsl(0, 0%, 100%), transparent);
}

.hue-slider {
  background: linear-gradient(to right, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000)
}
</style>
