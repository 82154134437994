import Gleap from 'gleap';
import { type App, nextTick } from 'vue'
import { getIdentity } from '@/logging/identyService';
import type { LoggingAdapter } from '@/logging/index';
import { tiers } from '@/enums/tiers'

const enabled = Boolean(Number(import.meta.env.VITE_GLEAP_ENABLED));

export const GleapPlugin: LoggingAdapter<never> & { instance: null } = {

  instance: null, // Invalid initialization errors should be thrown

  async initialize(app: App) {
    console.log('[@gleap] initializing');
    if (enabled || import.meta.env.PROD) {
      if (!import.meta.env.VITE_GLEAP_SDK_KEY) {
        throw new Error('Gleap SDK key is missing');
      }
      Gleap.initialize(import.meta.env.VITE_GLEAP_SDK_KEY);
      this.instance = Gleap.getInstance();
      app.config.globalProperties.$gleap = this.instance;
    }
  },

  trackEvent(eventName, properties) {
    if (this.instance) {
      Gleap.trackEvent(eventName, properties);
    } else {
      console.log('[@gleap/trackEvent]', eventName, properties);
    }
  },

  async pageView(to, from, failure) {
    if (this.instance) {
      if (!failure) {
        await nextTick();
        Gleap.trackEvent('$pageview', {
          page_title: to.name?.toString(),
          page_path: to.path,
        });
      }
    } else {
      console.log('[@gleap/pageView]', to.fullPath);
    }
  },

  reset() {
    if (this.instance) {
      this.instance.destroy();
      this.instance = null;
    } else {
      console.log('[@gleap/reset]');
    }
  },

  async registerUser(user) {

    if (!user || !this.instance) {
      return;
    }

    const gleapIdentity = Gleap.getIdentity();
    const isIdentified = Gleap.isUserIdentified();

    if (gleapIdentity.userId !== user.id) {
      Gleap.clearIdentity();
    }

    if (!isIdentified) {

      const userData = getIdentity(user);

      Gleap.identify(user.id, {
        name: userData.name,
        email: userData.email,
        plan: tiers.toString(userData.tier),
      });

      console.log('[@gleap/identify]', {
        userId: user.id,
        name: userData.name,
        email: userData.email,
        plan: tiers.toString(userData.tier),
      });
    } else {
      console.log('[@gleap/identified]', gleapIdentity);
    }
  },

  identifyUser() {
    // Not implemented, but is called
    return;
  },
};
