<script lang="ts" setup>
import { type Component, watch } from 'vue'
import { computed, onMounted, ref } from 'vue'
import { posthog, type Survey, type SurveyQuestion } from 'posthog-js'
import { Button } from '@/components/ui/button'
import MultipleChoiceQuestion from '@/components/Onboarding/surveyTypes/MultipleChoiceQuestion.vue'
import SingleChoiceQuestion from '@/components/Onboarding/surveyTypes/SingleChoiceQuestion.vue'
import TextQuestion from '@/components/Onboarding/surveyTypes/TextQuestion.vue'
import { Dialog, DialogContent, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import SurveyWizard from '@/components/Onboarding/SurveyWizard.vue'
import AccountSetup from '@/components/Onboarding/AccountSetup.vue'
import UpgradeView from '@/components/Onboarding/surveyTypes/UpgradeView.vue'
import { useConfirmDialog } from '@vueuse/core'
import { AlertDialogTitle, AlertDialog, AlertDialogDescription, AlertDialogFooter, AlertDialogContent } from '@/components/ui/alert-dialog'
import { useTheme } from '@/Hooks/useTheme'

const emit = defineEmits<{
  (event: 'surveyEnd'): void
}>()

const surveyId = '019276d6-3492-0000-f2aa-a9f8596f921c'
const survey = ref<Survey | null>(null)

const questionIndex = ref(0)
const amountOfQuestions = computed(() => survey.value?.questions.length
  ? survey.value.questions.length + 2
  : 0)

const surveyShown = () => {
  posthog.capture('survey shown', {
    $survey_id: surveyId,
  })
}

onMounted(() => {
  posthog.getSurveys((allSurveys) => {
    const onboardingSurvey = allSurveys.find((survey) => survey.id === surveyId)
    if (onboardingSurvey) {
      survey.value = onboardingSurvey
      surveyShown()
    } else {
      emit('surveyEnd')
    }
  })
})

const componentOf = (question: SurveyQuestion): Component => {
  switch (question.type) {
    case 'multiple_choice':
      return MultipleChoiceQuestion
    case 'single_choice':
      return SingleChoiceQuestion
    case 'open':
      return TextQuestion
    default:
      return TextQuestion
  }
}

const skipNextQuestion = ref(false)
const surveyResponses = ref<{ [key: string]: string | string[] }>({})

const answered = (value: string | string[], index: number) => {
  
  console.log(`User answered '${survey.value?.questions[index].question}' with '${value}'`);
  
  if (!value || value.length === 0) {
    canGoForward.value = false
    return
  }

  canGoForward.value = true

  if (index === 0) {
    surveyResponses.value = {
      ...surveyResponses.value,
      ['$survey_response']: value,
    }
  } else {
    surveyResponses.value = {
      ...surveyResponses.value,
      [`$survey_response_${index}`]: value,
    }
  }

  // Skip optional question if 'Other' option is not selected.
  if (survey.value?.questions[index + 1]?.optional && !value.includes('Other')) {
    skipNextQuestion.value = true
  } else {
    skipNextQuestion.value = false
  }
}

const submitSurvey = () => {
  posthog.capture('survey sent', {
    $survey_id: surveyId,
    ...surveyResponses.value,
  })
}

const canGoForward = ref(false)

const isOpen = defineModel<boolean>('open', { required: true })
watch(isOpen, (open) => {
  if (!open) {
    emit('surveyEnd')
  }
})

const images = [
  '/images/onboarding/question-01.png',
  '/images/onboarding/question-02.png',
  '/images/onboarding/question-03.png',
  '/images/onboarding/question-04.png',
  '/images/onboarding/question-05.png',
  '/images/onboarding/setup-accounts.png',
]

const imageSource = computed(() => images[questionIndex.value])
const question = computed(() => {
  if (!survey.value) {
    return null
  } else if (questionIndex.value < survey.value.questions.length) {
    const question = survey.value.questions[questionIndex.value]
    return {
      ...question,
      component: componentOf(question),
    }
  } else if (questionIndex.value === survey.value.questions.length) {
    return {
      type: 'setup',
      question: 'Setup your accounts',
      description: 'Almost there!',
      descriptionContentType: 'text',
      component: AccountSetup,
      buttonText: 'Continue',
    }
  } else {
    return null
  }
})

const { reveal, confirm, cancel, isRevealed } = useConfirmDialog()
const guardedIsOpen = computed({
  get() {
    return isOpen.value
  },
  async set(open) {
    if (open === false && questionIndex.value < amountOfQuestions.value - 1) {
      const { isCanceled } = await reveal()
      if (!isCanceled) {
        isOpen.value = false
      }
    } else {
      isOpen.value = open
    }
  }
})

const { theme } = useTheme()
</script>

<template>
  <Dialog v-model:open="guardedIsOpen">
    <DialogContent
      class="grid p-0 lg:max-w-[1300px] lg:min-h-[min(768px,_calc(100dvh-16rem))]" :class="theme"
    >
      <Transition
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        enter-from-class="opacity-0 translate-y-6"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
        leave-to-class="opacity-0 -translate-y-6"
        mode="out-in"
      >
        <div class="grid" :key="questionIndex === amountOfQuestions - 1 ? 'upgrade' : 'survey'">
          <SurveyWizard
            v-if="questionIndex < amountOfQuestions - 1"
            v-model:index="questionIndex"
            :src="imageSource"
            :steps="amountOfQuestions"
            @close="guardedIsOpen = false"
            :can-go-backward="questionIndex > 0" @backward="questionIndex -= 1"
            :can-go-forward="true" @forward="() => {
              if (questionIndex === survey!.questions.length - 1) {
                submitSurvey()
              }
              questionIndex += 1
            }"
            :buttonText="question.buttonText"
          >
            <header>
              <DialogDescription as="h3" v-if="question.description && question.type !== 'open'" class="font-light text-base">
                <span v-if="question.descriptionContentType === 'html'" v-html="question.description"/>
                <span v-else v-text="question.description"/>
              </DialogDescription>
              <DialogDescription v-else class="sr-only">
                Please answer the following question
              </DialogDescription>
              <DialogTitle as="h2" v-if="question" class="text-lg md:text-2xl lg:text-4xl font-semibold">
                {{ question.question }}
              </DialogTitle>
            </header>
            <component
              :is="question.component" :question="question" :key="question"
              @answered="(value: string) => answered(value, questionIndex)"
              @invalidAnswer="canGoForward = false"
            />
          </SurveyWizard>
          <UpgradeView v-else @close="guardedIsOpen = false" />
        </div>
      </Transition>

      <AlertDialog v-model:open="isRevealed">
        <AlertDialogContent>
          <AlertDialogTitle class="text-lg font-bold">Skip onboarding</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you wish to skip onboarding? We help you setup your account at the end.
          </AlertDialogDescription>
          <AlertDialogFooter class="gap-2">
            <Button variant="outline" @click="cancel">Cancel</Button>
            <Button variant="destructive" @click="confirm">Confirm</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped></style>
