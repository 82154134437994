<template>
  <button
    class="layout-block flex flex-col items-start transition hover:border-company-secondary-200"
    :class="{ selected: selected }"
    @click="$emit('click')"
  >
    <div class="layout-data flex w-full items-center justify-between">
      <span class="title">{{ name.replace('Ui', 'UI') }}</span>
      <DynamicPlanButtonWithTooltip feature="layouts" v-if="isPremium" />
      <span v-else-if="tier === 0" class="free-badge">Free</span>
    </div>

    <div class="layout-link" :class="cssClass">
      <div class="layout-frame">
        <div class="blurred-bg"></div>
        <div class="facecam">
          <img src="/images/images/streamer.png" />
        </div>
        <div class="gameui">
          <img class="game-ui" src="/images/svgs/game-ui.svg" />
        </div>

        <svg class="crop-square" viewBox="0 0 205 218" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="1.19434"
            y="1.09082"
            width="203.197"
            height="215.787"
            rx="11.5"
            fill="#CCF3FC"
            stroke="#9951FE"
            stroke-dasharray="4 4"
          />
          <image
            preserveAspectRatio="xMidYMid slice"
            x="1.19434"
            y="1.09082"
            width="203.197"
            height="215.787"
            opacity=".7"
            href="/images/images/shooter.png"
          />
          <rect x="1.19482" y="210.498" width="6.37901" height="6.37901" fill="white" stroke="#9951FE" />
          <rect x="1.19482" y="1.52124" width="6.37901" height="6.37901" fill="white" stroke="#9951FE" />
          <rect x="198.013" y="210.498" width="6.37901" height="6.37901" fill="white" stroke="#9951FE" />
          <rect x="198.013" y="1.52124" width="6.37901" height="6.37901" fill="white" stroke="#9951FE" />
        </svg>
        <svg class="crop-full" viewBox="0 0 205 346" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="0.539062"
            y="1.2146"
            width="203.197"
            height="344.206"
            rx="11.5"
            fill="#CCF3FC"
            stroke="#9951FE"
            stroke-dasharray="4 4"
          />
          <image
            preserveAspectRatio="xMidYMid slice"
            x="0.539062"
            y="1.2146"
            width="203.197"
            height="344.206"
            rx="11.5"
            opacity=".7"
            href="/images/images/shooter.png"
          />
          <path
            d="M6.91857 345.238C3.51793 344.994 0.801714 342.278 0.557444 338.877H6.91857V345.238Z"
            fill="white"
            stroke="#9951FE"
          />
          <path
            d="M197.357 338.877H203.719C203.474 342.278 200.758 344.994 197.357 345.238V338.877Z"
            fill="white"
            stroke="#9951FE"
          />
          <path
            d="M0.557444 7.59361C0.801714 4.19298 3.51793 1.47676 6.91857 1.23249V7.59361H0.557444Z"
            fill="white"
            stroke="#9951FE"
          />
          <path
            d="M197.357 1.23249C200.758 1.47676 203.474 4.19298 203.719 7.59361H197.357V1.23249Z"
            fill="white"
            stroke="#9951FE"
          />
        </svg>
      </div>
      <!-- <video v-if="video" :poster="img" :src="video" class="mx-auto d-block w-100 mw-100"  playsinline autoplay muted loop disableRemotePlayback disablePictureInPicture /> -->
      <!-- <img v-else :src="img" alt="preview image" class="mx-auto d-block mw-100 w-100" /> -->
    </div>
  </button>
</template>

<script>
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import { useUserInfoStore } from '@/store/user/userInfo'

export default {
  components: {
    DynamicPlanButtonWithTooltip,
  },
  props: {
    name: {
      type: String,
    },
    cssClass: {
      type: String,
    },
    isPremium: {
      type: Boolean,
      default: false,
    },
    toRoute: {
      type: Object,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userInfo = useUserInfoStore()
    return {
      tier: userInfo.tier,
    }
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:color';

.layout-block {
  cursor: pointer;
  background-color: white;
  border-radius: 15px;
  border: 2px solid #f9f6fe;

  .layout-data {
    padding: 0.7em 0.7em 0.7em 1em;

    .title {
      font-size: 15px;
      font-weight: 600;
    }

    .free-badge {
      border-radius: 20px;
      padding: 0.4em 0.7em;
      font-weight: 500;
      font-size: 12px;
      border-color: none;
      background: rgb(39, 212, 77, 0.1);
      color: #27d54d;
    }
  }

  .layout-link {
    width: 100%;
    aspect-ratio: 6.1 / 10;
    padding: 0.3em;

    .layout-frame {
      border: 1px solid rgba(136, 51, 255, 0.25);
      border-radius: 15px;
      background-color: rgb(204, 243, 252, 0.5);
      width: 100%;
      height: 100%;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;
      justify-content: flex-end;

      .blurred-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        filter: blur(2px);
        background-position-x: 50%;
        background-size: cover;
        background-image: url('/images/images/shooter.png');
      }

      .game-ui {
        width: 100%;
        position: absolute;
      }

      .crop-full {
        display: none;
      }

      .facecam {
        position: absolute;
        background-color: #33cff2;
        width: 50%;
        border-radius: 5px;
        overflow: hidden;
        z-index: 9;

        > img {
          width: 100%;
          padding: 0.5em 0.5em 0em 0.5em;
        }
      }

      .gameui {
        display: none;
      }
    }

    &.split {
      .layout-frame {
      }

      .facecam {
        height: 37%;
        left: 0;
        top: 0;
        width: 100%;

        display: flex;
        justify-content: center;
        border-radius: 0;

        > img {
          width: auto;
          height: 100%;
        }
      }
    }

    &.square {
      aspect-ratio: 1 /1.05;

      .facecam {
        top: 0;
        left: 25%;
      }
    }

    &.small-facecam,
    &.circle-facecam,
    &.triple-crop {
      > div {
        justify-content: center;
      }

      .facecam {
        position: absolute;
        top: 5%;
        left: 25%;
      }
    }

    &.circle-facecam {
      .facecam {
        border-radius: 50%;
        aspect-ratio: 1;
        overflow: hidden;

        > img {
          bottom: 0;
          position: absolute;
          padding: 0;
        }
      }
    }

    &.triple-crop {
      .layout-frame {
        .gameui {
          display: block;
          position: absolute;
          bottom: 15%;
          width: 75%;
          left: 12%;
        }
      }
    }

    &.blurred {
      > div {
        justify-content: center;
      }

      .facecam {
        display: none;
      }
    }

    &.fullscreen {
      .crop-full {
        display: block;
      }

      .crop-square {
        display: none;
      }

      svg {
        height: 100%;
      }

      .facecam {
        display: none;
      }
    }
  }

  &.selected {
    background-color: #8833ff;
    color: white;
    border-color: #6523c2;

    .layout-data {
      .free-badge {
        background-color: color.adjust(#8833ff, $lightness: 10%);
        color: white;
      }
    }
  }
}
</style>
