<script setup lang="ts">
import { type CaptionStyle, captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { captionStyleToCaptionPreset } from '@/components/Captions/v3/captionStyleToCaptionPreset'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import type { CaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import CaptionV2StyleSelector from '@/areas/editor/views/captions/v2/CaptionV2StyleSelector.vue'
import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'

const captionsStore = useCaptionsStore();
const historyStore = useHistoryStore();

const onSelect = async (style: CaptionStyle) => {

  const preset = captionStyleToCaptionPreset(style) as CaptionPreset;
  const captionStyleDefinition = captionStylesSettings[style] as CaptionStyleDefinition

  if (captionsStore.entities.length === 0) {
    await captionsStore.generateCaptions(preset, captionStyleDefinition.highlightColor);
  }

  captionsStore.baseCaptionPreset = preset;
  captionsStore.baseOptions.highlightColor = captionStyleDefinition.highlightColor;
};
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />
  <CaptionV2StyleSelector @select="style => historyStore.transaction('CAPTIONS:GENERATE', () => onSelect(style))" />
</template>

<style scoped lang="scss">

</style>
