<script lang="ts" setup>
import { onMounted, computed, inject, type ComputedRef } from 'vue'
import type { Ref } from 'vue'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import type { Area } from '@/modules/SLMovable/@types/Movable'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import type { Segment } from '@/areas/editor/@type/Project'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'

const props = defineProps<{
  id: string
  enableSnapping: boolean
}>()

const emit = defineEmits(['focus'])

const cropsStore = useCropsStore()
const crop = cropsStore.selectById(props.id)
const videoStore = useVideoStore()

onMounted(() => {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => emit('focus'), 0)
})

const aspectLock = computed(() => {

  if (!videoStore.videoElement) {
    return null
  }

  const sourceSize = { width: crop.width, height: crop.height }
  const { videoWidth, videoHeight } = videoStore.videoElement
  return {
    width: sourceSize.width * videoWidth,
    height: sourceSize.height * videoHeight,
  }
})

const editorFocusStore = useEditorFocusStore()
const focused = computed(() => {
  return editorFocusStore.focus && editorFocusStore.focus.type === 'crop' && editorFocusStore.focus.key === props.id
})

function setFocus() {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => editorFocusStore.setFocus('crop', props.id), 0)
}

const mouseover = inject<Ref<string | null>>('mouseover')!
const historyStore = useHistoryStore()

const area = computed({
  get: () => ({
    x: crop.feedData.x,
    y: crop.feedData.y,
    width: crop.feedData.width,
    height: crop.feedData.height,
  }),
  set: (area: Area) => {
    cropsStore.state[props.id].feedData.x = area.x
    cropsStore.state[props.id].feedData.y = area.y
    cropsStore.state[props.id].feedData.width = area.width
    cropsStore.state[props.id].feedData.height = area.height
  }
})

const isMobile = useIsMobile()
const { isLayoutsStep } = useEditorStep()
const disabled = computed(() => {
  return isMobile.value && !isLayoutsStep.value
})

const layoutsStore = useLayoutsStore()
const layout = computed(() => layoutsStore.selectById(crop.layoutId))

const isSplitLayout = computed(() => {
  return layout.value?.presetId === 'split' || layout.value?.presetId === 'basecam'
})

const segmentsStore = useSegmentsStore()
const zoomSegments = segmentsStore.whereIsZoom() as ComputedRef<Segment[]>

const isCurrentSegmentZoom = computed(() => {
  return zoomSegments.value.some((segment: Segment) => {
    return videoStore.currentTimeMs >= segment.startMs && videoStore.currentTimeMs <= segment.endMs
  })
});

function onMouseDown() {

  setFocus()

  const wasPlaying = videoStore.playing
  videoStore.playing = false

  function onMouseUp() {
    videoStore.playing = wasPlaying
  }

  window.addEventListener('mouseup', onMouseUp, { once: true })
}
</script>

<template>
  <div
    :class="crop.input.shape === 'circle' ? 'rounded-[100%]' : ''"
    :style="{
      left: area.x * 100 + '%',
      top: area.y * 100 + '%',
      width: area.width * 100 + '%',
      height: area.height * 100 + '%',
    }"
    class="absolute transition-opacity group"
    @click.stop
    @mousedown.stop="onMouseDown"
    @mouseover="mouseover = crop.id"
    @mouseleave="mouseover = null"
  >
    <MovableElement
      :id="crop.id + '-feed'"
      v-model="area"
      :move="!disabled && !isSplitLayout && !isCurrentSegmentZoom"
      :resize="!disabled && !isSplitLayout && !isCurrentSegmentZoom"
      @resize-end="historyStore.transaction('FEED:RESIZE')"
      @move-end="historyStore.transaction('FEED:MOVE')"
      :shape="crop.input.shape === 'circle' ? 'circle' : 'rectangle'"
      :aspectLock="aspectLock"
      :bounds="{ top: 0, right: 1, bottom: 1, left: 0 }"
      :disable-arrow-keys="!focused"
      :snap="enableSnapping"
      :min-size="6"
      :resize-handle-class="focused ? 'z-[250]' : ''"
      :handles="['nw', 'ne', 'se', 'sw']"
    >
      <template #move>
        <div
          class="absolute -inset-px border-2 transition-[opacity] border-current box-content"
          :class="{
            'rounded-full': crop.input.shape === 'circle',
            'opacity-100': focused || mouseover === crop.id,
            'opacity-0 group-hover:opacity-75': !focused,
          }"
          :style="{ color: crop.input.color }"
        />
      </template>
      <template #resize-direction>
        <div
          :style="{ backgroundColor: focused ? crop.input.color : 'transparent' }"
          class="h-3 w-3 transition-[background-color]"
        />
      </template>
    </MovableElement>
  </div>
</template>

<style scoped lang="scss"></style>
