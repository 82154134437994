import { useColorMode } from '@vueuse/core'

const { store, state } = useColorMode({ selector: null })

const colorMode = computed({
  get() {
    return store.value
  },
  set(value) {
    store.value = value
  }
});

const theme = computed({
  get() {
    return state.value;
  },
  set(value) {
    store.value = value
  }
});

const themes = ['light', 'dark'] as const
const colorModes = ['auto', 'light', 'dark'] as const

export function useTheme() {
  return { colorMode, theme, colorModes, themes }
}
