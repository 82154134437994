import { retryAsync } from '@/helpers/retry';

export async function fetchWithRetry(url: string, options: RequestInit = {}) {
  return await retryAsync(async () => {
    const result = await fetch(url, options);
    if (!result.ok) {
      console.table(result)
      console.table([...result.headers]);
      throw new Error(`Error fetching ${url}: ` + result.statusText);
    }
    return result;

  }, { maxRetries: 6 })
}