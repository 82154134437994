<script setup lang="ts">
import TwoStepsTierCard from '@/components/Dialog/Gold/TwoStepsTierCard.vue';
import CrossIcon from '@/components/Icons/CrossIcon.vue';
import type { UpgradeDialogPayload } from '@/helpers/upgradeDialog';
import type { Tier } from '@/data/plans';
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals';
import { useUserInfoStore } from '@/store/user/userInfo';
import { useDiscountCode } from '@/Hooks/useDiscountCode';
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog';
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade';
import { ref } from 'vue';
import { posthog } from 'posthog-js';
import BlackFridayTierCards from "@/components/Dialog/Gold/BlackFriday/BlackFridayTierCards.vue";
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue';

const props = defineProps<{
  isLoading: string | null
  close: (_payload: UpgradeDialogPayload) => void
  handleClose: (payload: UpgradeDialogPayload) => void
  payload: UpgradeDialogPayload
  error: string | null
  reason: string
  tiers: Tier[]
  title?: string
  subtitle?: string
}>();

const { interval, intervals } = useSubscriptionIntervals()
</script>

<template>
  <div class="bg-[#070033] modal-box max-h-screen xl:max-h-[95vh] w-full max-w-full gap-12 rounded-none p-0 md:rounded-xl min-[1175px]:max-w-[1175px] flex overflow-hidden">
    <div class="flex flex-col w-full max-h-full overflow-y-auto">
      <div class="relative">
        <div class="absolute inset-0">
          <img class="absolute top-0 left-0 max-w-[50%]" src="/black-friday/dialog/tl.png" alt="Black Friday Background Image" />
          <img class="absolute bottom-0 left-0 max-w-[50%]" src="/black-friday/dialog/bl.png" alt="Black Friday Background Image" />
          <img class="absolute bottom-0 right-0 max-w-[50%]" src="/black-friday/dialog/br.png" alt="Black Friday Background Image" />
        </div>
        
        <div class="relative flex flex-col w-full py-6">
          <div
            class="absolute right-2 top-2 z-10 cursor-pointer p-2"
            @click="() => close(payload)"
          >
            <cross-icon class="h-6 w-6 fill-current" />
          </div>
          <header class="text-center self-center text-white">
            <img class="w-72 h-auto -mb-12 mx-auto" src="/black-friday/dialog/deal.png" alt="Black Friday Background Image" />
            <h2 class="text-[40px] leading-[1] text-center uppercase mt-4">
              <img class="w-24 h-auto inline-block -mb-3 -mr-2" src="/black-friday/dialog/tag.png" alt="Black Friday Background Image" />
              <span class="sr-only">Gold</span> Yearly Plan<br />
              <span class="text-[#FF2023] font-title font-black text-[40px] leading-[1]">
                Black Friday
              </span>
            </h2>
            <p class="font-normal">Hurry, this Black Friday deal ends on 2 december</p>
          </header>
          <LightSwitch
            class="bg-white text-sm mx-auto"
            :options="Object.values(intervals)"
            :class="isLoading && 'pointer-events-none'"
            background="bg-gradient"
            v-model:value="interval"
            labelClasses="text-white"
          />
    
          <BlackFridayTierCards :close="close" />
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
