<script setup lang="ts">
import BlackFridayCheckmarkIcon from '@/components/Dialog/Gold/BlackFriday/BlackFridayCheckmarkIcon.vue';
import { useUserInfoStore } from '@/store/user/userInfo';
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals';
import { Button } from '@/components/ui/button';
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade';
import { getDisplayPrice, useProductsStore } from '@/store/products';
import { computed, ref, reactive } from 'vue';
import { useDiscountCode } from '@/Hooks/useDiscountCode';
import plans, { type Tier } from '@/data/plans';
import { posthog } from 'posthog-js';
import { useConfirmDialog, useElementSize, useLocalStorage } from '@vueuse/core';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { useUserSubscriptionStore } from '@/store/user/userSubscription';
import LottieAnimation from '@/components/LottieAnimation.vue';
import { noop } from 'lodash-es';
import settings from '@/data/settings';
import { AlertDialog, AlertDialogFooter, AlertDialogContent, AlertDialogTitle, AlertDialogDescription } from '@/components/ui/alert-dialog';
import logging from '@/logging';

const props = withDefaults(defineProps<{ close?: () => void; }>(), { close: noop })

const { interval } = useSubscriptionIntervals()

const productsStore = useProductsStore()
const planData = computed(() => {

  if (productsStore.isLoadingPrices) {
    return null
  }
  
  const silver = productsStore.productData.silver.plans;
  const gold = productsStore.productData.gold.plans;

  return { silver, gold }
})

const currency = computed(() => {
  return productsStore.products?.find((p) => p.currency)?.currency
})

const displayPriceRegexp = new RegExp(/(?<currency>[^\d\s.,]+)?\s*(?<integer>\d+)(?<decimal_marker>[.,])?(?<fraction>\d{0,2})\s*(?<currency_after>[^\d\s.,]+)?/);
function toDisplayPrice(price: number, currency: string) {

  const displayPrice = getDisplayPrice(price, currency, 2);
  const match = displayPrice.match(displayPriceRegexp);

  if (!match) {
    return null;
  }

  return {
    currency: match.groups?.currency,
    integer: match.groups?.integer,
    decimal_marker: match.groups?.decimal_marker,
    fraction: match.groups?.fraction,
    currency_after: match.groups?.currency_after,
  }
}

const goldYearlyPriceBeforeDiscount = computed(() => {
  
  const goldPlans = planData.value?.gold;
  if (!goldPlans) {
    return null
  }
  
  const plan = goldPlans.find((p) => p.key === 'month')!;
  return plan.paddleData.totalPriceRaw
})

const goldYearlyPriceAfterDiscount = computed(() => {

  if (!goldYearlyPriceBeforeDiscount.value) {
    return null
  }

  if (interval.value === 'month') {
    return goldYearlyPriceBeforeDiscount.value
  } else {
    return 0.5 * goldYearlyPriceBeforeDiscount.value
  }
})

const goldDisplayPrice = computed(() => {
  if (!goldYearlyPriceAfterDiscount.value || !currency.value) {
    return null
  } else if (interval.value === 'month') {
    return toDisplayPrice(goldYearlyPriceAfterDiscount.value!, currency.value!)
  } else {
    return toDisplayPrice(goldYearlyPriceAfterDiscount.value!, currency.value!)
  }
})

const silverDisplayPrice = computed(() => {
  
  const silverPlans = planData.value?.silver;
  if (!silverPlans || !currency.value) {
    return null
  }
  
  if (interval.value === 'month') {
    const plan = silverPlans.find((p) => p.key === 'month')!;
    return toDisplayPrice(plan.paddleData.totalPriceRaw, currency.value)
  } else {
    const plan = silverPlans.find((p) => p.key === 'year')!;
    return toDisplayPrice(plan.paddleData.totalPriceRaw / 12, currency.value)
  }
})

const silverYearlyBillingDisplayPrice = computed(() => {
    
    const silverPlans = planData.value?.silver;
    if (!silverPlans || !currency.value) {
      return null
    }
    
    const plan = silverPlans.find((p) => p.key === 'year')!;
    return getDisplayPrice(plan.paddleData.totalPriceRaw, currency.value, 2)
})

const goldYearlyBillingDisplayPrice = computed(() => {
    
    if (!goldYearlyPriceAfterDiscount.value || !currency.value) {
      return null
    }
    
    return getDisplayPrice(goldYearlyPriceAfterDiscount.value * 12!, currency.value, 2)
})

const userInfoStore = useUserInfoStore()

const discountCode = useDiscountCode()

const { isLoading, upgrade } = usePlanUpgrade()

const isUpgrading = ref(false);

const manualUpgradeDialog = reactive(useConfirmDialog())
const instantBillingDialog = reactive(useConfirmDialog())

const upgradeTo = async (tier: Tier) => {

  if (isUpgrading.value || userInfoStore.tier >= tier.tier) return;

  isUpgrading.value = true;

  const tierNumber = tier.key === 'silver' ? 10 : 100;

  if (userInfoStore.tier === 10) {
    if (interval.value === 'year') {
      await manualUpgradeDialog.reveal()
      isUpgrading.value = false;
      return
    } else {
      const { isCanceled } = await instantBillingDialog.reveal()

      if (isCanceled) {
        isUpgrading.value = false;
        return
      }
    }
  }

  if (tierNumber > userInfoStore.tier) {
    const eventMeta = {
      tier: tier.key,
      monthlyOrYearly: interval.value,
      currentPlan: userInfoStore.plan,
      isCurrentlyInTrial: userInfoStore.trialStatus === 'active',
    }

    logging.trackEvent('SubscriptionUpgrade Clicked', eventMeta)
  }

  isUpgrading.value = false;

  const blackFridayDiscountCode = 'BLACKFRIDAY24';
  return upgrade(tier, 'Black Friday Upgrade Dialog', blackFridayDiscountCode, interval.value)
}

const isUpgradingManually = ref(false);
const isManualUpgradeSuccess = useLocalStorage('manual-upgrade-request-sent', false);
const manualUpgradeError = ref<string | null>(null);
const optedIn = ref(false);
async function postManualUpgradeRequest() {

  if (!optedIn.value) {
    return;
  }
  
  isUpgradingManually.value = true;
  
  
  try {
    const subscriptionStore = useUserSubscriptionStore()
    await subscriptionStore.fetchSubscriptionData()

    isManualUpgradeSuccess.value = true;
    const response = await fetch('https://relay-proxy.dev912.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userInfoStore.userId,
        userEmail: userInfoStore.email,
        currentSubscriptionPlan: subscriptionStore?.subscription?.subscriptionPlanId,
      }),
    })

    if (response.ok) {

      logging.trackEvent('Black Friday Manual Upgrade Request Sent', {
        userId: userInfoStore.userId,
        currentPlan: userInfoStore.plan,
      })

      isManualUpgradeSuccess.value = true;
    } else {
      throw new Error('Something went wrong while attempting to upgrade your subscription 😢 Please try again later.');
    }
  } catch (e) {
    manualUpgradeError.value = e instanceof Error 
      ? e.message 
      : 'Something went wrong while attempting to upgrade your subscription 😢 Please try again later.';
  } finally {
    isUpgradingManually.value = false;
  }
}

const manualUpgradeDialogIsOpenModel = computed({
  get() {
    return manualUpgradeDialog.isRevealed
  },
  set(isOpen: boolean) {
    if (!isOpen) {
      manualUpgradeDialog.cancel()
      if (isManualUpgradeSuccess.value) {
        isManualUpgradeSuccess.value = false;
        manualUpgradeError.value = null;
        optedIn.value = false;
        props.close()
      }
    }
  }
})

const content = ref<HTMLElement | null>(null)
const { height: contentHeight } = useElementSize(content)
</script>

<template>
  <div class="flex flex-col md:flex-row items-center md:items-stretch justify-center gap-6 my-8 text-black w-full">
    <!-- Silver Card -->
    <div
      class="rounded-3xl mt-6 border border-red-500 text-center shadow-sm bg-white max-w-xs w-full cursor-pointer hover:shadow-xl transition-all hover:scale-105 active:scale-90"
      @click.stop="upgradeTo(plans.silver)"
    >
      <div class="p-6 flex flex-col h-full">
        <h3 class="mt-2 text-3xl font-black font-title">Silver</h3>
        <p class="text-xs font-light text-black/75">Create clips that get views</p>

        <div class="flex flex-col items-center mx-auto min-w-32 my-4">
          <div class="flex justify-center w-full" v-if="silverDisplayPrice">
            <p class="text-lg leading-[1] mt-6 font-bold">{{ silverDisplayPrice.currency }}</p>
            <p class="text-5xl leading-[1] font-bold">{{ silverDisplayPrice.integer }}</p>
            <p class="text-lg leading-[1] mt-6 font-bold">{{ silverDisplayPrice.decimal_marker }}</p>
            <p class="text-lg leading-[1] font-light opacity-50">{{ silverDisplayPrice.fraction === '00' ? '-' : silverDisplayPrice.fraction }}</p>
            <p class="text-lg leading-[1] mt-6 font-bold">{{ silverDisplayPrice.currency_after }}</p>
            <p class="text-sm leading-[1] mt-6 font-light opacity-50">/ month</p>
          </div>
          
          <p class="text-sm text-black/75 font-light" v-if="interval === 'month'">Billed monthly</p>
          <p class="text-sm text-black/75 font-light" v-else>
            Billed yearly ({{ silverYearlyBillingDisplayPrice }})
          </p>

          <p class="mt-2 inline-block rounded-full bg-green-300 px-3 py-1 text-xs font-normal text-green-800" v-if="interval === 'year'">
            Save 20%
          </p>
        </div>

        <div class="h-px w-full bg-gray-200 my-4" />

        <p class="text-sm font-semibold w-full text-left">Everything in Free+</p>
        <ul class="mt-2 space-y-2 text-left text-sm font-light mb-auto">
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> Export in 1080p / 60fps</li>
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> Add stickers & text</li>
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> AI powered captions</li>
        </ul>

        <Button disabled v-if="userInfoStore.tier === 10" class="mt-6 w-full">
          This is your current plan
        </Button>
        <Button v-else class="mt-6 w-full" :disabled="isLoading === 'silver'">
          <template v-if="isLoading === 'silver'">
            Upgrading to Silver...
          </template>
          <template v-else>
            Go Silver
          </template>
        </Button>
      </div>
    </div>

    <!-- Gold Card -->
    <div 
      class="rounded-3xl border border-red-500 text-center shadow-sm bg-white max-w-xs w-full cursor-pointer hover:shadow-xl transition-all hover:scale-105 active:scale-90"
      @click.stop="upgradeTo(plans.gold)"
    >
      <p class="w-full rounded-t-3xl bg-red-500 px-2 py-1 text-xs font-medium text-white">
        BLACK FRIDAY DEAL ✨
      </p>
      <div class="p-6 flex flex-col">
        <h3 class="mt-2 text-3xl font-black font-title">Gold</h3>
        <p class="text-xs font-light text-black/75">Create clips that get views</p>

        <div class="flex flex-col items-center mx-auto min-w-32 my-4">
          
          <div class="flex items-end gap-3.5">
            <p class="-ml-8 line-through font-light text-sm" v-if="goldYearlyPriceBeforeDiscount && currency && goldYearlyPriceBeforeDiscount !== goldYearlyPriceAfterDiscount">
              {{ getDisplayPrice(goldYearlyPriceBeforeDiscount, currency, 2).split(/[.,]/)[0] }}
            </p>
  
            <div class="flex justify-center w-full" v-if="goldDisplayPrice">
  
              <p class="text-lg leading-[1] mt-6 font-bold">{{ goldDisplayPrice.currency }}</p>
              <p class="text-5xl leading-[1] font-bold">{{ goldDisplayPrice.integer }}</p>
              <p class="text-lg leading-[1] mt-6 font-bold">{{ goldDisplayPrice.decimal_marker }}</p>
              <p class="text-lg leading-[1] font-light opacity-50">{{ goldDisplayPrice.fraction === '00'
                ? '-'
                : goldDisplayPrice.fraction }}</p>
              <p class="text-lg leading-[1] mt-6 font-bold">{{ goldDisplayPrice.currency_after }}</p>
              <p class="text-sm leading-[1] mt-6 font-light opacity-50">/ month</p>
            </div>
          </div>


          <p class="text-sm text-black/75 font-light" v-if="interval === 'month'">Billed monthly</p>
          <p class="text-sm text-black/75 font-light" v-else>
            Billed yearly ({{ goldYearlyBillingDisplayPrice }})
          </p>

          <p class="mt-2 inline-block rounded-full bg-green-300 px-3 py-1 text-xs font-normal mx-auto text-green-800" v-if="interval === 'year'">
            Save 50%
          </p>
        </div>

        <div class="h-px w-full bg-gray-200 my-4" />
        
        <p class="text-sm font-semibold w-full text-left">Everything in Silver+</p>
        <ul class="mt-2 space-y-2 text-left text-sm font-light mb-auto">
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> Create montages</li>
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> Schedule clips directly to social media</li>
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> Zoom & Sound effects</li>
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> Ultra fast rendering</li>
          <li class="flex gap-2"><BlackFridayCheckmarkIcon /> Animated Giphy stickers</li>
        </ul>

        <Button disabled v-if="userInfoStore.tier === 100" class="mt-6 w-full">
          This is your current plan
        </Button>
        <Button v-else class="mt-6 w-full" :disabled="isLoading === 'gold'">
          <template v-if="isLoading === 'gold'">
            Upgrading to Gold...
          </template>
          <template v-else>
            Go Gold
          </template>
        </Button>
      </div>
    </div>
    
    <Dialog v-model:open="manualUpgradeDialogIsOpenModel">
      <DialogContent :z-index="1000" class="max-w-5xl p-0">
        <div class="grid grid-cols-1 grid-rows-[1fr,_auto] md:grid-cols-2 md:grid-rows-1">
          <div class="relative" :style="{ minHeight: contentHeight + 48 + 'px' }">
            <Transition appear
              enter-active-class="transition-[transform,_opacity] duration-300"
              leave-active-class="transition-[transform,_opacity] duration-300"
              enter-from-class="opacity-0 translate-x-8"
              enter-to-class="opacity-100 translate-x-0"
              leave-from-class="opacity-100 translate-x-0"
              leave-to-class="opacity-0 -translate-x-8"
            >
              <section :key="isManualUpgradeSuccess ? 'done' : 'opt-in'" class="absolute inset-0">
                <div class="flex flex-col min-h-max h-full p-6 gap-4 flex-1" v-if="!isManualUpgradeSuccess" ref="content">
                  <DialogHeader>
                    <DialogTitle class="font-title text-2xl font-bold">We’re Giving YOU the Black Friday Deal Too! 🎉</DialogTitle>
                    <DialogDescription class="mt-3 font-light text-base text-foreground">As one of our valued subscribers, you deserve the same incredible deal as new users—because you’re just as important to us. 💛</DialogDescription>
                  </DialogHeader>
          
                  <p class="font-light text-foreground">Since offering this discount to existing subscribers is technically tricky, we’ll handle the upgrade manually for you.</p>
                  <p class="font-light text-foreground">Here’s all you need to do:</p>
          
                  <label class="label cursor-pointer flex items-start gap-4 justify-start mb-auto">
                    <input v-model="optedIn" class="checkbox rounded-sm mt-1 h-5 w-5 dark:border-white" type="checkbox" />
                    <span class="label-text text-foreground">
                      Please upgrade me to the GOLD YEARLY subscription at the 50% Black Friday discount. I agree to be automatically
                      charged the price difference between my current plan and the discounted Gold Yearly subscription.
                    </span>
                  </label>
                  
                  <p class="text-red-500" v-if="manualUpgradeError">
                    Something went wrong while attempting to upgrade your subscription 😢 Please try again later.
                  </p>

                  <DialogFooter class="border-t pt-4">
                    <Button @click="postManualUpgradeRequest" :disabled="isUpgradingManually || !optedIn">
                      <template v-if="isUpgradingManually">
                        Upgrading...
                      </template>
                      <template v-else>
                        Upgrade now
                      </template>
                    </Button>
                  </DialogFooter>
                </div>
                <div class="flex flex-col min-h-max h-full p-6 gap-4 flex-1" v-else ref="content">

                  <Teleport to="body">
                    <LottieAnimation ref="confetti" :loop="false"
                      class="pointer-events-none absolute z-[99999] inset-0 !bg-transparent"
                      url="/lottie/full-confetti.json"
                    />
                  </Teleport>

                  <DialogHeader>
                    <DialogTitle class="font-title text-2xl font-bold">Thank You for Upgrading to GOLD! ✨</DialogTitle>
                    <DialogDescription class="mt-3 font-normal text-base text-foreground">
                      We’re thrilled to have you on our GOLD plan! Your upgrade will be processed manually, and you’ll 
                      receive a confirmation email from Paddle once it’s complete.
                    </DialogDescription>
                  </DialogHeader>
      
                  <p class="font-light text-foreground">
                    This process may take up to 24 hours, so we appreciate your patience. Once you’ve received the email, 
                    you may need to log out and back in to access your new plan features.
                  </p>

                  <p class="font-light text-foreground mb-auto">
                    If you have any questions, don’t hesitate to reach out on our <a :href="settings.discordInviteUrl" class="underline hover:no-underline text-indigo-500" target="_blank">Discord</a> 😊
                  </p>

                  <DialogFooter class="border-t pt-4">
                    <DialogClose as-child>
                      <Button>
                        Got it!
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </div>
              </section>
            </Transition>
          </div>
          <img class="h-full w-full object-cover aspect-square" src="/black-friday/popup.jpg" alt="50% off black friday deal" />
        </div>
      </DialogContent>
    </Dialog>

    <AlertDialog v-model:open="instantBillingDialog.isRevealed">
      <AlertDialogContent :z-index="1000">
        <AlertDialogTitle class="text-pretty">Upgrade to Gold now</AlertDialogTitle>
        <AlertDialogDescription>
          Warning: You will be charged immediately for the difference in price for the remainder of this month.
        </AlertDialogDescription>

        <AlertDialogFooter>
          <Button variant="ghost" @click="instantBillingDialog.cancel">Cancel</Button>
          <Button variant="primary" @click="instantBillingDialog.confirm">
            I understand, upgrade now
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </div>
</template>

<style scoped lang="scss">

</style>
