<template>
  <main class="flex h-full w-full flex-col gap-8 p-6 lg:p-12">
    <div class="flex flex-col gap-2">
      <div class="flex w-full flex-wrap justify-between items-center gap-4">
        <h1 class="text-3xl lg:text-5xl">Content Publisher</h1>
        <div class="flex gap-2 self-end">
          <Button
            v-if="failedPosts.length"
            variant="outline"
            class="font-semibold transition-all"
            :disabled="isRetryingAllFailedPosts"
            @click="revealRetryConfirmDialog">
            <span class="flex justify-center items-center gap-2 text-sm">
              <template v-if="isRetryingAllFailedPosts">
                Retrying failed posts..
                <Spinner class="w-4 h-4 animate-spin text-company-primary-50" />
              </template>
              <template v-else>
                Retry {{ failedPosts.length }} failed post(s)
                <IconSaxRefresh2 class="w-4 h-4" />
              </template>
            </span>
          </Button>
          <router-link :to="{ name: dashboardRouteNames.contentPublisher.render }">
            <GradientButton class="rounded-full !p-2 font-semibold lg:!px-6">
              <span class="hidden md:flex">Schedule post</span>
              <IconSaxAdd class="fill-current [&>path]:stroke-2" />
            </GradientButton>
          </router-link>
        </div>
      </div>

      <DropdownMenu v-if="userInfoStore.allSocials.length > 0">
        <DropdownMenuTrigger class="self-start w-auto">
          <button class="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 max-w-64">
            <span v-if="store.accountFilter.length > 0" class="truncate">
              {{
                userInfoStore.allSocials
                  .filter(social => store.accountFilter.includes(social.id))
                  .map((social) => social.type === ConnectionTypes.YOUTUBE ? social.displayName : `@${social.displayName}`)
                  .join(', ')
              }}
            </span>
            <template v-else>
              <span class="text-sm font-semibold">Filter by account</span>
            </template>
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuSocialConnectionItem class="cursor-pointer" v-model:checked="store.accountFilter" :value="null">
            Show all posts
          </DropdownMenuSocialConnectionItem>
          <DropdownMenuSocialConnectionItem
            class="cursor-pointer"
            v-for="social in userInfoStore.allSocials" :key="social.id" 
            v-model:checked="store.accountFilter" :value="social.id"
          >
            <YoutubeIcon v-if="social.type === ConnectionTypes.YOUTUBE" class="w-4 h-4 text-youtube">
              YouTube
            </YoutubeIcon>
            <InstagramIcon v-if="social.type === ConnectionTypes.INSTAGRAM" class="w-4 h-4 fill-instagram">
              Instagram
            </InstagramIcon>
            <TikTokIcon v-if="social.type === ConnectionTypes.TIKTOK" class="w-4 h-4">
              TikTok
            </TikTokIcon>

            <span v-if="social.type === ConnectionTypes.YOUTUBE">{{ social.displayName }}</span>
            <span v-else>@{{ social.displayName }}</span>
          </DropdownMenuSocialConnectionItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>

    <ContentPublisher v-if="!isMobile" @schedule="scheduleVideo" />
    <ContentPublisherMobile v-else @schedule="scheduleVideo" />
  </main>
</template>

<script lang="ts" setup>
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { useHead } from '@unhead/vue'
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { Button } from '@/components/ui/button'
import IconSaxRefresh2 from '@/components/Icons/iconsax/IconSaxRefresh2.vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { OverallPostStatus, type PostDto } from '@/apis/streamladder-publisher/model'
import { addDays, subDays } from 'date-fns'
import Spinner from '@/components/Icons/Spinner.vue'
import toastEvents from '@/events/toastEvents'
import { useToast } from '@/Hooks/useToast'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import ContentPublisherMobile from "@/components-v2/content-publisher/ContentPublisherMobile.vue";
import compatabilityChecker from "@/services/compatabilityChecker";
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import ContentPublisher from '@/components-v2/content-publisher/ContentPublisher.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent } from '@/components/ui/dropdown-menu'
import ConnectionTypes from '@/enums/connectionTypes'
import DropdownMenuSocialConnectionItem from '@/areas/dashboard/pages/contentPublishing/DropdownMenuSocialConnectionItem.vue'

const isMobile = compatabilityChecker.isMobile()
useHead({
  title: 'Content Publisher',
  meta: [
    {
      name: 'description',
      content: 'Schedule your clips to be published on TikTok, Instagram and YouTube.',
    },
  ],
});

const router = useRouter();

function scheduleVideo(query: { postDate: string }) {
  router.push({ name: dashboardRouteNames.contentPublisher.render, query })
}

const { showToast } = useToast();

const isRetryingAllFailedPosts = ref(false);

const store = useContentPublisherStore();
const userInfoStore = useUserInfoStore();
const twoWeeksAgo = subDays(new Date(), 14);
const twoWeeksFromNow = addDays(new Date(), 14);

const postHasFailed = (post: PostDto) => {
  return post.status && (post.status.overallStatus === OverallPostStatus.Failed || post.status.overallStatus === OverallPostStatus.PartialFailure);
};

const postIsScheduledWithinTwoWeeksAgo = (post: PostDto) => {
  return post.scheduledAt
    && new Date(post.scheduledAt).getTime() >= twoWeeksAgo.getTime()
    && new Date(post.scheduledAt).getTime() <= twoWeeksFromNow.getTime();
};

const failedPosts = computed(() => store.posts
  .filter(postHasFailed)
  .filter(postIsScheduledWithinTwoWeeksAgo));

const confirmDialog = useConfirmDialog()

const revealRetryConfirmDialog = async () => {

  const title = failedPosts.value.length > 1
    ? `Are you sure you want to retry ${failedPosts.value.length} posts? 🙊`
    : 'Are you sure you want to retry one post? 🙊';

  isRetryingAllFailedPosts.value = true;
  await confirmDialog.reveal({
    title: title,
    message: 'All posts that have failed before now will be posted immediately.',
  });
};

confirmDialog.onConfirm(async () => {
  await retryFailedPosts();
  isRetryingAllFailedPosts.value = false;
});

confirmDialog.onCancel(() => {
  isRetryingAllFailedPosts.value = false;
});

const retryFailedPosts = async () => {

  showToast({
    type: toastEvents.TOAST,
    title: 'Retrying failed posts..',
    subtitle: `${failedPosts.value.length} post(s) are being retried.`
  });

  isRetryingAllFailedPosts.value = true;
  await Promise.all(failedPosts.value.map(post => store.retryPost(post.id!)));
  await store.refetch();
};

const canScheduleForInstagram = false;
</script>

<style lang="scss"></style>
