<script lang="ts" setup>
import { useStickersStore } from '@/areas/editor/store/useStickersStore';
import { useMovableContext } from '@/modules/SLMovable/useMovableContext';
import StickerElement from '@/areas/editor/workspaces/preview/layers/stickers/StickerElement.vue';
import { watch, ref } from 'vue';
import { clsx } from 'clsx';
import { useIsMobile } from '@/Hooks/useIsMobile';
import { useEditorFocusStore } from '@/store/editor/editorFocus';
import { renderStickerVue } from '@/lib/renderStickerVue';

const props = defineProps<{ id: string, class?: string, focus?: boolean }>();

const stickersStore = useStickersStore();
const sticker = stickersStore.selectById(props.id);
const focusType = stickersStore.selectFocusTypeById(props.id);

const elementRef = ref<HTMLDivElement>();

let controller = new AbortController();

async function updateStickerUrl() {

  if (sticker.type === 'custom' || sticker.type === 'giphy' || sticker.type === 'rive') {
    return;
  }

  controller.abort();
  controller = new AbortController();

  sticker.imageUrl = '';
  sticker.bitmap = undefined;
  const previewQuality = Math.min(1080, 720 * Math.min(window.devicePixelRatio, 2));
  const absoluteScale = (1.6 * previewQuality) / sticker.naturalWidth;
  const scale = absoluteScale / previewQuality;
  const element = elementRef.value?.parentNode?.querySelector('[data-sticker]');
  sticker.imageUrl = await renderStickerVue(sticker, scale, previewQuality, controller.signal, element);
}

const { width: movableWidth, height: movableHeight } = useMovableContext()!;

const hide = ref(false);

watch(sticker, () => {
  const shouldHide = () => !!sticker.imageUrl && !sticker.editing && !!sticker.bitmap;
  if (shouldHide()) {
    setTimeout(() => {
      hide.value = shouldHide();
    }, 1);
  } else {
    hide.value = false;
  }
});

watch(() => sticker.editing, () => {

  if (sticker.type === 'custom' || sticker.type === 'giphy' || sticker.type === 'rive') {
    return;
  }

  if (!sticker.editing) {
    updateStickerUrl();
  }
});

watch(() => sticker.naturalWidth, (width) => {
  if (width) {
    setTimeout(() => updateStickerUrl(), 200);
  }
});

const editorFocusStore = useEditorFocusStore();

const componentRef = ref<InstanceType<typeof StickerElement>>();

function setEditMode() {
  editorFocusStore.setFocus(focusType.value, props.id);
  setTimeout(() => componentRef.value?.setEditMode?.(), 0);
}

const isMobile = useIsMobile();

function onLoad() {
  if (sticker.editing) {
    if (isMobile.value) {
      sticker.editing = false;
    } else {
      setEditMode();
    }
  }
}

watch(() => sticker.editing, () => {
  if (sticker.editing) {
    setEditMode();
  }
});
</script>

<template>
  <StickerElement ref="componentRef" v-model="sticker"
    :key="'textContent' in sticker ? sticker.textContent : sticker.id"
    :render-width="movableWidth" :render-height="movableHeight" 
    :class="clsx({ 'invisible': hide }, 'transition-opacity', props.class)"
    :focus="focus" @ready="onLoad"
  />
  <div ref="elementRef" class="absolute"></div>
</template>

<style lang="scss" scoped>

</style>

