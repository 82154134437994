/**
 * Converts a GUID string to a deterministic float value between -1 and 1
 * Uses FNV-1a hash for uniform distribution
 * @param guid A valid GUID string like "123e4567-e89b-12d3-a456-426614174000"
 * @returns A number between -1 and 1
 */
export default function guidToRadians(guid: string): number {

  // Validate GUID format
  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  if (!guidRegex.test(guid)) {
    throw new Error(`Invalid GUID format "${guid}"`);
  }

  // FNV-1a hash parameters
  const FNV_PRIME = 0x01000193;
  const FNV_OFFSET_BASIS = 0x811c9dc5;

  // Convert GUID to bytes array
  const bytes = new TextEncoder().encode(guid);

  // Calculate FNV-1a hash
  let hash = FNV_OFFSET_BASIS;
  for (let i = 0; i < bytes.length; i++) {
    hash ^= bytes[i];
    hash = Math.imul(hash, FNV_PRIME);
  }

  // Use more bits from the hash for better distribution
  const highBits = hash >>> 0;
  const shiftedHash = Math.imul(hash, FNV_PRIME) >>> 0;

  // Combine high and low bits and normalize to -1 to 1
  return ((highBits * Number.MAX_SAFE_INTEGER + shiftedHash) % 2000000) / 1000000 - 1;
}

