import { pick, merge } from 'lodash-es';
import { computed, ref, watch, type Ref } from 'vue'
import { useUserTemplatesStore, type Template } from '@/store/user/userTemplates'
import { useUserInfoStore } from '@/store/user/userInfo'
import { storageToSticker } from '@/components/Stickers/stickerStorageMapper'
import { createGlobalState } from '@vueuse/core'
import { v4 as uuid } from 'uuid'
import type {
  LayoutWithCrops,
  Sticker,
  Crop,
  BaseSticker,
  TypedSticker,
  Layout,
  SoundEffect,
  Effect
} from '@/areas/editor/@type/Project'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { findShapeOf, findZIndexById, findNameFromId } from '@/areas/editor/@data/prefabs/_helpers'
import { split } from '@/areas/editor/@data/prefabs/split'
import { basecam } from '@/areas/editor/@data/prefabs/basecam'
import { mosaic } from '@/areas/editor/@data/prefabs/mosaic'
import { circle } from '@/areas/editor/@data/prefabs/circle'
import templateService from '@/services/templateService'
import { getApiCustomLayouts } from '@/apis/streamladder-api/custom-layouts/custom-layouts'
import * as Sentry from '@sentry/vue'
import { small } from '@/areas/editor/@data/prefabs/small'
import { blurred } from '@/areas/editor/@data/prefabs/blurred'
import { dual } from '@/areas/editor/@data/prefabs/dual'
import { gameUi } from '@/areas/editor/@data/prefabs/gameUi'
import { full } from '@/areas/editor/@data/prefabs/full'
import { half } from '@/areas/editor/@data/prefabs/half'
import { duoSplit } from '@/areas/editor/@data/prefabs/duoSplit'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Size, Area } from '@/modules/SLMovable/@types/Movable'
import { resizeAspectLockedFromCenter } from '@/modules/SLMovable/helpers/resize/resizeAspectLockedFromCenter'
import { resizeAspectLocked } from '@/modules/SLMovable/helpers/resize/resizeAspectLocked'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { saveCropsInLocalStorage } from '@/areas/editor/workspaces/preview/cropper/saveCropsInLocalStorage';

export const usePresets = createGlobalState(() => {

  const userTemplatesStore = useUserTemplatesStore()
  const customLayouts = ref<LayoutWithCrops[]>([])
  const videoStore = useVideoStore()
  const prefabs = computed(() => {
    if (videoStore.videoSize) {
      const { width, height } = videoStore.videoSize
      return [
        small(width, height), circle(width, height), gameUi(width, height),
        split(width, height), blurred(width, height), full(width, height),
        basecam(width, height), mosaic(width, height), dual(width, height),
        duoSplit(width, height), half(width, height),
      ]
    } else {
      return []
    }
  })

  const userInfoStore = useUserInfoStore()
  const layouts = computed<LayoutPreset[]>(() => {

    if (!videoStore.videoSize) {
      return []
    }

    const videoSize = videoStore.videoSize
    if (!userInfoStore.isLoggedIn) {
      return prefabs.value.map((l) => layoutWithCropsToLayoutPreset(l, videoSize, 'prefab'))
    }

    const userTemplates = userTemplatesStore.savedTemplates as Template[]
    const userLayouts = customLayouts.value
    const prefabLayouts = prefabs.value

    return [
      ...userTemplates
        .filter(hasCropperData)
        .map((t) => savedTemplateToLayoutPreset(t, videoSize)),

      ...userLayouts
        .map((l) => layoutWithCropsToLayoutPreset(l, videoSize, 'custom-layout')),

      ...prefabLayouts
        .map((l) => layoutWithCropsToLayoutPreset(l, videoSize, 'prefab'))
        .map((l) => ({
          ...l,
          crops: l.crops.map((crop) => ({
            ...crop,
            storageKey: crop.id,
          }))
        }))
    ].filter(Boolean)
  })
  
  function handleError(e: unknown) {
    console.error(e)
    Sentry.captureException(e)
  }

  const customLayoutsStatus = ref<'idle' | 'loading' | 'success' | 'error'>('idle')
  const fetchCustomLayoutsOnce = useFetchOnce(getApiCustomLayouts, customLayoutsStatus, {
    onSuccess(layouts) {
      customLayouts.value = layouts as LayoutWithCrops[]
    },
    onError(e) {
      handleError(e)
    }
  })
  
  const savedTemplatesStatus = ref<'idle' | 'loading' | 'success' | 'error'>('idle')
  const fetchSavedTemplates = useFetchOnce(templateService.getTemplates, savedTemplatesStatus, {
    onError(e) {
      handleError(e)
    },
  })

  async function prepareLayouts() {
    await Promise.all([
      fetchCustomLayoutsOnce(),
      fetchSavedTemplates(),
    ])
  }

  watch(() => userInfoStore.isLoggedIn, prepareLayouts)

  function applyPreset(presetId: string) {

    const preset = layouts.value.find((l) => l.id === presetId)
    if (!preset) {
      // console.log(layouts.value.map(l => l.id))
      throw new Error(`Preset ID "${presetId}" not found`)
    }

    const layoutsStore = useLayoutsStore()
    const layoutId = uuid()
    layoutsStore.createById(layoutId, {
      name: preset.name,
      presetId: preset.id,
    })

    const cropsStore = useCropsStore()
    for (const presetCrop of preset.crops ?? []) {

      const cropId = uuid();
      const crop = merge(presetCrop, findAreaOfPresetCrop(presetCrop, videoStore.videoSize!)); 

      cropsStore.createById(cropId, { ...crop, layoutId: layoutId });
      saveCropsInLocalStorage(cropId);
    }

    const stickersStore = useStickersStore()
    for (const sticker of preset.stickers ?? []) {
      if (!stickersStore.selectById(sticker.id)) {
        if (sticker?.icon === 'rive') {
          stickersStore.createRiveSticker({
            ...sticker,
            type: 'rive',
            artboard: sticker.key,
            naturalWidth: .75 * 100,
            naturalHeight: .25 * 100,
          });
        } else {
          stickersStore.createById(sticker.id, sticker)
        }
      }
    }

    const effectsStore = useEffectsStore();
    for (const sound of preset.sounds ?? []) {
      effectsStore.createById<Effect<'sound'>>(sound.id, {
        type: 'sound',
        name: sound.name,
        url: sound.url,
        tags: sound.tags,
        startMs: sound.startMs,
        endMs: sound.endMs,
        maxDurationMs: sound.maxDurationMs,
        volume: sound.volume,
      });
    }

    return layoutId
  }

  return {
    layouts,
    prepareLayouts,
    applyPreset,
    resetFetchStatus() {
      customLayoutsStatus.value = 'idle'
      savedTemplatesStatus.value = 'idle'
    }
  }
})

function useFetchOnce<T>(
  fn: () => Promise<T>, 
  status: Ref<'idle' | 'loading' | 'success' | 'error'>,
  options?: {
    onSuccess?: (data: T) => void,
    onError?: (e: unknown) => void
  }
) {
  const userInfoStore = useUserInfoStore()
  return async function fetchOnce() {
    if (userInfoStore.isLoggedIn && status.value === 'idle') {
      status.value = 'loading'
      await fn()
        .then((r) => {
          options?.onSuccess?.(r)
          status.value = 'success'
        })
        .catch((e) => {
          options?.onError?.(e)
          status.value = 'error'
        })
    }
  }
}

export type LayoutPreset = LayoutWithCrops
  & { sounds?: SoundEffect[] }
  & { stickers: (Sticker & { scale: number })[] }
  & { origin: 'saved-template' | 'custom-layout' | 'prefab' }

function hasCropperData(template: Template): boolean {
  return template.croppers.some(c => {
    return template.feeds.some(f => {
      return f.feedKey === c.cropperKey || f.feedKey === c.cropperKey.replace(/^c_/, 'f_')
    })
  })
}

function savedTemplateToLayoutPreset(template: Template, { width: videoWidth, height: videoHeight }: Size): LayoutPreset {

  const layout: Layout = {
    id: template.id,
    name: template.templateName,
    presetId: template.id,
  }

  const crops = [] as Crop[]
  for (let i = 0; i < template.croppers.length; i++) {

    const cropper = template.croppers[i]
    const feed = template.feeds.find((f) => {
      return f.feedKey === cropper.cropperKey
        || f.feedKey === cropper.cropperKey.replace(/^c_/, 'f_')
    })

    if (!feed?.data || !cropper.data) {
      console.error(JSON.stringify(template, null, 2))
      // Sentry.captureException(new Error('Invalid template data, missing feed or cropper data'))
      continue;
    }

    const oldCropArea = {
      width: cropper.data.w * videoWidth,
      height: cropper.data.h * videoHeight,
      x: cropper.data.x * videoWidth,
      y: cropper.data.y * videoHeight
    }

    const feedSize = {
      width: feed.data.w * 1080,
      height: feed.data.h * 1920,
    }

    const newCropSize = resizeAspectLockedFromCenter(
        oldCropArea,
        { x: (cropper.data.x + cropper.data.w) * videoWidth, y: (cropper.data.y + cropper.data.h) * videoHeight },
        ['s', 'e'],
        { top: 0, right: videoWidth, bottom: videoHeight, left: 0 },
        feedSize,
        null)

    crops.push({
      id: uuid(),
      layoutId: layout.id,
      x: newCropSize.x / videoWidth,
      y: newCropSize.y / videoHeight,
      z: findZIndexById(cropper.cropperKey) ?? i,
      width: newCropSize.width / videoWidth,
      height: newCropSize.height / videoHeight,
      feedData: {
        x: feed!.data.x,
        y: feed!.data.y,
        width: feed!.data.w,
        height: feed!.data.h,
      },
      input: {
        name: findNameFromId(cropper.cropperKey),
        color: `oklch(70% 0.15 ${Math.random() * 360})`,
        shape: findShapeOf(cropper),
      }
    })
  }

  const videoStore = useVideoStore()
  const stickers = [] as Sticker[]
  for (let i = 0; i < template.stickers.length; i++) {

    const stickerData = storageToSticker(template.stickers[i], videoStore._duration * 1000)
    const baseSticker: BaseSticker = {
      id: stickerData.key,
      key: stickerData.componentName,
      area: {
        x: stickerData.x,
        y: stickerData.y,
        width: stickerData.width ?? 1,
        height: stickerData.height ?? 1,
      },
      scale: stickerData.scale ?? null,
      z: i,
      editing: false,
      startMs: stickerData.start ?? 0,
      endMs: stickerData.end ?? videoStore._duration * 1000,
    }

    if (stickerData.icon) {
      stickers.push({
        ...baseSticker,
        type: 'social',
        color: stickerData.color,
        textContent: stickerData.text || stickerData.htmlContent,
        icon: stickerData.icon,
      } as TypedSticker<'social'>)
    } else if (stickerData.isTextSticker) {
      stickers.push({
        ...baseSticker,
        type: 'text',
        color: stickerData.color,
        textContent: stickerData.text || stickerData.htmlContent,
        variant: stickerData.variant,
        editing: false,
      } as TypedSticker<'text'>)
    } else if (stickerData.imageUrl) {
      if (stickerData.imageUrl.includes('webp')) {
        stickers.push({
          ...baseSticker,
          type: 'giphy',
          area: {
            x: stickerData.x,
            y: stickerData.y,
            width: stickerData?.width ?? 0.5,
            height: stickerData?.height ?? 0.25
          },
          imageUrl: stickerData.imageUrl,
          naturalWidth: stickerData.naturalWidth,
          naturalHeight: stickerData.naturalHeight
        } as TypedSticker<'giphy'>)
      } else {
        stickers.push({
          ...baseSticker,
          type: 'custom',
          imageUrl: stickerData.imageUrl,
        } as TypedSticker<'custom'>)
      }
    } else {
      console.error('Unknown sticker type', JSON.stringify(stickerData))
      // Sentry.captureException(new Error('Unknown sticker type'))
    }
  }

  const sounds = [];

  if (template.sounds && template.sounds?.length > 0) {
    for (const sound of template.sounds) {
      sounds.push(sound as SoundEffect);
    }
  }

  return {
    ...layout,
    crops,
    stickers,
    sounds,
    origin: 'saved-template',
  }
}

function layoutWithCropsToLayoutPreset(layout: LayoutWithCrops, videoSize: Size, origin: LayoutPreset['origin']): LayoutPreset {
  return {
    ...ensureCorrectCropperAspectRatio(layout, videoSize),
    stickers: [],
    origin: origin,
  }
}

function ensureCorrectCropperAspectRatio(layout: LayoutWithCrops, videoSize: Size) {

  const crops = layout.crops.map((crop) => {

    const oldCropSize = {
      width: crop.width * videoSize.width,
      height: crop.height * videoSize.height,
      x: crop.x * videoSize.width,
      y: crop.y * videoSize.height
    }

    const feedAspectLock = {
      width: crop.feedData.width * 1080,
      height: crop.feedData.height * 1920,
    }

    if (oldCropSize.width / oldCropSize.height === feedAspectLock.width / feedAspectLock.height) {
      return crop
    }

    const newCropSize = resizeAspectLockedFromCenter(
      oldCropSize,
      { x: (crop.x + crop.width) * videoSize.width, y: (crop.y + crop.height) * videoSize.height },
      ['s', 'e'],
      { top: 0, right: videoSize.width, bottom: videoSize.height, left: 0 },
      feedAspectLock,
      null)

    return {
      ...crop,
      x: newCropSize.x / videoSize.width,
      y: newCropSize.y / videoSize.height,
      width: newCropSize.width / videoSize.width,
      height: newCropSize.height / videoSize.height,
    }
  })

  return {
    ...layout,
    crops,
  }
}

function findAreaOfPresetCrop(crop: Omit<Crop, 'id' | 'layoutId'>, videoSize: Size): Omit<Crop, 'id' | 'layoutId' | 'storageKey'> {

  if (!crop.storageKey) {
    return crop
  }

  const storageString = localStorage.getItem(crop.storageKey)
  if (!storageString) {
    return crop
  }

  const storageValue = JSON.parse(storageString)
  if (!isCompleteCrop(storageValue)) {
    return crop;
  }

  if (storageValue.videoWidth !== videoSize.width || storageValue.videoHeight !== videoSize.height) {
    return crop
  }
  
  if (crop.input.shape === 'freeform' || crop.input.shape !== storageValue.input.shape) {
    return storageValue
  }

  const storedCrop = {
    width: storageValue.width * videoSize.width,
    height: storageValue.height * videoSize.height,
    x: storageValue.x * videoSize.width,
    y: storageValue.y * videoSize.height
  }

  const cropAspectLock = {
    width: crop.width * videoSize.width,
    height: crop.height * videoSize.height,
  }

  if (storedCrop.width / storedCrop.height === cropAspectLock.width / cropAspectLock.height) {
    return storageValue
  }

  const resizedCrop = resizeAspectLocked(
    storedCrop,
    {
      x: (crop.x + crop.width) * videoSize.width,
      y: (crop.y + crop.height) * videoSize.height
    },
    ['s', 'e'],
    {
      top: 0,
      right: videoSize.width,
      bottom: videoSize.height,
      left: 0
    },
    cropAspectLock,
    null)

  return {
    width: resizedCrop.width / videoSize.width,
    height: resizedCrop.height / videoSize.height,
    x: resizedCrop.x / videoSize.width,
    y: resizedCrop.y / videoSize.height
  }
}

function isArea(area: unknown): area is { x: number, y: number, width: number, height: number } {
  return Boolean(area && typeof area === 'object'
    && 'x' in area && 'y' in area
    && 'width' in area && 'height' in area
    && typeof area.x === 'number' && typeof area.y === 'number'
    && typeof area.width === 'number' && typeof area.height === 'number')
}

function isCompleteCrop(crop: unknown): crop is Omit<Crop, 'id' | 'layoutId' | 'storageKey'> & { videoWidth: number, videoHeight: number } {
  return isArea(crop) 
    && 'videoWidth' in crop && 'videoHeight' in crop
    && 'input' in crop && 'feedData' in crop
}
