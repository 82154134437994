<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import type { MultipleSurveyQuestion } from 'posthog-js'
import MultipleChoiceOption from '@/components/Onboarding/surveyTypes/MultipleChoiceOption.vue'
import { Textarea } from '@/components/ui/textarea'

const props = defineProps<{ question: MultipleSurveyQuestion }>();
const emit = defineEmits<{ 
  (event: 'answered', payload: string[]): void
  (event: 'invalidAnswer'): void
}>()

const answers = ref<string[]>([]);

const openChoiceAnswer = ref<string>('')
const openChoiceAnswerModel = ref<string>('');
const openChoiceOption = computed(() => {
  const choices = props.question.choices
  if (props.question.hasOpenChoice) {
    return choices[choices.length - 1]
  } else {
    return null
  }
})

const hasOpenChoiceOption = computed(() => {
  return openChoiceOption.value && answers.value?.includes(openChoiceOption.value)
})

const response = computed(() => {

  const closedChoices = answers.value.filter(a => a !== openChoiceOption.value)
  const openChoice = openChoiceAnswer.value || 'Other'
  
  if (openChoiceOption.value && answers.value.includes(openChoiceOption.value)) {
    return [ ...closedChoices, openChoice ].filter(Boolean)
  } else {
    return closedChoices.filter(Boolean)
  }
})

watch(response, (response) => emit('answered', response))
</script>

<template>
  <div class="flex flex-row flex-wrap gap-2">
    <MultipleChoiceOption 
      v-for="(option, index) in question.choices" :key="option + index"
      v-model:answers="answers" :choice="option" size="sm"
    >
      {{ option }}
    </MultipleChoiceOption>
  </div>

  <Textarea v-if="hasOpenChoiceOption"
    v-model="openChoiceAnswerModel"
    @change="openChoiceAnswer = openChoiceAnswerModel"
    class="bg-panel-bg placeholder:font-light placeholder:text-sm w-full min-h-[75px] max-h-[250px]"
    maxlength="300"
    placeholder="Enter your own answer here..."
  />
</template>

<style scoped>

</style>
