import * as Mp4Muxer from 'mp4-muxer';
// import MP4Decoder from '../decoder/mp4-decoder';

// @ts-ignore
import { MP4Demuxer } from './demuxer-mp4';
import { getEncoderConfig } from './get-encoder-config';
import { encodeAudio, resampleAudio } from './audio-utils';

function createMuxer(width: number, height: number) {
    const target = new Mp4Muxer.ArrayBufferTarget();
    const muxer = new Mp4Muxer.Muxer({
        target,
        video: {
            codec: 'avc',
            width: width,
            height: height,
        },
        audio: {
            codec: 'aac',
            numberOfChannels: 2,
            sampleRate: 44100,
        },
        firstTimestampBehavior: 'offset',
        fastStart: false,
    });

    return muxer;
}


interface AudioData {
    rawData: Float32Array[];
    sampleRate: number;
}


export async function createMontage(videoUrls: string[], decodeAudioInMainThread: (url: string) => Promise<AudioData>, useHardwareAcceleration: boolean) {
    let muxer: Mp4Muxer.Muxer<Mp4Muxer.ArrayBufferTarget> | null = null;
    let canvas: OffscreenCanvas | null = null;
    let ctx: OffscreenCanvasRenderingContext2D | null = null;

    let firstVideoDecoderConfig: VideoDecoderConfig | null = null;
    let myTimeStamp = 0;
    let myAudioTimeStamp = 0;
    const audioSampleRate = 44100;
    let firstVideoAspectRatio = 0;
    let totalProgress = 0;

    const videoEncoder = new VideoEncoder({
        output: (encodedVideoChunk, meta) => {
            muxer!.addVideoChunk(encodedVideoChunk, meta, myTimeStamp);
            myTimeStamp += encodedVideoChunk.duration!;
            // console.log({ myTimeStamp }, videoEncoder.encodeQueueSize);
        },
        error: (e) => {
            console.error(e);
            postMessage({ type: 'error', log: e });
        }
    })

    function onProgress(progress: number) {
        postMessage({ type: 'progress', progress: progress / videoUrls.length, status: '(2/2) Exporting..' });
    }


    for (const url of videoUrls) {
        const getUrlWithoutCache = () => url.includes('?') ? url + `&videoCache=${Math.round(Math.random() * 1e9)}` : url + `?videoCache=${Math.round(Math.random() * 1e9)}`;

        const videoPromise = new Promise(resolve => {
            let videoTrack: string | null = null;
            let decoderConfig: VideoDecoderConfig | null = null;
            let decoder: VideoDecoder | null = null;
            let encodedFrames = 0;
            let decodedFrames = 0;
            let aspectRatio = 0;
            let currentVideoProgress = 0;
            let muxedFrames = 0;

            const isSLRender = url.includes('results-sl-enam.cdn');

            const demuxer = new MP4Demuxer(getUrlWithoutCache(), {
                startTime: 0,
                endTime: Infinity,
                onConfig(config: { videoConfig: VideoDecoderConfig & { id: string } }) {
                    aspectRatio = config.videoConfig.codedWidth! / config.videoConfig.codedHeight!;
                    if (muxer === null) {
                        console.log('Initializing muxer based on first video');
                        muxer = createMuxer(config.videoConfig.codedWidth!, config.videoConfig.codedHeight!);
                        const encoderConfig = getEncoderConfig(config.videoConfig.codedWidth!, config.videoConfig.codedHeight!) as VideoEncoderConfig
                        encoderConfig.codec = config.videoConfig.codec;

                        if (!useHardwareAcceleration) encoderConfig.hardwareAcceleration = 'prefer-software';

                        videoEncoder.configure(encoderConfig);
                        firstVideoAspectRatio = config.videoConfig.codedWidth! / config.videoConfig.codedHeight!;
                    }
                    if (canvas === null){
                        canvas = new OffscreenCanvas(config.videoConfig.codedWidth!, config.videoConfig.codedHeight!);
                        ctx = canvas.getContext('2d');
                    }
                    if (firstVideoDecoderConfig === null) {
                        firstVideoDecoderConfig = config.videoConfig;
                    }
                    
                    if (!isSLRender || firstVideoDecoderConfig.codedWidth !== config.videoConfig.codedWidth
                        || firstVideoDecoderConfig.codedHeight !== config.videoConfig.codedHeight
                        || firstVideoDecoderConfig.codec !== config.videoConfig.codec
                    ) {
                        decoder = new VideoDecoder({
                            output: (videoFrame) => {

                                if (aspectRatio !== firstVideoAspectRatio) {
                                    const imgAspectRatio = videoFrame.displayWidth / videoFrame.displayHeight;
                                    const canvasAspectRatio = canvas!.width / canvas!.height;

                                    let renderWidth, renderHeight;

                                    // Scale the image to fit the canvas while maintaining the aspect ratio
                                    if (imgAspectRatio > canvasAspectRatio) {
                                        // Image is wider than the canvas
                                        renderWidth = canvas!.width;
                                        renderHeight = canvas!.width / imgAspectRatio;
                                    } else {
                                        // Image is taller than the canvas
                                        renderWidth = canvas!.height * imgAspectRatio;
                                        renderHeight = canvas!.height;
                                    }
                                
                                    // Calculate the position to center the image
                                    const x = (canvas!.width - renderWidth) / 2;
                                    const y = (canvas!.height - renderHeight) / 2;
                                
                                    // Draw the image
                                    ctx!.fillStyle = '#000000';
                                    ctx?.fillRect(0, 0, canvas!.width, canvas!.height);
                                    ctx!.drawImage(videoFrame, x, y, renderWidth, renderHeight);

                                    const canvasFrame = new VideoFrame(canvas!, { timestamp: videoFrame.timestamp, duration: videoFrame.duration! });
                                    videoEncoder.encode(canvasFrame,{ keyFrame: encodedFrames % 120 === 0 })
                                    // console.log('Canvas frame', encodedFrames)
                                    canvasFrame.close();
                                }
                                else {
                                    videoEncoder.encode(videoFrame, { keyFrame: encodedFrames % 120 === 0 });
                                }
                                videoFrame.close();
                                encodedFrames++;
                                // console.log({ encodedFrames}, decoder?.decodeQueueSize);
                            },
                            error: (e) => {
                                console.error(e);
                                postMessage({ type: 'error', log: e });
                            }
                        });

                        if (!useHardwareAcceleration) config.videoConfig.hardwareAcceleration = 'prefer-software';

                        decoder.configure(config.videoConfig);
                    }

                    console.log(config.videoConfig)

                    decoderConfig = config.videoConfig;

                    videoTrack = config.videoConfig.id;
                    demuxer.start();
                },
                async onChunk(sample: any, timestamp: number, duration: number, track_id: string) {
                    const type = sample.is_sync ? "key" : "delta";
                
                    if (decoder) {
                        await videoEncoder.flush();

                        while(Math.abs(decodedFrames - encodedFrames) > 60) {
                            console.log('Waiting for decoder to catch up');
                            await new Promise(r => setTimeout(r, 200));
                        }
                        currentVideoProgress = encodedFrames / demuxer.videoTrack.nb_samples;
                        if (encodedFrames % 100 === 0) onProgress(totalProgress + currentVideoProgress);
                    }
                    
                    if (track_id === videoTrack) {
                        if (decoder) {
                            decoder.decode(new EncodedVideoChunk({
                                type: type,
                                timestamp: timestamp,
                                duration: duration,
                                data: sample.data
                            }))
                            decodedFrames++;
                        }
                        else {
                            muxer!.addVideoChunkRaw(sample.data, type, myTimeStamp, duration, {
                                decoderConfig: decoderConfig!,
                            });
                            myTimeStamp += duration;


                            muxedFrames++;
                            currentVideoProgress = muxedFrames / demuxer.videoTrack.nb_samples;

                            if (muxedFrames % 100 === 0) onProgress(totalProgress + currentVideoProgress);
                        }
                    }
                },
                setStatus(status: string, text: string) {
                    console.log(status, text)
                },
                onFinish: () => {
                    console.log('finish')
                },
                onFinishProgressing: async () => {
                    console.log('finish');
                    await new Promise(r => setTimeout(r, 1000));
                    
                    if (decoder) await decoder.flush();
                    await videoEncoder.flush();
                    demuxer.releaseUsedSamples();

                    console.log(videoEncoder, decoder);
                    resolve(null);
                },
                onError: (e) => {
                    console.error(e);
                    postMessage({ type: 'error', log: e });
                }
            });
        })

        await videoPromise;

        const audioPromise = (async () => {
            const audio = await decodeAudioInMainThread(getUrlWithoutCache());
            if (audio.sampleRate !== audioSampleRate) {
                for (let channelIx = 0; channelIx < audio.rawData.length; channelIx++) {
                    audio.rawData[channelIx] = resampleAudio(audio.rawData[channelIx], audio.sampleRate, audioSampleRate);
                }
            }

            await encodeAudio(audio.rawData, 1e6 / 30, audioSampleRate, (chunk, timestamp) => {
                if (myAudioTimeStamp <= myTimeStamp) {
                    muxer?.addAudioChunk(chunk, undefined, myAudioTimeStamp);
                }
                else console.log('skip audio ', myAudioTimeStamp, myTimeStamp)

                myAudioTimeStamp += chunk.duration!;

            }, (error) => {
                console.error(error);
            })
        })();

        await audioPromise;

        totalProgress += 1.0;

        myAudioTimeStamp = myTimeStamp;
    }



    await muxer!.finalize();

    // @ts-ignore
    postMessage({ type: 'finished', buffer: muxer!.target.buffer, fps: 0, speed: 0 }, [muxer.target.buffer]);
}