<script setup lang="ts">
import { useMovableElementContext } from '@/modules/SLMovable/useMovableElementContext'
import { onKeyDown, onKeyUp, useMagicKeys, useRafFn } from '@vueuse/core'
import { moveWithBoundingAndSnapping } from '@/modules/SLMovable/helpers/move/move'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import { uniq, debounce } from 'lodash-es'

const props = defineProps<{
  id: string,
  handleClass?: string,
  disableArrowKeys?: boolean
}>()

const emit = defineEmits<{
  (event: 'moveStart', point: { x: number; y: number }): void
  (event: 'move', area: { x: number; y: number; width: number; height: number }): void
  (event: 'moveEnd'): void
}>()

const {
  focused,
  snap: snapEnabled,
  isMoving,
  isResizing,
  movingFrom,
  source,
  localArea,
  scaleX,
  scaleY,
  bounds,
  snapGridId,
  containerHeight,
  containerWidth,
  container,
} = useMovableElementContext()!

const snapLines = ref<{ x: number[]; y: number[] }>({ x: [], y: [] })

onKeyDown(['Shift'], () => {
  if (movingFrom.value) {
    snapLines.value = {
      x: [localArea.value.x + 0.5 * localArea.value.width],
      y: [localArea.value.y + 0.5 * localArea.value.height],
    }
  }
})

onKeyUp(['Shift'], () => {
  if (isMoving.value) {
    snapLines.value = { x: [], y: [] }
  }
})

const snap = computed(() => {
  return {
    x: uniq([0, 0.5, 1, /* ...snapLines.x */]),
    y: uniq([0, 0.5, 1, /* ...snapLines.y */]),
  }
})

function determineSnapping(event: MouseEvent | TouchEvent) {

  if (!snapEnabled.value || event.ctrlKey) {
    return null
  }

  return {
    x: snap.value.x ?? null,
    y: snap.value.y ?? null,
  }
}

function determineMoveTarget(event: MouseEvent | TouchEvent) {

  const point = determineTouchPoint(event)
  const deltaX = scaleX.value(point.x - movingFrom.value!.x)
  const deltaY = scaleY.value(point.y - movingFrom.value!.y)

  if (!event.shiftKey) {
    return {
      x: source.value.x + deltaX,
      y: source.value.y + deltaY,
    }
  }

  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    const snapY = snapLines.value.y[0] ?? localArea.value.y + 0.5 * localArea.value.height
    return {
      x: source.value.x + deltaX,
      y: snapY - 0.5 * source.value.height,
    }
  } else {
    const snapX = snapLines.value.x[0] ?? localArea.value.x + 0.5 * localArea.value.width
    return {
      x: snapX - 0.5 * localArea.value.width,
      y: source.value.y + deltaY,
    }
  }
}

const snapThreshold = computed(() => ({
  x: scaleX.value(5),
  y: scaleY.value(5),
}))

function move(event: MouseEvent | TouchEvent) {

  if (!movingFrom.value || (event instanceof MouseEvent && event.buttons !== 1)) {
    return
  }

  localArea.value = moveWithBoundingAndSnapping(
    localArea.value,
    determineMoveTarget(event),
    bounds.value,
    determineSnapping(event),
    snapThreshold.value,
  )

  emit('move', localArea.value)
}

function determineTouchPoint(event: MouseEvent | TouchEvent) {

  if (!container.value) {
    return { x: 0, y: 0 }
  }

  const { pageX, pageY } = 'pageX' in event ? event : event.touches[0]

  const containerRect = container.value.getBoundingClientRect()

  return {
    x: pageX - containerRect.x - window.scrollX,
    y: pageY - containerRect.y - window.scrollY,
  }
}

const moveEvents = ['mousemove', 'touchmove'] as const
const moveEndEvents = ['mouseup', 'touchend', 'click', 'dblclick', 'auxclick'] as const

const debouncedMove = debounce(move, 0)
const debouncedMoveEnd = debounce(moveEnd, 0)

function moveStart(event: MouseEvent | TouchEvent) {

  emit('moveStart', determineTouchPoint(event))

  for (const event of moveEvents) {
    window.addEventListener(event, debouncedMove)
  }

  for (const event of moveEndEvents) {
    window.addEventListener(event, debouncedMoveEnd)
  }
}

const debouncedMoveStart = debounce(moveStart, 0)

function moveEnd() {

  snapLines.value = { x: [], y: [] }
  emit('moveEnd')
  
  for (const event of moveEvents) {
    window.removeEventListener(event, debouncedMove)
  }
  
  for (const event of moveEndEvents) {
    window.removeEventListener(event, debouncedMoveEnd)
  }
}

const snapLinesToDisplay = computed(() => {
  
  if (!snapEnabled.value) {
    return {
      x: [],
      y: [],
    }
  }

  function pointAt(relative: number) {
    return {
      x: localArea.value.x + relative * localArea.value.width,
      y: localArea.value.y + relative * localArea.value.height,
    }
  }

  return {
    x: snap.value.x.filter((x) => [0, 0.5, 1].some(v => pointAt(v).x === x)),
    y: snap.value.y.filter((y) => [0, 0.5, 1].some(v => pointAt(v).y === y)),
  }
})

const boundaryLinesToDisplay = computed(() => {

  const boundariesX = [bounds.value?.top, bounds.value?.bottom].filter((x) => x !== null) as number[]
  const boundariesY = [bounds.value?.left, bounds.value?.right].filter((x) => x !== null) as number[]

  return {
    x: boundariesX.filter((x) => x === localArea.value.x || x === localArea.value.x + localArea.value.width),
    y: boundariesY.filter((y) => y === localArea.value.y || y === localArea.value.y + localArea.value.height),
  }
})

const { shift, arrowUp, arrowLeft, arrowDown, arrowRight } = useMagicKeys()

const { pause: pauseKeyboardHandling, resume: resumeKeyboardHandling } = useRafFn(() => {

  if (!shift.value && !arrowUp.value && !arrowLeft.value && !arrowDown.value && !arrowRight.value) {
    return
  }
  
  if (!focused.value) {
    return
  }

  const delta = {
    x: arrowLeft.value ? -1 : arrowRight.value ? 1 : 0,
    y: arrowUp.value ? -1 : arrowDown.value ? 1 : 0,
  }

  if (shift.value) {
    delta.x *= 10
    delta.y *= 10
  }

  delta.x /= containerWidth.value
  delta.y /= containerHeight.value

  delta.x += localArea.value.x
  delta.y += localArea.value.y

  localArea.value = moveWithBoundingAndSnapping(
    localArea.value,
    delta,
    bounds.value,
    null)

  emit('move', localArea.value)

}, { immediate: false })

const isInputTarget = (e: Event) => {
  if (!e.target) return false
  const { tagName, contentEditable } = e.target as HTMLElement
  return tagName === 'INPUT' || tagName === 'TEXTAREA' || contentEditable === 'true';
}

function onArrowKeyDown(e: Event) {
  if (focused.value && !props.disableArrowKeys && !isInputTarget(e)) {
    if (arrowUp.value || arrowLeft.value || arrowDown.value || arrowRight.value) {
      resumeKeyboardHandling()
      return false
    }
  }
}

function onArrowKeyUp() {
  if (!arrowUp.value  && !arrowLeft.value  && !arrowDown.value  && !arrowRight.value) {
    setTimeout(() => {
      pauseKeyboardHandling()
      if (focused.value && !props.disableArrowKeys) {
        emit('moveEnd')
      }
    }, 0)
  }
}

onMounted(() => {
  window.addEventListener('keydown', onArrowKeyDown)
  window.addEventListener('keyup', onArrowKeyUp)
})

onUnmounted(() => {
  window.removeEventListener('keydown', onArrowKeyDown)
  window.removeEventListener('keyup', onArrowKeyUp)
})
</script>

<template>
  <div
    class="absolute inset-0"
    :class="[handleClass, {
      'cursor-grab': !isResizing && !isMoving,
      'cursor-grabbing': isMoving,
    }]"
    @mousedown="debouncedMoveStart"
    @touchstart.passive="debouncedMoveStart"
  >
    <slot />
  </div>

  <Teleport :to="`#${snapGridId}`">
    <template v-if="isMoving">
      <template v-if="bounds">
        <div
          v-for="x in boundaryLinesToDisplay.x"
          :key="`bound-${x}`"
          class="absolute w-px bg-red-500"
          :style="{
            top: (bounds.top ?? 0) * containerHeight + 'px',
            height: (1 + ((bounds.bottom ?? 0) - 1) - (bounds.top ?? 0)) * containerHeight + 'px',
            left: x * containerWidth - 1 + 'px',
          }"
        />
      </template>

      <div
        v-for="x in snapLinesToDisplay.x"
        :key="x"
        class="absolute w-px bg-sky-500"
        :style="{
          top: (bounds?.top ?? 0) * containerHeight + 'px',
          height: (1 + ((bounds?.bottom ?? 0) - 1) - (bounds?.top ?? 0)) * containerHeight + 'px',
          left: x * containerWidth - 1 + 'px',
        }"
      />

      <template v-if="bounds">
        <div
          v-for="y in boundaryLinesToDisplay.y"
          :key="`bound-${y}`"
          class="absolute h-px bg-red-500"
          :style="{
            left: (bounds.left ?? 0) * containerWidth + 'px',
            width: (1 + ((bounds.right ?? 0) - 1) - (bounds.left ?? 0)) * containerWidth + 'px',
            top: y * containerHeight - 1 + 'px',
          }"
        />
      </template>

      <div
        v-for="y in snapLinesToDisplay.y"
        :key="y"
        class="absolute h-px bg-sky-500"
        :style="{
          left: (bounds?.left ?? 0) * containerWidth + 'px',
          width: (1 + ((bounds?.right ?? 0) - 1) - (bounds?.left ?? 0)) * containerWidth + 'px',
          top: y * containerHeight - 1 + 'px',
        }"
      />
    </template>
  </Teleport>
</template>

<style scoped lang="scss"></style>
