/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type {
  CreateUploadedVideoDto,
  CreateUploadedVideoResponseDtoApiResponse,
  UpdateUploadedVideoDto,
  UploadedVideoApiResponse,
  UploadedVideoListApiResponse,
} from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const postApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  createUploadedVideoDto: MaybeRef<CreateUploadedVideoDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  videoId = unref(videoId)
  createUploadedVideoDto = unref(createUploadedVideoDto)

  return streamLadderAxiosInstance<CreateUploadedVideoResponseDtoApiResponse>(
    {
      url: `/api/UploadedVideos/${videoId}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUploadedVideoDto,
    },
    options
  )
}

export const getPostApiUploadedVideosVideoIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: CreateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
  TError,
  { videoId: string; data: CreateUploadedVideoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
    { videoId: string; data: CreateUploadedVideoDto }
  > = (props) => {
    const { videoId, data } = props ?? {}

    return postApiUploadedVideosVideoId(videoId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiUploadedVideosVideoIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>
>
export type PostApiUploadedVideosVideoIdMutationBody = CreateUploadedVideoDto
export type PostApiUploadedVideosVideoIdMutationError = void

export const usePostApiUploadedVideosVideoId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: CreateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
  TError,
  { videoId: string; data: CreateUploadedVideoDto },
  TContext
> => {
  const mutationOptions = getPostApiUploadedVideosVideoIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  videoId = unref(videoId)

  return streamLadderAxiosInstance<UploadedVideoApiResponse>(
    { url: `/api/UploadedVideos/${videoId}`, method: 'GET', signal },
    options
  )
}

export const getGetApiUploadedVideosVideoIdQueryKey = (videoId: MaybeRef<string>) => {
  return ['api', 'UploadedVideos', videoId] as const
}

export const getGetApiUploadedVideosVideoIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>,
  TError = void
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUploadedVideosVideoIdQueryKey(videoId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>> = ({ signal }) =>
    getApiUploadedVideosVideoId(videoId, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(videoId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>, TError, TData>
}

export type GetApiUploadedVideosVideoIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>
>
export type GetApiUploadedVideosVideoIdQueryError = void

export const useGetApiUploadedVideosVideoId = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>,
  TError = void
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUploadedVideosVideoIdQueryOptions(videoId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const putApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  updateUploadedVideoDto: MaybeRef<UpdateUploadedVideoDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  videoId = unref(videoId)
  updateUploadedVideoDto = unref(updateUploadedVideoDto)

  return streamLadderAxiosInstance<UploadedVideoApiResponse>(
    {
      url: `/api/UploadedVideos/${videoId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateUploadedVideoDto,
    },
    options
  )
}

export const getPutApiUploadedVideosVideoIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: UpdateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
  TError,
  { videoId: string; data: UpdateUploadedVideoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
    { videoId: string; data: UpdateUploadedVideoDto }
  > = (props) => {
    const { videoId, data } = props ?? {}

    return putApiUploadedVideosVideoId(videoId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiUploadedVideosVideoIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>
>
export type PutApiUploadedVideosVideoIdMutationBody = UpdateUploadedVideoDto
export type PutApiUploadedVideosVideoIdMutationError = void

export const usePutApiUploadedVideosVideoId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: UpdateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
  TError,
  { videoId: string; data: UpdateUploadedVideoDto },
  TContext
> => {
  const mutationOptions = getPutApiUploadedVideosVideoIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  videoId = unref(videoId)

  return streamLadderAxiosInstance<void>({ url: `/api/UploadedVideos/${videoId}`, method: 'DELETE' }, options)
}

export const getDeleteApiUploadedVideosVideoIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
    TError,
    { videoId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
  TError,
  { videoId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
    { videoId: string }
  > = (props) => {
    const { videoId } = props ?? {}

    return deleteApiUploadedVideosVideoId(videoId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiUploadedVideosVideoIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>
>

export type DeleteApiUploadedVideosVideoIdMutationError = void

export const useDeleteApiUploadedVideosVideoId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
    TError,
    { videoId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
  TError,
  { videoId: string },
  TContext
> => {
  const mutationOptions = getDeleteApiUploadedVideosVideoIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiUploadedVideos = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<UploadedVideoListApiResponse>(
    { url: `/api/UploadedVideos`, method: 'GET', signal },
    options
  )
}

export const getGetApiUploadedVideosQueryKey = () => {
  return ['api', 'UploadedVideos'] as const
}

export const getGetApiUploadedVideosQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideos>>,
  TError = void
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideos>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUploadedVideosQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUploadedVideos>>> = ({ signal }) =>
    getApiUploadedVideos(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiUploadedVideos>>,
    TError,
    TData
  >
}

export type GetApiUploadedVideosQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUploadedVideos>>>
export type GetApiUploadedVideosQueryError = void

export const useGetApiUploadedVideos = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideos>>,
  TError = void
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideos>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUploadedVideosQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
