<script lang="ts" setup>
import compatabilityChecker from '@/services/compatabilityChecker.js'
import { computed, ref } from 'vue'
import type { DayCellContentArg } from '@fullcalendar/common'
import { isSameDay } from 'date-fns'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { useScreen } from '@/Hooks/useScreen'
import IconSaxCalendarAdd from '@/components/Icons/iconsax/IconSaxCalendarAdd.vue'

const props = defineProps<{ content: DayCellContentArg }>()

const container = ref<HTMLDivElement>()

const store = useContentPublisherStore()
const hasScheduledAnyVideoOnThisDay = computed(() => {
  const thisDate = props.content.date
  return store.events.some((ue) => {
    if (!ue.date) return false
    const eventDate = new Date(ue.date)
    return isSameDay(thisDate, eventDate)
  })
})

const isLargeScreen = useScreen('lg')
const showOverlay = computed(() => {
  const { isPast, isDisabled } = props.content
  return !isPast && !isDisabled && isLargeScreen.value && !compatabilityChecker.isMobile()
})
</script>
<template>
  <div ref="container">
    <span
      :class="{ 'bg-cyan-600 text-white': isSameDay(content.date, new Date()) }"
      class="absolute right-0 top-0 flex h-8 w-8 items-center justify-center rounded-bl-lg rounded-tr-lg text-right text-xs"
      data-hide-if-week-view
    >
      {{ content.dayNumberText }}
    </span>

    <span v-if="showOverlay" class="fc-daygrid-day-overlay invisible absolute -inset-0.5">
      <span
        v-if="!hasScheduledAnyVideoOnThisDay"
        class="user-schedule-help-text flex h-full w-full flex-col items-center justify-center gap-1 text-center text-xs"
      >
        <IconSaxCalendarAdd />
        <span class="hidden md:inline">Schedule a video</span>
      </span>
    </span>
  </div>
</template>
