import { ref } from 'vue'
import { useClipUrlValidator } from '@/Hooks/clip-form/useClipUrlValidator'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRouter } from 'vue-router';

export function useClipFormManager() {
  
  const error = ref<{ message: string; title: string } | null>(null)
  const resetEditor = useResetEditor()
  const validateClipUrl = useClipUrlValidator()
  const router = useRouter()

  const youtubeVideoId = ref<string | null>(null)

  async function submitClipUrl(clipUrl: string | undefined) {
    const validationResult = validateClipUrl(clipUrl)
    error.value = validationResult.error
    youtubeVideoId.value = validationResult.youtubeVideoId ?? null

    if (validationResult.route) {
      resetEditor()
      const params = validationResult.route.params
      await router.push({ name: 'editor/[clipSource]/[clipId]', params: { clipSource: params.source, clipId: params.clipId } })
    }
  }

  return { error, youtubeVideoId, submitClipUrl }
}
