<script setup lang="ts">
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import { guessBranchPreviewUrl } from '@/components/Debug/guessBranchPreviewUrl'
import { isInputTarget } from '@/components/Editor/Timeline/helpers'
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon.vue'
import IconSaxBarcode from '@/components/Icons/iconsax/IconSaxBarcode.vue'
import IconSaxClipboardExport from '@/components/Icons/iconsax/IconSaxClipboardExport.vue'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Switch } from '@/components/ui/switch'
import { tiers } from '@/enums/tiers'
import EventBus from '@/eventBus'
import ToastEvents from '@/events/toastEvents'
import unwrap from '@/helpers/unwrap'
import { useDeploymentVersion } from '@/Hooks/useDeploymentVersion'
import { FEATURE_FLAGS, useFeatureFlags } from '@/Hooks/useFeatureFlagEnabled'
import { useScreenName } from '@/Hooks/useScreen'
import { useTheme } from '@/Hooks/useTheme'
import { useUserInfoStore } from '@/store/user/userInfo'
import { RiveDefinitions } from '@/webcodec-renderer/rive/rive-definitions'
import { useClipboard } from '@vueuse/core'
import { useStorage } from '@vueuse/core/index'
import { orderBy } from 'lodash-es'
import QRious from 'qrious'
import { onMounted, onUnmounted, ref, watch } from 'vue'

const userInfoStore = useUserInfoStore()

const show = useStorage('debugPopup-open', false)
const riveDebug = ref(false);

watch(
  () => userInfoStore.tier,
  (newVal) => {
    userInfoStore.hasSubscription = newVal > 0
    userInfoStore.plan = tiers.toString(newVal)
  }
)

const { copy } = useClipboard()
const featureFlags = useFeatureFlags()

const colorModes = ['light', 'dark', 'auto'] as const
const { colorMode } = useTheme()

function onKeyDown(e: Event) {

  if (isInputTarget(e)) return
  
  if (e instanceof KeyboardEvent) {
    if (e.key === 'Escape') {
      show.value = false
    }

    if (e.key === 'L' && e.shiftKey) {
      const newMode = colorModes[(colorModes.indexOf(colorMode.value) + 1) % colorModes.length]
      colorMode.value = newMode
      EventBus.$emit(ToastEvents.TOAST, {
        title: `Color mode changed to "${newMode}"`,
        timeout: 5000
      });
    }
  }
}

const url = guessBranchPreviewUrl()
const qr = ref<HTMLCanvasElement | null>(null)

onMounted(() => {
  if (qr.value && url) {
    qr.value.innerHTML = ''
    new QRious({
      element: qr.value,
      value: url,
      size: 400,
    })
  }
  
  window.addEventListener('keydown', onKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', onKeyDown)
})

const { data: deployment } = useDeploymentVersion()
const isQrOpen = ref(false)

const screenSize = useScreenName()



const riveDefinition = reactive({
  animationDuration: 0,
  heightOffset: 0,
  minWidth: 10,
  widthOffset: 0,
  xOffset: 0,
  yOffset: 0,
  minHeight: 10,
});


watch(riveDebug, () => {
// @ts-ignore
  Object.assign(riveDefinition, window.riveDefinition)
})

watch(() => riveDefinition, () => {
  // @ts-ignore
  Object.assign(window.riveDefinition ,riveDefinition)
}, { deep: true })

const sortedFeatureKeys = orderBy(unwrap.keys(FEATURE_FLAGS), (key) => typeof featureFlags[key]);

async function copyRiveDefinitionToClipboard() {
  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(JSON.stringify(riveDefinition, null, 2));
    }
  } catch (err) {
    console.error(err);
  }
}


function getActiveRiveDefinition() {
  //@ts-ignore
  return Object.entries(RiveDefinitions).find(([, entry]) => entry === window.riveDefinition)?.[0]
}

</script>

<template>
  <Teleport to="body">
    <div
      class="fixed inset-y-0 z-50 flex h-full flex-col transition-[left] layer-1 w-96 drop-shadow-xl"
      :class="{
        '-left-96': !show,
        'left-0': show,
      }"
    >
      <Button variant="ghost" class="absolute left-full top-0 layer-1 p-2 rounded-none" @click="show = !show">
        <ArrowRightIcon class="h-4 w-4 transition" :class="show ? 'rotate-180' : ''" />
      </Button>
      <ScrollArea class="h-full min-h-0">
        <div class="h-full p-4 flex flex-col gap-2">
          <div class="flex flex-col w-full text-xs p-2 opacity-50">
            <h4 class="text-sm">Deployment</h4>
            <pre>Latest: {{ deployment?.latestSha }}</pre>
            <pre v-if="deployment?.hasUpdateReady">Current: {{ deployment?.currentJsSha }}</pre>
          </div>
    
          <section class="p-2 flex flex-col gap-4">
            <header>
              <h4 class="text-lg">General</h4>
              <pre class="font-light opacity-50 text-xs text-left">Screen size: {{ screenSize }}</pre>
            </header>
            
            <div class="flex flex-col gap-1">
              <label class="text-sm">Username</label>
              <Input type="text" v-model="userInfoStore.userName" />
            </div>
      
            <div class="flex flex-col gap-1">
              <label class="text-sm">User Tier</label>
              <LightSwitch
                class="layer-2 mr-auto"
                label-classes="text-white"
                :options="[
                  { label: 'Free', value: tiers.FREE.toString() },
                  { label: 'Silver', value: tiers.SILVER.toString() },
                  { label: 'Gold', value: tiers.GOLD.toString() },
                ]"
                :value="userInfoStore.tier.toString()"
                @update:value="v => userInfoStore.tier = Number(v)"
              />
            </div>
      
            <div class="flex flex-col gap-1">
              <label class="text-sm">Color Mode</label>
              <LightSwitch
                class="layer-2 mr-auto"
                label-classes="text-white"
                :options="[
                  { label: 'Light', value: 'light' },
                  { label: 'Dark', value: 'dark' },
                  { label: 'Auto', value: 'auto' },
                ]"
                v-model:value="colorMode"
              />
            </div>

            <div class="flex flex-col gap-1">
              <Button @click="riveDebug = !riveDebug">Toggle Rive debug</Button>

              <div v-if="riveDebug" class="w-full flex flex-col gap-4">

                <div>
                  {{ getActiveRiveDefinition() }}
                </div>

                <div>
                  <label class="text-sm">Animation duration</label>
                  <div class="flex w-full"><input class="w-full" min="0" max="10" type="range" v-model.number="riveDefinition.animationDuration" /> <span class="pl-2">{{ riveDefinition.animationDuration }}s</span></div>
                </div>

                <div>
                  <label class="text-sm">Min Width</label>
                  <div class="flex w-full"><input class="w-full" min="0" max="1000" type="range" v-model.number="riveDefinition.minWidth" /> <span class="pl-2">{{ riveDefinition.minWidth }}px</span></div>
                </div>

                <div>
                  <label class="text-sm">Min Height</label>
                  <div class="flex w-full"><input class="w-full" min="-300" max="600" type="range" v-model.number="riveDefinition.minHeight" /> <span class="pl-2">{{ riveDefinition.minHeight }}px</span></div>
                </div>

                <div>
                  <label class="text-sm">Width offset</label>
                  <div class="flex w-full"><input class="w-full" min="-300" max="600" type="range" v-model.number="riveDefinition.widthOffset" /> <span class="pl-2">{{ riveDefinition.widthOffset }}px</span></div>
                </div>

                <div>
                  <label class="text-sm">Height offset</label>
                  <div class="flex w-full"><input class="w-full" min="-300" max="600" type="range" v-model.number="riveDefinition.heightOffset" /> <span class="pl-2">{{ riveDefinition.heightOffset }}px</span></div>
                </div>

                <div>
                  <label class="text-sm">X offset</label>
                  <div class="flex w-full"><input class="w-full" min="-300" max="600" type="range" v-model.number="riveDefinition.xOffset" /> <span class="pl-2">{{ riveDefinition.xOffset }}px</span></div>
                </div>

                <div>
                  <label class="text-sm">Y offset</label>
                  <div class="flex w-full"><input class="w-full" min="-300" max="600" type="range" v-model.number="riveDefinition.yOffset" /> <span class="pl-2">{{ riveDefinition.yOffset }}px</span></div>
                </div>

                <div>
                  <Button @click="copyRiveDefinitionToClipboard()">Copy</Button>
                </div>

              </div>
            </div>
          </section>
    
          <hr class="my-4 border-zinc-500" />
    
          <section class="p-2 flex flex-col gap-2">
            <h4 class="text-lg">Feature flags</h4>
            <template v-for="(key, index) in sortedFeatureKeys" :key="key">
              <div class="flex gap-2 items-center py-1" v-if="typeof featureFlags[key] === 'boolean'">
                <Switch v-model:checked="featureFlags[key]" :id="key" />
                <label class="text-sm" :for="key">{{ key }}</label>
              </div>
              <div v-else class="flex flex-col gap-1 py-1">
                <label class="text-sm">{{ key }}</label>
                <SelectDropdown v-model="featureFlags[key]" :options="FEATURE_FLAGS[key].variants.map((v) => ({ label: v, value: v }))" />
              </div>
              <hr v-if="index < sortedFeatureKeys.length - 1" />
            </template>
            <div
              class="form-control"
              v-for="key in Object.keys(featureFlags).filter((k) => !Object.keys(FEATURE_FLAGS).includes(k))"
              :key="key"
            >
              <label class="label flex cursor-pointer flex-col items-stretch space-y-2">
                <span class="label-text text-brand-state-text-primary">{{ key }}</span>
                <template v-if="typeof featureFlags[key] === 'boolean'">
                  <input type="checkbox" v-model="featureFlags[key]" :value="key" class="toggle checked:bg-blue-400" />
                </template>
                <input v-else type="text" v-model="featureFlags[key]" class="input input-sm" />
              </label>
            </div>
          </section>
        </div>
      </ScrollArea>
  
      <template v-if="url !== null">
        <div class="m-4 mb-1 transition-[height] overflow-hidden shrink-0 grow-0" :class="isQrOpen ? 'h-[22rem]' : 'h-0'">
          <canvas ref="qr" class="w-full h-auto" />
        </div>
        <div class="flex w-full flex-row gap-2 p-2">
          <Button variant="ghost" size="square" @click="isQrOpen = !isQrOpen">
            <IconSaxBarcode />
          </Button>
          <Button as="a" :href="url" target="_blank" rel="noopener noreferrer" class="btn-primary btn flex-grow">
            Open branch on server
          </Button>
          <Button variant="ghost" size="square" @click="copy(url)">
            <IconSaxClipboardExport />
          </Button>
        </div>
      </template>
    </div>
  </Teleport>
</template>
