<script setup lang="ts">
import GifsSection from '@/areas/editor/views/elements/sections/GifsSection.vue'
import SocialStickersSection from '@/areas/editor/views/elements/sections/SocialStickersSection.vue'
import TextStickersSection from '@/areas/editor/views/elements/sections/TextStickersSection.vue'
import CustomStickersSection from '@/areas/editor/views/elements/sections/CustomStickersSection.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import TwitchEmotesSection from '@/areas/editor/views/elements/sections/TwitchEmotesSection.vue'
import RiveStickersSection from '@/areas/editor/views/elements/sections/RiveStickersSection.vue'

const userInfoStore = useUserInfoStore()
</script>

<template>
  <div class="min-h-0 overflow-y-auto h-full lg:max-h-screen lg:pb-24">

    <div class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 lg:pb-4">
      <header>
        <h2 class="text-xl font-semibold leading-snug font-title">Elements</h2>
        <p class="font-light">
          Add elements to increase engagement
        </p>
      </header>

      <template v-if="userInfoStore.isLoggedIn">
        <hr />
        <CustomStickersSection />
      </template>

      <hr />
      <RiveStickersSection />

      <hr />
      <SocialStickersSection />
      <hr />
      <TextStickersSection />
      <hr />
      <GifsSection />
      <hr />
      <TwitchEmotesSection />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
