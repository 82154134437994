<script setup lang="ts">
import { isNewSticker, lastUsedStickerFirst, newestStickerFirst } from '@/helpers/isNewOverlayElement'
import { capitalize, ref, watch, computed } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import Tile from '@/components/Tile.vue'
import { Switch } from '@/components/ui/switch'
import StickerWrapper from '@/areas/editor/views/stickers/StickerWrapper.vue'
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import { useLocalStorage } from '@vueuse/core'
import { useUserInfoStore, onUserInfoReady } from '@/store/user/userInfo'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { Input } from '@/components/ui/input'
import { IconAdjustmentsHorizontal, IconArrowRight } from '@tabler/icons-vue'
import { Dialog, DialogClose, DialogTrigger, DialogContent } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import type { TypedSticker } from "@/areas/editor/@type/Project";
import { useStickersStore } from "@/areas/editor/store/useStickersStore";
import { useHistoryStore } from "@/areas/editor/store/useHistoryStore";
import logging from "@/logging";
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { setLastUsedSocialStickerKey } from '@/areas/editor/views/elements/useElementsSettings'

defineProps<{
  hideBackButton?: boolean
}>();

const selectedFilter = ref()

const userInfoStore = useUserInfoStore()
const stickerText = ref()

const clipInfoStore = useEditorClipInfoStore()
watch(() => clipInfoStore.source, (src) => {
  selectedFilter.value = selectFilterByClipSource(src as 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'local-file')
}, { immediate: true })

function selectFilterByClipSource(source: 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'local-file') {
  switch (source) {
    case 'youtube-clip':
      return 'youtube'
    case 'kick-clip':
      return 'kick'
    case 'twitch-clip':
    case 'twitch-vod':
    default:
      return 'twitch'
  }
}

onUserInfoReady(() => {
  if (!stickerText.value) {
    stickerText.value = userInfoStore.userName || 'StreamLadder'
  }
})

const stickers = computed(() => {
  return [...stickerLibrary]
    .sort(newestStickerFirst)
    .sort(lastUsedStickerFirst)
    .filter(({ tags }) => tags.includes(selectedFilter.value as never));
})

const filters = [
  { key: 'twitch', name: 'Twitch' },
  { key: 'halloween', name: '🎃 Halloween' },
  { key: 'youtube', name: 'YouTube' },
  { key: 'kick', name: 'Kick' },
  { key: 'facebook', name: 'Facebook' },
  { key: 'multiple', name: 'Multiple socials' },
].filter((f) => stickerLibrary.some((s) => s.tags.includes(f.key)))

const socials = useLocalStorage('multi-sticker-socials', [
  { key: 'twitch', label: 'Twitch', selected: true },
  { key: 'kick', label: 'Kick', selected: false },
  { key: 'tiktok', label: 'TikTok', selected: false },
  { key: 'instagram', label: 'Instagram', selected: true },
  { key: 'youtube', label: 'YouTube', selected: true },
  { key: 'facebook', label: 'Facebook', selected: false },
])

const activeSocials = computed(() => {
  return socials.value.filter((s) => s.selected).map((s) => s.key)
})

const stickersStore = useStickersStore()
const historyStore = useHistoryStore()
const editorFocusStore = useEditorFocusStore()

async function select(sticker: TypedSticker<'social'>) {

  const id = stickersStore.createSocialSticker(sticker)
  editorFocusStore.setFocus(FocusTypes.STICKER, id)

  const meta = stickerLibrary.find((s) => s.key === sticker.key)!
  const isMultiSticker = 'tags' in meta && meta.tags && (meta.tags as string[]).includes('multiple')
  if (isMultiSticker) {
    logging.trackEvent('Editor MultiSticker Added', {
      Color: sticker.color,
      Component: meta.component,
      socials: activeSocials.value,
      Tags: meta.tags,
    })
  }

  setLastUsedSocialStickerKey(sticker.key);

  logging.trackEvent('Editor Sticker Added', {
    Color: sticker.color,
    Component: meta.component,
    Icon: 'icon' in meta ? meta.icon : undefined,
    Tags: meta.tags,
  })
}

const { currentSection } = useEditorStep()
</script>

<template>
  <section class="flex flex-col gap-4 w-full h-full p-4 pb-0 2xl:p-8 max-h-screen">

    <div v-if="!hideBackButton" class="flex items-center gap-2 cursor-pointer hover:-translate-x-0.5 transition-transform text-brand-state-link hover:underline underline-offset-2" @click="currentSection = null">
      <IconSaxArrowLeft class="w-4 h-4" />
      <span class="text-xl lg:text-sm mt-0.5 select-none">Back to Elements</span>
    </div>

    <div class="flex flex-col gap-2">
      <div class="w-full min-w-0 overflow-x-auto overflow-y-hidden">
        <div class="flex flex-row gap-1 m-2">
          <RadioToggleButton
            v-for="filter in filters" :key="filter.key" :value="filter.key" v-model="selectedFilter"
            size="sm" class="flex-col relative font-light data-[state=active]:font-semibold  shrink-0"
          >
            <span class="absolute inset-auto">{{ capitalize(filter.name) }}</span>
            <span class="font-semibold invisible">{{ capitalize(filter.name) }}</span>
          </RadioToggleButton>
        </div>
      </div>
      <Input class="min-w-0" type="text" ref="stickerTextInp" v-model="stickerText" />
    </div>

    <ScrollArea class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8">
      <div class="grid w-full gap-2 notranslate grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))]">
        <Dialog v-if="selectedFilter === 'multiple'">
          <DialogTrigger>
            <Tile :aspect=".6" class="text-current active:scale-90 transition-[transform,_border-color,_background-color]">
              <IconAdjustmentsHorizontal />
              <span>Edit socials</span>
            </Tile>
          </DialogTrigger>
          <DialogContent>
            <h4>Manage your social icons</h4>
            <p class="text-opacity-50">Select which social platforms you wish to display in your sticker</p>
            <div class="mt-4 flex flex-col gap-2">
              <label class="flex items-center gap-2 cursor-pointer" v-for="social in socials" :key="social.key" :for="social.key">
                <Switch :id="social.key" v-model:checked="social.selected" />
                <span class="text-lg">{{ social.label }}</span>
              </label>
            </div>

            <footer class="flex items-center justify-between">
              <DialogClose>
                <Button variant="depressed">
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose>
                <Button variant="gradient">
                  Save <IconArrowRight class="transition group-hover:translate-x-2" />
                </Button>
              </DialogClose>
            </footer>
          </DialogContent>
        </Dialog>

        <StickerWrapper
          ref="stickerWrappers"
          v-for="sticker in stickers"
          :key="sticker.key"
          :stickerMeta="sticker"
          :is-new="isNewSticker(sticker)"
          :editable="false"
          :text-content="stickerText || '&nbsp;'"
          :active-socials="activeSocials"
          @select="(s) => select(s as TypedSticker<'social'>)"
        />
      </div>
    </ScrollArea>
  </section>
</template>

<style scoped lang="scss">

</style>
