import { acceptHMRUpdate, defineStore } from 'pinia'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useEffectsStore } from "@/areas/editor/store/useEffectsStore";
import { v4 as uuid } from 'uuid'
import { useCurrentCaption } from '@/areas/editor/views/captions/useCurrentCaption'
import logging from '@/logging'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useProjectStore } from '@/areas/editor/store/useProjectStore';

export const FocusTypes = {
  NONE: 'none',
  TEXTSTICKER: 'textsticker',
  CAPTION: 'caption',
  FEED: 'feed',
  SEGMENT: 'segment',
  STICKER: 'sticker',
  ZOOM: 'zoom',
  CROP: 'crop',
  SOUND: 'sound',
  RIVE: 'rive',
} as const
export type FocusType = (typeof FocusTypes)[keyof typeof FocusTypes]

type FocusTarget = {
  type: FocusType
  key?: string
}

interface EditorFocusState {
  focus: FocusTarget | null
  selectedCaptions: string[]
}

export const useEditorFocusStore = defineStore('editorFocus', {
  state: (): EditorFocusState => {
    return {
      focus: null,
      selectedCaptions: [],
    }
  },
  getters: {
    canDeleteFocusModel(state) {

      if (state.focus?.type === FocusTypes.FEED) return false
      if (state.focus?.type === FocusTypes.CROP) return false

      const editorFeedDataStore = useEditorFeedDataStore()
      const segmentsStore = useSegmentsStore()

      if (state.focus?.type === FocusTypes.SEGMENT && state.focus.key && (editorFeedDataStore.segments.length === 1 || segmentsStore.ids.length === 1)) {
        return false
      }

      return state.focus?.key !== undefined
    },
  },
  actions: {
    setFocus(type: FocusType, key?: string) {
      // console.trace('focus', type, key)
      this.focus = { type, key }
      if (type === FocusTypes.CAPTION && key) {
        this.selectedCaptions = [key]
      }
    },
    unFocus() {
      // console.trace('unFocus')
      this.focus = null
    },
    deleteFocusModel() {
      const historyStore = useHistoryStore()
      const projectStore = useProjectStore()

      if (this.focus?.type === FocusTypes.CAPTION && this.focus.key) {
        if (projectStore.useLegacyCaptions) {
          const editorCaptionsStore = useEditorCaptionsStore();
          const caption = editorCaptionsStore.captions.find((caption) => caption.id === this.focus!.key)!;
          if (caption.words.length === 1) {
            editorCaptionsStore.deleteCaption(this.focus.key);
          } else {
            const { currentWordId } = useCurrentCaption();
            editorCaptionsStore.updateCaption(caption.id, {
              ...caption,
              words: caption.words.filter((word) => word.id !== currentWordId.value),
            });
          }
        } else {
          const captionsStore = useCaptionsStore();
          captionsStore.deleteWordById(this.focus.key);
        }
        this.unFocus()
        historyStore.transaction('CAPTIONS:DELETE')
      }
      if ((this.focus?.type === FocusTypes.STICKER || this.focus?.type === FocusTypes.TEXTSTICKER || this.focus?.type === FocusTypes.RIVE) && this.focus.key) {
        const stickersStore = useStickersStore()
        stickersStore.removeById(this.focus.key)
        const nextSticker = stickersStore.ids[stickersStore.ids.length - 1]
        if (nextSticker) {
          stickersStore.focus(nextSticker)
        }
        historyStore.transaction('STICKER:DELETE')
      }

      if (this.focus?.type === FocusTypes.SEGMENT && this.focus.key) {
        const segmentsStore = useSegmentsStore()
        const segments = segmentsStore.whereIsNotZoom()
        if (segments.value.length > 1) {
          segmentsStore.removeById(this.focus.key)
          historyStore.transaction('SEGMENT:DELETE')
        }
        this.unFocus()
      }
      if (this.focus?.type === FocusTypes.ZOOM && this.focus.key) {
        const effectsStore = useEffectsStore()
        const segmentsStore = useSegmentsStore()
        const effect = effectsStore.selectById(this.focus.key)
        segmentsStore.removeById(effect.segmentId)
        effectsStore.removeById(effect.id)
        historyStore.transaction('ZOOM:DELETE')
        this.unFocus()
      }
      if (this.focus?.type === FocusTypes.SOUND && this.focus.key) {

        const effectsStore = useEffectsStore()
        const effect = effectsStore.selectById(this.focus.key)
        effectsStore.removeById(effect.id)
        historyStore.transaction('SOUND_EFFECT:DELETE')

        this.unFocus()
      }
    },
    splitFocusModel(timeMs: number, fromMultipleLayoutsCard = false) {
      
      const historyStore = useHistoryStore()

      logging.trackEvent('Editor Split Added', {
        fromMultipleLayoutsCard: fromMultipleLayoutsCard,
      });

      if (!this.focus) {
        const editorFocusStore = useEditorFocusStore();
        const currentSegment = useCurrentSegment();
        const currentSegmentId = currentSegment.value.id;
        editorFocusStore.setFocus('segment', currentSegmentId);
      }

      if (this.focus?.type === FocusTypes.SEGMENT && this.focus.key) {
        const segmentsStore = useSegmentsStore()
        segmentsStore.splitAt('layout', timeMs)
        historyStore.transaction('SEGMENT:SPLIT')
      }

      if (this.focus?.type === FocusTypes.CROP && this.focus.key) {
        const segmentsStore = useSegmentsStore()
        segmentsStore.splitAt('layout', timeMs)
        historyStore.transaction('SEGMENT:SPLIT')
      }

      if (this.focus?.type === FocusTypes.ZOOM && this.focus.key) {
        const segmentsStore = useSegmentsStore()
        const newSegmentId = segmentsStore.splitAt('zoom', timeMs)
        if (newSegmentId) {
          const effectsStore = useEffectsStore()
          const effect = effectsStore.selectById(this.focus.key)
          effectsStore.createById(uuid(), {
            ...effect,
            segmentId: newSegmentId,
          })
        }

        historyStore.transaction('ZOOM:SPLIT')
      }

      if (this.focus?.type === FocusTypes.STICKER || this.focus?.type === FocusTypes.TEXTSTICKER) {
        const newStickersStore = useStickersStore()
        newStickersStore.splitStickerById(this.focus.key, timeMs)
        historyStore.transaction('STICKER:SPLIT')
      }
    },
    pullModel(ms: number, direction: 'left' | 'right') {
      if (!this.focus?.key) return
      const historyStore = useHistoryStore()
      if (this.focus.type === FocusTypes.SEGMENT) {
        const segmentsStore = useSegmentsStore()
        segmentsStore.pullSegment(this.focus.key, direction, ms)
        historyStore.transaction('SEGMENT:UPDATE_DURATION')
      }

      if (this.focus.type === FocusTypes.CAPTION) {
        const editorCaptionsStore = useEditorCaptionsStore()
        const caption = editorCaptionsStore.captions.find((caption) => caption.id === this.focus.key)
        if (!caption) return
        const newCaption = findAndPull(caption, direction, ms)
        if (newCaption) {
          editorCaptionsStore.updateCaption(newCaption.id, newCaption)
        }
      }

      if (this.focus.type === FocusTypes.ZOOM) {
        const effectsStore = useEffectsStore();
        const segmentsStore = useSegmentsStore();
        const effect = effectsStore.selectById(this.focus.key);
        segmentsStore.pullSegment(effect.segmentId, direction, ms);
        historyStore.transaction('ZOOM:UPDATE_DURATION')
      }

      if (this.focus.type === FocusTypes.STICKER || this.focus.type === FocusTypes.TEXTSTICKER) {
        const stickersStore = useStickersStore()
        stickersStore.pullSticker(this.focus.key, direction, ms)
        historyStore.transaction('STICKER:UPDATE_DURATION')
      }
    },
  },
})

const findAndPull = (
  model: {
    start: number
    end: number
    id: string
  },
  direction: 'left' | 'right',
  targetMs: number
) => {
  const alignLeft = direction === 'left'
  return {
    start: alignLeft ? model.start : targetMs,
    end: alignLeft ? targetMs : model.end,
    id: model.id,
  }
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorFocusStore, import.meta.hot))
}
