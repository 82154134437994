<script setup lang="ts">
import { useLocalStorage } from '@vueuse/core';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { upgradeDialog } from '@/helpers/upgradeDialog';
import { useBlackFriday } from '@/Hooks/dates/useBlackFriday'
import { useUserInfoStore } from '@/store/user/userInfo';

const isOpen = useLocalStorage('black-friday-dialog', true);
const { showBlackFridayDeal } = useBlackFriday();

const userInfoStore = useUserInfoStore();
</script>

<template>
  <Dialog v-model:open="isOpen" title="Black Friday Deal" v-if="showBlackFridayDeal && userInfoStore.isLoggedIn">
    <DialogContent :z-index="1000" class="max-w-5xl p-0">
      <div class="grid grid-cols-1 grid-rows-[1fr,_auto] md:grid-cols-2 md:grid-rows-1">
        <div class="flex flex-col h-full p-6 gap-4 flex-1">
          <DialogHeader>
            <DialogTitle class="font-title text-3xl font-bold uppercase">
              This black friday, unlock the ultimate deal
            </DialogTitle>
            <DialogDescription class="mt-3 font-light text-lg text-foreground">
              Get AI Clipping, a Content Publisher for all your socials, lightning-fast rendering, dynamic effects and much more- all at 50% OFF!
            </DialogDescription>
          </DialogHeader>

          <p class="font-light text-lg text-foreground mb-auto">
            Go all out on features, without breaking the bank. Don't miss out!
          </p>

          <DialogFooter class="border-t pt-4">
            <DialogClose as-child>
              <Button class="uppercase" @click="upgradeDialog.open({ reason: 'Black Friday Popup Dialog', intentional: true })">
                Get the deal now
              </Button>
            </DialogClose>
          </DialogFooter>
        </div>
        <img class="h-full w-full object-cover aspect-square" src="/black-friday/popup.jpg" alt="50% off black friday deal" />
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
