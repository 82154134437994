import type { MaybeRef } from '@vueuse/shared'
import { useWebsocketChannel } from '@/modules/WebsocketService/WebSocketService'
import { computed, ref } from 'vue'
import { match } from 'ts-pattern'
import { toValue } from 'vue'

type RenderStatus = 'finished' | 'error' | 'progress' | 'idle' | 'endless-progress'
type Status = 'idle' | 'in-progress' | 'success' | 'error' | 'in-endless-progress'

interface VideoImport {
  status: RenderStatus
}

interface OverlayRender {
  status: RenderStatus
  percentage: number
}

interface VideoRender {
  status: RenderStatus
  percentage: number
}

interface VideoUpload {
  status: RenderStatus
}

interface PreProcess {
  status: RenderStatus
  percentage: number
}

type ProgressEvent = {
  overallStatus: RenderStatus
  videoImport: VideoImport
  overlayRender: OverlayRender
  videoRender: VideoRender
  videoUpload: VideoUpload
  preProcess: PreProcess
}

export const useLiveRenderStatus = (id: MaybeRef<string>) => {

  const overlayStatus = ref<Status>('idle')
  const overlayPercentage = ref(100)

  const uploadStatus = ref<Status>('in-endless-progress')
  const videoUploadStatus = ref<Status>('idle')

  const renderStatus = ref<Status>('in-endless-progress')
  const renderPercentage = ref(0)

  const preprocessStatus = ref<Status>('idle');
  const preprocessPercentage = ref(0);

  const isFinished = ref(false)

  useWebsocketChannel(
    computed(() => `cache-renders-${toValue(id)}`),
    (eventName, data) => {
      if (eventName === 'progress') {
        const progressData = data as ProgressEvent
        try {

          if (progressData.preProcess) {
            match(progressData.preProcess)
            .with({ status: 'progress' }, () => {
              preprocessStatus.value = 'in-progress'
              preprocessPercentage.value = progressData.preProcess.percentage
            })
            .with({ status: 'finished' }, () => {
              preprocessStatus.value = 'success'
            })
            .with({ status: 'idle' }, () => {
              preprocessStatus.value = 'idle'
            })
            .with({ status: 'error' }, () => {
              preprocessStatus.value = 'error'
            })
            .with({ status: 'endless-progress' }, () => {
              preprocessStatus.value = 'in-endless-progress'
            })
            .exhaustive()
          }


          match(progressData.videoUpload)
            .with({ status: 'progress' }, () => {
              videoUploadStatus.value = 'in-progress'
            })
            .with({ status: 'finished' }, () => {
              videoUploadStatus.value = 'success'
            })
            .with({ status: 'idle' }, () => {
              videoUploadStatus.value = 'idle'
            })
            .with({ status: 'error' }, () => {
              videoUploadStatus.value = 'error'
            })
            .with({ status: 'endless-progress' }, () => {
              videoUploadStatus.value = 'in-endless-progress'
            })
            .exhaustive()

          match(progressData.videoImport)
            .with({ status: 'progress' }, () => {
              uploadStatus.value = 'in-progress'
            })
            .with({ status: 'finished' }, () => {
              uploadStatus.value = 'success'
            })
            .with({ status: 'idle' }, () => {
              uploadStatus.value = 'idle'
            })
            .with({ status: 'error' }, () => {
              uploadStatus.value = 'error'
            })
            .with({ status: 'endless-progress' }, () => {
              uploadStatus.value = 'in-endless-progress'
            })
            .exhaustive()

          match(progressData.overlayRender)
            .with({ status: 'progress' }, () => {
              overlayStatus.value = 'in-progress'
              overlayPercentage.value = progressData.overlayRender.percentage
            })
            .with({ status: 'finished' }, () => {
              overlayStatus.value = 'success'
            })
            .with({ status: 'idle' }, () => {
              overlayStatus.value = 'idle'
            })
            .with({ status: 'error' }, () => {
              overlayStatus.value = 'error'
            })
            .with({ status: 'endless-progress' }, () => {
              overlayStatus.value = 'in-endless-progress'
            })
            .exhaustive()

          match(progressData.videoRender)
            .with({ status: 'progress' }, () => {
              renderStatus.value = 'in-progress'
              renderPercentage.value = progressData.videoRender.percentage
            })
            .with({ status: 'finished' }, () => {
              renderStatus.value = 'success'
            })
            .with({ status: 'idle' }, () => {
              renderStatus.value = 'idle'
            })
            .with({ status: 'error' }, () => {
              renderStatus.value = 'error'
            })
            .with({ status: 'endless-progress' }, () => {
              renderStatus.value = 'in-endless-progress'
            })
            .exhaustive()
        } catch (e) {
          console.error('Error parsing progress data from message', e)
        }
      }
      if (eventName === 'finished') {
        renderStatus.value = 'success'
        // force the refetch to get the result url
        isFinished.value = true
      }
    }
  )

  return {
    overlayStatus,
    overlayPercentage,
    uploadStatus,
    renderStatus,
    renderPercentage,
    isFinished,
    videoUploadStatus,
    preprocessStatus,
    preprocessPercentage
  }
}
