<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { ref, onMounted, computed, watch } from 'vue'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import Tile from '@/components/Tile.vue'
import type { CustomSticker as ApiCustomSticker } from '@/apis/streamladder-api/model'
import { Skeleton } from '@/components/ui/skeleton'
import AppImage from '@/areas/editor/components/AppImage.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useScreen } from '@/Hooks/useScreen'
import { Input } from '@/components/ui/input'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { getApiTwitchEmotes } from '@/apis/streamladder-api/twitch-emotes/twitch-emotes'
import { onUserInfoReady } from '@/store/user/userInfo'
import {
  getLastUsedTwitchEmotesUserName, setLastUsedTwitchEmoteFileUrl,
  setLastUsedTwitchEmotesUserName
} from '@/areas/editor/views/elements/useElementsSettings'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import logging from '@/logging'
import { lastUsedTwitchEmoteStickerFirst } from '@/helpers/isNewOverlayElement'
import { uniqBy } from 'lodash-es'

defineProps<{
  hideBackButton?: boolean
}>();

const input = ref<HTMLInputElement | null>(null)
const userName = ref<string>('')

onUserInfoReady(async (state) => {
  const lastUsed = getLastUsedTwitchEmotesUserName();
  if (!lastUsed && state.userName && !userName.value) {
    userName.value = state.userName
  }
});

const stickers = ref<ApiCustomSticker[]>([])
const isFetching = ref(false)
async function fetchStickers() {
  setLastUsedTwitchEmotesUserName(userName.value)
  isFetching.value = true
  stickers.value = await getApiTwitchEmotes({ streamerName: userName.value })
  isFetching.value = false
}

const isDeleting = ref<string[]>([])

onMounted(async () => {
  const lastUsed = getLastUsedTwitchEmotesUserName();
  if (lastUsed) {
    userName.value = lastUsed
    await fetchStickers()
  }
})

const stickersStore = useStickersStore()

async function loadImageByUrl(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject()
    img.src = url
  })
}

const editorFocusStore = useEditorFocusStore()
const historyStore = useHistoryStore()

async function addStickerToProject(sticker: ApiCustomSticker) {
  try {

    const image = await loadImageByUrl(sticker.fileUrl)
    const drawWidth = 0.3 * 1080
    const drawHeight = image.naturalHeight * (drawWidth / image.naturalWidth)

    const id = stickersStore.createCustomSticker({
      imageUrl: sticker.fileUrl,
      naturalHeight: image.naturalHeight,
      naturalWidth: image.naturalWidth,
      area: {
        x: 0.1,
        y: 0.1 + Math.random() * (0.9 - drawHeight / 1920),
        width: drawWidth / 1080,
        height: drawHeight / 1920,
      }
    })

    logging.trackEvent('Editor TwitchEmoteSticker Added', {
      ImageUrl: sticker.fileUrl,
    })

    setLastUsedTwitchEmoteFileUrl(sticker.fileUrl)

    setTimeout(() => editorFocusStore.setFocus(FocusTypes.STICKER, id), 0)
    historyStore.transaction('STICKER:ADD')
  } catch (e) {
    console.error(e)
  }
}

const { currentSection } = useEditorStep()
const viewAll = () => {
  currentSection.value = editorRouteNames.emotes
};

const is2Xl = useScreen('2xl')
const maxItems = computed(() => {
  return is2Xl.value ? 5 : 4;
});

const filteredStickers = computed(() => {
  return uniqBy([...stickers.value]
    .sort(lastUsedTwitchEmoteStickerFirst)
    .slice(0, maxItems.value), 'id');
})

watch(currentSection,async () => {
  const lastUsed = getLastUsedTwitchEmotesUserName();
  if (lastUsed) {
    userName.value = lastUsed;
  }
  await fetchStickers();
}, { immediate: true });
</script>

<template>
  <div class="flex flex-row gap-2 justify-between items-center">
    <div class="flex flex-row gap-2">
      <h2 class="text-base font-semibold leading-snug">Twitch Emotes</h2>
    </div>
    <Button variant="link" class="font-semibold lg:text-sm p-0 h-full" @click="viewAll">
      View all
    </Button>
  </div>

  <form
    class="layer-1 flex items-stretch outline outline-2 outline-transparent transition-all rounded-lg focus-within:outline-offset-2 focus-within:outline-cyan-400 text-sm"
    @submit.prevent.stop="fetchStickers"
  >
    <input
      ref="input"
      v-model="userName"
      class="min-w-0 flex-auto rounded-l-lg border border-r-0 border-zinc-900/20 p-3 text-left font-light text-zinc-900 outline-none dark:border-zinc-200/20 bg-white dark:bg-surface-panel-50"
      placeholder="Enter a Twitch name"
      type="text"
    />
    <button
      :disabled="!userName?.trim()"
      class="cursor-pointer group flex flex-nowrap items-center justify-center gap-1 whitespace-nowrap rounded-l-none rounded-r-lg bg-purple-700 px-2 py-2 text-white outline-none transition-colors hover:bg-purple-600 focus:bg-purple-600"
      type="submit"
    >
      <span class="hidden md:inline">Search emotes</span>
      <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-1 group-focus:translate-x-1" />
    </button>
  </form>

  <template v-if="isFetching">
    <div class="max-h-[300px] grid w-full gap-2 grid-cols-[repeat(auto-fill,_minmax(90px,_1fr))] lg:grid-cols-[repeat(auto-fill,_minmax(70px,_1fr))] overflow-hidden">
      <Skeleton v-for="i in 4" :key="i" class="aspect-square w-full border border-surface-panel-border" />
    </div>
  </template>
  <template v-else-if="filteredStickers.length > 0">
    <div class="relative max-h-[300px] grid w-full gap-2 grid-cols-[repeat(auto-fill,_minmax(90px,_1fr))] lg:grid-cols-[repeat(auto-fill,_minmax(70px,_1fr))] overflow-y-auto">
      <div v-for="sticker in filteredStickers" :key="sticker.id">
        <Tile
          @click="() => addStickerToProject(sticker)"
          class="active:scale-90 transition-[transform,_border-color,_background-color] group"
          v-if="!isDeleting.includes(sticker.id)"
        >
          <DynamicPlanButtonWithTooltip feature="twitch-emotes" class="absolute right-2 top-2" />
          <div class="aspect-square">
            <AppImage class="h-full w-full cursor-pointer object-contain" :src="sticker.fileUrl" alt="Custom sticker preview" />
          </div>
        </Tile>
        <Skeleton v-else class="aspect-square w-full border border-surface-panel-border" />
      </div>
    </div>
  </template>
  <template v-else-if="filteredStickers.length === 0 && userName?.length > 0">
    <div class="text-sm font-light">
      <p>No emotes found for this Twitch user 🧐</p>
    </div>
  </template>
</template>

<style scoped lang="scss">

</style>
