<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import InputHeaderForm from '@/areas/dashboard/components/InputHeaderForm.vue'
import { ref, computed } from 'vue'
import logging from '@/logging'
import type { VodDTO } from '@/apis/streamladder-api/model'
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogTrigger } from '@/components/ui/dialog'
import CreateProjectForm from '@/areas/dashboard/pages/clipGPT/CreateProjectForm.vue'
import { getApiTwitchVodsVideoId } from '@/apis/streamladder-api/twitch-vods/twitch-vods'
import RecentClipGptProjectsGrid from '@/areas/dashboard/pages/clipGPT/RecentClipGptProjectsGrid.vue'
import CheckMarkIcon from '@/components/Icons/CheckMarkIcon.vue'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import { Button } from '@/components/ui/button'
import IconSaxSearchNormal1 from '@/components/Icons/iconsax/IconSaxSearchNormal1.vue'
import IconClipGptArrow from '@/components/Icons/IconClipGptArrow.vue'
import settings from '@/data/settings'
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@/components/ui/collapsible'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import BrowseStreamsDialogContent from '@/areas/dashboard/pages/clipGPT/BrowseStreamsDialogContent.vue'
import ClipGoatPromotionModal from '@/pages/modals/ClipGoatPromotionModal.vue'
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'

useHead({
  title: 'Clip GPT',
  meta: [
    {
      name: 'description',
      content: 'Generate clips from your twitch VODs',
    },
  ],
})

// Regular expression to match the Twitch clip ID patterns 
const twitchVodRegexp = /(https:\/\/www\.twitch\.tv\/(?:([a-zA-Z0-9_-]+\/video)|videos)\/)(?<vodId>\d+)(?:\?t=\d+h\d+m\d+s)?/

function validateTwitchStreamUrl(url: string) {
  
  if (!url?.trim()) {
    return {
      id: null,
      error: {
        title: 'Oops! Looks like you forgot to enter a URL. We need a clip URL from a Twitch stream.',
        message: 'twitch-vod-explanation',
      },
    }
  }

  const match = url.match(twitchVodRegexp)
  const vodId = match?.groups?.vodId

  if (!vodId) {
    return {
      id: null,
      error: {
        title: 'Oops! Looks like you forgot to enter a URL. We need a clip URL from a Twitch stream.',
        message: 'twitch-vod-explanation',
      },
    }
  }

  return {
    id: vodId,
    error: null
  }
}

// Regular expression to match the YouTube video ID patterns
const youtubeVideoRegex = /(youtube.com\/watch\?v=|youtu.be\/)(?<clipslug>[\w-]+)/
const clipGoatVideoId = ref<null | string>(null)

const isLoading = ref(false)
const error = ref<{ title: string, message: string }>()
async function submitUrl(url: string) {

  error.value = undefined
  isLoading.value = true
  logging.trackEvent('ClipGPT requesting clips', { url })

  const youtubeVideoMatch = url.match(youtubeVideoRegex)
  const youtubeId = youtubeVideoMatch?.groups?.clipslug
  if (youtubeId) {
    clipGoatVideoId.value = youtubeId
    error.value = {
      title: `Oops! Looks like you've entered a YouTube video URL. We need a clip URL from Twitch, YouTube, or Kick.`,
      message: 'twitch-vod-explanation',
    }
  }

  const result = validateTwitchStreamUrl(url)
  if (result.error) {
    error.value = result.error
  } else {
    try {
      await fetchVodAndOpenDialog(result.id)
    } catch (e) {
      console.error(e)
      error.value = {
        title: 'Something went wrong',
        message: e instanceof Error ? e.message : 'Please try again later',
      }
    }
  }

  isLoading.value = false
}

async function submitUrlSilently(url: string) {
  error.value = undefined
  isLoading.value = true

  const { id } = validateTwitchStreamUrl(url)
  if (id) {
    logging.trackEvent('ClipGPT requesting clips', { url })
    try {
      await fetchVodAndOpenDialog(id)
    } catch (e) {
      console.error(e)
    }
  }

  isLoading.value = false
}

async function fetchVodAndOpenDialog(id: string) {
  vod.value = await getApiTwitchVodsVideoId(id) ?? null
}

const vod = ref<VodDTO | null>(null)
const isCreateDialogOpen = computed({
  get() {
    return vod.value !== null
  },
  set(value: boolean) {
    if (!value) {
      vod.value = null
    }
  }
})
</script>

<template>
  <WithUpgradeOverlay title="Get up to 10 clips from a single Twitch stream with AI" feature="clip-gpt" subtitle="Say Goodbye to Manually Scrubbing Through Streams! Get ready-made horizontal clips to download or edit with our Clip Editor – perfect for turning into TikToks and more!">
    <main class="flex flex-col h-full lg:min-h-[calc(100svh-3rem)] flex-1">
      <div class="p-8 lg:p-16 bg-gradient-to-tl from-[#DD8AF8] to-[#764FF5] rounded-t-2xl grid gap-8 lg:grid-cols-12 overflow-hidden w-full">
        <div class="flex flex-col gap-8 lg:col-span-6 text-balance">
          <header class="flex flex-col items-start gap-2">
            <span class="px-3 py-1 rounded-full bg-white/25 font-bold text-sm text-white inline-block uppercase">
              Beta
            </span>
            <h1 class="text-2xl lg:text-3xl xl:text-4xl font-bold font-title text-white text-pretty">Get up to 10 clips from a single Twitch stream with AI </h1>
            <p class="font-light text-white text-pretty">Say Goodbye to Manually Scrubbing Through Streams! Get ready-made horizontal clips to download or edit with our Clip Editor – perfect for turning into TikToks and more!</p>
          </header>
          <ol class="flex flex-col gap-4 text-white font-light">
            <li class="flex items-center gap-4">
              <div class="w-7 h-7 grid place-items-center bg-current rounded-full shrink-0">
                <CheckMarkIcon class="w-4 h-4 fill-pink-400" />
              </div>
              <span class="text-white text-balance">Easily generate clips from your latest public Twitch stream</span>
            </li>
            <li class="flex items-center gap-4">
              <div class="w-7 h-7 grid place-items-center bg-current rounded-full shrink-0">
                <CheckMarkIcon class="w-4 h-4 fill-pink-400" />
              </div>
              <span class="text-white text-balance">Generates up to 10 horizontal-clips from a single stream</span>
            </li>
            <li class="flex items-center gap-4">
              <div class="w-7 h-7 grid place-items-center bg-current rounded-full shrink-0">
                <CheckMarkIcon class="w-4 h-4 fill-pink-400" />
              </div>
              <span class="text-white text-balance">Note: Currently unavailable for sub-only streams</span>
            </li>
          </ol>
          <section class="flex flex-col gap-4 relative">
            <IconClipGptArrow class="absolute left-full top-0 ml-4 -mt-8 hidden lg:block" />
            <IconClipGptArrow class="absolute top-full left-full -rotate-90 origin-top-left -scale-x-100 -ml-10 -mt-16 block lg:hidden" />
            <InputHeaderForm
              class="w-full"
              :isLoading="isLoading"
              :error="error"
              @submit="submitUrl"
              @paste="submitUrlSilently"
              placeholder="Type or paste Twitch stream url"
              cta="Generate Clips"
              :icon="IconSaxMagicpen"
            />
              <Dialog>
                <DialogTrigger as-child>
                  <Button variant="dashboardOutline" size="lg">
                    <IconSaxSearchNormal1 class="w-4 h-4" />
                    Browse recent streams
                  </Button>
                </DialogTrigger>
                <DialogContent class="max-w-4xl">
                  <BrowseStreamsDialogContent @select="v => vod = v" />
                </DialogContent>
              </Dialog>
          </section>
        </div>
  
        <div class="lg:col-span-5 lg:col-start-8">
          <img src="/images/clip-gpt/hero.png" alt="Clip GPT" class="w-full h-full object-contain" />
        </div>
      </div>
  
      <section class="flex flex-col gap-4 px-6 py-8 lg:p-12">
        <RecentClipGptProjectsGrid />
      </section>
      
      <section class="flex flex-col gap-4 px-6 py-8 lg:p-12 bg-surface-panel-bg">
        <div class="flex items-end gap-4 justify-between">
          <header class="flex flex-col items-start">
            <span class="px-3 py-1 mb-2 rounded-full bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-50 text-sm inline-block uppercase">
              Beta
            </span>
            <h2 class="text-2xl font-semibold text-brand-state-text-primary">
              Frequently Asked Questions
            </h2>
            <p class="font-light text-brand-state-text-secondary max-w-[45ch] text-pretty">
              Everything you need to know about our beta and how you can help us improve by sharing feedback on
              <a :href="settings.discordInviteUrl" target="_blank" class="text-primary underline">
                Discord
              </a>
            </p>
          </header>
          <div class="w-24 h-24 -mb-12 mr-12 relative z-[0] hidden md:flex">
            <img src="/images/clip-gpt/monster.png" alt="Clip GPT FAQ" class="w-80 h-80 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -scale-x-100 object-cover" />
          </div>
        </div>
        <div class="flex flex-col gap-2 relative">
          <Collapsible class="rounded-2xl border border-border bg-background">
            <CollapsibleTrigger class="px-6 py-4 text-lg font-bold flex items-center w-full group">
              <span class="w-full text-left">
                How many VODs can I clip per day?
              </span>
              
              <IconSaxArrowDown2 class="group-data-[state=open]:rotate-180 transition-transform" />
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div class="px-6 pb-4">
                During our beta, Gold members can generate clips from up to 3 VODs per day. This lets you test out our AI and find the best moments without limits!
              </div>
            </CollapsibleContent>
          </Collapsible>
          <Collapsible class="rounded-2xl border border-border bg-background">
            <CollapsibleTrigger class="px-6 py-4 text-lg font-bold flex items-center w-full group">
              <span class="w-full text-left">
              What happens if my stream is sub-only?
              </span>
              
              <IconSaxArrowDown2 class="group-data-[state=open]:rotate-180 transition-transform" />
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div class="px-6 pb-4">
                Currently, we can't generate clips from sub-only streams. Make sure your VODs are public so the AI can access and process them.
              </div>
            </CollapsibleContent>
          </Collapsible>
          <Collapsible class="rounded-2xl border border-border bg-background">
            <CollapsibleTrigger class="px-6 py-4 text-lg font-bold flex items-center w-full group">
              <span class="w-full text-left">
              Can I edit the clips after they are generated?
              </span>
              
              <IconSaxArrowDown2 class="group-data-[state=open]:rotate-180 transition-transform" />
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div class="px-6 pb-4">
                Absolutely! After the AI generates your clips, you can download them or use our clip editor to tweak and optimize them for TikTok, YouTube, or other platforms.
              </div>
            </CollapsibleContent>
          </Collapsible>
          <Collapsible class="rounded-2xl border border-border bg-background">
            <CollapsibleTrigger class="px-6 py-4 text-lg font-bold flex items-center w-full group">
              <span class="w-full text-left">
              What types of videos does the AI support?
              </span>
              
              <IconSaxArrowDown2 class="group-data-[state=open]:rotate-180 transition-transform" />
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div class="px-6 pb-4">
                Our AI currently supports Twitch VODs. Simply drop your VOD URL, and the AI will handle the rest. We're also working on adding support for YouTube, Kick, and manual uploads in the near future!
              </div>
            </CollapsibleContent>
          </Collapsible>
          <Collapsible class="rounded-2xl border border-border bg-background">
            <CollapsibleTrigger class="px-6 py-4 text-lg font-bold flex items-center w-full group">
              <span class="w-full text-left">
              Can I create clips in different aspect ratios?
              </span>
              
              <IconSaxArrowDown2 class="group-data-[state=open]:rotate-180 transition-transform" />
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div class="px-6 pb-4">
                Right now, the AI generates horizontal clips. However, you can edit them with our clip editor to fit other platforms like TikTok or Instagram Reels.
              </div>
            </CollapsibleContent>
          </Collapsible>
        </div>
      </section>
    </main>
  
    <Dialog v-model:open="isCreateDialogOpen">
      <DialogContent class="max-w-screen-lg">
        <CreateProjectForm v-if="vod" :vod="vod" />
        <div class="w-full h-full" v-else>
          <DialogTitle>Select a VOD first</DialogTitle>
          <DialogDescription>
            Afterwards you can generate clips!
          </DialogDescription>
        </div>
      </DialogContent>
    </Dialog>
    
    <ClipGoatPromotionModal v-model="clipGoatVideoId" />
  </WithUpgradeOverlay>
</template>

<style lang="scss" scoped></style>
