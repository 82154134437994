<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useFontsStore } from '@/store/fonts'
import type { Sticker } from '@/areas/editor/@type/Project'
import StickerElement from '@/components/Editor/Movable/StickerElement.vue'
import { noop } from 'lodash-es'
import { selectStickerFont } from '@/store/entity-system/selectors/selectStickerFont'

const sticker = ref<Sticker | null>(null)
const renderWidth = ref(1080)
const renderHeight = ref(1920)

type RenderData = {
  sticker: Sticker
  scale: number,
  renderWidth: number,
  renderHeight: number,
}
declare global {
  interface Window {
    renderOverlay: (data: RenderData) => Promise<void>;
  }
}

let onReady = noop

const fontsStore = useFontsStore()
onMounted(async () => {

  window.renderOverlay = async (data) => {

    // data = 'renderData' in data ? data.renderData : data

    const promise = new Promise<void>((resolve) => {
      onReady = resolve
    })

    // Ensure sticker is unmounted before remounting.
    sticker.value = null
    await new Promise((resolve) => setTimeout(resolve, 0))

    const font = selectStickerFont(data.sticker)
    if (font) {
      console.time(`Loading font "${font}"`)
      await fontsStore.loadFontByLabel(font)
      console.timeEnd(`Loading font "${font}"`)
    }
    
    renderWidth.value = data.renderWidth ?? 1080
    renderHeight.value = data.renderHeight ?? 1920

    sticker.value = data.sticker
    
    const maxScale = (data.sticker.area.width * data.renderWidth) / (data.sticker.naturalWidth || 100)
    sticker.value.scale = Math.min(
      data.sticker.scale ?? data.scale, maxScale / data.renderWidth)

    console.time('Rendering overlay')
    await promise
    console.timeEnd('Rendering overlay')
  }

  // Example/test usage
  // window.renderOverlay({
  //   'sticker': {"id":"8866774f-ebfc-4597-b3d6-1f9ae7835852","type":"text","scale":0.0036231884057971015,"area":{"x":0.25,"y":0.5,"width":0.4997169384057972,"height":0.13025617472953663},"z":0,"startMs":0,"endMs":59966.992,"editing":false,"key":"Basic Jan","variant":"Basic Jan","color":"#00F5D4","icon":"","textContent":"Enter Text\n here"}
  // })
})

</script>

<template>
  <div class="flex">
    <StickerElement
      v-if="sticker"
      id="overlay" class="!m-0" 
      v-model="sticker"
      :render-height="renderHeight" :render-width="renderWidth" 
      @ready="onReady"
    />
  </div>
</template>
