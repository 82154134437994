import { defineStore } from 'pinia'
import { useEntityStore } from '@/areas/editor/store/useEntityStore'
import { v4 as uuid } from 'uuid'
import { computed, ref, watch } from 'vue'
import type { CaptionPreset, CaptionPresetVariant } from '@/components/Captions/v3/CaptionPreset'
import { useGenerateCaptions } from '@/areas/editor/views/captions/useGenerateCaptions'
import { isEqual, uniq, orderBy } from 'lodash-es';
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { captionPresets } from '@/data/captionPresets'
import { defaultCaptionOptions } from '@/store/editor/editorCaptions'
import { findEmojis } from '@/helpers/emojify/emojify'
import { useFontsStore } from '@/store/fonts';
import { useLocalStorage } from '@vueuse/core';
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'

export const defaultCaptionsArea: CaptionsArea = {
  x: 0.0545,
  y: 0.57731,
  scale: 0.00405,
};

export type Speaker = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z';

export type TranscriptWordEffect = {
  id: string;
  type: 'supersize' | string;
  animation: 'float-around' | string;
  size: 'small' | 'medium' | 'large';
  // TODO: Add more properties
};

export type TranscriptWord = {
  id: string;
  text: string;
  start: number;
  end: number;
  speaker: Speaker;
  captionVariant: CaptionPresetVariant | null;
  effects?: TranscriptWordEffect[];
};

export type Transcript = {
  id: string;
  captionVariant: CaptionPresetVariant | null;
  emojis: string[];
  words: TranscriptWord[];
};

export type CaptionsArea = {
  x: number;
  y: number;
  scale: number;
};

export type CaptionsResponseSegment = {
  startMs: number;
  endMs: number;
  text: string;
  speaker: Speaker;
};

export type CaptionsResponseWord = {
  startMs: number;
  endMs: number;
  text: string;
  speaker: Speaker;
};

export type CaptionsResponse = {
  id: string;
  segments: CaptionsResponseSegment[];
  words: CaptionsResponseWord[];
};

export const useCaptionsStore = defineStore('captions', () => {

  const videoStore = useVideoStore();
  const segmentsStore = useSegmentsStore();

  const { state, ids, entities, operations } = useEntityStore<Transcript>();

  const defaultCaptionPreset = Object.values(captionPresets)[0];

  const baseCaptionPreset = ref<CaptionPreset>(defaultCaptionPreset);
  const fontsStore = useFontsStore();
  watch(baseCaptionPreset, async (preset: CaptionPreset | null) => {
    if (preset) {
      const fontFamily = preset.font.fontFamily;
      await fontsStore.loadFontByLabel(fontFamily);
    }
  })
  
  const hasGeneratedCaptions = ref(false);
  const isGenerating = ref(false);

  const captionsArea = ref(defaultCaptionsArea);

  const wordIdBeingEdited = ref<string | null>(null);
  const wordIdBeingHovered = ref<string | null>(null);

  const captionIdBeingEdited = ref<string | null>(null);
  const captionIdHovered = ref<string | null>(null);

  const _wordIdsHighlighted = ref<string[]>([]);
  const wordIdsHighlighted = computed({
    get() {
      return _wordIdsHighlighted.value;
    },
    set(value) {
      // Always reset the value before setting it on the next tick, this ensures css animations are always triggered again.
      _wordIdsHighlighted.value = [];
      setTimeout(() => _wordIdsHighlighted.value = value);
    }
  });

  const _captionIdHighlighted = ref<string | null>(null);
  const captionIdHighlighted = computed({
    get() {
      return _captionIdHighlighted.value;
    },
    set(value) {
      // Always reset the value before setting it on the next tick, this ensures css animations are always triggered again.
      _captionIdHighlighted.value = null;
      setTimeout(() => _captionIdHighlighted.value = value);
    }
  });

  const showOtherTracksWhileInCaptionsEditMode = ref(false);

  const language = ref<string>('en-US');

  const baseOptions = useLocalStorage('caption-options', defaultCaptionOptions);

  watch(() => baseOptions.value, newValue => {
    localStorage.setItem('caption-options', JSON.stringify(newValue));
  }, { deep: true });

  const rendererEntities = computed(() => {
    const sorted = orderBy(entities.value, e => e.words[0].start);
    if (baseOptions.value.grouping === 'single') {
      return sorted.flatMap(caption => caption.words.map(word => ({
        id: caption.id,
        captionVariant: caption.captionVariant,
        emojis: baseOptions.value.emojis ? emojisForCaption(baseOptions.value, caption) : [],
        words: [word],
      })));
    } else {
      return splitEntitiesIntoGroups(baseOptions.value, sorted);
    }
  });

  watch(() => baseOptions.value.emojis, (value) => {
    if (value) {
      // Add a single emoji to the last of each rendererEntity
      for (const caption of entities.value) {
        const emojis = findEmojis(caption, { maxEmojis: 1 });
        if (emojis.length > 0) {
          caption.emojis = emojis;
        }
      }
    } else {
      for (const caption of entities.value) {
        caption.emojis = [];
      }
    }
  }, { immediate: true });

  return {

    state,
    ids,
    entities,

    rendererEntities,

    sorted: computed(() => orderBy(entities.value, e => e.words[0].start)),

    ...operations,
    
    $reset() {
      operations.$reset();
      baseCaptionPreset.value = defaultCaptionPreset;
      hasGeneratedCaptions.value = false;
    },

    hasGeneratedCaptions,
    baseCaptionPreset,
    isGenerating,
    captionsArea,

    language,

    baseOptions,
    
    wordIdBeingHovered,
    wordIdBeingEdited,

    wordIdsHighlighted,
    captionIdHighlighted,
    
    captionIdHovered,
    captionIdBeingEdited,

    showOtherTracksWhileInCaptionsEditMode,

    wordAtTime(timeMs: number) {
      return entities.value.flatMap(caption => caption.words).find(word => word.start <= timeMs && word.end >= timeMs);
    },

    canAddNewCaptionAtTime(timeMs: number) {
      return !entities.value.some(caption => caption.words[0].start <= timeMs && caption.words[caption.words.length - 1].end >= timeMs);
    },

    getCaptionAtTime(timeMs: number) {
      return entities.value.find(caption => caption.words[0].start <= timeMs && caption.words[caption.words.length - 1].end >= timeMs);
    },

    getWordAtTime(timeMs: number) {
      return entities.value.flatMap(caption => caption.words).find(word => word.start <= timeMs && word.end >= timeMs);
    },

    getAllUsedFontFamilies() {

      const baseFontFamily = baseCaptionPreset.value?.font?.fontFamily || defaultCaptionPreset.font.fontFamily;
      const captionFontFamilies = entities.value.flatMap(caption => caption.captionVariant?.font?.fontFamily || baseFontFamily);
      const wordFontFamilies = entities.value.flatMap(caption => caption.words.map(word => word.captionVariant?.font?.fontFamily || baseFontFamily));

      return uniq([...captionFontFamilies, ...wordFontFamilies]);
    },

    getCaptionPresetById(presetId: string) {
      return Object.values(captionPresets).find(preset => preset.key === presetId);
    },

    getAllWordIds() {
      return entities.value.flatMap(caption => caption.words.map(word => word.id));
    },

    deleteWordsByIds(wordIds: string[]) {
      const caption = this.findCaptionByWordId(wordIds[0]);
      if (caption) {
        const words = caption.words.filter(word => !wordIds.includes(word.id)).map(word => word.text).join(' ')
        this.updateCaptionWordsById(caption.id, words);
      }
    },

    deleteWordById(wordId: string) {
      const caption = this.findCaptionByWordId(wordId);
      if (caption) {
        this.updateCaptionWordsById(caption.id, caption.words.filter(word => word.id !== wordId).map(word => word.text).join(' '));
      }
    },

    deleteWordAtTime(timeMs: number) {
      const word = this.getWordAtTime(timeMs);
      if (word) {
        this.deleteWordById(word.id);
      }
    },

    removeEffectsFromWordsByIds(wordIds: string[], effectType: string | null = null) {
      for (const wordId of wordIds) {
        const caption = this.findCaptionByWordId(wordId);
        if (caption && effectType) {
          const word = caption.words.find(word => word.id === wordId);
          if (word) {
            word.effects = word.effects?.filter(effect => effect.type !== effectType) ?? [];
          }
        } else if (caption) {
          const word = caption.words.find(word => word.id === wordId);
          if (word) {
            word.effects = [];
          }
        }
      }
    },

    addEffectToWordsByIds(wordIds: string[], effect: TranscriptWordEffect) {
      for (const wordId of wordIds) {
        const caption = this.findCaptionByWordId(wordId);
        if (caption) {
          const word = caption.words.find(word => word.id === wordId);
          if (word) {
            word.effects = word.effects ? word.effects.concat(effect) : [effect];
          }
        }
      }
    },

    updateWordsColor(words: TranscriptWord[], color: string) {
      for (const word of words) {
        const caption = this.findCaptionByWordId(word.id);
        if (caption) {
          const captionsVariant = baseCaptionPreset.value ?? caption.captionVariant ?? word.captionVariant;
          word.captionVariant = {
            ...captionsVariant,
            font: {
              ...captionsVariant?.font,
              color: color,
            },
          };
        }
      }
    },

    mergeCaptions(captionA: Transcript, captionB: Transcript) {
      captionA.words = captionA.words.concat(captionB.words);
      this.removeById(captionB.id);
    },

    findCaptionByWordId(wordId: string) {
      return entities.value.find(caption => caption.words.some(word => word.id === wordId))
    },

    findRendererCaptionByWordId(wordId: string): Transcript | undefined {
      return rendererEntities.value.find(caption => caption.words.some(word => word.id === wordId))
    },

    findWordById(wordId: string) {
      return entities.value.flatMap(caption => caption.words).find(word => word.id === wordId);
    },

    findMiddleMsOfWordByWordId(wordId: string) {
      const word = this.findWordById(wordId);
      if (!word) {
        return;
      }
      return word.start + (0.5 * (word.end - word.start));
    },

    findActiveWordByTime(timeMs: number) {
      return entities.value.flatMap(caption => caption.words).find(word => word.start <= timeMs && word.end >= timeMs);
    },

    updateTimingsById(wordId: string, startMs: number, endMs: number) {
      const caption = this.findCaptionByWordId(wordId);
      if (caption) {
        const word = state[caption.id].words.find(word => word.id === wordId);
        if (word) {
          word.start = startMs;
          word.end = endMs;
        }
      }
    },

    getCaptionPresetIdByWordId(wordId: string) {
      const caption = this.findCaptionByWordId(wordId);
      if (caption) {
        const word = state[caption.id].words.find(word => word.id === wordId);
        if (word) {
          if (word.captionVariant) {
            return word.captionVariant;
          } else if (caption.captionVariant) {
            return caption.captionVariant;
          } else {
            return baseCaptionPreset.value;
          }
        }
      }
    },

    hideWordHighlights() {
      wordIdsHighlighted.value = [];
    },

    updateWordTextById(wordId: string, text: string) {
      const caption = this.findCaptionByWordId(wordId);
      if (caption) {
        const word = state[caption.id].words.find(word => word.id === wordId);
        if (word) {
          word.text = text;
        }
      }
    },

    getAvailableSpaceMsAfterCaption(caption: Transcript) {
      const nextCaptionStartMs = state[ids.value[ids.value.indexOf(caption.id) + 1]]?.words[0]?.start || videoStore.durationMs;
      return nextCaptionStartMs - caption.words[caption.words.length - 1].end;
    },

    addCaption(fromMs?: number, toMs?: number, text?: string) {

      const newCaption = {
        id: uuid(),
        captionVariant: baseCaptionPreset.value,
        emojis: [],
        words: [{
          id: uuid(),
          text: text ?? '',
          start: fromMs ?? (segmentsStore.entities[0]?.startMs ?? 0),
          end: toMs ?? ((segmentsStore.entities[0]?.startMs ?? 0) + 500),
          speaker: 'A',
          captionVariant: null
        }]
      };

      this.createById(newCaption.id, newCaption);

      this.sortAllCaptions();

      return newCaption;
    },

    async addCaptionAfter(caption: Transcript) {

      const availableSpaceMs = this.getAvailableSpaceMsAfterCaption(caption);
      const newWordDurationMs = Math.min(availableSpaceMs, 500);

      if (newWordDurationMs < 100) {
        return;
      }

      const newCaption = {
        id: uuid(),
        captionVariant: caption.captionVariant,
        emojis: caption.emojis,
        words: [{
          id: uuid(),
          text: '',
          start: caption.words[caption.words.length - 1].end,
          end: caption.words[caption.words.length - 1].end + newWordDurationMs,
          speaker: caption.words[caption.words.length - 1].speaker,
          captionVariant: caption.captionVariant,
        }],
      } as Transcript;

      this.createById(newCaption.id, newCaption);

      this.sortAllCaptions();

      return newCaption;
    },

    // After doing an action, we need to resort the captions to make sure they are in the correct order.
    sortAllCaptions() {

      const sortedEntities = entities.value.toSorted((a, b) => a.words[0].start - b.words[0].start);

      for (const id of ids.value) {
        this.removeById(id);
      }

      for (const entity of sortedEntities) {
        this.createById(entity.id, entity);
      }
    },

    showWordHighlights(words: TranscriptWord[]) {

      const ids = [];

      for (const sentence of entities.value) {
        const sentenceWords = sentence.words.map(w => w.text.trim().toLowerCase());
        const searchWords = words.map(w => w.text.trim().toLowerCase());

        for (let i = 0; i <= sentenceWords.length - searchWords.length; i++) {
          if (sentenceWords.slice(i, i + searchWords.length).join(' ') === searchWords.join(' ')) {
            for (let j = 0; j < searchWords.length; j++) {
              ids.push(sentence.words[i + j].id);
            }
          }
        }
      }

      wordIdsHighlighted.value = ids;

      return ids;
    },

    replaceWordsInSentence(originalWords: TranscriptWord[], replacementText: string, replaceInAllSentences: boolean = true) {

      let sentencesToChange = uniq(wordIdsHighlighted.value.map(id => this.findCaptionByWordId(id)!));

      if (!sentencesToChange.length) {
        return;
      }

      // Only replace the words in the first sentence.
      if (!replaceInAllSentences) {
        sentencesToChange = [this.findCaptionByWordId(originalWords[0].id)!];
      }

      // If we have multiple words, we need to delete the other words since we replace the first word with multiple words.
      if (originalWords.length > 1) {
        for (const sentence of sentencesToChange) {
          for (let i = 1; i < originalWords.length; i++) {
            for (const word of sentence.words) {
              if (word.text.trim().toLowerCase() === originalWords[i].text.trim().toLowerCase()) {
                this.deleteWordById(word.id);
              }
            }
          }
        }
      }

      for (const sentence of sentencesToChange) {

        const newSentence = sentence.words
          .map(word =>
            word.text.trim().toLowerCase() === originalWords[0].text.trim().toLowerCase()
              ? maintainCasing(word.text, replacementText)
              : word.text
          )
          .join(' ');

        this.updateCaptionWordsById(sentence.id, newSentence);
      }
    },

    updateCaptionWordsById(captionId: string, words: string) {

      // Prototyping yo. But probably permanent.

      const inputWords = words
        .replaceAll('\n', ' ')
        .split(' ')
        .filter(word => word.trim() !== "");

      if (inputWords.length === 0) {
        this.removeById(captionId);
        return;
      }

      const currentWords = state[captionId]?.words;
      if (!currentWords) {
        return;
      }

      const noChangesFound = isEqual(currentWords.map(word => word.text), inputWords);
      if (noChangesFound) {
        return;
      }

      const latestSpeaker = currentWords[currentWords.length - 1]?.speaker || 'A';
      const latestCaptionVariant = currentWords[currentWords.length - 1]?.captionVariant || null;

      const oldWords = state[captionId].words;
      const newWords = [];

      const amountOfNewWords = inputWords.length - oldWords.length;
      const nextCaption = state[ids.value[ids.value.indexOf(captionId) + 1]];
      const nextCaptionFirstWordStartMs = nextCaption?.words[0]?.start || videoStore.durationMs;

      const minNewWordDurationMs = 100;
      const maxNewWordDurationMs = 500;

      const availableSpaceMs = nextCaptionFirstWordStartMs - oldWords[oldWords.length - 1]?.end;

      const newWordDurationMs = Math.max(Math.min(availableSpaceMs / amountOfNewWords, maxNewWordDurationMs), minNewWordDurationMs);
      let newWordOffsetMs = 0;

      const notEnoughSpaceForNewWords = availableSpaceMs < amountOfNewWords * minNewWordDurationMs;

      // Sorry.
      if (notEnoughSpaceForNewWords) {

        const startReferenceMs = oldWords[0]?.start ?? 0;

        const allAvailableSpaceMs = nextCaptionFirstWordStartMs - startReferenceMs;
        const wordDurationMs = allAvailableSpaceMs / inputWords.length;

        for (const [index, word] of inputWords.entries()) {

          newWords.push({
            id: uuid(),
            text: word,
            start: startReferenceMs + newWordOffsetMs,
            end: startReferenceMs + newWordOffsetMs + wordDurationMs,
            speaker: latestSpeaker,
            captionVariant: oldWords[index]?.captionVariant ?? latestCaptionVariant,
            effects: oldWords[index]?.effects,
          } as TranscriptWord);

          newWordOffsetMs += wordDurationMs;
        }
      } else {

        const hasDeletedWords = inputWords.length < oldWords.length;

        for (const [index, word] of inputWords.entries()) {

          let currentIndex = index;

          // If we have deleted words, we need to adjust the index to match the correct word in the oldWords array.
          // Otherwise, words will be placed in the wrong position.
          if (hasDeletedWords && oldWords[index].text !== word) {
            currentIndex = index + (oldWords.length - inputWords.length);
          }

          newWords.push({
            id: uuid(),
            text: word,
            start : oldWords[currentIndex]?.start ?? (oldWords[oldWords.length - 1]?.end + newWordOffsetMs),
            end: oldWords[currentIndex]?.end ?? (oldWords[oldWords.length - 1]?.end + newWordOffsetMs + newWordDurationMs),
            speaker: latestSpeaker,
            captionVariant: oldWords[currentIndex]?.captionVariant ?? latestCaptionVariant,
            effects: oldWords[currentIndex]?.effects,
          } as TranscriptWord);

          if (!oldWords[currentIndex]?.start && !oldWords[currentIndex]?.end) {
            newWordOffsetMs += newWordDurationMs;
          }
        }
      }

      state[captionId].words = newWords;
    },

    removeAllCaptions() {
      for (const id of ids.value) {
        this.removeById(id);
      }
      baseCaptionPreset.value = null;
      hasGeneratedCaptions.value = false;
    },

    async regenerateCaptions() {
      this.removeAllCaptions();
      await this.generateCaptions();
    },

    async generateCaptions(preset: CaptionPreset | null = null, highlightColor: string | null = null) {

      isGenerating.value = true;

      const generator = useGenerateCaptions();
      await generator.generateCaptionsAsync();

      const captionsOptions = useLocalStorage('caption-options', defaultCaptionOptions);

      if (preset) {
        baseCaptionPreset.value = preset;
      }

      baseOptions.value.highlight = captionsOptions.value.highlight;
      baseOptions.value.highlightColor = captionsOptions.value.highlightColor ?? (highlightColor ?? '#FFD700');
      baseOptions.value.size = captionsOptions.value.size;
      baseOptions.value.animation = captionsOptions.value.animation;
      baseOptions.value.animationTarget = captionsOptions.value.animationTarget;
      baseOptions.value.emojis = captionsOptions.value.emojis;
      baseOptions.value.emojiLocation = captionsOptions.value.emojiLocation;
      baseOptions.value.grouping = captionsOptions.value.grouping;
      baseOptions.value.stripPunctuation = captionsOptions.value.stripPunctuation;
      baseOptions.value.rotate = captionsOptions.value.rotate;

      isGenerating.value = false;
    }
  }
});

const splitEntitiesIntoGroups = (baseOptions: object, entities: Transcript[]) => {

  const punctuationMarks = ['.', '!', '?'];

  const splitEntities = [];
  const maxWordsPerGroup = 5;
  const allowedExtraWords = 2;

  for (const entity of entities) {
    const words = entity.words;

    for (let i = 0; i < words.length; ) {
      let groupEnd = Math.min(i + maxWordsPerGroup, words.length);
      let groupWords = words.slice(i, groupEnd);

      // Check if punctuation occurs within the next allowedExtraWords outside the group
      let extensionEnd = groupEnd;
      for (let j = groupEnd; j < Math.min(groupEnd + allowedExtraWords, words.length); j++) {
        if (punctuationMarks.includes(words[j].text.slice(-1))) {
          extensionEnd = j + 1; // Include this word and stop checking
          break;
        }
      }

      // Extend the group if a sentence-ending punctuation is found
      groupWords = words.slice(i, extensionEnd);
      groupEnd = extensionEnd;

      splitEntities.push({
        id: i + "_" + entity.id,
        captionVariant: entity.captionVariant,
        emojis: baseOptions.emojis ? emojisForWords(baseOptions, groupWords) : [],
        words: groupWords,
        start: groupWords[0].start,
        end: groupWords[groupWords.length - 1].end,
      });

      i = groupEnd; // Move to the next group
    }
  }

  const splitEntitiesWithEffects = [];

  for (const entity of splitEntities) {
    const effectIndices = entity.words
      .map((word, index) => (word.effects?.some(e => e.type === 'supersize') ? index : -1))
      .filter(index => index !== -1);

    if (effectIndices.length > 0) {
      let lastIndex = 0;

      for (const effectIndex of effectIndices) {
        // Words before the current effect
        if (effectIndex > lastIndex) {
          const beforeWords = entity.words.slice(lastIndex, effectIndex);
          splitEntitiesWithEffects.push({
            id: `${entity.id}-before-${lastIndex}`,
            captionVariant: entity.captionVariant,
            emojis: entity.emojis, // Adjust if needed
            words: beforeWords,
            start: beforeWords[0].start,
            end: beforeWords[beforeWords.length - 1].end,
          });
        }

        // Current effect word
        const effectWord = entity.words[effectIndex];
        splitEntitiesWithEffects.push({
          id: `${entity.id}-effect-${effectIndex}`,
          captionVariant: entity.captionVariant,
          emojis: entity.emojis, // Adjust if needed
          words: [effectWord],
          start: effectWord.start,
          end: effectWord.end,
        });

        // Update the last processed index
        lastIndex = effectIndex + 1;
      }

      // Words after the last effect
      if (lastIndex < entity.words.length) {
        const afterWords = entity.words.slice(lastIndex);
        splitEntitiesWithEffects.push({
          id: `${entity.id}-after-${lastIndex}`,
          captionVariant: entity.captionVariant,
          emojis: entity.emojis, // Adjust if needed
          words: afterWords,
          start: afterWords[0].start,
          end: afterWords[afterWords.length - 1].end,
        });
      }
    } else {
      // No effects, keep the entity as is
      splitEntitiesWithEffects.push(entity);
    }
  }

  return splitEntitiesWithEffects;
};

const emojisForCaption = (baseOptions: object, caption: Transcript) => {
  if (baseOptions.emojis) {
    return caption.emojis.length === 0 ? findEmojis(caption, { maxEmojis: 1 }) : caption.emojis;
  } else {
    return [];
  }
};

const emojisForWords = (baseOptions: object, words: TranscriptWord[]) => {
  if (baseOptions.emojis) {
    return findEmojis({ words: words }, { maxEmojis: 1 });
  } else {
    return [];
  }
};

const maintainCasing = (original: string, replacement: string) => {
  if (original.match(/^[A-Z]/)) {
    return replacement.charAt(0).toUpperCase() + replacement.slice(1);
  } else {
    return replacement;
  }
};