<script setup lang="ts">
import { useRouter } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import type { ContentPublisherEventWithRow } from '@/components-v2/content-publisher/_helpers'
import ContentPublisherEvent from '@/components-v2/content-publisher/ContentPublisherEvent.vue'

defineProps<{ posts: ContentPublisherEventWithRow[] }>()

const router = useRouter();
const openContentPublisherInNewTab = (id: string) => {
  window.open(router.resolve({ name: dashboardRouteNames.contentPublisher.root, query: { post: id } }).href, '_blank');
};
</script>

<template>
  <section class="bg-zinc-100 shadow-inner w-full rounded-lg min-h-[9.5rem] shrink-0 p-2 flex flex-col justify-center">
    <div class="flex flex-col h-full gap-1 items-center justify-center" v-if="posts.length === 0">
      <p class="text-4xl">🦗</p>
      <div class="flex flex-col items-center gap-0 font-light text-muted-foreground text-sm text-center whitespace-pre-wrap">
        <p class="font-semibold">*cricket noises*</p>
        <p>Let's schedule some posts right away!</p>
      </div>
    </div>
    <div class="flex flex-col gap-2" v-else>
      <h4 class="text-sm leading-[1]">On this day...</h4>
      <div class="flex gap-2 overflow-x-auto snap-x snap-mandatory">
        <ContentPublisherEvent
          v-for="post in posts" :key="post.id" class="w-48 shrink-0 snap-start"
          direction="horizontal" :event-content="{ event: post }" 
          @click="openContentPublisherInNewTab" />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
