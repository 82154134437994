<script setup lang="ts">
import type { Tier } from '@/data/plans'
import { useUserInfoStore } from '@/store/user/userInfo'
import TierPrice from '@/components/Tiers/TierPrice.vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { useDiscountCode } from '@/Hooks/useDiscountCode'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import { posthog } from 'posthog-js'
import logging from '@/logging'

const props = defineProps<{ tier: Tier; compact?: boolean; reason: string }>()

const userInfoStore = useUserInfoStore()
const { interval } = useSubscriptionIntervals()

const discountCode = useDiscountCode()

const { reveal } = useConfirmDialog()
const { isLoading, upgrade: _upgrade } = usePlanUpgrade()

const upgrade = async () => {

  if (userInfoStore.tier >= props.tier.tier) {
    return
  }

  if (userInfoStore.tier !== 0) {
    const confirm = await reveal({
      title: `Upgrade to ${props.tier.title} now`,
      message: `Warning: You will be charged immediately for the difference in price for the remainder of this ${interval.value}.`,
    })
    if (!confirm) return
  }

  if (props.tier.tier > userInfoStore.tier) {
    const eventMeta = {
      tier: props.tier.key,
      monthlyOrYearly: interval,
      currentPlan: userInfoStore.plan,
      isCurrentlyInTrial: userInfoStore.trialStatus === 'active',
    }

    logging.trackEvent('SubscriptionUpgrade Clicked', eventMeta)
  }

  return _upgrade(props.tier, props.reason, discountCode)
}
</script>

<template>
  <article
    :class="{
      'border-[#33CFF2] bg-[#F5FDFEEB] shadow-blue-300/30 hover:shadow-blue-300/50': tier.highlighted,
      'border-gray-300 bg-white/95 ': !tier.highlighted,
      'cursor-default': tier.tier <= userInfoStore.tier,
      'cursor-pointer hover:border-[#33CFF2] hover:shadow-xl': tier.tier > userInfoStore.tier,
      'gap-6 p-6 pb-4 2xl:p-8': !compact,
    }"
    class="group relative flex h-full flex-col rounded-lg border text-left shadow backdrop-blur transition-all"
    @click="upgrade"
  >
    <span
      v-if="tier.highlighted"
      class="absolute right-3 top-3 rounded-full bg-[#6080FE] px-3 py-1.5 text-sm font-light text-white"
    >
      Most Popular
    </span>

    <header class="flex flex-col gap-2">
      <h3 class="p-0 text-2xl">{{ tier.title }}</h3>
    </header>

    <TierPrice
      :class="{
        'group-hover:text-[#78767B]': tier.tier > userInfoStore.tier,
      }"
      :interval="interval"
      :tier="tier.key"
      class="transition-colors"
    />

    <div class="mt-auto">
      <ol class="flex flex-col gap-2 text-sm font-thin text-[#78767B]">
        <li v-for="feature in tier.features.slice(0, 3)" v-bind:key="feature" class="flex gap-2">
          <svg
            class="min-w-6 xl:min-w-4 2xl:min-w-6 -mt-0.5 w-6 flex-shrink-0 flex-grow-0 text-[#6080FE] xl:w-4 2xl:w-6"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M20.9557 5.90309L9.05079 19.5087L3.04214 13.5001L4.50055 12.0417L8.95032 16.4915L19.4035 4.54492L20.9557 5.90309Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>

          {{ feature }}
        </li>
      </ol>
    </div>
    <div
      v-if="isLoading === tier.key"
      :class="!tier.highlighted ? 'btn-outline border-2 bg-white' : ''"
      class="btn-disabled loading btn-lg btn whitespace-nowrap font-bold xl:h-10 xl:min-h-[2.5rem] 2xl:h-12 2xl:min-h-[3rem]"
    >
      Upgrading to {{ tier.title }}...
    </div>
    <div
      v-else-if="tier.tier > userInfoStore.tier && !isLoading"
      :class="{ 'shadow-xl': tier.highlighted }"
      class="group-hover:shadow-gradient relative h-12 rounded-full transition-all before:transition-all xl:h-10 2xl:h-12"
    >
      <div class="bg-gradient absolute w-full rounded-full">
        <button
          :class="
            tier.highlighted
              ? 'bg-black text-white'
              : 'btn-outline border-2 bg-white text-black group-hover:border-black group-hover:bg-black group-hover:text-white'
          "
          class="btn-lg btn w-full flex-nowrap gap-2 whitespace-nowrap font-bold mix-blend-lighten xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
        >
          Choose plan
        </button>
      </div>
    </div>
    <div v-else-if="tier.tier === userInfoStore.tier" class="btn-outline btn-disabled btn-lg btn border-2 bg-white">
      Current plan
    </div>
    <a
      @click.stop
      class="link-hover link text-center text-sm font-bold text-[#2F184E]"
      :class="isLoading && 'pointer-events-none'"
      href="/upgrade#compare"
      target="_blank"
    >
      View all {{ tier.title }} features
    </a>
  </article>
</template>
