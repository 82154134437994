import mainEvents from "@/events/mainEvents";
import uploadService from "@/services/uploadService";
import { useHead } from "@unhead/vue";
import axios from "axios";
import EventBus from '@/eventBus'
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { useVideoStore } from "../store/useVideoStore";
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';

export async function repairTwitchClipAudio(mp4Url: string) {

  const videoStore = useVideoStore();
  const resultBlob = await repairAudioSync(mp4Url);

  const videoElement = videoStore.videoElement;
  if (videoElement) {
    const currentTime = videoElement.currentTime
    const paused = videoElement.paused;
    videoElement.src = URL.createObjectURL(resultBlob);
    videoElement.currentTime = currentTime
    if (!paused) {
      videoElement.play().catch(console.error)
    }
  }
}

async function repairAudioSync(url: string) {

    console.time('repairTwitchAudio')
    const ffmpeg = new FFmpeg();
  
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm'
  
    await ffmpeg.load({
      // workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
    const videoFile = await fetchFile(url);
  
    ffmpeg.writeFile('input.mp4', videoFile);
    
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    
    const firefoxBrowsersCommand = [
      '-i', 'input.mp4',
      '-c:a', 'aac',
      '-c:v', 'libx264',
      '-preset', 'ultrafast',
      'output.mp4'
    ];

    const normalBrowsersCommand = [
      '-i', 'input.mp4',
      '-map', '0:v',
      '-vcodec', 'copy',
      '-map', '0:a',
      '-c:a', 'aac',
      'output.mp4'
    ];
    
    const clipInfoStore = useEditorClipInfoStore();
    if (isFirefox) {
      ffmpeg.on('progress', ({ progress }) => {
        if (clipInfoStore.loadingState) clipInfoStore.loadingState.progressRatio = progress;
        console.debug(progress);
      })
    }
  
    await ffmpeg.exec(isFirefox ? firefoxBrowsersCommand : normalBrowsersCommand);
  
    const data = await ffmpeg.readFile('output.mp4');
  
    console.timeEnd('repairTwitchAudio')
    return new Blob([data], {type: "video/mp4"});
}
  
const uploadVideo = async (file: Blob): Promise<null | string> => {

    // Creating a cancellation token
    const cancelSource = axios.CancelToken.source()
  
    try {
  
      // Start uploading the file.
      const result = await uploadService.getImportFileSignedUrl()
  
      const response = await uploadService.uploadFileS3(
        result.signedUrl,
        file,
        (p) => (console.log(p)),
        'video/mp4',
        '',
        { cancelToken: cancelSource.token }
      );
  
      if (response.status !== 200) {
        console.error('Error uploading video');
        EventBus.$emit(mainEvents.ERROR, "Error uploading your fixed video. Please try again later.");
        useHead({ title: 'Error' });
        return null;
      }
  
      return result.resultUrl;
  
    } catch (e) {
      console.error('Error uploading video');
      useHead({ title: 'Error' });
      return null;
    }
  }
