<script setup lang="ts">
import { ToolbarButton,  ToolbarRoot,  ToolbarSeparator } from 'radix-vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { Button } from '@/components/ui/button'
import IconSaxEdit2 from '@/components/Icons/iconsax/IconSaxEdit2.vue'
import IconBringToFront from '@/components/Icons/IconBringToFront.vue'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import { useToolbarPosition } from '@/areas/editor/hooks/useToolbarPosition'
import { inject, type Ref, computed } from 'vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';
import type { TypedSticker } from '@/areas/editor/@type/Project'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'

const props = defineProps<{ id: string }>()
const stickersStore = useStickersStore()
const sticker = stickersStore.selectById(props.id)

const editorWrapper = inject<Ref<HTMLElement>>('editorWrapper')!

const offset = 1
const areaRef = computed(() => sticker.area)
const { top, left, width, element } = useToolbarPosition(areaRef, editorWrapper, offset)
const { element: workspace } = useWorkspaceBoundingContext()!

const historyStore = useHistoryStore();
const { currentStep, currentSection, isBottomPanelOpen } = useEditorStep();

function onEditClick(sticker: TypedSticker) {
  if (sticker.type === 'rive') {
    stickersStore.focus(sticker.id);
    currentStep.value = editorRouteNames.elements;
    currentSection.value = editorRouteNames.rive;
    isBottomPanelOpen.value = true;
  }
  else {
    stickersStore.setEditModeById(sticker.id, !sticker.editing)
  }
}

</script>

<template>
  <Teleport :to="workspace">
    <ToolbarRoot
      ref="element"
      data-retain-focus
      :style="{ top: top + 'px', left: left - 0.5 * width + 'px' }"
      :class="{ '!opacity-0': top === offset }"
      class="layer-1 !fixed mt-1.5 flex !min-w-max max-w-full animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards] items-center gap-0.5 p-0.5 rounded-md shadow-lg z-40"
      @click.stop
    >
      <ToolbarButton v-if="sticker.type !== 'custom' && sticker.type !== 'giphy'">
        <Tooltip>
          <TooltipTrigger>
            <Button
              @click="onEditClick(sticker)"
              variant="ghost"
              size="sm"
              class="rounded-sm"
            >
              <IconSaxEdit2 class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Edit
          </TooltipContent>
        </Tooltip>
      </ToolbarButton>

      <ToolbarSeparator v-if="sticker.type !== 'custom'" class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <Tooltip>
          <TooltipTrigger>
            <Button
              @click="historyStore.transaction('STICKER:MOVE_TO_FOREGROUND', () => stickersStore.moveToForeground(sticker.id))"
              variant="ghost"
              size="sm"
              class="disabled:pointer-events-none rounded-sm"
            >
              <IconBringToFront class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Bring to front
          </TooltipContent>
        </Tooltip>
      </ToolbarButton>

      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <Tooltip>
          <TooltipTrigger>
            <Button
              @click="historyStore.transaction('STICKER:DELETE', () => stickersStore.removeById(sticker.id))"
              variant="ghostDestructive"
              size="sm"
              class="disabled:pointer-events-none rounded-sm"
            >
              <IconSaxTrash class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Delete
          </TooltipContent>
        </Tooltip>
      </ToolbarButton>
    </ToolbarRoot>
  </Teleport>
</template>

<style lang="scss" scoped></style>
