import type { ApiUser } from '@/store/user/userInfo'

export function getIdentity(user: ApiUser) {
  const DeploymentEnv = import.meta.env.VITE_DEPLOYMENT_ENV
  return {
    tier: user.tier,
    email: user.email,
    name: user.userName,
    deploymentEnv: DeploymentEnv,
    hasTwitchConnected: user.twitchAccount != null,
    hasGoogleConnected: user.googleAccount != null,
    hasTikTokConnected: user.tikTokAccounts.length > 0,
    hasYouTubeConnected: user.youTubeAccounts.length > 0,
  }
}
