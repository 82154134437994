import { defineStore } from 'pinia'
import type { Effect, EffectMap } from '@/areas/editor/@type/Project';
import { useEntityStore } from '@/areas/editor/store/useEntityStore'
import { computed } from 'vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useStickersStore } from './useStickersStore';

export const useEffectsStore = defineStore('effects', () => {

  const { state, ids, entities, operations } = useEntityStore<Effect>()
  const stickerStore = useStickersStore();
  
  return {
    state,
    ids,
    entities,
    ...operations,

    whereTypeIs<T extends EffectMap['type']>(type: T) {
      return operations.where<Effect<T>>(e => e.type === type)
    },
    
    idsWhereTypeIs(type: Effect['type']) {
      return operations.idsWhere(e => e.type === type)
    },

    selectBySegmentId(segmentId: string) {
      return computed(() => entities.value.find(e => 'segmentId' in e && e.segmentId === segmentId))
    },

    updateVolumeById(id: string, volume: number) {
      const effect = state[id];
      if (effect.type === 'sound') {
        effect.volume = volume;
      }
    },

    updateTimingsById(id: string, startMs: number, endMs: number) {
      const effect = state[id];
      const parentSticker = stickerStore.entities.find(sticker => sticker.id === effect.parentId);

      if (effect.parentId && parentSticker) {
        parentSticker.startMs = startMs;
        parentSticker.endMs = endMs;
      }
      if (effect.type === 'sound') {
        effect.startMs = startMs;
        effect.endMs = endMs;
      }
    },
  }
})
