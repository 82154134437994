<script lang="ts" setup>
import upgradeEvents from '@/events/upgradeEvents'
import type { Feature } from '@/data/features'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { useModal } from '@/Hooks/useModal'
import CrossIcon from '@/components/Icons/CrossIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import LottieAnimation from '@/components/LottieAnimation.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { ref } from 'vue'

type Payload = {
  feature?: Feature
  reason?: string
  title?: string
  subtitle?: string
}

const userInfoStore = useUserInfoStore()
const confetti = ref<LottieAnimation>()
const { isLoading, error } = usePlanUpgrade()

const { isShowing, payload, close, open } = useModal<Payload>({
  openEvent: upgradeEvents.UPGRADE_SUCCESS,
})

const handleClose = (payload: any) => {
  close(payload)
  // navigate to dashboard
  location.href = '/'
}

const playCount = ref(0)
const moreConfetti = () => {
  playCount.value++
}
</script>

<template>
  <teleport to="body">
    <div
      id="has-upgraded-dialog"
      @click.self="handleClose"
      class="modal light overflow-hidden"
      :class="{
        'modal-open z-[100]': isShowing,
      }"
      v-if="isShowing"
    >
      <LottieAnimation
        ref="confetti"
        :loop="false"
        :key="playCount"
        class="pointer-events-none absolute left-0 top-0 z-40 w-full !bg-transparent"
        url="/lottie/full-confetti.json"
      />
      <div class="modal-box max-w-screen-xl flex-col gap-3 overflow-x-hidden rounded-lg xl:flex xl:items-stretch">
        <div
          class="absolute right-2 top-2 z-10 cursor-pointer p-2 text-gray-300 hover:text-company-primary"
          @click="handleClose"
        >
          <cross-icon class="h-6 w-6 fill-current" />
        </div>
  
        <LottieAnimation
          class="pointer-events-none m-auto h-20 w-80 flex-1 justify-center md:h-80"
          url="/lottie/giraffe.json"
        />
        <h3 class="flex-1 justify-center pb-3 pt-3 text-center text-xl text-green-600 md:text-5xl">
          Poggers, {{ userInfoStore.userName }}!<br />Upgrade Successful!
        </h3>
  
        <p class="text-center text-black/90">
          Hooray, we've successfully upgraded your account.<br />We can't wait to see what you'll create!
        </p>
        <p class="mb-6 mt-6 text-center italic text-black/90">💜 The StreamLadder Team</p>
  
        <GradientButton @click="handleClose" class="m-auto h-12 w-[250px] !rounded-full"
          >Start creating <IconSaxArrowRight
        /></GradientButton>
        <button
          @click="moreConfetti"
          class="btn-outline btn-primary btn m-auto mb-6 mt-2 flex h-12 w-[250px] justify-center rounded-full border-2"
        >
          More confetti! 🎉
        </button>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss"></style>
