/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { GetApiTwitchEmotesParams, TwitchEmoteDto } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiTwitchEmotes = (
  params?: MaybeRef<GetApiTwitchEmotesParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return streamLadderAxiosInstance<TwitchEmoteDto[]>(
    { url: `/api/TwitchEmotes`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiTwitchEmotesQueryKey = (params?: MaybeRef<GetApiTwitchEmotesParams>) => {
  return ['api', 'TwitchEmotes', ...(params ? [params] : [])] as const
}

export const getGetApiTwitchEmotesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTwitchEmotes>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiTwitchEmotesParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchEmotes>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchEmotesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchEmotes>>> = ({ signal }) =>
    getApiTwitchEmotes(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiTwitchEmotes>>,
    TError,
    TData
  >
}

export type GetApiTwitchEmotesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTwitchEmotes>>>
export type GetApiTwitchEmotesQueryError = unknown

export const useGetApiTwitchEmotes = <TData = Awaited<ReturnType<typeof getApiTwitchEmotes>>, TError = unknown>(
  params?: MaybeRef<GetApiTwitchEmotesParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchEmotes>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchEmotesQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
