<script setup lang="ts">
import { computed, ref } from 'vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import type { CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'
import CaptionStyleSelector from '@/areas/editor/views/captions/CaptionStyleSelector.vue'
import CaptionStyleSettings from '@/areas/editor/views/captions/CaptionStyleSettings.vue'
import CaptionsGenerator from '@/areas/editor/views/captions/CaptionsGenerator.vue'
import { useGenerateCaptions } from '@/areas/editor/views/captions/useGenerateCaptions'
import LottieAnimation from '@/components/LottieAnimation.vue'

const { isGeneratingCaptions } = useGenerateCaptions()
const editorCaptionsStore = useEditorCaptionsStore()

const isCaptionsGeneratorOpen = ref(false)
function onSelect(style: CaptionStyle) {
  editorCaptionsStore.captionStyle = style
  isCaptionsGeneratorOpen.value = true
}

function onDone() {
  setTimeout(() => {
    isCaptionsGeneratorOpen.value = false
  }, 0)
}

const key = computed(() => {
  if (isCaptionsGeneratorOpen.value) {
    return 'generate'
  } else if (!editorCaptionsStore.captionsGenerated) {
    return 'select'
  } else {
    return 'settings'
  }
})
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 h-full sm:h-screen">
    <div class="relative h-full">
      <Transition
        enter-from-class="opacity-0 translate-x-6" leave-to-class="opacity-0 -translate-x-6"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <div class="w-full h-full">
          <div v-show="isGeneratingCaptions" class="flex flex-col pt-2 items-center text-center h-full gap-2">
            <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
            <p>
              <strong>Making magic</strong>
            </p>
            <p class="my-2 font-light">
              This process might take up to 20 seconds depending on the length of your clip.
            </p>
            <div class="text-emerald-600 bg-emerald-500/5 dark:text-emerald-500 dark:bg-emerald-500/5 p-4 rounded-lg border border-current flex justify-center items-center gap-4 font-light text-sm">
              You can continue working on your project while we generate the captions.
            </div>
          </div>

          <div v-show="!isGeneratingCaptions" class="flex flex-col gap-4 absolute inset-0 py-4 2xl:py-4" :key="key">
            <CaptionsGenerator v-if="isCaptionsGeneratorOpen" @done="onDone" />
            <CaptionStyleSelector v-else-if="!editorCaptionsStore.captionsGenerated" @select="onSelect" />
            <CaptionStyleSettings v-else />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
