<script setup lang="ts">
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import type { Effect } from '@/areas/editor/@type/Project'
import { computed, onMounted, ref, watch } from 'vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { Button } from '@/components/ui/button'
import { Slider } from '@/components/ui/slider'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import IconSaxVolumeHigh from '@/components/Icons/iconsax/IconSaxVolumeHigh.vue'

defineProps<{
  hideBackButton?: boolean
}>();

const effectsStore = useEffectsStore();

const videoStore = useVideoStore();
const editorFocusStore = useEditorFocusStore();
const historyStore = useHistoryStore();

const { currentSection } = useEditorStep();

const volume = ref(0.4);

const currentSound = computed(() => {
  return effectsStore.entities.find((e) => e.id === editorFocusStore.focus?.key && e.type === 'sound') as Effect<'sound'>;
});

onMounted(() => {
  if (currentSound.value && currentSound.value.volume !== undefined) {
    volume.value = currentSound.value.volume;
  }
});

watch(() => currentSound.value, (sound) => {

  if (sound && sound.volume !== undefined) {

    volume.value = sound.volume;

    if (videoStore.muted) {
      videoStore.muted = false;
    }
  }
}, { immediate: true });

const updateVolume = (sound: Effect<'sound'>) => {
  if (sound) {
    videoStore._currentTime = sound.startMs / 1000 + 0.01;
    effectsStore.updateVolumeById(sound.id, volume.value);
  }
};

const focusType = computed(() => editorFocusStore.focus?.type)
// This happens when the Sound effect is removed
watch(focusType, (type) => {
  if (type !== FocusTypes.SOUND) {
    currentSection.value = null;
  }
});

const volumeModel = computed({
  get: () => [volume.value],
  set: ([value]) => {
    volume.value = value;
  }
})
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">

    <div class="flex items-center gap-2 cursor-pointer hover:-translate-x-0.5 transition-transform text-brand-state-link hover:underline underline-offset-2" @click="currentSection = null">
      <IconSaxArrowLeft class="w-4 h-4" />
      <span class="text-xl lg:text-sm mt-0.5 select-none">Back to Audio</span>
    </div>

    <header>
      <div class="flex gap-1 items-center">
        <h2 class="text-xl font-semibold leading-snug">{{ currentSound?.name }}</h2>
      </div>
    </header>

    <hr />

    <div class="flex flex-col w-full gap-2">
      <p class="font-semibold">Volume</p>
      <div class="flex flex-row gap-3 items-center mb-2">
        <div class="border dark:border-surface-panel-border rounded-lg p-4 shadow">
          <IconSaxVolumeHigh class="w-6 h-6 text-black dark:text-white" />
        </div>
        <div class="flex border dark:border-surface-panel-border rounded-lg w-full gap-4 p-2 shadow">
          <Slider
            class="rounded cursor-pointer"
            v-model="volumeModel" :min="0" :max="1" :step="0.01" 
            @value-commit="historyStore.transaction('SOUND_EFFECT:CHANGE_VOLUME', () => updateVolume(currentSound))" 
          />
          <div class="flex justify-center items-center bg-gray-100 dark:bg-surface-bg rounded-lg p-2 min-w-[60px]">
            {{ Math.round(volume * 100) }}%
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">

</style>
