import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { getApiClipGptClipsId } from '@/apis/streamladder-api/clip-gpt-clips/clip-gpt-clips'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError';
import type { ClipDto } from '@/areas/editor/@type/ClipDto';

export async function startupFromTwitchVodByClipId(clipId: string, config: StartupConfig) {
  try {
    const clip = await getApiClipGptClipsId(clipId, { signal: config.signal ?? undefined });
    return { 
      error: null,
      clip: {
        taskId: null,
        id: clip.id,
        title: clip.title,
        dateFromNow: 'just now',
        createdAt: clip.createdAt,
        mp4Url: clip.videoUrl,
        thumbnailUrl: clip.thumbnailUrl,
        languageCode: null,
        broadcasterName: null,
        viewCount: null,
      } as unknown as ClipDto
    }
  } catch (e) {
    handleStartupError(e)
    const editorClipInfoStore = useEditorClipInfoStore()
    editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Clip Not Found! 🚫',
      description: 'Looks like we couldn\'t find that clip. Please try again later or open a ticket for help! 💬'
    }
    return { error: 'Looks like we couldn\'t find that clip. Please try again later or open a ticket for help! 💬', clip: null }
  }
}
