/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { CreateCustomLayoutDto, CustomLayoutDto } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiCustomLayouts = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<CustomLayoutDto[]>({ url: `/api/CustomLayouts`, method: 'GET', signal }, options)
}

export const getGetApiCustomLayoutsQueryKey = () => {
  return ['api', 'CustomLayouts'] as const
}

export const getGetApiCustomLayoutsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiCustomLayouts>>,
  TError = void
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiCustomLayouts>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiCustomLayoutsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiCustomLayouts>>> = ({ signal }) =>
    getApiCustomLayouts(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiCustomLayouts>>,
    TError,
    TData
  >
}

export type GetApiCustomLayoutsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCustomLayouts>>>
export type GetApiCustomLayoutsQueryError = void

export const useGetApiCustomLayouts = <
  TData = Awaited<ReturnType<typeof getApiCustomLayouts>>,
  TError = void
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiCustomLayouts>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiCustomLayoutsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const putApiCustomLayoutsLayoutId = (
  layoutId: MaybeRef<string>,
  createCustomLayoutDto: MaybeRef<CreateCustomLayoutDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  layoutId = unref(layoutId)
  createCustomLayoutDto = unref(createCustomLayoutDto)

  return streamLadderAxiosInstance<CustomLayoutDto>(
    {
      url: `/api/CustomLayouts/${layoutId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createCustomLayoutDto,
    },
    options
  )
}

export const getPutApiCustomLayoutsLayoutIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiCustomLayoutsLayoutId>>,
    TError,
    { layoutId: string; data: CreateCustomLayoutDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiCustomLayoutsLayoutId>>,
  TError,
  { layoutId: string; data: CreateCustomLayoutDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiCustomLayoutsLayoutId>>,
    { layoutId: string; data: CreateCustomLayoutDto }
  > = (props) => {
    const { layoutId, data } = props ?? {}

    return putApiCustomLayoutsLayoutId(layoutId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiCustomLayoutsLayoutIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiCustomLayoutsLayoutId>>
>
export type PutApiCustomLayoutsLayoutIdMutationBody = CreateCustomLayoutDto
export type PutApiCustomLayoutsLayoutIdMutationError = void

export const usePutApiCustomLayoutsLayoutId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiCustomLayoutsLayoutId>>,
    TError,
    { layoutId: string; data: CreateCustomLayoutDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof putApiCustomLayoutsLayoutId>>,
  TError,
  { layoutId: string; data: CreateCustomLayoutDto },
  TContext
> => {
  const mutationOptions = getPutApiCustomLayoutsLayoutIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiCustomLayoutsLayoutId = (
  layoutId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  layoutId = unref(layoutId)

  return streamLadderAxiosInstance<CustomLayoutDto>(
    { url: `/api/CustomLayouts/${layoutId}`, method: 'GET', signal },
    options
  )
}

export const getGetApiCustomLayoutsLayoutIdQueryKey = (layoutId: MaybeRef<string>) => {
  return ['api', 'CustomLayouts', layoutId] as const
}

export const getGetApiCustomLayoutsLayoutIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiCustomLayoutsLayoutId>>,
  TError = void
>(
  layoutId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiCustomLayoutsLayoutId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiCustomLayoutsLayoutIdQueryKey(layoutId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiCustomLayoutsLayoutId>>> = ({ signal }) =>
    getApiCustomLayoutsLayoutId(layoutId, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(layoutId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiCustomLayoutsLayoutId>>, TError, TData>
}

export type GetApiCustomLayoutsLayoutIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiCustomLayoutsLayoutId>>
>
export type GetApiCustomLayoutsLayoutIdQueryError = void

export const useGetApiCustomLayoutsLayoutId = <
  TData = Awaited<ReturnType<typeof getApiCustomLayoutsLayoutId>>,
  TError = void
>(
  layoutId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiCustomLayoutsLayoutId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiCustomLayoutsLayoutIdQueryOptions(layoutId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const deleteApiCustomLayoutsLayoutId = (
  layoutId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  layoutId = unref(layoutId)

  return streamLadderAxiosInstance<unknown>({ url: `/api/CustomLayouts/${layoutId}`, method: 'DELETE' }, options)
}

export const getDeleteApiCustomLayoutsLayoutIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiCustomLayoutsLayoutId>>,
    TError,
    { layoutId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiCustomLayoutsLayoutId>>,
  TError,
  { layoutId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiCustomLayoutsLayoutId>>,
    { layoutId: string }
  > = (props) => {
    const { layoutId } = props ?? {}

    return deleteApiCustomLayoutsLayoutId(layoutId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiCustomLayoutsLayoutIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiCustomLayoutsLayoutId>>
>

export type DeleteApiCustomLayoutsLayoutIdMutationError = void

export const useDeleteApiCustomLayoutsLayoutId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiCustomLayoutsLayoutId>>,
    TError,
    { layoutId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof deleteApiCustomLayoutsLayoutId>>,
  TError,
  { layoutId: string },
  TContext
> => {
  const mutationOptions = getDeleteApiCustomLayoutsLayoutIdMutationOptions(options)

  return useMutation(mutationOptions)
}
