<script setup lang="ts">
import Workspace from '@/areas/editor/workspaces/Workspace.vue';
import { onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';
import WorkspaceSpinner from '@/areas/editor/workspaces/WorkspaceSpinner.vue';
import { fetchProjectJson } from '@/queries/projects/projectsApi';
import type { TypedSticker } from '@/areas/editor/@type/Project';
import { startupVideo, startupWithDefaultLayout } from '@/areas/editor/startup/generalStartupMethods';
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore';
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore';
import { uniqBy } from 'lodash-es';
import { useCropsStore } from '@/areas/editor/store/useCropsStore';
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus';
import { useStickersStore } from '@/areas/editor/store/useStickersStore';
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore';
import { handleStartupError } from '@/areas/editor/startup/handleStartupError';
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions';
import { useFontsStore } from '@/store/fonts';
import { captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager';
import { useIsMobile } from '@/Hooks/useIsMobile';
import WorkspaceMobile from '@/areas/editor/workspaces/WorkspaceMobile.vue';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore';
import { useVideoStore } from '@/areas/editor/store/useVideoStore';
import { useUserInfoStore, onUserInfoReadyAsync } from '@/store/user/userInfo';
import { supabase } from '@/authentication/supabase';
import { saveProject } from '@/queries/projects/autoSaveProjectById';

const route = useRoute();
const router = useRouter();

const editorClipInfoStore = useEditorClipInfoStore();

const fontsStore = useFontsStore();

function isValidProjectId(projectId: unknown): projectId is string {
  return typeof projectId === 'string';
}

const historyStore = useHistoryStore();

const abortController = new AbortController();

// const uploadProjectThumbnail = useUploadProjectThumbnail();

onMounted(async () => {

  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Loading project...',
  };

  const projectId = route.params.projectId;
  if (!isValidProjectId(projectId)) {
    return await router.replace({ name: 'editor' });
  }

  const project = await tryDownloadProject(projectId);
  if (!project) {
    const userInfoStore = await onUserInfoReadyAsync();
    return editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Project not found 🕵️',
      description: userInfoStore.isAuthenticated
        ? 'Looks like the project you are trying to open does not exist, or is not available to you.'
        : 'Please sign in to access your projects.',
    };
  }

  if (!project.id || !project.mp4Url) {
    return handleStartupError(new Error('Invalid project data'));
  }

  editorClipInfoStore.id = project.id;
  editorClipInfoStore.mp4Url = project.mp4Url;
  editorClipInfoStore.title = project.title ?? 'New Clip';
  editorClipInfoStore.languageCode = project.language ?? null;

  const errors = [];

  try {
    if (project.segments.length === 0) {

      let videoOffsetMs = 0;
      const mp4BoxPromise = await startupVideo({ signal: abortController.signal });
      editorClipInfoStore.loadingState = {
        state: 'loading',
        title: 'Loading video metadata',
        description: 'Ensuring the video is ready for editing...',
      };
      
      try {
        const info = await mp4BoxPromise();

        // Assume the first video track contains the framerate info
        const videoTrack = info.tracks.find(track => track.type === 'video');
        const audioTrack = info.tracks.find(track => track.type === 'audio');

        const videoMinusOneEntry = videoTrack?.edits?.find(edit => edit.media_time === -1);
        const audioMinusOneEntry = audioTrack?.edits?.find(edit => edit.media_time === -1);

        if (videoTrack && videoMinusOneEntry) {
          videoOffsetMs = Math.max(videoOffsetMs, videoMinusOneEntry.segment_duration / videoTrack.movie_timescale);
        }
        if (audioTrack && audioMinusOneEntry) {
          videoOffsetMs = Math.max(videoOffsetMs, audioMinusOneEntry.segment_duration / audioTrack.movie_timescale);
        }
      } catch (e) {
        console.error(e);
      }

      await startupWithDefaultLayout(abortController, videoOffsetMs);
    } else {

      await startupVideo({ signal: abortController.signal })

      const segmentsStore = useSegmentsStore();
      segmentsStore.$reset();
      for (const segment of project.segments) {
        segmentsStore.createById(segment.id, segment);
      }

      const layoutsStore = useLayoutsStore();
      layoutsStore.$reset();
      for (const layout of uniqBy(project.layouts, 'id')) {
        layoutsStore.createById(layout.id, layout);
      }

      const cropsStore = useCropsStore();
      cropsStore.$reset();
      for (const crop of project.crops) {
        cropsStore.createById(crop.id, crop);
      }
    }

    const editorFocusStore = useEditorFocusStore();
    if (project.crops[0]) {
      editorFocusStore.setFocus(FocusTypes.CROP, project.crops[0].id);
    }

    const stickersStore = useStickersStore();
    stickersStore.$reset();
    for (const sticker of project.stickers) {
      const upgradedSticker = purgeBlobUrlFrom(sticker);
      stickersStore.createById(upgradedSticker.id, upgradedSticker);
    }
  } catch (e) {
    errors.push(e);
  }

  const effectsStore = useEffectsStore();
  try {
    effectsStore.$reset();
    for (const effect of project.effects) {
      try {
        effectsStore.createById(effect.id, effect);
      } catch (e) {
        errors.push(e);
      }
    }
  } catch (e) {
    errors.push(e);
  }

  if ('entities' in project.captions) {
    try {

      const captionsStore = useCaptionsStore();
      captionsStore.$reset();
      for (const caption of project.captions.entities) {
        captionsStore.createById(caption.id, caption);
      }

      captionsStore.captionsArea = project.captions.captionsArea;
      captionsStore.baseOptions = project.captions.baseOptions;
      captionsStore.hasGeneratedCaptions = project.captions.hasGeneratedCaptions;

      if (project.captions.entities.length > 0) {
        captionsStore.captionsArea = project.captions.captionsArea;
        captionsStore.baseCaptionPreset = project.captions.baseCaptionPreset;
      }
    } catch (e) {
      errors.push(e);
    }
  }

  if (errors.length > 0) {
    console.error(errors);
    return handleStartupError(new Error('Invalid project data'));
  }

  if ('captions' in project.captions) {

    const editorCaptionsStore = useEditorCaptionsStore();
    editorCaptionsStore.captionsSettings = project.captions.settings;
    editorCaptionsStore.captionsDocument = project.captions.document;

    // Wait for captions document side effects to finish.
    await new Promise((resolve) => setTimeout(resolve, 0));

    editorCaptionsStore.groupedCaptions.splice(0, editorCaptionsStore.groupedCaptions.length, ...project.captions.captions);

    const captionStyleDefinition = captionStylesSettings[project.captions.settings.style];
    await fontsStore.loadFontByLabel(captionStyleDefinition.fontFamily);
  }

  editorClipInfoStore.loadingState = null;

  historyStore.transaction('PROJECT:CREATE');

  // await uploadProjectThumbnail();
});

supabase.auth.onAuthStateChange((event) => {
  if (event === 'SIGNED_IN') {
    saveProject();
  }
});

async function tryDownloadProject(id: string) {
  try {
    return await fetchProjectJson(id);
  } catch (e) {
    handleStartupError(e);
    return null;
  }
}

// In some cases a Blob URL is stored in the sticker object. This function removes it, because it can cause the application
// to throw errors when the Blob URL is no longer valid (for example after reloading).
function purgeBlobUrlFrom(sticker: TypedSticker) {
  const upgradedSticker = { ...sticker }
  if (upgradedSticker.imageUrl?.startsWith('blob:')) {
    upgradedSticker.imageUrl = ''
  }
  return upgradedSticker
}

onUnmounted(() => {
  abortController.abort();
});

const isMobile = useIsMobile();

const videoStore = useVideoStore();
</script>

<template>
  <div
    v-if="editorClipInfoStore.loadingState || !videoStore.videoSize"
    class="flex items-center justify-center p-8 w-full h-full"
  >
    <WorkspaceSpinner />
  </div>
  <template v-else>
    <template v-if="isMobile">
      <WorkspaceMobile />
    </template>
    <template v-else>
      <Workspace />
    </template>
  </template>
</template>

<style scoped lang="scss">

</style>
