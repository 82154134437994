<script setup lang="ts">
import { Rive, StateMachineInput, StateMachineInputType } from '@rive-app/canvas';

const props = defineProps<{
  src: string;
  artboard: string;
  class?: string;
}>();

const canvas = ref<HTMLCanvasElement | null>(null);

// Can't be a proxy ref because... // Sorry.
let hoverInput: StateMachineInput | null = null;

onMounted(() => {
  if (canvas.value instanceof HTMLCanvasElement) {
    const canvasElement = canvas.value;
    canvasElement.width = canvasElement.offsetWidth * window.devicePixelRatio;
    canvasElement.height = canvasElement.offsetHeight * window.devicePixelRatio;

    const riveInstance = new Rive({
      canvas: canvas.value,
      src: props.src,
      artboard: props.artboard,
      autoplay: true,
      stateMachines: ['State Machine 1'], // Replace with your actual state machine name
      onLoad: () => {
        const inputs = riveInstance.stateMachineInputs('State Machine 1');
        const hover = inputs.find((input) => input.name === 'Boolean 1' && input.type === StateMachineInputType.Boolean);
        if (hover) {
          hoverInput = hover;
        }
      },
    });
  }
});

const startHover = () => {
  if (hoverInput && hoverInput.type === StateMachineInputType.Boolean) {
    hoverInput.value = true;
  }
};

const endHover = () => {
  if (hoverInput && hoverInput.type === StateMachineInputType.Boolean) {
    hoverInput.value = false;
  }
};

defineExpose({ startHover, endHover });
</script>

<template>
  <div v-bind="props">
    <canvas ref="canvas" class="w-full h-full"></canvas>
  </div>
</template>

<style scoped lang="scss">

</style>
