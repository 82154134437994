<script setup lang="ts">
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { ref, onMounted, computed } from 'vue'
import { useMutation } from '@tanstack/vue-query'
import { useClipboard } from '@vueuse/core'
import { SelectValue, SelectGroup, SelectContent, Select, SelectTrigger, SelectItem } from '@/components/ui/select'
import MegaphoneIcon from '@/components/Icons/MegaphoneIcon.vue'
import { Button } from '@/components/ui/button'
import ErrorIcon from '@/components/Dialog/Icons/ErrorIcon.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import IconSaxMagicStar from '@/components/Icons/iconsax/IconSaxMagicStar.vue'
import { useGenerateCaptions } from '@/areas/editor/views/captions/useGenerateCaptions'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import settings from '@/data/settings'
import { Dialog, DialogContent, DialogClose } from '@/components/ui/dialog'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'

const emit = defineEmits(['done', 'cancel'])

const editorClipInfoStore = useEditorClipInfoStore()
const historyStore = useHistoryStore()
const { supportedLocales, generateCaptionsAsync, captionsLanguageCode, shouldTrimBeforeGenerating, isOverDurationLimit }
  = useGenerateCaptions()

const mustTrim = ref(isOverDurationLimit.value)
onMounted(() => {
  mustTrim.value = isOverDurationLimit.value
})

const { mutateAsync: generateCaptions, isPending: isGenerating, error } = useMutation({ 
  mutationFn: async () => {
    await historyStore.transaction('CAPTIONS:GENERATE', () => generateCaptionsAsync())
  },
  onSuccess: () => {
    emit('done')
  },
})

const { copy, copied } = useClipboard()
function copyErrorMessage() {
  if (!error.value) return
  copy(`${error.value.message}\nProject ID: ${editorClipInfoStore.id}`)
}

const isTranscriptionError = computed(() => {
  return error.value?.message === 'Generating captions failed: Error in transcribing clip. see server logs for details'
})

const { resetEditorStep } = useEditorStep()
const editorCaptionsStore = useEditorCaptionsStore()
</script>

<template>
  <div class="flex flex-col gap-6 h-full" :key="mustTrim ? 'trim' : error ? 'error' : 'generating'">
    <template v-if="mustTrim">

      <LottieAnimation :loop="false" class="mx-auto w-24 h-24" url="/lottie/error-alt.json" />

      <header class="text-xl font-bold">Oops! Video too long 🎥</header>
      <p class="font-light text-sm">
        Your clip exceeds the 3-minute limit for caption generation. Please upload a shorter video or trim your
        current one to fit within the time frame.
      </p>

      <div class="mt-8 flex w-full flex-col items-stretch justify-center gap-4 md:flex-row md:items-center">
        <Button variant="gradient" @click="mustTrim = shouldTrimBeforeGenerating; emit('done')">
          Try again
        </Button>
      </div>
    </template>

    <template v-else-if="error">
      <header class="flex gap-4 items-center flex-wrap">
        <ErrorIcon />
        <div>
          <header>Error while generating captions 😔</header>
          <p class="font-light text-sm">Unfortunately an error has occurred</p>
        </div>
      </header>

      <template v-if="!isTranscriptionError">
        <p class="mb-0 mt-2">
          Please report this issue in our support channel in discord together with your video/twitch-clip and the following
          message:
        </p>

        <code class="mb-0 mt-2 flex gap-2 rounded bg-zinc-100 p-4 text-sm">
          <span class="flex flex-col">
            <span>{{ error.message }}</span>
            <span>Project ID: {{ editorClipInfoStore.id }}</span>
          </span>
        </code>

        <div class="flex gap-2">
          <Button class="flex-1" variant="depressed" @click="emit('done')">
            Close
          </button>
          <Button class="flex-1" :variant="copied ? 'ghost' : 'primary'" @click="copyErrorMessage">
            <template v-if="copied">Copied!</template>
            <template v-else>Copy error message</template>
          </Button>
        </div>
      </template>
      <template v-else>
        <p class="mb-0 mt-2 text-pretty">
          Our AI-Powered Captions server is currently experiencing issues. We apologize for the inconvenience and appreciate your patience. Please check our <a :href="settings.discordInviteUrl" target="_blank" class="link text-indigo-500">Discord Server</a> for updates.
        </p>

        <div class="flex gap-2">
          <Button class="flex-1" variant="depressed" @click="async () => {
            resetEditorStep();
            await historyStore.$reset();
            emit('cancel');
          }">
            Try a different video
          </button>
          <Button class="flex-1" @click="emit('cancel')">
            Continue without captions
          </Button>
        </div>
      </template>
    </template>

    <template v-else>

      <Dialog default-open 
        v-if="!isGenerating && !editorCaptionsStore.captionsGenerated"
        @update:open="(open) => !open && emit('done')"
      >
        <DialogContent class="max-w-xl flex flex-row items-stretch justify-between gap-12">
          <div class="text-left w-full">
            <header class="text-lg font-bold">Which language is being spoken in the clip?</header>
            <div class="mt-4 flex items-center gap-2">
              <p class="whitespace-nowrap">Spoken language:</p>

              <Select v-model="captionsLanguageCode">
                <SelectTrigger>
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent class="z-[1000]">
                  <SelectGroup>
                    <SelectItem v-for="locale of supportedLocales" :key="locale.code" :value="locale.code">
                      {{ locale.label }}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <div v-if="captionsLanguageCode !== 'en_us'" class="flex justify-center pt-4">
              <div class="flex items-center space-x-2 rounded-lg bg-blue-100 px-4 py-2 text-sm text-company-primary">
                <MegaphoneIcon height="17" width="16" />
                <span>With this language, long clip captions may run out of sync with the audio</span>
              </div>
            </div>

            <div class="flex items-center justify-between gap-2 pt-10">
              <DialogClose as-child>
                <Button variant="outline">
                  Close
                </button>
              </DialogClose>
              <Button variant="primary" @click="generateCaptions" class="ml-auto">
                <template v-if="isGenerating">
                  <div class="w-4 h-4 border-2 border-t-transparent border-current rounded-full animate-spin" />
                  Generating...
                </template>
                <template v-else>
                  <IconSaxMagicStar class="w-4 h-4 -scale-x-100" />
                  Generate
                </template>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      
      <template v-if="!isGenerating">
        <div class="flex flex-col pt-2 items-center text-center h-full gap-2">
          <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
          <p>
            <strong>Select the spoken language</strong>
          </p>
          <p class="my-2 font-light">
            Please select the language spoken in your clip to generate captions.
          </p>
        </div>
      </template>

      <div v-else class="flex flex-col pt-2 items-center text-center h-full gap-2">
        <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
        <p>
          <strong>Making magic</strong>
        </p>
        <p class="my-2 font-light">
          This process might take up to 20 seconds depending on the length of your clip.
        </p>
        <div class="text-emerald-600 bg-emerald-500/5 dark:text-emerald-500 dark:bg-emerald-500/5 p-4 rounded-lg border border-current flex justify-center items-center gap-4 font-light text-sm">
          You can continue working on your project while we generate the captions.
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>
