import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore';
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore';
import { useCropsStore } from '@/areas/editor/store/useCropsStore';
import { useStickersStore } from '@/areas/editor/store/useStickersStore';
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore';
import type { Project } from '@/areas/editor/@type/Project';
import { omit, isEqual } from 'lodash-es';
import type { EntityStore } from '@/areas/editor/store/useEntityStore';
import type { Reactive } from 'vue';
import unwrap from '@/helpers/unwrap';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'

export function useApplySnapshot() {

  const segmentsStore = useSegmentsStore();
  const layoutsStore = useLayoutsStore();
  const cropsStore = useCropsStore();
  const stickersStore = useStickersStore();
  const effectsStore = useEffectsStore();
  const captionsStore = useCaptionsStore();

  return (snapshot: Project) => {

    applyDifferences(segmentsStore, snapshot.segments);
    applyDifferences(layoutsStore, snapshot.layouts);
    applyDifferences(cropsStore, snapshot.crops);
    applyDifferences(stickersStore, snapshot.stickers);
    applyDifferences(effectsStore, snapshot.effects);

    // New captions.
    if ('entities' in snapshot.captions) {
      applyDifferences(captionsStore, snapshot.captions.entities);
      captionsStore.hasGeneratedCaptions = snapshot.captions.hasGeneratedCaptions;
      captionsStore.captionsArea = snapshot.captions.captionsArea;
      captionsStore.baseOptions = snapshot.captions.baseOptions;
      captionsStore.baseCaptionPreset = snapshot.captions.baseCaptionPreset;
    }
  }
}

type Store<T extends { id: string }> = Pick<EntityStore<T>['operations'], 'createById' | 'removeById'> & Pick<Reactive<EntityStore<T>>, 'state' | 'ids'>;

function applyDifferences<T extends { id: string }, TStore extends Store<T>>(store: TStore, items: T[]) {

  const itemsToRemove = store.ids.filter(id => !items.some(item => item.id === id)) as string[]
  for (const item of itemsToRemove) {
    store.removeById(item)
  }

  for (const item of items) {
    mergeById(store, item)
  }
}

function mergeById<T extends { id: string }, TStore extends Store<T>>(store: TStore, item: T) {
  const state = store.state[item.id]
  if (state) {
    const keys = unwrap.keys(omit(item, 'id'))
    for (const key of keys) {
      if (!isEqual(state[key], item[key])) {
        state[key] = item[key];
      }
    }
  } else {
    store.createById(item.id, item);
  }
}
