<script lang="ts" setup>
import {
  IconCheck,
  IconClockFilled,
  IconLoader,
  IconLoaderQuarter,
  IconRefresh,
  IconX,
  IconDotsVertical,
  IconEdit,
  IconTrash,
} from '@tabler/icons-vue'
import SocialMediaTarget from '@/components-v2/content-publisher/ContentPublisherSocialMediaTarget.vue'
import { computed, ref } from 'vue'
import { OverallPostStatus as Status } from '@/apis/streamladder-publisher/model'
import ConfirmDialog from '@/components/Dialog/Confirm/ConfirmDialog.vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import toastEvents from '@/events/toastEvents'
import { useToast } from '@/Hooks/useToast'
import IconSaxInformation from '@/components/Icons/iconsax/IconSaxInformation.vue'
import { Button } from '@/components/ui/button'
import Spinner from '@/components/Icons/Spinner.vue'
import { RouterLink } from 'vue-router'

const props = defineProps<{
  readonly modelValue: string | null
}>()

const emit = defineEmits<{
  (name: 'update:modelValue', value: null): void
}>()

function toDateString(date: string) {
  return new Date(date).toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  })
}

const { showToast } = useToast();

const store = useContentPublisherStore()
const post = computed(() => (props.modelValue ? store.entities[props.modelValue] : null))
const thumbnail = computed(() => post.value?.targets?.find((t) => t.thumbnailUrl)?.thumbnailUrl)

const contextMenuOpen = ref(false)
const confirmDialogOpen = ref(false)

function close() {
  confirmDialogOpen.value = false
  emit('update:modelValue', null)
}

const deletePost = () => {
  if (props.modelValue) {
    showToast({
      type: toastEvents.TOAST_SUCCESS,
      title: 'Post cancelled!',
      subtitle: 'Your post was cancelled successfully, it will not be posted.',
    })
    store.removePost(props.modelValue)
    close()
  }
}

useConditionalEventListener(
  computed(() => props.modelValue !== null),
  'keydown',
  (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close()
    }
  }
)

const retryConfirmDialogOpen = ref(false);
const retrying = ref(false);

const retry = async () => {
  retrying.value = true;

  // If post date is in the past, show confirm dialog
  if (post.value?.scheduledAt && new Date(post.value.scheduledAt).getTime() < new Date().getTime()) {
    retryConfirmDialogOpen.value = true;
  } else {
    await retryFailedPost();
    retrying.value = false;
  }
};

const retryFailedPost = async () => {
  if (props.modelValue && post.value) {
    retryConfirmDialogOpen.value = false;
    await store.retryPost(props.modelValue);
    await store.refetch();
    retrying.value = false;
  }
}
</script>

<template>
  <Teleport to="body">
    <section :class="{ 'modal-open dark:bg-zinc-900/90': post }" class="modal" @click="close">
      <div
        class="md:!max-w-screen layer-1 modal-box h-full max-h-[min(calc(100vh-5rem),_900px)] w-screen overflow-hidden rounded-lg p-0 lg:!max-w-[min-content]"
        @click.stop="contextMenuOpen = false"
      >
        <div v-if="post" class="flex h-full w-full">
          <div class="flex justify-center items-center bg-gray-900">
            <video
              :poster="thumbnail"
              :src="post.contentUrl"
              autoplay
              class="hidden aspect-[9/16] h-full w-auto self-stretch lg:flex"
              controls
              controlslist="nodownload, nofullscreen, noremoteplayback"
              disablepictureinpicture
              disableremoteplayback
              height="16"
              loop
              muted
              playsinline
              width="9"
            />
          </div>
          <div
            class="relative flex h-full max-h-full w-full flex-col items-center justify-center lg:min-w-[40ch] xl:min-w-[55ch]"
          >
            <div class="h-full w-full overflow-y-auto overflow-x-hidden">
              <div
                v-if="post.targets && post.targets.length > 0"
                class="flex min-h-full flex-col items-center justify-center gap-4 px-12 py-8 xl:px-24 xl:py-16"
              >
                <template
                  v-if="post.status.overallStatus === Status.Scheduled"
                >
                  <div
                    class="mask flex h-16 w-16 shrink-0 items-center justify-center bg-sky-600 text-white md:h-40 md:w-40 xl:mt-0"
                  >
                    <IconCheck class="h-8 w-8 stroke-[4] md:h-20 md:w-20" />
                  </div>
                  <header class="my-4 flex flex-col items-center gap-2 text-center">
                    <h4 class="m-0 p-0 text-2xl">Scheduled and ready</h4>
                    <p class="font-light leading-loose">
                      Your clip is set to be posted at the planned time. You can review or make changes as needed. We'll
                      take care of the rest.
                    </p>
                  </header>
                </template>
                <template v-else-if="post.status.overallStatus === Status.Publishing || post.status.overallStatus === Status.New">
                  <div
                    class="mask flex h-16 w-16 shrink-0 items-center justify-center bg-[#B3B3B3] text-white md:h-40 md:w-40"
                  >
                    <IconRefresh class="h-8 w-8 stroke-[2] md:h-20 md:w-20" />
                  </div>
                  <header class="my-6 flex flex-col items-center gap-2 text-center">
                    <h4 class="m-0 p-0 text-2xl">In progress 🍳</h4>
                    <p class="font-light leading-loose">
                      Your clip is currently being processed. It will be ready soon, and you can check its status here
                      anytime.
                    </p>
                  </header>
                </template>
                <template
                  v-else-if="
                    post.status.overallStatus === Status.Failed
                  "
                >
                  <div
                    class="mask flex h-16 w-16 shrink-0 items-center justify-center bg-rose-500 text-white md:h-40 md:w-40"
                  >
                    <IconX class="h-8 w-8 stroke-[4] md:h-20 md:w-20" />
                  </div>
                  <header class="mt-6 flex flex-col items-center gap-2 text-center">
                    <h4 class="m-0 p-0 text-2xl">Something went wrong 🙈</h4>
                    <p class="font-light leading-loose">
                      Unfortunately, your clip(s) failed to post. Let's review it together and get it back on track.
                    </p>
                  </header>

                  <Button variant="primary" :disabled="retrying" @click="retry">
                    <template v-if="!retrying">
                      <IconRefresh />
                      <span>Retry failed post(s)</span>
                    </template>
                    <template v-else>
                      <Spinner class="h-4 w-4 animate-spin" />
                      <span>Retrying..</span>
                    </template>
                  </Button>
                </template>
                <template
                  v-else-if="
                    post.status.overallStatus === Status.PartialFailure
                  "
                >
                  <div
                    class="mask flex h-16 w-16 shrink-0 items-center justify-center bg-rose-500 text-white md:h-40 md:w-40"
                  >
                    <IconSaxInformation class="h-8 w-8 stroke-[4] md:h-24 md:w-24" />
                  </div>
                  <header class="mt-6 flex flex-col items-center gap-2 text-center">
                    <h4 class="m-0 p-0 text-2xl">Could not post on a social account 😿</h4>
                    <p class="font-light leading-loose">
                      Unfortunately, your clip failed to post on one or more socials. Let's review it together and get it back on track.
                    </p>
                  </header>

                  <Button variant="primary" :disabled="retrying" @click="retry">
                    <template v-if="!retrying">
                      <IconRefresh />
                      <span>Retry failed post(s)</span>
                    </template>
                    <template v-else>
                      <Spinner class="h-4 w-4 animate-spin" />
                      <span>Retrying..</span>
                    </template>
                  </Button>
                </template>
                <template v-else-if="post.status.overallStatus === Status.Published">
                  <div
                    class="mask flex h-16 w-16 shrink-0 items-center justify-center bg-emerald-500 text-white md:h-40 md:w-40"
                  >
                    <IconCheck class="h-8 w-8 stroke-[4] md:h-20 md:w-20" />
                  </div>
                  <header class="my-6 flex flex-col items-center gap-2 text-center">
                    <h4 class="m-0 p-0 text-2xl">Successfully posted 🎉</h4>
                    <p class="font-light leading-loose">
                      Your clip is now live for all to see. Share it with your audience or keep track of how it's
                      performing.
                    </p>
                  </header>
                </template>

                <span class="my-4 block w-full text-center">
                  <IconClockFilled class="mr-2 inline-block shrink-0" />
                  {{ toDateString(post.scheduledAt ?? post.publishedAt ?? post.createdAt) }}
                </span>

                <ol class="layer-2 flex w-screen sm:w-full flex-col gap-1 rounded-lg p-2 sm:p-4">
                  <SocialMediaTarget
                    v-for="target of post.targets"
                    :key="target.id"
                    :post-id="post.id!"
                    :target="target"
                  />
                </ol>
              </div>
            </div>

            <div
              v-if="post.status.overallStatus !== Status.Published && post.status.overallStatus !== Status.Publishing"
              class="layer-2 flex w-full shrink-0 flex-row items-center justify-end p-3"
            >
              <div class="relative flex flex-row gap-2" @click.stop>
                <!-- Currently whenever a post has failed or partially failed and you remove or add social media targets, the targets don't change. For now, only enable the edit button when scheduled successfully. -->
                <Button
                  v-if="post.status.overallStatus === Status.Scheduled || post.status.overallStatus === Status.Failed || post.status.overallStatus === Status.PartialFailure"
                  variant="outline"
                  :as="RouterLink"
                  :to="{ name: dashboardRouteNames.contentPublisher.post, params: { postId: post.id } }"
                  class="flex justify-start gap-1 whitespace-nowrap px-3 py-1 text-left text-sm"
                >
                  <IconEdit class="w-4 h-4" />
                  <span>Edit</span>
                </Button>
                <Button
                  variant="outline"
                  class="flex w-full items-center justify-start gap-1 whitespace-nowrap px-3 py-1 text-left text-rose-500 dark:text-red-500 hover:bg-rose-500 hover:text-white text-sm"
                  @click="confirmDialogOpen = true"
                >
                  <IconTrash class="w-4 h-4" />
                  <span>Cancel</span>
                </Button>
              </div>
            </div>

            <div v-if="store.isPerformingBackgroundAction" class="absolute left-2 top-2 shrink-0 text-zinc-400">
              <IconLoader class="absolute h-6 w-6 drop-shadow-2xl" />
              <IconLoaderQuarter class="spinner absolute h-6 w-6 text-company-primary-50" />
            </div>
          </div>
        </div>

        <button class="btn-ghost btn-circle btn absolute right-2 top-2">
          <IconX @click="close" />
        </button>
      </div>
    </section>
  </Teleport>
  <Teleport to="body">
    <ConfirmDialog
      v-if="confirmDialogOpen"
      message="Your clip will remain available on StreamLadder."
      title="Are you sure you want to cancel this post?"
      @cancel="confirmDialogOpen = false"
      @confirm="deletePost"
    />
  </Teleport>
  <Teleport to="body">
    <ConfirmDialog
      v-if="retryConfirmDialogOpen"
      title="Are you sure you want to retry? 🙊"
      message="It will be posted immediately to your socials."
      @cancel="() => {retryConfirmDialogOpen = false; retrying = false}"
      @confirm="retryFailedPost"
    />
  </Teleport>
</template>

<style lang="scss" scoped>
.mask {
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iY3VycmVudENvbG9yIiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgPHBhdGggZD0iTTEyLjAxIDIuMDExYTMuMiAzLjIgMCAwIDEgMi4xMTMgLjc5N2wuMTU0IC4xNDVsLjY5OCAuNjk4YTEuMiAxLjIgMCAwIDAgLjcxIC4zNDFsLjEzNSAuMDA4aDFhMy4yIDMuMiAwIDAgMSAzLjE5NSAzLjAxOGwuMDA1IC4xODJ2MWMwIC4yNyAuMDkyIC41MzMgLjI1OCAuNzQzbC4wOSAuMWwuNjk3IC42OThhMy4yIDMuMiAwIDAgMSAuMTQ3IDQuMzgybC0uMTQ1IC4xNTRsLS42OTggLjY5OGExLjIgMS4yIDAgMCAwIC0uMzQxIC43MWwtLjAwOCAuMTM1djFhMy4yIDMuMiAwIDAgMSAtMy4wMTggMy4xOTVsLS4xODIgLjAwNWgtMWExLjIgMS4yIDAgMCAwIC0uNzQzIC4yNThsLS4xIC4wOWwtLjY5OCAuNjk3YTMuMiAzLjIgMCAwIDEgLTQuMzgyIC4xNDdsLS4xNTQgLS4xNDVsLS42OTggLS42OThhMS4yIDEuMiAwIDAgMCAtLjcxIC0uMzQxbC0uMTM1IC0uMDA4aC0xYTMuMiAzLjIgMCAwIDEgLTMuMTk1IC0zLjAxOGwtLjAwNSAtLjE4MnYtMWExLjIgMS4yIDAgMCAwIC0uMjU4IC0uNzQzbC0uMDkgLS4xbC0uNjk3IC0uNjk4YTMuMiAzLjIgMCAwIDEgLS4xNDcgLTQuMzgybC4xNDUgLS4xNTRsLjY5OCAtLjY5OGExLjIgMS4yIDAgMCAwIC4zNDEgLS43MWwuMDA4IC0uMTM1di0xbC4wMDUgLS4xODJhMy4yIDMuMiAwIDAgMSAzLjAxMyAtMy4wMTNsLjE4MiAtLjAwNWgxYTEuMiAxLjIgMCAwIDAgLjc0MyAtLjI1OGwuMSAtLjA5bC42OTggLS42OTdhMy4yIDMuMiAwIDAgMSAyLjI2OSAtLjk0NHptMy42OTciIHN0cm9rZS13aWR0aD0iMCIgZmlsbD0iY3VycmVudENvbG9yIiAvPgo8L3N2Zz4K);
}

.spinner {
  animation: spin 1s ease-in infinite;
}

@keyframes spin {
  0%,
  12.49% {
    transform: rotate(0deg);
  }

  12.5%,
  24.99% {
    transform: rotate(45deg);
  }

  25%,
  37.49% {
    transform: rotate(90deg);
  }

  37.5%,
  49.99% {
    transform: rotate(135deg);
  }

  50%,
  62.49% {
    transform: rotate(180deg);
  }

  62.5%,
  74.99% {
    transform: rotate(225deg);
  }

  75%,
  87.49% {
    transform: rotate(270deg);
  }

  87.5%,
  100% {
    transform: rotate(315deg);
  }
}
</style>
