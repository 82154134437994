import type { Project, Segment } from '@/areas/editor/@type/Project';

type PartialNullable<T> = {
  [P in keyof T]?: T[P] | undefined | null
};

export function ensureValidSnapshot(snapshot: PartialNullable<Project>): Project {
  return {
    id: snapshot?.id ?? undefined,
    title: snapshot?.title ?? undefined,
    mp4Url: snapshot?.mp4Url ?? undefined,
    language: snapshot?.language ?? undefined,
    segments: snapshot?.segments ?? [] as Segment[],
    layouts: snapshot?.layouts ?? [],
    crops: snapshot?.crops ?? [],
    stickers: snapshot?.stickers ?? [],
    effects: snapshot?.effects ?? [],
    // captions: snapshot?.captions ?? takeCaptionsSnapshot(),
  }
}
