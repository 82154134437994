<script setup lang="ts">
import TimelineRoot from '@/modules/SLTimeline/TimelineRoot.vue'
import TimelineContainer from '@/modules/SLTimeline/TimelineContainer.vue'
import Timeline from '@/modules/SLTimeline/Timeline.vue'
import { useProvideTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { computed, nextTick, ref, watch, reactive } from 'vue'
import TimeLinePlayHead from '@/modules/SLTimeline/TimeLinePlayHead.vue'
import { useRafFn, useThrottleFn, watchDeep } from '@vueuse/core'
import TimelineHeader from '@/components/Editor/Timeline/TimelineHeader.vue'
import TimeLineDialsTrack from '@/modules/SLTimeline/TimeLineDialsTrack.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { onKeyStroke } from '@vueuse/core/index'
import TimelineFooter from '@/components/Editor/Timeline/TimelineFooter.vue'
import { isInputTarget, isForeignTarget } from '@/components/Editor/Timeline/helpers'
import CaptionTrack from '@/components/Editor/Timeline/tracks/CaptionTrack.vue'
import ZoomTrack from '@/components/Editor/Timeline/tracks/ZoomTrack.vue'
import SegmentTrack from '@/components/Editor/Timeline/tracks/SegmentTrack.vue'
import StickerTrack from '@/components/Editor/Timeline/tracks/StickerTrack.vue'

const props = defineProps<{
  step: 'layout' | 'crop' | 'editor'
  defaultOpen?: boolean
}>()

const editorVideoStore = useEditorVideoStore()
const editorFeedStore = useEditorFeedDataStore()
const editorFocusStore = useEditorFocusStore()

const totalDurationMs = computed(() => editorVideoStore._duration * 1000)

const updateFunction = () => {
  updateWordsTimer(editorVideoStore.getExactTime())
}

const { pause, resume } = useRafFn(updateFunction, { immediate: false })

const resumeVideoAndTimeline = () => {
  resume()
  editorVideoStore.playing = true
}

const pauseVideoAndTimeline = () => {
  pause()
  editorVideoStore.playing = false
}

watchDeep(
  () => editorFocusStore.focus,
  (value) => {
    nextTick(() => updateWordsTimer(editorVideoStore.getExactTime() + 0.001))
  }
)

const commitSeekThrottle = useThrottleFn(
  (ms: number) => {
    editorVideoStore._currentTime = ms / 1000
  },
  200,
  true
)

let wasPlaying = false
const commitSeek = (ms: number, scrubbing = false) => {
  editorVideoStore.scrubbing = scrubbing
  if (scrubbing && editorVideoStore.playing) {
    wasPlaying = true
    editorVideoStore.playing = false
  }
  if (!scrubbing && wasPlaying) {
    editorVideoStore.playing = true
    wasPlaying = false
  }
  currentTimeMs.value = ms
  commitSeekThrottle(ms)
}

const {
  zoomLevel,
  autoScroll,
  open: timelineOpen,
} = useProvideTimelineStore(totalDurationMs, {
  minVisibleMs: 500,
  onSeek: commitSeek,
  openDefault: props.defaultOpen,
})

watch(
  () => editorVideoStore.playing,
  (value) => {
    value && !editorVideoStore.scrubbing && !editorVideoStore.preservedPaused
      ? resumeVideoAndTimeline()
      : pauseVideoAndTimeline()
  }
)

onKeyStroke(['Backspace', 'Delete', 'Del'], (e) => {
  if (isInputTarget(e)) return
  editorFocusStore.deleteFocusModel()
})

onKeyStroke(['s'], async (e) => {
  if (isInputTarget(e)) return
  editorFocusStore.splitFocusModel(currentTimeMs.value)
})

onKeyStroke([' '], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  if (isForeignTarget(e)) return

  e.preventDefault()
  editorVideoStore.preservedPaused = !editorVideoStore.preservedPaused
  editorVideoStore.playing = !editorVideoStore.playing
})

onKeyStroke(['m'], (e) => {
  if (isInputTarget(e)) return
  editorVideoStore.muted = !editorVideoStore.muted
})

const currentTimeMs = ref(0)

const updateWordsTimer = (currentTime: number) => {
  if (editorVideoStore.seeking) return
  if (currentTime !== currentTimeMs.value) {
    currentTimeMs.value = currentTime
    if (!editorVideoStore.scrubbing) {
      autoScroll(currentTimeMs.value)
    }
  }
}

const dialStepSize = computed(() => {
  return zoomLevel.value < 0.5 ? 500 : 1000
})

const dialNumberInterval = computed(() => {
  return zoomLevel.value < 0.5 ? 10 : 5
})

const showZoomTrack = computed(() => {
  return props.step === 'editor'
})

const inputBindings = reactive(editorVideoStore.currentTimeBindings)
</script>

<template>
  <TimelineRoot class="flex transform flex-col border-gray-100 bg-white transition-[height]">
    <div
      v-if="!timelineOpen"
      class="relative flex h-1.5 origin-bottom transform border-transparent transition-[height] hover:h-2.5"
    >
      <div class="absolute inset-0 bg-gray-100" />
      <div class="absolute inset-y-0 left-0 bg-company-primary-500" :style="{ width: inputBindings.progress + '%' }" />
      <input
        type="range"
        class="absolute inset-0 w-full overflow-hidden opacity-0"
        v-bind="inputBindings"
        v-model="inputBindings.model"
        @mousedown="inputBindings.onMouseDown"
        @mouseup="inputBindings.onMouseUp"
      />
    </div>
    <TimelineHeader class="px-4 lg:container lg:mx-auto lg:px-0" />
    <Transition>
      <TimelineContainer
        v-if="timelineOpen"
        class="min-h-16 relative flex h-full w-full flex-col overflow-y-hidden border-t border-zinc-400 bg-surface-panel-50"
      >
        <Timeline class="relative flex h-full flex-grow flex-col gap-2 pb-2">
          <TimeLineDialsTrack
            class="h-4 w-full pt-2 text-xs font-normal text-gray-300"
            :duration="editorVideoStore._duration"
            :stepSize="dialStepSize"
            :number-interval="dialNumberInterval"
          ></TimeLineDialsTrack>
          <StickerTrack />
          <CaptionTrack />
          <ZoomTrack v-if="showZoomTrack" />
          <SegmentTrack />
          <div class="flex h-4 w-full"></div>
          <!--          <div class="w-full md:h-8"></div>-->

          <TimeLinePlayHead
            :current-time="currentTimeMs"
            class="no-drag pointer-events-none absolute inset-y-0 z-20 flex h-full -translate-x-1/2 transform cursor-col-resize px-2"
          >
            <div class="h-full w-[4px] bg-gray-950" />
          </TimeLinePlayHead>
          <!--        <TimeLineSeeker class="no-drag pointer-events-none absolute inset-y-0 flex h-full -translate-x-1/2 transform">-->
          <!--          <div class="h-full w-[4px] bg-gray-500" />-->
          <!--        </TimeLineSeeker>-->
        </Timeline>
      </TimelineContainer>
    </Transition>
    <TimelineFooter v-if="timelineOpen" class="md:hidden" />
  </TimelineRoot>
</template>

<style scoped lang="scss"></style>
