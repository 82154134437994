/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { GetApiTwitchVodsParams, VodDTO } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiTwitchVodsVideoId = (
  videoId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  videoId = unref(videoId)

  return streamLadderAxiosInstance<VodDTO>({ url: `/api/TwitchVods/${videoId}`, method: 'GET', signal }, options)
}

export const getGetApiTwitchVodsVideoIdQueryKey = (videoId: MaybeRef<string>) => {
  return ['api', 'TwitchVods', videoId] as const
}

export const getGetApiTwitchVodsVideoIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTwitchVodsVideoId>>,
  TError = unknown
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVodsVideoId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchVodsVideoIdQueryKey(videoId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchVodsVideoId>>> = ({ signal }) =>
    getApiTwitchVodsVideoId(videoId, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(videoId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVodsVideoId>>, TError, TData>
}

export type GetApiTwitchVodsVideoIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTwitchVodsVideoId>>>
export type GetApiTwitchVodsVideoIdQueryError = unknown

export const useGetApiTwitchVodsVideoId = <
  TData = Awaited<ReturnType<typeof getApiTwitchVodsVideoId>>,
  TError = unknown
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVodsVideoId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchVodsVideoIdQueryOptions(videoId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiTwitchVodsVideoIdImport = (
  videoId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  videoId = unref(videoId)

  return streamLadderAxiosInstance<VodDTO>({ url: `/api/TwitchVods/${videoId}/import`, method: 'GET', signal }, options)
}

export const getGetApiTwitchVodsVideoIdImportQueryKey = (videoId: MaybeRef<string>) => {
  return ['api', 'TwitchVods', videoId, 'import'] as const
}

export const getGetApiTwitchVodsVideoIdImportQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTwitchVodsVideoIdImport>>,
  TError = unknown
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVodsVideoIdImport>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchVodsVideoIdImportQueryKey(videoId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchVodsVideoIdImport>>> = ({ signal }) =>
    getApiTwitchVodsVideoIdImport(videoId, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(videoId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVodsVideoIdImport>>, TError, TData>
}

export type GetApiTwitchVodsVideoIdImportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiTwitchVodsVideoIdImport>>
>
export type GetApiTwitchVodsVideoIdImportQueryError = unknown

export const useGetApiTwitchVodsVideoIdImport = <
  TData = Awaited<ReturnType<typeof getApiTwitchVodsVideoIdImport>>,
  TError = unknown
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVodsVideoIdImport>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchVodsVideoIdImportQueryOptions(videoId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiTwitchVods = (
  params?: MaybeRef<GetApiTwitchVodsParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return streamLadderAxiosInstance<VodDTO[]>(
    { url: `/api/TwitchVods`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiTwitchVodsQueryKey = (params?: MaybeRef<GetApiTwitchVodsParams>) => {
  return ['api', 'TwitchVods', ...(params ? [params] : [])] as const
}

export const getGetApiTwitchVodsQueryOptions = <TData = Awaited<ReturnType<typeof getApiTwitchVods>>, TError = unknown>(
  params?: MaybeRef<GetApiTwitchVodsParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVods>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchVodsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchVods>>> = ({ signal }) =>
    getApiTwitchVods(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiTwitchVods>>,
    TError,
    TData
  >
}

export type GetApiTwitchVodsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTwitchVods>>>
export type GetApiTwitchVodsQueryError = unknown

export const useGetApiTwitchVods = <TData = Awaited<ReturnType<typeof getApiTwitchVods>>, TError = unknown>(
  params?: MaybeRef<GetApiTwitchVodsParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchVods>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchVodsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
