<script setup lang="ts">
import { type Bindings, type KeybindingConfig, useKeyCombination } from '@/lib/keybindings';
import { type ButtonProps, Button } from '@/components/ui/button';
import { omit } from 'lodash-es';
import Keyboard from '@/components/ui/keyboard/Keyboard.vue';

type Props = KeybindingConfig & ButtonProps & {
  event: Bindings;
  limit?: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (name: 'event', event: Event): void }>();
function onKeyCombination(event: Event) {
  emit('event', event);
}

useKeyCombination(props.event, onKeyCombination, props);

const buttonProps = computed<ButtonProps>(() => omit(props, ['event', 'prevent', 'stop']));
</script>

<template>
  <Button v-bind="buttonProps" @click="emit('event', $event)">
    <slot />
    <Keyboard :event="event" :limit="limit" />
  </Button>
</template>

<style scoped lang="scss">

</style>
