<template>
  <Dialog v-model:open="open">
    <DialogContent>
      <DialogHeader>
        <DialogTitle class="text-brand-state-text-primary">QR-code</DialogTitle>
      </DialogHeader>
      <a :href="urlToResult" class="flex justify-center">
        <canvas id="qrCodeResult" ref="qrCodeContainer" class="w-full max-w-80"></canvas>
      </a>
      <p class=" my-2">Scan this QR-code with your phone to download the clip to your phone.</p>
      <div class="flex w-full justify-end">
        <Button @click="closeDialog" class="w-full" :show-icon="false">
          Close
        </Button>
      </div>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import QRious from 'qrious'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { Button } from "@/components/ui/button";
import { Dialog, DialogHeader, DialogContent } from '@/components/ui/dialog/';
import { useGuard } from "@/Hooks/useGuard";
import { useRouter } from "vue-router";
import { DialogTitle } from "radix-vue";

const urlToResult = ref('');
const open = ref(false);
const router = useRouter();

function validate() {
  if (!useGuard('qr-code-download')) {
    upgradeDialog.open('qr-code-download')

    return false
  }
  return true
}

async function publish(videoUrl: string) {
  urlToResult.value =
    location.origin + router.resolve({ name: 'DownloadResult', query: { downloadSrc: encodeURIComponent(videoUrl) } }).href
  await setQrCode(urlToResult.value)
}

async function setQrCode(text: string) {
  open.value = true;
  nextTick(() => {
    new QRious({
      element: document.getElementById('qrCodeResult'),
      value: text,
      size: 400,
    })
  })
}

function closeDialog() {
  open.value = false;
}


defineExpose({
  publish,
  validate
})
</script>

<style></style>
