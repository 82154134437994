<script setup lang="ts">
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import TimeLineAddNode from '@/areas/editor/timeline/tracks/TimeLineAddNode.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import ZoomNode from '@/areas/editor/timeline/tracks/zooms/ZoomNode.vue'

const segmentsStore = useSegmentsStore()
const zooms = segmentsStore.whereIsZoom()
</script>

<template>
  <TimeLineTrack class="group relative h-full max-h-12 w-full" v-if="zooms.length > 0">
    <TimeLineAddNode />
    <ZoomNode v-for="(zoom, i) in zooms" :key="zoom.id" :index="i" :id="zoom.id" :zooms="zooms" />
  </TimeLineTrack>
</template>
