<script lang="ts" setup>
import DashboardNavigation from '@/areas/dashboard/layout/nav/DashboardNavigation.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useScreen } from '@/Hooks/useScreen'
import { useHead } from '@unhead/vue'
import { useTheme } from '@/Hooks/useTheme'

const isDesktop = useScreen('lg')
const { theme } = useTheme()
useHead({
  bodyAttrs: {
    class: theme
  },
})
</script>

<template>
  <main class="bg-surface-bg min-h-[100dvh] lg:h-[100dvh] grid">
    <section class="dashboard mx-auto grid min-[1920px]:max-w-[1920px] min-h-0 w-full">
      <Component :is="isDesktop ? ScrollArea : 'div'" class="dashboard__view">
        <section class="h-full mx-0 sm:mx-4 pb-4 mt-0 min-h-0 lg:m-6 lg:ml-0 lg:pb-0">
          <div
            ref="view"
            class="h-full w-full min-h-0 rounded-2xl border border-surface-panel-border bg-surface-panel text-brand-state-text-primary shadow-lg"
          >
            <slot name="router-view" v-if="$slots['router-view']" />
            <RouterView v-else />
          </div>
        </section>
      </Component>

      <!-- Navigation is placed after view intentionally to ensure any flyouts overlap the view. Grid Area is used for placement -->
      <header class="dashboard__nav will-change-contents lg:flex lg:flex-col lg:min-h-0">
        <DashboardNavigation class="p-4 lg:p-6" />
      </header>
    </section>
  </main>
</template>

<style lang="scss">
.dashboard {
  font-family: 'Metropolis', sans-serif;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas:
    'navigation'
    'view';

  @screen lg {
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas: 
      'navigation view';
  }

  .dashboard__nav {
    grid-area: navigation;
  }

  .dashboard__view {
    grid-area: view;
    // For some reason this prevents the view from overflowing the grid.
    @screen lg {
      grid-column-end: none;
    }
  }

  .dashboard__footer {
    grid-area: footer;
  }
}
</style>
