import { useCropsStore } from '@/areas/editor/store/useCropsStore';
import { pick } from 'lodash-es';
import { useVideoStore } from '@/areas/editor/store/useVideoStore';

export function saveCropsInLocalStorage(...ids: string[]) {
  const videoStore = useVideoStore();
  const cropsStore = useCropsStore();
  for (const id of ids) {
    const crop = cropsStore.selectById(id);
    if (crop.storageKey) {
      localStorage.setItem(crop.storageKey, JSON.stringify({
        ...pick(crop, ['x', 'y', 'width', 'height', 'feedData', 'input']),
        videoWidth: videoStore.videoSize?.width,
        videoHeight: videoStore.videoSize?.height,
      }))
    }
  }
}
