<script lang="ts" setup>
import { ref, type Component } from 'vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'

defineProps<{
  error?: { title: string, message: string }
  loading?: boolean
  cta: string
  placeholder: string
  icon?: Component
}>()

const value = ref('')

const emit = defineEmits<{ 
  (name: 'submit', value: string): void
  (name: 'paste', value: string): void
}>()

// Wait for the value to be updated before emitting the paste event
function onPaste() {
  setTimeout(() => {
    emit('paste', value.value)
  }, 0)
}
</script>

<template>
  <section class="light flex flex-col gap-4">
    <Input
      as="form"
      class="relative w-full border-none p-0 shadow-lg"
      :class="{ 'outline outline-2 outline-offset-1 outline-rose-500': error }"
      @submit.prevent.stop="emit('submit', value)"
    >
      <div class="absolute left-3 top-2.5 shrink-0 pr-0 text-rose-500" v-if="error">
        <IconSaxInfoCircle />
      </div>
      <input
        v-model="value"
        @paste="onPaste"
        class="h-full min-w-0 flex-auto rounded-r-none bg-transparent px-3"
        :class="{ 'pl-11 text-rose-500': error }"
        :placeholder="placeholder"
        type="text"
      />
      <Button
        class="group rounded-l-none rounded-r-md h-full"
        type="submit"
        :disabled="loading"
      >
        <template v-if="loading">
          <span class="animate-spin h-4 w-4 border border-current border-t-transparent rounded-full" />
          <span class="hidden md:inline">Searching...</span>
        </template>
        <template v-else>
          <span class="hidden md:inline">{{ cta }}</span>
          <component :is="icon ?? IconSaxArrowRight2" class="h-4 w-4 transition-transform group-hover:translate-x-2 md:mr-2" />
        </template>
      </Button>
    </Input>

    <ClipUrlError :error="error" />

    <slot></slot>
  </section>
</template>

<style lang="scss" scoped>
input:placeholder-shown {
  text-overflow: ellipsis;
}
</style>
