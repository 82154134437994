<script lang="ts" setup>
import type { VodDTO, CreateClipGptProjectRequest } from '@/apis/streamladder-api/model'
import { DialogTitle, DialogDescription, DialogHeader, DialogFooter, DialogClose, Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog'
import { ref, reactive, computed } from 'vue'
import AppImage from '@/areas/editor/components/AppImage.vue'
import { Skeleton } from '@/components/ui/skeleton'
import { Input } from '@/components/ui/input'
import { Select, SelectTrigger, SelectContent, SelectItem } from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import unwrap from '@/helpers/unwrap'
import { postApiClipGptProjects } from '@/apis/streamladder-api/clip-gpt-projects/clip-gpt-projects'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { AxiosError } from 'axios'
import { posthog } from 'posthog-js'
import InfoIcon from '@/components/Icons/InfoIcon.vue'
import { useClipGptProjects } from '@/areas/dashboard/pages/clipGPT/useClipGptProjects'
import { useIntervalFn } from '@vueuse/core'
import RadixErrorDialog from '@/components/Dialog/RadixErrorDialog.vue'
import logging from '@/logging'

const props = defineProps<{ vod: VodDTO }>()

const isCreatingProject = ref(false)
const createProjectError = ref<string>()
const validationErrors = ref<Partial<Record<keyof typeof formData, string>>>({})

async function submit() {

  const result = validate()
  isCreatingProject.value = true

  if (result.ok) {
    try {
      await createProject()
    } catch (e) {
      createProjectError.value = (e as Error).message
    }
  }
  isCreatingProject.value = false
}

const router = useRouter()

async function createProject() {

  const response = await postApiClipGptProjects(formData).catch(handleAxiosError)

  logging.trackEvent('ClipGPT Project Created', {
    streamDurationMinutes: Math.round((props.vod.durationSeconds ?? 0) / 60),
    streamerName: props.vod.broadcasterName,
    languageCode: formData.languageCode,
  })

  if (response?.id) {
    await router.push({
      name: dashboardRouteNames.clipGpt.project,
      params: { projectId: response.id },
    })
  } else {
    throw new Error('Failed to create your project. Please use another video or try again later.')
  }
}

function validate(...fields: (keyof typeof formData)[]) {

  if (fields.length === 0) {
    fields = unwrap.keys(formData)
  }

  if (fields.includes('streamerName')) {
    delete validationErrors.value.streamerName
    if (!formData.streamerName) {
      validationErrors.value.streamerName = 'Please enter a streamer name'
    } else if (formData.streamerName.length > 50) {
      validationErrors.value.streamerName = 'Streamer name must be at most 50 characters long'
    } else if (formData.streamerName.length < 3) {
      validationErrors.value.streamerName = 'Streamer name must be at least 3 characters long'
    }
  }

  if (fields.includes('languageCode')) {
    delete validationErrors.value.languageCode
    if (!formData.languageCode) {
      validationErrors.value.languageCode = 'Please select a language'
    }
  }

  return { ok: Object.keys(validationErrors.value).length === 0 }
}

function handleAxiosError(e: unknown) {

  if (e instanceof AxiosError) {
    switch (e.response?.data as string) {
      case 'daily_creation_limit_reached':
        throw new Error('You have reached the daily limit for creating projects. Please try again tomorrow.')
      case 'invalid_video_id':
        throw new Error('Invalid video ID. Please use another video or try again later.')
    }
  }

  throw new Error('Failed to create your project. Please use another video or try again later.')
}

const supportedLocales = [
  { code: 'en_us', label: '🇺🇸 English' }, { code: 'es', label: '🇪🇸 Spanish' }, { code: 'fr', label: '🇫🇷 French' },
  { code: 'de', label: '🇩🇪 German' }, { code: 'it', label: '🇮🇹 Italian' }, { code: 'nl', label: '🇳🇱 Dutch' },
  { code: 'pt', label: '🇵🇹 Portuguese' }, { code: 'hi', label: '🇮🇳 Hindi' }, { code: 'ja', label: '🇯🇵 Japanese' },
  { code: 'zh', label: '🇨🇳 Chinese' }, { code: 'fi', label: '🇫🇮 Finnish' }, { code: 'ko', label: '🇰🇷 Korean' },
  { code: 'pl', label: '🇵🇱 Polish' }, { code: 'ru', label: '🇷🇺 Russian' }, { code: 'tr', label: '🇹🇷 Turkish' },
  { code: 'uk', label: '🇺🇦 Ukrainian' }, { code: 'vi', label: '🇻🇳 Vietnamese' }]

function findMatchingLanguage(vod: VodDTO) {
  if (vod.language === 'en') {
    return 'en_us'
  } else if (supportedLocales.some((locale) => locale.code === props.vod.language)) {
    return props.vod.language
  } else {
    return null
  }
}

const thumbnail = computed(() => {
  if (props.vod.thumbnailUrl) {
    return props.vod.thumbnailUrl
      .replace('%{width}', '1280')
      .replace('%{height}', '720')
  } else {
    return null
  }
})

const formData = reactive<CreateClipGptProjectRequest>({
  videoId: props.vod.streamId,
  streamerName: props.vod.broadcasterName,
  thumbnailUrl: thumbnail.value,
  title: props.vod.title || 'StreamLadder AI Highlight',
  languageCode: findMatchingLanguage(props.vod),
})

const language = computed(() => {
  return supportedLocales.find((locale) => locale.code === formData.languageCode)
})


const { projects } = useClipGptProjects()

const now = ref(new Date().getTime())

useIntervalFn(() => {
  now.value = new Date().getTime()
}, 10_000)

const projectsInTheLast24Hours = computed(() => {
  return projects.value.filter((project) => {
    const createdAt = new Date(project.createdAt)

    // filter out failed projects, then don't count towards the limit
    return (now.value - createdAt.getTime() < 24 * 60 * 60 * 1000) && project.status !== 'failed'
  })
})

const amountOfProjectsToday = computed(() => projectsInTheLast24Hours.value.length)
const maxAmountOfProjectsPerDay = 3

const hasReachedDailyLimit = computed(() => amountOfProjectsToday.value >= maxAmountOfProjectsPerDay)
</script>

<template>
  <TooltipProvider>
    <form class="flex flex-col gap-6" @submit.prevent.stop="submit">
      <DialogHeader class="flex flex-col gap-1 items-center">
        <p class="text-brand-state-text-secondary text-lg font-semibold">
          You've selected
        </p>
        <DialogTitle
          as="h2"
          class="text-3xl lg:text-5xl font-bold font-title text-brand-state-text-primary px-4 text-center text-balance"
        >
          {{ vod.title }}
        </DialogTitle>
        <DialogDescription class="sr-only">
          Automatically find the best moments from your stream using StreamLadder AI!
        </DialogDescription>
      </DialogHeader>

      <div class="flex flex-col items-center gap-1">
        <Tooltip>
          <TooltipTrigger as-child>
            <span class="text-foreground flex items-center gap-1">Daily Limit <InfoIcon class="w-5 h-5" /></span>
          </TooltipTrigger>
          <TooltipContent as-child>
            <p class="max-w-md">
              During beta, you can generate up to {{ maxAmountOfProjectsPerDay }} streams per day. This helps everyone try out the AI without overloading the system.
            </p>
          </TooltipContent>
        </Tooltip>

        <div v-if="hasReachedDailyLimit" class="flex rounded-lg border border-yellow-600 bg-yellow-50 text-yellow-600 dark:text-yellow-400 font-semibold px-6 py-2 dark:bg-yellow-900">
          You’ve reached your daily limit of {{ maxAmountOfProjectsPerDay }} streams. Come back tomorrow to generate more clips!
        </div>
        <div v-else class="flex rounded-lg border border-green-500 bg-green-100 text-green-700 dark:text-green-400 font-semibold px-6 py-2 dark:bg-green-900">
          {{ amountOfProjectsToday }} / {{ maxAmountOfProjectsPerDay }}
        </div>
      </div>

      <div
        v-if="thumbnail"
        class="w-full relative aspect-video object-cover border-4 border-black rounded-2xl overflow-hidden bg-zinc-200"
      >
        <Skeleton class="w-full h-full absolute inset-0" />
        <AppImage
          :alt="`Thumbnail of '${vod.title}'`"
          :src="thumbnail"
          class="w-full h-full relative"
        />
      </div>

      <div class="w-full max-w-screen-sm mx-auto">
        <div class="flex flex-col sm:flex-row gap-2 sm:gap-4">
          <div class="flex flex-col gap-1 flex-1">
            <Tooltip>
              <TooltipTrigger as-child>
                <Label :class="validationErrors.streamerName ? 'text-red-500' : ''" for="streamer-name">
                  Streamer name
                  <IconSaxInfoCircle class="w-4 h-4 rotate-180" />
                </Label>
              </TooltipTrigger>
              <TooltipContent>
                Use your display name to help the AI get the captions just right! 👌
              </TooltipContent>
            </Tooltip>
            <Input
              id="streamer-name" v-model="formData.streamerName"
              :class="validationErrors.streamerName ? 'border-red-500' : ''"
              class="h-10 border-input bg-background" @change="validate('streamerName')"
            />
            <p v-if="validationErrors.streamerName" class="text-red-500">
              {{ validationErrors.streamerName }}
            </p>
          </div>
          <div class="flex flex-col gap-1 flex-1">
            <Label :class="validationErrors.languageCode ? 'text-red-500' : ''" for="language">Language</Label>
            <Select v-model="formData.languageCode" @update:modelValue="validate('languageCode')">
              <SelectTrigger id="language" :class="validationErrors.languageCode ? 'border-red-500 text-red-500' : ''">
                <template v-if="language">
                  {{ language.label }}
                </template>
                <template v-else>
                  Select language
                </template>
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="locale in supportedLocales" :key="locale.code" :value="locale.code">
                  {{ locale.label }}
                </SelectItem>
              </SelectContent>
            </Select>
            <p v-if="validationErrors.languageCode" class="text-red-500">
              {{ validationErrors.languageCode }}
            </p>
          </div>
        </div>

        <div v-if="createProjectError" class="bg-surface-panel-100 text-red-500 flex items-center gap-2">
          <IconSaxWarning2 class="shrink-0" />
          <p class="text-pretty">
            {{ createProjectError }}
          </p>
        </div>
      </div>

      <DialogFooter class="gap-2 !flex-col-reverse max-w-screen-sm w-full mx-auto">
        <DialogClose as-child>
          <Button class="mx-auto" type="button" variant="ghost">
            Cancel
          </Button>
        </DialogClose>
        
        <RadixErrorDialog 
          v-if="vod.isSubOnly"
        >
          <template #title>This stream is subscriber&nbsp;only</template>
          <template #description>
            We cannot analyse this stream as it is only available to your subscribers. Please make this stream publicly available or try a different stream.
          </template>
          <template #trigger>
            <Button size="lg" type="button" variant="primary">
              Create clips from this stream
              <IconSaxMagicpen />
            </Button>
          </template>
        </RadixErrorDialog>
        <Button v-else :disabled="isCreatingProject || hasReachedDailyLimit" size="lg" type="submit" variant="primary">
          <template v-if="isCreatingProject">
            <div class="w-4 h-4 border-2 border-current border-t-transparent rounded-full animate-spin" />
            Creating project...
          </template>
          <template v-else>
            Create clips from this stream
            <IconSaxMagicpen />
          </template>
        </Button>
      </DialogFooter>
    </form>
  </TooltipProvider>
</template>

<style lang="scss" scoped>

</style>
