import { ref } from 'vue';
import { storeProject } from '@/queries/projects/projectsApi';
import { useProjectStore } from '@/areas/editor/store/useProjectStore';
import { clone, debounce } from 'lodash-es';

export const isSavingProject = ref(false);

export async function saveProject() {

  if (isSavingProject.value) {
    return;
  }

  const projectsStore = useProjectStore();
  const project = clone(projectsStore.project);
  if (!project?.id) {
    return;
  }

  isSavingProject.value = true;
  await storeProject(project);
  isSavingProject.value = false;
}

export const debouncedSaveProject = debounce(saveProject, 1_000);
