<script lang="ts" setup>
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useUpdateUploadedVideo } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import ThumbnailContainer from '@/components/Dialog/MultiUploadDialog/ThumbnailContainer.vue'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { findFeature } from '@/data/features'
import { tiers } from '@/enums/tiers'
import { bytesToMb, formatFileSize } from '@/helpers/fileSize'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { useUserInfoStore } from '@/store/user/userInfo'
import { clamp } from 'lodash-es'
import { capitalize, computed, ref } from 'vue'

interface Props {
  id: string
  titleEditable?: boolean
}

const props = withDefaults(defineProps<Props>(), { titleEditable: false });

const fileUploads = useFileUploads()
const upload = fileUploads.selectById(props.id)

const validationError = computed(() => upload.value.validationResult?.error)
const validationGuard = computed(() => upload.value.validationResult?.guard)
const guardedFeature = computed(() => validationGuard.value ? findFeature(validationGuard.value) : null)
const guardedTierName = computed(() => guardedFeature.value ? capitalize(tiers.toString(guardedFeature.value.tier)) : null)

const userInfoStore = useUserInfoStore()

const { update, isUpdating } = useUpdateUploadedVideo(props.id);

async function submit() {
  if (!upload.value.fileMeta) {
    return;
  }

  await update({
    name: upload.value.fileMeta.fileName,
    fileExtension: upload.value.fileMeta.fileExtension,
    fileSizeMb: bytesToMb(upload.value.fileMeta.fileSize),
    videoDurationSeconds: upload.value.fileMeta.duration,
  });
}

const isCancelling = ref(false)
async function cancel() {
  isCancelling.value = true
  await fileUploads.cancel(props.id)
  isCancelling.value = false
}

const isRemoving = ref(false)
async function remove() {
  isRemoving.value = true
  await fileUploads.remove(props.id)
  isRemoving.value = false
}
</script>

<template>
  <li class="flex flex-col gap-4 border-t border-t-zinc-200 pt-6 pb-6 first:pt-0 first:border-none" :id="'file-preview-' + upload.id">
    <template v-if="(upload.validationResult && !upload.validationResult.requiresAuth && upload.status === 'invalid') || upload.error">
      <span class="flex gap-4 items-center justify-between">
        <Button size="square" tabindex="-1" variant="ghostDestructive" @click="cancel" :disabled="isCancelling">
          <div class="w-6 h-6 rounded-full border-2 border-current border-t-transparent animate-spin flex items-center justify-center" v-if="isCancelling" />
          <IconSaxTrash v-else />
        </Button>
        <span class="rounded-lg layer-2 min-w-[33%] aspect-video grid place-items-center">
          <IconSaxWarning2 class="text-brand-state-error-text w-10 h-10" />
        </span>
        
        <span class="flex flex-col w-full gap-2">
          <span>
            <label class="text-foreground -mt-[1.2em]">Name</label>
            <Input as="div" class="pointer-events-none" disabled>
              <p class="line-clamp-1 h-6">{{ upload.file.name }}</p>
            </Input>
          </span>
        </span>
      </span>
      <span
        class="border border-brand-state-error-border bg-brand-state-error-bg text-brand-state-error-text rounded-lg py-2 px-3 flex items-center gap-3"
      >
        <IconSaxWarning2 class="w-6 h-6" />
        <span v-if="validationError" class="flex flex-col">
          <span class="text-base font-semibold">{{ validationError.title }}</span>
          <span class="text-sm font-light opacity-75">{{ validationError.message }}</span>
        </span>
        <span
          v-else-if="validationGuard === 'server-side-rendering'"
          class="flex flex-col cursor-pointer"
          @click="upgradeDialog.open(validationGuard)"
        >
          <span class="text-base font-semibold text-foreground">Use StreamLadder on your phone</span>
          <span class="text-foreground text-sm font-light opacity-75">
            To use StreamLadder on your phone, please upgrade to <strong>StreamLadder {{ guardedTierName }}</strong>.
          </span>
        </span>
        <template v-else-if="validationGuard === 'upload-large-files'">
          <span
            v-if="userInfoStore.tier === tiers.FREE"
            class="flex flex-col cursor-pointer"
            @click="upgradeDialog.open(validationGuard)"
          >
            <span class="text-base font-semibold text-foreground">File too large</span>
            <span class="text-foreground text-sm font-light opacity-75">
              You uploaded a {{ formatFileSize(upload.file.size) }} file. To edit files up to 1GB, please upgrade to <strong>StreamLadder {{ guardedTierName }}</strong>.
            </span>
          </span>
          <span v-else class="flex flex-col">
            <span class="text-base font-semibold text-foreground">File too large</span>
            <span class="text-foreground text-sm font-light opacity-75">Files larger than 1GB are not supported</span>
          </span>
        </template>
        <span v-else-if="upload.error" class="flex flex-col">
          <span class="text-base font-semibold">{{ upload.error.name }}</span>
          <span class="text-sm font-light opacity-75">{{ upload.error.message }}</span>
        </span>
      </span>
    </template>
    <template v-else>
      <span class="flex gap-4 items-center justify-between">
        <Button size="square" tabindex="-1" variant="ghostDestructive" @click="remove" :disabled="isRemoving || !upload.validationResult">
          <div class="w-6 h-6 rounded-full border-2 border-red-500 border-t-transparent animate-spin flex items-center justify-center" v-if="isRemoving" />
          <IconSaxTrash v-else />
        </Button>
        <ThumbnailContainer
          class="rounded-lg relative"
          :alt="`Thumbnail of '${upload.fileMeta?.fileName}'`"
          :src="upload.fileMeta?.thumbnail?.blobUrl"
        >
          <span v-if="upload.progress !== null"
            class="block rounded-b-lg absolute bottom-0 left-0 right-0 w-full h-1"
            :class="upload.progress < 100 ? 'bg-brand-state-active-bg' : 'bg-brand-state-success-bg'"
          >
            <span class="block bg-brand-state-active-bg absolute top-0 left-0 h-1 rounded-t-lg"
              :style="{ width: clamp(upload.progress, 0, 100) + '%' }"
              :class="upload.progress < 100 ? 'rounded-r-lg bg-brand-state-active-border' : 'bg-brand-state-success'"
            />
          </span>
        </ThumbnailContainer>
        <div class="flex flex-col w-full gap-2 min-w-0">
          <div>
            <label class="text-foreground">Name</label>
            <form @submit.prevent="submit" v-if="upload.fileMeta" @click.stop>
              <Input v-if="!userInfoStore.isLoggedIn || !props.titleEditable" v-model.trim="upload.fileMeta.fileName" disabled class="pointer-events-none" />
              <Input v-else as="label" class="p-0 w-full flex items-center group">
                <input type="text" v-model.trim="upload.fileMeta.fileName" class="bg-transparent !outline-none px-3 py-2 min-w-0 flex-auto" />
                <Button
                  variant="ghost" size="sm" :disabled="isUpdating"
                  class="mx-3 my-2 invisible group-hover:opacity-100 group-hover:visible group-focus-within:opacity-100 group-focus-within:visible !text-brand-state-active-border"
                >
                  <span v-if="isUpdating">Saving...</span>
                  <span v-else>Save</span>
                </Button>
              </Input>
            </form>
            <Input v-else as="div" class="skeleton" disabled />
          </div>
          
          <span v-if="false">
            <!-- Might be useful to check our getVideoFileMeta response from time to time -->
            <label class="text-foreground">File size</label>
            <Input as="textarea" class="text-sm whitespace-pre">
              {{ JSON.stringify(upload.fileMeta, undefined, 4) }}
            </Input>
          </span>
        </div>
      </span>
    </template>
    <span v-if="upload.isDupe" class="border border-brand-state-active-border bg-brand-state-active-bg text-black rounded-lg py-2 px-3 flex items-center gap-3">
      <IconSaxInfoCircle class="w-6 h-6 text-brand-state-active-border" />
      <span class="flex flex-col">
        <span class="text-base font-semibold">Duplicate file</span>
        <span class="text-sm font-light opacity-75">The file you're attempting to upload was already added.</span>
      </span>
      <Button size="circle" tabindex="-1" variant="ghost" @click="fileUploads.state[id].isDupe = false" class="ml-auto w-6 h-6">
        <IconSaxCloseCircle class="w-4 h-4" />
      </Button>
    </span>
  </li>
</template>

<style lang="scss" scoped>

</style>
