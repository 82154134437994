/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */

export type JobStatus = (typeof JobStatus)[keyof typeof JobStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobStatus = {
  draft: 'draft',
  processing: 'processing',
  success: 'success',
  failed: 'failed',
} as const
