<script setup lang="ts">
import { FocusTypes, useEditorFocusStore } from "@/store/editor/editorFocus";
import ZoomInput from '@/areas/editor/views/effects/sections/zooms/ZoomInput.vue'
import { computed, ref, watch } from 'vue'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'

const editorFocusStore = useEditorFocusStore()

const id = computed(() => editorFocusStore.focus?.key)
const focusType = computed(() => editorFocusStore.focus?.type)

const scrollContainer = ref<HTMLDivElement | null>(null)
const isMoving = ref(false)

const { currentSection } = useEditorStep();

// This happens when the Zoom effect is removed
watch(focusType, (type) => {
  if (type !== FocusTypes.ZOOM) {
    currentSection.value = null;
  }
});
</script>

<template>
  <div class="flex flex-col h-full max-h-screen">

    <div class="p-4 pb-0 2xl:p-8 2xl:pb-0 min-h-0">
      <div class="flex items-center gap-2 cursor-pointer hover:-translate-x-0.5 transition-transform text-brand-state-link hover:underline underline-offset-2 md:mb-4" @click="currentSection = null">
        <IconSaxArrowLeft class="w-4 h-4" />
        <span class="text-xl lg:text-sm mt-0.5 select-none">Back to Effects</span>
      </div>
    </div>

    <div class="p-4 pt-0 2xl:p-8 min-h-0" :class="isMoving ? 'overflow-hidden' : 'overflow-y-auto'" ref="scrollContainer">

      <template v-if="id && focusType === FocusTypes.ZOOM">
        <ZoomInput
          :id="id" :key="id"
          @move-start="isMoving = true" @move-end="isMoving = false"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
