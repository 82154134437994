<script setup lang="ts">
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import type { Segment } from '@/areas/editor/@type/Project'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { computed } from 'vue'
import { colorOptions, stringToSemiRandomNumber } from '@/areas/editor/timeline/tracks/stickers/stickerHelpers'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'

const props = defineProps<{ id: string, index: number, segments: Segment[] }>()

const segmentsStore = useSegmentsStore()
const segment = segmentsStore.selectById(props.id)
const currentSegment = useCurrentSegment()

const layoutsStore = useLayoutsStore()
const layout = computed(() => layoutsStore.selectById(segment.layoutId))

const editorFocusStore = useEditorFocusStore()
const isActive = computed(() => {
  const focusType = editorFocusStore.focus?.type
  const isFocussedOnSegmentPart = focusType === FocusTypes.SEGMENT || focusType === FocusTypes.CROP || focusType === FocusTypes.ZOOM
  return isFocussedOnSegmentPart && currentSegment.value?.id === props.id
})

const videoStore = useVideoStore()

const { triggerSeek } = useTimelineStore()
const historyStore = useHistoryStore()

function updateAndSeek(id: string, startMs: number, endMs: number) {

  if (startMs > videoStore.currentTime || endMs < videoStore.currentTime) {
    // This magic number is needed to fix the preview being fully blurred when seeking to the start of a segment
    triggerSeek(startMs + 1);
  }

  segmentsStore.updateTimingsById(id, startMs, endMs)
}

const color = computed(() => colorOptions[Math.floor(stringToSemiRandomNumber(segment.id, 0, colorOptions.length))])

const setFocus = () => {
  segmentsStore.setFocusById(segment.id);
  const isCurrentTimeWithinSegment = videoStore.currentTime >= segment.startMs && videoStore.currentTime <= segment.endMs;
  if (!isCurrentTimeWithinSegment) {
    videoStore._currentTime = segment.startMs / 1000 + 0.01;
  }
};
</script>

<template>
  <TimeLineTrackNode
    :id="segment.id"
    class="md:min-h-14 cursor-grab rounded-lg h-full bg-[hsl(var(--random-bg-color))] ring-[hsl(var(--random-handle-color))] hover:ring-4 data-[state=active]:ring-4 hover:z-20 data-[state=active]:z-10 transition-[box-shadow]"
    :style="{
      '--random-bg-color': color.bg,
      '--random-border-color': color.border,
      '--random-handle-color': color.handle,
    }"
    :start-ms="segment.startMs"
    :end-ms="segment.endMs"
    :min-start-ms="segments[index - 1]?.endMs ?? 0"
    :max-end-ms="segments[index + 1]?.startMs ?? videoStore._duration * 1000"
    :data-focus="isActive ? 'focus' : 'blur'"
    :data-current-segment-id="currentSegment?.id"
    :data-segment-id="segment.id"
    :active="isActive"
    seek-behavior="seek"
    @resizeStart="() => segmentsStore.setFocusById(segment.id)"
    @resizeStop="(startMs, endMs) => historyStore.transaction('SEGMENT:UPDATE_DURATION', () => updateAndSeek(segment.id, startMs, endMs))"
    @dragStart="() => segmentsStore.setFocusById(segment.id)"
    @dragStop="(startMs, endMs) => historyStore.transaction('SEGMENT:UPDATE_START_TIME', () => updateAndSeek(segment.id, startMs, endMs))"
    @activated="setFocus"
  >
    <div class="flex h-full w-full items-center overflow-hidden rounded-lg text-sm font-normal text-white">
      <span
        class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-0.5 text-center align-middle font-normal"
      >
        {{ layout.name }}
      </span>
    </div>
    <template #handles>
      <div class="flex h-full flex-row gap-0.5 px-1 py-1">
        <div class="bg-[hsl(var(--random-handle-color))] h-full w-1 rounded" />
        <div class="bg-[hsl(var(--random-handle-color))] h-full w-1 rounded" />
      </div>
    </template>
  </TimeLineTrackNode>
</template>

<style scoped lang="scss">

</style>
