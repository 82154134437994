import { computed } from 'vue';
import { useUserInfoStore } from '@/store/user/userInfo';
import { useCountdown } from '@/components/ui/countdown';


const blackFridayStartDate = new Date('2024-11-21T00:00:00Z');
const blackFridayEndDate = new Date('2024-12-02T23:59:59Z');

const blackFridayCountDown = useCountdown(blackFridayStartDate, blackFridayEndDate)
const isBlackFriday = computed(() => blackFridayCountDown.remaining.value > 0)

export function useBlackFriday() {

  const userInfoStore = useUserInfoStore();
  const showBlackFridayDeal = computed(() => {
    return userInfoStore.hasLoadedUserInfo && userInfoStore.tier < 100 && isBlackFriday.value
  })

  return {
    ...blackFridayCountDown,
    isBlackFriday: isBlackFriday,
    showBlackFridayDeal: showBlackFridayDeal,
  }
}
