<script setup lang="ts">
import { usePrevious } from '@vueuse/core'
import { computed } from 'vue'
import { range } from 'lodash-es'
import { Button } from '@/components/ui/button'

defineProps<{
  steps: number;
  src: string;
  canGoBackward: boolean;
  canGoForward: boolean;
}>();

const emit = defineEmits(['close', 'backward', 'forward'])

const index = defineModel<number>('index', { required: true })
const previous = usePrevious(index, index.value)
const direction = computed(() => index.value >= previous.value ? 'forward' : 'backward')
</script>

<template>
  <div class="grid lg:grid-cols-2 h-full">
    <div class="flex flex-col gap-2 p-6 lg:p-10">
      <div class="flex justify-between items-center h-8">
        <nav v-if="steps > 1" class="relative flex gap-2">
          <button
            v-for="step in range(steps)" :key="step" :disabled="step > index"
            class="h-1 w-6 rounded bg-black hover:bg-gray-500 active:scale-90 transition-all disabled:bg-gray-100 disabled:pointer-events-none"
            :title="`Skip to step #${step + 1}`"
            @click="index = step"
          />
        </nav>
        <Button v-if="index === 0" @click="emit('close')" variant="link" class="font-light select-none" size="sm">
          Skip survey
        </Button>
      </div>

      <Transition
        :enter-from-class="direction === 'backward' ? 'opacity-0 -translate-y-2' : 'opacity-0 translate-y-2'"
        :leave-to-class="direction === 'backward' ? 'opacity-0 translate-y-2' : 'opacity-0 -translate-y-2'"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
        mode="out-in"
      >
        <div :key="index" class="flex flex-col justify-center min-h-[250px] flex-1 gap-6">
          <slot />
        </div>
      </Transition>

      <div class="flex items-center justify-between gap-2">
        <Button variant="outline" @click="emit('backward')" v-if="canGoBackward">
          Back
        </Button>

        <Button class="ml-auto" size="lg" @click="emit('forward')" :disabled="!canGoForward">
          Continue
        </Button>
      </div>
    </div>
    <div class="relative overflow-hidden">
      <Transition
        :enter-from-class="direction === 'backward' ? 'opacity-0 -translate-y-2' : 'opacity-0 translate-y-2'"
        :leave-to-class="direction === 'backward' ? 'opacity-0 translate-y-2' : 'opacity-0 -translate-y-2'"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
        mode="out-in"
      >
        <img class="w-full h-full absolute inset-0 object-cover" crossorigin="anonymous" :key="src" :src="src" alt="Survey vector image" />
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
