<template>
  <div
    v-show="shouldShow"
    @click="upgrade"
    :class="{ 'bg-gradient-to-br from-yellow-300 to-yellow-600 px-2': !small, 'cursor-pointer': canClick }"
    class="flex select-none items-center rounded-full text-sm text-black"
  >
    <svg
      :class="{ 'h-6 w-6': small, 'h-4 w-4': !small }"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28 28"
    >
      <defs>
        <linearGradient
          :id="`gradient_${id}`"
          x1="14"
          y1="28.46"
          x2="14"
          y2="3.54"
          gradientTransform="translate(0 30) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#ffc600" />
          <stop offset="1" stop-color="#ffde69" />
        </linearGradient>
        <mask :id="`mask_${id}`" x="4.77" y="5.29" width="19.5" height="19.5" maskUnits="userSpaceOnUse" />
        <linearGradient
          :id="`gradient_${id}_2`"
          x1="14.05"
          y1="22.78"
          x2="14.05"
          y2="10.16"
          gradientTransform="translate(0 30) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fffcdd" />
          <stop offset="1" stop-color="#ffe896" />
        </linearGradient>
      </defs>
      <circle cx="14" cy="14" r="13.5" :style="`fill: url(#gradient_${id}); stroke: #765c00;`" />
      <circle cx="14" cy="14" r="9.73" style="fill: #a88300" />
      <g :style="`mask: url(#mask_${id})`">
        <circle cx="14" cy="14" r="9.75" style="fill: #c09525" />
      </g>
      <path
        d="M14.05,7.22l2.1,4.21,4.21,.53-2.89,3.24,.79,4.65-4.21-2.1-4.21,2.1,.79-4.65-2.9-3.24,4.21-.53,2.1-4.21Z"
        :style="`fill: url(#gradient_${id}_2);`"
      />
    </svg>

    <span class="mb-0 ml-1 mt-0.5 whitespace-nowrap pr-2" v-if="!props.small">
      <strong>Gold</strong> <span class="text-base font-normal">plan</span>
    </span>
  </div>
</template>
<script lang="ts" setup>
import type { Feature } from '@/data/features'
import { useGuard } from '@/Hooks/useGuard'
import { computed, ref } from 'vue'
import features from '@/data/features'
import { v4 as uuidv4 } from 'uuid'
import { upgradeDialog } from '@/helpers/upgradeDialog'

const props = withDefaults(
  defineProps<{
    title?: string
    feature?: Feature
    subtitle?: string
    small?: boolean
    findFeature?: boolean
    forceShow?: boolean
    canClick?: boolean
  }>(),
  {
    small: false,
    findFeature: true,
    forceShow: false,
    canClick: true,
  },
)

const _feature = computed(() => features.find((f) => f.name === props.feature))

const title = computed(() => props.title || (props.feature && props.findFeature ? _feature.value.promo?.title : null))
const subtitle = computed(
  () => props.subtitle || (props.feature && props.findFeature ? _feature.value.promo?.subtitle : null),
)

const id = uuidv4()

const canUserUseFeature = props.feature ? useGuard(props.feature) : ref(false)
const shouldShow = computed(() => props.forceShow || !canUserUseFeature.value)
const canClick = computed(() => !props.forceShow && !canUserUseFeature.value)

const upgrade = () => {
  if (!canClick.value || (props.forceShow && canUserUseFeature.value)) {
    return
  }

  upgradeDialog.open(props.feature, true)
}
</script>
