import type { Primitive } from 'ts-essentials';

export function logFlatTable(object: Record<string, unknown>) {
  console.table(flatten(object))
}

function flatten(object: Record<string, unknown>, parentKey = '', result = {} as Record<string, Primitive>){
  for (const key in object) {
    const fullKey = parentKey
      ? Array.isArray(object) ? `${parentKey}[${key}]` : `${parentKey}.${key}`
      : key;
    const childObject = object[key];
    if (isObject(childObject)) {
      flatten(childObject, fullKey, result);
    } else {
      result[fullKey] = object[key] as Primitive;
    }
  }
  return result;
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
