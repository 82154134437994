import { createPinia } from 'pinia';
import { createApp } from 'vue';
import ScreenshotPage from '@/pages/ScreenshotPage.vue';
import type { Sticker } from '@/areas/editor/@type/Project';
import { fontsData } from '@/data/fonts';
import { isAppleDevice } from '@/webcodec-renderer/worker/webcodec-test';

function extractFontWeight(cssRule: string) {
  const match = cssRule.match(/font-weight:\s*(\d+)/);
  return match ? match[1] : null;
}

function getStylesForElement(
  el: Element, allStyles: Set<string>, styleSheets: CSSStyleSheet[],
  fontUrls: Map<string, { url: string, weight: string, family: string }>,
) {

  // Iterate over all stylesheets in the document
  const copy = [...styleSheets];
  for (let i = 0; i < copy.length; i++) {

    const sheet = copy[i];

    // Some stylesheets may not allow access due to cross-origin restrictions
    try {
      const rules = sheet.rules || sheet.cssRules; // Get the CSS rules from the stylesheet
      for (let j = 0; j < rules.length; j++) {
        const rule = rules[j];

        if (rule instanceof CSSFontFaceRule) {
          const src = rule.style.getPropertyValue('src');
          const urlMatch = src.match(/url\(["']?([^"')]+)["']?\)/);
          if (urlMatch) {
            fontUrls.set(urlMatch[1], {
              family: rule.style.getPropertyValue('font-family').replace(/['"]/g, ''),
              weight: rule.style.getPropertyValue('font-weight'),
              url: urlMatch[1],
            });
          }
        }

        // Check if the rule applies to the element
        if (el.matches(rule.selectorText)) {

          let cssText = rule.cssText;
          const cssVariables = rule.cssText.match(/var\(--[^)]+\)/g);
          const variableNames = cssVariables
            ? cssVariables.map(v => v.match(/--[^)]+/)?.[0])
            : [];

          for (const variable of variableNames) {
            if (variable) {
              const variableValue = getComputedStyle(el).getPropertyValue(variable);

              if (!variableValue) {
                continue;
              }
              cssText = cssText.replace(`var(${variable})`, variableValue);
            }
          }
          allStyles.add(cssText);
        }
      }
    } catch (e) {
      styleSheets.splice(i, 1);
      // console.warn('Unable to access stylesheet due to cross-origin restrictions', e);
    }
  }
}


function extractFontFamily(cssString: string) {
  // Regular expression to match the font-family property
  const fontFamilyRegex = /font-family\s*:\s*["']?([^"';]+)["']?\s*;/i;

  // Find the match in the string
  const match = cssString.match(fontFamilyRegex);

  // If a match is found, return the captured group (the font family)
  if (match && match[1]) {
    return match[1].trim();
  }

  // Return null if no font-family is found
  return null;
}

function traverseDom(
  el: Element, allStyles: Set<string>, styleSheets: CSSStyleSheet[],
  fontUrls: Map<string, { url: string, weight: string, family: string }>,
  fontsFromStyle: { weight: string, family: string }[],
) {

  getStylesForElement(el, allStyles, styleSheets, fontUrls);

  const style = el.getAttribute('style');
  if (style?.includes('font-family')) {
    const family = extractFontFamily(style) ?? '';
    const weight = extractFontWeight(style) ?? '';

    fontsFromStyle.push({ family, weight });
  }

  // Recursively go through each child element
  for (let i = 0; i < el.children.length; i++) {
    traverseDom(el.children[i], allStyles, styleSheets, fontUrls, fontsFromStyle);
  }
}

async function fetchFontAsBase64(fontUrl: string) {
  try {
    // Step 1: Fetch the font file from the URL
    const response = await fetch(fontUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch font: ${response.statusText}`);
    }

    // Step 2: Convert the response to a Blob
    const fontBlob = await response.blob();

    // Step 3: Read the Blob as a Base64 Data URL using FileReader
    const base64Font = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(fontBlob);
    });

    return base64Font; // This will return the Base64 Data URL of the font
  } catch (error) {
    console.error('Error fetching or converting the font to base64:', error);
  }
}


async function emojiToBase64(emoji: string) {
  const imageUrl = `/images/apple-emojis/${emoji}.png`;

  // Fetch the image as a blob
  const response = await fetch(imageUrl);
  if (!response.ok) {
    console.error(`Failed to fetch image for emoji: ${emoji}`);
    return null;
  }

  // Convert the image to base64
  const imageBlob = await response.blob();
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result); // This will be the base64 data URL
    };
    reader.onerror = reject;
    reader.readAsDataURL(imageBlob); // Convert the image blob to base64
  });
}


type RenderData = {
  sticker: Sticker
  scale: number,
  renderWidth: number,
  renderHeight: number,
}
declare global {
  interface Window {
    renderOverlay: (data: RenderData) => Promise<null>;
  }
}


const stickerStylesCache = new Map<string, any>();
function getStickerStyles(key: string) {
  const fromCache = stickerStylesCache.get(key);
  if (fromCache) return fromCache;

  const styles = new Set<string>();
  const styleSheets = Array.from(document.styleSheets);
  const fontUrls = new Map<string, { url: string, weight: string, family: string }>();
  const fontsFromStyle: { weight: string, family: string }[] = [];

  const stickerStyles = { styles, styleSheets, fontUrls, fontsFromStyle };

  stickerStylesCache.set(key, stickerStyles);

  return stickerStyles;
}

const styleCache = new Map<string, HTMLStyleElement>();


export function clearCache() {
  styleCache.clear();
  stickerStylesCache.clear();
}

async function getSvgImageFromSticker(sticker: Sticker, stickerContainer: HTMLElement, padding = 0) {

  const previewQuality = Math.min(1080, 720 * Math.min(window.devicePixelRatio, 2));
  const absoluteScale = (1.6 * previewQuality) / sticker.naturalWidth;
  const scale = absoluteScale / previewQuality;

  const cacheKey = JSON.stringify({
    fontFamily: 'fontFamily' in sticker 
      ? sticker.fontFamily 
      : null,
    quality: previewQuality,
    key: sticker.key,
    color: 'color' in sticker
      ? sticker.color
      : null,
    icon: 'icon' in sticker
      ? sticker.icon
      : null,
  });

  // console.log(cacheKey);

  const { styles, styleSheets, fontUrls, fontsFromStyle } = getStickerStyles(cacheKey);

  const targetAreaWidth = sticker.naturalWidth * ((sticker.scale ?? 1) * previewQuality) / previewQuality;
  const targetAreaHeight = sticker.naturalHeight * ((sticker.scale ?? 1) * previewQuality) / previewQuality;

  // const width = stickerContainer.clientWidth;
  // const height = stickerContainer.clientHeight;

  const rect = stickerContainer.getBoundingClientRect();

  let width = rect.width;
  let height = rect.height;

  if (padding) {
    width = targetAreaWidth;
    height = targetAreaHeight;
  }

  const areaWidth = width;
  const areaHeight = height;


  // console.log({ targetAreaHeight, targetAreaWidth, areaHeight, areaWidth, sticker })
  // console.log({ areaWidth, areaHeight, sticker, width, height });

  if (width === 0 || height === 0) return;
  // sticker.imageUrl = ''

  const svgNS = 'http://www.w3.org/2000/svg'; // SVG namespace
  const svg = document.createElementNS(svgNS, 'svg');

  // Set the SVG size
  svg.setAttribute('width', (width + padding * 2).toString());
  svg.setAttribute('height', (height + padding * 2).toString());
  svg.setAttribute('viewBox', `0 0 ${width + padding * 2} ${height + padding * 2}`);

  // Create a foreignObject element
  const foreignObject = document.createElementNS(svgNS, 'foreignObject');
  foreignObject.setAttribute('x', padding.toString());
  foreignObject.setAttribute('y', padding.toString());
  foreignObject.setAttribute('width', areaWidth.toString());
  foreignObject.setAttribute('height', areaHeight.toString());
  foreignObject.setAttribute('style', 'overflow: visible');


  const div = stickerContainer;
  div.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml'); // Set the namespace for HTML inside the foreignObject

  if (styles.size === 0) {
    traverseDom(div, styles, styleSheets, fontUrls, fontsFromStyle);
  }

  const stickerContainerClone = div.cloneNode(true) as HTMLElement;
  // Append the div to the foreignObject
  foreignObject.appendChild(stickerContainerClone);


  // replaceCssVars(stickerContainerClone as Element);

  // stickerContainerClone.style.position = 'absolute';
  // stickerContainerClone.style.top = '0';
  // document.body.appendChild(stickerContainerClone)

  // Append the foreignObject to the SVG
  svg.appendChild(foreignObject);

  svg.setAttribute('id', 'stickerSVG');
  // console.log(svg);
  // Append the SVG to the body (or any other container)

  // svg.style.position = 'absolute'
  // svg.style.top = '0'
  // document.body.appendChild(svg);


  let style = styleCache.get(cacheKey);

  const fontsDefs: string[] = [];

  async function embedFont(label: string, fontWeight: string) {
    await document.fonts.ready;

    let fontUrl = Array.from<{ url: string, weight: string, family: string }>(fontUrls.values())
      .find(font => font.family.toLowerCase() === label.toLowerCase() && font.weight === fontWeight)?.url;
    if (!fontUrl) fontUrl = Array.from<{ url: string, weight: string, family: string }>(fontUrls.values())
      .find(font => font.family.toLowerCase() === label.toLowerCase())?.url;
    if (!fontUrl) {
      const fontData = fontsData.fonts.find(font => font.label === label);
      fontUrl = fontData?.url.substring(5, fontData?.url.length - 2);
    }

    if (fontUrl) {
      const base64 = await fetchFontAsBase64(fontUrl);

      fontsDefs.push(`
        @font-face {
          font-family: '${label}';
          font-style: normal;
          src: url(${base64});
        }
      `);
    }
  }

  if (!style) {

    style = document.createElement('style');
    style.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml'); // Set the namespace for HTML inside the foreignObject

    const stylesArray = Array.from(styles) as string[];

    for (let ix = 0; ix < stylesArray.length; ix++) {
      if (stylesArray[ix].includes('font-family:')) {
        const label = extractFontFamily(stylesArray[ix]) ?? '';
        const fontWeight = extractFontWeight(stylesArray[ix]) ?? '';
        await embedFont(label, fontWeight);
      }
    }

    for (const font of fontsFromStyle) {

      await embedFont(font.family, font.weight);
    }

    style.innerHTML = [...fontsDefs, ...stylesArray].join('\n');

    styleCache.set(cacheKey, style);
  }



  if (!isAppleDevice) {
    const emojiRegex = /(?:\u{2764}\u{FE0F}|[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{2B06}\u{2194}\u{2934}\u{2935}\u{1F004}\u{1F0CF}\u{23E9}\u{1F195}\u{1F197}]+)/gu;

    // Get all span elements on the page
    const spans = svg.querySelectorAll('span');

    // Loop through each span to check for emoji content
    for (const span of spans) {
      const emojiContent = span.innerText?.trim() ?? '';

      const emojiMatch = emojiContent.match(emojiRegex);
      // Check if the span contains an emoji
      if (emojiContent && emojiMatch?.[0] && emojiContent === emojiMatch[0]) {
        const base64Image = await emojiToBase64(emojiMatch[0]) as string;

        if (base64Image) {
          // Create an <img> element with the base64 image
          const imgTag = document.createElement('img');
          imgTag.src = base64Image;

          try {
            await imgTag.decode();
            imgTag.alt = emojiContent;
            imgTag.style.display = 'inline'
            imgTag.style.height = '1em'
            imgTag.style.width = '1em';
            
            // sorry
            imgTag.style.marginBottom = '-3px';
            imgTag.style.marginLeft = '-2px';

            // Replace the span node with the <img> element
            span.replaceWith(imgTag)
          } catch(e) {
            console.warn(e);
          }
        }
      }
    }
  }

  // console.log(style.innerHTML)
  svg.appendChild(style);
  // const font = await fetchFontAsBase64('/fonts/ShadowIntoLight/ShadowsIntoLight-Regular.ttf');

  svg.style.position = 'absolute';
  svg.style.top = '0';

  // document.body.appendChild(svg)


  const stylesArray = Array.from(styles) as string[];

  for (let ix = 0; ix < stylesArray.length; ix++) {
    if (stylesArray[ix].includes('font-family:')) {
      const label = extractFontFamily(stylesArray[ix]) ?? '';
      // default font
      foreignObject.setAttribute('font-family', label);
    }
  }


  const svgString = new XMLSerializer().serializeToString(svg);

  if (!padding) {
    const base64Svg = btoa(unescape(encodeURIComponent(svgString)));
    const imageUrl = `data:image/svg+xml;base64,${base64Svg}`;
    return imageUrl;
  }

  // Create a Blob object from the SVG string
  const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
  const imageUrl = URL.createObjectURL(svgBlob);
  return imageUrl;
}


async function waitForWindowActive() {
  while (document.visibilityState !== 'visible') {
    console.log('wait for focus');
    await new Promise(r => setTimeout(r, 200));
  }
}

let isRenderingResolve = () => {};
let isRenderingPromise: null | Promise<null> = null;

export async function renderStickerVue(
  sticker: Sticker, scale: number,
  quality: number, signal?: AbortSignal,
  element?: Element | null,
) {

  if (isRenderingPromise) {
    await isRenderingPromise;
  }

  isRenderingPromise = new Promise((resolve) => {
    isRenderingResolve = () => resolve(null);
  });

  await waitForWindowActive();
  let stickerContainer = document.getElementById('sticker-renderer');

  if (!stickerContainer) {
    stickerContainer = document.createElement('div');
    stickerContainer.id = 'sticker-renderer';

    stickerContainer.style.opacity = '0';
    document.body.appendChild(stickerContainer);

    const app = createApp(ScreenshotPage);
    const pinia = createPinia();
    app.use(pinia);
    app.mount('#sticker-renderer');
  }

  if (!element) {

    await window.renderOverlay({
      sticker: sticker,
      scale: scale,
      renderWidth: quality,
      renderHeight: quality * (16 / 9),
    });

    const url = await getSvgImageFromSticker(sticker, stickerContainer!.querySelector('#overlay') as Element);
    isRenderingResolve();
    return url;
  } else {
    const url = await getSvgImageFromSticker(sticker, element as Element, 30);
    isRenderingResolve();
    return url;
  }
}
