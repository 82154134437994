/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { CreateVideoDto, GetApiVideosParams, VideoResultDto } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiVideos = (
  params?: MaybeRef<GetApiVideosParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return streamLadderAxiosInstance<VideoResultDto[]>(
    { url: `/api/Videos`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiVideosQueryKey = (params?: MaybeRef<GetApiVideosParams>) => {
  return ['api', 'Videos', ...(params ? [params] : [])] as const
}

export const getGetApiVideosQueryOptions = <TData = Awaited<ReturnType<typeof getApiVideos>>, TError = void>(
  params?: MaybeRef<GetApiVideosParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiVideos>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiVideosQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiVideos>>> = ({ signal }) =>
    getApiVideos(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiVideos>>,
    TError,
    TData
  >
}

export type GetApiVideosQueryResult = NonNullable<Awaited<ReturnType<typeof getApiVideos>>>
export type GetApiVideosQueryError = void

export const useGetApiVideos = <TData = Awaited<ReturnType<typeof getApiVideos>>, TError = void>(
  params?: MaybeRef<GetApiVideosParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiVideos>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiVideosQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const postApiVideos = (
  createVideoDto: MaybeRef<CreateVideoDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  createVideoDto = unref(createVideoDto)

  return streamLadderAxiosInstance<VideoResultDto>(
    { url: `/api/Videos`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createVideoDto },
    options
  )
}

export const getPostApiVideosMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiVideos>>, TError, { data: CreateVideoDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiVideos>>, TError, { data: CreateVideoDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiVideos>>, { data: CreateVideoDto }> = (props) => {
    const { data } = props ?? {}

    return postApiVideos(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiVideosMutationResult = NonNullable<Awaited<ReturnType<typeof postApiVideos>>>
export type PostApiVideosMutationBody = CreateVideoDto
export type PostApiVideosMutationError = void

export const usePostApiVideos = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiVideos>>, TError, { data: CreateVideoDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof postApiVideos>>, TError, { data: CreateVideoDto }, TContext> => {
  const mutationOptions = getPostApiVideosMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteApiVideosId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  id = unref(id)

  return streamLadderAxiosInstance<unknown>({ url: `/api/Videos/${id}`, method: 'DELETE' }, options)
}

export const getDeleteApiVideosIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiVideosId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiVideosId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiVideosId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deleteApiVideosId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiVideosIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiVideosId>>>

export type DeleteApiVideosIdMutationError = void

export const useDeleteApiVideosId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiVideosId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof deleteApiVideosId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteApiVideosIdMutationOptions(options)

  return useMutation(mutationOptions)
}
