<script setup lang="ts">
import HsbInput from '@/components/colors/HsbInput.vue'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { cn } from '@/lib/utils'
import { type ClassValue } from 'clsx'

interface Props {
  disabled?: boolean; 
  class?: ClassValue;
  recentlyUsedColors?: readonly string[];
  recommendedColors?: readonly string[];
  side?: 'left' | 'right' | 'top' | 'bottom';
}

const props = defineProps<Props>();
const model = defineModel<string>({ required: true })
const emit = defineEmits(['commit-value', 'close', 'open']);

const isMobile = useIsMobile();

const modelHasChanged = ref(false);
watch(model, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    modelHasChanged.value = true;
  }
});

function onOpenChange(open: boolean) {
  if (open) {
    emit('open');
  } else if(modelHasChanged.value) {
    emit('close', model.value);
  }

  modelHasChanged.value = false;
}

const isInteracting = ref(false);
function onIsInteracting(value: boolean) {
  isInteracting.value = value;
}
</script>

<template>
  <template v-if="isMobile">
    <Dialog @update:open="onOpenChange">
      <DialogTrigger as-child>
        <template v-if="$slots.default">
          <slot />
        </template>

        <Button v-else variant="toggle" :class="cn('border border-input bg-background min-w-56 justify-start', props.class)">
          <span :style="{ backgroundColor: model }" class="w-6 h-6 border border-border rounded" />
          <span class="text-sm uppercase">{{ model }}</span>
        </Button>
      </DialogTrigger>

      <DialogContent class="max-w-[90%] max-h-[90dvh] mx-auto rounded-lg" :class="{ 'overflow-hidden': isInteracting }">
        <DialogHeader class="text-left">
          <DialogTitle>Choose your color</DialogTitle>
        </DialogHeader>

        <div>
          <HsbInput
            v-model:hex="model"
            @commit-value="emit('commit-value')"
            @is-interacting="onIsInteracting"
            :recently-used-colors="recentlyUsedColors"
            :recommended-colors="recommendedColors"
          />
        </div>

        <DialogFooter>
          <DialogClose>
            <Button variant="primary">
              Choose color
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </template>

  <template v-else>
    <Popover @update:open="onOpenChange">
      <PopoverTrigger as-child>
        <template v-if="$slots.default">
          <slot />
        </template>

        <Button v-else variant="toggle" :class="cn('border border-input bg-background min-w-56 justify-start', props.class)">
          <span :style="{ backgroundColor: model }" class="w-6 h-6 border border-border rounded" />
          <span class="text-sm uppercase">{{ model }}</span>
        </Button>
      </PopoverTrigger>

      <PopoverContent :side="props.side" class="w-56 2xl:w-72">
        <HsbInput
          v-model:hex="model"
          @commit-value="emit('commit-value')"
          :recently-used-colors="recentlyUsedColors"
          :recommended-colors="recommendedColors"
        />
      </PopoverContent>
    </Popover>
  </template>
</template>

<style scoped>

</style>
